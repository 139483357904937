/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React from "react";
import {Box} from "@mui/material";
import {H6} from "components/CustomTypography";

const ShopHeaderOurCollection = (props) => {

    return (
        <Box pt={2} pb={2}
             sx={{
                 alignItems: "center",
             }}
        >
            <Box width="100%" alignItems="left">
                <H6 textTransform="capitalize" fontSize="18px" fontWeight="bold" color="grey.900" ellipsis>
                    Our Collection:
                </H6>
            </Box>
        </Box>
    );
};

export default ShopHeaderOurCollection;
