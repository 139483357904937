import * as React from "react";
import {
  Box, Container, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import PropTypes from "prop-types";
import {
  schema,
  getButtonDataSendApproved,
  getButtonDataSendDisable,
  getButtonData,
  getTextfieldData
} from "./helper";
import {checkUserAllowed, getLocalDate} from "helpers/utils";
import {H2, H4, H5} from "components/CustomTypography";
import TextFieldComponent from "components/TextField";
import {DATE_FORMAT} from "config/constants";

const AdminOfferSettingEditor = ({
  setState, offer, updateOfferSettingClicked, updateOfferInfoClicked, cancelEdit, isLoading, userDetails, history, selectedOffer,
                                   onCancel
}) => {


  const {
     _id, offer_id, name, description
  } = selectedOffer;


  const [blur, setblur] = React.useState({
    name: false,
    description: false,
  });


  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOffer);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.description );

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOffer);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  const onFinishApproved = () => {
    updateOfferSettingClicked({
      offer_id, status: "approve",
    });
  };

  const onFinishDisable = () => {
    updateOfferSettingClicked({
      offer_id, status: "disable",
    });
  };

  const onFinishUpdate = () => {
    updateOfferInfoClicked({
      offer_id, name, description,
    });
  };

  const handleShopButtonClick = () => {
    history.push(`/shops/shop/${offer._id}`);
  };

  return (
      <Container>
        <Box display="flex" flexWrap="wrap">
          <Box mb={2}>
            <ButtonComponent
                type="primary"
                variant="outlined"
                name="< Back"
                size="small"
                onClick={cancelEdit}
            />
          </Box>
        </Box>
        <Box mb={2}>
          <Box width="100%" alignItems="center">
          <H2 textAlign="left" title={offer.name} ellipsis>
            {offer.name}
          </H2>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Description:</H5>
            </Box>
            <Box width="70%">
              {offer.description}
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Valid till:</H5>
            </Box>
            <Box width="70%">
              {getLocalDate(offer.valid_date, DATE_FORMAT)}
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Offer Status:</H5>
            </Box>
            <Box width="70%">
              {offer.is_offer_active === true ? "Active" : "Inactive"}
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Offer Created:</H5>
            </Box>
            <Box width="70%">
              {getLocalDate(offer.offer_created_at, DATE_FORMAT)}
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Shop</H5>
            </Box>
            <Box width="70%">
              {offer.title}
              <ButtonComponent
                  type="primary"
                  variant="contained"
                  name="Shop"
                  size="small"
                  onClick={handleShopButtonClick}
              />
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Shop Status:</H5>
            </Box>
            <Box width="70%">
              {offer.is_shop_active === true ? "Active" : "Inactive"}
            </Box>
          </Box>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="30%" >
              <H5 fontSize={14} textAlign="left" ellipsis>Shop Created:</H5>
            </Box>
            <Box width="70%">
              {getLocalDate(offer.shop_created_at, DATE_FORMAT)}
            </Box>
          </Box>

        </Box>
        <Box mb={2}>
          {((!isLoading && !offer.is_offer_active) && (checkUserAllowed(userDetails.userlevel, "approveoffer"))) &&
              getButtonDataSendApproved(onFinishApproved).map((element) => (
                  <ButtonComponent
                      type={element.type}
                      variant={element.variant}
                      onClick={element.onClick}
                      disabled={element.disabled}
                      name={element.name}
                      size="small"
                  />
              ))
          }
          {((!isLoading && offer.is_offer_active) && (checkUserAllowed(userDetails.userlevel, "disableoffer"))) &&
              getButtonDataSendDisable(onFinishDisable).map((element) => (
                  <ButtonComponent
                      type={element.type}
                      variant={element.variant}
                      onClick={element.onClick}
                      disabled={element.disabled}
                      name={element.name}
                      size="small"
                  />
              ))
          }
        </Box>

        <Box mb={2}>
          {getTextfieldData(name, description).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                    required
                    label={element.label}
                    value={element.value}
                    error={!!getError(element.onChangeField)}
                    helperText={getError(element.onChangeField)}
                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                />
              </Box>
          ))}

          <Typography align="center">
            {getButtonData(onFinishUpdate, cancelEdit, hasError, isTouched).map((element) => (
                <ButtonComponent
                    sx={{
                      m: 2,
                    }}
                    type={element.type}
                    variant={element.variant}
                    onClick={element.onClick}
                    disabled={element.disabled}
                    name={element.name}
                />
            ))}
          </Typography>

        </Box>
      </Container>
  );
};

AdminOfferSettingEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  offer: PropTypes.object.isRequired,
  updateOfferSettingClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AdminOfferSettingEditor;
