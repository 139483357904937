import * as React from "react";
import {
    Avatar,
    Box, Card, Container, IconButton, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import TextFieldComponent from "components/TextField";
import {
    getButtonDataSendApproved,
    getButtonDataSendRejected,
    getButtonDataSendDeleted,
    getButtonDataSendUnlockURL,
    getButtonDataSendEnable,
    getButtonDataSendDisable,
    getButtonDataCancel,
    getTextfieldDataPin,
    getButtonDataSendVerified,
    getButtonDataSendDisplayReq,
    getButtonDataSendDisplayPrinted,
    getButtonDataSendDisplayDelivered,
    getButtonDataSendMsg,
    getTextfieldDataMessage,
    getButtonDataSendInternalMsg,
    getTextfieldDataInternalMessage,
    schema,
    getTextfieldUpdateDomain,
    getButtonUpdateDomain,
    getTextfieldUpdateSocial,
    getButtonUpdateSocial,
} from "./helper";
import {
    checkUserAllowed,
    getLocalDate,
    parseTextWithLinks
} from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import {H2, H5, H6} from "components/CustomTypography";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import {handleExternalPage} from "services/utils";
import {DATE_FORMAT_SHORT, DATE_TIME_FORMAT_SHORT} from "config/constants";
import SelectFieldComponent from "components/SelectField";
import ImageAlbumAdmin from "components/elements/main/ImageAlbumAdmin";
import AdminSellerShopsList from "components/elements/admin/shops/AdminSellerShopsList";
import CustomSectionCreator from "components/CustomSectionCreator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AdminDisplayDeliveredMain from "components/pages/admin/shops/AdminDisplayDeliveredMain";


const AdminSellerSubscriptionEditor = ({
  setState, selectedShop, symbol,
                                   approvedrejectShopClicked, disableShopClicked, enableShopClicked, updateDomainClicked, updateSocialClicked,
                                   verifiedShopClicked, unlockURLShopClicked,
                                   displayReqClicked, displayDeliveredClicked, displayPrintedClicked, handleSalesLeadClicked,
                                   handleShopInternalMessageClicked, handleOrderMessageClicked,
                                   handleSalesLeadSelected, salesLeadOptions, shopMatchingList,
                                   onCancel, orderdetail, isLoading, userDetails,
}) => {

  const {
     created_at: createdAt, order_id: orderIdValue, status, pin, message, internalmessage, subdomain,
      instahandle, facebookhandle, whatsapphandle
  } = selectedShop || {};

    const {
        plans, messages
    } = orderdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinishApproved = () => {
      approvedrejectShopClicked({ shop_id: orderIdValue, status: "approve" });
  };

    const onFinishRejected = () => {
        approvedrejectShopClicked({ shop_id: orderIdValue, status: "reject" });
    };

    const onFinishDeleted = () => {
        approvedrejectShopClicked({ shop_id: orderIdValue, status: "delete" });
    };

    const onFinishUpdateDomain = () => {
        updateDomainClicked({ shop_id: orderIdValue, subdomain: subdomain });
    };

    const onFinishUpdateSocial = () => {
        updateSocialClicked({ shop_id: orderIdValue, instahandle: instahandle, facebookhandle:  facebookhandle, whatsapphandle:  whatsapphandle });
    };

    const onFinishSendInternalMsg = () => {
        handleShopInternalMessageClicked( orderIdValue, message );
    };

    const onFinishSendMsg = () => {
        handleOrderMessageClicked( orderIdValue, message );
    };

    const onFinishUnlocked = () => {
        unlockURLShopClicked({ shop_id: orderIdValue });
    };

    const onFinishEnable = () => {
        enableShopClicked({ shop_id: orderIdValue });
    };

    const onFinishDisable = () => {
        disableShopClicked({ shop_id: orderIdValue });
    };

    const onFinishVerified = () => {
        verifiedShopClicked(orderIdValue, pin);
    };

    const onFinishDisplayReq = () => {
        displayReqClicked({ shop_id: orderIdValue });
    };

    const onFinishDisplayDelivered = (formData) => {
        displayDeliveredClicked(orderIdValue, formData);
    };

    const onFinishDisplayPrinted = () => {
        displayPrintedClicked({ shop_id: orderIdValue });
    };

    const onFinishSalesLead = (event) => {
        handleSalesLeadClicked(orderIdValue, event.target.value);
    };

    const onSelectingSalesLead = (event) => {
        handleSalesLeadSelected(event.target.value);
    };

    const hasError = () => {
    try {
      return !schema.validateSync(selectedShop);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedShop);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };


    const renderShopInformation = () => (
        <>
            <Box mt={2}>
                <H2>
                    {orderdetail.order_id}
                </H2>
                <H5>
                    {orderdetail.status}
                </H5>
                <H5>
                    {orderdetail.paymentstatus}
                </H5>
            </Box>
            <Box mt={2} mb={2}>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Contact Name:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.user && orderdetail.user.name }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Phone/Email:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.user && (orderdetail.user.mobile + " " + orderdetail.user.email) }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Added on:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {getLocalDate(orderdetail.created_at, DATE_TIME_FORMAT_SHORT)}
                        </H5>
                    </Box>
                </Box>
            </Box>
        </>
    );

    const renderShopDetail = () => (
        <Box display={window.innerWidth < 400 ? "" : "flex"}>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Verified:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.is_verified ? "Yes" : "No"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Verified by:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.is_verified ? orderdetail.sales_user && orderdetail.sales_user.name : "NA"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Domain:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.is_domain_locked ? "Ready" : "Not Ready"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Delivery:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.shippingoption}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Payment:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.is_payment_setup ? "Ready" : "Not Ready"}
                        </H5>
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {orderdetail.paymentoption} {orderdetail.paymentupi}
                        </H5>
                    </Box>
                </Box>
                <Box mb={2}>
                    {((!isLoading && !orderdetail.is_verified) && (checkUserAllowed(userDetails.userlevel, "verifyshop"))) &&
                        getTextfieldDataPin(pin).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        ))
                    }

                    {!isLoading && !orderdetail.is_verified && (checkUserAllowed(userDetails.userlevel, "verifyshop")) &&
                    (<SelectFieldComponent
                    label="Sales Lead for verification"
                    options={salesLeadOptions}
                    onChange={(event) => onSelectingSalesLead(event)}
                />)}

                    {((!isLoading && !orderdetail.is_verified) && (checkUserAllowed(userDetails.userlevel, "verifyshop"))) &&
                        getButtonDataSendVerified(onFinishVerified).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                </Box>
            </Box>
        </Box>
    );



    const renderShopSendMessages = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Message to Shop owner:"  />
            <Box mb={2}>
                {
                    getTextfieldDataMessage(message).map((element) => (
                        <Box className="textfield" m={2}>
                            <TextFieldComponent
                                required
                                label={element.label}
                                value={element.value}
                                error={!!getError(element.onChangeField)}
                                helperText={getError(element.onChangeField)}
                                onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))
                },
                {getButtonDataSendMsg(onFinishSendMsg).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
                }
            </Box>
        </Box>
            );

    const renderShopSentMessages = () => (
        <Box>
            <Box mb={2} width="100%">
                {messages && messages.map((messageItem, index) => (
                    <Box width="100%" display="flex">
                        <Box width="30%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {getLocalDate(messageItem.added_at, DATE_FORMAT_SHORT)} ({messageItem.added_by && messageItem.added_by.name})
                            </H5>
                        </Box>
                        <Box width="70%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {parseTextWithLinks(messageItem.message)}
                            </H5>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );


    return (
        <Container>
            <Container maxWidth="md">
                <Box mb={2}>
                    <ButtonComponent
                        type="primary"
                        variant="outlined"
                        name="< Back"
                        size="small"
                        onClick={onCancel}
                    />
                </Box>
                {isLoading && <Box m={10}><MockGeneralHeader  /></Box>}
                {!isLoading && renderShopInformation()}
                {!isLoading && renderShopDetail()}
                <Box mb={2}>
                    <Box width="100%" display="flex">
                        <Box width="40%">
                    {((!isLoading && (orderdetail.status === "new" || orderdetail.status === "rejected")) && (checkUserAllowed(userDetails.userlevel, "approveshop"))) &&
                        getButtonDataSendApproved(onFinishApproved).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }

                        </Box>
                        <Box width="30%">
                    {((!isLoading && (orderdetail.status === "new" || orderdetail.status === "approved")) && (checkUserAllowed(userDetails.userlevel, "rejectshop"))) &&
                        getButtonDataSendRejected(onFinishRejected).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                        </Box>
                        <Box width="30%">
                            {((!isLoading && (orderdetail.status === "rejected")) && (checkUserAllowed(userDetails.userlevel, "deleteshop"))) &&
                                getButtonDataSendDeleted(onFinishDeleted).map((element) => (
                                    <ButtonComponent
                                        type={element.type}
                                        variant={element.variant}
                                        onClick={element.onClick}
                                        disabled={element.disabled}
                                        name={element.name}
                                        size="small"
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mb={2}>
                    {((!isLoading && (orderdetail.status === "approved" && orderdetail.is_active)) && (checkUserAllowed(userDetails.userlevel, "disableshop"))) &&
                        getButtonDataSendDisable(onFinishDisable).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                    {((!isLoading && (orderdetail.status === "approved" && !orderdetail.is_active)) && (checkUserAllowed(userDetails.userlevel, "enableshop"))) &&
                        getButtonDataSendEnable(onFinishEnable).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                </Box>
                <Box mb={2}>
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSendMessages()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSentMessages()}
                </Box>
            </Container>
        </Container>
    );
};

export default AdminSellerSubscriptionEditor;
