/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "components/elements/main/index.scss";

import UserCombinedSearch from "components/widgets/user/UserCombinedSearch";
import UserProductPopularSlider from "components/elements/user/products/UserProductPopularSlider";
import UserProductNewSlider from "components/elements/user/products/UserProductNewSlider";
import UserOffersFavNearbySlider from "components/elements/user/offers/UserOffersFavNearbySlider";
import UserOffersNearbySlider from "components/elements/user/offers/UserOffersNearbySlider";
import UserOffersLatestSlider from "components/elements/user/offers/UserOffersLatestSlider";
import UserShopsNearSlider from "components/elements/user/shops/UserShopsNearSlider";
import UserShopsPopularSlider from "components/elements/user/shops/UserShopsPopularSlider";
import UserShopsLatestSlider from "components/elements/user/shops/UserShopsLatestSlider";
import UserProductCategorySlider from "components/elements/user/products/UserProductCategorySlider";
import UserShopLocationSlider from "components/elements/user/UserShopLocationSlider";
import UserShopsExactLocationSlider from "components/elements/user/shops/UserShopsExactLocationSlider";
import {isFeatureSupported} from "helpers/utils";
import CustomFlexBox from "components/CustomFlexBox";
import { Box } from "@mui/material";
import {Button} from "components/Atoms";
import SlideshowIcon from "@mui/icons-material/Slideshow";

const UserHomeMain = (props) => {

    const {
        history
    } = props;

  const [state, setState] = useState({
    layoutColumns: 3,
  });

  useEffect(() => {

      ReactGA.send({
          hitType: "pageview",
          page: "/",
          title: "Main Home",
      });

    document.getElementById("color").setAttribute("href", "#");
  }, []);

    const handleCollectionButtonClick = () => {

        ReactGA.event({
            category: "User Interaction",
            action: "Clicked Latest Collection",
            label: "Button Main", // Optional
        });

        history.push("/products/sort/popular?ls=1");

    };

  return (
      <div>
          <UserCombinedSearch {...props} colSize={state.layoutColumns} />

          <UserProductCategorySlider isDirect={true} includeAll={false} {...props} />

          <UserProductPopularSlider />

          {/* <UserShopLocationSlider isDirect={true} isProduct={true} {...props} /> */}

          <UserShopsPopularSlider />

          <UserShopsLatestSlider />

          <UserProductNewSlider />

          <UserShopsNearSlider />

          <UserShopsExactLocationSlider />

          {/* <UserShopLocationSlider isDirect={true} isProduct={false} {...props} /> */}

          <UserOffersNearbySlider />


          {localStorage.getItem("isAuthorized") &&
              <UserOffersFavNearbySlider />}


          <UserOffersLatestSlider />

          {isFeatureSupported("shopreels") &&
              <CustomFlexBox sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  width: '100%',
                  bottom: 70,
                  justifyContent: 'center',
                  zIndex: 'modal'
              }}>

                      <Button
                          sx={{marginRight: '2px', marginLeft: '4px'}}
                          size="large"
                          color="primary"
                          variant="contained"
                          onClick={() => handleCollectionButtonClick()}
                      >
                          <SlideshowIcon /> &nbsp; Latest Collection
                      </Button>
              </CustomFlexBox>
          }

      </div>
  );
};

export default UserHomeMain;
