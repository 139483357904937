// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.orders";

const querystring = require('querystring');

function* adminGetCommonOrders(action: Object) {
  try {
    const response = yield API.get(
      `/admin/orders/search` + (action.category ? `?category=` + action.category.id : ""),
      action.payload
    );
    yield put(Actions.adminGetCommonOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetCommonOrdersFailure(error));
  }
}


function* adminGetStoreOrders(action: Object) {
  try {

    const searchQuery = querystring.stringify({ id: action.shopId, status: action.orderstatus ?  action.orderstatus : "" });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/admin/orders/search`+ query
    );
    yield put(Actions.adminGetStoreOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetStoreOrdersFailure(error));
  }
}

function* adminSearchOrders(action: Object) {
  try {
    const searchQuery = querystring.stringify({
      oid: action.oid,
      shopId: action.shopId ? action.shopId : "",
      isShop: action.isShop ? action.isShop : "",
      limit: action.limit ? action.limit : "",
      status: action.orderstatus ?  action.orderstatus : ""
    });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/admin/orders/search` + query
    );
    yield put(Actions.adminSearchOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchOrdersFailure(error));
  }
}
function* adminSearchOrderKeywords(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      oid: action.oid,
      shopId: action.shopId ? action.shopId : "",
      isShop: action.isShop ? action.isShop : "",
    });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/orders/get-keywords` + query,
    );
    yield put(Actions.adminSearchOrderKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchOrderKeywordsFailure(error));
  }
}

function* adminGetOrderDetail(action: Object) {
  try {
    const response = yield API.get(
        `/admin/orders/get-order/` + action.payload,
    );
    yield put(Actions.adminGetOrderDetailSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetOrderDetailFailure(error));
  }
}

function* adminUpdateOrder(action: Object) {
  try {
    const response = yield API.post(
        `admin/orders/update`,
        action.payload
    );
    yield put(Actions.adminUpdateOrderSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateOrderFailure(error));
  }
}

export default function* OrdersSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_GET_COMMON_ORDERS_START, adminGetCommonOrders);
  yield takeLatest(ActionTypes.ADMIN_GET_STORE_ORDERS_START, adminGetStoreOrders);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_ORDERS_START, adminSearchOrders);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_START, adminSearchOrderKeywords);
  yield takeLatest(ActionTypes.ADMIN_GET_ORDER_DETAIL_START, adminGetOrderDetail);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_ORDER_START, adminUpdateOrder);
}
