/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from 'lodash.debounce';

import * as Actions from "actions";
import {
    TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import UserProductItem from "components/elements/user/products/UserProductItem";
import UserProductDetail from "components/elements/user/products/UserProductDetail";
import UserSellerShop from "components/elements/user/shops/UserSellerShop";
import UserSellerShopView from "components/elements/user/shops/UserSellerShopView";
import MockProductList from "components/elements/main/MockProductList";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {H5, H6} from "components/CustomTypography";
import {userGetProductInfo, userMainSearchCombined} from "actions";
import {Link} from "react-router-dom";
import {styled} from "@mui/material/styles";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {getImage, getLocationBreadcrum} from "helpers/utils";
import LazyImage from "components/LazyImage";
import CustomSectionCreator from "../../../CustomSectionCreator";

export const SearchOutlinedIcon = styled(SearchOutlined)(({ theme }) => ({
    color: theme.palette.grey[600],
    marginRight: 6,
}));

const UserCombinedSearch = (props) => {
  const [state, setState] = useState({
    limit: 20,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search products & shops",
    isShop: false,
  });

  const [productState, setProductState] = useState("");
    const [shopState, setShopState] = useState("");

    const [productModeState, setProductModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);

    const {
    serviceError, reqStatus, isLoadingProducts,
        userMainResetSearchProducts, userMainResetsearchKeywords,
        userMainSearchCombined, userMainSearchKeywordsCombined,
        userMainGetProductInfo, userMainGetShopInfoById,
    products, productdetail, searchkeywords_combined,
        shops, shopdetail,
        userMainResetProductDetail,
      currency, match, history,
    isLoadingShops, shopId, isShop, limit, isMarketMall,
        userDetails
  } = props;

  const { keywords } = searchkeywords_combined;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;

  useEffect(() => {
    if (query) {
        userMainResetSearchProducts();
        userMainSearchCombined(query, isShop, shopId, limit, isMarketMall);
    }
  }, []);

  const runSearch = (keyword) => {
      userMainResetsearchKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });
    history.push(`/search/${keyword}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
        userMainSearchKeywordsCombined(value, shopId, isShop);
    }
  };

    const debouncedChangeHandler = useCallback(
        debounce(runKeywordSearch, 300)
        , []);

  const selectKeywordSearch = (value) => {
      history.push(`/search/${value}`);
  };

  const handleDelete = () => {
      history.push("/");
  };
    const handleViewMoreShops = () => {
        history.push(`/shops/search/${query}`);
    };

    const handleViewMoreProducts = () => {
        history.push(`/products/search/${query}`);
    };

  const approveProduct = (product) => {
      userMainGetProductInfo(product);
    setProductModeState("PREVIEW");
  };


  const handleProductEdit = (mode, product, reset) => () => {
    setProductModeState(mode);
    if (reset) {
      setProductState({
        selectedProduct: {
           _id: "", status: "", order_type: ""
        },
      });
    } else {
        userMainGetProductInfo(product.id);
      setProductState({
        selectedOrder: {
          _id: product._id, status: product.status
        },
      });
    }
  };

    const handleShopEdit = (mode, shop, reset) => () => {
        setProductModeState(mode);
        if (reset) {
            setShopState({
                selectedShop: {
                    shop_id: "", status: "", user: "",
                    is_active: "", is_verified: "",
                },
            });
        } else {
            userMainGetShopInfoById(shop.id);
            setShopState({
                selectedShop: {
                    shop_id: shop.id, status: shop.status, user: shop.user,
                    is_active: shop.is_active, is_verified: shop.is_verified,
                },
            });
        }
    };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);


  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/search")) {
      history.push("/search");
    }
  };

  let isModalSearch = false;

  const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No Product found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  const renderResults = () => (
      <div>
          <Typography variant="h6">Products</Typography>
          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
              { products.map((product, index) => (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                      <UserProductItem
                          product={product}
                          currency={currency}
                          key={`${index + 1}`}
                          viewProduct={handleProductEdit("VIEW_PRODUCT", product, false)}
                      />
                  </Grid>
              ))}
          </Grid>
          <Typography variant="h6">Shops</Typography>
          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
              { shops.map((shop, index) => (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                      <UserSellerShop
                          shop={shop}
                          isDirect={false}
                          currency={currency}
                          viewShop={handleShopEdit("VIEW_SHOP", shop, false)}
                          key={`${index + 1}`}
                      />
                  </Grid>
              ))}
          </Grid>
      </div>
  );



  const renderSearchHelp = () => (
      <Container>
          <Box>
              {
                  window.innerWidth <= 500
                  && (match.path === "/search")
                  && optionValues.map((element) => (
                      element.type === "s" ?

                          <Box m={2} width="100%" display="flex" onClick={() => history.push(`/shop/${element.value}`)}>
                              <Box width="75%">
                                  <H5 fontSize={16} ellipsis>
                                      {element.label}
                                  </H5>
                                  <H6 fontSize={12} ellipsis>
                                      {element.sublabel}
                                  </H6>
                              </Box>
                              <Box width="25%">
                                  <LazyImage
                                      src={getImage(element.images)}
                                      width={60}
                                      height={60}
                                      mx="auto"
                                  />
                              </Box>
                          </Box>
                          :
                          <Box m={2} width="100%" display="flex" onClick={() => selectKeywordSearch(element.label)}>
                              <Box width="85%">
                                  <H5 fontSize={16} ellipsis>
                                      {element.label}
                                  </H5>
                                  <H6 fontSize={12} ellipsis>
                                      {element.sublabel}
                                  </H6>
                              </Box>
                              <Box width="15%">
                                  <IconButton disabled align="right" size="large">
                                      <ArrowForwardIcon />
                                  </IconButton>
                              </Box>
                          </Box>
                  ))
              }
          </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box mb={2}>
        {
            window.innerWidth <= 500
            && (match.path === "/search") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={handleKeyPress}
          onSelect={(input) => debouncedChangeHandler(input.target.value)}
          options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
          renderInput={(params) => (
            <TextField
                {...params}
                InputProps={{
                    sx: {
                        height: 44,
                        paddingRight: 0,
                        borderRadius: 0,
                        color: "grey.900",
                        overflow: "hidden",
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                        },
                    },
                    startAdornment: <SearchOutlinedIcon fontSize="small" />,
                }}
              autoFocus={isModalSearch}
              placeholder={`Search ${process.env.REACT_APP_SHOP_TITLE} & ${process.env.REACT_APP_PRODUCT_TITLE}`}
            />
          )}
        />
        </Box>
      </Container>

        {
            renderSearchHelp()
        }


      <Container>
        {query && (
        <Chip
          label={query}
          onDelete={handleDelete}
        />
        )}
      </Container>

      { match.path !== "/"
      && (
          match.path === "/search/:query" ||
          match.path === "/search/"
      ) && (
              <>
                  {query && (<CustomSectionCreator title={"Searching: '" + query + "'"} />)}
                  {isLoadingProducts ? <MockProductList />
                      : (products.length > 0 || shops.length > 0)
                          ? renderResults()
                          : renderNoResults()}
              </>
          )}
    </>
  );

  const renderProductEdit = (productMode) => {
    switch (productMode) {
        case "VIEW_SHOP":
            return (
                <UserSellerShopView
                    setState={(value, field) => setShopState({
                        ...state,
                        selectedShop: {
                            ...selectedShop,
                            [field]: value,
                        },
                    })}
                    currency={currency}
                    history={history}
                    isLoading={isLoadingShops}
                    shopdetail={shopdetail}
                    selectedShop={selectedShop}
                    onCancel={() => setProductModeState("PREVIEW")}
                />
            );

      case "VIEW_PRODUCT":
        default:
        return (
            <UserProductDetail
                setState={(value, field) => setProductState({
                  ...state,
                  selectedProduct: {
                    ...selectedProduct,
                    [field]: value,
                  },
                })}
                currency={currency}
                history={history}
                userDetails={userDetails}
                isLoading={isLoadingProducts}
                productdetail={productdetail}
                selectedProduct={selectedProduct}
                onCancel={() => setProductModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedProduct = {} } = productState;
    const { selectedShop = {} } = shopState;

  return (
      <Container>
        {productModeState === "PREVIEW" ? (
            renderSearchModule()
        ) : (
            renderProductEdit(productModeState)
        )}
      </Container>
  );


};

UserCombinedSearch.defaultProps = {
  currency: "",
};

UserCombinedSearch.propTypes = {
  currency: PropTypes.string,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
    shops: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchkeywords_combined: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainSearchCombined: PropTypes.func.isRequired,
    userMainSearchKeywordsCombined: PropTypes.func.isRequired,
    userMainResetsearchKeywords: PropTypes.func.isRequired,
    userMainResetSearchProducts: PropTypes.func.isRequired,
    userMainGetProductInfo: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userCombinedSearch = connect(
  ({ userMainProducts, bootupInfo, userMainShops }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    currency: bootupInfo.symbol,
    reqStatus: userMainProducts.reqStatus,
    isLoadingProducts: userMainProducts.isLoading,
    serviceError: userMainProducts.serviceError,
    products: userMainProducts.searchproducts,
      shops: userMainProducts.searchshops,
      searchkeywords_combined: userMainProducts.searchkeywords_combined,
      searchcategorykeywords: userMainProducts.searchcategorykeywords,
      productdetail: userMainProducts.productdetail,
      shopdetail: userMainShops.shopDetail,
    isLoadingShops: userMainShops.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserCombinedSearch);

export { userCombinedSearch as default };
