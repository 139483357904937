/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import { BottomNavigation, BottomNavigationAction } from "components/Atoms";

import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import StorageIcon from "@mui/icons-material/Storage";
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import PercentIcon from '@mui/icons-material/Percent';
import {getURI} from "helpers/utils";


const BottomNavMain = (props) => {

  const {
    pathArray
  } = props;

  const [tab, setTab] = React.useState("products");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const selectedTab = () => {

    switch (pathArray[1]) {
      case "me":
        setTab("account");
        break;
      case "shops":
        setTab("shops");
        break;
      case "products":
        setTab("products");
        break;
      case "offers":
        setTab("offers");
        break;
      case "home":
      default:
        setTab("home");
        break;

    }
  }

  React.useEffect(() => {
    selectedTab();
  });

  return (
    <BottomNavigation
      showLabels
      style={{
        zIndex: 10, position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "#fff", boxShadow: "0 -1px 1px 0 rgba(0,0,0,.1)",
      }}
      value={tab}
      onChange={handleChange}
    >
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "home", "")} />
      <BottomNavigationAction label={process.env.REACT_APP_SHOP_TITLE} value="shops" icon={<StoreIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "shops", "")} />
      <BottomNavigationAction label={process.env.REACT_APP_PRODUCT_TITLE} value="products" icon={<StorageIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "products", "")} />
      <BottomNavigationAction label="Offers" value="offers" icon={<PercentIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "offers", "")}/>
      <BottomNavigationAction label="Account" value="account" icon={<PersonRoundedIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "account", "")} />
    </BottomNavigation>

  );
};

BottomNavMain.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslate(BottomNavMain);
