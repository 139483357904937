import React from 'react';
import ContentLoader from "react-content-loader";

const MockShopHeader = (props) => (
    <ContentLoader
        speed={2}
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    ><rect x="0" y="0" width="100%" height="64" />
    </ContentLoader>
)

export default MockShopHeader;
