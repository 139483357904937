// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const login = (req) => ({
  type: ActionTypes.LOGIN_START,
  payload: req,
});

export const loginSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: req,
  };
};
export const loginFailure = (error) => ({
  type: ActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const loginPreOTP = (req) => ({
  type: ActionTypes.LOGIN_PRE_OTP_START,
  payload: req,
});

export const loginPreOTPSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_PRE_OTP_SUCCESS,
    payload: req,
  };
};
export const loginPreOTPFailure = (error) => ({
  type: ActionTypes.LOGIN_PRE_OTP_FAILURE,
  payload: error,
});

export const loginPostOTP = (req) => ({
  type: ActionTypes.LOGIN_POST_OTP_START,
  payload: req,
});

export const loginPostOTPSuccess = (req) => {
  return {
    type: ActionTypes.LOGIN_POST_OTP_SUCCESS,
    payload: req,
  };
};
export const loginPostOTPFailure = (error) => ({
  type: ActionTypes.LOGIN_POST_OTP_FAILURE,
  payload: error,
});

export const Sociallogin = (req) => ({
  type: ActionTypes.SOCIAL_LOGIN_START,
  payload: req,
});

export const SocialloginSuccess = (req) => {
  return {
    type: ActionTypes.SOCIAL_LOGIN_SUCCESS,
    payload: req,
  };
};
export const SocialloginFailure = (error) => ({
  type: ActionTypes.SOCIAL_LOGIN_FAILURE,
  payload: error,
});


export const logout = (req) => ({
  type: ActionTypes.LOGOUT_START,
  payload: req,
});

export const logoutSuccess = (req) => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    payload: req,
  };
};
export const logoutFailure = (error) => ({
  type: ActionTypes.LOGOUT_FAILURE,
  payload: error,
});

export const deleteAccount = (req) => ({
  type: ActionTypes.DELETE_ACCOUNT_START,
  payload: req,
});

export const deleteAccountSuccess = (req) => {
  return {
    type: ActionTypes.DELETE_ACCOUNT_SUCCESS,
    payload: req,
  };
};
export const deleteAccountFailure = (error) => ({
  type: ActionTypes.DELETE_ACCOUNT_FAILURE,
  payload: error,
});

export const register = (req) => ({
  type: ActionTypes.REGISTER_START,
  payload: req,
});

export const registerSuccess = (data) => {
  return {
    type: ActionTypes.REGISTER_SUCCESS,
    payload: data,
  };
};
export const registerFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILURE,
  payload: error,
});

export const resetPassword = (req) => ({
  type: ActionTypes.RESET_PASSWORD_START,
  payload: req,
});

export const resetPasswordSuccess = (data) => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
    payload: data,
  };
};
export const resetPasswordFailure = (error) => ({
  type: ActionTypes.RESET_PASSWORD_FAILURE,
  payload: error,
});

export const getUserProfile = (req) => ({
  type: ActionTypes.GET_USER_PROFILE_START,
  payload: req,
});

export const getUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getUserProfileFailure = (error) => ({
  type: ActionTypes.GET_USER_PROFILE_FAILURE,
  payload: error,
});

export const updateUserUUID = (req) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_UUID_START,
  payload: req,
});

export const updateUserUUIDSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_UUID_SUCCESS,
    payload: data,
  };
};
export const updateUserUUIDFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_UUID_FAILURE,
  payload: error,
});

export const updateUserLocation = (req) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_LOCATION_START,
  payload: req,
});

export const updateUserLocationSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_LOCATION_SUCCESS,
    payload: data,
  };
};
export const updateUserLocationFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_LOCATION_FAILURE,
  payload: error,
});

export const updateAdminLocation = (req) => ({
  type: ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_START,
  payload: req,
});

export const updateAdminLocationSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_SUCCESS,
    payload: data,
  };
};
export const updateAdminLocationFailure = (error) => ({
  type: ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_FAILURE,
  payload: error,
});

export const getShopUserProfile = (req) => ({
  type: ActionTypes.GET_SHOP_USER_PROFILE_START,
  payload: req,
});

export const getShopUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopUserProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_USER_PROFILE_FAILURE,
  payload: error,
});

export const getShopAndUserProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_SHOP_AND_USER_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getShopAndUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_AND_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopAndUserProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_AND_USER_PROFILE_FAILURE,
  payload: error,
});

export const getShopProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_SHOP_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getShopProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHOP_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getShopProfileFailure = (error) => ({
  type: ActionTypes.GET_SHOP_PROFILE_FAILURE,
  payload: error,
});

export const getMarketAndUserProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_MARKET_AND_USER_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getMarketAndUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_MARKET_AND_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getMarketAndUserProfileFailure = (error) => ({
  type: ActionTypes.GET_MARKET_AND_USER_PROFILE_FAILURE,
  payload: error,
});

export const getMarketProfile = (req, isShopDomain) => ({
  type: ActionTypes.GET_MARKET_PROFILE_START,
  payload: req,
  isShopDomain: isShopDomain
});

export const getMarketProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_MARKET_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getMarketProfileFailure = (error) => ({
  type: ActionTypes.GET_MARKET_PROFILE_FAILURE,
  payload: error,
});


export const updateUserProfile = (req) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_START,
  payload: req,
});

export const updateUserProfileSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PROFILE_SUCCESS,
    payload: data,
  };
};
export const updateUserProfileFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PROFILE_FAILURE,
  payload: error,
});

export const getBillingAddress = (req) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_START,
  payload: req,
});

export const getBillingAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_BILLING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getBillingAddressFailure = (error) => ({
  type: ActionTypes.GET_BILLING_ADDRESS_FAILURE,
  payload: error,
});

export const updateBillingAddress = (req) => ({
  type: ActionTypes.UPDATE_BILLING_ADDRESS_START,
  payload: req,
});

export const updateBillingAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateBillingAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_BILLING_ADDRESS_FAILURE,
  payload: error,
});


export const addShippingAddress = (req) => ({
  type: ActionTypes.ADD_SHIPPING_ADDRESS_START,
  payload: req,
});

export const addShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.ADD_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const addShippingAddressFailure = (error) => ({
  type: ActionTypes.ADD_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const getShippingAddress = (req) => ({
  type: ActionTypes.GET_SHIPPING_ADDRESS_START,
  payload: req,
});

export const getShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getShippingAddressFailure = (error) => ({
  type: ActionTypes.GET_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const updateShippingAddress = (req) => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_START,
  payload: req,
});

export const updateShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateShippingAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const deleteShippingAddress = (req) => ({
  type: ActionTypes.DELETE_SHIPPING_ADDRESS_START,
  payload: req,
});

export const deleteShippingAddressSuccess = (data) => {
  return {
    type: ActionTypes.DELETE_SHIPPING_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const deleteShippingAddressFailure = (error) => ({
  type: ActionTypes.DELETE_SHIPPING_ADDRESS_FAILURE,
  payload: error,
});

export const updateUserPic = (req) => ({
  type: ActionTypes.UPDATE_USER_PIC_START,
  payload: req,
});

export const updateUserPicSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_USER_PIC_SUCCESS,
    payload: data,
  };
};
export const updateUserPicFailure = (error) => ({
  type: ActionTypes.UPDATE_USER_PIC_FAILURE,
  payload: error,
});

export const getCities = (req) => ({
  type: ActionTypes.GET_CITIES_START,
  payload: req,
});

export const getCitiesSuccess = (data) => {
  return {
    type: ActionTypes.GET_CITIES_SUCCESS,
    payload: data,
  };
};
export const getCitiesFailure = (error) => ({
  type: ActionTypes.GET_CITIES_FAILURE,
  payload: error,
});

export const getCityLocations = (cityid) => ({
  type: ActionTypes.GET_CITY_LOCATIONS_START,
  cityid: cityid,
});

export const getCityLocationsSuccess = (data) => {
  return {
    type: ActionTypes.GET_CITY_LOCATIONS_SUCCESS,
    payload: data,
  };
};
export const getCityLocationsFailure = (error) => ({
  type: ActionTypes.GET_CITY_LOCATIONS_FAILURE,
  payload: error,
});

export const getAddressSuggestions = (req) => ({
  type: ActionTypes.GET_ADDRESS_SUGGESTIONS_START,
  payload: req,
});

export const getAddressSuggestionsSuccess = (data) => {
  return {
    type: ActionTypes.GET_ADDRESS_SUGGESTIONS_SUCCESS,
    payload: data,
  };
};
export const getAddressSuggestionsFailure = (error) => ({
  type: ActionTypes.GET_ADDRESS_SUGGESTIONS_FAILURE,
  payload: error,
});

export const createPaymentOrder = (req) => ({
  type: ActionTypes.COMMON_CREATE_PAYMENT_ORDER_START,
  payload: req,
});

export const createPaymentOrderSuccess = (data) => {
  return {
    type: ActionTypes.COMMON_CREATE_PAYMENT_ORDER_SUCCESS,
    payload: data,
  };
};
export const createPaymentOrderFailure = (error) => ({
  type: ActionTypes.COMMON_CREATE_PAYMENT_ORDER_FAILURE,
  payload: error,
});

export const checkPaymentVerifyOrder = (req) => ({
  type: ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_START,
  payload: req,
});

export const checkPaymentVerifyOrderSuccess = (data) => {
  return {
    type: ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_SUCCESS,
    payload: data,
  };
};
export const checkPaymentVerifyOrderFailure = (error) => ({
  type: ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_FAILURE,
  payload: error,
});

export const addAppErrorLog = (req) => ({
  type: ActionTypes.ADD_APP_ERROR_LOG_START,
  payload: req,
});

export const addAppErrorLogSuccess = (data) => {
  return {
    type: ActionTypes.ADD_APP_ERROR_LOG_SUCCESS,
    payload: data,
  };
};
export const addAppErrorLogFailure = (error) => ({
  type: ActionTypes.ADD_APP_ERROR_LOG_FAILURE,
  payload: error,
});
