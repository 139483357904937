/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-multi-carousel";
import * as Actions from "actions";
import { Container, Box } from "@mui/material";

import MockProductBox from "components/elements/main/MockProductBox";
import CustomSectionCreator from "components/CustomSectionCreator";
import AdminSellerShopItem from "components/elements/admin/shops/AdminSellerShopItem";

const AdminSalesLeadList = (props) => {

  const { searchkeywords, selectedShop, userDetails, adminSearchSalesLeadKeywords, isLoading } = props;

    useEffect(() => {
        adminSearchSalesLeadKeywords("exact", selectedShop.title, "", selectedShop.location);
    }, []);

    const renderResults = () => (
        <Container>
            <CustomSectionCreator title="Similar Sales Leads"  />
            { searchkeywords.keywords.map((shop, index) => (
                <Box>
                    <AdminSellerShopItem
                        shop={shop}
                        isSame={shop.value === selectedShop.id}
                        isDirect={true}
                        key={`${index + 1}`}
                    />
                </Box>
            ))}
        </Container>
    );

  return (
<>
      {isLoading ? <MockProductBox />
              : AdminSalesLeadList.length > 0
                  ? renderResults()
                  : ""}

</>

  );
};

AdminSalesLeadList.propTypes = {
    searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
    adminSearchSalesLeadKeywords: PropTypes.func.isRequired,
};

// Mapping Props and State.
const adminSalesLeadList = connect(
  ({ adminSales, bootupInfo }) => ({
      userDetails: bootupInfo.userDetails,
      reqStatus: adminSales.reqStatus,
      isLoading: adminSales.isLoading,
      serviceError: adminSales.serviceError,
      searchkeywords: adminSales.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSalesLeadList);

export { adminSalesLeadList as default };
