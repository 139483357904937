//  @flow
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleExternalPage } from "services/utils";

import {
  Page,
  Notification,
  Pace,
} from "components/Atoms";
import {
  Box, Card, Container, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "actions/seller/seller.store";

import CustomSectionCreator from "components/CustomSectionCreator";
import DomainEditor from "components/elements/seller/DomainEditor";
import LanguageIcon from '@mui/icons-material/Language';
import LockIcon from '@mui/icons-material/Lock';

import "./styles.scss";

const StoreProfileDomain = (props) => {
  const [state, setState] = useState({ domainMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopDomain,
    getDomain, updateDomain, checkDomainAvailability,
  } = props;

  useEffect(() => {
    getDomain();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.CHECK_DOMAIN_AVAILABILITY_SUCCESS:
        setState({ storeDomain: shopDomain });
        // setState({ newsubdomain: shopDomain.value.subdomain });
        break;
      case ActionTypes.UPDATE_DOMAIN_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const handleDomainEdit = (mode) => (e) => {
    setState({ domainMode: mode });
    setState({ storeDomain: shopDomain });
    setState({ newsubdomain: shopDomain.value.subdomain });
  };

  const handleDomainEditCancel = (mode) => (e) => {
    setState({ domainMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateDomainCall = (values) => {
    setState({ domainMode: "PREVIEW" });
    updateDomain(values);
  };

  const { domainMode, newsubdomain, storeDomain = {} } = state;

  const isDomainEditing = reqStatus === ActionTypes.UPDATE_DOMAIN_START;
  const url = `https://${shopDomain.value.subdomain}.${shopDomain.value.domain}`;

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<LanguageIcon />} title="Shop Website">
          {domainMode === "PREVIEW" ? (
              <Box display="flex">
                <Box overflow="hidden" width="80%" className="p-3">
                  <Typography>
                    {!isLoading && <a href="#" onClick={handleExternalPage(url)}>{`${url || ""}`}</a>}

                  </Typography>
                </Box>
                <Box>
                  <Typography align="center">
                    {!isLoading
                    && (
                      !shopDomain.value.is_domain_locked ?
                      <ButtonComponent
                      sx={{
                      m: 2,
                    }}
                      type="primary"
                      variant="contained"
                      name="Edit"
                      size="small"
                      onClick={handleDomainEdit("EDIT")}
                      /> :
                          <LockIcon  color="primary" />
                    )
                    }
                  </Typography>
                </Box>
              </Box>
          ) : (
              <DomainEditor
                  setState={(event) => setState({
                    ...state,
                    newsubdomain: event.target.value,
                  })}
                  cancelEdit={handleDomainEditCancel("PREVIEW")}
                  storeDomain={storeDomain}
                  newsubdomain={newsubdomain}
                  checkDomainAvailability={checkDomainAvailability}
                  updateDomainCall={updateDomainCall}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfileDomain.propTypes = {
  history: PropTypes.object.isRequired,
  shopDomain: PropTypes.object.isRequired,
  updateDomain: PropTypes.func.isRequired,
  checkDomainAvailability: PropTypes.func.isRequired,
  getDomain: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const _StoreProfileDomain = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopDomain: store.storeDomain,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileDomain);

export { _StoreProfileDomain as default };
