//  @flow
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Page,
  Notification,
  Pace,
} from "components/Atoms";
import {
  Container, Box, Card, IconButton
} from "@mui/material";
import {H4, H5} from 'components/CustomTypography';

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "actions/seller/seller.store";

import AddressPreview from "components/elements/seller/AddressPreview";
import AddressEditor from "components/elements/seller/AddressEditor";

import "./styles.scss";
import CustomSectionCreator from "components/CustomSectionCreator";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import {openGoogleMapLocation} from "helpers/utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ButtonComponent from "../../../../Button";
import LockIcon from "@mui/icons-material/Lock";

const StoreProfileAddress = (props) => {

  const [state, setState] = useState({ addressMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopAddress,
    getStoreAddress, updateStoreAddress, updateStoreLocation
  } = props;

  useEffect(() => {
    getStoreAddress();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_ADDRESS_SUCCESS:
      case ActionTypes.UPDATE_STORE_LOCATION_SUCCESS:
        getStoreAddress();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { addressMode, storeAddress = {} } = state;

  const handleAddressEdit = (address) => {
    setState({ addressMode: "EDIT" });
    setState({
      storeAddress: address,
    });
  };

  const handleAddressEditCancel = (mode) => (e) => {
    setState({ addressMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateAddressCall = (address) => {
    setState({ addressMode: "PREVIEW" });
    updateStoreAddress(address);
  };

  const updateLocationCall = () => {
    updateStoreLocation();
  };

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<LocationOnRoundedIcon />} title="Shop Address">
          {addressMode === "PREVIEW" ? (
              <AddressPreview
                  isLoading={isLoading}
                  address={shopAddress}
                  editAddress={() => handleAddressEdit(shopAddress)}
                  cancelEdit={handleAddressEditCancel("PREVIEW")}
              />
          ) : (
              <AddressEditor
                  setState={(event, field) => setState({
                    ...state,
                    storeAddress: {
                      ...storeAddress,
                      [field]: event.target.value,
                    },
                  })}
                  storeAddress={storeAddress}
                  updateAddressCall={updateAddressCall}
                  cancelEdit={handleAddressEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
        <CustomSectionCreator isSubtle={true} icon={<LocationOnRoundedIcon />} title="Shop Location">
          {
              shopAddress.shoplocation &&
              (
                  <Box display="flex">
                    <Box overflow="hidden" width="80%" className="p-3">
                  <H5 fontSize={14} textAlign="left" ellipsis>
                    {shopAddress.shoplocation && <IconButton
                        size="small"
                        onClick={() => openGoogleMapLocation(shopAddress.shoplocation)}
                    >
                      Check shop location: <ArrowForwardIcon color="primary"/>
                    </IconButton>}
                  </H5>
                    </Box>
                    <Box>
                      {!isLoading
                          && (
                              !shopAddress.is_location_locked ?
                                  <ButtonComponent
                                      sx={{
                                        m: 2,
                                      }}
                                      type="primary"
                                      variant="contained"
                                      name="Update"
                                      size="small"
                                      onClick={() => updateLocationCall()}
                                  /> :
                                  <LockIcon color="primary" />
                          )
                      }
                    </Box>
                  </Box>)
          }
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfileAddress.propTypes = {
  history: PropTypes.object.isRequired,
  shopAddress: PropTypes.object.isRequired,
  updateStoreAddress: PropTypes.func.isRequired,
  getStoreAddress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const _StoreProfileAddress = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopAddress: store.storeAddress,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileAddress);

export { _StoreProfileAddress as default };
