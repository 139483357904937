/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import {
  Button, Typography, Divider, Chip,
} from "@mui/material";
import Slider from "react-slick/lib";
import { CustomLeftArrow, CustomRightArrow } from "components/Button/SliderButtons";
import { Slider6 } from "services/script";

const CategoryTitleSlider = (props) => {
  const {
    categoryList, match, history, refetch,
  } = props;

  const onClick = (path) => {
    history.push(path);
    if (refetch) {
      refetch();
    }
  };

  if (!categoryList || !categoryList.length) {
    return null;
  }
  const selectedCategory = categoryList.find((element) => match.params.category === element.id);

  return (
      <Slider {...Slider6}>

        <span style={{ paddingRight: "5px" }}>
          {!selectedCategory
            ? <Button variant="contained" size="medium" color="primary" onClick={() => onClick("/product")}>All</Button>
            : <Button variant="outlined" size="medium" color="secondary" onClick={() => onClick("/product")}>All</Button>}
        </span>
        {categoryList.map((category, index) => (
          <span style={{ paddingRight: "5px" }} key={`${index + 1}`}>
            {selectedCategory && category.id === selectedCategory.id
              ? (
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => onClick(`/product/category/${category.id}`)}
                >
                  {category.name}
                </Button>
              )
              : (
                <Button
                  variant="outlined"
                  size="medium"
                  color="secondary"
                  onClick={() => onClick(`/product/category/${category.id}`)}
                >
                  {category.name}
                </Button>
              )}

          </span>
        ))}

      </Slider>
  );
};

CategoryTitleSlider.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CategoryTitleSlider;
