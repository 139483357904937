import * as yup from "yup";

export const schema = yup.object().shape({
  shopname: yup.string().required("Name is required field").min(4, 'Minimum 4 characters'),
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  phone: yup.string().nullable(),
});

export const getTextfieldData1 = (shopname) => [
  {
    label: "Shop Name",
    value: shopname,
    onChangeField: "shopname",
  },
];

export const getTextfieldData2 = (name, phone, description) => [
  {
    label: "Contact (Owner)",
    value: name,
    onChangeField: "name",
  },
  {
    label: "Phone",
    value: phone,
    onChangeField: "phone",
  },
  {
    label: "Description: ",
    value: description,
    onChangeField: "description",
  },
];

export const getSelectFieldData = (leadstatus) => [
  {
    label: "Status",
    value: leadstatus,
    onChangeField: "leadstatus",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    variant: "contained",
    onClick: onFinish,
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    variant: "outlined",
    onClick: cancelEdit,
    type: "primary",
    disabled: false,
  },
];
