/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminShopHeader from "components/pages/admin/shops/AdminShopHeader";
import AdminOrderSearch from "components/widgets/admin/AdminOrderSearch";
import * as Actions from "actions";
import { checkUserAllowed } from "helpers/utils.js";
import CustomSectionCreator from "components/CustomSectionCreator";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


const AdminShopOrderList = (props) => {

    const {
        match, userDetails,
        adminResetSearchProducts
    } = props;

    const { shopId } = match.params;

    const [state] = useState({
        layoutColumns: 3,
    });

    useEffect(() => {
        adminResetSearchProducts();
        document.getElementById("color").setAttribute("href", "#");
    }, []);

    const renderOrderSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "orders")) {
            return <AdminOrderSearch {...props} shopId={shopId} isShop={true} colSize={state.layoutColumns} />;
        } else {
            return  <div>Not available </div>;
        }
    }

    return (
        <div>
            <AdminShopHeader {...props} />
            <CustomSectionCreator icon={<ShoppingCartIcon />} title="Orders">
            {
                renderOrderSearch()
            }
            </CustomSectionCreator>
        </div>
    );
};

AdminShopOrderList.defaultProps = {
  isLoading: false,
};

AdminShopOrderList.propTypes = {
    adminResetSearchProducts: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

const adminShopOrderList = connect(
  ({ adminShops, bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    storeList: adminShops.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopOrderList);

export default adminShopOrderList;
