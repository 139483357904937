// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.offers";
import {adminAddOfferInfoFailure, adminAddOfferInfoSuccess} from "actions/admin/admin.offers";


const querystring = require('querystring');

function* adminSearchOffers(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      name: action.name ? action.name : "",
      filter: action.filter ? action.filter : "",
      startdate: action.startdate ? action.startdate : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/offers/search` + query
    );
    yield put(Actions.adminSearchOffersSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchOffersFailure(error));
  }
}

function* adminShopOffers(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      shop: action.shopId ? action.shopId : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/offers/search` + query
    );
    yield put(Actions.adminShopOffersSuccess(response));
  } catch (error) {
    yield put(Actions.adminShopOffersFailure(error));
  }
}

function* adminGetOfferInfo(action: Object) {
  try {
    const response = yield API.get(
        `/admin/offers/get-info/` + action.payload
    );
    yield put(Actions.adminGetOfferInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetOfferInfoFailure(error));
  }
}


function* adminUpdateOfferStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/offers/update-status`,
         action.payload
    );
    yield put(Actions.adminUpdateOfferStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateOfferStatusFailure(error));
  }
}

function* adminAddOfferInfo(action: Object) {
  try {
    const response = yield API.post(
        `/admin/offers/add-offer`,
        action.payload
    );
    yield put(Actions.adminAddOfferInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddOfferInfoFailure(error));
  }
}


function* adminUpdateOfferInfo(action: Object) {
  try {
    const response = yield API.post(
        `/admin/offers/update-info`,
        action.payload
    );
    yield put(Actions.adminUpdateOfferInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateOfferInfoFailure(error));
  }
}

function* adminAddOfferQuickInfo(action: Object) {
  try {

    let locationquery = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      locationquery = {
        lat: latitude,
        lng: longitude,
      };
    }

    const searchQuery = querystring.stringify({
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;
    const response = yield API.post(
        `/admin/offers/add-offer-quick/`+ action.payload +  query,
        action.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminAddOfferInfoQuickSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddOfferInfoQuickFailure(error));
  }
}

export default function* OffersSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_SEARCH_OFFERS_START,  adminSearchOffers);
  yield takeLatest(ActionTypes.ADMIN_SHOP_OFFERS_START,  adminShopOffers);
  yield takeLatest(ActionTypes.ADMIN_GET_OFFER_INFO_START,  adminGetOfferInfo);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_OFFER_STATUS_START,   adminUpdateOfferStatus);
  yield takeLatest(ActionTypes.ADMIN_ADD_OFFER_START,             adminAddOfferInfo);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_OFFER_INFO_START,     adminUpdateOfferInfo);
  yield takeLatest(ActionTypes.ADMIN_ADD_OFFER_QUICK_START,       adminAddOfferQuickInfo);
}
