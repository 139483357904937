/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import { BottomNavigation, BottomNavigationAction } from "components/Atoms";
import {checkUserAllowed, getURI} from "helpers/utils";


import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import StorageIcon from "@mui/icons-material/Storage";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';

const BottomNavAdmin = (props) => {

  const {
    pathArray, userDetails
  } = props;

  const [tab, setTab] = React.useState("home");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const selectedTab = () => {

    switch (pathArray[1]) {
      case "me":
        setTab("account");
        break;
      case "shops":
        setTab("shops");
        break;
      case "products":
        setTab("products");
        break;
      case "orders":
        setTab("orders");
        break;
      case "sales":
        setTab("sales");
        break;
      case "home":
      default:
        setTab("home");
        break;
    }
  }

  React.useEffect(() => {
    selectedTab();
  });

  return (
    <BottomNavigation
      showLabels
      style={{
        zIndex: 10, position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "#fff", boxShadow: "0 -1px 1px 0 rgba(0,0,0,.1)",
      }}
      value={tab}
      onChange={handleChange}
    >
      {checkUserAllowed(userDetails.userlevel, "home") &&
          <BottomNavigationAction label="Home" value="home" icon={<HomeIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "home", "")}/>
      }
      {checkUserAllowed(userDetails.userlevel, "shops") &&
          <BottomNavigationAction label="Shops" value="shops" icon={<StoreIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "shops", "")}/>
      }
      {checkUserAllowed(userDetails.userlevel, "products") &&
          <BottomNavigationAction label="Products" value="products" icon={<StorageIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "products", "")}/>
      }
      {checkUserAllowed(userDetails.userlevel, "orders") &&
          <BottomNavigationAction label="Orders" value="orders" icon={<ShoppingCartRoundedIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "orders", "")}/>
      }
      {checkUserAllowed(userDetails.userlevel, "sales") &&
          <BottomNavigationAction label="Sales" value="sales" icon={<AddBusinessIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "sales", "")}/>
      }
      {checkUserAllowed(userDetails.userlevel, "subscriptions") &&
          <BottomNavigationAction label="Packages" value="subscriptions" icon={<ShoppingCartRoundedIcon/>} component={Link}
                                  to={getURI(process.env.REACT_APP_TYPE, "subscriptions", "")}/>
      }
      <BottomNavigationAction label="Account" value="account" icon={<PersonRoundedIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, "account", "")} />
    </BottomNavigation>

  );
};

BottomNavAdmin.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslate(BottomNavAdmin);
