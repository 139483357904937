/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Tab, Tabs, TabList, TabPanel,
} from "react-tabs";
import { Container, Grid, Box } from "@mui/material";

import ShopProductItem from "components/elements/user/products/ShopProductItem";

import * as Actions from "actions";

import { CURRENCY } from "config/constants";
import MockProductBox from "components/elements/main/MockProductBox";
import {checkProductInCart, isFeatureSupported, isMarketPlace, isShopSiteAndSubdomain} from "helpers/utils";
import CustomSectionCreator from "components/CustomSectionCreator";

const ShopProductsList = (props) => {

  const {
    products, currency, addProductToCart,
    addToWishlist, addToCompare, userGetNewProducts, usersearchProducts, shopId,
      cartItems, cartAddingPid, isCartLoading,
      isLoading
  } = props;

  useEffect(() => {
      usersearchProducts("", shopId, true, isShopSiteAndSubdomain(), "", "");
  }, []);

    const renderResults = () => (
      <Container>
          <CustomSectionCreator title="Our Collection" />
          <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
              { products.map((product, index) => (
                  <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                  <ShopProductItem
                    product={product}
                    isDirect={true}
                    link={isShopSiteAndSubdomain() ?
                        isFeatureSupported("shopreels") ? "/products/sort/popular?ls=1&li=" + index : ""
                        :
                        isFeatureSupported("shopreels") ?`/shop/${shopId}/products/sort/popular?ls=1&li=` + index : ""
                    }
                    currency={currency}
                    inCart={checkProductInCart(cartItems, product.id)}
                    isCartLoading={isCartLoading}
                    cartAddingPid={cartAddingPid}
                    shopId={shopId}
                    onAddToCompareClicked={() => addToCompare(product)}
                    onAddToWishlistClicked={() => addToWishlist(product)}
                    onAddToCartClicked={() => addProductToCart(product._id, shopId, !isMarketPlace())}
                    key={`${index + 1}`}
                  />
                  </Grid>
              ))}
          </Grid>
      </Container>
  );

    return (

        <>
            {isLoading ? <MockProductBox />
                : products.length > 0
                    ? renderResults()
                    : ""}

        </>
    );

};

ShopProductsList.defaultProps = {
    currency: CURRENCY,
};

ShopProductsList.propTypes = {
    currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  addToWishlist: PropTypes.func.isRequired,
    userGetNewProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Mapping Props and State.
const shopProductsList = connect(
  ({ userproducts, cartList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.searchproducts,
      cartItems: cartList.cart,
      isCartLoading: cartList.isLoading,
      cartAddingPid: cartList.cartAddingPid,
      currency: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductsList);

export { shopProductsList as default };
