import * as React from "react";


import "./AdminSalesFuture.scss";
import {Box, Card, IconButton, Typography} from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    getDistance, getLogo,
    openGoogleMapPlaceId, searchGoogleForInstagram
} from "helpers/utils";
import {H2, H4} from "components/CustomTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import PercentIcon from "@mui/icons-material/Percent";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";


const AdminSalesFuture = (props) => {
  const { lead = {}, userDetails, editCategoryImage, editCategoryInfo, addOfferInfo, editCategorySettings, className = "", isShopAdded } = props;
  const { id, name, images = [], is_popular, is_active, store_entry } = lead;

  return (
      <Box alignItems="center" width="100%" display="flex">
          <Box width="50%" >
              <H2 fontSize={18} ellipsis>
                  {lead.shopname}
              </H2>
              <H4 fontSize={14} onClick={() => openGoogleMapPlaceId(lead.place_id, lead.location)}>
                  {getDistance(lead.distance)}
                  <IconButton
                      size="small"
                  >
                      <ArrowForwardIcon color="primary" />
                  </IconButton>
              </H4>
              <IconButton
                  size="large"
                  onClick={() => searchGoogleForInstagram(lead.shopname)}
              >
                  <ManageSearchIcon color="primary"/>
              </IconButton>

              {lead.phone && <IconButton
                  size="small"
                  onClick={() => searchGoogleForInstagram(lead.phone)}
              >
                  <PersonSearchIcon color="primary"/>
              </IconButton>}

              {!isShopAdded && (<H4 fontSize={14}>
                  Add Offer:
                  <IconButton color="primary" size="small" onClick={() => addOfferInfo(lead)}>
                      <PercentIcon color="primary"/>
                  </IconButton>
              </H4>)
              }
          </Box>
          <Box width="25%">
              <H4 fontSize={14} ellipsis>
                  {lead.phone}
              </H4>
          </Box>
          <Box width="25%"  onClick={editCategoryInfo}>
              <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={() => editCategoryInfo(lead)}>
                  <LazyImage
                      src={getLogo(lead.logo)}
                      width={80}
                      height={80}
                      mx="auto"
                  />
              </HoverBox>
          </Box>
      </Box>
  );
};

export default AdminSalesFuture;
