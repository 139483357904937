/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "components/elements/main/index.scss";

import * as Actions from "actions";

import UserProductSearch from "components/widgets/user/UserProductSearch";
import { isLocalMarket } from "helpers/utils";

const UserProductsPageSearch = (props) => {

    const {
        userMainResetSearchProducts, bootupInfo
    } = props;

    const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : "");

  const [state] = useState({
    layoutColumns: 3,
  });

    useEffect(() => {
    //userMainResetSearchProducts();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

  return (
      <UserProductSearch {...props} marketId={marketId} colSize={state.layoutColumns} />
  );
};

UserProductsPageSearch.propTypes = {
    userMainResetSearchProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userProductsPageSearch = connect(
  ({ userMainProducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainProducts.reqStatus,
    isLoading: userMainProducts.isLoading,
    serviceError: userMainProducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductsPageSearch);

export default userProductsPageSearch;
