import {CallOutlined, ExpandMore, MailOutline, Menu} from "@mui/icons-material";
import {Box, Container, MenuItem, styled, Typography} from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase";
import Image from "components/BazaarImage";
import BazaarMenu from "components/BazaarMenu";
import { FlexBox } from "components/flex-box";
import NavLink from "components/nav-link/NavLink";
import { Span } from "components/Typography";
import { Link } from 'react-router-dom';
import { H2, H6 } from "components/CustomTypography";

import React, { useEffect, useState } from "react";
import { layoutConstant } from "utils/constants";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const TopbarWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "bgColor",
})(({ theme, bgColor }) => ({
  fontSize: 12,
  height: layoutConstant.topbarHeight,
  background: bgColor || theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  "& .topbarLeft": {
    "& .logob": {
      display: "block", // keep it on for now - sandeep
    },
    "& .title": {
      marginLeft: "10px",
    },
    "@media only screen and (max-width: 900px)": {
      "& .logob": {
        display: "block",
      },
      "& > *:not(.logob)": {
        display: "none",
      },
    },
  },
  "& .topbarRight": {
    "& .link": {
      paddingRight: 30,
      color: theme.palette.secondary.contrastText,
    },
    "@media only screen and (max-width: 900px)": {
      "& .link": {
        display: "none",
      },
    },
  },
  "& .menuItem": {
    minWidth: 100,
  },
  "& .marginRight": {
    marginRight: "1.25rem",
  },
  "& .handler": {
    height: layoutConstant.topbarHeight,
  },
  "& .smallRoundedImage": {
    height: 15,
    width: 25,
    borderRadius: 2,
  },
  "& .menuTitle": {
    fontSize: 12,
    marginLeft: "0.5rem",
    fontWeight: 600,
  },
}));

const Topbar = ({ bgColor, headerCart, isTitleTag, titleBanner, titleTag, locationTag }) => {
  const [currency, setCurrency] = useState(currencyList[0]);
  const [language, setLanguage] = useState(languageList[0]);

  const handleCurrencyClick = (curr) => () => setCurrency(curr);

  const handleLanguageClick = (lang) => () => setLanguage(lang);

  useEffect(() => {
    // get language from browser
    // console.log(navigator.language);
  }, []);
  return (
    <TopbarWrapper bgColor={bgColor}>
      <Container
        sx={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FlexBox className="topbarLeft" alignItems="center">
          <div className="logob">
            <Link to="/">
            {isTitleTag ?
                <H2 ml={2} textTransform="capitalize" fontSize={28} letterSpacing="-2px" fontWeight="bold" textAlign="left" color="default.grey">
                  {titleTag}
                </H2>
                :
              <Image
                  display="block"
                  height="40px"
                  src={titleBanner}
                  alt="logo"
              />}
            </Link>
          </div>
        </FlexBox>
        <FlexBox className="topbarRight" alignItems="center">
          <H6 fontSize={14}>{locationTag}</H6>
        {headerCart}
        </FlexBox>
      </Container>
    </TopbarWrapper>
  );
};

const languageList = [
  {
    title: "EN",
    imgUrl: "/assets/images/flags/usa.png",
  },
  {
    title: "BN",
    imgUrl: "/assets/images/flags/bd.png",
  },
  {
    title: "HN",
    imgUrl: "/assets/images/flags/in.png",
  },
];
const currencyList = [
  {
    title: "USD",
    imgUrl: "/assets/images/flags/usa.png",
  },
  {
    title: "EUR",
    imgUrl: "/assets/images/flags/uk.png",
  },
  {
    title: "BDT",
    imgUrl: "/assets/images/flags/bd.png",
  },
  {
    title: "INR",
    imgUrl: "/assets/images/flags/in.png",
  },
];
export default Topbar;
