import React from 'react';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ButtonComponent from "components/Button";
import { cameraTakePicture } from "utils/cordova";


const CameraButton = ({onChange}) => {

    const resolve = (value) => {
        onChange(value);
    };

    const reject = (value) => {};

    const startTakingPicture = () => {
        cameraTakePicture(resolve, reject);
    };

    const cordova = window.cordova;

    return (
        (cordova ?
                <label htmlFor="icon-button-file">
                    <ButtonComponent
                        sx={{
                            m: 2,
                            color: 'text.lightprimary',
                        }}
                        endIcon={<PhotoCamera />}
                        type="primary"
                        variant="contained"
                        name="Camera"
                        size="small"
                        onClick={startTakingPicture}
                        id="cameraGetPicture"
                    />
                </label>
                : ""
        )
    )
}


export default CameraButton;
