import * as yup from "yup";

export const schema = yup.object().shape({
  paymentoption: yup.string().nullable().required(),
  paymentkey: yup.string().nullable().when("paymentoption", { is: "smsbased", then: yup.string().required().matches(/^[0-9.]+$/, "Must be key") }),
  paymentupi: yup.string().nullable().when("paymentoption", { is: "upibased", then: yup.string().required().matches(/^[\w.-]+@[\w.-]+$/, "Must be valid UPI ID") }),
});

export const getTextfieldDataSMSbased = (paymentkey) => [
  {
    label: "Gateway Key",
    value: paymentkey,
    onChangeField: "paymentkey",
  },
];

export const getTextfieldDataUPIbased = (paymentupi) => [
  {
    label: "UPI",
    value: paymentupi,
    onChangeField: "paymentupi",
  },
];

export const getRadioButtonData = (paymentoption) => [
  {
    label: "Payment",
    value: paymentoption,
    onChangeField: "paymentoption",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    vairant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    vairant: "outlined",
    type: "primary",
    disabled: false,
  },
];
