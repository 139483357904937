/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";
import {
  CircularProgress, Container,
} from "@mui/material";
import * as Actions from "actions";
import ShopItemBox from "components/elements/user/shops/ShopItemBox";
import ShopProductSearch from "components/widgets/user/ShopProductSearch";

import { BreakPoints } from "config/constants";

const ShopView = (props) => {
  const [state, setState] = useState({
    hasMoreItems: true,
    breakPoint: "lg",
    isMobile: false,
    bShowSearchModal: false,
    searchvalue: "Search products",
    layoutColumns: 3,
  });
  const manageLayoutChange = () => {
    const docElement = document.documentElement;
    const currentWindowWidth = window.innerWidth || (docElement && docElement.offsetWidth);
    if (currentWindowWidth < BreakPoints.md) {
      setState({ ...state, isMobile: true });
    } else {
      setState({ ...state, isMobile: false });
    }
  };

  useEffect(() => {
    manageLayoutChange();
    props.usergetStoreList();
  }, []);

  const { isLoading, allshops, addStoreToFavlist } = props;
  const { shops = [] } = allshops || {};
  return (
    <Container>
      <ShopProductSearch {...props} colSize={state.layoutColumns} />
      <br />
      <div className="product-wrapper-grid">
        <div className="container-fluid">
          {isLoading ? <CircularProgress />
            : shops.length > 0
              ? (
                <div className="row">
                  { shops.map((shop, index) => (
                    <div className="product-card-container" key={`${index + 1}`}>
                      <ShopItemBox
                        shop={shop}
                        onAddToFavlistClicked={() => addStoreToFavlist(shop)}
                      />
                    </div>
                  ))}
                </div>
              )
              : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="" />
                    <h3>Sorry! Couldn`t find the shops you were looking For!!!    </h3>
                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue shopping</Link>
                  </div>
                </div>
              )}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
    </Container>
  );
};

ShopView.defaultProps = {
  isLoading: false,
};

ShopView.propTypes = {
  isLoading: PropTypes.bool,
  addStoreToFavlist: PropTypes.func.isRequired,
  allshops: PropTypes.objectOf(PropTypes.any).isRequired,
  usergetStoreList: PropTypes.func.isRequired,
};

// Mapping Props and State.
const ShopViewComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.categoryproducts,
    categoryList: userproducts.categoryList,
    searchkeywords: userproducts.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopView);

export default ShopViewComponent;
