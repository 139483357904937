export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGIN_PRE_OTP_START = "LOGIN_PRE_OTP_START";
export const LOGIN_PRE_OTP_SUCCESS = "LOGIN_PRE_OTP_SUCCESS";
export const LOGIN_PRE_OTP_FAILURE = "LOGIN_PRE_OTP_FAILURE";

export const LOGIN_POST_OTP_START = "LOGIN_POST_OTP_START";
export const LOGIN_POST_OTP_SUCCESS = "LOGIN_POST_OTP_SUCCESS";
export const LOGIN_POST_OTP_FAILURE = "LOGIN_POST_OTP_FAILURE";

export const SOCIAL_LOGIN_START = "SOCIAL_LOGIN_START";
export const SOCIAL_LOGIN_SUCCESS = "SOCIAL_LOGIN_SUCCESS";
export const SOCIAL_LOGIN_FAILURE = "SOCIAL_LOGIN_FAILURE";

export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const DELETE_ACCOUNT_START = "DELETE_ACCOUNT_START";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_FAILURE = "DELETE_ACCOUNT_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const GET_USER_PROFILE_START = "GET_USER_PROFILE_START";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE";

export const UPDATE_USER_PROFILE_UUID_START = "UPDATE_USER_PROFILE_UUID_START";
export const UPDATE_USER_PROFILE_UUID_SUCCESS = "UPDATE_USER_PROFILE_UUID_SUCCESS";
export const UPDATE_USER_PROFILE_UUID_FAILURE = "UPDATE_USER_PROFILE_UUID_FAILURE";

export const UPDATE_USER_PROFILE_LOCATION_START = "UPDATE_USER_PROFILE_LOCATION_START";
export const UPDATE_USER_PROFILE_LOCATION_SUCCESS = "UPDATE_USER_PROFILE_LOCATION_SUCCESS";
export const UPDATE_USER_PROFILE_LOCATION_FAILURE = "UPDATE_USER_PROFILE_LOCATION_FAILURE";

export const UPDATE_ADMIN_PROFILE_LOCATION_START = "UPDATE_ADMIN_PROFILE_LOCATION_START";
export const UPDATE_ADMIN_PROFILE_LOCATION_SUCCESS = "UPDATE_ADMIN_PROFILE_LOCATION_SUCCESS";
export const UPDATE_ADMIN_PROFILE_LOCATION_FAILURE = "UPDATE_ADMIN_PROFILE_LOCATION_FAILURE";

export const GET_SHOP_USER_PROFILE_START = "GET_SHOP_USER_PROFILE_START";
export const GET_SHOP_USER_PROFILE_SUCCESS = "GET_SHOP_USER_PROFILE_SUCCESS";
export const GET_SHOP_USER_PROFILE_FAILURE = "GET_SHOP_USER_PROFILE_FAILURE";

export const GET_SHOP_AND_USER_PROFILE_START = "GET_SHOP_AND_USER_PROFILE_START";
export const GET_SHOP_AND_USER_PROFILE_SUCCESS = "GET_SHOP_AND_USER_PROFILE_SUCCESS";
export const GET_SHOP_AND_USER_PROFILE_FAILURE = "GET_SHOP_AND_USER_PROFILE_FAILURE";

export const GET_SHOP_PROFILE_START = "GET_SHOP_PROFILE_START";
export const GET_SHOP_PROFILE_SUCCESS = "GET_SHOP_PROFILE_SUCCESS";
export const GET_SHOP_PROFILE_FAILURE = "GET_SHOP_PROFILE_FAILURE";

export const GET_MARKET_AND_USER_PROFILE_START = "GET_MARKET_AND_USER_PROFILE_START";
export const GET_MARKET_AND_USER_PROFILE_SUCCESS = "GET_MARKET_AND_USER_PROFILE_SUCCESS";
export const GET_MARKET_AND_USER_PROFILE_FAILURE = "GET_MARKET_AND_USER_PROFILE_FAILURE";

export const GET_MARKET_PROFILE_START = "GET_MARKET_PROFILE_START";
export const GET_MARKET_PROFILE_SUCCESS = "GET_MARKET_PROFILE_SUCCESS";
export const GET_MARKET_PROFILE_FAILURE = "GET_MARKET_PROFILE_FAILURE";

export const UPDATE_USER_PROFILE_START = "UPDATE_USER_PROFILE_START";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export const GET_BILLING_ADDRESS_START = "GET_BILLING_ADDRESS_START";
export const GET_BILLING_ADDRESS_SUCCESS = "GET_BILLING_ADDRESS_SUCCESS";
export const GET_BILLING_ADDRESS_FAILURE = "GET_BILLING_ADDRESS_FAILURE";

export const UPDATE_BILLING_ADDRESS_START = "UPDATE_BILLING_ADDRESS_START";
export const UPDATE_BILLING_ADDRESS_SUCCESS = "UPDATE_BILLING_ADDRESS_SUCCESS";
export const UPDATE_BILLING_ADDRESS_FAILURE = "UPDATE_BILLING_ADDRESS_FAILURE";

export const ADD_SHIPPING_ADDRESS_START = "ADD_SHIPPING_ADDRESS_START";
export const ADD_SHIPPING_ADDRESS_SUCCESS = "ADD_SHIPPING_ADDRESS_SUCCESS";
export const ADD_SHIPPING_ADDRESS_FAILURE = "ADD_SHIPPING_ADDRESS_FAILURE";

export const GET_SHIPPING_ADDRESS_START = "GET_SHIPPING_ADDRESS_START";
export const GET_SHIPPING_ADDRESS_SUCCESS = "GET_SHIPPING_ADDRESS_SUCCESS";
export const GET_SHIPPING_ADDRESS_FAILURE = "GET_SHIPPING_ADDRESS_FAILURE";

export const UPDATE_SHIPPING_ADDRESS_START = "UPDATE_SHIPPING_ADDRESS_START";
export const UPDATE_SHIPPING_ADDRESS_SUCCESS = "UPDATE_SHIPPING_ADDRESS_SUCCESS";
export const UPDATE_SHIPPING_ADDRESS_FAILURE = "UPDATE_SHIPPING_ADDRESS_FAILURE";

export const DELETE_SHIPPING_ADDRESS_START = "DELETE_SHIPPING_ADDRESS_START";
export const DELETE_SHIPPING_ADDRESS_SUCCESS = "DELETE_SHIPPING_ADDRESS_SUCCESS";
export const DELETE_SHIPPING_ADDRESS_FAILURE = "DELETE_SHIPPING_ADDRESS_FAILURE";

export const UPDATE_USER_PIC_START = "UPDATE_USER_PIC_START";
export const UPDATE_USER_PIC_SUCCESS = "UPDATE_USER_PIC_SUCCESS";
export const UPDATE_USER_PIC_FAILURE = "UPDATE_USER_PIC_FAILURE";

export const GET_CITIES_START = "GET_CITIES_START";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_CITY_LOCATIONS_START = "GET_CITY_LOCATIONS_START";
export const GET_CITY_LOCATIONS_SUCCESS = "GET_CITY_LOCATIONS_SUCCESS";
export const GET_CITY_LOCATIONS_FAILURE = "GET_CITY_LOCATIONS_FAILURE";

export const COMMON_CREATE_PAYMENT_ORDER_START = "COMMON_CREATE_PAYMENT_ORDER_START";
export const COMMON_CREATE_PAYMENT_ORDER_SUCCESS = "COMMON_CREATE_PAYMENT_ORDER_SUCCESS";
export const COMMON_CREATE_PAYMENT_ORDER_FAILURE = "COMMON_CREATE_PAYMENT_ORDER_FAILURE";

export const COMMON_CHECK_PAYMENT_VERIFY_START = "COMMON_CHECK_PAYMENT_VERIFY_START";
export const COMMON_CHECK_PAYMENT_VERIFY_SUCCESS = "COMMON_CHECK_PAYMENT_VERIFY_SUCCESS";
export const COMMON_CHECK_PAYMENT_VERIFY_FAILURE = "COMMON_CHECK_PAYMENT_VERIFY_FAILURE";

export const GET_ADDRESS_SUGGESTIONS_START = "GET_ADDRESS_SUGGESTIONS_START";
export const GET_ADDRESS_SUGGESTIONS_SUCCESS = "GET_ADDRESS_SUGGESTIONS_SUCCESS";
export const GET_ADDRESS_SUGGESTIONS_FAILURE = "GET_ADDRESS_SUGGESTIONS_FAILURE";

export const ADD_APP_ERROR_LOG_START = "ADD_APP_ERROR_LOG_START";
export const ADD_APP_ERROR_LOG_SUCCESS = "ADD_APP_ERROR_LOG_SUCCESS";
export const ADD_APP_ERROR_LOG_FAILURE = "ADD_APP_ERROR_LOG_FAILURE";


// Seller

export const SELLER_ORDERS_RESET = "SELLER_ORDERS_RESET";
export const SELLER_PRODUCTS_RESET = "SELLER_PRODUCTS_RESET";
export const SELLER_STORE_RESET = "SELLER_STORE_RESET";

export const GET_COMMON_ORDERS_START = "GET_COMMON_ORDERS_START";
export const GET_COMMON_ORDERS_SUCCESS = "GET_COMMON_ORDERS_SUCCESS";
export const GET_COMMON_ORDERS_FAILURE = "GET_COMMON_ORDERS_FAILURE";

export const GET_STORE_ORDER_CATEGORIES_START = "GET_STORE_ORDER_CATEGORIES_START";
export const GET_STORE_ORDER_CATEGORIES_SUCCESS = "GET_STORE_ORDER_CATEGORIES_SUCCESS";
export const GET_STORE_ORDER_CATEGORIES_FAILURE = "GET_STORE_ORDER_CATEGORIES_FAILURE";

export const GET_STORE_ORDERS_START = "GET_STORE_ORDERS_START";
export const GET_STORE_ORDERS_SUCCESS = "GET_STORE_ORDERS_SUCCESS";
export const GET_STORE_ORDERS_FAILURE = "GET_STORE_ORDERS_FAILURE";

export const GET_ORDER_DETAIL_START = "GET_ORDER_DETAIL_START";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE";

export const ADD_ORDER_START = "ADD_ORDER_START";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAILURE = "ADD_ORDER_FAILURE";

export const UPDATE_ORDER_STATUS_START = "UPDATE_ORDER_STATUS_START";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";
export const UPDATE_ORDER_STATUS_FAILURE = "UPDATE_ORDER_STATUS_FAILURE";

export const REMOVE_ORDER_START = "REMOVE_ORDER_START";
export const REMOVE_ORDER_SUCCESS = "REMOVE_ORDER_SUCCESS";
export const REMOVE_ORDER_FAILURE = "REMOVE_ORDER_FAILURE";

export const ADD_ORDER_UPC_START = "ADD_ORDER_UPC_START";
export const ADD_ORDER_UPC_SUCCESS = "ADD_ORDER_UPC_SUCCESS";
export const ADD_ORDER_UPC_FAILURE = "ADD_ORDER_UPC_FAILURE";

export const SEARCH_ORDERS_START = "SEARCH_ORDERS_START";
export const SEARCH_ORDERS_SUCCESS = "SEARCH_ORDERS_SUCCESS";
export const SEARCH_ORDERS_FAILURE = "SEARCH_ORDERS_FAILURE";

export const SEARCH_ORDERS_KEYWORDS_START = "SEARCH_ORDERS_KEYWORDS_START";
export const SEARCH_ORDERS_KEYWORDS_SUCCESS = "SEARCH_ORDERS_KEYWORDS_SUCCESS";
export const SEARCH_ORDERS_KEYWORDS_FAILURE = "SEARCH_ORDERS_KEYWORDS_FAILURE";

export const CHECK_ORDER_START = "CHECK_ORDER_START";
export const CHECK_ORDER_SUCCESS = "CHECK_ORDER_SUCCESS";
export const CHECK_ORDER_FAILURE = "CHECK_ORDER_FAILURE";

export const GET_ACTIVE_CATEGORIES_START = "GET_ACTIVE_CATEGORIES_START";
export const GET_ACTIVE_CATEGORIES_SUCCESS = "GET_ACTIVE_CATEGORIES_SUCCESS";
export const GET_ACTIVE_CATEGORIES_FAILURE = "GET_ACTIVE_CATEGORIES_FAILURE";

export const GET_COMMON_PRODUCTS_START = "GET_COMMON_PRODUCTS_START";
export const GET_COMMON_PRODUCTS_SUCCESS = "GET_COMMON_PRODUCTS_SUCCESS";
export const GET_COMMON_PRODUCTS_FAILURE = "GET_COMMON_PRODUCTS_FAILURE";

export const GET_STORE_PRODUCT_CATEGORIES_START = "GET_STORE_PRODUCT_CATEGORIES_START";
export const GET_STORE_PRODUCT_CATEGORIES_SUCCESS = "GET_STORE_PRODUCT_CATEGORIES_SUCCESS";
export const GET_STORE_PRODUCT_CATEGORIES_FAILURE = "GET_STORE_PRODUCT_CATEGORIES_FAILURE";

export const GET_STORE_PRODUCTS_START = "GET_STORE_PRODUCTS_START";
export const GET_STORE_PRODUCTS_SUCCESS = "GET_STORE_PRODUCTS_SUCCESS";
export const GET_STORE_PRODUCTS_FAILURE = "GET_STORE_PRODUCTS_FAILURE";

export const ADD_PRODUCT_START = "ADD_PRODUCT_START";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const UPDATE_PRODUCT_START = "UPDATE_PRODUCT_START";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILURE = "UPDATE_PRODUCT_FAILURE";

export const REMOVE_PRODUCT_START = "REMOVE_PRODUCT_START";
export const REMOVE_PRODUCT_SUCCESS = "REMOVE_PRODUCT_SUCCESS";
export const REMOVE_PRODUCT_FAILURE = "REMOVE_PRODUCT_FAILURE";

export const ADD_PRODUCT_UPC_START = "ADD_PRODUCT_UPC_START";
export const ADD_PRODUCT_UPC_SUCCESS = "ADD_PRODUCT_UPC_SUCCESS";
export const ADD_PRODUCT_UPC_FAILURE = "ADD_PRODUCT_UPC_FAILURE";

export const SEARCH_PRODUCTS_START = "SEARCH_PRODUCTS_START";
export const SEARCH_PRODUCTS_SUCCESS = "SEARCH_PRODUCTS_SUCCESS";
export const SEARCH_PRODUCTS_FAILURE = "SEARCH_PRODUCTS_FAILURE";

export const SEARCH_KEYWORDS_START = "SEARCH_KEYWORDS_START";
export const SEARCH_KEYWORDS_SUCCESS = "SEARCH_KEYWORDS_SUCCESS";
export const SEARCH_KEYWORDS_FAILURE = "SEARCH_KEYWORDS_FAILURE";

export const CHECK_PRODUCT_START = "CHECK_PRODUCT_START";
export const CHECK_PRODUCT_SUCCESS = "CHECK_PRODUCT_SUCCESS";
export const CHECK_PRODUCT_FAILURE = "CHECK_PRODUCT_FAILURE";

export const ADD_PRIVATE_PRODUCT_START = "ADD_PRIVATE_PRODUCT_START";
export const ADD_PRIVATE_PRODUCT_SUCCESS = "ADD_PRIVATE_PRODUCT_SUCCESS";
export const ADD_PRIVATE_PRODUCT_FAILURE = "ADD_PRIVATE_PRODUCT_FAILURE";

export const UPDATE_PRIVATE_PRODUCT_START = "UPDATE_PRIVATE_PRODUCT_START";
export const UPDATE_PRIVATE_PRODUCT_SUCCESS = "UPDATE_PRIVATE_PRODUCT_SUCCESS";
export const UPDATE_PRIVATE_PRODUCT_FAILURE = "UPDATE_PRIVATE_PRODUCT_FAILURE";

export const REMOVE_PRIVATE_PRODUCT_START = "REMOVE_PRIVATE_PRODUCT_START";
export const REMOVE_PRIVATE_PRODUCT_SUCCESS = "REMOVE_PRIVATE_PRODUCT_SUCCESS";
export const REMOVE_PRIVATE_PRODUCT_FAILURE = "REMOVE_PRIVATE_PRODUCT_FAILURE";

export const ADD_PRIVATE_PRODUCT_IMAGE_START = "ADD_PRIVATE_PRODUCT_IMAGE_START";
export const ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS = "ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS";
export const ADD_PRIVATE_PRODUCT_IMAGE_FAILURE = "ADD_PRIVATE_PRODUCT_IMAGE_FAILURE";

export const REMOVE_PRIVATE_PRODUCT_IMAGE_START = "REMOVE_PRIVATE_PRODUCT_IMAGE_START";
export const REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS = "REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS";
export const REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE = "REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE";

export const ADD_PRIVATE_PRODUCT_IMAGE_QUICK_START = "ADD_PRIVATE_PRODUCT_IMAGE_QUICK_START";
export const ADD_PRIVATE_PRODUCT_IMAGE_QUICK_SUCCESS = "ADD_PRIVATE_PRODUCT_IMAGE_QUICK_SUCCESS";
export const ADD_PRIVATE_PRODUCT_IMAGE_QUICK_FAILURE = "ADD_PRIVATE_PRODUCT_IMAGE_QUICK_FAILURE";

export const ADD_PRIVATE_CATEGORY_START = "ADD_PRIVATE_CATEGORY_START";
export const ADD_PRIVATE_CATEGORY_SUCCESS = "ADD_PRIVATE_CATEGORY_SUCCESS";
export const ADD_PRIVATE_CATEGORY_FAILURE = "ADD_PRIVATE_CATEGORY_FAILURE";

export const UPDATE_PRIVATE_CATEGORY_START = "UPDATE_PRIVATE_CATEGORY_START";
export const UPDATE_PRIVATE_CATEGORY_SUCCESS = "UPDATE_PRIVATE_CATEGORY_SUCCESS";
export const UPDATE_PRIVATE_CATEGORY_FAILURE = "UPDATE_PRIVATE_CATEGORY_FAILURE";

export const UPDATE_PRIVATE_CATEGORY_SETTING_START = "UPDATE_PRIVATE_CATEGORY_SETTING_START";
export const UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS = "UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS";
export const UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE = "UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE";

export const REMOVE_PRIVATE_CATEGORY_START = "REMOVE_PRIVATE_CATEGORY_START";
export const REMOVE_PRIVATE_CATEGORY_SUCCESS = "REMOVE_PRIVATE_CATEGORY_SUCCESS";
export const REMOVE_PRIVATE_CATEGORY_FAILURE = "REMOVE_PRIVATE_CATEGORY_FAILURE";

export const ADD_PRIVATE_CATEGORY_IMAGE_START = "ADD_PRIVATE_CATEGORY_IMAGE_START";
export const ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS = "ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS";
export const ADD_PRIVATE_CATEGORY_IMAGE_FAILURE = "ADD_PRIVATE_CATEGORY_IMAGE_FAILURE";

export const REMOVE_PRIVATE_CATEGORY_IMAGE_START = "REMOVE_PRIVATE_CATEGORY_IMAGE_START";
export const REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS = "REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS";
export const REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE = "REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE";

export const GET_USER_LOCATION_START = "GET_USER_LOCATION_START";
export const GET_USER_LOCATION_SUCCESS = "GET_USER_LOCATION_SUCCESS";
export const GET_USER_LOCATION_FAILURE = "GET_USER_LOCATION_FAILURE";

export const GET_PLACE_SUGGESTIONS_START = "GET_PLACE_SUGGESTIONS_START";
export const GET_PLACE_SUGGESTIONS_SUCCESS = "GET_PLACE_SUGGESTIONS_SUCCESS";
export const GET_PLACE_SUGGESTIONS_FAILURE = "GET_PLACE_SUGGESTIONS_FAILURE";

export const GET_STORES_FOR_LOCATION_START = "GET_STORES_FOR_LOCATION_START";
export const GET_STORES_FOR_LOCATION_SUCCESS = "GET_STORES_FOR_LOCATION_SUCCESS";
export const GET_STORES_FOR_LOCATION_FAILURE = "GET_STORES_FOR_LOCATION_FAILURE";

export const ADD_SHOP_TO_ACCOUNT_START = "ADD_SHOP_TO_ACCOUNT_START";
export const ADD_SHOP_TO_ACCOUNT_SUCCESS = "ADD_SHOP_TO_ACCOUNT_SUCCESS";
export const ADD_SHOP_TO_ACCOUNT_FAILURE = "ADD_SHOP_TO_ACCOUNT_FAILURE";

export const GET_DOMAIN_START = "GET_DOMAIN_START";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAILURE = "GET_DOMAIN_FAILURE";

export const CHECK_DOMAIN_AVAILABILITY_START = "CHECK_DOMAIN_AVAILABILITY_START";
export const CHECK_DOMAIN_AVAILABILITY_SUCCESS = "CHECK_DOMAIN_AVAILABILITY_SUCCESS";
export const CHECK_DOMAIN_AVAILABILITY_FAILURE = "CHECK_DOMAIN_AVAILABILITY_FAILURE";

export const UPDATE_DOMAIN_START = "UPDATE_DOMAIN_START";
export const UPDATE_DOMAIN_SUCCESS = "UPDATE_DOMAIN_SUCCESS";
export const UPDATE_DOMAIN_FAILURE = "UPDATE_DOMAIN_FAILURE";

export const GET_STORE_PROFILE_START = "GET_STORE_PROFILE_START";
export const GET_STORE_PROFILE_SUCCESS = "GET_STORE_PROFILE_SUCCESS";
export const GET_STORE_PROFILE_FAILURE = "GET_STORE_PROFILE_FAILURE";

export const UPDATE_STORE_PROFILE_START = "UPDATE_STORE_PROFILE_START";
export const UPDATE_STORE_PROFILE_SUCCESS = "UPDATE_STORE_PROFILE_SUCCESS";
export const UPDATE_STORE_PROFILE_FAILURE = "UPDATE_STORE_PROFILE_FAILURE";

export const GET_STORE_SERVICE_START = "GET_STORE_SERVICE_START";
export const GET_STORE_SERVICE_SUCCESS = "GET_STORE_SERVICE_SUCCESS";
export const GET_STORE_SERVICE_FAILURE = "GET_STORE_SERVICE_FAILURE";

export const UPDATE_STORE_SERVICE_START = "UPDATE_STORE_SERVICE_START";
export const UPDATE_STORE_SERVICE_SUCCESS = "UPDATE_STORE_SERVICE_SUCCESS";
export const UPDATE_STORE_SERVICE_FAILURE = "UPDATE_STORE_SERVICE_FAILURE";

export const GET_STORE_RETURNS_START = "GET_STORE_RETURNS_START";
export const GET_STORE_RETURNS_SUCCESS = "GET_STORE_RETURNS_SUCCESS";
export const GET_STORE_RETURNS_FAILURE = "GET_STORE_RETURNS_FAILURE";

export const UPDATE_STORE_RETURNS_START = "UPDATE_STORE_RETURNS_START";
export const UPDATE_STORE_RETURNS_SUCCESS = "UPDATE_STORE_RETURNS_SUCCESS";
export const UPDATE_STORE_RETURNS_FAILURE = "UPDATE_STORE_RETURNS_FAILURE";

export const GET_STORE_PAYMENT_START = "GET_STORE_PAYMENT_START";
export const GET_STORE_PAYMENT_SUCCESS = "GET_STORE_PAYMENT_SUCCESS";
export const GET_STORE_PAYMENT_FAILURE = "GET_STORE_PAYMENT_FAILURE";

export const UPDATE_STORE_PAYMENT_START = "UPDATE_STORE_PAYMENT_START";
export const UPDATE_STORE_PAYMENT_SUCCESS = "UPDATE_STORE_PAYMENT_SUCCESS";
export const UPDATE_STORE_PAYMENT_FAILURE = "UPDATE_STORE_PAYMENT_FAILURE";

export const UPDATE_STORE_PAYMENT_QRCODE_START = "UPDATE_STORE_PAYMENT_QRCODE_START";
export const UPDATE_STORE_PAYMENT_QRCODE_SUCCESS = "UPDATE_STORE_PAYMENT_QRCODE_SUCCESS";
export const UPDATE_STORE_PAYMENT_QRCODE_FAILURE = "UPDATE_STORE_PAYMENT_QRCODE_FAILURE";

export const GET_STORE_TAX_START = "GET_STORE_TAX_START";
export const GET_STORE_TAX_SUCCESS = "GET_STORE_TAX_SUCCESS";
export const GET_STORE_TAX_FAILURE = "GET_STORE_TAX_FAILURE";

export const UPDATE_STORE_TAX_START = "UPDATE_STORE_TAX_START";
export const UPDATE_STORE_TAX_SUCCESS = "UPDATE_STORE_TAX_SUCCESS";
export const UPDATE_STORE_TAX_FAILURE = "UPDATE_STORE_TAX_FAILURE";

export const GET_STORE_ADDRESS_START = "GET_STORE_ADDRESS_START";
export const GET_STORE_ADDRESS_SUCCESS = "GET_STORE_ADDRESS_SUCCESS";
export const GET_STORE_ADDRESS_FAILURE = "GET_STORE_ADDRESS_FAILURE";

export const UPDATE_STORE_ADDRESS_START = "UPDATE_STORE_ADDRESS_START";
export const UPDATE_STORE_ADDRESS_SUCCESS = "UPDATE_STORE_ADDRESS_SUCCESS";
export const UPDATE_STORE_ADDRESS_FAILURE = "UPDATE_STORE_ADDRESS_FAILURE";

export const UPDATE_STORE_LOCATION_START = "UPDATE_STORE_LOCATION_START";
export const UPDATE_STORE_LOCATION_SUCCESS = "UPDATE_STORE_LOCATION_SUCCESS";
export const UPDATE_STORE_LOCATION_FAILURE = "UPDATE_STORE_LOCATION_FAILURE";

export const GET_STORE_SOCIAL_START = "GET_STORE_SOCIAL_START";
export const GET_STORE_SOCIAL_SUCCESS = "GET_STORE_SOCIAL_SUCCESS";
export const GET_STORE_SOCIAL_FAILURE = "GET_STORE_SOCIAL_FAILURE";

export const UPDATE_STORE_SOCIAL_START = "UPDATE_STORE_SOCIAL_START";
export const UPDATE_STORE_SOCIAL_SUCCESS = "UPDATE_STORE_SOCIAL_SUCCESS";
export const UPDATE_STORE_SOCIAL_FAILURE = "UPDATE_STORE_SOCIAL_FAILURE";

export const GET_STORE_IMAGES_START = "GET_STORE_IMAGES_START";
export const GET_STORE_IMAGES_SUCCESS = "GET_STORE_IMAGES_SUCCESS";
export const GET_STORE_IMAGES_FAILURE = "GET_STORE_IMAGES_FAILURE";

export const ADD_STORE_IMAGE_START = "ADD_STORE_IMAGE_START";
export const ADD_STORE_IMAGE_SUCCESS = "ADD_STORE_IMAGE_SUCCESS";
export const ADD_STORE_IMAGE_FAILURE = "ADD_STORE_IMAGE_FAILURE";

export const REMOVE_STORE_IMAGE_START = "REMOVE_STORE_IMAGE_START";
export const REMOVE_STORE_IMAGE_SUCCESS = "REMOVE_STORE_IMAGE_SUCCESS";
export const REMOVE_STORE_IMAGE_FAILURE = "REMOVE_STORE_IMAGE_FAILURE";

export const UPDATE_STORE_LOGO_START = "UPDATE_STORE_LOGO_START";
export const UPDATE_STORE_LOGO_SUCCESS = "UPDATE_STORE_LOGO_SUCCESS";
export const UPDATE_STORE_LOGO_FAILURE = "UPDATE_STORE_LOGO_FAILURE";

//seller offers

export const SELLER_SEARCH_OFFERS_START = "SELLER_SEARCH_OFFERS_START";
export const SELLER_SEARCH_OFFERS_SUCCESS = "SELLER_SEARCH_OFFERS_SUCCESS";
export const SELLER_SEARCH_OFFERS_FAILURE = "SELLER_SEARCH_OFFERS_FAILURE";

export const SELLER_GET_OFFER_INFO_START = "SELLER_GET_OFFER_INFO_START";
export const SELLER_GET_OFFER_INFO_SUCCESS = "SELLER_GET_OFFER_INFO_SUCCESS";
export const SELLER_GET_OFFER_INFO_FAILURE = "SELLER_GET_OFFER_INFO_FAILURE";

export const SELLER_ADD_OFFER_START = "SELLER_ADD_OFFER_START";
export const SELLER_ADD_OFFER_SUCCESS = "SELLER_ADD_OFFER_SUCCESS";
export const SELLER_ADD_OFFER_FAILURE = "SELLER_ADD_OFFER_FAILURE";

export const SELLER_UPDATE_OFFER_START = "SELLER_UPDATE_OFFER_START";
export const SELLER_UPDATE_OFFER_SUCCESS = "SELLER_UPDATE_OFFER_SUCCESS";
export const SELLER_UPDATE_OFFER_FAILURE = "SELLER_UPDATE_OFFER_FAILURE";

export const SELLER_ADD_OFFER_IMAGE_START = "SELLER_ADD_OFFER_IMAGE_START";
export const SELLER_ADD_OFFER_IMAGE_SUCCESS = "SELLER_ADD_OFFER_IMAGE_SUCCESS";
export const SELLER_ADD_OFFER_IMAGE_FAILURE = "SELLER_ADD_OFFER_IMAGE_FAILURE";

export const SELLER_REMOVE_OFFER_IMAGE_START = "SELLER_REMOVE_OFFER_IMAGE_START";
export const SELLER_REMOVE_OFFER_IMAGE_SUCCESS = "SELLER_REMOVE_OFFER_IMAGE_SUCCESS";
export const SELLER_REMOVE_OFFER_IMAGE_FAILURE = "SELLER_REMOVE_OFFER_IMAGE_FAILURE";

//seller subscriptions/packages

export const GET_SELLER_ACTIVE_SUBSCRIPTIONS_START = "GET_SELLER_ACTIVE_SUBSCRIPTIONS_START";
export const GET_SELLER_ACTIVE_SUBSCRIPTIONS_SUCCESS = "GET_SELLER_ACTIVE_SUBSCRIPTIONS_SUCCESS";
export const GET_SELLER_ACTIVE_SUBSCRIPTIONS_FAILURE = "GET_SELLER_ACTIVE_SUBSCRIPTIONS_FAILURE";

export const GET_AVAILABLE_SUBSCRIPTIONS_START = "GET_AVAILABLE_SUBSCRIPTIONS_START";
export const GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS = "GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS";
export const GET_AVAILABLE_SUBSCRIPTIONS_FAILURE = "GET_AVAILABLE_SUBSCRIPTIONS_FAILURE";

export const GET_SUBSCRIPTIONS_CART_START = "GET_SUBSCRIPTIONS_CART_START";
export const GET_SUBSCRIPTIONS_CART_SUCCESS = "GET_SUBSCRIPTIONS_CART_SUCCESS";
export const GET_SUBSCRIPTIONS_CART_FAILURE = "GET_SUBSCRIPTIONS_CART_FAILURE";

export const ADD_SUBSCRIPTION_TO_CART_START = "ADD_SUBSCRIPTION_TO_CART_START";
export const ADD_SUBSCRIPTION_TO_CART_SUCCESS = "ADD_SUBSCRIPTION_TO_CART_SUCCESS";
export const ADD_SUBSCRIPTION_TO_CART_FAILURE = "ADD_SUBSCRIPTION_TO_CART_FAILURE";

export const REMOVE_SUBSCRIPTION_FROM_CART_START = "REMOVE_SUBSCRIPTION_FROM_CART_START";
export const REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS = "REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS";
export const REMOVE_SUBSCRIPTION_FROM_CART_FAILURE = "REMOVE_SUBSCRIPTION_FROM_CART_FAILURE";

export const CHECKOUT_SUBSCRIPTION_ORDER_START = "CHECKOUT_SUBSCRIPTION_ORDER_START";
export const CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS = "CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS";
export const CHECKOUT_SUBSCRIPTION_ORDER_FAILURE = "CHECKOUT_SUBSCRIPTION_ORDER_FAILURE";

export const GENERATE_SUBSCRIPTION_ORDER_START = "GENERATE_SUBSCRIPTION_ORDER_START";
export const GENERATE_SUBSCRIPTION_ORDER_SUCCESS = "GENERATE_SUBSCRIPTION_ORDER_SUCCESS";
export const GENERATE_SUBSCRIPTION_ORDER_FAILURE = "GENERATE_SUBSCRIPTION_ORDER_FAILURE";

export const GET_SUBSCRIPTION_ORDERS_START = "GET_SUBSCRIPTION_ORDERS_START";
export const GET_SUBSCRIPTION_ORDERS_SUCCESS = "GET_SUBSCRIPTION_ORDERS_SUCCESS";
export const GET_SUBSCRIPTION_ORDERS_FAILURE = "GET_SUBSCRIPTION_ORDERS_FAILURE";

export const GET_SUBSCRIPTION_ACTIVE_ORDERS_START = "GET_SUBSCRIPTION_ACTIVE_ORDERS_START";
export const GET_SUBSCRIPTION_ACTIVE_ORDERS_SUCCESS = "GET_SUBSCRIPTION_ACTIVE_ORDERS_SUCCESS";
export const GET_SUBSCRIPTION_ACTIVE_ORDERS_FAILURE = "GET_SUBSCRIPTION_ACTIVE_ORDERS_FAILURE";

export const GET_SUBSCRIPTION_ORDER_DETAIL_START = "GET_SUBSCRIPTION_ORDER_DETAIL_START";
export const GET_SUBSCRIPTION_ORDER_DETAIL_SUCCESS = "GET_SUBSCRIPTION_ORDER_DETAIL_SUCCESS";
export const GET_SUBSCRIPTION_ORDER_DETAIL_FAILURE = "GET_SUBSCRIPTION_ORDER_DETAIL_FAILURE";


// User base

export const USER_LOGIN_START = "USER_LOGIN_START";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_SOCIAL_LOGIN_START = "USER_SOCIAL_LOGIN_START";
export const USER_SOCIAL_LOGIN_SUCCESS = "USER_SOCIAL_LOGIN_SUCCESS";
export const USER_SOCIAL_LOGIN_FAILURE = "USER_SOCIAL_LOGIN_FAILURE";

export const USER_LOGOUT_START = "USER_LOGOUT_START";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const USER_REGISTER_START = "USER_REGISTER_START";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const USER_RESET_PASSWORD_START = "USER_RESET_PASSWORD_START";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";

export const USER_GET_USER_PROFILE_START = "USER_GET_USER_PROFILE_START";
export const USER_GET_USER_PROFILE_SUCCESS = "USER_GET_USER_PROFILE_SUCCESS";
export const USER_GET_USER_PROFILE_FAILURE = "USER_GET_USER_PROFILE_FAILURE";

export const USER_UPDATE_USER_PROFILE_START = "USER_UPDATE_USER_PROFILE_START";
export const USER_UPDATE_USER_PROFILE_SUCCESS = "USER_UPDATE_USER_PROFILE_SUCCESS";
export const USER_UPDATE_USER_PROFILE_FAILURE = "USER_UPDATE_USER_PROFILE_FAILURE";

export const USER_GET_BILLING_ADDRESS_START = "USER_GET_BILLING_ADDRESS_START";
export const USER_GET_BILLING_ADDRESS_SUCCESS = "USER_GET_BILLING_ADDRESS_SUCCESS";
export const USER_GET_BILLING_ADDRESS_FAILURE = "USER_GET_BILLING_ADDRESS_FAILURE";

export const USER_UPDATE_BILLING_ADDRESS_START = "USER_UPDATE_BILLING_ADDRESS_START";
export const USER_UPDATE_BILLING_ADDRESS_SUCCESS = "USER_UPDATE_BILLING_ADDRESS_SUCCESS";
export const USER_UPDATE_BILLING_ADDRESS_FAILURE = "USER_UPDATE_BILLING_ADDRESS_FAILURE";

export const USER_GET_USER_LOCATION_START = "USER_GET_USER_LOCATION_START";
export const USER_GET_USER_LOCATION_SUCCESS = "USER_GET_USER_LOCATION_SUCCESS";
export const USER_GET_USER_LOCATION_FAILURE = "USER_GET_USER_LOCATION_FAILURE";

// User products
export const USER_CART_WISHLIST_RESET = "USER_CART_WISHLIST_RESET";
export const USER_PRODUCTS_RESET = "USER_PRODUCTS_RESET";
export const USER_CATEGORY_PRODUCTS_RESET = "USER_CATEGORY_PRODUCTS_RESET";
export const USER_SEARCH_PRODUCTS_RESET = "USER_SEARCH_PRODUCTS_RESET";
export const USER_SEARCH_KEYWORDS_RESET = "USER_SEARCH_KEYWORDS_RESET";
export const USER_PRODUCT_DETAIL_RESET = "USER_PRODUCT_DETAIL_RESET";
export const USER_ORDERS_RESET = "USER_ORDERS_RESET";

export const USER_MAIN_WISHLIST_RESET = "USER_MAIN_WISHLIST_RESET";
export const USER_MAIN_PRODUCTS_RESET = "USER_MAIN_PRODUCTS_RESET";
export const USER_MAIN_CATEGORY_PRODUCTS_RESET = "USER_MAIN_CATEGORY_PRODUCTS_RESET";
export const USER_MAIN_SEARCH_PRODUCTS_RESET = "USER_MAIN_SEARCH_PRODUCTS_RESET";
export const USER_MAIN_SEARCH_KEYWORDS_RESET = "USER_MAIN_SEARCH_KEYWORDS_RESET";
export const USER_MAIN_SEARCH_COMBINED_RESET = "USER_MAIN_SEARCH_COMBINED_RESET";
export const USER_MAIN_SEARCH_KEYWORDS_COMBINED_RESET = "USER_MAIN_SEARCH_KEYWORDS_COMBINED_RESET";
export const USER_MAIN_PRODUCT_DETAIL_RESET = "USER_MAIN_PRODUCT_DETAIL_RESET";
export const USER_MAIN_ORDERS_RESET = "USER_MAIN_ORDERS_RESET";

// User shop
export const USER_SEARCH_PRODUCTS_START = "USER_SEARCH_PRODUCTS_START";
export const USER_SEARCH_PRODUCTS_SUCCESS = "USER_SEARCH_PRODUCTS_SUCCESS";
export const USER_SEARCH_PRODUCTS_FAILURE = "USER_SEARCH_PRODUCTS_FAILURE";

export const USER_SEARCH_KEYWORDS_START = "USER_SEARCH_KEYWORDS_START";
export const USER_SEARCH_KEYWORDS_SUCCESS = "USER_SEARCH_KEYWORDS_SUCCESS";
export const USER_SEARCH_KEYWORDS_FAILURE = "USER_SEARCH_KEYWORDS_FAILURE";

export const USER_GET_PRODUCT_INFO_START = "USER_GET_PRODUCT_INFO_START";
export const USER_GET_PRODUCT_INFO_SUCCESS = "USER_GET_PRODUCT_INFO_SUCCESS";
export const USER_GET_PRODUCT_INFO_FAILURE = "USER_GET_PRODUCT_INFO_FAILURE";

export const USER_GET_PRODUCT_PRICES_UPC_START = "USER_GET_PRODUCT_PRICES_UPC_START";
export const USER_GET_PRODUCT_PRICES_UPC_SUCCESS = "USER_GET_PRODUCT_PRICES_UPC_SUCCESS";
export const USER_GET_PRODUCT_PRICES_UPC_FAILURE = "USER_GET_PRODUCT_PRICES_UPC_FAILURE";

export const USER_GET_COMMON_PRODUCTS_START = "USER_GET_COMMON_PRODUCTS_START";
export const USER_GET_COMMON_PRODUCTS_SUCCESS = "USER_GET_COMMON_PRODUCTS_SUCCESS";
export const USER_GET_COMMON_PRODUCTS_FAILURE = "USER_GET_COMMON_PRODUCTS_FAILURE";

export const USER_GET_POPULAR_PRODUCTS_START = "USER_GET_POPULAR_PRODUCTS_START";
export const USER_GET_POPULAR_PRODUCTS_SUCCESS = "USER_GET_POPULAR_PRODUCTS_SUCCESS";
export const USER_GET_POPULAR_PRODUCTS_FAILURE = "USER_GET_POPULAR_PRODUCTS_FAILURE";

export const USER_GET_NEW_PRODUCTS_START = "USER_GET_NEW_PRODUCTS_START";
export const USER_GET_NEW_PRODUCTS_SUCCESS = "USER_GET_NEW_PRODUCTS_SUCCESS";
export const USER_GET_NEW_PRODUCTS_FAILURE = "USER_GET_NEW_PRODUCTS_FAILURE";

export const USER_GET_ACTIVE_CATEGORIES_START = "USER_GET_ACTIVE_CATEGORIES_START";
export const USER_GET_ACTIVE_CATEGORIES_SUCCESS = "USER_GET_ACTIVE_CATEGORIES_SUCCESS";
export const USER_GET_ACTIVE_CATEGORIES_FAILURE = "USER_GET_ACTIVE_CATEGORIES_FAILURE";

export const USER_GET_POPULAR_ACTIVE_CATEGORIES_START = "USER_GET_POPULAR_ACTIVE_CATEGORIES_START";
export const USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS = "USER_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS";
export const USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE = "USER_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE";

// User main
export const USER_MAIN_SEARCH_PRODUCTS_START = "USER_MAIN_SEARCH_PRODUCTS_START";
export const USER_MAIN_SEARCH_PRODUCTS_SUCCESS = "USER_MAIN_SEARCH_PRODUCTS_SUCCESS";
export const USER_MAIN_SEARCH_PRODUCTS_FAILURE = "USER_MAIN_SEARCH_PRODUCTS_FAILURE";

export const USER_MAIN_SEARCH_KEYWORDS_START = "USER_MAIN_SEARCH_KEYWORDS_START";
export const USER_MAIN_SEARCH_KEYWORDS_SUCCESS = "USER_MAIN_SEARCH_KEYWORDS_SUCCESS";
export const USER_MAIN_SEARCH_KEYWORDS_FAILURE = "USER_MAIN_SEARCH_KEYWORDS_FAILURE";

export const USER_MAIN_SEARCH_COMBINED_START = "USER_MAIN_SEARCH_COMBINED_START";
export const USER_MAIN_SEARCH_COMBINED_SUCCESS = "USER_MAIN_SEARCH_COMBINED_SUCCESS";
export const USER_MAIN_SEARCH_COMBINED_FAILURE = "USER_MAIN_SEARCH_COMBINED_FAILURE";

export const USER_MAIN_SEARCH_KEYWORDS_COMBINED_START = "USER_MAIN_SEARCH_KEYWORDS_COMBINED_START";
export const USER_MAIN_SEARCH_KEYWORDS_COMBINED_SUCCESS = "USER_MAIN_SEARCH_KEYWORDS_COMBINED_SUCCESS";
export const USER_MAIN_SEARCH_KEYWORDS_COMBINED_FAILURE = "USER_MAIN_SEARCH_KEYWORDS_COMBINED_FAILURE";

export const USER_MAIN_GET_PRODUCT_INFO_START = "USER_MAIN_GET_PRODUCT_INFO_START";
export const USER_MAIN_GET_PRODUCT_INFO_SUCCESS = "USER_MAIN_GET_PRODUCT_INFO_SUCCESS";
export const USER_MAIN_GET_PRODUCT_INFO_FAILURE = "USER_MAIN_GET_PRODUCT_INFO_FAILURE";

export const USER_MAIN_GET_PRODUCT_PRICES_UPC_START = "USER_MAIN_GET_PRODUCT_PRICES_UPC_START";
export const USER_MAIN_GET_PRODUCT_PRICES_UPC_SUCCESS = "USER_MAIN_GET_PRODUCT_PRICES_UPC_SUCCESS";
export const USER_MAIN_GET_PRODUCT_PRICES_UPC_FAILURE = "USER_MAIN_GET_PRODUCT_PRICES_UPC_FAILURE";

export const USER_MAIN_GET_COMMON_PRODUCTS_START = "USER_MAIN_GET_COMMON_PRODUCTS_START";
export const USER_MAIN_GET_COMMON_PRODUCTS_SUCCESS = "USER_MAIN_GET_COMMON_PRODUCTS_SUCCESS";
export const USER_MAIN_GET_COMMON_PRODUCTS_FAILURE = "USER_MAIN_GET_COMMON_PRODUCTS_FAILURE";

export const USER_MAIN_GET_POPULAR_PRODUCTS_START = "USER_MAIN_GET_POPULAR_PRODUCTS_START";
export const USER_MAIN_GET_POPULAR_PRODUCTS_SUCCESS = "USER_MAIN_GET_POPULAR_PRODUCTS_SUCCESS";
export const USER_MAIN_GET_POPULAR_PRODUCTS_FAILURE = "USER_MAIN_GET_POPULAR_PRODUCTS_FAILURE";

export const USER_MAIN_GET_NEW_PRODUCTS_START = "USER_MAIN_GET_NEW_PRODUCTS_START";
export const USER_MAIN_GET_NEW_PRODUCTS_SUCCESS = "USER_MAIN_GET_NEW_PRODUCTS_SUCCESS";
export const USER_MAIN_GET_NEW_PRODUCTS_FAILURE = "USER_MAIN_GET_NEW_PRODUCTS_FAILURE";

export const USER_MAIN_GET_ACTIVE_CATEGORIES_START = "USER_MAIN_GET_ACTIVE_CATEGORIES_START";
export const USER_MAIN_GET_ACTIVE_CATEGORIES_SUCCESS = "USER_MAIN_GET_ACTIVE_CATEGORIES_SUCCESS";
export const USER_MAIN_GET_ACTIVE_CATEGORIES_FAILURE = "USER_MAIN_GET_ACTIVE_CATEGORIES_FAILURE";

export const USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_START = "USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_START";
export const USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS = "USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS";
export const USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE = "USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE";

// User shop cart
export const USER_GET_CART_PRODUCTS_START = "USER_GET_CART_PRODUCTS_START";
export const USER_GET_CART_PRODUCTS_SUCCESS = "USER_GET_CART_PRODUCTS_SUCCESS";
export const USER_GET_CART_PRODUCTS_FAILURE = "USER_GET_CART_PRODUCTS_FAILURE";

export const USER_ADD_PRODUCT_TO_CART_START = "USER_ADD_PRODUCT_TO_CART_START";
export const USER_ADD_PRODUCT_TO_CART_SUCCESS = "USER_ADD_PRODUCT_TO_CART_SUCCESS";
export const USER_ADD_PRODUCT_TO_CART_FAILURE = "USER_ADD_PRODUCT_TO_CART_FAILURE";

export const USER_REMOVE_PRODUCT_FROM_CART_START = "USER_REMOVE_PRODUCT_FROM_CART_START";
export const USER_REMOVE_PRODUCT_FROM_CART_SUCCESS = "USER_REMOVE_PRODUCT_FROM_CART_SUCCESS";
export const USER_REMOVE_PRODUCT_FROM_CART_FAILURE = "USER_REMOVE_PRODUCT_FROM_CART_FAILURE";

export const USER_INCREMENT_PRODUCT_IN_CART_START = "USER_INCREMENT_PRODUCT_IN_CART_START";
export const USER_INCREMENT_PRODUCT_IN_CART_SUCCESS = "USER_INCREMENT_PRODUCT_IN_CART_SUCCESS";
export const USER_INCREMENT_PRODUCT_IN_CART_FAILURE = "USER_INCREMENT_PRODUCT_IN_CART_FAILURE";

export const USER_DECREMENT_PRODUCT_IN_CART_START = "USER_DECREMENT_PRODUCT_IN_CART_START";
export const USER_DECREMENT_PRODUCT_IN_CART_SUCCESS = "USER_DECREMENT_PRODUCT_IN_CART_SUCCESS";
export const USER_DECREMENT_PRODUCT_IN_CART_FAILURE = "USER_DECREMENT_PRODUCT_IN_CART_FAILURE";

export const USER_UPDATE_PRODUCT_SIZE_IN_CART_START = "USER_UPDATE_PRODUCT_SIZE_IN_CART_START";
export const USER_UPDATE_PRODUCT_SIZE_IN_CART_SUCCESS = "USER_UPDATE_PRODUCT_SIZE_IN_CART_SUCCESS";
export const USER_UPDATE_PRODUCT_SIZE_IN_CART_FAILURE = "USER_UPDATE_PRODUCT_SIZE_IN_CART_FAILURE";

export const USER_CHECKOUT_PRODUCT_ORDER_START = "USER_CHECKOUT_PRODUCT_ORDER_START";
export const USER_CHECKOUT_PRODUCT_ORDER_SUCCESS = "USER_CHECKOUT_PRODUCT_ORDER_SUCCESS";
export const USER_CHECKOUT_PRODUCT_ORDER_FAILURE = "USER_CHECKOUT_PRODUCT_ORDER_FAILURE";

export const USER_GENERATE_PRODUCT_ORDER_START = "USER_GENERATE_PRODUCT_ORDER_START";
export const USER_GENERATE_PRODUCT_ORDER_SUCCESS = "USER_GENERATE_PRODUCT_ORDER_SUCCESS";
export const USER_GENERATE_PRODUCT_ORDER_FAILURE = "USER_GENERATE_PRODUCT_ORDER_FAILURE";

// User main wishlist
export const USER_MAIN_GET_WISHLIST_PRODUCTS_START = "USER_MAIN_GET_WISHLIST_PRODUCTS_START";
export const USER_MAIN_GET_WISHLIST_PRODUCTS_SUCCESS = "USER_MAIN_GET_WISHLIST_PRODUCTS_SUCCESS";
export const USER_MAIN_GET_WISHLIST_PRODUCTS_FAILURE = "USER_MAIN_GET_WISHLIST_PRODUCTS_FAILURE";

export const USER_MAIN_ADD_PRODUCT_TO_WISHLIST_START = "USER_MAIN_ADD_PRODUCT_TO_WISHLIST_START";
export const USER_MAIN_ADD_PRODUCT_TO_WISHLIST_SUCCESS = "USER_MAIN_ADD_PRODUCT_TO_WISHLIST_SUCCESS";
export const USER_MAIN_ADD_PRODUCT_TO_WISHLIST_FAILURE = "USER_MAIN_ADD_PRODUCT_TO_WISHLIST_FAILURE";

export const USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_START = "USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_START";
export const USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = "USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS";
export const USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE = "USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE";

// User shop wishlist
export const USER_GET_SHOP_WISHLIST_PRODUCTS_START = "USER_GET_SHOP_WISHLIST_PRODUCTS_START";
export const USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS = "USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS";
export const USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE = "USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE";

export const USER_ADD_PRODUCT_TO_WISHLIST_START = "USER_ADD_PRODUCT_TO_WISHLIST_START";
export const USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS = "USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS";
export const USER_ADD_PRODUCT_TO_WISHLIST_FAILURE = "USER_ADD_PRODUCT_TO_WISHLIST_FAILURE";

export const USER_REMOVE_PRODUCT_FROM_WISHLIST_START = "USER_REMOVE_PRODUCT_FROM_WISHLIST_START";
export const USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS = "USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS";
export const USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE = "USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE";

export const USER_GET_LATEST_OFFERS_START = "USER_GET_LATEST_OFFERS_START";
export const USER_GET_LATEST_OFFERS_SUCCESS = "USER_GET_LATEST_OFFERS_SUCCESS";
export const USER_GET_LATEST_OFFERS_FAILURE = "USER_GET_LATEST_OFFERS_FAILURE";

export const USER_GET_OFFER_DETAIL_START = "USER_GET_OFFER_DETAIL_START";
export const USER_GET_OFFER_DETAIL_SUCCESS = "USER_GET_OFFER_DETAIL_SUCCESS";
export const USER_GET_OFFER_DETAIL_FAILURE = "USER_GET_OFFER_DETAIL_FAILURE";

// User Main Shops
export const USER_MAIN_SEARCH_SHOPS_RESET = "USER_MAIN_SEARCH_SHOPS_RESET";
export const USER_MAIN_SEARCH_SHOPS_KEYWORDS_RESET = "USER_MAIN_SEARCH_SHOPS_KEYWORDS_RESET";
export const USER_MAIN_SHOPS_RESET_ALL = "USER_MAIN_SHOPS_RESET_ALL";

export const USER_MAIN_GET_LOCATION_STORE_LIST_START = "USER_MAIN_GET_LOCATION_STORE_LIST_START";
export const USER_MAIN_GET_LOCATION_STORE_LIST_SUCCESS = "USER_MAIN_GET_LOCATION_STORE_LIST_SUCCESS";
export const USER_MAIN_GET_LOCATION_STORE_LIST_FAILURE = "USER_MAIN_GET_LOCATION_STORE_LIST_FAILURE";

export const USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_START = "USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_START";
export const USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_SUCCESS = "USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_SUCCESS";
export const USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_FAILURE = "USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_FAILURE";

export const USER_MAIN_GET_POPULAR_STORE_LIST_START = "USER_MAIN_GET_POPULAR_STORE_LIST_START";
export const USER_MAIN_GET_POPULAR_STORE_LIST_SUCCESS = "USER_MAIN_GET_POPULAR_STORE_LIST_SUCCESS";
export const USER_MAIN_GET_POPULAR_STORE_LIST_FAILURE = "USER_MAIN_GET_POPULAR_STORE_LIST_FAILURE";

export const USER_MAIN_GET_LATEST_STORE_LIST_START = "USER_MAIN_GET_LATEST_STORE_LIST_START";
export const USER_MAIN_GET_LATEST_STORE_LIST_SUCCESS = "USER_MAIN_GET_LATEST_STORE_LIST_SUCCESS";
export const USER_MAIN_GET_LATEST_STORE_LIST_FAILURE = "USER_MAIN_GET_LATEST_STORE_LIST_FAILURE";

export const USER_MAIN_SEARCH_SHOPS_START = "USER_MAIN_SEARCH_SHOPS_START";
export const USER_MAIN_SEARCH_SHOPS_SUCCESS = "USER_MAIN_SEARCH_SHOPS_SUCCESS";
export const USER_MAIN_SEARCH_SHOPS_FAILURE = "USER_MAIN_SEARCH_SHOPS_FAILURE";

export const USER_MAIN_SEARCH_SHOPS_KEYWORD_START = "USER_MAIN_SEARCH_SHOPS_KEYWORD_START";
export const USER_MAIN_SEARCH_SHOPS_KEYWORD_SUCCESS = "USER_MAIN_SEARCH_SHOPS_KEYWORD_SUCCESS";
export const USER_MAIN_SEARCH_SHOPS_KEYWORD_FAILURE = "USER_MAIN_SEARCH_SHOPS_KEYWORD_FAILURE";

export const USER_MAIN_GET_STORE_FAVLIST_START = "USER_MAIN_GET_STORE_FAVLIST_START";
export const USER_MAIN_GET_STORE_FAVLIST_SUCCESS = "USER_MAIN_GET_STORE_FAVLIST_SUCCESS";
export const USER_MAIN_GET_STORE_FAVLIST_FAILURE = "USER_MAIN_GET_STORE_FAVLIST_FAILURE";

export const USER_MAIN_ADD_STORE_TO_FAVLIST_START = "USER_MAIN_ADD_STORE_TO_FAVLIST_START";
export const USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS = "USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS";
export const USER_MAIN_ADD_STORE_TO_FAVLIST_FAILURE = "USER_MAIN_ADD_STORE_TO_FAVLIST_FAILURE";

export const USER_MAIN_REMOVE_STORE_FROM_FAVLIST_START = "USER_MAIN_REMOVE_STORE_FROM_FAVLIST_START";
export const USER_MAIN_REMOVE_STORE_FROM_FAVLIST_SUCCESS = "USER_MAIN_REMOVE_STORE_FROM_FAVLIST_SUCCESS";
export const USER_MAIN_REMOVE_STORE_FROM_FAVLIST_FAILURE = "USER_MAIN_REMOVE_STORE_FROM_FAVLIST_FAILURE";

export const USER_MAIN_GET_SHOP_INFO_BYID_START = "USER_MAIN_GET_SHOP_INFO_BYID_START";
export const USER_MAIN_GET_SHOP_INFO_BYID_SUCCESS = "USER_MAIN_GET_SHOP_INFO_BYID_SUCCESS";
export const USER_MAIN_GET_SHOP_INFO_BYID_FAILURE = "USER_MAIN_GET_SHOP_INFO_BYID_FAILURE";

// Main order list and order detail
export const USER_MAIN_GET_ORDERLIST_PRODUCTS_START = "USER_MAIN_GET_ORDERLIST_PRODUCTS_START";
export const USER_MAIN_GET_ORDERLIST_PRODUCTS_SUCCESS = "USER_MAIN_GET_ORDERLIST_PRODUCTS_SUCCESS";
export const USER_MAIN_GET_ORDERLIST_PRODUCTS_FAILURE = "USER_MAIN_GET_ORDERLIST_PRODUCTS_FAILURE";

export const USER_MAIN_GET_ORDER_DETAIL_START = "USER_MAIN_GET_ORDER_DETAIL_START";
export const USER_MAIN_GET_ORDER_DETAIL_SUCCESS = "USER_MAIN_GET_ORDER_DETAIL_SUCCESS";
export const USER_MAIN_GET_ORDER_DETAIL_FAILURE = "USER_MAIN_GET_ORDER_DETAIL_FAILURE";

// Main offers list and offer detail

export const USER_MAIN_SEARCH_OFFERS_RESET = "USER_MAIN_SEARCH_SHOPS_RESET";
export const USER_MAIN_SEARCH_OFFERS_KEYWORDS_RESET = "USER_MAIN_SEARCH_SHOPS_KEYWORDS_RESET";
export const USER_MAIN_OFFERS_RESET_ALL = "USER_MAIN_SHOPS_RESET_ALL";

export const USER_MAIN_SEARCH_OFFERS_START = "USER_MAIN_SEARCH_OFFERS_START";
export const USER_MAIN_SEARCH_OFFERS_SUCCESS = "USER_MAIN_SEARCH_OFFERS_SUCCESS";
export const USER_MAIN_SEARCH_OFFERS_FAILURE = "USER_MAIN_SEARCH_OFFERS_FAILURE";

export const USER_MAIN_SEARCH_OFFERS_KEYWORD_START = "USER_MAIN_SEARCH_OFFERS_KEYWORD_START";
export const USER_MAIN_SEARCH_OFFERS_KEYWORD_SUCCESS = "USER_MAIN_SEARCH_OFFERS_KEYWORD_SUCCESS";
export const USER_MAIN_SEARCH_OFFERS_KEYWORD_FAILURE = "USER_MAIN_SEARCH_OFFERS_KEYWORD_FAILURE";

export const USER_MAIN_GET_NEARBY_OFFERS_START = "USER_MAIN_GET_NEARBY_OFFERS_START";
export const USER_MAIN_GET_NEARBY_OFFERS_SUCCESS = "USER_MAIN_GET_NEARBY_OFFERS_SUCCESS";
export const USER_MAIN_GET_NEARBY_OFFERS_FAILURE = "USER_MAIN_GET_NEARBY_OFFERS_FAILURE";

export const USER_MAIN_GET_FAV_NEARBY_OFFERS_START = "USER_MAIN_GET_FAV_NEARBY_OFFERS_START";
export const USER_MAIN_GET_FAV_NEARBY_OFFERS_SUCCESS = "USER_MAIN_GET_FAV_NEARBY_OFFERS_SUCCESS";
export const USER_MAIN_GET_FAV_NEARBY_OFFERS_FAILURE = "USER_MAIN_GET_FAV_NEARBY_OFFERS_FAILURE";

export const USER_MAIN_GET_LATEST_OFFERS_START = "USER_MAIN_GET_LATEST_OFFERS_START";
export const USER_MAIN_GET_LATEST_OFFERS_SUCCESS = "USER_MAIN_GET_LATEST_OFFERS_SUCCESS";
export const USER_MAIN_GET_LATEST_OFFERS_FAILURE = "USER_MAIN_GET_LATEST_OFFERS_FAILURE";

export const USER_MAIN_GET_OFFER_DETAIL_START = "USER_MAIN_GET_OFFER_DETAIL_START";
export const USER_MAIN_GET_OFFER_DETAIL_SUCCESS = "USER_MAIN_GET_OFFER_DETAIL_SUCCESS";
export const USER_MAIN_GET_OFFER_DETAIL_FAILURE = "USER_MAIN_GET_OFFER_DETAIL_FAILURE";

// Shop specific order list and order detail
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_START = "USER_GET_SHOP_ORDERLIST_PRODUCTS_START";
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_SUCCESS = "USER_GET_SHOP_ORDERLIST_PRODUCTS_SUCCESS";
export const USER_GET_SHOP_ORDERLIST_PRODUCTS_FAILURE = "USER_GET_SHOP_ORDERLIST_PRODUCTS_FAILURE";

export const USER_GET_SHOP_ORDER_DETAIL_START = "USER_GET_SHOP_ORDER_DETAIL_START";
export const USER_GET_SHOP_ORDER_DETAIL_SUCCESS = "USER_GET_SHOP_ORDER_DETAIL_SUCCESS";
export const USER_GET_SHOP_ORDER_DETAIL_FAILURE = "USER_GET_SHOP_ORDER_DETAIL_FAILURE";

export const USER_UPDATE_ORDER_STATUS_START = "USER_UPDATE_ORDER_STATUS_START";
export const USER_UPDATE_ORDER_STATUS_SUCCESS = "USER_UPDATE_ORDER_STATUS_SUCCESS";
export const USER_UPDATE_ORDER_STATUS_FAILURE = "USER_UPDATE_ORDER_STATUS_FAILURE";


// Admin actions

// Admin - Orders

export const ADMIN_GET_COMMON_ORDERS_START = "ADMIN_GET_COMMON_ORDERS_START";
export const ADMIN_GET_COMMON_ORDERS_SUCCESS = "ADMIN_GET_COMMON_ORDERS_SUCCESS";
export const ADMIN_GET_COMMON_ORDERS_FAILURE = "ADMIN_GET_COMMON_ORDERS_FAILURE";

export const ADMIN_GET_STORE_ORDERS_START = "ADMIN_GET_STORE_ORDERS_START";
export const ADMIN_GET_STORE_ORDERS_SUCCESS = "ADMIN_GET_STORE_ORDERS_SUCCESS";
export const ADMIN_GET_STORE_ORDERS_FAILURE = "ADMIN_GET_STORE_ORDERS_FAILURE";

export const ADMIN_SEARCH_ORDERS_START = "ADMIN_SEARCH_ORDERS_START";
export const ADMIN_SEARCH_ORDERS_SUCCESS = "ADMIN_SEARCH_ORDERS_SUCCESS";
export const ADMIN_SEARCH_ORDERS_FAILURE = "ADMIN_SEARCH_ORDERS_FAILURE";

export const ADMIN_SEARCH_ORDERS_KEYWORDS_START = "ADMIN_SEARCH_ORDERS_KEYWORDS_START";
export const ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS = "ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS";
export const ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE = "ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE";

export const ADMIN_GET_ORDER_DETAIL_START = "ADMIN_GET_ORDER_DETAIL_START";
export const ADMIN_GET_ORDER_DETAIL_SUCCESS = "ADMIN_GET_ORDER_DETAIL_SUCCESS";
export const ADMIN_GET_ORDER_DETAIL_FAILURE = "ADMIN_GET_ORDER_DETAIL_FAILURE";

export const ADMIN_UPDATE_ORDER_START = "ADMIN_UPDATE_ORDER_START";
export const ADMIN_UPDATE_ORDER_SUCCESS = "ADMIN_UPDATE_ORDER_SUCCESS";
export const ADMIN_UPDATE_ORDER_FAILURE = "ADMIN_UPDATE_ORDER_FAILURE";

// Admin - Products
export const ADMIN_SEARCH_PRODUCTS_RESET = "ADMIN_SEARCH_PRODUCTS_RESET";
export const ADMIN_SEARCH_KEYWORDS_RESET = "ADMIN_SEARCH_KEYWORDS_RESET";
export const ADMIN_PRODUCT_DETAIL_RESET = "ADMIN_PRODUCT_DETAIL_RESET";
export const ADMIN_CATEGORY_SEARCH_RESET = "ADMIN_CATEGORY_SEARCH_RESET";


export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_START = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_START";
export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS";
export const ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE = "ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE";

export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_START = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_START";
export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS";
export const ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE = "ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE";

export const ADMIN_GET_PRODUCT_INFO_START = "ADMIN_GET_PRODUCT_INFO_START";
export const ADMIN_GET_PRODUCT_INFO_SUCCESS = "ADMIN_GET_PRODUCT_INFO_SUCCESS";
export const ADMIN_GET_PRODUCT_INFO_FAILURE = "ADMIN_GET_PRODUCT_INFO_FAILURE";

export const ADMIN_GET_PRODUCT_SHOPS_INFO_START = "ADMIN_GET_PRODUCT_SHOPS_INFO_START";
export const ADMIN_GET_PRODUCT_SHOPS_INFO_SUCCESS = "ADMIN_GET_PRODUCT_SHOPS_INFO_SUCCESS";
export const ADMIN_GET_PRODUCT_SHOPS_INFO_FAILURE = "ADMIN_GET_PRODUCT_SHOPS_INFO_FAILURE";

export const ADMIN_UPDATE_PRODUCT_STATUS_START = "ADMIN_UPDATE_PRODUCT_STATUS_START";
export const ADMIN_UPDATE_PRODUCT_STATUS_SUCCESS = "ADMIN_UPDATE_PRODUCT_STATUS_SUCCESS";
export const ADMIN_UPDATE_PRODUCT_STATUS_FAILURE = "ADMIN_UPDATE_PRODUCT_STATUS_FAILURE";

export const ADMIN_UPDATE_PRODUCT_VIDEO_START = "ADMIN_UPDATE_PRODUCT_VIDEO_START";
export const ADMIN_UPDATE_PRODUCT_VIDEO_SUCCESS = "ADMIN_UPDATE_PRODUCT_VIDEO_SUCCESS";
export const ADMIN_UPDATE_PRODUCT_VIDEO_FAILURE = "ADMIN_UPDATE_PRODUCT_VIDEO_FAILURE";

export const ADMIN_SEARCH_COMMON_CATEGORY_START = "ADMIN_SEARCH_COMMON_CATEGORY_START";
export const ADMIN_SEARCH_COMMON_CATEGORY_SUCCESS = "ADMIN_SEARCH_COMMON_CATEGORY_SUCCESS";
export const ADMIN_SEARCH_COMMON_CATEGORY_FAILURE = "ADMIN_SEARCH_COMMON_CATEGORY_FAILURE";

export const ADMIN_UPDATE_PRODUCT_INFO_START = "ADMIN_UPDATE_PRODUCT_INFO_START";
export const ADMIN_UPDATE_PRODUCT_INFO_SUCCESS = "ADMIN_UPDATE_PRODUCT_INFO_SUCCESS";
export const ADMIN_UPDATE_PRODUCT_INFO_FAILURE = "ADMIN_UPDATE_PRODUCT_INFO_FAILURE";

export const ADMIN_UPDATE_PRODUCT_CATEGORY_START = "ADMIN_UPDATE_PRODUCT_CATEGORY_START";
export const ADMIN_UPDATE_PRODUCT_CATEGORY_SUCCESS = "ADMIN_UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const ADMIN_UPDATE_PRODUCT_CATEGORY_FAILURE = "ADMIN_UPDATE_PRODUCT_CATEGORY_FAILURE";

export const ADMIN_GET_COMMON_PRODUCTS_START = "ADMIN_GET_COMMON_PRODUCTS_START";
export const ADMIN_GET_COMMON_PRODUCTS_SUCCESS = "ADMIN_GET_COMMON_PRODUCTS_SUCCESS";
export const ADMIN_GET_COMMON_PRODUCTS_FAILURE = "ADMIN_GET_COMMON_PRODUCTS_FAILURE";

export const ADMIN_GET_COMMON_CATEGORIES_START = "ADMIN_GET_COMMON_CATEGORIES_START";
export const ADMIN_GET_COMMON_CATEGORIES_SUCCESS = "ADMIN_GET_COMMON_CATEGORIES_SUCCESS";
export const ADMIN_GET_COMMON_CATEGORIES_FAILURE = "ADMIN_GET_COMMON_CATEGORIES_FAILURE";

export const ADMIN_GET_COMMON_MAIN_CATEGORIES_START = "ADMIN_GET_COMMON_MAIN_CATEGORIES_START";
export const ADMIN_GET_COMMON_MAIN_CATEGORIES_SUCCESS = "ADMIN_GET_COMMON_MAIN_CATEGORIES_SUCCESS";
export const ADMIN_GET_COMMON_MAIN_CATEGORIES_FAILURE = "ADMIN_GET_COMMON_MAIN_CATEGORIES_FAILURE";

export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START";
export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE = "ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE";

export const ADMIN_SEARCH_PRODUCTS_START = "ADMIN_SEARCH_PRODUCTS_START";
export const ADMIN_SEARCH_PRODUCTS_SUCCESS = "ADMIN_SEARCH_PRODUCTS_SUCCESS";
export const ADMIN_SEARCH_PRODUCTS_FAILURE = "ADMIN_SEARCH_PRODUCTS_FAILURE";

export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_START = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_START";
export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS";
export const ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE = "ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE";

export const ADMIN_ADD_PRIVATE_PRODUCT_QUICK_START = "ADMIN_ADD_PRIVATE_PRODUCT_QUICK_START";
export const ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS = "ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS";
export const ADMIN_ADD_PRIVATE_PRODUCT_QUICK_FAILURE = "ADMIN_ADD_PRIVATE_PRODUCT_QUICK_FAILURE";

export const ADMIN_ADD_COMMON_PRODUCT_START = "ADMIN_ADD_COMMON_PRODUCT_START";
export const ADMIN_ADD_COMMON_PRODUCT_SUCCESS = "ADMIN_ADD_COMMON_PRODUCT_SUCCESS";
export const ADMIN_ADD_COMMON_PRODUCT_FAILURE = "ADMIN_ADD_COMMON_PRODUCT_FAILURE";

export const ADMIN_UPDATE_COMMON_PRODUCT_START = "ADMIN_UPDATE_COMMON_PRODUCT_START";
export const ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS = "ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS";
export const ADMIN_UPDATE_COMMON_PRODUCT_FAILURE = "ADMIN_UPDATE_COMMON_PRODUCT_FAILURE";

export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_START = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_START";
export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS";
export const ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE = "ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE";

export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START";
export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS";
export const ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE = "ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE";

export const ADMIN_ADD_COMMON_CATEGORY_START = "ADMIN_ADD_COMMON_CATEGORY_START";
export const ADMIN_ADD_COMMON_CATEGORY_SUCCESS = "ADMIN_ADD_COMMON_CATEGORY_SUCCESS";
export const ADMIN_ADD_COMMON_CATEGORY_FAILURE = "ADMIN_ADD_COMMON_CATEGORY_FAILURE";

export const ADMIN_UPDATE_COMMON_CATEGORY_START = "ADMIN_UPDATE_COMMON_CATEGORY_START";
export const ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS = "ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS";
export const ADMIN_UPDATE_COMMON_CATEGORY_FAILURE = "ADMIN_UPDATE_COMMON_CATEGORY_FAILURE";

export const ADMIN_UPDATE_COMMON_CATEGORY_SETTING_START = "ADMIN_UPDATE_COMMON_CATEGORY_SETTING_START";
export const ADMIN_UPDATE_COMMON_CATEGORY_SETTING_SUCCESS = "ADMIN_UPDATE_COMMON_CATEGORY_SETTING_SUCCESS";
export const ADMIN_UPDATE_COMMON_CATEGORY_SETTING_FAILURE = "ADMIN_UPDATE_COMMON_CATEGORY_SETTING_FAILURE";

export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_START = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_START";
export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS";
export const ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE = "ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE";

export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START";
export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS";
export const ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE = "ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE";

// Admin shops

export const ADMIN_SEARCH_SHOPS_RESET = "ADMIN_SEARCH_SHOPS_RESET";
export const ADMIN_SEARCH_SHOPS_KEYWORDS_RESET = "ADMIN_SEARCH_SHOPS_KEYWORDS_RESET";

export const ADMIN_GET_LOCATION_STORE_LIST_START = "ADMIN_GET_LOCATION_STORE_LIST_START";
export const ADMIN_GET_LOCATION_STORE_LIST_SUCCESS = "ADMIN_GET_LOCATION_STORE_LIST_SUCCESS";
export const ADMIN_GET_LOCATION_STORE_LIST_FAILURE = "ADMIN_GET_LOCATION_STORE_LIST_FAILURE";

export const ADMIN_SEARCH_SHOPS_START = "ADMIN_SEARCH_SHOPS_START";
export const ADMIN_SEARCH_SHOPS_SUCCESS = "ADMIN_SEARCH_SHOPS_SUCCESS";
export const ADMIN_SEARCH_SHOPS_FAILURE = "ADMIN_SEARCH_SHOPS_FAILURE";

export const ADMIN_SEARCH_SHOPS_KEYWORD_START = "ADMIN_SEARCH_SHOPS_KEYWORD_START";
export const ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS = "ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS";
export const ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE = "ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE";

export const ADMIN_GET_SHOP_INFO_BYID_START = "ADMIN_GET_SHOP_INFO_BYID_START";
export const ADMIN_GET_SHOP_INFO_BYID_SUCCESS = "ADMIN_GET_SHOP_INFO_BYID_SUCCESS";
export const ADMIN_GET_SHOP_INFO_BYID_FAILURE = "ADMIN_GET_SHOP_INFO_BYID_FAILURE";

export const ADMIN_UPDATE_STORE_STATUS_START = "ADMIN_UPDATE_STORE_STATUS_START";
export const ADMIN_UPDATE_STORE_STATUS_SUCCESS = "ADMIN_UPDATE_STORE_STATUS_SUCCESS";
export const ADMIN_UPDATE_STORE_STATUS_FAILURE = "ADMIN_UPDATE_STORE_STATUS_FAILURE";

export const ADMIN_UPDATE_STORE_PROFILE_START = "ADMIN_UPDATE_STORE_PROFILE_START";
export const ADMIN_UPDATE_STORE_PROFILE_SUCCESS = "ADMIN_UPDATE_STORE_PROFILE_SUCCESS";
export const ADMIN_UPDATE_STORE_PROFILE_FAILURE = "ADMIN_UPDATE_STORE_PROFILE_FAILURE";

export const ADMIN_UPDATE_STORE_LOGO_START = "ADMIN_UPDATE_STORE_LOGO_START";
export const ADMIN_UPDATE_STORE_LOGO_SUCCESS = "ADMIN_UPDATE_STORE_LOGO_SUCCESS";
export const ADMIN_UPDATE_STORE_LOGO_FAILURE = "ADMIN_UPDATE_STORE_LOGO_FAILURE";

export const ADMIN_UPDATE_STORE_VIDEO_START = "ADMIN_UPDATE_STORE_VIDEO_START";
export const ADMIN_UPDATE_STORE_VIDEO_SUCCESS = "ADMIN_UPDATE_STORE_VIDEO_SUCCESS";
export const ADMIN_UPDATE_STORE_VIDEO_FAILURE = "ADMIN_UPDATE_STORE_VIDEO_FAILURE";

export const ADMIN_ADD_STORE_COLLECTION_VIDEO_START = "ADMIN_ADD_STORE_COLLECTION_VIDEO_START";
export const ADMIN_ADD_STORE_COLLECTION_VIDEO_SUCCESS = "ADMIN_ADD_STORE_COLLECTION_VIDEO_SUCCESS";
export const ADMIN_ADD_STORE_COLLECTION_VIDEO_FAILURE = "ADMIN_ADD_STORE_COLLECTION_VIDEO_FAILURE";

export const ADMIN_UPDATE_STORE_URLLOCK_START = "ADMIN_UPDATE_STORE_URLLOCK_START";
export const ADMIN_UPDATE_STORE_URLLOCK_SUCCESS = "ADMIN_UPDATE_STORE_URLLOCK_SUCCESS";
export const ADMIN_UPDATE_STORE_URLLOCK_FAILURE = "ADMIN_UPDATE_STORE_URLLOCK_FAILURE";

export const ADMIN_UPDATE_STORE_SALESLEAD_START = "ADMIN_UPDATE_STORE_SALESLEAD_START";
export const ADMIN_UPDATE_STORE_SALESLEAD_SUCCESS = "ADMIN_UPDATE_STORE_SALESLEAD_SUCCESS";
export const ADMIN_UPDATE_STORE_SALESLEAD_FAILURE = "ADMIN_UPDATE_STORE_SALESLEAD_FAILURE";

export const ADMIN_DISABLE_STORE_STATUS_START = "ADMIN_DISABLE_STORE_STATUS_START";
export const ADMIN_DISABLE_STORE_STATUS_SUCCESS = "ADMIN_DISABLE_STORE_STATUS_SUCCESS";
export const ADMIN_DISABLE_STORE_STATUS_FAILURE = "ADMIN_DISABLE_STORE_STATUS_FAILURE";

export const ADMIN_ENABLE_STORE_STATUS_START = "ADMIN_ENABLE_STORE_STATUS_START";
export const ADMIN_ENABLE_STORE_STATUS_SUCCESS = "ADMIN_ENABLE_STORE_STATUS_SUCCESS";
export const ADMIN_ENABLE_STORE_STATUS_FAILURE = "ADMIN_ENABLE_STORE_STATUS_FAILURE";

export const ADMIN_UPDATE_STORE_DOMAIN_START = "ADMIN_UPDATE_STORE_DOMAIN_START";
export const ADMIN_UPDATE_STORE_DOMAIN_SUCCESS = "ADMIN_UPDATE_STORE_DOMAIN_SUCCESS";
export const ADMIN_UPDATE_STORE_DOMAIN_FAILURE = "ADMIN_UPDATE_STORE_DOMAIN_FAILURE";

export const ADMIN_UPDATE_STORE_SOCIAL_START = "ADMIN_UPDATE_STORE_SOCIAL_START";
export const ADMIN_UPDATE_STORE_SOCIAL_SUCCESS = "ADMIN_UPDATE_STORE_SOCIAL_SUCCESS";
export const ADMIN_UPDATE_STORE_SOCIAL_FAILURE = "ADMIN_UPDATE_STORE_SOCIAL_FAILURE";

export const ADMIN_DISPLAY_DELIVERED_STORE_STATUS_START = "ADMIN_DISPLAY_DELIVERED_STORE_STATUS_START";
export const ADMIN_DISPLAY_DELIVERED_STORE_STATUS_SUCCESS = "ADMIN_DISPLAY_DELIVERED_STORE_STATUS_SUCCESS";
export const ADMIN_DISPLAY_DELIVERED_STORE_STATUS_FAILURE = "ADMIN_DISPLAY_DELIVERED_STORE_STATUS_FAILURE";

export const ADMIN_DISPLAY_DONE_STORE_STATUS_START = "ADMIN_DISPLAY_DONE_STORE_STATUS_START";
export const ADMIN_DISPLAY_DONE_STORE_STATUS_SUCCESS = "ADMIN_DISPLAY_DONE_STORE_STATUS_SUCCESS";
export const ADMIN_DISPLAY_DONE_STORE_STATUS_FAILURE = "ADMIN_DISPLAY_DONE_STORE_STATUS_FAILURE";

export const ADMIN_DISPLAY_REQUEST_STORE_STATUS_START = "ADMIN_DISPLAY_REQUEST_STORE_STATUS_START";
export const ADMIN_DISPLAY_REQUEST_STORE_STATUS_SUCCESS = "ADMIN_DISPLAY_REQUEST_STORE_STATUS_SUCCESS";
export const ADMIN_DISPLAY_REQUEST_STORE_STATUS_FAILURE = "ADMIN_DISPLAY_REQUEST_STORE_STATUS_FAILURE";

export const ADMIN_DISPLAY_PRINTED_STATUS_START = "ADMIN_DISPLAY_PRINTED_STATUS_START";
export const ADMIN_DISPLAY_PRINTED_STATUS_SUCCESS = "ADMIN_DISPLAY_PRINTED_STATUS_SUCCESS";
export const ADMIN_DISPLAY_PRINTED_STATUS_FAILURE = "ADMIN_DISPLAY_PRINTED_STATUS_FAILURE";

export const ADMIN_UPDATE_STORE_VERIFIED_START = "ADMIN_UPDATE_STORE_VERIFIED_START";
export const ADMIN_UPDATE_STORE_VERIFIED_SUCCESS = "ADMIN_UPDATE_STORE_VERIFIED_SUCCESS";
export const ADMIN_UPDATE_STORE_VERIFIED_FAILURE = "ADMIN_UPDATE_STORE_VERIFIED_FAILURE";

export const ADMIN_SEND_MESSAGE_START = "ADMIN_SEND_MESSAGE_START";
export const ADMIN_SEND_MESSAGE_SUCCESS = "ADMIN_SEND_MESSAGE_SUCCESS";
export const ADMIN_SEND_MESSAGE_FAILURE = "ADMIN_SEND_MESSAGE_FAILURE";

export const ADMIN_SEND_INTERNAL_MESSAGE_START = "ADMIN_SEND_INTERNAL_MESSAGE_START";
export const ADMIN_SEND_INTERNAL_MESSAGE_SUCCESS = "ADMIN_SEND_INTERNAL_MESSAGE_SUCCESS";
export const ADMIN_SEND_INTERNAL_MESSAGE_FAILURE = "ADMIN_SEND_INTERNAL_MESSAGE_FAILURE";

// Admin manage offers

export const ADMIN_SEARCH_OFFERS_START = "ADMIN_SEARCH_OFFERS_START";
export const ADMIN_SEARCH_OFFERS_SUCCESS = "ADMIN_SEARCH_OFFERS_SUCCESS";
export const ADMIN_SEARCH_OFFERS_FAILURE = "ADMIN_SEARCH_OFFERS_FAILURE";

export const ADMIN_GET_OFFER_INFO_START = "ADMIN_GET_OFFER_INFO_START";
export const ADMIN_GET_OFFER_INFO_SUCCESS = "ADMIN_GET_OFFER_INFO_SUCCESS";
export const ADMIN_GET_OFFER_INFO_FAILURE = "ADMIN_GET_OFFER_INFO_FAILURE";

export const ADMIN_UPDATE_OFFER_STATUS_START = "ADMIN_UPDATE_OFFER_STATUS_START";
export const ADMIN_UPDATE_OFFER_STATUS_SUCCESS = "ADMIN_UPDATE_OFFER_STATUS_SUCCESS";
export const ADMIN_UPDATE_OFFER_STATUS_FAILURE = "ADMIN_UPDATE_OFFER_STATUS_FAILURE";

export const ADMIN_ADD_OFFER_START = "ADMIN_ADD_OFFER_START";
export const ADMIN_ADD_OFFER_SUCCESS = "ADMIN_ADD_OFFER_SUCCESS";
export const ADMIN_ADD_OFFER_FAILURE = "ADMIN_ADD_OFFER_FAILURE";

export const ADMIN_UPDATE_OFFER_INFO_START = "ADMIN_UPDATE_OFFER_INFO_START";
export const ADMIN_UPDATE_OFFER_INFO_SUCCESS = "ADMIN_UPDATE_OFFER_INFO_SUCCESS";
export const ADMIN_UPDATE_OFFER_INFO_FAILURE = "ADMIN_UPDATE_OFFER_INFO_FAILURE";

export const ADMIN_SHOP_OFFERS_START = "ADMIN_SHOP_OFFERS_START";
export const ADMIN_SHOP_OFFERS_SUCCESS = "ADMIN_SHOP_OFFERS_SUCCESS";
export const ADMIN_SHOP_OFFERS_FAILURE = "ADMIN_SHOP_OFFERS_FAILURE";

export const ADMIN_ADD_OFFER_QUICK_START = "ADMIN_ADD_OFFER_QUICK_START";
export const ADMIN_ADD_OFFER_QUICK_SUCCESS = "ADMIN_ADD_OFFER_QUICK_SUCCESS";
export const ADMIN_ADD_OFFER_QUICK_FAILURE = "ADMIN_ADD_OFFER_QUICK_FAILURE";

// Admin subscription

export const ADMIN_GET_SUBSCRIPTION_ORDERS_START = "ADMIN_GET_SUBSCRIPTION_ORDERS_START";
export const ADMIN_GET_SUBSCRIPTION_ORDERS_SUCCESS = "ADMIN_GET_SUBSCRIPTION_ORDERS_SUCCESS";
export const ADMIN_GET_SUBSCRIPTION_ORDERS_FAILURE = "ADMIN_GET_SUBSCRIPTION_ORDERS_FAILURE";

export const ADMIN_GET_SUBSCRIPTION_ORDER_ID_START = "ADMIN_GET_SUBSCRIPTION_ORDER_ID_START";
export const ADMIN_GET_SUBSCRIPTION_ORDER_ID_SUCCESS = "ADMIN_GET_SUBSCRIPTION_ORDER_ID_SUCCESS";
export const ADMIN_GET_SUBSCRIPTION_ORDER_ID_FAILURE = "ADMIN_GET_SUBSCRIPTION_ORDER_ID_FAILURE";

export const ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START = "ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START";
export const ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_SUCCESS = "ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_SUCCESS";
export const ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_FAILURE = "ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_FAILURE";

export const ADMIN_GET_ALL_SUBSCRIPTIONS_START = "GET_ALL_SUBSCRIPTIONS_START";
export const ADMIN_GET_ALL_SUBSCRIPTIONS_SUCCESS = "GET_ALL_SUBSCRIPTIONS_SUCCESS";
export const ADMIN_GET_ALL_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const ADMIN_ADD_SUBSCRIPTION_PLAN_START = "ADMIN_ADD_SUBSCRIPTION_PLAN_START";
export const ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS = "ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS";
export const ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE = "ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE";

export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START";
export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS";
export const ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE = "ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE";

// Admin - Sales lead
export const ADMIN_SEARCH_SALES_LEAD_RESET = "ADMIN_SEARCH_SALES_LEAD_RESET";
export const ADMIN_SEARCH_SALES_KEYWORD_RESET = "ADMIN_SEARCH_SALES_KEYWORD_RESET";
export const ADMIN_SALES_LEAD_DETAIL_RESET = "ADMIN_SALES_LEAD_DETAIL_RESET";
export const ADMIN_SALES_LEAD_IMAGES_RESET = "ADMIN_SALES_LEAD_IMAGES_RESET";
export const ADMIN_SALES_EXECUTIVE_SEARCH_RESET = "ADMIN_SALES_EXECUTIVE_SEARCH_RESET";

export const ADMIN_SEARCH_SALES_LEAD_START = "ADMIN_SEARCH_SALES_LEAD_START";
export const ADMIN_SEARCH_SALES_LEAD_SUCCESS = "ADMIN_SEARCH_SALES_LEAD_SUCCESS";
export const ADMIN_SEARCH_SALES_LEAD_FAILURE = "ADMIN_SEARCH_SALES_LEAD_FAILURE";

export const ADMIN_SEARCH_SALES_LEAD_KEYWORDS_START = "ADMIN_SEARCH_SALES_LEAD_KEYWORDS_START";
export const ADMIN_SEARCH_SALES_LEAD_KEYWORDS_SUCCESS = "ADMIN_SEARCH_SALES_LEAD_KEYWORDS_SUCCESS";
export const ADMIN_SEARCH_SALES_LEAD_KEYWORDS_FAILURE = "ADMIN_SEARCH_SALES_LEAD_KEYWORDS_FAILURE";

export const ADMIN_GET_SALES_LEAD_INFO_START = "ADMIN_GET_SALES_LEAD_INFO_START";
export const ADMIN_GET_SALES_LEAD_INFO_SUCCESS = "ADMIN_GET_SALES_LEAD_INFO_SUCCESS";
export const ADMIN_GET_SALES_LEAD_INFO_FAILURE = "ADMIN_GET_SALES_LEAD_INFO_FAILURE";

export const ADMIN_ADD_SALES_LEAD_START = "ADMIN_ADD_SALES_LEAD_START";
export const ADMIN_ADD_SALES_LEAD_SUCCESS = "ADMIN_ADD_SALES_LEAD_SUCCESS";
export const ADMIN_ADD_SALES_LEAD_FAILURE = "ADMIN_ADD_SALES_LEAD_FAILURE";

export const ADMIN_UPDATE_SALES_LEAD_START = "ADMIN_UPDATE_SALES_LEAD_START";
export const ADMIN_UPDATE_SALES_LEAD_SUCCESS = "ADMIN_UPDATE_SALES_LEAD_SUCCESS";
export const ADMIN_UPDATE_SALES_LEAD_FAILURE = "ADMIN_UPDATE_SALES_LEAD_FAILURE";

export const ADMIN_UPDATE_SALES_LEAD_CARD_START = "ADMIN_UPDATE_SALES_LEAD_CARD_START";
export const ADMIN_UPDATE_SALES_LEAD_CARD_SUCCESS = "ADMIN_UPDATE_SALES_LEAD_CARD_SUCCESS";
export const ADMIN_UPDATE_SALES_LEAD_CARD_FAILURE = "ADMIN_UPDATE_SALES_LEAD_CARD_FAILURE";

export const ADMIN_UPDATE_SALES_LEAD_EVENT_START = "ADMIN_UPDATE_SALES_LEAD_EVENT_START";
export const ADMIN_UPDATE_SALES_LEAD_EVENT_SUCCESS = "ADMIN_UPDATE_SALES_LEAD_EVENT_SUCCESS";
export const ADMIN_UPDATE_SALES_LEAD_EVENT_FAILURE = "ADMIN_UPDATE_SALES_LEAD_EVENT_FAILURE";

export const ADMIN_UPDATE_SALES_LEAD_PLACEID_START = "ADMIN_UPDATE_SALES_LEAD_PLACEID_START";
export const ADMIN_UPDATE_SALES_LEAD_PLACEID_SUCCESS = "ADMIN_UPDATE_SALES_LEAD_PLACEID_SUCCESS";
export const ADMIN_UPDATE_SALES_LEAD_PLACEID_FAILURE = "ADMIN_UPDATE_SALES_LEAD_PLACEID_FAILURE";

export const ADMIN_ADD_SALES_LEAD_IMAGE_START = "ADMIN_ADD_SALES_LEAD_IMAGE_START";
export const ADMIN_ADD_SALES_LEAD_IMAGE_SUCCESS = "ADMIN_ADD_SALES_LEAD_IMAGE_SUCCESS";
export const ADMIN_ADD_SALES_LEAD_IMAGE_FAILURE = "ADMIN_ADD_SALES_LEAD_IMAGE_FAILURE";

export const ADMIN_REMOVE_SALES_LEAD_IMAGE_START = "ADMIN_REMOVE_SALES_LEAD_IMAGE_START";
export const ADMIN_REMOVE_SALES_LEAD_IMAGE_SUCCESS = "ADMIN_REMOVE_SALES_LEAD_IMAGE_SUCCESS";
export const ADMIN_REMOVE_SALES_LEAD_IMAGE_FAILURE = "ADMIN_REMOVE_SALES_LEAD_IMAGE_FAILURE";

export const ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_START = "ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_START";
export const ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_SUCCESS = "ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_SUCCESS";
export const ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_FAILURE = "ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_FAILURE";

export const ADMIN_GET_SALES_EXECUTIVES_START = "ADMIN_GET_SALES_EXECUTIVES_START";
export const ADMIN_GET_SALES_EXECUTIVES_SUCCESS = "ADMIN_GET_SALES_EXECUTIVES_SUCCESS";
export const ADMIN_GET_SALES_EXECUTIVES_FAILURE = "ADMIN_GET_SALES_EXECUTIVES_FAILURE";

export const ADMIN_UPDATE_LEAD_EXECUTIVE_START = "ADMIN_UPDATE_LEAD_EXECUTIVE_START";
export const ADMIN_UPDATE_LEAD_EXECUTIVE_SUCCESS = "ADMIN_UPDATE_LEAD_EXECUTIVE_SUCCESS";
export const ADMIN_UPDATE_LEAD_EXECUTIVE_FAILURE = "ADMIN_UPDATE_LEAD_EXECUTIVE_FAILURE";

export const ADMIN_SEARCH_SALES_FUTURE_START = "ADMIN_SEARCH_SALES_FUTURE_START";
export const ADMIN_SEARCH_SALES_FUTURE_SUCCESS = "ADMIN_SEARCH_SALES_FUTURE_SUCCESS";
export const ADMIN_SEARCH_SALES_FUTURE_FAILURE = "ADMIN_SEARCH_SALES_FUTURE_FAILURE";

export const ADMIN_UPDATE_SALES_FUTURE_START = "ADMIN_UPDATE_SALES_FUTURE_START";
export const ADMIN_UPDATE_SALES_FUTURE_SUCCESS = "ADMIN_UPDATE_SALES_FUTURE_SUCCESS";
export const ADMIN_UPDATE_SALES_FUTURE_FAILURE = "ADMIN_UPDATE_SALES_FUTURE_FAILURE";

export const ADMIN_UPDATE_SHOP_FUTURE_LOGO_START = "ADMIN_UPDATE_SHOP_FUTURE_LOGO_START";
export const ADMIN_UPDATE_SHOP_FUTURE_LOGO_SUCCESS = "ADMIN_UPDATE_SHOP_FUTURE_LOGO_SUCCESS";
export const ADMIN_UPDATE_SHOP_FUTURE_LOGO_FAILURE = "ADMIN_UPDATE_SHOP_FUTURE_LOGO_FAILURE";

// Get Products

export const FETCH_PRODUCTS_BEGIN = "FETCH_PRODUCTS_BEGIN";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";

// FILTERS
export const FILTER_BRAND = "FILTER_BRAND";
export const FILTER_COLOR = "FILTER_COLOR";
export const FILTER_PRICE = "FILTER_PRICE";
export const SORT_BY = "SORT_BY";

export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

// Cart
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const INCREMENT_QTY = "INCREMENT_QTY";
export const DECREMENT_QTY = "DECREMENT_QTY";

// WishList
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST";

// Compare
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";

// CheckOut Process
export const CHECKOUT_REQUEST = "CHECKOUT_REQUEST";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";
export const CHECKOUT_FAILURE = "CHECKOUT_FAILURE";
