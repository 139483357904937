// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  AutoComplete,
  Button,
  Notification,
} from "components/Atoms";
import { H6 } from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import "./style.scss";
import * as Actions from "actions/seller/seller.products";
import * as ActionTypes from "constants/ActionTypes";

import PrivateCategoryEditor from "components/elements/seller/PrivateCategoryEditor";
import SellerCategory from "components/elements/seller/SellerCategory";
import SellerCategorySettingEditor from "components/elements/seller/SellerCategorySettingEditor";

import PrivateCategoryImageMain from "components/pages/seller/products/PrivateCategoryImageMain";
import MockProductList from "components/elements/main/MockProductList";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, Grid} from "@mui/material";
import {useHistory} from "react-router";

const { Search } = Input;
const { Option } = AutoComplete;

const PrivateCategoryMain = (props) => {
  const [state, setState] = useState({ categoryMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: props.categoryInput });

  const {
    serviceError, reqStatus, isLoading, categoryList,
    getStoreProductCategories, addPrivateCategory, updatePrivateCategory, updatePrivateCategorySetting
  } = props;

  useEffect(() => {
    props.getStoreProductCategories();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADD_PRIVATE_CATEGORY_SUCCESS:
      case ActionTypes.UPDATE_PRIVATE_CATEGORY_SUCCESS:
      case ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS:
      case ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS:
      case ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS:
        getStoreProductCategories();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateCategoryToAccountCall = (values) => {
    if (!values.id) {
      addPrivateCategory(values);
    } else {
      updatePrivateCategory(values);
    }
    setState({ categoryMode: "PREVIEW" });
  };

  const updateCategorySettingCall = (values, id) => {
    values.id = id;
    updatePrivateCategorySetting(values);
    setState({ categoryMode: "PREVIEW" });
  };

  const handleCategoryEdit = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          name: "", description: "", id: "", image: [],
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          name: item.name, description: item.description, id: item.id, image: item.image,
        },
      });
    }
  };

  const handleCategorySetting = (mode, item) => (e) => {
    setState({ categoryMode: mode });
    setCategoryState({
      storeCategory: {
        name: item.name,
        id: item.id,
        is_popular: item.is_popular,
        is_active: item.is_active,
      },
    });
  };

  const handleCategoryImage = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          name: "", description: "", id: "", image: [],
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          name: item.name, description: item.description, id: item.id, image: item.image,
        },
      });
    }
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { categories } = categoryList;
  const { categoryMode } = state;
  const { storeCategory = {} } = categoryState;

  const renderNoResults = () => (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <H6>No category found
                  </H6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );

  const renderResults = () => (
      <Container>
        <Grid item container md={9} xs={12} spacing={4}>
          {categories.map((category, index) => (
              <Grid item xs={6} sm={4} key={index}>
                <SellerCategory
                    className="product-item"
                    key={category.id}
                    category={category}
                    editCategoryImage={handleCategoryImage("EDIT_IMG", category, false)}
                    editCategoryInfo={handleCategoryEdit("EDIT", category, false)}
                    editCategorySettings={handleCategorySetting("EDIT_SET", category)}
                />
              </Grid>
          ))}
        </Grid>
        <Box>&nbsp;<br />&nbsp;</Box>
      </Container>
  );

  const renderCategoryList = () => (
      <Box>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
          <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleCategoryEdit("EDIT", null, true)}
          >
            Add New Category
          </Button>
        </CustomFlexBox>

      {isLoading ? <MockProductList />
        : categories.length > 0
          ? renderResults()
          : renderNoResults()}
      </Box>
  );

  const renderCategoryEdit = () => {
    switch (categoryMode) {
      case "EDIT":
        return (
          <PrivateCategoryEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
            category={storeCategory}
            addUpdateCategoryToShopClicked={addUpdateCategoryToAccountCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_IMG":
        return (
          <PrivateCategoryImageMain
            category={storeCategory}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_SET":
      default:
        return (
          <SellerCategorySettingEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
            category={storeCategory}
            updateCategorySettingClicked={updateCategorySettingCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
    }
  };

  return (
      <Box pt={2}>
      {categoryMode === "PREVIEW" ? (
        renderCategoryList()
      ) : (
        renderCategoryEdit(categoryMode)
      )}
    </Box>
  );
};

PrivateCategoryMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  categoryList: [],
};

PrivateCategoryMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getStoreProductCategories: PropTypes.func.isRequired,
  addPrivateCategory: PropTypes.func.isRequired,
  updatePrivateCategory: PropTypes.func.isRequired,
  updatePrivateCategorySetting: PropTypes.func.isRequired,
};

const privateCategoryMain = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    categoryList: products.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(PrivateCategoryMain);

export default privateCategoryMain;
