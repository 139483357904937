import * as yup from "yup";

export const schema = yup.object().shape({
  status: yup.string().required("Status is required field"),
});


export const getRadioButtonData = (status) => [
  {
    label: "Status",
    value: status,
    onChangeField: "status",
  },
];

export const getButtonData = (onFinish, onCancel, hasError, isTouched) => [
  {
    name: "Update",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError(),
  },
  {
    name: "Cancel",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];

export const getButtonDataSendMsg = (onFinishSendMsg) => [
  {
    name: "Send Message to Seller",
    onClick: onFinishSendMsg,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataCancel = (onCancel) => [
  {
    name: "Close",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldDataRejectMessage = (message) => [
  {
    label: "Message",
    value: message,
    onChangeField: "message",
  },
];
