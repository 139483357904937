import { Box, Divider, Grid, Typography } from "@mui/material";
import Accordion from "components/accordion/Accordion";
import AccordionHeader from "components/accordion/AccordionHeader";
import Header from "components/header/Header";
import MobileCategoryImageBox from "components/mobile-category-nav/MobileCategoryImageBox";
import MobileCategoryNavStyle from "components/mobile-category-nav/MobileCategoryNavStyle";
import { H4 } from './CustomTypography'
import navigations from "data/navigations";
//import Link from "next/link";
import {Link} from 'react-router-dom';
import { Fragment, useEffect, useState } from "react";
import React from "react";

const MobileCategoryNav = (props) => {

  const [category, setCategory] = useState(null);
  const [popularList, setPopularList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const {
      categories, categoryState, selectCategoryClicked
  } = props;


  const handleMainCategoryClick = (cat) => () => {

    let menuData = cat.children;

//    if (menuData) setSubCategoryList(categories);
    if (menuData) setSubCategoryList(menuData);
    else setSubCategoryList([]);

    setCategory(cat);
  };

  const handleSubCategoryClick = (cat) => () => {
    selectCategoryClicked(cat);
  };

  useEffect(() => {

    let list = [];

    categories && categories.length > 0 && categories.forEach((category) => {
      category.children.forEach((child) => {
        if (child.is_popular === true) {
          list.push(child);
        }
      });
    });

    setPopularList(list);
    setCategory(categories && categories.length > 0 && categories[0]);
    setSubCategoryList(categories && categories.length > 0 && categories[0].children);

  }, []);


  return (
    <MobileCategoryNavStyle>
      <Header className="header" />
      <Box className="main-category-holder">
        {categories.map((item) => (
          <Box
            key={item.name}
            onClick={handleMainCategoryClick(item)}
            borderLeft={`${category?.id === item.id ? "3" : "0"}px solid`}
          >
            <MobileCategoryImageBox {...item} />
            <Typography
              className="ellipsis"
              textAlign="center"
            >
            </Typography>

          </Box>
        ))}
      </Box>

      <Box className="container">
        {/*
        <Box mb={4}>
          <Typography mb={2}>
          <H4 fontSize={14} fontWeight="bold" lineHeight="1">Popular Categories</H4>
          </Typography>
          <Grid sx={{
            width: 'calc(100% - 70px)'
          }}
                container spacing={3}>
            {popularList.map((item, ind) => (
              <Grid item lg={1} md={3} sm={3} xs={4} key={ind}>
                  <a onClick={handleSubCategoryClick(item)}>
                    <MobileCategoryImageBox {...item} />
                  </a>
              </Grid>
            ))}
          </Grid>
        </Box>
        */}

        {category?.menuComponent === "MegaMenu1" ? (
          subCategoryList.map((item, ind) => (
            <Fragment key={ind}>
              <Divider />
              <Accordion>
                {/*
                <AccordionHeader px={0} py={1.25}>
                    <H4 fontSize={14} fontWeight="bold" lineHeight="1">{item.title}</H4>
                </AccordionHeader>
                */}

                <Box mb={4} mt={1}>
                  <Grid container spacing={3}>
                    {item.subCategories?.map((item, ind) => (
                      <Grid item lg="1" md="3" sm="3" xs="4" key={ind}>
                          <a onClick={handleSubCategoryClick(item)}>
                            <MobileCategoryImageBox {...item}  />
                          </a>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Accordion>
            </Fragment>
          ))
        ) : (
          <Box mb={4}>
            {/*
            <Typography mb={2}>
              <H4 fontSize={14} fontWeight="bold" lineHeight="1">{category && category.name}</H4>
            </Typography>
            */}
            <Grid sx={{
              width: 'calc(100% - 70px)'
            }} container spacing={3}>
              {subCategoryList.map((item, ind) => (
                <Grid item lg="1" md="3" sm="3" xs="4" key={ind}>
                    <a onClick={handleSubCategoryClick(item)}>
                      <MobileCategoryImageBox {...item} />
                    </a>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

    </MobileCategoryNavStyle>
  );
};

export default MobileCategoryNav;
