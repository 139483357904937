import * as ActionTypes from '../../constants/ActionTypes'


export const userMainResetSearchOffers = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_RESET
});

export const userMainResetSearchOffersKeywords = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_KEYWORDS_RESET
});

export const userMainResetOffers = () => ({
    type: ActionTypes.USER_MAIN_OFFERS_RESET_ALL
});

export const userMainSearchOffers = (reqData, sort, location, marketId) => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_START,
    payload: reqData,
    sort: sort,
    location: location,
    market: marketId
});

export const userMainSearchOffersSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_OFFERS_SUCCESS,
        payload: data,
    };
};

export const userMainSearchOffersFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_FAILURE,
    payload: error,
});

export const userMainSearchOfferKeywords = (req) => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_KEYWORD_START,
    payload: req,
});

export const userMainSearchOfferKeywordsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_OFFERS_KEYWORD_SUCCESS,
        payload: data,
    };
};
export const userMainSearchOfferKeywordsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_OFFERS_KEYWORD_FAILURE,
    payload: error,
});

export const userMainGetNearbyOffers = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_START,
    payload: reqData,
});

export const userMainGetNearbyOffersSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_SUCCESS,
        payload: data,
    };
};
export const userMainGetNearbyOffersFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_FAILURE,
    payload: error,
});

export const userMainGetFavNearbyOffers = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_START,
    payload: reqData,
});

export const userMainGetFavNearbyOffersSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_SUCCESS,
        payload: data,
    };
};
export const userMainGetFavNearbyOffersFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_FAILURE,
    payload: error,
});

export const userMainGetLatestOffers = (marketId) => ({
    type: ActionTypes.USER_MAIN_GET_LATEST_OFFERS_START,
    market: marketId
});

export const userMainGetLatestOffersSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_LATEST_OFFERS_SUCCESS,
        payload: data,
    };
};
export const userMainGetLatestOffersFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_LATEST_OFFERS_FAILURE,
    payload: error,
});

export const userMainGetOfferDetail = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_OFFER_DETAIL_START,
    payload: reqData,
});

export const userMainGetOfferDetailSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_OFFER_DETAIL_SUCCESS,
        payload: data,
    };
};

export const userMainGetOfferDetailFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_OFFER_DETAIL_FAILURE,
    payload: error,
});
