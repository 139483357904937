//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Notification,
  Pace,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";
import * as ActionTypes from "constants/ActionTypes";

import PaymentPreview from "components/elements/seller/PaymentPreview";
import PaymentEditor from "components/elements/seller/PaymentEditor";

import "./styles.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import CustomSectionCreator from "components/CustomSectionCreator";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const StoreProfilePayment = (props) => {
  const [state, setState] = useState({ serviceMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopPayment,
    getStorePayment, updateStorePayment, history,
  } = props;

  useEffect(() => {
    getStorePayment();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_PAYMENT_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { serviceMode, storePayment = {} } = state;

  const handlePaymentEdit = (payment) => {
    setState({ serviceMode: "EDIT" });
    setState({
      storePayment: payment,
    });
  };

  const handleServiceEditCancel = (mode) => (e) => {
    setState({ serviceMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updatePaymentCall = (payment) => {
    setState({ serviceMode: "PREVIEW" });
    updateStorePayment(payment);
  };

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<CurrencyRupeeIcon />} title="Payment">
          {serviceMode === "PREVIEW" ? (
              <PaymentPreview
                  isLoading={isLoading}
                  payment={shopPayment}
                  history={history}
                  editPayment={() => handlePaymentEdit(shopPayment)}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          ) : (
              <PaymentEditor
                  setState={(value, field) => setState({
                    ...state,
                    storePayment: {
                      ...storePayment,
                      [field]: value,
                    },
                  })}
                  payment={storePayment}
                  updatePaymentCall={updatePaymentCall}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfilePayment.propTypes = {
  history: PropTypes.object.isRequired,
  shopPayment: PropTypes.object.isRequired,
  updateStorePayment: PropTypes.func.isRequired,
  getStorePayment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const storeProfilePayment = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopPayment: store.storePayment,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfilePayment);

export { storeProfilePayment as default };
