import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Notification,
    Pace
} from "components/Atoms";
import {Box, Container} from "@mui/material";
import { H4, H5 } from "components/CustomTypography";

import * as Actions         from "actions/seller/seller.products";

import {useEffect} from "react";
import PhotoUpload from "components/PhotoUpload";

const PrivateProductImageMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, product, cancelEdit,
        addPrivateProductImage, removePrivateProductImage,
        productImageList
    } = props;

    const {prod_id, name, images} = product;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        product.images = productImageList.images;
    }, [serviceError, reqStatus]);


    const addImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        addPrivateProductImage(product.prod_id, formData);
    }

    const filter = (id) => {
        return images.filter(image => image.cdn.public_id !== id)
    }

    const removeImageCallback = (id) => {
        product.images = product.images.filter(image => image.cdn.public_id !== id);
        removePrivateProductImage(product.prod_id, id);
    }

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={true}
                             images={images}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );

};

// Mapping Props and State.
const _PrivateProductImageMain = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      productImageList: products.productImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(PrivateProductImageMain);

export { _PrivateProductImageMain as default };
