import * as React from "react";

import "./style.scss";
import { Box, Container } from "@mui/material";
import {getLocalDate, getPriceCurrency, parseTextWithLinks} from "helpers/utils";
import CustomSectionCreator from "components/CustomSectionCreator";
import {H2, H4, H5} from "components/CustomTypography";
import ButtonComponent from "components/Button";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import {DATE_FORMAT_SHORT} from "../../../../config/constants";

const SubscriptionOrder = (props) => {

    const { subscriptionorder = {}, handlePayment, currency, history, onCancel, isLoadingBootInfo  } = props;

  const { _id, order_id, plans, order_type, status, paymentstatus, payment_id, paymentmethod,
      total_price, total_service, total_tax, total_order, messages } = subscriptionorder;

    const renderOrderInformation = () => (
        <Box ml={2} mt={2}>
            <H2 mb={2} fontSize={16}>Order # {order_id}</H2>
        </Box>
    );


    const renderPaymentInformation = () => (
        <Box ml={2}>
            <H2 mb={2} fontSize={16}> Payment Status: {paymentstatus} </H2>
            {paymentstatus === "due" ? (<ButtonComponent
                type="primary"
                name="Pay for Order"
                fullWidth
                onClick={handlePayment}
            >
            </ButtonComponent>) : "Payment ID: " + payment_id + "(" + paymentmethod + ")"
            }
        </Box>
    );

    const renderOrderItems = () => (
        <Box>
            {plans.map((item, index) => (
                <Box className="styled-card" width="100%" display="flex" p={1}>
                    <Box width="80%" order={1} ml={2}>
                        <H2 mb={2} fontSize={16}  ellipsis>
                            {item.plan.title}
                        </H2>
                        <H4 fontSize={12}>
                            {item.plan.description}
                        </H4>
                    </Box>
                    <Box width="20%" order={2} ml={2}>
                        <H2 mb={2} fontSize={16}  ellipsis>
                            {getPriceCurrency(item.price, currency)}
                        </H2>
                    </Box>
                </Box>
            ))}
        </Box>
    );


    const renderOrderTotal = () => (
        <Box>
            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Subtotal: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_price, currency)}</H2>
                </Box>
            </Box>

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Service Charge: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_service, currency)}</H2>
                </Box>
            </Box>

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Taxes: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_tax, currency)}</H2>
                </Box>
            </Box>

            <hr className="solid" />

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Total: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_order, currency)}</H2>
                </Box>
            </Box>
        </Box>
    );

    const renderOrderMessages = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Instructions" />
            <Box mb={2} width="100%">
                {messages && messages.map((messageItem, index) => (
                    <Box width="100%" display="flex">
                            <H4 fontSize={14} ellipsis>
                                {parseTextWithLinks(messageItem.message)}
                            </H4>
                    </Box>
                ))}
            </Box>
        </Box>
    );


    return (
      <div>
          <ShopHeaderNav onCancel={onCancel} />
          <CustomSectionCreator isSubtle={true} title="Order Information" />
          <Container>
              <Box>
                  {renderOrderInformation()}
                  <hr className="solid" />
                  {isLoadingBootInfo ? <MockGeneralHeader /> : renderPaymentInformation()}
                  <hr className="solid" />
                  {renderOrderItems()}
                  <hr className="solid" />
                  {renderOrderTotal()}
                  <hr className="solid" />
                  {renderOrderMessages()}
              </Box>
          </Container>
      </div>

  );
};

export default SubscriptionOrder;
