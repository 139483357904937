/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Container, Box, IconButton
} from "@mui/material";

// Import Swiper styles
import 'swiper/swiper.min.css';

import { connect } from "react-redux";
import * as Actions from "actions";
import { bindActionCreators } from "redux";
import CategoryItem from "./CategoryItems";
import CustomSectionCreator from "components/CustomSectionCreator";
import StorageIcon from "@mui/icons-material/Storage";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {H2, H4} from "components/CustomTypography";
import CustomFlexBox from "components/CustomFlexBox";


const UserProductCategorySlider = (props) => {

  const { commonCategoryList, isDirect, history, includeAll, handleSelectCategorySet, selectedCategory, openCategoryList } = props;

  const handleSelectCategoryLink = (id) => {
    history.push("/products/category/" + id);
  };

  if (!commonCategoryList) {
    return null;
  }

  const { categories } = commonCategoryList;

  if (!categories.length) {
    return <></>;
  }

  let popularlist = [];
  let categorylist = null;

  categories && categories.length > 0 && categories.forEach((category) => {
    category.children.forEach((child) => {
      if (child.is_popular === true) {
          popularlist.push(child);
      }
      if (selectedCategory && child._id === selectedCategory) {
          categorylist = category;
      }
    });
  });

    const breakpoints1 = {
        480: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 6,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 7,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 8,
            spaceBetween: 20,
        },
    };

    const breakpoints2 = {
        480: {
            slidesPerView: 7,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 8,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 9,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 12,
            spaceBetween: 20,
        },
    };

  return (
      <Container>
          <swiper-container slides-per-view="4.5" space-between="0" free-mode="true" breakpoints={JSON.stringify(breakpoints1)}>
              {includeAll && (<swiper-slide>
                  <Box sx={{width: '100%', alignItems: 'center'}}>
                      <Box pb={1} onClick={openCategoryList}>
                          <IconButton sx={{
                              padding: '0px'
                          }} color="primary">
                              <ViewModuleIcon sx={{
                                  height: '60px',
                                  width: '60px',
                              }}/>
                          </IconButton>
                          <H2 textTransform="capitalize" fontSize={12} textAlign="center" ellipsis>All Categories</H2>
                      </Box>
                  </Box>
              </swiper-slide>)
              }
              { popularlist.map((category, index) => (
                  <swiper-slide  key={index+2}>
                      <Box sx={{ width: '100%', alignItems: 'center' }}>
                          <CategoryItem key={`${index + 2}`} isDirect={isDirect} category={category} handleSelectCategoryLink={handleSelectCategoryLink} handleSelectCategorySet={handleSelectCategorySet} />
                      </Box>
                  </swiper-slide>
              )) }
          </swiper-container>

          {categorylist &&
              <>
                  <CustomFlexBox bgcolor="header" justifyContent="space-between" alignItems="center" p={1} />
                  <swiper-container slides-per-view="5.5" space-between="0" free-mode="true" breakpoints={JSON.stringify(breakpoints2)}>
                      {categorylist.children.length > 0 && categorylist.children.map((category, index) => (
                          <swiper-slide key={index}>
                              <Box sx={{ width: '100%', alignItems: 'center'}}>
                                  <CategoryItem key={`${index + 1}`} isDirect={isDirect} isSmall={true} category={category}
                                                handleSelectCategoryLink={handleSelectCategoryLink}
                                                handleSelectCategorySet={handleSelectCategorySet}/>
                              </Box>
                          </swiper-slide>
                      ))}
                  </swiper-container>
              </>
          }
      </Container>

  );
};

UserProductCategorySlider.propTypes = {
  usergetProductCategories: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const _userProductCategorySlider = connect(
  ({ userMainProducts, bootupInfo }) => ({
    bootupInfo,
    commonCategoryList: userMainProducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductCategorySlider);

export default _userProductCategorySlider;
