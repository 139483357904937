import React, { useEffect } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    Notification, Pace,
} from "components/Atoms";

import {Box, Container} from "@mui/material";
import PhotoUpload from "components/PhotoUpload";
import { H5 } from "components/CustomTypography";
import { checkUserAllowed } from "helpers/utils.js";
import * as Actions         from "actions";

const AdminSalesImageMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, lead, cancelEdit,
        adminAddSalesLeadImage, adminRemoveSalesLeadImage, adminUpdateSalesLeadCard,
        adminResetLeadImages,
        leadImageList,
        userDetails
    } = props;


    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            let feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        lead.images = leadImageList.images;
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {
        adminResetLeadImages();
        const formData = new FormData()
        formData.append("image", file);
        adminAddSalesLeadImage(lead.id, formData);
    }

    const removeImageCallback = (id) => {
        adminResetLeadImages();
        lead.images = lead.images.filter(image => image.cdn.public_id !== id);
        adminRemoveSalesLeadImage(lead.id, id);
    }

    const scanImageCallback = (id) => {
        adminUpdateSalesLeadCard({ lead_id: lead.id, image_id: id });
    }

    const {id, name, images} = lead;

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={checkUserAllowed(userDetails.userlevel, "salesops")}
                             images={images}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             scanImageCallback={scanImageCallback}
                             isScanAllowed={checkUserAllowed(userDetails.userlevel, "scancard")}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );

};

// Mapping Props and State.
const adminSalesImageMain = connect(
    ({ adminSales, bootupInfo }) => ({
      bootupInfo: bootupInfo,
        userDetails: bootupInfo.userDetails,
      reqStatus: adminSales.reqStatus,
      isLoading: adminSales.isLoading,
      serviceError: adminSales.serviceError,
        leadImageList: adminSales.leadImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(AdminSalesImageMain);

export { adminSalesImageMain as default };
