import React from 'react';
import { useCallback, useState } from "react";
import Slider from '@mui/material/Slider';
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import CustomFlexBox from "./CustomFlexBox";
import {Box} from "@mui/material";
import ButtonComponent from "components/Button";
import Images from "components/elements/main/Images";
import FileVideoButton from "components/elements/main/FileVideoButton";
import CameraButton from "components/elements/main/CameraButton";
import {dataURIToBlob, resizeFile} from "services/utils";

const VideoUpload = ({ images, addVideoCallback, removeImageCallback, scanImageCallback, cancelEdit, isRemoveAllowed, isScanAllowed, key, idx }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [image, setImage] = useState(null);

  /*
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      addImageCallback(croppedImage);
      setCroppedImage(null);
      setImage(null);

    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);


  const cancelUpload = () => {
    setCroppedImage(null);
    setImage(null);
  }

   */


  const addCameraImage = (file) => {

    (async () => {
      const cameraimage = await resizeFile(dataURIToBlob("data:image/jpeg;base64,"+file));
      setImage(cameraimage);
    })();
  }

  const addFileImage = (e) => {

    const files = Array.from(e.target.files)
    const errs = []
    const types = ['video/mp4'];

    files.forEach(async (file) => {

      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`)
      }

      /*
      if (file.size > 150000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`)
      }

       */

      if (errs.length) {
        return errs.forEach(err => this.sendNotification("error", {title: err, description: err}))
      }

      /*
      const fileimage = await resizeFile(file);
      setImage(fileimage);
*/
      addVideoCallback(file);
    });
  }

  const filter = id => {
    return images.filter(image => image.cdn.public_id !== id)
  }

  const handleError = id => {
    setImage(filter(id))
  }

  return (
    <Box key={key}>
      <Box width="100%" alignItems="left" display="flex">
        <Box width="35%" >
          <FileVideoButton idx={idx} onFileSelect={addFileImage} />
        </Box>
      </Box>
    </Box>
  );
};

export default VideoUpload;
