import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Span } from 'components/CustomTypography';

import "./style.scss";

const SellerProductStatusSelector = (props) => {

    const {
        onStatusSelect, productstatus
    } = props;

    return (
          <Box>
            <Box pb={2}>
              <span style={{ paddingRight: "5px" }}>
              {!productstatus || productstatus === "new" ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("new")}>Latest</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("new")}>Latest</Button>
              }
              </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "popular" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("popular")} >Popular</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("popular")} >Popular</Button>
                }
                </span>
            </Box>
          </Box>
    );
}

export default SellerProductStatusSelector;
