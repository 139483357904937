import * as React from "react";

import "./style.scss";
import {Box, Card, IconButton, Typography} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { getLocalDate } from "helpers/utils";


const SellerOrder = (props) => {

    const { order = {}, editOrderStatus } = props;
  const { _id, order_id, price, order_type, status, user, order_date } = order;

  return (
      <Card key={_id} className="m-2">
          <Box alignContent="center" width="100%" display="flex" p={2}>
          <Box width="20%" order={1}>
                  <Typography align="left">
                      {order_id} ({order_type})
                  </Typography>
              </Box>
              <Box width="60%" order={2}>
                  <Typography align="left">
                      {user ? (<div className="delivery"><div>{user.name}</div><div>{user.mobile}</div><div>{user.phone}</div></div>) : <div className="delivery">User not available (please contact us)</div>}
                      {getLocalDate(order_date)}
                  </Typography>
              </Box>
              <Box width="20%" order={3}>
                  <Typography align="left">

                  </Typography>
                  <Typography align="left">
                      {status}
                  </Typography>
                  <IconButton color="primary" size="small" onClick={editOrderStatus}>
                      <EditIcon />
                  </IconButton>
              </Box>
          </Box>
      </Card>
  );
};

export default SellerOrder;
