import * as React from "react";
import ButtonComponent from "components/Button";
import {Avatar, Box, IconButton, Typography} from "@mui/material";
import { H6 } from "components/CustomTypography";
import {schema} from "../ServiceEditor/helper";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { getLogo } from "helpers/utils";

const PaymentPreview = ({
  isLoading, payment, editPayment, cancelEdit, history,
}) => {
  const { paymentoption, paymentkey, paymentupi, paymentqrcode } = payment;

    const handlePage = (page) => () => {
        history.push({
            pathname: page,
        });
    };


    const renderPayment = (option) => {
        switch(option) {
            /*
            case "smsbased":
                return (
                    <Typography>
                        <H6>Direct (SMS based):</H6>
                        <H6>Gateway Key:</H6>
                        {`${paymentkey || ""}`}
                    </Typography>
                );
            case "viagateway":
                return (
                    <Typography>
                        Payment via {process.env.REACT_APP_NAME}
                    </Typography>
                );
                */
            case "upibased":
                return (
                    <Typography>
                        Direct to shop (UPI):
                        <H6>UPI ID:</H6>
                        {`${paymentupi || ""}`}
                    </Typography>
                );

            case "qrcodeimage":
            default:
                return (
                    <div>
                        <Typography>
                            Direct to shop (QR Code)
                        </Typography>

                        <Box m={2} display="flex" className="position-relative">
                            <Box>
                                <Avatar onClick={handlePage("/shop/qrcode")} src={getLogo(paymentqrcode)} className="profile-img" />
                                <div className="camera-icon">
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="span"
                                        onClick={handlePage("/shop/qrcode")}
                                        size="large"
                                    >
                                        <PhotoCamera />
                                    </IconButton>
                                </div>
                            </Box>
                        </Box>
                    </div>
                );
        }
    }


  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
          {renderPayment(paymentoption)}
      </Box>
      <Box>
        {!isLoading
              && (
              <ButtonComponent
                sx={{
                  m: 2,
                }}
                variant="contained"
                type="primary"
                name="Edit"
                size="small"
                onClick={editPayment}
              />
              )}
      </Box>
    </Box>
  );
};

export default PaymentPreview;
