import React from 'react';
import ContentLoader from "react-content-loader";

const MockOrderDetail = (props) => (
    <ContentLoader
        speed={2}
        viewBox=""
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    >
        <rect x="10" y="10" width="100%" height="20" />
        <rect x="10" y="40" width="100%" height="20" />
        <rect x="10" y="70" width="100%" height="20" />
        <rect x="10" y="100" width="100%" height="20" />
    </ContentLoader>
)

export default MockOrderDetail;
