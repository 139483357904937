//  @flow
import * as React from "react";
import * as Actions from "actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminMainShopSearch    from "components/pages/admin/shops/AdminMainShopSearch";

import { checkUserAllowed } from "helpers/utils.js";

import "./styles.scss";
import {Box, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useState} from "react";
import AdminSalesMain from "../../sales/AdminSalesMain";



function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const AdminShops = (props) => {

  const {
    userDetails
  } = props;

  const getActiveTabInitial = () => {

      if (checkUserAllowed(userDetails.userlevel, "shopsrecent")) {
          return 0;
      } else if (checkUserAllowed(userDetails.userlevel, "shopsmysignup")) {
          return 1;
      } else if (checkUserAllowed(userDetails.userlevel, "shopsnearby")) {
          return 2;
      } else {
          return 0;
      }
  }

    const [value, setValue] = useState(getActiveTabInitial());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

  const renderCategories = () => {
    if (checkUserAllowed(userDetails.userlevel, "shops")) {
      return (
          <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        aria-label="">
                      {checkUserAllowed(userDetails.userlevel, "shopsrecent") &&
                          <Tab label="Recent" {...a11yProps(0)} />
                      }
                      {checkUserAllowed(userDetails.userlevel, "shopsmysignup") &&
                          <Tab label="My Signups" {...a11yProps(1)} />
                      }
                      <Tab label="Nearby" {...a11yProps(2)} />
                  </Tabs>
              </Box>
              { checkUserAllowed(userDetails.userlevel, "shopsrecent") &&
                  <TabPanel value={value} index={0}>
                      <AdminMainShopSearch {...props} shopsearchtype="new" ></AdminMainShopSearch>
                  </TabPanel>
              }
              { checkUserAllowed(userDetails.userlevel, "shopsmysignup") &&
                  <TabPanel value={value} index={1}>
                      <AdminMainShopSearch {...props} shopsearchtype="my" ></AdminMainShopSearch>
                  </TabPanel>
              }
              <TabPanel value={value} index={2}>
                  <AdminMainShopSearch {...props} shopsearchtype="nearby" ></AdminMainShopSearch>
              </TabPanel>

          </Box>
      )
    } else {
      return (
          <div>Not available </div>
      )
    }
  }

  return (
      <div>
        {
          renderCategories()
        }
      </div>
  );
}

const adminShops = connect(
    ({ bootupInfo }) => ({
      bootupInfo,
      userDetails: bootupInfo.userDetails,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShops);

export { adminShops as default };

