//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Notification,
  Pace,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";
import * as ActionTypes from "constants/ActionTypes";

import ReturnsPreview from "components/elements/seller/ReturnsPreview";
import ReturnsEditor from "components/elements/seller/ReturnsEditor";

import "./styles.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import CustomSectionCreator from "components/CustomSectionCreator";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';

const StoreProfileReturns = (props) => {
  const [state, setState] = useState({ serviceMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopReturns,
    getStoreReturns, updateStoreReturns,
  } = props;

  useEffect(() => {
    getStoreReturns();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_RETURNS_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { serviceMode, storeReturns = {} } = state;

  const handleServiceEdit = (service) => {
    setState({ serviceMode: "EDIT" });
    setState({
      storeReturns: service,
    });
  };

  const handleServiceEditCancel = (mode) => (e) => {
    setState({ serviceMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateServiceCall = (service) => {
    setState({ serviceMode: "PREVIEW" });
    updateStoreReturns(service);
  };

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<KeyboardReturnIcon />} title="Return Policy">
          {serviceMode === "PREVIEW" ? (
              <ReturnsPreview
                  isLoading={isLoading}
                  service={shopReturns}
                  editService={() => handleServiceEdit(shopReturns)}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          ) : (
              <ReturnsEditor
                  setState={(value, field) => setState({
                    ...state,
                    storeReturns: {
                      ...storeReturns,
                      [field]: value,
                    },
                  })}
                  service={storeReturns}
                  updateServiceCall={updateServiceCall}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfileReturns.propTypes = {
  history: PropTypes.object.isRequired,
  shopReturns: PropTypes.object.isRequired,
  updateStoreReturns: PropTypes.func.isRequired,
  getStoreReturns: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const _StoreProfileReturns = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopReturns: store.storeReturns,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileReturns);

export { _StoreProfileReturns as default };
