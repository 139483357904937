/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
    Select, MenuItem, FormControl, InputLabel
} from "@mui/material";
import { H4 } from "components/CustomTypography";
import CustomSnackBar from "components/CustomSnackBar";
import * as ActionTypes from "constants/ActionTypes";
import * as yup from "yup";

import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import OTPInput from "otp-input-react";

const LoginPreOTPForm = ({
  loginPreOTP, serviceError, reqStatus, showPasswordLogin,
}) => {
  const schema = yup.object().shape({
    mobile: yup.string().required("Enter mobile number").matches(/^[0-9]+$/, "Must be only digits").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits'),
  });

  const [values, setValues] = useState({
      mobile: "",
  });

    const [code, setCode] = useState("91");
    const [mobile, setMobile] = useState("");
    const [blur, setBlur] = useState(false);

  const onFinish = (number) => {
      setMobile(number);
      if (number.length > 9) {
          loginPreOTP(code, number);
      }
  };

  const hasError = () => {
    try {
      return !schema.validateSync({ mobile: values.mobile });
    } catch (err) {
      return true;
    }
  };

  const handleBlur = () => {
    setBlur(true);
  };

  const getError = (field) => {
    if (hasError()) {
      try {
        schema.validateSyncAt(field, values[field]);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
      >
          <Grid item mt={2}>
              <H4 fontSize="16px">
                  Enter Mobile Number
              </H4>
          </Grid>

          <Grid item mt={2}>
              <FormControl>
                  <InputLabel id="simple-select-autowidth-label">Country</InputLabel>
                  <Select
                      labelId="simple-select-autowidth-label"
                      id="code"
                      autoWidth
                      defaultValue={code}
                      onChange={(event) => { setCode(event.target.value) }}
                      label="Country"
                  >
                      <MenuItem value={1}>US (+1)</MenuItem>
                      <MenuItem value={91}>India (+91)</MenuItem>
                  </Select>
              </FormControl>
          </Grid>
          <Grid item textAlign="center">
              <FormControl sx={{ m: 1}}>
                  <OTPInput
                      value={mobile}
                      onChange={onFinish}
                      OTPLength={10}
                      otpType="number"
                      autoFocus={true}
                      inputStyles={{
                          //width: "1.8rem",
                          height: "3rem",
                          padding: "0px",
                          margin: "1px",
                          fontSize: "1.8rem",
                          borderRadius: 4,
                          border: "1px solid rgba(0,0,0,0.3)"
                      }}
                  />
              </FormControl>
          </Grid>
          <Grid container>
              <Grid item>
                  {reqStatus === ActionTypes.LOGIN_POST_OTP_FAILURE && (
                      <CustomSnackBar
                          status
                          errorMessage={serviceError}
                      />
                  )}
              </Grid>
              <Grid container item sx={{height: "100px"}} direction="column" justifyContent="flex-end" alignItems="flex-end">
                  <Grid item>
                      <Link onClick={showPasswordLogin} variant="button">
                          {"Password Login"}
                      </Link>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
  );
};

LoginPreOTPForm.propTypes = {
  loginPreOTP: PropTypes.func.isRequired,
  reqStatus: PropTypes.string.isRequired,
    serviceError: PropTypes.string.isRequired,
};

export default LoginPreOTPForm;
