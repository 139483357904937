import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Notification,
    Pace
} from "components/Atoms";
import {Box, Container, IconButton, Typography} from "@mui/material";
import ButtonComponent from "components/Button";
import { H4, H5 } from "components/CustomTypography";

import * as Actions         from "actions/seller/seller.products";

import Images               from "components/elements/main/Images";
import CameraButton         from "components/elements/main/CameraButton";
import FileButton from "components/elements/main/FileButton";

import {dataURIToBlob, resizeFile} from "services/utils";
import PhotoUpload from "components/PhotoUpload";
import {useEffect} from "react";

const PrivateProductQuickAddMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, product, cancelEdit,
        addPrivateProductImageQuick, removePrivateProductImage,
        productImageList
    } = props;

    const {prod_id, name, images} = product;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        addPrivateProductImageQuick(formData);
    }

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={false}
                             images={images}
                             addImageCallback={addImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );
};

// Mapping Props and State.
const privateProductQuickAddMain = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      productImageList: products.productImageList,
        product: products.productinfo,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(PrivateProductQuickAddMain);

export { privateProductQuickAddMain as default };
