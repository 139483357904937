// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as Actions     from "actions/admin/admin.subscription";
import * as ActionTypes from 'constants/ActionTypes';
import {
  ADMIN_GET_SUBSCRIPTION_ORDER_ID_START,
  ADMIN_GET_SUBSCRIPTION_ORDERS_START,
  ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START
} from "constants/ActionTypes";

function* adminGetAllSubscriptionsOrders(action: Object) {
  try {
    const response = yield API.get(
        `/admin/subscriptions/get-orders`,
        action.payload
    );
    yield put(
        Actions.adminGetAllSubscriptionsOrdersSuccess(response)
    );
  } catch (error) {
    yield put(Actions.adminGetAllSubscriptionsOrdersFailure(error));
  }
}

function* adminGetSubscriptionsOrderById(action: Object) {
  try {
    const response = yield API.get(
        `/admin/subscriptions/get-order/` + action.payload
    );
    yield put(
        Actions.adminGetSubscriptionsOrderByIdSuccess(response)
    );
  } catch (error) {
    yield put(Actions.adminGetSubscriptionsOrderByIdFailure(error));
  }
}

function* adminAddSubscriptionOrderMessage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/subscriptions/add-message`,
        action.payload
    );

    yield put(Actions.adminAddSubscriptionOrderMessageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSubscriptionOrderMessageFailure(error));
  }
}


function* adminGetAllSubscriptions(action: Object) {
  try {
    const response = yield API.get(
      `/admin/subscriptions/subscription-plans`,
      action.payload
    );
    yield put(
      Actions.adminGetAllSubscriptionsSuccess(response)
    );
  } catch (error) {
    yield put(Actions.adminGetAllSubscriptionsFailure(error));
  }
}

function* adminAddSubscriptionPlan(action: Object) {
  try {
    const response = yield API.post(
        `/admin/subscriptions/add-subscription-plan`,
        action.payload
    );

    yield put(Actions.adminAddSubscriptionPlanSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSubscriptionPlanFailure(error));
  }
}

function* adminUpdateStatusSubscription(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscriptions/subscription-plans`,
        action.payload
    );

    yield put(Actions.adminUpdateStatusSubscriptionSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateStatusSubscriptionFailure(error));
  }
}

export default function* SubscriptionsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_START, adminGetAllSubscriptionsOrders);
  yield takeLatest(ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_START, adminGetSubscriptionsOrderById);
  yield takeLatest(ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START, adminAddSubscriptionOrderMessage);
  yield takeLatest(ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_START, adminGetAllSubscriptions);
  yield takeLatest(ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_START, adminAddSubscriptionPlan);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START, adminUpdateStatusSubscription);
}
