/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import AdminSubscriptionSearch from "components/widgets/admin/AdminSubscriptionSearch";
import "components/elements/main/index.scss";
import { checkUserAllowed } from "helpers/utils.js";


const AdminMainShopSearch = (props) => {

  const [state] = useState({
    layoutColumns: 3,
  });

    const {
        userDetails
    } = props;


    useEffect(() => {
    props.adminResetSearchShops();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

    const renderShopSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "subscriptions")) {
            return <AdminSubscriptionSearch {...props} colSize={state.layoutColumns} />;
        } else {
            return  <div>Not available </div>;
        }
    }

    return (
        <div>
            {
                renderShopSearch()
            }
        </div>
    );
};

AdminMainShopSearch.propTypes = {
    adminResetSearchShops: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminMainShopSearchComponent = connect(
  ({ adminProducts, bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: adminProducts.reqStatus,
    isLoading: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminMainShopSearch);

export { AdminMainShopSearchComponent as default };
