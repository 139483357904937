//  @flow
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Page,
  Notification,
  Pace,
} from "components/Atoms";
import {
  Container, Box, Card, IconButton
} from "@mui/material";
import {H4, H5} from 'components/CustomTypography';

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "actions/seller/seller.store";

import SocialPreview from "components/elements/seller/SocialPreview";
import SocialEditor from "components/elements/seller/SocialEditor";

import "./styles.scss";
import CustomSectionCreator from "components/CustomSectionCreator";
import InstagramIcon from '@mui/icons-material/Instagram';
import {openFacebook, openInstagram, openWhatsApp} from "helpers/utils";


const StoreProfileSocial = (props) => {

  const [state, setState] = useState({ addressMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopSocial,
    getStoreSocial, updateStoreSocial
  } = props;

  useEffect(() => {
    getStoreSocial();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_SOCIAL_SUCCESS:
        getStoreSocial();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { addressMode, storeSocial = {} } = state;

  const handleSocialEdit = (social) => {
    setState({ addressMode: "EDIT" });
    setState({
      storeSocial: social,
    });
  };

  const handleSocialEditCancel = (mode) => (e) => {
    setState({ addressMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateSocialCall = (social) => {
    setState({ addressMode: "PREVIEW" });
    updateStoreSocial(social);
  };

  const handleSocialOpenInsta = (mode) => {
    openInstagram(mode && mode.instahandle);
  };

  const handleSocialOpenFB = (mode) => {
    openFacebook(mode && mode.facebookhandle);
  };

  const handleSocialOpenWA = (mode) => {
    openWhatsApp(mode && mode.whatsapphandle);
  };

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<InstagramIcon />} title="Shop Social Pages">
          {addressMode === "PREVIEW" ? (
              <SocialPreview
                  isLoading={isLoading}
                  social={shopSocial}
                  openSocialInsta={() => handleSocialOpenInsta(shopSocial)}
                  openSocialFB={() => handleSocialOpenFB(shopSocial)}
                  openSocialWA={() => handleSocialOpenWA(shopSocial)}
                  editSocial={() => handleSocialEdit(shopSocial)}
                  cancelEdit={handleSocialEditCancel("PREVIEW")}
              />
          ) : (
              <SocialEditor
                  setState={(event, field) => setState({
                    ...state,
                    storeSocial: {
                      ...storeSocial,
                      [field]: event.target.value,
                    },
                  })}
                  storeSocial={storeSocial}
                  updateSocialCall={updateSocialCall}
                  cancelEdit={handleSocialEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfileSocial.propTypes = {
  history: PropTypes.object.isRequired,
  shopSocial: PropTypes.object.isRequired,
  updateStoreSocial: PropTypes.func.isRequired,
  getStoreSocial: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const storeProfileSocial = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopSocial: store.storeSocial,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileSocial);

export { storeProfileSocial as default };
