/* eslint-disable no-underscore-dangle */
import React from "react";
import {
  Box
} from "@mui/material";
import PropTypes from "prop-types";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {getImage, } from "helpers/utils";
import {H2, } from "components/CustomTypography";

const CategoryItem = (props) => {
     const { isDirect, isSmall, category, handleSelectCategoryLink, handleSelectCategorySet } = props;

    return (<Box pb={1} pt={1} onClick={isDirect ? () => handleSelectCategoryLink(category._id) : () => handleSelectCategorySet(category)}>
        <HoverBox  mb={0} mx="auto">
            <LazyImage
                src={getImage(category.image)}
                width={isSmall ? 50 : 60}
                height={isSmall ? 50 : 60}
                mx="auto"
            />
        </HoverBox>
        <H2 textTransform="capitalize" fontSize={isSmall ? 10 : 12} textAlign="center" title={category.name} ellipsis>
            {category.name}
        </H2>
    </Box>);

};

CategoryItem.propTypes = {
  category: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CategoryItem;
