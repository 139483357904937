import * as yup from "yup";

export const schema = yup.object().shape({
  is_popular: yup.boolean().default(true),
  is_active: yup.boolean().default(true),
});


export const getButtonData = (cancelEdit, hasError, isTouched) => [
  {
    name: "Cancel",
    variant: "outlined",
    onClick: cancelEdit,
    type: "primary",
    disabled: false,
  },
];

export const getTextfieldDataKeyword = (keyword) => [
  {
    label: "Keyword",
    value: keyword,
    onChangeField: "keyword",
  },
];

export const getTextfieldDataPhone = (phone) => [
  {
    label: "Phone",
    value: phone,
    onChangeField: "phone",
  },
];

export const getButtonDataKeywordSearch = (onSearchFuturesKeyword) => [
  {
    name: "Search",
    onClick: onSearchFuturesKeyword,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];
