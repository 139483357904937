// @flow
import * as ActionTypes from '../../constants/ActionTypes';
import {ADMIN_ADD_OFFER_FAILURE, ADMIN_ADD_OFFER_START, ADMIN_ADD_OFFER_SUCCESS} from "../../constants/ActionTypes";


export const adminSearchOffers = (name, filter, startdate) => ({
  type: ActionTypes.ADMIN_SEARCH_OFFERS_START,
  name: name,
  filter: filter,
  startdate: startdate,
});

export const adminSearchOffersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_OFFERS_SUCCESS,
    payload: data,
  };
};

export const adminSearchOffersFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_OFFERS_FAILURE,
  payload: error,
});

export const adminShopOffers = (reqData) => ({
  type: ActionTypes.ADMIN_SHOP_OFFERS_START,
  shopId: reqData,
});

export const adminShopOffersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SHOP_OFFERS_SUCCESS,
    payload: data,
  };
};

export const adminShopOffersFailure = (error) => ({
  type: ActionTypes.ADMIN_SHOP_OFFERS_FAILURE,
  payload: error,
});

export const adminGetOfferInfo = (reqData) => ({
  type: ActionTypes.ADMIN_GET_OFFER_INFO_START,
  payload: reqData,
});

export const adminGetOfferInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_OFFER_INFO_SUCCESS,
    payload: data,
  };
};
export const adminGetOfferInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_OFFER_INFO_FAILURE,
  payload: error,
});


export const adminUpdateOfferStatus = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_OFFER_STATUS_START,
  payload: reqData,
});

export const adminUpdateOfferStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_OFFER_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminUpdateOfferStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_OFFER_STATUS_FAILURE,
  payload: error,
});

export const adminAddOfferInfo = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_OFFER_START,
  payload: reqData,
});

export const adminAddOfferInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_OFFER_SUCCESS,
    payload: data,
  };
};
export const adminAddOfferInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_OFFER_FAILURE,
  payload: error,
});

export const adminUpdateOfferInfo = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_OFFER_INFO_START,
  payload: reqData,
});

export const adminUpdateOfferInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_OFFER_INFO_SUCCESS,
    payload: data,
  };
};
export const adminUpdateOfferInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_OFFER_INFO_FAILURE,
  payload: error,
});

export const adminAddOfferQuickInfo = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_OFFER_QUICK_START,
  payload: reqData,
  formData: formData
});

export const adminAddOfferInfoQuickSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_OFFER_QUICK_SUCCESS,
    payload: data,
  };
};
export const adminAddOfferInfoQuickFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_OFFER_QUICK_FAILURE,
  payload: error,
});
