/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Box } from "@mui/material";
import * as Actions from "actions";

// Import Swiper styles
import 'swiper/swiper.min.css';
//import 'swiper/components/navigation/navigation.min.css';
import './UserProductPopularSlider.scss';

// import Custom Components
import UserProductItem from "components/elements/user/products/UserProductItem";
import MockProductBox from "components/elements/main/MockProductBox";
import CustomSectionCreator from "components/CustomSectionCreator";
import {isFeatureSupported} from "helpers/utils";

const UserProductPopularSlider = (props) => {
  const {
    products, currency, addProductToCart, addProductToWishlist,
    addToCompare, userMainGetPopularProducts, isLoading, marketId
  } = props;

  useEffect(() => {
      userMainGetPopularProducts("", "", "", marketId);
  }, []);

    const breakpoints = {
        300: {
            slidesPerView: 2.0,
            spaceBetween: 20,
        },
        340: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        380: {
            slidesPerView: 2.4,
            spaceBetween: 20,
        },
        420: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 2.8,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    const renderResults = () => (

    <Container>
        {<CustomSectionCreator title={'Popular ' + process.env.REACT_APP_PRODUCT_TITLE} seeLink="/products/sort/popular" seeMoreLinkTitle={isFeatureSupported("shopreels") ? "View " + process.env.REACT_APP_REELS_TAG : "View more"} seeMoreLink={isFeatureSupported("shopreels") ? "/products/sort/popular?ls=1" : "/products/sort/popular"}   />}
        <swiper-container slides-per-view="1.8" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
            { products.map((product, index) => (
                <swiper-slide key={index}>
                    <Box sx={{ width: 150 }}>
                        <UserProductItem
                            product={product}
                            isDirect={true}
                            link={isFeatureSupported("shopreels") ? "/products/sort/popular?ls=1&li=" + index : ""}
                            currency={currency}
                            onAddToCompareClicked={() => addToCompare(product)}
                            onAddToWishlistClicked={() => addProductToWishlist(product)}
                            onAddToCartClicked={() => addProductToCart(product, "")}
                        />
                    </Box>
                </swiper-slide>
            ))}
        </swiper-container>
    </Container>
    );

  return (

      <>
          {isLoading ? <MockProductBox />
              : products.length > 0
                  ? renderResults()
                  : ""}

      </>


  );
};

UserProductPopularSlider.defaultProps = {
  symbol: "$",
};

UserProductPopularSlider.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
    userMainGetPopularProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const UserProductPopularSliderComponent = connect(
  ({ userMainProducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainProducts.reqStatus,
    isLoading: userMainProducts.isLoading,
    serviceError: userMainProducts.serviceError,
    products: userMainProducts.popularproducts,
    currency: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductPopularSlider);

export default UserProductPopularSliderComponent;
