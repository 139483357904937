// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.sales";
import {
  adminSearchSalesFutureFailure,
  adminSearchSalesFutureSuccess, adminUpdateSalesFutureFailure,
  adminUpdateSalesFutureSuccess, adminUpdateSalesLeadPlaceIdFailure, adminUpdateSalesLeadPlaceIdSuccess
} from "actions/admin/admin.sales";
import * as queryString from "querystring";

const querystring = require('querystring');

function* adminSearchSalesLead(action: Object) {
  try {

    let locationquery = {};

    if (action.name === "nearby" || action.name === "mynearby") {
      if (navigator.geolocation) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        locationquery = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    if ((action.name === "exact" || action.name === "search" || action.name === "map") && action.location) {
      locationquery = {
        lat: action.location.coordinates[1],
        lng: action.location.coordinates[0],
      };
    }

    const searchQuery = querystring.stringify({
      type: action.name ? action.name : "",
      leadstatus: action.leadstatus ? action.leadstatus : "",
      start: action.start ? action.start : "",
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
      filter: action.filter ? action.filter : "",
      exec: action.executive ? action.executive : "",
      shopname: action.shopname ? action.shopname : "",
      shopphone: action.shopphone ? action.shopphone : "",
      offset: action.offset ? action.offset : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
      `/admin/sales/search-leads` + query
    );
    yield put(Actions.adminSearchSalesLeadSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchSalesLeadFailure(error));
  }
}

function* adminSearchSalesLeadKeywords(action: Object) {
  try {

    let locationquery = {};

    if (action.searchtype === "nearby" || (action.searchtype === "mynearby")) {
      if (navigator.geolocation) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        locationquery = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    if (action.searchtype === "exact" && action.location) {
      locationquery = {
        lat: action.location.coordinates[1],
        lng: action.location.coordinates[0],
      };
    }

    const searchQuery = querystring.stringify({
      searchtype: action.searchtype ? action.searchtype : "",
      name: action.name ? action.name : "",
      execId: action.execId ? action.execId : "",
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/sales/get-lead-keywords` + query
    );
    yield put(Actions.adminSearchSalesLeadKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchSalesLeadKeywordsFailure(error));
  }
}

function* adminGetSalesLeadInfo(action: Object) {
  try {
    const response = yield API.get(
        `/admin/sales/get-lead-info/` + action.payload
    );
    yield put(Actions.adminGetSalesLeadInfoSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetSalesLeadInfoFailure(error));
  }
}


function* adminAddSalesLead(action: Object) {
  try {

    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }

    const response = yield API.post(
        `admin/sales/add-lead`,
        {... action.payload, ...query}
    );
    yield put(Actions.adminAddSalesLeadSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSalesLeadFailure(error));
  }
}

function* adminUpdateSalesLead(action: Object) {
  try {
    const response = yield API.post(
        `admin/sales/update-lead/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateSalesLeadSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateSalesLeadFailure(error));
  }
}

function* adminUpdateSalesLeadCard(action: Object) {
  try {
    const response = yield API.post(
        `admin/sales/update-lead-cardinfo/` + action.payload.lead_id,
        action.payload
    );
    yield put(Actions.adminUpdateSalesLeadCardSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateSalesLeadCardFailure(error));
  }
}

function* adminUpdateSalesLeadEvent(action: Object) {
  try {
    const response = yield API.post(
        `admin/sales/update-lead-event/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateSalesLeadEventSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateSalesLeadEventFailure(error));
  }
}

function* adminUpdateSalesLeadPlaceId(action: Object) {
  try {

    const response = yield API.post(
        `admin/sales/update-placeid`,
        action.payload
    );
    yield put(Actions.adminUpdateSalesLeadPlaceIdSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateSalesLeadPlaceIdFailure(error));
  }
}

function* adminAddSalesLeadImage(action: Object) {
  try {

    let locationquery = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      locationquery = {
        lat: latitude,
        lng: longitude,
      };
    }

    const searchQuery = querystring.stringify({
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.post(
        `admin/sales/add-lead-images/` + action.payload + query,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddSalesLeadImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSalesLeadImageFailure(error));
  }
}

function* adminRemoveSalesLeadImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/sales/remove-lead-image`,
        action.payload
    );
    yield put(Actions.adminRemoveSalesLeadImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminRemoveSalesLeadImageFailure(error));
  }
}

function* adminAddSalesLeadImageQuick(action: Object) {
  try {

    let locationquery = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      locationquery = {
        lat: latitude,
        lng: longitude,
      };
    }

    const searchQuery = querystring.stringify({
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;


    const response = yield API.post(
        `admin/sales/add-lead-quick` + query,
        action.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.adminAddSalesLeadImageQuickSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddSalesLeadImageQuickFailure(error));
  }
}

function* adminGetSalesExecutives(action: Object) {
  try {
    const response = yield API.get(
        `/admin/sales/get-executives`,
        action.payload
    );
    yield put(Actions.adminGetSalesExecutivesSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetSalesExecutivesFailure(error));
  }
}

function* adminUpdateLeadExecutive(action: Object) {
  try {

    const response = yield API.post(
        `admin/sales/update-executive` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateLeadExecutiveSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateLeadExecutiveFailure(error));
  }
}


function* adminSearchSalesFuture(action: Object) {
  try {

    let locationquery = {};

    if (action.name === "nearby") {
      if (navigator.geolocation) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        locationquery = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    if ((action.name === "exact" || action.name === "search" || action.name === "map") && action.location) {
      locationquery = {
        lat: action.location.coordinates[1],
        lng: action.location.coordinates[0],
      };
    }

    const searchQuery = querystring.stringify({
      type: action.name ? action.name : "",
      leadstatus: action.leadstatus ? action.leadstatus : "",
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
      name: action.keyword ? action.keyword : "",
      phone: action.phone ? action.phone : "",
      offset: action.offset ? action.offset : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/sales-future/search-sales-future` + query
    );
    yield put(Actions.adminSearchSalesFutureSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchSalesFutureFailure(error && error.message));
  }
}

function* adminUpdateSalesFuture(action: Object) {
  try {
    const response = yield API.post(
        `admin/sales-future/update-sales-future/` + action.payload.id,
        action.payload
    );
    yield put(Actions.adminUpdateSalesFutureSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateSalesFutureFailure(error));
  }
}

function* adminUpdateShopFutureLogoImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/sales-future/update-logo-pic/` +  action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminUpdateShopFutureLogoImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopFutureLogoImageFailure(error));
  }
}

export default function* SalesSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SALES_LEAD_START, adminSearchSalesLead);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_START, adminSearchSalesLeadKeywords);
  yield takeLatest(ActionTypes.ADMIN_GET_SALES_LEAD_INFO_START, adminGetSalesLeadInfo);
  yield takeLatest(ActionTypes.ADMIN_ADD_SALES_LEAD_START, adminAddSalesLead);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SALES_LEAD_START, adminUpdateSalesLead);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_START, adminUpdateSalesLeadCard);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_START, adminUpdateSalesLeadEvent);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_START, adminUpdateSalesLeadPlaceId);
  yield takeLatest(ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_START, adminAddSalesLeadImage);
  yield takeLatest(ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_START, adminRemoveSalesLeadImage);
  yield takeLatest(ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_START, adminAddSalesLeadImageQuick);
  yield takeLatest(ActionTypes.ADMIN_GET_SALES_EXECUTIVES_START, adminGetSalesExecutives);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_START, adminUpdateLeadExecutive);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SALES_FUTURE_START, adminSearchSalesFuture);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SALES_FUTURE_START, adminUpdateSalesFuture);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_START, adminUpdateShopFutureLogoImage);
}
