/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Link, useHistory} from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {getDistance, getImageOrLogo} from "helpers/utils";


import "./UserOfferBoxItem.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H6 } from "components/CustomTypography";


const UserOfferBoxItem = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });

    const history = useHistory();

  const {
      offer
  } = props;

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };

  const vegIconUrl = `${process.env.PUBLIC_URL}/assets/images/GreenIcon.png`;
  const nonVegIconUrl = `${process.env.PUBLIC_URL}/assets/images/RedIcon.png`;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

    const handleOfferButtonClick = () => {
        history.push(`/favs/offers/${offer.offer_id}`);
    };

  return (
      <Box pb={3} onClick={handleOfferButtonClick}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px">
          <LazyImage
              src={getImageOrLogo(offer.images, offer.logo)}
              width={150}
              height={150}
              alt={offer.name}
              mx="auto"
          />
        </HoverBox>
        <H2 textTransform="capitalize" fontSize={14} textAlign="left" title={offer.name} ellipsis>
          {offer.name}
        </H2>
          <H6 textTransform="uppercase" fontSize={10} textAlign="left" title={offer.title} ellipsis>
              {offer.title}
          </H6>
          <H6 fontSize={10} textAlign="left" title={offer.area} ellipsis>
              {offer.area}
          </H6>
      </Box>
  );
};

UserOfferBoxItem.defaultProps = {

};

UserOfferBoxItem.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
};

const userOfferBoxItem = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOfferBoxItem);

export default userOfferBoxItem;
