import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Notification,
    Pace
} from "components/Atoms";
import {Box, Container, IconButton, Typography} from "@mui/material";
import { H4, H5 } from "components/CustomTypography";

import * as Actions         from "actions/seller/seller.products";

import PhotoUpload from "components/PhotoUpload";
import {useEffect} from "react";
import CustomSectionCreator from "components/CustomSectionCreator";

const AdminPrivateProductQuickAddMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, product, cancelEdit,
        adminAddPrivateProductClicked,
        shop_id
    } = props;

    const {prod_id, name, images} = product;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
    }, [serviceError, reqStatus]);

    const addProductImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        adminAddPrivateProductClicked(shop_id, formData);
    }

    return (
        <Container>
            {<CustomSectionCreator isSubtle={true} title="Add New Product (Quick add)" /> }
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={false}
                             images={images}
                             addImageCallback={addProductImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );
};

// Mapping Props and State.
const adminPrivateProductQuickAddMain = connect(
    ({ adminProducts, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: adminProducts.reqStatus,
      isLoading: adminProducts.isLoading,
      serviceError: adminProducts.serviceError,
      productImageList: adminProducts.productImageList,
        product: adminProducts.productdetail,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(AdminPrivateProductQuickAddMain);

export { adminPrivateProductQuickAddMain as default };
