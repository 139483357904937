import * as React from "react";
import {
  Box, Container, IconButton, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import PropTypes from "prop-types";
import {
  schema,
  getTextfieldDataKeyword,
  getTextfieldDataPhone,
  getButtonDataKeywordSearch,
} from "./helper";
import {H2, H4, H5, H6} from "components/CustomTypography";
import SelectFieldComponent from "components/SelectField";
import TextFieldComponent from "components/TextField";

import {
  calculateDistance,
  checkUserAllowed,
  getDistance,
  getDistanceKms,
  getLogo,
  getURI,
  openGoogleMapLocation,
  openGoogleMapPlaceId
} from "helpers/utils";
import {Link} from "react-router-dom";
import ImageAlbum from "components/elements/main/ImageAlbum";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomSectionCreator from "components/CustomSectionCreator";

const AdminSalesLeadSettingEditor = ({
  setState, lead, cancelEdit, updateSalesLeadPlaceIdClicked, searchFuturePlaceIdKeywordClicked,
                                            salesLeadPlaceIdOptions, searchfutures, userDetails
}) => {
  const {
    shopname, name, phone, id, is_popular, is_active, place_id, location, distance, keyword, salesfuture, images, cardtext
  } = lead || {};

  const [blur, setblur] = React.useState({
    is_popular: false,
    is_active: false,
  });

  const onFinishSalesLeadPlaceId = (event) => {
    updateSalesLeadPlaceIdClicked(id, event.target.value);
  };

  const onSearchFuturesKeyword = () => {
    searchFuturePlaceIdKeywordClicked(lead, keyword, phone);
  };

  const handleCheckBoxChange = (event, field) => {
    setState(event.target.checked, field);
    setblur({ ...blur, [field]: true });
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
  };

  const hasError = () => {
    try {
      return !schema.validateSync(lead);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.is_popular || blur.is_active);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, lead);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };



  const renderFuturePlaceIDHelp = () => (
      <>
        {
          getTextfieldDataKeyword(keyword).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                    required
                    label={element.label}
                    value={element.value}
                    error={!!getError(element.onChangeField)}
                    helperText={getError(element.onChangeField)}
                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                />
              </Box>
          ))
        }
        {
          getTextfieldDataPhone(phone).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                    required
                    label={element.label}
                    value={element.value}
                    error={!!getError(element.onChangeField)}
                    helperText={getError(element.onChangeField)}
                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                />
              </Box>
          ))
        }
        {
          getButtonDataKeywordSearch(onSearchFuturesKeyword).map((element) => (
              <ButtonComponent
                  type={element.type}
                  variant={element.variant}
                  onClick={element.onClick}
                  disabled={element.disabled}
                  name={element.name}
              />
          ))
        }
      </>
  );


  return (
    <Container>
      <Box mb={2}>
        <ButtonComponent
            type="primary"
            variant="outlined"
            name="< Back"
            size="small"
            onClick={cancelEdit}
        />
      </Box>
      <Box width="100%">
        <H2 fontSize={16} textAlign="left" ellipsis>
          {shopname}
        </H2>
        <H4 fontSize={14} textAlign="left" ellipsis>
          {name} {phone}
        </H4>
      </Box>
      <Box width="100%">
        <H4 fontSize={14} textAlign="left" ellipsis>
          {checkUserAllowed(userDetails.userlevel, "scancard") && cardtext}
        </H4>
      </Box>
      <Box alignItems="center" width="100%" display="flex">
        <Box width="30%">
          <H5 fontSize={14} textAlign="left" ellipsis>
            Location:
          </H5>
        </Box>
        <Box width="70%">
          <IconButton
              size="small"
              onClick={() => openGoogleMapLocation(location)}
          >
            <ArrowForwardIcon color="primary" />
          </IconButton>
        </Box>
      </Box>
      <Box alignItems="center" width="100%" display="flex">
        <Box width="30%" >
          <H5 fontSize={14} textAlign="left" ellipsis>
            Place ID:
          </H5>
        </Box>
        <Box width="70%">
          {
            ((place_id &&  salesfuture) ?
          <IconButton
              size="small"
              onClick={() => openGoogleMapPlaceId(place_id, salesfuture.location)}
          >
            <ArrowForwardIcon color="primary" />
          </IconButton>
                : <span>Not Available</span> )
          }
        </Box>
      </Box>
      <Box alignItems="center" width="100%" display="flex">
        <Box width="30%" >
          <H5 fontSize={14} textAlign="left" ellipsis>
            Place Info:
          </H5>
        </Box>
        <Box width="70%">
          {
            ((place_id &&  salesfuture) &&
              (<Box>
                <H5 fontSize={14} textAlign="left" ellipsis>
                {salesfuture.shopname} {salesfuture.phone}
              </H5>
                <H5 fontSize={14} textAlign="left" ellipsis>
                  {salesfuture.address}
                </H5>
                <H5 fontSize={14} textAlign="left" ellipsis>
                  {location && salesfuture.location && getDistanceKms(calculateDistance(location.coordinates[1], location.coordinates[0], salesfuture.location.coordinates[1], salesfuture.location.coordinates[0], "K"))}
                </H5>
              </Box>))
          }
        </Box>
      </Box>
      <Box alignItems="center" width="100%" display="flex" mb={2}>
        <Box width="30%" >
          <H5 fontSize={14} textAlign="left" ellipsis>
            Update Place ID:
          </H5>
        </Box>
        <Box width="70%">
          <SelectFieldComponent
              label="Place ID"
              options={salesLeadPlaceIdOptions}
              onChange={(event) => onFinishSalesLeadPlaceId(event)}
          />
        </Box>
      </Box>
      <CustomSectionCreator title="Search Places:"  />
      <Box alignItems="center" width="100%" display="flex">
        <Box width="30%" >
          <H5 fontSize={14} textAlign="left" ellipsis>
            Search:
          </H5>
        </Box>
        <Box width="70%">
      {
        renderFuturePlaceIDHelp()
      }
        </Box>
      </Box>
      <Box width="100%" display="flex">
        <Container maxWidth="md">
          <CustomSectionCreator title="Sales Futures"  />
          {searchfutures && searchfutures.salesfutures && searchfutures.salesfutures.map((searchitem, index) => (
              <Box key={`${index + 1}`} p={1}>
                <Box className="styled-card" width="100%" display="flex" p={1}>
                  <Box width="80%" order={1}>
                    <Typography align="left">
                      <H2 fontSize={12}>
                        {searchitem.shopname} {searchitem.phone} {searchitem.is_updated && "(U)"}
                      </H2>
                    </Typography>
                    <Typography align="left">
                      <H6 fontSize={12}>
                        {searchitem.address}
                      </H6>
                    </Typography>
                  </Box>
                  <Box width="20%" order={1} ml={2}>
                    {location && searchitem.location && getDistanceKms(calculateDistance(location.coordinates[1], location.coordinates[0], searchitem.location.coordinates[1], searchitem.location.coordinates[0], "K"))}
                    <Typography align="left">
                        <IconButton
                            size="small"
                            onClick={() => openGoogleMapPlaceId(searchitem.place_id, searchitem.location)}
                        >
                          <ArrowForwardIcon color="primary" />
                        </IconButton>
                    </Typography>
                  </Box>
                </Box>
              </Box>
          ))}
        </Container>
      </Box>
      <Box width="100%" display="flex">
        <Container maxWidth="md">
          <CustomSectionCreator title="Searched Shops"  />
          {searchfutures && searchfutures.searched && searchfutures.searched.map((searchitem, index) => (
              <Box key={`${index + 1}`} p={1}>
                <Box className="styled-card" width="100%" display="flex" p={1}>
                  <Box width="80%" order={1}>
                    <Typography align="left">
                      <H2 fontSize={12}>
                        {searchitem.shopname} {searchitem.phone}
                      </H2>
                    </Typography>
                    <Typography align="left">
                      <H6 fontSize={12}>
                        {searchitem.address}
                      </H6>
                    </Typography>
                  </Box>
                  <Box width="20%" order={1} ml={2}>
                    {location && searchitem.location && getDistanceKms(calculateDistance(location.coordinates[1], location.coordinates[0], searchitem.location.coordinates[1], searchitem.location.coordinates[0], "K"))}
                    <Typography align="left">
                      <IconButton
                          size="small"
                          onClick={() => openGoogleMapPlaceId(searchitem.place_id, searchitem.location)}
                      >
                        <ArrowForwardIcon color="primary" />
                      </IconButton>
                    </Typography>
                  </Box>
                </Box>
              </Box>
          ))}
        </Container>
      </Box>
      <Box>
        <ImageAlbum images={images} />
      </Box>
    </Container>
  );
};

AdminSalesLeadSettingEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  lead: PropTypes.object.isRequired,
  searchFuturePlaceIdKeywordClicked: PropTypes.func.isRequired,
  updateSalesLeadPlaceIdClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AdminSalesLeadSettingEditor;
