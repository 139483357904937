/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Box,
  Typography,
  FormControlLabel,
  Radio,
    Select,
    InputLabel,
  MenuItem,
  FormControl
} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { schema, getTextfieldData, getButtonData, getSelectFieldData, getRadioButtonData } from "./helper";
import RadioButtonFieldComponent from "components/RadioButtonField";
import CustomSelectField from "components/CustomSelectField";


const ShippingAddressEditor = ({
  setState, shippingAddressEditing, verifyAddressToAccountCall, cancelEdit, cities,
}) => {
  const {
    _id, name, city, address, pincode, phone
  } = shippingAddressEditing;

  const [blur, setblur] = React.useState({
    name: false,
    city: false,
    address: false,
    pincode: false,
    phone: false,
  });


  const selectOptions = cities.map((item) => {
    const { name, id } = item;
    return (
        <MenuItem key={id} value={id}>{name}</MenuItem>
    );
  });


  const onFinish = () => {
    var cityitem = cities.find((c) => c.id === city);
    var cityname = cityitem.name;
    verifyAddressToAccountCall({ address, cityname });
  };

  const handleChange = (event, field) => {
    setState(event, field);
    setblur({ ...blur, [field]: true });

  };

  const hasError = () => {
    try {
      return !schema.validateSync(shippingAddressEditing);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.address || blur.city || blur.pincode || blur.phone);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, shippingAddressEditing);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };


  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(name, address, pincode, phone).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
        {getSelectFieldData(city).map((element) => (
            <Box className="textfield" m={2}>
              <CustomSelectField
                  required
                  label={element.label}
                  value={element.value}
                  onClick={element.onClick}
                  error={!!getError(element.onChangeField)}
                  helperText={getError(element.onChangeField)}
                  name={element.name}
                  children={selectOptions}
                  onChange={(event) => handleChange(event, element.onChangeField)}
              />
            </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
              variant={element.variant}
              type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

ShippingAddressEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  shippingAddressEditing: PropTypes.object.isRequired,
  verifyAddressToAccountCall: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ShippingAddressEditor;
