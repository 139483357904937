import * as React from "react";
import ButtonComponent from "components/Button";
import { Box, Typography } from "@mui/material";
import { H6 } from "components/CustomTypography";
import {schema} from "../ServiceEditor/helper";

const ReturnsPreview = ({
  isLoading, service, editService, cancelEdit,
}) => {
  const { returnsoption, alloweddays } = service;


    const renderReturns = (option) => {
        switch(option) {
            case "exchange":
                return (
                    <Typography>
                        Exchange within:
                        {`${alloweddays || ""}`} days
                    </Typography>
                );
            case "fullrefund":
                return (
                    <Typography>
                        Full refund within:
                        {`${alloweddays || ""}`} days
                    </Typography>
                );
            case "noreturns":
            default:
                return (
                    <Typography>
                        No Returns
                    </Typography>
                );
        }
    }


  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
          {renderReturns(returnsoption)}
      </Box>
      <Box>
        {!isLoading
              && (
              <ButtonComponent
                sx={{
                  m: 2,
                }}
                variant="contained"
                type="primary"
                name="Edit"
                size="small"
                onClick={editService}
              />
              )}
      </Box>
    </Box>
  );
};

export default ReturnsPreview;
