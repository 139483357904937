/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import {
  Button, Divider,
} from "components/Atoms";
import * as ActionTypes from "constants/ActionTypes";
import { Snackbar, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CustomSnackBar from "components/CustomSnackBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

const LoginEmailForm = ({
  login, googleSignIn, showResetPassword, showRegister, serviceError, reqStatus, showOTPLogin,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [blur, setBlur] = useState(false);

  const onFinish = () => {
    login({ email, password });
  };

  const onBlur = () => {
    setBlur(true);
  };

  return (
      <Box sx={{ mt: 1 }}>
          <TextFieldComponent
              fullWidth
              margin="normal"
              value={email}
              label="Email"
              required
              helperText={blur && !email && "This field is required"}
              onChange={(event) => { setEmail(event.target.value); setBlur(true); }}
              onBlur={onBlur}
              error={blur && !email}
          />
          <TextFieldComponent
              fullWidth
              margin="normal"
              value={password}
              label="Password"
              type="password"
              required
              helperText={blur && !password && "This field is required"}
              onChange={(event) => { setPassword(event.target.value); setBlur(true); }}
              onBlur={onBlur}
              error={blur && !password}
          />

          <ButtonComponent
              sx={{
                  mt: 3, mb: 2
              }}
              variant="contained"
              type="primary"
              fullWidth
              onClick={onFinish}
              name="Login"
              disabled={!blur || (blur && !email && !password)}
          />

          <Grid container>
              <Grid item>
                  {reqStatus === ActionTypes.LOGIN_FAILURE && (
                      <CustomSnackBar
                          status
                          errorMessage={serviceError}
                      />
                  )}
              </Grid>
          </Grid>
          <Grid container>
              <Grid item xs>
              </Grid>
              <Grid item>
                  <Link  onClick={showOTPLogin} variant="body2">
                      {"OTP Login"}
                  </Link>
              </Grid>
          </Grid>
      </Box>
  );
};

export default LoginEmailForm;
