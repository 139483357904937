import React, { useEffect } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Notification,
    Pace
} from "components/Atoms";

import {Box, Container} from "@mui/material";
import PhotoUpload from "components/PhotoUpload";
import * as Actions         from "actions";
import HeaderBack from "components/elements/main/HeaderBack";
import CustomSectionCreator from "components/CustomSectionCreator";
import {H2} from "components/CustomTypography";
import AdminOfferListItem from "components/elements/admin/offers/AdminOfferListItem";

const AdminAddOfferQuickMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, selectedShop, images, cancelEdit, shopOffers,
        adminAddQuickOfferClicked, key
    } = props;

    const {
        shop_id, title
    } = selectedShop;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };


    useEffect(() => {
        if (serviceError && reqStatus) {
            let feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {
        const formData = new FormData()
        formData.append("image", file);
        adminAddQuickOfferClicked(shop_id, formData);
    }



      return (
          <Container key={key}>
              <Box>
                  <HeaderBack onCancel={() => cancelEdit()}/>
                  {<CustomSectionCreator title="Add New Offer" /> }
                  <Box>
                      <H2 fontSize={18} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left"  ellipsis>
                          {title}
                      </H2>
                  </Box>
              </Box>
              {isLoading && <Pace color="#27ae60" height="2px" />}
              <Box >
                  <PhotoUpload
                      key={key}
                            isRemoveAllowed={false}
                            images={images}
                            addImageCallback={addImageCallback}
                            cancelEdit={cancelEdit}
                  />
              </Box>
              <Box>
                  {<CustomSectionCreator isSubtle={true} title="Previous Offers" /> }
                  {shopOffers && shopOffers.map((offer, index) => (
                      <AdminOfferListItem offer={offer} />
                  ))}
              </Box>
          </Container>
      );
};

// Mapping Props and State.
const adminAddOfferQuickMain = connect(
    ({ adminOffers, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: adminOffers.reqStatus,
        isLoading: adminOffers.isLoading,
        serviceError: adminOffers.serviceError,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminAddOfferQuickMain);

export { adminAddOfferQuickMain as default };
