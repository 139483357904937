import * as yup from "yup";

export const schema = yup.object().shape({
  status: yup.string().required("Status is required field"),
});


export const getRadioButtonData = (status) => [
  {
    label: "Status",
    value: status,
    onChangeField: "status",
  },
];

export const getButtonData = (onFinish, onCancel, hasError, isTouched) => [
  {
    name: "Update",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError(),
  },
  {
    name: "Cancel",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];

export const getButtonDataSendApproved = (onFinishApproved) => [
  {
    name: "Approve Shop",
    onClick: onFinishApproved,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendVerified = (onFinishVerified) => [
  {
    name: "Verify Seller",
    onClick: onFinishVerified,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataCancel = (onCancel) => [
  {
    name: "Close",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldDataPin = (pin) => [
  {
    label: "Pin",
    value: pin,
    onChangeField: "pin",
  },
];
