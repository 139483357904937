import * as yup from "yup";

export const schema = yup.object().shape({
  is_popular: yup.boolean().default(true),
  is_active: yup.boolean().default(true),
});

export const getTextfieldData = (name, description) => [
  {
    label: "Name",
    value: name,
    onChangeField: "name",
  },
  {
    label: "Description",
    value: description,
    onChangeField: "description",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    variant: "contained",
    onClick: onFinish,
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    variant: "outlined",
    onClick: cancelEdit,
    type: "primary",
    disabled: false,
  },
];
