// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  storeList: {
    count: 0,
    records: [],
  },
  shops: [],
  popularshops: [],
  searchshops: [],
  shopDetail: {
    title: "",
    addressline: "",
    cityname: "",
    phone:"",
    subdomain:"",
    domain:"",
  },
  searchkeywords: {
    keywords: [],
  },
};

export default function (state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */

    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START:
    case ActionTypes.ADMIN_SEARCH_SHOPS_START:
    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START:
    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START:
    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_START:
    case ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_START:
    case ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_START:
    case ActionTypes.ADMIN_UPDATE_STORE_PROFILE_START:
    case ActionTypes.ADMIN_UPDATE_STORE_LOGO_START:
    case ActionTypes.ADMIN_UPDATE_STORE_VIDEO_START:
    case ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_START:
    case ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_START:
    case ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_START:
    case ActionTypes.ADMIN_DISABLE_STORE_STATUS_START:
    case ActionTypes.ADMIN_ENABLE_STORE_STATUS_START:
    case ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_START:
    case ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_START:
    case ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_START:
    case ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_START:
    case ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_START:
    case ActionTypes.ADMIN_SEND_MESSAGE_START:
    case ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SHOPS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE:
    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_PROFILE_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_LOGO_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_VIDEO_FAILURE:
    case ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_FAILURE:
    case ActionTypes.ADMIN_ENABLE_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_DISABLE_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_FAILURE:
    case ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_FAILURE:
    case ActionTypes.ADMIN_SEND_MESSAGE_FAILURE:
    case ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {shop_count, shops} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeList: {
            count: shop_count,
            records: shops,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeList: {
            count: 0,
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SHOPS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchshops: action.payload.response.shops
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchshops: []
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS:
      if (action.payload.statusCode === 200) {
        const { keywords } = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: keywords,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          searchkeywords: {
            keywords: [],
          },
        };
      }

    case ActionTypes.ADMIN_GET_SHOP_INFO_BYID_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shopDetail: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          shopDetail: {
            ratings: [],
            images: [],
          },
        };
      }

    case ActionTypes.ADMIN_UPDATE_STORE_STATUS_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_PROFILE_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_LOGO_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_VIDEO_SUCCESS:
    case ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_SUCCESS:
    case ActionTypes.ADMIN_ENABLE_STORE_STATUS_SUCCESS:
    case ActionTypes.ADMIN_DISABLE_STORE_STATUS_SUCCESS:
    case ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_SUCCESS:
    case ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_SUCCESS:
    case ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_SUCCESS:
    case ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_SEND_MESSAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SHOPS_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchshops: [],
      };

    case ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORDS_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchkeywords: {
          keywords : [],
        },
      };

    default:
      return state;
  }
}
