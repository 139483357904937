/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./shopwishlist.scss";

// import materialComponents
import {
  Card, Typography, IconButton, Container, Box,
} from "@mui/material";
import {getImage, getURI, isMarketPlace} from "helpers/utils";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

import MockProductList from "components/elements/main/MockProductList";
import * as Actions from "actions";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import CustomSectionCreator from "components/CustomSectionCreator";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {H2, H6} from "../../../../CustomTypography";

const ShopWishList = (props) => {
  const [items, setItems] = useState([]);

  const {
    isLoading, wishlist, currency, bootupInfo, getShopWishlistProducts,
    removeProductFromWishlist, addProductToCart, match
  } = props;

    const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

  useEffect(() => {
    getShopWishlistProducts(shopId, !isMarketPlace());
  }, []);

  /*
  useEffect(() => {
    if (!(JSON.stringify(shopwishlistItems) === JSON.stringify(items))) {
      setItems(shopwishlistItems);
    }
  }, [shopwishlistItems]);

   */



  const handleRemove = (prodId) => {
    removeProductFromWishlist(prodId);
    /*
    const newItems = items;
    newItems.splice(items.indexOf(item), 1);
    setItems(newItems);

     */
  };

  const renderNoResults = () => (
      <Box align="center">
          <H6 fontSize={16}>No product added to favourite list</H6>
      </Box>
  );

  const renderResults = () => (
      <Container maxWidth="md">
        {wishlist.map((item, index) => (

            <Box key={`${index + 1}`} p={1}>
                <Box className="styled-card" width="100%" display="flex" p={1}>
                    <Box width="20%" order={1}>
                        <Typography align="center">
                                <img
                                    alt=""
                                    className="img-product"
                                    src={getImage(item.product && item.product.images)}
                                />
                        </Typography>
                    </Box>
                    <Box width="80%" order={1} ml={2}>
                        <Typography align="left">
                            <H2 fontSize={16}>
                                {item.product && item.product.name}
                                <IconButton
                                    size="small"
                                    onClick={() => addProductToCart(item.product.id, shopId, !isMarketPlace())}
                                >
                                    <AddShoppingCartIcon color="secondary" />
                                </IconButton>
                            </H2>
                            <H6 fontSize={16}>
                                {item.product && item.product.description}
                            </H6>
                        </Typography>
                        <Typography align="left">
                            <H6 fontSize={12}>
                                <IconButton size="small" onClick={() => handleRemove(item.product.id)}>
                                    <HighlightOffIcon color="secondary" />
                                </IconButton>
                            </H6>
                        </Typography>
                    </Box>
                </Box>
            </Box>
        ))}
      </Container>
  );

  return (
      <Container>
          {isMarketPlace() &&
              <ShopHeaderMini {...props} />}
          <CustomSectionCreator icon={<FavoriteIcon />} title="Favourites">
          </CustomSectionCreator>

          {isLoading ? <MockProductList />
              : wishlist.length > 0
                  ? renderResults()
                  : renderNoResults()}
      </Container>
  );


};


ShopWishList.defaultProps = {
  currency: "",
};

ShopWishList.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  getShopWishlistProducts: PropTypes.func.isRequired,
  removeProductFromWishlist: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    wishlist: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const shopWishLists = connect(
  ({ cartList, bootupInfo }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
      wishlist: cartList.wishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopWishList);

export default shopWishLists;
