//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Row,
  Col,
  Button,
    Input,
  Notification,
    Radio,
  Spin
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions     from "actions/seller/seller.orders";
import * as ActionTypes from 'constants/ActionTypes';


import "./styles.scss";

import SellerOrder                    from "components/elements/seller/SellerOrder";
import SellerOrderEditor              from "components/elements/seller/SellerOrderEditor";
import {useEffect, useState} from "react";
// Component Imports


const SearchOrderList = (props) => {

  const [state, setState] = useState({ total: 0, totalDiscount: 0 });

  const {
    serviceError, reqStatus, isLoading,
    userDetails, ordertype, query, searchorderList, getStoreOrders, searchOrders, updateOrder
  } = props;

  const { records } = searchorderList;

  const { orderMode, selectedOrder = {}, searchType } = state;

  useEffect(() => {
    getStoreOrders(ordertype);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
        // optimize later - updated order only
        searchOrders(query, searchType);
        break;
      case ActionTypes.LOGIN_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);


  const filterOrders = (value) => {
    setState({ filterText: value.toLowerCase() });
  };

  const setKeyword = (keyword) => {
    state.searchquery = keyword;
    //hideSearchModal();
    searchOrders(keyword, searchType);
  };

  const editOrder = (order) => {
    updateOrder(order);
    setState({ orderMode: "PREVIEW" });
  };

  const handleOrderEdit = (mode, order, reset) => () => {

    setState({ orderMode: mode });
    if (reset) {
      setState({ selectedOrder: { order_id: "", _id: "", status: "", order_type: ""}});
    } else {
      setState({ selectedOrder: { order_id: order.order_id, _id: order._id, status: order.status, order_type: order.order_type}});
    }
  };

  const fetchSearchResults = ( updatedPageNo = '', query ) => {
    state.searchquery = query;
    searchOrders(query, state.searchType);
  };

  const handleOnInputChange = ( value ) => {
    const query = value;
    if ( ! query ) {
      setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      setState( { query, loading: true, message: '' }, () => {
        fetchSearchResults( 1, query );
      } );
    }
  };

  const onChange = e => {
    state.searchType = e.target.value;
  };

    const { Search } = Input;

    return (
        <section className="order-page">
          <div className="action-box">
        <Page className="orders-catalog">
        <div className="content">
          <div className="header">
            <Input.Search
                type="text"
                name="query"
                value={ query }
                placeholder="Enter Order Name"
                onSearch={handleOnInputChange}
                enterButton
            />
            <Radio.Group defaultValue="orderid" onChange={this.onChange}>
              <Radio value="orderid">Order Id</Radio>
              <Radio value="name">Name</Radio>
              <Radio value="phone">Phone</Radio>
              <Radio value="email">Email</Radio>
            </Radio.Group>
          </div>
          {isLoading && <Spin />}
          {orderMode === "PREVIEW" ? (
              <div className="order-list">
                {
                  records.length > 0 ?
                      <Row gutter={[24, 24]}>
                        {records.map((order) => {
                          return (
                              <Col span={24} key={`pr-item-${order.id}`}>
                                <SellerOrder
                                    className="product-item"
                                    key={order._id}
                                    data={order}
                                    actions={
                                      <>
                                        <Button
                                            type="primary"
                                            onClick={this.handleOrderEdit("EDIT", order, false)}
                                        >
                                          Edit
                                        </Button>
                                      </>
                                    }
                                />
                              </Col>
                          );
                        })
                        }
                      </Row> :
                      <div className="row">
                        <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                          <h4>No orders!!</h4>
                        </div>
                      </div>
                }
              </div>
          ) : (
              <SellerOrderEditor
                  data={selectedOrder}
                  updateOrderClicked={this.editOrder}
                  onCancel={() => this.setState({ orderMode: "PREVIEW" })}
              />
          )
          }
        </div>
      </Page>
          </div>
        </section>
    );
}
// Mapping Props and State.
const _SearchOrderList = connect(
  ({ orders, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: orders.reqStatus,
    isLoading: orders.isLoading,
    serviceError: orders.serviceError,
    searchorderList: orders.searchorderList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchOrderList);

export { _SearchOrderList as default };
