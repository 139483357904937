// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  AutoComplete,
  Button,
  Notification,
  Typography
} from "components/Atoms";
import {H2, H6} from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import "./style.scss";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import SellerOffer from "components/elements/seller/offers/SellerOffer";
import SellerOfferEditor from "components/elements/seller/offers/SellerOfferEditor";
import AdminSalesLeadSettingEditor from "components/elements/admin/sales/AdminSalesLeadSettingEditor";
import SellerOffersImageMain from "components/pages/seller/offers-coupons/SellerOffersImageMain";

import MockProductList from "components/elements/main/MockProductList";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, Grid} from "@mui/material";
import {useHistory} from "react-router";

const { Search } = Input;
const { Option } = AutoComplete;

const SellerOffersMain = (props) => {
  const [state, setState] = useState({ categoryMode: "PREVIEW" });
  const [offerState, setOfferState] = useState({ selectedOffer: props.categoryInput });

  const {
    serviceError, reqStatus, isLoading, searchoffers, bootupInfo,
    sellerSearchOffers, sellerAddOffer, sellerUpdateOffer, adminUpdateCommonCategorySetting
  } = props;

  useEffect(() => {
    sellerSearchOffers();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.SELLER_ADD_OFFER_SUCCESS:
      case ActionTypes.SELLER_UPDATE_OFFER_SUCCESS:
      case ActionTypes.SELLER_ADD_OFFER_IMAGE_SUCCESS:
      case ActionTypes.SELLER_REMOVE_OFFER_IMAGE_SUCCESS:
        sellerSearchOffers();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateCategoryToAccountCall = (values) => {
    if (!values.id) {
      sellerAddOffer(values);
    } else {
      sellerUpdateOffer(values);
    }
    setState({ categoryMode: "PREVIEW" });
  };

  const updateCategorySettingCall = (values, id) => {
    values.id = id;
    adminUpdateCommonCategorySetting(values);
    setState({ categoryMode: "PREVIEW" });
  };

  const handleCategoryEdit = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setOfferState({
        storeOffer: {
          name: "", description: "", id: "", images: [], valid_date: ""
        },
      });
    } else {
      setOfferState({
        storeOffer: {
          name: item.name, description: item.description, id: item.id, images: item.images, valid_date: item.valid_date,
        },
      });
    }
  };

  const handleCategorySetting = (mode, item) => (e) => {
    setState({ categoryMode: mode });
    setOfferState({
      storeOffer: {
        name: item.name,
        id: item.id,
        is_popular: item.is_popular,
        is_active: item.is_active,
      },
    });
  };

  const handleCategoryImage = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setOfferState({
        storeOffer: {
          name: "", description: "", id: "", images: [], valid_date : "",
        },
      });
    } else {
      setOfferState({
        storeOffer: {
          name: item.name, description: item.description, id: item.id, images: item.images, valid_date: item.valid_date
        },
      });
    }
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { offers } = searchoffers;
  const { categoryMode } = state;
  const { storeOffer = {} } = offerState;

  const renderNoResults = () => (
      <div>
        <Container maxWidth="md">
          <Typography align="center">
            <H2 fontSize={16} ellipsis>
              No offer added
            </H2>
          </Typography>
        </Container>
      </div>
  );

  const renderResults = () => (
    <div>
      <Container maxWidth="md">
          {offers.map((offer, index) => (
              <Box key={`${index + 1}`} p={1}>
              <SellerOffer
                className="product-item"
                key={offer.id}
                offer={offer}
                userDetails={bootupInfo.userDetails}
                editCategoryImage={handleCategoryImage("EDIT_IMG", offer, false)}
                editCategoryInfo={handleCategoryEdit("EDIT", offer, false)}
                editCategorySettings={handleCategorySetting("EDIT_SET", offer)}
              />
              </Box>
          ))}
      </Container>
    </div>
  );

  const renderCategoryList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
          <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleCategoryEdit("EDIT", null, true)}
          >
            Add New Offer
          </Button>
        </CustomFlexBox>

      {isLoading ? <MockProductList />
        : offers.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );

  const renderCategoryEdit = () => {
    switch (categoryMode) {
      case "EDIT":
        return (
          <SellerOfferEditor
              setState={(value, field) => setOfferState({
                ...state,
                storeOffer: {
                  ...storeOffer,
                  [field]: value,
                },
              })}
              offer={storeOffer}
            addUpdateCategoryToShopClicked={addUpdateCategoryToAccountCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_IMG":
        return (
          <SellerOffersImageMain
              offer={storeOffer}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_SET":
      default:
        return (
          <AdminSalesLeadSettingEditor
              setState={(value, field) => setOfferState({
                ...state,
                storeOffer: {
                  ...storeOffer,
                  [field]: value,
                },
              })}
              offer={storeOffer}
            updateCategorySettingClicked={updateCategorySettingCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
    }
  };

  return (
    <Container>
      {categoryMode === "PREVIEW" ? (
        renderCategoryList()
      ) : (
        renderCategoryEdit(categoryMode)
      )}
    </Container>
  );
};

SellerOffersMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  searchoffers: [],
};

SellerOffersMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  searchoffers: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  sellerSearchOffers: PropTypes.func.isRequired,
  sellerAddOffer: PropTypes.func.isRequired,
  sellerUpdateOffer: PropTypes.func.isRequired,
};

const sellerOffersMain = connect(
  ({ offers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: offers.reqStatus,
    isLoading: offers.isLoading,
    serviceError: offers.serviceError,
    searchoffers: offers.searchoffers,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(SellerOffersMain);

export default sellerOffersMain;
