/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Typography} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";

const ProfileEditor = ({ profile, updateProfileCall, cancelEdit }) => {
  const [values, setValues] = useState({ name: "" });
  const [blur, setBlur] = useState(false);

  const { name } = profile;

  const onFinish = () => {
    updateProfileCall(values);
  };

  useEffect(() => {
    setValues({ name });
  }, [name]);

  const onChange = (event) => {
    setValues({ name: event.target.value });
      setBlur(event.target.value);
  };
  const onBlur = (event) => {
    setBlur(event.target.value);
  };

  return (
      <Box m={2}>
          <Box display="flex" flexWrap="wrap">
              <Box className="textfield" m={2}>
                  <TextFieldComponent
                      value={values.name}
                      label="Name"
                      required
                      helperText={blur && !values.name && "This field is required"}
                      onChange={onChange}
                      error={blur && !values.name}
                  />
              </Box>
          </Box>
          <br />
          <Typography align="center">
              <ButtonComponent
                  sx={{
                      m: 2,
                  }}
                  variant="contained"
                  type="primary"
                  onClick={onFinish}
                  name="Save"
                  disabled={!blur || (blur && !values.name)}
              />
              <ButtonComponent
                  variant="outlined"
                  type="primary"
                  onClick={cancelEdit}
                  name="Cancel"
              />
          </Typography>
      </Box>
  );
};

ProfileEditor.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any).isRequired,
  updateProfileCall: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ProfileEditor;
