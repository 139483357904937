import * as React from "react";

import "./style.scss";
import CategoryMenuSlider from "components/elements/main/CategoryMenuSlider";
import PropTypes from "prop-types";

const SellerCategorySelector = (props) => {
  const {
    categoryList, onCategorySelect, resetCategory, selectedCategory,
  } = props;

  const { categories } = categoryList;

  return (
    <div>
      <div className="category-list">
        <CategoryMenuSlider
          resetCategory={resetCategory}
          categories={categories}
          selectedCategory={selectedCategory}
          onCategorySelect={onCategorySelect}
        />
      </div>
    </div>
  );
};

SellerCategorySelector.defaultProps = {
  categoryList: [],
};

SellerCategorySelector.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.object),
  onCategorySelect: PropTypes.func.isRequired,
  resetCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.func.isRequired,
  handlePage: PropTypes.func.isRequired,
};

export default SellerCategorySelector;
