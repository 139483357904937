import * as React from "react";


import "./SellerCategory.scss";
import {Box, Card, IconButton, Typography} from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H4, H5, H6 } from "components/CustomTypography";
import {getImageOrLogo, getLocalDate} from "helpers/utils";
import {Link} from "react-router-dom";
import {DATE_FORMAT} from "config/constants";


const SellerOffer = (props) => {
  const { offer = {}, userDetails, editCategoryImage, editCategoryInfo, editCategorySettings, className = "" } = props;
  const { id, name, images = [], is_popular, is_active, store_entry, valid_date } = offer;


    return (
        <Box className="styled-card" width="100%" display="flex" p={1}>
            <Box width="20%" order={1}>
                <Typography align="center">
                    <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={editCategoryImage}>
                        <img
                            alt=""
                            className="img-product"
                            src={getImageOrLogo(offer.images, offer.shop && offer.shop.logo)}
                        />
                    </HoverBox>
                </Typography>
            </Box>
            <Box width="60%" order={1} ml={2} onClick={editCategoryInfo}>
                <H2 fontSize={16}  ellipsis>
                    {offer.name}
                </H2>
                <H4 mt={1} fontSize={14}>
                    {offer.description}
                </H4>
                <H6 mt={1} fontSize={12}>
                    Valid till : {getLocalDate(valid_date, DATE_FORMAT)}
                </H6>
            </Box>
            <Box width="20%" order={1} ml={3}>
                <IconButton className="setting-button" color="primary" size="small" onClick={editCategoryInfo}>
                    <MoreHorizIcon />
                </IconButton>
            </Box>
        </Box>
    );

};

export default SellerOffer;
