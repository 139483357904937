// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const getAvailableSubscriptionPlans = (req) => ({
  type: ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_START,
  payload: req,
});

export const getAvailableSubscriptionPlansSuccess = (data) => {
  return {
    type: ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};

export const getAvailableSubscriptionPlansFailure = (error) => ({
  type: ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_FAILURE,
  payload: error,
});

export const getSellerActiveSubscriptionPlans = (req) => ({
  type: ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_START,
  payload: req,
});

export const getSellerActiveSubscriptionPlansSuccess = (data) => {
  return {
    type: ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const getSellerActiveSubscriptionPlansFailure = (error) => ({
  type: ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_FAILURE,
  payload: error,
});


export const getSubscriptionsCart = (req) => ({
  type: ActionTypes.GET_SUBSCRIPTIONS_CART_START,
  payload: req,
});

export const getSubscriptionsCartSuccess = (data) => {
  return {
    type: ActionTypes.GET_SUBSCRIPTIONS_CART_SUCCESS,
    payload: data,
  };
};
export const getSubscriptionsCartFailure = (error) => ({
  type: ActionTypes.GET_SUBSCRIPTIONS_CART_FAILURE,
  payload: error,
});

export const addSubscriptionToCart = (reqData) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_START,
  planId: reqData,
});

export const addSubscriptionToCartSuccess = (data) => {
  return {
    type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_SUCCESS,
    payload: data,
  };
};

export const addSubscriptionToCartFailure = (error) => ({
  type: ActionTypes.ADD_SUBSCRIPTION_TO_CART_FAILURE,
  payload: error,
});

export const removeSubscriptionFromCart = (reqData) => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START,
  planId: reqData,
});

export const removeSubscriptionFromCartSuccess = (data, planId) => {
  return {
    type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS,
    payload: data,
    planId: planId
  };
};

export const removeSubscriptionFromCartFailure = (error) => ({
  type: ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_FAILURE,
  payload: error,
});

export const checkoutSubscriptionPlanOrder = (reqData) => ({
  type: ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_START,
  payload: reqData,
});

export const checkoutSubscriptionPlanOrderSuccess = (data) => {
  return {
    type: ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS,
    payload: data,
  };
};

export const checkoutSubscriptionPlanOrderFailure = (error) => ({
  type: ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_FAILURE,
  payload: error,
});

export const generateSubscriptionPlanOrder = (reqData) => ({
  type: ActionTypes.GENERATE_SUBSCRIPTION_ORDER_START,
  payload: reqData,
});

export const generateSubscriptionPlanOrderSuccess = (data) => {
  return {
    type: ActionTypes.GENERATE_SUBSCRIPTION_ORDER_SUCCESS,
    payload: data,
  };
};

export const generateSubscriptionPlanOrderFailure = (error) => ({
  type: ActionTypes.GENERATE_SUBSCRIPTION_ORDER_FAILURE,
  payload: error,
});

export const getSubscriptionsOrders = (req) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ORDERS_START,
  payload: req,
});

export const getSubscriptionsOrdersSuccess = (data) => {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_ORDERS_SUCCESS,
    payload: data,
  };
};
export const getSubscriptionsOrdersFailure = (error) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ORDERS_FAILURE,
  payload: error,
});

export const getSubscriptionsActiveOrders = (req) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_START,
  payload: req,
});

export const getSubscriptionsActiveOrdersSuccess = (data) => {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_SUCCESS,
    payload: data,
  };
};
export const getSubscriptionsActiveOrdersFailure = (error) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_FAILURE,
  payload: error,
});

export const getSubscriptionsOrderDetail = (orderId) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_START,
  orderId: orderId,
});

export const getSubscriptionsOrderDetailSuccess = (data) => {
  return {
    type: ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_SUCCESS,
    payload: data,
  };
};
export const getSubscriptionsOrderDetailFailure = (error) => ({
  type: ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_FAILURE,
  payload: error,
});

