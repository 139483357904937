/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Container,
} from "@mui/material";

import { Notification } from "components/Atoms";
import { H6 } from 'components/CustomTypography';

import * as Actions from "actions/seller/seller.orders";
import * as ActionTypes from "constants/ActionTypes";

import SellerOrder from "components/elements/seller/SellerOrder";
import SellerOrderEditor from "components/elements/seller/SellerOrderEditor";
import SellerOrderSelector from "components/elements/seller/SellerOrderSelector";
import MockProductList from "components/elements/main/MockProductList";

import { CURRENCY } from "config/constants";
import "./styles.scss";

const OrdersMain = (props) => {
  const [state, setState] = useState({ orderMode: "PREVIEW" });
  const [orderState, setOrderState] = useState("");
  const [statusState, setStatusState] = useState("");
  const [typeState, setTypeState] = useState("");

  const {
    serviceError, reqStatus, isLoading, storeOrders, symbol = "$", bootupInfo = {},
    getStoreOrders, includestatus, getOrderDetail, orderdetail, updateOrderStatus
  } = props;

  useEffect(() => {
    getStoreOrders(statusState, typeState);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_ORDER_STATUS_SUCCESS:
        //getStoreOrders(props.orderstatus, props.ordertype);
        getStoreOrders(statusState, typeState);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);


  const setOrderStatus = (status) => {
    setStatusState(status);
    getStoreOrders(status, typeState);
  };

  const setOrderType = (type) => {
    setTypeState(type);
    getStoreOrders(statusState, type);
  };

  const editOrder = (order) => {
    updateOrderStatus(order);
    setState({ orderMode: "PREVIEW" });
  };

  const handleOrderEdit = (mode, order, reset) => () => {
    setState({ orderMode: mode });
    if (reset) {
      setOrderState({
        selectedOrder: {
          order_id: "", _id: "", status: "", order_type: "", user: "",
        },
      });
    } else {
      props.getSellerOrderDetail(order._id);
      setOrderState({
        selectedOrder: {
          order_id: order.order_id, _id: order._id, status: order.status, order_type: order.order_type, user: order.user,
        },
      });
    }
  };

  const handlePage = (page) => (e) => {
    props.history.push({
      pathname: page,
    });
  };

  const { orderMode } = state;
  const { selectedOrder = {} } = orderState;
  const { records } = storeOrders;

  const renderNoResults = () => (
    <section className="cart-section section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div>
              <div className="col-sm-12 empty-cart-cls text-center">
                <H6>No order found
                </H6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const renderResults = () => (
    records.map((order) => (
      <SellerOrder
        className="product-item"
        key={order._id}
        order={order}
        editOrderStatus={handleOrderEdit("EDIT", order, false)}
      />
    ))
  );

  const renderOrderList = () => (
      <Container>
      {includestatus === true ?
      <SellerOrderSelector handlePage={handlePage} onTypeSelect={setOrderType} onStatusSelect={setOrderStatus} orderstatus={statusState} ordertype={typeState} /> : ""
      }
      {isLoading ? <MockProductList />
        : records.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );

  const renderOrderEdit = (orderMode) => {
    switch (orderMode) {
      case "EDIT":
        return (
          <SellerOrderEditor
              setState={(value, field) => setOrderState({
                ...state,
                selectedOrder: {
                  ...selectedOrder,
                  [field]: value,
                },
              })}
              symbol={symbol}
              isLoading={isLoading}
              orderdetail={orderdetail}
              selectedOrder={selectedOrder}
            updateOrderClicked={editOrder}
            onCancel={() => setState({ orderMode: "PREVIEW" })}
          />
        );
      case "EDIT_SET":
      default:
        return (
            <SellerOrderEditor
                setState={(value, field) => setOrderState({
                  ...state,
                  selectedOrder: {
                    ...selectedOrder,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                isLoading={isLoading}
                orderdetail={orderdetail}
                selectedOrder={selectedOrder}
                updateOrderClicked={editOrder}
                onCancel={() => setState({ orderMode: "PREVIEW" })}
            />
        );
    }
  };

  return (
    <Container>
      {orderMode === "PREVIEW" ? (
        renderOrderList()
      ) : (
        renderOrderEdit(orderMode)
      )}
    </Container>
  );
};

OrdersMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  storeOrders: [],
  symbol: CURRENCY,
  includestatus: true,
};

OrdersMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  storeOrders: PropTypes.arrayOf(PropTypes.object),
  symbol: PropTypes.string,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getStoreOrders: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired,
};

const ordersMain = connect(
  ({ orders, bootupInfo }) => ({
    bootupInfo,
    symbol: bootupInfo.symbol,
    reqStatus: orders.reqStatus,
    isLoading: orders.isLoading,
    serviceError: orders.serviceError,
    storeOrders: orders.storeOrders,
    orderdetail: orders.orderdetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(OrdersMain);

export default ordersMain;
