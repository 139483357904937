/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { H5 } from "components/CustomTypography";
import { schema, getTextfieldData, getButtonData } from "./helper";

const AddressEditor = ({
  setState, storeAddress, updateAddressCall, cancelEdit,
}) => {
  const {
    _id, addressline, areaname, pincode, area, city
  } = storeAddress;

  const [blur, setblur] = React.useState({
    addressline: false,
    areaname: false,
    pincode: false,
  });

  const onFinish = () => {
    updateAddressCall({
      _id, areaname, addressline, pincode,
    });
  };

  const handleChange = (event, field) => {
    setState(event, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(storeAddress);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.areaname || blur.addressline || blur.pincode);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, storeAddress);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Box m={2}>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(addressline, areaname, pincode).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <Box display="flex" flexWrap="wrap">
        <H5>Location: {area}, {city} </H5>
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Box>
  );
};

AddressEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  storeAddress: PropTypes.object.isRequired,
  updateAddressCall: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AddressEditor;
