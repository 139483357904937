/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container, Box, Typography, FormControlLabel, Radio, Card,
} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import {
  schema, getTextfieldData, getRadioButtonData, getButtonData, getTextfieldDataUPIbased, getTextfieldDataSMSbased,
} from "./helper";

const PaymentEditor = ({
  setState, payment, updatePaymentCall, cancelEdit,
}) => {
  const { paymentoption, paymentkey, paymentupi } = payment;

  const [blur, setblur] = React.useState({
    paymentoption: false,
    paymentkey: false,
    paymentupi: false,
  });

  const onFinish = () => {
    updatePaymentCall({
      paymentoption, paymentkey, paymentupi
    });
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleRadioButtonChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(payment);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.paymentoption || blur.paymentkey || blur.paymentupi );

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, payment);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  const options = [
    { label: "Direct to shop (QR Code)", value: "qrcodeimage" },
    { label: "Direct to shop (UPI)", value: "upibased" },
    /*
  { label: "Direct (SMS request)", value: "smsbased" },
  { label: "Payment via " + process.env.REACT_APP_NAME, value: "viagateway" },
     */
  ];

  const inputOptions = options.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  return (
    <Container>
      <Box>
        <Card>
        {getRadioButtonData(paymentoption).map((element) => (
            <Box className="textfield" m={2}>
              <RadioButtonFieldComponent
                  required
                  options={inputOptions}
                  label={element.label}
                  value={element.value}
                  onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
              />
            </Box>
        ))}
        {paymentoption === "smsbased" && getTextfieldDataSMSbased(paymentkey).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
            />
          </Box>
        ))}

          {paymentoption === "upibased" && getTextfieldDataUPIbased(paymentupi).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                    required
                    label={element.label}
                    value={element.value}
                    error={!!getError(element.onChangeField)}
                    helperText={getError(element.onChangeField)}
                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                />
              </Box>
          ))}
        </Card>
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

PaymentEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  payment: PropTypes.object.isRequired,
  updateService: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default PaymentEditor;
