/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import {H4} from 'components/CustomTypography';
import ButtonComponent from "components/Button";
import { schema, getTextfieldData, getButtonData } from "./helper";
import DatePicker from "react-datepicker";

const SellerOfferEditor = ({
  setState, offer, addUpdateCategoryToShopClicked, cancelEdit,
}) => {
  const {
    id, name, description, valid_date
  } = offer;
  const [blur, setblur] = React.useState({
    name: false,
    description: false,
    valid_date: false
  });

  const onFinish = () => {
    addUpdateCategoryToShopClicked({
      id, name, description, valid_date
    });
  };

  const handleChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleOfferExpiryDate = (value) => {
    setState(value, "valid_date");
    setblur({ ...blur, valid_date: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(offer);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.description || blur.valid_date);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, offer);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(name, description).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box width="15%" m={2}>
          <Typography align="left">
            <b>Valid till</b>
          </Typography>
        </Box>
        <Box width="75%" m={2}>
          <style>
            {`.date-picker input {
                        border:2px;
                        background-color: #eee;
                        color:#000;
                        }`}
          </style>
          <DatePicker
              wrapperClassName="date-picker"
              placeholderText="Delivery Date"
              selected={valid_date ? new Date(valid_date) : new Date()}
              minDate={new Date()}
              dateFormat="MMMM d, yyyy"
              onChange={handleOfferExpiryDate}
          />
        </Box>
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            variant={element.variant}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

SellerOfferEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  addUpdateCategoryToShopClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default SellerOfferEditor;
