import * as React from "react";

import { H6, SpanInline } from 'components/CustomTypography';
import CustomImageBox from 'components/CustomImageBox';

import "./SellerCategory.scss";
import {Box, Card, IconButton} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const AdminCommonCategory = (props) => {
  const { category = {}, editCategoryImage, editCategoryInfo, editCategorySettings, className = "" } = props;
  const { id, name, image = [], is_popular, is_active, store_entry } = category;

  const galleryInput = image.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });



  return (

      <Card className="seller-category-card">
        <CustomImageBox mx="auto" borderRadius="8px" onClick={editCategoryImage}>
          <div className="image-container" >
            <Box className="actions" position="absolute" top={0} right={0} display="none" flexDirection="column">
              <IconButton color="primary" size="small">
                <ImageIcon />
              </IconButton>
            </Box>
            <Box className="front">
              {category.image && (

                  <img
                      src={`${category.image[0] ? category.image[0].cdn.secure_url : "/assets/images/fashion/product/1.jpg"}`}
                      className="product-image"
                      alt=""
                  />

              )}
            </Box>
          </div>
        </CustomImageBox>
        <SpanInline fontWeight="600" fontSize="14px" title={category.name} ellipsis>
          <H6  className="text-truncate" fontWeight="600" width="70%" textAlign="left">
            {name}
          </H6>
          <IconButton  className="setting-button" color="primary" size="small" onClick={editCategoryInfo}>
            <MoreHorizIcon />
          </IconButton>
        </SpanInline>
        <SpanInline fontWeight="600" fontSize="14px" title={category.name} ellipsis>
          <H6 fontWeight="600" width="70%" textAlign="left">
          </H6>
          <IconButton  className="setting-button" color="primary" size="small" onClick={editCategorySettings}>
            <MoreHorizIcon />
          </IconButton>
        </SpanInline>
      </Card>
  );
};

export default AdminCommonCategory;
