// @flow
import * as ActionTypes from 'constants/ActionTypes';
import {SELLER_SEARCH_OFFERS_START} from "constants/ActionTypes";

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,

  searchoffers: {
    offers:[],
  },
  offerdetail: {
    images: [],
  },
  offerImageList: {
    images: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.SELLER_SEARCH_OFFERS_START:
    case ActionTypes.SELLER_GET_OFFER_INFO_START:
    case ActionTypes.SELLER_ADD_OFFER_START:
    case ActionTypes.SELLER_UPDATE_OFFER_START:
    case ActionTypes.SELLER_ADD_OFFER_IMAGE_START:
    case ActionTypes.SELLER_REMOVE_OFFER_IMAGE_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.SELLER_SEARCH_OFFERS_FAILURE:
    case ActionTypes.SELLER_GET_OFFER_INFO_FAILURE:
    case ActionTypes.SELLER_ADD_OFFER_FAILURE:
    case ActionTypes.SELLER_UPDATE_OFFER_FAILURE:
    case ActionTypes.SELLER_ADD_OFFER_IMAGE_FAILURE:
    case ActionTypes.SELLER_REMOVE_OFFER_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.SELLER_SEARCH_OFFERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { offers } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchoffers: {
            offers : offers,
          },

        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchleads: []
        };
      }
    }

    case ActionTypes.SELLER_GET_OFFER_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          offerdetail: action.payload.response
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          offerdetail: {
            images: [],
          }
        };
      }
    }

    case ActionTypes.SELLER_ADD_OFFER_SUCCESS:
    case ActionTypes.SELLER_UPDATE_OFFER_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }
    case ActionTypes.SELLER_ADD_OFFER_IMAGE_SUCCESS:
    case ActionTypes.SELLER_REMOVE_OFFER_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          offerImageList: {
            images: action.payload.response.images,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          offerImageList: {
            images: [],
          },
        };
      }
    }


    default:
      return state;
  }
}
