import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions/seller/seller.store";

import {Box, Container} from "@mui/material";
import {useEffect} from "react";

import PhotoUpload from "components/PhotoUpload";

const ProfileLogoEditor = (props) => {

  const { serviceError, reqStatus, isLoading,
      updateStoreLogo,
      storeProfile, cancelEdit } = props;

    const { logo } = storeProfile;

    useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
  }, [serviceError, reqStatus]);

  const sendNotification = (type, { title, description = "" }) => {
    console.log({
      message: title,
      description,
    });
  };

    const addImageCallback = (file) => {
        const formData = new FormData()
        formData.append("image", file);
        updateStoreLogo(formData);
    }


    return (
        <Container>
            <Box >
                <PhotoUpload isRemoveAllowed={false}
                             images={logo}
                             addImageCallback={addImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );
};

// Mapping Props and State.
const profileLogoEditor = connect(
  ({ store, bootupInfo }) => ({
    store,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileLogoEditor);

export { profileLogoEditor as default };
