/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Box,
  Typography,
  FormControlLabel,
  Radio,
    Select,
    InputLabel,
  MenuItem,
  FormControl
} from "@mui/material";
import ButtonComponent from "components/Button";
import { schema, getButtonData, getRadioButtonData } from "./helper";
import RadioButtonFieldComponent from "components/RadioButtonField";



const ShippingAddressVerify = ({
  setState, shippingAddressEditing, addUpdateShippingAddress, cancelEdit, suggestions,
}) => {

  const {
    _id, name, city, address, pincode, phone, place_id, cityname
  } = shippingAddressEditing;

  const [blur, setblur] = React.useState({
    place_id: false,
  });


  const inputOptions = suggestions.map((item) => {
    const { description, place_id } = item;
    return (
        <FormControlLabel value={place_id} control={<Radio />} label={description} />
    );
  });


  const selectOptions = suggestions.map((item) => {
    const { description, place_id } = item;
    return (
        <MenuItem key={place_id} value={place_id}>{description}</MenuItem>
    );
  });


  const onFinish = () => {
    addUpdateShippingAddress({
      _id, name, city, address, pincode, phone, place_id, cityname
    });
  };

  const handleRadioButtonChange = (event, field) => {
    setState(event, field);
    setblur({ ...blur, [field]: true });

  };

  const hasError = () => {
    try {
      return !schema.validateSync(shippingAddressEditing);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.place_id);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, shippingAddressEditing);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };


  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getRadioButtonData(place_id).map((element) => (
            <Box className="textfield" m={2}>
              <RadioButtonFieldComponent
                  required
                  label={element.label}
                  value={element.value}
                  error={!!getError(element.onChangeField)}
                  helperText={getError(element.onChangeField)}
                  name={element.name}
                  options={inputOptions}
                  onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
              />
            </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
              variant={element.variant}
              type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>

      <Box>


      </Box>
    </Container>
  );
};

ShippingAddressVerify.propTypes = {
  setState: PropTypes.func.isRequired,
  shippingAddressEditing: PropTypes.object.isRequired,
  addUpdateShippingAddress: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default ShippingAddressVerify;
