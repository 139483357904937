// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  products: [],
  popularproducts: [],
  searchproducts: [],
  categoryproducts: [],
  popularcategories: [],
  productprices: [],
  categoryList: {
    count: 0,
    categories: [],
  },
  mainCategoryList: {
    count: 0,
    maincategories: [],
  },
  categoryImageList: {
    image: [],
  },
  productdetail: {
      images: [],
      categories: [],
      commoncategories: [],
  },
  productshopsdetail: {
    productdetail: {
      images: [],
      categories: [],
      commoncategories: [],
    },
    productshops: [],
  },
  productList: {
    count: 0,
    products: [],
  },
  productImageList: {
    images: [],
  },

  productsByCategory: {
    meta: {
      count: 0,
    },
    records: [],
  },

  storeProductCategoryList: {
    count: 0,
    categories: [],
  },
  storeProducts: {
    meta: {
      count: 0,
    },
    records: [],
  },
  searchproductList: {
    count: 0,
    products: [],
  },
  searchkeywords: {
    keywords: [],
  },
  searchcategorykeywords: {
    keywordscategory: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_START:
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_START:
    case ActionTypes.ADMIN_GET_PRODUCT_INFO_START:
    case ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_START:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_START:
    case ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_START:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_START:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_START:

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_START:
    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_START:
    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_START:

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_START:
    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_START:
    case ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_START:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_START:
    case ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_START:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_START:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_START:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_START:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_START:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_START:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_START:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SETTING_START:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_START:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_FAILURE:
    case ActionTypes.ADMIN_GET_PRODUCT_INFO_FAILURE:
    case ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_FAILURE:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_FAILURE:
    case ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_FAILURE:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_FAILURE:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_FAILURE:

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_FAILURE:

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_FAILURE:
    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_FAILURE:
    case ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_FAILURE:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_FAILURE:
    case ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_FAILURE:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_FAILURE:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_FAILURE:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SETTING_FAILURE:
    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_FAILURE:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_SEARCH_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchproducts: action.payload.response.products
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchproducts: []
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_PRODUCTS_KEYWORDS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { keywords } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchkeywords: {
            keywords : keywords,
          },
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchkeywords: {
            keywords: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_PRODUCT_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          productdetail: action.payload.response
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          productdetail: {
              images: [],
              categories: [],
              commoncategories: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_GET_PRODUCT_SHOPS_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          productshopsdetail: action.payload.response
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          productshopsdetail: {
            productdetail: {
              images: [],
              categories: [],
              commoncategories: [],
            },
            productshops: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_COMMON_CATEGORY_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { categories } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchcategorykeywords: {
            keywordscategory : categories,
          },
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchcategorykeywords: {
            keywordscategory: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_PRODUCT_STATUS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_PRODUCT_CATEGORY_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProducts: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_STORE_PRIVATE_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          storeProductCategoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_STATUS_PRIVATE_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_GET_COMMON_PRODUCTS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const {product_count, products} = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: product_count,
            },
            records: products,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productsByCategory: {
            meta: {
              count: 0,
            },
            records: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_COMMON_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { categories } = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          categoryList: {
            categories : categories,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          categoryList: {
            count: 0,
            categories: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_COMMON_MAIN_CATEGORIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { categories } = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          mainCategoryList: {
            maincategories : categories,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          mainCategoryList: {
            count: 0,
            maincategories: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_SUCCESS:
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_COMMON_PRODUCT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }
    case ActionTypes.ADMIN_ADD_COMMON_PRODUCT_IMAGE_SUCCESS:
    case ActionTypes.ADMIN_REMOVE_COMMON_PRODUCT_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: action.payload.response.images,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          productImageList: {
            images: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SETTING_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS:
    case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          categoryImageList: {
            image: action.payload.response.image,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          categoryImageList: {
            image: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_PRODUCTS_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchproducts: [],
      };

    case ActionTypes.ADMIN_SEARCH_KEYWORDS_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchkeywords: {
          keywords : [],
        },
      };


    case ActionTypes.ADMIN_CATEGORY_SEARCH_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchcategorykeywords: {
          keywordscategory: [],
        },
      };

    case ActionTypes.ADMIN_PRODUCT_DETAIL_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        productdetail: {
          images: [],
          categories: [],
          commoncategories: [],
        },
      };

    default:
      return state;
  }
}
