// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminGetCommonOrders = (reqData, category) => ({
  type: ActionTypes.ADMIN_GET_COMMON_ORDERS_START,
  payload: reqData,
  category: category
});

export const adminGetCommonOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_COMMON_ORDERS_SUCCESS,
    payload: data,
  };
};
export const adminGetCommonOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_COMMON_ORDERS_FAILURE,
  payload: error,
});

export const adminGetStoreOrders = (reqData, orderstatus) => ({
  type: ActionTypes.ADMIN_GET_STORE_ORDERS_START,
  shopId: reqData,
  orderstatus: orderstatus,
});

export const adminGetStoreOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_STORE_ORDERS_SUCCESS,
    payload: data,
  };
};
export const adminGetStoreOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_STORE_ORDERS_FAILURE,
  payload: error,
});

export const adminSearchOrders = (oid, shopId, isShop, limit, status) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_START,
  oid: oid,
  shopId: shopId,
  isShop: isShop,
  limit: limit,
  status: status,
});

export const adminSearchOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_ORDERS_SUCCESS,
    payload: data,
  };
};

export const adminSearchOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_FAILURE,
  payload: error,
});

export const adminSearchOrderKeywords = (oid, shopId, isShop) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_START,
  oid: oid,
  shopId: shopId,
  isShop: isShop,
});

export const adminSearchOrderKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const adminSearchOrderKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_ORDERS_KEYWORDS_FAILURE,
  payload: error,
});

export const adminGetOrderDetail = (reqData) => ({
  type: ActionTypes.ADMIN_GET_ORDER_DETAIL_START,
  payload: reqData,
});

export const adminGetOrderDetailSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_ORDER_DETAIL_SUCCESS,
    payload: data,
  };
};
export const adminGetOrderDetailFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_ORDER_DETAIL_FAILURE,
  payload: error,
});

export const adminUpdateOrder = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_ORDER_START,
  payload: reqData,
});

export const adminUpdateOrderSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_ORDER_SUCCESS,
    payload: data,
  };
};
export const adminUpdateOrderFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_ORDER_FAILURE,
  payload: error,
});
