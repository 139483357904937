/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Notification } from "components/Atoms";
import {
  Divider, Typography, Container, LinearProgress, Card,
  IconButton,
  //Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText,
} from "@mui/material";
import ButtonComponent from "components/Button";
import EditIcon from "@mui/icons-material/Edit";

import "./style.scss";

import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import ShippingAddressEditor from "components/elements/core/ShippingAddressEditor";
import ShippingAddressVerify from "components/elements/core/ShippingAddressVerify";
import ShippingAddressPreview from "components/elements/core/ShippingAddressPreview";

const ShippingAddressList = (props) => {
  const [state, setState] = useState({ shipAddressMode: "PREVIEW" });
  const [addressState, setAddressState] = useState("");

  {/*
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState("");
  */}

  const {
    serviceError, reqStatus, isLoading, shippingAddress, cities, suggestions,
    getShippingAddress, getCities, history, updateShippingAddress, addShippingAddress, getAddressSuggestions,
  } = props;

  useEffect(() => {
    getShippingAddress();
    getCities();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADD_SHIPPING_ADDRESS_SUCCESS:
      case ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
      case ActionTypes.DELETE_SHIPPING_ADDRESS_SUCCESS:
        getShippingAddress();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateAddressToAccountCall = (values) => {
    if (!values._id) {
      addShippingAddress(values);
    } else {
      updateShippingAddress(values);
    }
    setState({ shipAddressMode: "PREVIEW" });
  };

  const verifyAddressToAccountCall = (values) => {
    getAddressSuggestions({ city: values.cityname, address: values.address});
    setState({ ...state, shipAddressMode: "VERIFY" });
  };

  const handleAddressEdit = (mode, item, reset) => () => {
    setState({ shipAddressMode: mode });
    if (reset) {
      setState({
        shippingAddressEditing: {
          name: "",
          address: "",
          area: "",
          _id: "",
          city: "",
          cityname: "",
          pincode: "",
          phone: "",
          place_id: "",
        },
      });
    } else {
      //item.place_id = "";
      setState({
        shippingAddressEditing: item,
      });
    }
  };

  const handlePage = (page) => () => {
    history.push({
      pathname: page,
    });
  };

  const { shipAddressMode, shippingAddressEditing = {} } = state;


  if (isLoading) {
    return (
      <Typography align="center">
        <LinearProgress />
      </Typography>
    );
  }

  const renderShippingAddressList = () => (
      <div className="product-list">
        <Divider />
        <Typography className="m-2" align="center">
          <ButtonComponent
              variant="contained"
              type="primary"
              name="Add delivery address"
              fullWidth
              onClick={handleAddressEdit("EDIT", null, true)}
          >

          </ButtonComponent>
        </Typography>
        <Divider />
        {shippingAddress.map((address) => (
            <Card className="mt-4" key={`pr-item-${address._id}`}>
              <ShippingAddressPreview
                  key={address._id}
                  shippingaddress={address}
                  actions={(
                      <>
                        <IconButton size="small" color="primary" onClick={handleAddressEdit("EDIT", address, false)}>
                          <EditIcon />
                        </IconButton>
                        {/* <IconButton size="small" onClick={() => handleDeleteOpen(address)}>
                            <DeleteIcon />
                          </IconButton> */}
                      </>
                  )}
              />
            </Card>
        ))}
        <Divider />
        <br />
      </div>
  );

  const renderShippingAddressEdit = () => {
    switch (shipAddressMode) {
      case "VERIFY":
        return (
        <ShippingAddressVerify
            shippingAddressEditing={shippingAddressEditing}
            suggestions={suggestions}
            setState={(event, field) =>
                setState({
              ...state,
              shippingAddressEditing: {
                ...shippingAddressEditing,
                [field]: event.target.value,
              },
            })}
            addUpdateShippingAddress={addUpdateAddressToAccountCall}
            cancelEdit={() => setState({ shipAddressMode: "EDIT" })}
        />
        );
      case "EDIT":
      default:
        return (
            <ShippingAddressEditor
                shippingAddressEditing={shippingAddressEditing}
                cities={cities}
                setState={(event, field) =>
                    setState({
                  ...state,
                  shippingAddressEditing: {
                    ...shippingAddressEditing,
                    [field]: event.target.value,
                  },
                })}
                verifyAddressToAccountCall={verifyAddressToAccountCall}
                cancelEdit={() => setState({ shipAddressMode: "PREVIEW" })}
            />
        );
    }
  };

  return (
      <Container>
        {shipAddressMode === "PREVIEW" ? (
            renderShippingAddressList()
        ) : (
            renderShippingAddressEdit()
        )}
      </Container>
  );
};

ShippingAddressList.propTypes = {
  history: PropTypes.object.isRequired,
  shippingAddress: PropTypes.arrayOf(PropTypes.object).isRequired,
  cities: PropTypes.arrayOf(PropTypes.object),
  suggestions: PropTypes.arrayOf(PropTypes.object),
  getCities: PropTypes.func.isRequired,
  getAddressSuggestions: PropTypes.func.isRequired,
  addShippingAddress: PropTypes.func.isRequired,
  updateShippingAddress: PropTypes.func.isRequired,
  deleteShippingAddress: PropTypes.func.isRequired,
  getShippingAddress: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

const _ShippingAddressList = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    shippingAddress: bootupInfo.shippingAddress,
    cities: bootupInfo.cities,
    suggestions: bootupInfo.addresssuggestions,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShippingAddressList);

export { _ShippingAddressList as default };
