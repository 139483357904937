/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Link, useHistory} from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {getDistance, getImage, getImageOrLogo, getLocalDate, getLogo, getURI} from "helpers/utils";


import "./AdminOfferListItem.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H4 } from "components/CustomTypography";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";


const AdminOfferListItem = (props) => {
  const [state, setState] = useState("");

    const history = useHistory();

  const {
      offer
  } = props;


    const handleOfferButtonClick = () => {
        history.push(getURI(process.env.REACT_APP_TYPE, "offer", "", "", offer.offer_id));
    };

    return (
        <Box sx={{backgroundColor : "#eee"}} width="100%" display="flex" p={1} m={1} onClick={handleOfferButtonClick}>
            <Box width="20%" order={1}>
                <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={() => handleOfferButtonClick()}>
                    <LazyImage
                        src={getImageOrLogo(offer.images, offer.logo)}
                        width={80}
                        height={80}
                        mx="auto"
                    />
                </HoverBox>
            </Box>
            <Box width="80%" order={1} ml={2}>
                <H2 mb={1} fontSize={16} ellipsis>
                    {offer.name}
                </H2>
                <H4 mb={1} fontSize={12}>
                    {offer.description}
                </H4>
                <H4 mb={1} fontSize={12}>
                    Added: {getLocalDate(offer.offer_created_at, DATE_TIME_FORMAT_SHORT)}
                </H4>
            </Box>
        </Box>
    );
};

AdminOfferListItem.defaultProps = {

};

AdminOfferListItem.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
};

const adminOfferListItem = connect(
  ({ adminOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminOffers.reqStatus,
    isLoading: adminOffers.isLoading,
    serviceError: adminOffers.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminOfferListItem);

export default adminOfferListItem;
