/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Box } from "@mui/material";
import * as Actions from "actions";

// Import Swiper styles
import 'swiper/swiper.min.css';

import ShopProductItem from "components/elements/user/products/ShopProductItem";


import { CURRENCY } from "config/constants";
import MockProductBox from "components/elements/main/MockProductBox";
import {checkProductInCart, isFeatureSupported, isMarketPlace, isShopSiteAndSubdomain} from "helpers/utils";
import CustomSectionCreator from "components/CustomSectionCreator";
import StorageIcon from "@mui/icons-material/Storage";

const ShopProductPopularSlider = (props) => {
  const {
    products, currency, addProductToCart, addProductToWishlist, addToCompare,
    usergetPopularProducts, bootupInfo, isLoading, shopId,
      cartItems, isCartLoading, cartAddingPid,
  } = props;

  useEffect(() => {
    usergetPopularProducts(shopId, true, !isMarketPlace());
  }, []);

    const breakpoints = {
        300: {
            slidesPerView: 2.0,
            spaceBetween: 20,
        },
        340: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        380: {
            slidesPerView: 2.4,
            spaceBetween: 20,
        },
        420: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 2.8,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    const renderResults = () => (
        <Container>
            {<CustomSectionCreator title={'Popular ' + process.env.REACT_APP_PRODUCT_TITLE} seeMoreLink={isShopSiteAndSubdomain() ?
                                                                            isFeatureSupported("shopreels") ? "/products/sort/popular?ls=1" : "/products/sort/popular"
                                                                            :
                                                                            isFeatureSupported("shopreels") ?`/shop/${shopId}/products/sort/popular?ls=1` : "/shop/${shopId}/products/sort/popular"}
                                                            seeMoreLinkTitle={isFeatureSupported("shopreels") ?  "View " + process.env.REACT_APP_REELS_TAG : "View more"} />}
            <swiper-container slides-per-view="1.8" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
                    { products.map((product, index) => (
                        <swiper-slide key={index}>
                            <Box sx={{ width: 150 }}>
                            <ShopProductItem
                                product={product}
                                isDirect={true}
                                link={isShopSiteAndSubdomain() ?
                                    isFeatureSupported("shopreels") ? "/products/sort/popular?ls=1&li=" + index : ""
                                    :
                                    isFeatureSupported("shopreels") ?`/shop/${shopId}/products/sort/popular?ls=1&li=` + index : ""
                                }
                                currency={currency}
                                shopId={shopId}
                                inCart={checkProductInCart(cartItems, product.id)}
                                isCartLoading={isCartLoading}
                                cartAddingPid={cartAddingPid}
                                onAddToCompareClicked={() => addToCompare(product)}
                                onAddToWishlistClicked={() => addProductToWishlist(product)}
                                onAddToCartClicked={() => addProductToCart(product._id, shopId, !isMarketPlace())}
                            />
                            </Box>
                        </swiper-slide>
                    ))}
            </swiper-container>
        </Container>
    );
  return (

      <>
          {isLoading ? <MockProductBox />
              : products.length > 0
                  ? renderResults()
                  : ""}

      </>
  );
};

ShopProductPopularSlider.defaultProps = {
  symbol: CURRENCY,
};

ShopProductPopularSlider.propTypes = {
    currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usergetPopularProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    products: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Mapping Props and State.
const shopProductPopularSlider = connect(
  ({ userproducts, cartList, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.popularproducts,
      cartItems: cartList.cart,
      isCartLoading: cartList.isLoading,
      cartAddingPid: cartList.cartAddingPid,
      currency: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductPopularSlider);

export { shopProductPopularSlider as default };
