/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import "components/elements/main/index.scss";
import {Box, Button, IconButton} from "@mui/material";
import {FlexBetween, FlexBox} from "components/flex-box";
import MockShopHeader from "components/elements/main/MockShopHeader";

import { Avatar, Card, Typography } from "@mui/material";
import { H1, H2, H5, H6 } from "components/CustomTypography";
import {getLogo, openGoogleMapLocation } from "helpers/utils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import * as ActionTypes from "constants/ActionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import PlaceIcon from "@mui/icons-material/Place";
import ButtonComponent from "../../../../Button";
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import UserSellerShopView from "../UserSellerShopView";

const ShopHeaderNav = (props) => {

    const {
        onCancel, history
    } = props;

  const [state] = useState({
    layoutColumns: 3,
  });

    const handleBack = () => {
        history.goBack();
    };

    const handleHome = () => {
        history.push(`/`);
    };

    return (
        <Box mb={2} width="100%" display="flex">
            <Box width="50%" alignItems="left">
                <ButtonComponent
                    type="primary"
                    variant="contained"
                    name="< Back"
                    size="small"
                    onClick={onCancel ? onCancel : handleBack}
                />
            </Box>
            <Box width="50%" display="flex" justifyContent="flex-end">
                <ButtonComponent
                    type="primary"
                    variant="contained"
                    name="Home"
                    size="small"
                    onClick={handleHome}
                />
            </Box>
        </Box>
    );
};

export default ShopHeaderNav;
