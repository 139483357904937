/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Box, Card, IconButton, Button,
} from "@mui/material";
import {checkUserAllowed, getImage, getLocalDate, getLogo} from "helpers/utils";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ViewListIcon from '@mui/icons-material/ViewList';
import EditIcon from "@mui/icons-material/Edit";
import "./AdminSellerShop.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {H2, H4, H5, H6} from "components/CustomTypography";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";

const AdminSellerShopItem = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const history = useHistory();

  const {
    shop = {}, isSame
  } = props;


  return (
      <Box mb={2} alignItems="center" width="100%" onClick={() => history.push(`/shops/shop/${shop.value}`)}>
          <LazyImage
              src={getImage(shop.images)}
              width="100%"
              mx="auto"
          />
          <H5 fontSize={16} ellipsis>
              <b>
                  {shop.label} {isSame && "(Selected)"}
              </b>
          </H5>
          <H5 fontSize={12} ellipsis>
              {shop.sublabel}
          </H5>
          <H5 fontSize={12} ellipsis>
              {shop.sublabel2}
          </H5>
      </Box>
  );
};

export default AdminSellerShopItem;
