/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React from "react";
import ErrorBoundaryComponent from "components/ErrorBoundary";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "actions/core/core.actions";


// Fallback UI when something goes wrong in UI
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorInfo: error };
  }

  componentDidCatch(error, info) {
      this.props.addAppErrorLog({error: error.toString(), info: info.componentStack.toString(), app: process.env.REACT_APP_TYPE});
    // log the error to error reporting service
  }

  render() {
    const { hasError, errorInfo } = this.state;
    if (hasError) {
      return <ErrorBoundaryComponent errorInfo={errorInfo.toString()} />;
    }
    return this.props.children;
  }
}

// Mapping Props and State.
const errorBoundary = connect(
    ({ bootupInfo }) => ({
      bootupInfo,
      reqStatus: bootupInfo.reqStatus,
      isLoading: bootupInfo.isLoading,
      serviceError: bootupInfo.serviceError,
      serviceCode: bootupInfo.serviceCode,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ErrorBoundary);

export { errorBoundary as default };
