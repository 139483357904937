import ArrowRight from '@mui/icons-material/ArrowRight'
import { Link } from "react-router-dom";
import React, { ReactNode } from 'react'
import CustomFlexBox from "components/CustomFlexBox"
import { H2 } from './CustomTypography'


const CustomSectionHeader = ({ title, seeMoreLink, seeLink, seeMoreLinkTitle, icon, isSubtle }) => (
    <CustomFlexBox  sx={{borderBottom: 3, borderColor: 'primary.100'}} bgcolor={isSubtle ? "grey.300": "grey.100"} justifyContent="space-between" alignItems="center" p={1}>
        <Link to={seeLink ? seeLink : seeMoreLink}>
            <a>
                <CustomFlexBox alignItems="center">
                    {icon && (
                        <CustomFlexBox color={isSubtle ? "grey.900" : "grey.100"} mr={1} alignItems="center">
                            {icon}
                        </CustomFlexBox>
                    )}
                    <H2 color={isSubtle ? "grey.900" : "primary.100"} fontSize={18} fontWeight="bold" lineHeight="1">
                        {title}
                    </H2>
                </CustomFlexBox>
            </a>
        </Link>

      {seeMoreLink && (
        <Link to={seeMoreLink}>
          <a>
            <CustomFlexBox alignItems="center" ml={1} color={isSubtle ? "grey.900" : "primary.100"}>
                {seeMoreLinkTitle}
              <ArrowRight fontSize="small" color="inherit" />
            </CustomFlexBox>
          </a>
        </Link>
      )}
    </CustomFlexBox>
)

export default CustomSectionHeader
