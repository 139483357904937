import * as yup from "yup";

export const schema = yup.object().shape({
  status: yup.string().required("Status is required field"),
});


export const getRadioButtonData = (status) => [
  {
    label: "Status",
    value: status,
    onChangeField: "status",
  },
];

export const getButtonData = (onFinish, onCancel, hasError, isTouched) => [
  {
    name: "Update",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError(),
  },
  {
    name: "Cancel",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];

export const getTextfieldUpdateDomain = (subdomain) => [
  {
    label: "Subdomain",
    value: subdomain,
    onChangeField: "subdomain",
  },
];

export const getButtonUpdateDomain = (onFinishUpdateDomain) => [
  {
    name: "Update Domain",
    onClick: onFinishUpdateDomain,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldUpdateSocial = (instahandle, facebookhandle, whatsapphandle) => [
  {
    label: "Instagram",
    value: instahandle,
    onChangeField: "instahandle",
  },
  {
    label: "Facebook",
    value: facebookhandle,
    onChangeField: "facebookhandle",
  },
  {
    label: "Whatsapp",
    value: whatsapphandle,
    onChangeField: "whatsapphandle",
  },
];

export const getButtonUpdateSocial = (onFinishUpdateSocial) => [
  {
    name: "Update Social",
    onClick: onFinishUpdateSocial,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldDataInternalMessage = (internalmessage) => [
  {
    label: "Message",
    value: internalmessage,
    onChangeField: "message",
  },
];

export const getButtonDataSendInternalMsg = (onFinishSendInternalMsg) => [
  {
    name: "Add Message",
    onClick: onFinishSendInternalMsg,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldDataMessage = (message) => [
  {
    label: "Message",
    value: message,
    onChangeField: "message",
  },
];

export const getButtonDataSendMsg = (onFinishSendMsg) => [
  {
    name: "Send Message to Seller",
    onClick: onFinishSendMsg,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendApproved = (onFinishApproved) => [
  {
    name: "Approve Shop",
    onClick: onFinishApproved,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendRejected = (onFinishRejected) => [
  {
    name: "Reject Shop",
    onClick: onFinishRejected,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendDeleted = (onFinishDeleted) => [
  {
    name: "Delete Shop",
    onClick: onFinishDeleted,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendUnlockURL = (onFinishUnlocked) => [
  {
    name: "Unlock URL",
    onClick: onFinishUnlocked,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendEnable = (onFinishEnable) => [
  {
    name: "Enable Shop",
    onClick: onFinishEnable,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendDisable = (onFinishDisable) => [
  {
    name: "Disable Shop",
    onClick: onFinishDisable,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendDisplayReq = (onFinishDisplayReq) => [
  {
    name: "Request New Display",
    onClick: onFinishDisplayReq,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendDisplayDelivered = (onFinishDisplayDelivered) => [
  {
    name: "Display Delivered",
    onClick: onFinishDisplayDelivered,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendDisplayPrinted = (onFinishDisplayPrinted) => [
  {
    name: "Display Printed",
    onClick: onFinishDisplayPrinted,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataSendVerified = (onFinishVerified) => [
  {
    name: "Enter PIN & Verify",
    onClick: onFinishVerified,
    variant: "contained",
    type: "primary",
    disabled: false
  },
];

export const getButtonDataCancel = (onCancel) => [
  {
    name: "Close",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false
  },
];

export const getTextfieldDataPin = (pin) => [
  {
    label: "Pin",
    value: pin,
    onChangeField: "pin",
  },
];
