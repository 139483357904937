import React, { useEffect, useRef } from 'react';
import createStoriesSlider from './stories-slider.esm.js';

export default function StoriesSlider(props) {
  const {
    Swiper,
    EffectCube,
    Virtual,
    enabled = true,
    autoplayDurationVideo = 14000,
    autoplayDurationPhoto = 5000,
    onStoriesSlider,
    onSlidesIndexesChange,
    onAutoplayStart,
    onAutoplayStop,
    onEnd,
    children,
    ...rest
  } = props;

  const elRef = useRef(null);
  const storiesSlider = useRef(null);

  useEffect(() => {
    storiesSlider.current = createStoriesSlider(elRef.current, {
      Swiper,
      EffectCube,
      Virtual,
      enabled,
      autoplayDurationVideo,
      autoplayDurationPhoto,
      onSlidesIndexesChange,
      onAutoplayStart,
      onAutoplayStop,
      onEnd,
    });

    if (storiesSlider) {
      console.log("StoriesSlider: in effect storeisSlider: " + storiesSlider);
    } else {
      console.log("StoriesSlider: in effect storeisSlider: is NULL");
    }
    if (onStoriesSlider) onStoriesSlider(storiesSlider.current);
    return () => {
      if (storiesSlider.current && storiesSlider.current.destroy) {
        storiesSlider.current.destroy();
      }
    };
  }, []);

  return (
    <div className="stories-slider" ref={elRef} {...rest}>
      <div className="swiper">
        <div className="swiper-wrapper">{children}</div>
      </div>
    </div>
  );
}
