// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

function* userMainGetOrderlistProducts(action: Object) {
  try {
    const response = yield API.get(
        `/users/orders/get-orders`,
        { withCredentials: true }
    );
    yield put(Actions.userMainGetOrderlistProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetOrderlistProductsFailure(error));
  }
}


function* userMainGetOrderDetail(action: Object) {
  try {
    const response = yield API.get(
        `/users/orders/get-order/` + action.payload,
        { withCredentials: true }
    );
    yield put(Actions.userMainGetOrderDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.userMainGetOrderDetailFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_MAIN_GET_ORDERLIST_PRODUCTS_START,         userMainGetOrderlistProducts);
  yield takeLatest(ActionTypes.USER_MAIN_GET_ORDER_DETAIL_START,               userMainGetOrderDetail);
}
