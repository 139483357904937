import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { DATE_FORMAT } from "config/constants";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import {getLocalDate, getTimeSlot, getImage, isShopSiteAndSubdomain, isMarketPlace, getURI} from "helpers/utils";
import {
    Avatar, Box, Button, Card, Container, IconButton, Typography,
} from "@mui/material";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CustomSectionCreator from "components/CustomSectionCreator";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import ImagesDownload from "components/elements/main/ImagesDownload";
import * as ActionTypes from "constants/ActionTypes";
import {Notification} from "components/Atoms";
import ButtonComponent from "components/Button";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";

const OrderSuccess = (props) => {

  const { location } = props;

  const {
    order_id, _id, items, shipping, symbol, total_price, total_shipping, total_order, total_tax, created_at, order_type, delivery_date, delivery_time, status,
  } = location.state || {};

    const {
        serviceError, reqStatus, isLoading,
        shopinfo, userUpdateOrderStatus, history
    } = props;

    const [copySuccess, setCopySuccess] = useState('Copy');

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };


    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.USER_UPDATE_ORDER_STATUS_SUCCESS:
                history.push(getURI(process.env.REACT_APP_TYPE, "shop_orders", shopinfo.id, "", ""));
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

    const updateOrder = () => {
        userUpdateOrderStatus({ order_id: _id, status: "paid", message: "" });
    };

    const renderOrderInformation = () => (
    <Box>
          <CustomSectionCreator icon={<ShoppingCartRoundedIcon />} title={"Order Confirmation: " + order_id}>
          </CustomSectionCreator>
    </Box>
  );

    const renderPendingPaymentInformation = () => (
        <Box>
            <CustomSectionCreator icon={<CurrencyRupeeIcon />} title={"Next step: Make payment to confirm order"}>
                {shopinfo.paymentoption && shopinfo.paymentoption === "upibased" ?
                    <Box>
                        <Typography>Pay via UPI payment app: <b>{shopinfo.paymentupi}</b>
                            &nbsp;<ButtonComponent variant="contained"
                                                   type="primary"
                                                   name={copySuccess}
                                                   size="small"
                                                   onClick={() => copyToClipBoard(shopinfo.paymentupi)} />
                        </Typography>
                        <Typography>Payment due: <b>{symbol + total_order}</b></Typography>
                    </Box>

                    :
                    <ImagesDownload
                        images={shopinfo.paymentqrcode}
                        message={"Download QRCode and Pay via payment app " + symbol + total_order}
                        shopqrfilename={shopinfo.title + " order " + order_id}
                    />
                }
                <Box><Typography>Add order no: {order_id} in payment information</Typography></Box>
                <Box>
                    <ButtonComponent
                        variant="contained"
                        type="primary"
                        name="Confirm Paid"
                        fullWidth
                        onClick={() => updateOrder()}
                    />
                </Box>
            </CustomSectionCreator>
        </Box>
    );

    const renderPaymentInformation = () => (
        <Box>
            {status === "pending" ? renderPendingPaymentInformation()
                :
                <CustomSectionCreator icon={<CurrencyRupeeIcon />} title={"Payment"}>
                    <Box><Typography>Status: Paid</Typography></Box>
                </CustomSectionCreator>
            }
        </Box>
    );

  const renderOrderItems = () => (
    <Card className="p-3">
      {items.map((cartItem, index) => (
        <Card className="order-card" key={`${index + 1}`}>
          <Box minHeight="100%" width="100%" display="flex">
            <Box className="styled-card" width={window.innerWidth < 400 ? "17%" : "12%"}>
                <Avatar variant="square" src={getImage(cartItem.product.images)} alt="" />
            </Box>
            <Box flexGrow={1}>
              <Typography className="styled-link" gutterBottom variant="h6" component="p">
                {cartItem.product.name}
              </Typography>
              <Typography display="block" variant="caption">
                {cartItem.quantity}
                {" "}
                x
                {symbol}
                {cartItem.seller_price}
              </Typography>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                    {cartItem.product_size}
                </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {symbol}
                {cartItem.quantity * cartItem.seller_price}
              </Typography>
            </Box>
          </Box>
        </Card>
      ))}
    </Card>
  );

  const renderShippingAddress = () => (
    <Card className="p-3">
      <Typography variant="h6">Delivery</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
      <Typography variant="h6">Address</Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.name}
      </Typography>
      <Typography variant="subtitle2" display="block">
        {shipping.address}
        ,
        {shipping.city}
        ,
        {shipping.pincode}
      </Typography>
      <Typography display="block" variant="subtitle2">
        {shipping.phone}
      </Typography>
    </Card>
  );

  const renderShopPickup = () => (
    <Card className="p-3">
      <Typography variant="h6">Pickup</Typography>
      <Typography variant="subtitle2" display="block">
        {getLocalDate(delivery_date, DATE_FORMAT)}
        ,
        {getTimeSlot(delivery_time)}
      </Typography>
    </Card>
  );

  const renderOrderTotal = () => (
    <Card className="p-3">
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Subtotal: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_price}
          </Typography>
        </Box>
      </Box>
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Shipping: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_shipping}
          </Typography>
        </Box>
      </Box>
        <Box display="flex">
            <Box width="50%">
                <Typography variant="subtitle2" display="block">Taxes: </Typography>
            </Box>
            <Box width="50%">
                <Typography variant="subtitle2" display="block" align="right">
                    {symbol}
                    {total_tax}
                </Typography>
            </Box>
        </Box>
      <hr className="solid" />
      <Box display="flex">
        <Box width="50%">
          <Typography variant="subtitle2" display="block">Total price: </Typography>
        </Box>
        <Box width="50%">
          <Typography variant="subtitle2" display="block" align="right">
            {symbol}
            {total_order}
          </Typography>
        </Box>
      </Box>
    </Card>
  );

    const renderOrderDetails = () => (
        <Box>
            <CustomSectionCreator icon={<ShoppingCartRoundedIcon />} title={"Order detail: " }>
                {renderOrderItems()}
                {renderOrderTotal()}
            </CustomSectionCreator>
        </Box>
    );

  return (
    (order_id)
      ? (
        <div>
            <ShopHeaderNav history={history} />
            {isMarketPlace() && <ShopHeaderMini {...props} />}
          <Container maxWidth="md">
              {renderOrderInformation()}

            <Box display={window.innerWidth < 400 ? "" : "flex"}>
              <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                {order_type === "delivery" ? renderShippingAddress() : renderShopPickup()}
              </Box>
            </Box>
              {renderPaymentInformation()}
              {renderOrderDetails()}
          </Container>
        </div>
      )
      : (
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <a href="/" className="btn btn-solid">back to home</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
  );
};

OrderSuccess.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};


// Mapping Props and State.
const orderSuccess = connect(
    ({ bootupInfo, userorders }) => ({
        bootupInfo,
        symbol: bootupInfo.symbol,
        shopinfo: bootupInfo.shopinfo,
        reqStatus: userorders.reqStatus,
        isLoading: userorders.isLoading,
        serviceError: userorders.serviceError,
    }),
    (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(OrderSuccess);

export { orderSuccess as default };

