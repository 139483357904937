import { SvgIcon } from "@mui/material";
import React from "react";

const MakeUp = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M252.43,293.891c-4.985-2.372-10.95-0.257-13.324,4.728l-1.658,3.484c-2.373,4.985-0.256,10.95,4.728,13.324
			c1.388,0.661,2.85,0.974,4.291,0.974c3.736,0,7.32-2.103,9.033-5.702l1.658-3.484C259.532,302.229,257.414,296.264,252.43,293.891
			z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M140.39,235.953c-3.904-3.904-10.234-3.904-14.138,0c-8.288,8.289-21.775,8.289-30.064,0
			c-3.904-3.904-10.234-3.904-14.138,0c-3.904,3.904-3.904,10.234,0,14.138c8.042,8.041,18.606,12.063,29.17,12.063
			c10.564,0,21.127-4.021,29.169-12.063C144.293,246.188,144.293,239.858,140.39,235.953z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M477.379,26.56c-35.221-35.22-92.454-35.322-127.815-0.328c-11.038-5.48-22.757-10.181-35.113-14.051
			C273.916-0.516,240.754-0.045,237.878,0.018C183.647,0.054,136.343,22.809,114.4,59.422c-2.896,4.832-5.309,9.84-7.245,14.993
			C96.82,84.256,50.18,132.457,50.18,200.803c0,45.522-21.896,69.289-34.947,79.405c-4.877,3.78-7.518,9.716-7.063,15.878
			c0.455,6.165,3.94,11.651,9.323,14.676c4.918,2.764,10.449,5.417,15.797,7.983c4.729,2.269,11.449,5.493,14.726,7.591v17.806
			c0,2.436-0.465,4.815-1.382,7.074l-2.479,6.102c-3.395,8.354-2.145,17.919,3.243,25.117v2.193c0,6.487,2.856,12.491,7.613,16.564
			v11.738c0,25.155,20.464,45.619,45.619,45.619h29.518c5.521,0,9.997-4.476,9.997-9.997c0-5.521-4.476-9.997-9.997-9.997h-29.518
			c-14.129,0-25.626-11.496-25.626-25.626v-17.248c0-4.006-2.391-7.623-6.075-9.195l-0.432-0.184
			c-0.672-0.287-1.106-0.944-1.106-1.674v-5.643c0-2.242-0.754-4.42-2.14-6.182l-1.764-2.242c-1.274-1.618-1.583-3.81-0.808-5.718
			l2.479-6.103c1.892-4.659,2.852-9.57,2.852-14.598v-21.283c0-9.633-9.813-14.341-26.071-22.141
			c-4.269-2.048-8.659-4.155-12.542-6.232c16.078-13.122,40.778-41.746,40.778-93.684c0-37.582,16.729-68.898,31.242-89
			c0.793,17.419,6.062,35.635,15.813,53.874c25.657,47.987,70.337,68.101,106.238,84.261c34.158,15.376,53.491,25.055,53.646,45.175
			c0.113,14.775-3.285,25.646-10.1,32.309c-7.868,7.694-18.312,7.497-18.375,7.496c-3.355-0.235-6.5,1.23-8.543,3.898
			c-2.044,2.668-2.509,6.191-1.408,9.366c0.293,0.846,7.375,20.926,23.588,40.493c20.264,24.455,45.843,37.359,74.364,37.648
			l26.862,78.756c1.417,4.157,5.301,6.773,9.46,6.773c1.07,0,2.159-0.173,3.228-0.539c5.226-1.782,8.017-7.463,6.234-12.688
			l-26.454-77.561c49.873-26.726,84.938-68.185,101.654-120.399c13.325-41.621,13.962-88.323,2.703-132.555
			c6.169-3.75,11.886-8.227,17.051-13.392C512.712,119.385,512.712,61.894,477.379,26.56z M438.584,294.571
			c-15.688,49.005-49.369,87.588-97.468,111.694c-24.321,1.092-45.534-8.958-63.071-29.899c-7.071-8.442-12.189-17.132-15.54-23.71
			c5.704-1.784,12.255-4.963,18.153-10.612c11.073-10.606,16.608-26.447,16.45-47.083c-0.26-33.918-31.917-48.167-65.432-63.254
			c-34.86-15.692-74.371-33.477-96.814-75.456c-13.942-26.079-17.224-50.876-9.793-72.617c0.031-0.083,0.06-0.167,0.089-0.25
			c1.629-4.71,3.754-9.28,6.394-13.685C149.919,39.05,190.712,20.01,238.01,20.01c0.096,0,0.191-0.001,0.288-0.004
			c0.311-0.01,31.819-0.763,70.178,11.252c51.047,15.991,88.65,45.786,111.763,88.558
			C449.449,173.877,456.308,239.205,438.584,294.571z M454.352,148.127c-4.46-12.997-9.964-25.674-16.525-37.815
			c-16.411-30.368-40.12-55.302-69.855-73.819c27.72-23.239,69.218-21.849,95.27,4.204c27.538,27.538,27.538,72.346,0,99.884
			C460.47,143.354,457.499,145.873,454.352,148.127z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M264.573,468.308l-31.321-54.25l-1.059-37.043c-0.886-31.022-24.881-56.313-55.814-58.83
			c-22.746-1.854-44.585,10.76-54.354,31.383l-0.494,1.042c-12.95,27.335-3.607,60.403,21.732,76.921l31.179,20.324l34.062,58.996
			c1.851,3.207,5.211,5,8.666,4.999c1.695,0,3.415-0.432,4.989-1.341c4.781-2.76,6.42-8.874,3.659-13.656l-30.259-52.41
			l12.056-6.961l12.056-6.961l27.586,47.78c2.759,4.781,8.873,6.419,13.656,3.659C265.696,479.203,267.334,473.089,264.573,468.308z
			 M182.223,429.061l-28.04-18.278c-17.002-11.083-23.271-33.272-14.582-51.614l0.494-1.042C145.921,345.83,158.479,338,171.944,338
			c0.935,0,1.874,0.038,2.815,0.115c20.755,1.688,36.855,18.659,37.449,39.473l0.961,33.606L182.223,429.061z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M279.555,494.935c-1.859-1.86-4.439-2.929-7.068-2.929c-2.639,0-5.208,1.07-7.068,2.929s-2.929,4.439-2.929,7.068
			s1.07,5.208,2.929,7.068c1.859,1.859,4.429,2.929,7.068,2.929c2.629,0,5.208-1.07,7.068-2.929
			c1.859-1.859,2.929-4.439,2.929-7.068S281.414,496.795,279.555,494.935z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M197.63,58.603c-2.171-5.075-8.045-7.433-13.123-5.262c-17.631,7.537-30.347,20.242-35.806,35.776
			c-5.47,15.565-3.416,33.043,5.94,50.542c1.801,3.369,5.254,5.285,8.825,5.285c1.591,0,3.204-0.381,4.704-1.183
			c4.869-2.603,6.706-8.66,4.103-13.53c-6.738-12.603-8.322-24.206-4.709-34.488c3.579-10.183,12.387-18.714,24.803-24.02
			C197.443,69.555,199.799,63.681,197.63,58.603z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M222.014,46.003l-0.56,0.001c-5.52,0.032-9.971,4.534-9.938,10.056c0.032,5.501,4.502,9.938,9.996,9.938
			c0.02,0,0.04,0,0.06,0l0.442-0.001c5.521,0,9.997-4.476,9.997-9.997S227.535,46.003,222.014,46.003z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default MakeUp;
