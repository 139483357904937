/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions";

import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid,
} from "@mui/material";

import {
  Notification,
} from "components/Atoms";

import Autocomplete from "@mui/material/Autocomplete";
import UserSellerOffer from "components/elements/user/offers/UserSellerOffer";
import UserSellerOfferView from "components/elements/user/offers/UserSellerOfferView";
import UserSellerOtherOfferView from "components/elements/user/offers/UserSellerOtherOfferView";
import MockProductBox from "components/elements/main/MockProductBox";
import { H5, H6 } from "components/CustomTypography";
import {SearchOutlinedIcon} from "../UserCombinedSearch";
import CustomSectionCreator from "components/CustomSectionCreator";
import LazyImage from "components/LazyImage";
import {getImage, getLocationBreadcrum, isMarketPlaceOnly} from "helpers/utils";
import UserShopLocationSlider from "components/elements/user/UserShopLocationSlider";
import * as ActionTypes from "constants/ActionTypes";
import {userMainGetOfferDetail} from "actions";

const UserOfferSearch = (props) => {

  const [state, setState] = useState({
    limit: 5,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
  });
  const [shopState, setShopState] = useState("");
  const [shopModeState, setShopModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);
  const [locationState, setLocationState] = useState("");
  const [marketState, setMarketState] = useState("");
  const [sortState, setSortState] = useState("popular");

  const {
    serviceError, reqStatus, isLoading,
    offers, otheroffers, shopdetail, offerdetail, currency,
    userMainSearchOffers, userMainGetOfferDetail, userMainGetShopInfoById,
    userMainSearchOfferKeywords, citylocations,
    userMainResetSearchOffers, userMainResetSearchOfferKeywords,
    userMainAddStoreToFavlist,
    searchkeywords, match, history, symbol = "$", marketId
  } = props;

  const { keywords } = searchkeywords;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;
  const { sort } = match.params;
  const { location } = match.params;
  const { market } = match.params;

  useEffect(() => {

    ReactGA.send({
      hitType: "pageview",
      page: "/offers",
      title: "Main Offers",
    });

    if (sort) {
      setSortState(sort);
    }

    if (query) {
      userMainResetSearchOffers();
      userMainSearchOffers(query, sort, "", marketId);
    } else if (location) {
      // need lat/lng to do query
      setLocationState(location);
      userMainSearchOffers("", sort, location, marketId);
    } else if (market) {
      setMarketState(market);
      userMainSearchOffers("", sort, market, marketId);
    } else {
      userMainSearchOffers("", sort, location, marketId);
    }
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const runSearch = (keyword) => {
    userMainResetSearchOfferKeywords();
    setState({ resetvalue: "" });
    history.push(`/offers/search/${keyword}`);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const selectItem = (e, value) => {
    if (e.key === "Enter") {
      runSearch(value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      history.push(`/offer/${option.key}`)
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      userMainSearchOfferKeywords(value,
        props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
    }
  };

  const handleDelete = () => {
    history.push("/offers");
  };


  const addShopFavourite = (shopId) => {
    userMainAddStoreToFavlist(shopId)
    setShopModeState("PREVIEW");
  };

  const handleShopOfferView = (mode, offer, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedOffer: {
          offer_id: "",
          shop_id: "",
        },
      });
    } else {
      userMainGetOfferDetail(offer.offer_id);
      setShopState({
        selectedOffer: {
          offer_id: offer.offer_id,
          shop_id: offer._id,
        },
      });
    }
  };

  const handleShopOtherOfferView = (mode, offer, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedOffer: {
          offer_id: "",
          shop_id: "",
          name: "",
          description: "",
          title: "",
          valid_date: "",
          images: [],
          logo: [],
        },
      });
    } else {
      setShopState({
        selectedOffer: {
          offer_id: offer.offer_id,
          shop_id: offer._id,
          name: offer.name,
          description: offer.description,
          title: offer.title,
          valid_date: offer.valid_date,
          images: offer.images,
          logo: offer.logo,
        },
      });
    }
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);


  const handleLocationSelect = (loc) => {
    setLocationState(loc.id);
    userMainSearchOffers("", sort, loc.id, marketId);
  };

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/offers/search")) {
      props.history.push("/offers/search");
    }
  };

  let isModalSearch = false;

  const renderNoResults = () => (
      <Container>
        <Box>
          {query && (<Chip label={query} onDelete={handleDelete}/>)}
          {!query && (<CustomSectionCreator title="Popular Offers" />)}
          <Typography variant="h6">
              No Offer found
          </Typography>
        </Box>
      </Container>
  );

  var type = {
    'new': 'Latest Offers',
    'default': 'Latest Offers'
  };

  var othertype = {
    'new': 'Latest Offers from other shops',
    'default': 'Latest Offers from other shops'
  };

  const renderOfferResults = () => (
      <div>
        {query && (<Chip label={query} onDelete={handleDelete}/>)}
        {!query && (<CustomSectionCreator title={(type[sort] || type['default']) + " " + getLocationBreadcrum(citylocations, locationState)} />)}
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {offers.map((offer, index) => (
              <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                <UserSellerOffer
                    offer={offer}
                    symbol={currency}
                    viewOffer={handleShopOfferView("VIEW", offer, false)}
                    key={`${index + 1}`}
                />
              </Grid>
          ))}
        </Grid>
      </div>
  );

  const renderOtherOffersResults = () => (
      <div>
        {query && (<Chip label={query} onDelete={handleDelete}/>)}
        {!query && (<CustomSectionCreator title={(othertype[sort] || othertype['default']) + " " + getLocationBreadcrum(citylocations, locationState)} />)}
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {otheroffers.map((offer, index) => (
              <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
                <UserSellerOffer
                    offer={offer}
                    symbol={currency}
                    viewOffer={handleShopOtherOfferView("VIEW_OTHER", offer, false)}
                    key={`${index + 1}`}
                />
              </Grid>
          ))}
        </Grid>
      </div>
  );

  const renderSearchHelpMobile = () => (
      <Container>
        <Box>
          {
            window.innerWidth <= 500
            && (match.path === "/offers/search")
            && optionValues.map((element) => (
                  <Box m={2} alignItems="center" width="100%" display="flex" onClick={() => history.push(`/offer/${element.value}`)}>
                    <Box width="75%">
                      <H5 fontSize={16} ellipsis>
                        {element.label}
                      </H5>
                      <H6 fontSize={12} ellipsis>
                        {element.sublabel}
                      </H6>
                    </Box>
                    <Box width="25%">
                      <LazyImage
                          src={getImage(element.images)}
                          width={60}
                          height={60}
                          mx="auto"
                      />
                    </Box>
                  </Box>
            ))
          }
        </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>
      {/*
      <Container onClick={MobileViewOnClick}>
          <Box mb={2}>
        {
            window.innerWidth <= 500
            && (match.path === "/offers/search") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={
            handleKeyPress
          }
          onChange={
            (event, newValue) => selectItem(event, newValue)

          }
          onSelect={
            (input) => runKeywordSearch(input.target.value)
          }
          options={
            window.innerWidth <= 500 ? [] : optionValues
          }
          renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
          )}
          renderInput={(params) => (
            <TextField
                {...params}
              InputProps={{
                sx: {
                  height: 44,
                  paddingRight: 0,
                  borderRadius: 0,
                  color: "grey.900",
                  overflow: "hidden",
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "primary.main",
                  },
                },
                startAdornment: <SearchOutlinedIcon fontSize="small" />,
              }}
              autoFocus={isModalSearch}
              placeholder={`Search Shops`}
            />
          )}
        />
        </Box>
      </Container>
      */}

      {
        renderSearchHelpMobile()
      }

      <Container>
        { match.path !== "/"
            && (match.path === "/offers/search/:query" ||
                match.path === "/offers/search/:query" ||
                match.path === "/offers" ||
                match.path === "/offers/sort/:sort" ||
                match.path === "/offers/location/:location" ||
                match.path === "/offers/market/:market") && (
                <>
                {/* {isMarketPlaceOnly() && sortState !== "nearby" &&
                      <UserShopLocationSlider
                          {...props}
                          isDirect={false}
                          isProduct={false}
                          setLocation={locationState}
                          headerTitle={"By Location: " + (type[sortState] || type['default'])}
                          handleSelectCategoryLink={handleLocationSelect}
                          handleSelectCategorySet={handleLocationSelect}/>
                  } */}

                  {isLoading ? <MockProductBox />
                      : offers.length > 0 ? renderOfferResults() : renderNoResults()
                  },
                  {isLoading ? <MockProductBox />
                      : otheroffers.length > 0 ? renderOtherOffersResults() : renderNoResults()
                  }

                </>
            )}
      </Container>
    </>
  );

  const renderShopEdit = (shopMode) => {
    switch (shopMode) {
      case "VIEW_OTHER":
        return (
            <UserSellerOtherOfferView
                setState={(value, field) => setShopState({
                  ...state,
                  selectedOffer: {
                    ...selectedOffer,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                history={history}
                isLoading={isLoading}
                offerdetail={offerdetail}
                selectedOffer={selectedOffer}
                onAddToFavlistClicked={addShopFavourite}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );
      case "VIEW":
      default:
        return (
            <UserSellerOfferView
                setState={(value, field) => setShopState({
                  ...state,
                  selectedOffer: {
                    ...selectedOffer,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                history={history}
                isLoading={isLoading}
                offerdetail={offerdetail}
                selectedOffer={selectedOffer}
                onAddToFavlistClicked={addShopFavourite}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedOffer = {} } = shopState;

  return (
      <Container>
        {shopModeState === "PREVIEW" ? (
            renderSearchModule()
        ) : (
            renderShopEdit(shopModeState)
        )}
      </Container>
  );
};

UserOfferSearch.defaultProps = {
  currency: "",
};

UserOfferSearch.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  citylocations: PropTypes.objectOf(PropTypes.any).isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  userMainGetShopInfoById: PropTypes.func.isRequired,
  userMainSearchShops: PropTypes.func.isRequired,
  userMainSearchShopKeywords: PropTypes.func.isRequired,
  userMainResetSearchShopKeywords: PropTypes.func.isRequired,
  userMainResetSearchShops: PropTypes.func.isRequired,
  userMainAddStoreToFavlist: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userOfferSearch = connect(
  ({ userMainOffers, userMainShops, bootupInfo }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
    citylocations: bootupInfo.citylocations,
    offers: userMainOffers.searchoffers,
    otheroffers: userMainOffers.otheroffers,
    searchkeywords: userMainOffers.searchkeywords,
    offerdetail: userMainOffers.offerdetail,
    shopdetail: userMainShops.shopDetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOfferSearch);

export { userOfferSearch as default };
