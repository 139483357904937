// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  activePlans: [],
  availablePlans: [],
  cartAddingPid: "",
  subscriptioncart: [],
  orderplaced: [],
  subscriptionorder: {
    plans: [],
    total_order: "",
    total_tax: "",
    total_service: "",
    total_price: "",
    status: "",
    paymentstatus: "",
    paymentmethod: "",
    payment_id: ""
  },
  subscriptionorders:[],
  subscriptionactiveorders:[],
  subscriptionCheckout: {
    cart: [],
    total_original_price: "",
    total_after_discount: "",
    total_discount: "",
    total_shipping: "",
    promo_discount: "",
  },
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_START:
    case ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_START:
    case ActionTypes.GET_SUBSCRIPTIONS_CART_START:
    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_START:
    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START:
    case ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_START:
    case ActionTypes.GENERATE_SUBSCRIPTION_ORDER_START:
    case ActionTypes.GET_SUBSCRIPTION_ORDERS_START:
    case ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_START:
    case ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_FAILURE:
    case ActionTypes.GET_SUBSCRIPTIONS_CART_FAILURE:
    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_FAILURE:
    case ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_FAILURE:
    case ActionTypes.GENERATE_SUBSCRIPTION_ORDER_FAILURE:
    case ActionTypes.GET_SUBSCRIPTION_ORDERS_FAILURE:
    case ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_FAILURE:
    case ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */
    case ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          availablePlans: action.payload.response.plans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          availablePlans: [],
        };
      }
    }

    case ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          activePlans: action.payload.response.activeplans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          activePlans: [],
        };
      }
    }

    case ActionTypes.GET_SUBSCRIPTIONS_CART_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptioncart: action.payload.response.cartitems,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptioncart: [],
        };
      }
    }

    case ActionTypes.ADD_SUBSCRIPTION_TO_CART_SUCCESS:
      if (action.payload.statusCode === 200) {
        if (state.subscriptioncart.findIndex(cartItem => cartItem.plan._id === action.planId) !== -1) {
          const subscriptioncart = state.subscriptioncart.reduce((cartAcc, cartItem) => {
            if (cartItem.plan._id === action.planId) {
              cartAcc.push({
                ...cartItem
              })
            } else {
              cartAcc.push(cartItem)
            }
            return cartAcc
          }, [])

          return {
            ...state,
            isLoading: false,
            reqStatus: action.type,
            serviceError: "",
            subscriptioncart
          }
        }

        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptioncart: [...state.subscriptioncart, {
            ...action.payload.response
          }]
        }
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }

    /*{
      const planToAdd = action.payload;
      const plans = [...state.availablePlans];
      const planIndex = plans.findIndex((plan) => plan._id === planToAdd._id);
      if (planIndex !== -1) plans[planIndex].addedToCart = true;
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        availablePlans: plans,
      };
    }

     */

    case ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptioncart: state.subscriptioncart.filter(cartItem => cartItem.plan._id !== action.planId)
        }
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }

    case ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          orderplaced: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          orderplaced: []
        };
      }

    case ActionTypes.GENERATE_SUBSCRIPTION_ORDER_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          orderplaced: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          orderplaced: [],
          subscriptioncart: []
        };
      }

    case ActionTypes.GET_SUBSCRIPTION_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptionorders: action.payload.response.subscriptionorders,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptionorders: [],
        };
      }
    }

    case ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptionactiveorders: action.payload.response.subscriptionorders,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          subscriptionactiveorders: [],
        };
      }
    }

    case ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_SUCCESS:
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          subscriptionorder: action.payload.response.order,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          subscriptionorder: []
        };
      }

    default:
      return state;
  }
}
