//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";

import * as Actions         from "actions/core/core.actions";
import * as ActionTypes     from 'constants/ActionTypes';
import {Box, Container} from "@mui/material";
import { H4 } from 'components/CustomTypography';
import {
  Pace
} from "components/Atoms";
import "./styles.scss";

import ProfileImageEditor   from "components/widgets/core/ProfileImageEditor";
import CustomSectionCreator from "components/CustomSectionCreator";
import ImageIcon from '@mui/icons-material/Image';
import {useState} from "react";
import {useEffect} from "react";
import PropTypes from "prop-types";

const ProfileImage = (props) => {

  const [state, setState] = useState({
    pictureMode: "PREVIEW",
  });

  const {
    isLoading,
    userDetails,
    updateUserPic,
    serviceError,
    reqStatus,
  } = props;

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);



  const handleImagesEdit = (mode) => (e) => {
    setState({ pictureMode: mode });
  };

  const handleImagesEditCancel = (mode) => (e) => {
    setState({ pictureMode: mode });
    props.history.push({
      pathname: '/me'
    });
  }

  const {image} = userDetails;
  const { pictureMode } = state;

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator icon={<ImageIcon />} title="Profile">
          {pictureMode === "PREVIEW" ? (
              <ProfileImageEditor
                  image={image}
                  updateUserPic={updateUserPic}
                  cancelEdit={handleImagesEditCancel("EDIT")}
              />
          ) : (
              <ProfileImageEditor
                  image={image}
                  cancelEdit={handleImagesEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
}

ProfileImage.defaultProps = {
  isLoading: false,
};

ProfileImage.propTypes = {
  isLoading: PropTypes.bool,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  updateUserPic: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const _ProfileImage = connect(
  ({ store, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProfileImage);

export { _ProfileImage as default };
