import * as React from "react";


import "./AdminOffer.scss";
import {Box, Card, IconButton} from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H4 } from "components/Typography";
import {getImageOrLogo, getLocalDate} from "helpers/utils";
import {DATE_FORMAT} from "config/constants";


const AdminOffer = (props) => {
  const { offer = {}, userDetails, editCategorySettings, className = "" } = props;
  const { id, name, images = [], is_popular, is_active, store_entry } = offer;

  return (

      <Box pb={2}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px">
          <LazyImage
              src={getImageOrLogo(offer.images, offer.logo)}
              width={150}
              height={150}
              alt={offer.name}
              mx="auto"
          />
        </HoverBox>
        <H4 fontSize={14} textAlign="left" title={offer.name} ellipsis>
            {offer.name}
        </H4>
          <H4 fontSize={12} textAlign="left" title={offer.name} ellipsis>
              {getLocalDate(offer.offer_created_at, DATE_FORMAT)}
          </H4>
          <Box display="flex">
              <H4 fontSize={12} textAlign="left">
                  {offer.is_offer_active === true ? "Active" : "Inactive"}
              </H4>
              <IconButton  className="setting-button" color="primary" size="small" onClick={editCategorySettings}>
                  <MoreHorizIcon />
              </IconButton>
          </Box>
      </Box>
  );
};

export default AdminOffer;
