/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import * as Actions from "actions";
import {Box, Container} from "@mui/material";
import { checkUserAllowed } from "helpers/utils.js";

import AdminProductSearch from "components/widgets/admin/AdminProductSearch";
import AdminShopSearch from "components/widgets/admin/AdminShopSearch";
import AdminOrderSearch from "components/widgets/admin/AdminOrderSearch";
import ButtonComponent from "../../../../Button";

const AdminMainHome = (props) => {

  const [state] = useState({
    layoutColumns: 3,
    storeid: "NA",
  });

    const {
        userDetails,
        adminResetSearchProducts, adminResetSearchShops, history
    } = props;

  useEffect(() => {
    adminResetSearchProducts();
    adminResetSearchShops();
  }, []);

  const refetch = () => {
      props.usergetProductCategories(props.bootupInfo.appState.subdomain_shopId,
            true, props.bootupInfo.appState.isWebsite);
  };


    const renderShopSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "shops")) {
            return <Container>
                <AdminShopSearch {...props} colSize={state.layoutColumns} />
            </Container>;
        } else {
            return  <div />;
        }
    }

    const renderProductSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "products")) {
            return <Container>
                <AdminProductSearch {...props} colSize={state.layoutColumns} />
            </Container>;
        } else {
            return  <div />;
        }
    }

    const renderOrderSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "orders")) {
            return <Container>
                <AdminOrderSearch {...props} colSize={state.layoutColumns} />
            </Container>;
        } else {
            return  <div />;
        }
    }

    const handleOffersButtonClick = (shop) => {
        history.push(`/offers`);
    };

    const renderOffers = () => {
        if (checkUserAllowed(userDetails.userlevel, "offers")) {
            return <Container>
                <Box mb={2}>
                    <ButtonComponent
                        type="primary"
                        variant="contained"
                        name="Manage Offers"
                        size="small"
                        onClick={() => handleOffersButtonClick()}
                    />
                </Box>
            </Container>;
        } else {
            return  <div />;
        }
    }

  return (
    <div>
      {/* SEO Support */}
      <Helmet>
        <title>Shopana</title>
        <meta name="description" content={props.bootupInfo.shopinfo.title} />
      </Helmet>
      {/* SEO Support End */}
        {
            renderShopSearch()
        }
        {
            renderProductSearch()
        }
        {
            renderOrderSearch()
        }
        {
            renderOffers()
        }
    </div>
  );
};

AdminMainHome.propTypes = {
    adminResetSearchProducts: PropTypes.func.isRequired,
    adminResetSearchShops: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const _AdminMainHome = connect(
  ({ adminProducts, bootupInfo }) => ({
    bootupInfo,
    userDetails: bootupInfo.userDetails,
    reqStatus: adminProducts.reqStatus,
    isLoading: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
    categoryList: adminProducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminMainHome);

export { _AdminMainHome as default };
