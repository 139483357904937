import * as React from "react";

import * as _ from "lodash";
import { Input, Button, Modal } from "components/Atoms";

import "./style.scss";
import {useEffect} from "react";

const CategoryBrowser = (props) => {

  const {
    isLoading,
    title,
    categoryList,
    storeId,
    visible,
    onOk,
    onCancel,
    onCategorySelect
  } = this.props;
  const { categories, count } = categoryList;
  const { filterText } = this.state;


  useEffect(() => {
    this.filterCategories = _.debounce(this.filterCategories, 1000);
  }, []);

  const filterCategories = (value) => {
    this.setState({ filterText: value.toLowerCase() });
  };


    return (
      <Modal
        title={title || "Browse By Category"}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        type="full-page"
        className="dialog-category-browser"
      >
        <Input.Search
          placeholder="Enter category name"
          onChange={(e) => this.filterCategories(e.target.value)}
          onSearch={this.filterCategories}
        />
        <div className="category-list">
          {categories.map((category, index) => {
            const { name, id } = category;
            if (filterText) {
              if (name.toLowerCase().indexOf(filterText) === -1) return null;
            }
            return (
              <span className="category-item" key={id}>
                <Button
                    onClick={() => this.props.onCategorySelect(category)}
                >
                  {name}
                </Button>
              </span>
            );
          })}
        </div>
      </Modal>
    );
}

export default CategoryBrowser;
