// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "api";

import * as Actions     from "actions/seller/seller.subscription";
import * as ActionTypes from 'constants/ActionTypes';


function* getAvailableSubscriptionPlans(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/subscription/subscription-plans`,
      action.payload
    );

    yield put(Actions.getAvailableSubscriptionPlansSuccess(response));
  } catch (error) {
    yield put(Actions.getAvailableSubscriptionPlansFailure(error));
  }
}

function* getSellerActiveSubscriptionPlans(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscription/active-subscription-plans`,
        action.payload
    );
    yield put(
        Actions.getSellerActiveSubscriptionPlansSuccess(response)
    );
  } catch (error) {
    yield put(Actions.getSellerActiveSubscriptionPlansFailure(error));
  }
}
function* getSubscriptionsCart(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/cart/get-plan-cart`,
        action.payload
    );

    yield put(Actions.getSubscriptionsCartSuccess(response));
  } catch (error) {
    yield put(Actions.getSubscriptionsCartFailure(error));
  }
}

function* addSubscriptionToCart(action: Object) {
  // TODO: Add to cart
  try {

    const response = yield API.post(
        `sellers/cart/add-plan-cart`,
        { "plan_id" : action.planId },
        { withCredentials: true }
    );

    yield put(Actions.addSubscriptionToCartSuccess(response, action.planId));
  } catch (error) {
    yield put(Actions.addSubscriptionToCartFailure(error));
  }
}

function* removeSubscriptionFromCart(action: Object) {
  // TODO: Add to cart
  try {

    const response = yield API.post(
        `sellers/cart/remove-plan-cart`,
        { "plan_id" : action.planId },
        { withCredentials: true }
    );

    yield put(Actions.removeSubscriptionFromCartSuccess(response, action.planId));
  } catch (error) {
    yield put(Actions.removeSubscriptionFromCartFailure(error));
  }
}

function* checkoutSubscriptionPlanOrder(action: Object) {
  try {

    const response = yield API.post(
        `sellers/checkout/checkout-cart-verify`,
        action.payload,
        { withCredentials: true }
    );

    yield put(Actions.checkoutSubscriptionPlanOrderSuccess(response));
  } catch (error) {
    yield put(Actions.checkoutSubscriptionPlanOrderFailure(error));
  }
}

function* generateSubscriptionPlanOrder(action: Object) {
  try {

    const response = yield API.post(
        `sellers/checkout/buy-cart-completed`,
        action.payload,
        { withCredentials: true }
    );
    yield put(Actions.generateSubscriptionPlanOrderSuccess(response));
  } catch (error) {
    yield put(Actions.generateSubscriptionPlanOrderFailure(error));
  }
}

function* getSubscriptionsOrders(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscription/get-orders`,
        action.payload
    );

    yield put(Actions.getSubscriptionsOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.getSubscriptionsOrdersFailure(error));
  }
}

function* getSubscriptionsActiveOrders(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscription/get-orders?paymentstatus=due`,
        action.payload
    );

    yield put(Actions.getSubscriptionsActiveOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.getSubscriptionsActiveOrdersFailure(error));
  }
}

function* getSubscriptionsOrderDetail(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/subscription/get-order/` + action.orderId
    );

    yield put(Actions.getSubscriptionsOrderDetailSuccess(response));
  } catch (error) {
    yield put(Actions.getSubscriptionsOrderDetailFailure(error));
  }
}

export default function* SubscriptionsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.GET_AVAILABLE_SUBSCRIPTIONS_START, getAvailableSubscriptionPlans);
  yield takeLatest(ActionTypes.GET_SELLER_ACTIVE_SUBSCRIPTIONS_START, getSellerActiveSubscriptionPlans);
  yield takeLatest(ActionTypes.GET_SUBSCRIPTIONS_CART_START, getSubscriptionsCart);
  yield takeLatest(ActionTypes.ADD_SUBSCRIPTION_TO_CART_START, addSubscriptionToCart);
  yield takeLatest(ActionTypes.REMOVE_SUBSCRIPTION_FROM_CART_START, removeSubscriptionFromCart);
  yield takeLatest(ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_START, checkoutSubscriptionPlanOrder);
  yield takeLatest(ActionTypes.GENERATE_SUBSCRIPTION_ORDER_START, generateSubscriptionPlanOrder);
  yield takeLatest(ActionTypes.GET_SUBSCRIPTION_ORDERS_START, getSubscriptionsOrders);
  yield takeLatest(ActionTypes.GET_SUBSCRIPTION_ACTIVE_ORDERS_START, getSubscriptionsActiveOrders);
  yield takeLatest(ActionTypes.GET_SUBSCRIPTION_ORDER_DETAIL_START, getSubscriptionsOrderDetail);
}
