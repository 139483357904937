import * as React from "react";
import {
    Avatar,
    Box, Card, Container, IconButton, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import TextFieldComponent from "components/TextField";
import {
    getButtonDataSendApproved,
    getButtonDataSendRejected,
    getButtonDataSendDeleted,
    getButtonDataSendUnlockURL,
    getButtonDataSendEnable,
    getButtonDataSendDisable,
    getButtonDataCancel,
    getTextfieldDataPin,
    getButtonDataSendVerified,
    getButtonDataSendDisplayReq,
    getButtonDataSendDisplayPrinted,
    getButtonDataSendDisplayDelivered,
    getButtonDataSendMsg,
    getTextfieldDataMessage,
    getButtonDataSendInternalMsg,
    getTextfieldDataInternalMessage,
    schema,
    getTextfieldUpdateDomain,
    getButtonUpdateDomain,
    getTextfieldUpdateSocial,
    getButtonUpdateSocial,
} from "./helper";
import {
    checkUserAllowed,
    getLocalDate,
    isShopSiteAndSubdomain,
    openGoogleMapPlaceId,
    openGoogleMapLocation,
    getLogo, getImage, openInstagram, searchInstagram, openFacebook, searchGoogle, searchGoogleForInstagram
} from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import {H2, H5, H6} from "components/CustomTypography";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import {handleExternalPage} from "services/utils";
import {DATE_FORMAT_SHORT, DATE_TIME_FORMAT_SHORT} from "config/constants";
import SelectFieldComponent from "components/SelectField";
import ImageAlbumAdmin from "components/elements/main/ImageAlbumAdmin";
import AdminSellerShopsList from "components/elements/admin/shops/AdminSellerShopsList";
import CustomSectionCreator from "components/CustomSectionCreator";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InstagramIcon from '@mui/icons-material/Instagram';
import SearchIcon from '@mui/icons-material/Search';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AdminDisplayDeliveredMain from "components/pages/admin/shops/AdminDisplayDeliveredMain";


const AdminSellerShopEditor = ({
  setState, selectedShop, symbol,
                                   approvedrejectShopClicked, disableShopClicked, enableShopClicked, updateDomainClicked, updateSocialClicked,
                                   verifiedShopClicked, unlockURLShopClicked,
                                   displayReqClicked, displayDeliveredClicked, displayPrintedClicked, handleSalesLeadClicked,
                                   handleShopInternalMessageClicked, handleShopMessageClicked,
                                   handleSalesLeadSelected, salesLeadOptions, shopMatchingList,
                                   onCancel, shopdetail, isLoading, userDetails,
}) => {

  const {
     created_at: createdAt, shop_id: shopIdValue, status, pin, message, internalmessage, subdomain,
      instahandle, facebookhandle, whatsapphandle
  } = selectedShop || {};

    const {
        products, saleslead, internalmessages, messages
    } = shopdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinishApproved = () => {
      approvedrejectShopClicked({ shop_id: shopIdValue, status: "approve" });
  };

    const onFinishRejected = () => {
        approvedrejectShopClicked({ shop_id: shopIdValue, status: "reject" });
    };

    const onFinishDeleted = () => {
        approvedrejectShopClicked({ shop_id: shopIdValue, status: "delete" });
    };

    const onFinishUpdateDomain = () => {
        updateDomainClicked({ shop_id: shopIdValue, subdomain: subdomain });
    };

    const onFinishUpdateSocial = () => {
        updateSocialClicked({ shop_id: shopIdValue, instahandle: instahandle, facebookhandle:  facebookhandle, whatsapphandle:  whatsapphandle });
    };

    const onFinishSendInternalMsg = () => {
        handleShopInternalMessageClicked( shopIdValue, message );
    };

    const onFinishSendMsg = () => {
        handleShopMessageClicked( shopIdValue, message );
    };

    const onFinishUnlocked = () => {
        unlockURLShopClicked({ shop_id: shopIdValue });
    };

    const onFinishEnable = () => {
        enableShopClicked({ shop_id: shopIdValue });
    };

    const onFinishDisable = () => {
        disableShopClicked({ shop_id: shopIdValue });
    };

    const onFinishVerified = () => {
        verifiedShopClicked(shopIdValue, pin);
    };

    const onFinishDisplayReq = () => {
        displayReqClicked({ shop_id: shopIdValue });
    };

    const onFinishDisplayDelivered = (formData) => {
        displayDeliveredClicked(shopIdValue, formData);
    };

    const onFinishDisplayPrinted = () => {
        displayPrintedClicked({ shop_id: shopIdValue });
    };

    const onFinishSalesLead = (event) => {
        handleSalesLeadClicked(shopIdValue, event.target.value);
    };

    const onSelectingSalesLead = (event) => {
        handleSalesLeadSelected(event.target.value);
    };

    const hasError = () => {
    try {
      return !schema.validateSync(selectedShop);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedShop);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };


    const url = (shopdetail.domain && shopdetail.subdomain) ? "https://" + shopdetail.subdomain + "." + shopdetail.domain : "";

    const renderShopInformation = () => (
        <>
            <Box mt={2}>
                <H2 textAlign="left" title={shopdetail.title} ellipsis>
                    {shopdetail.title}
                </H2>
                <H5>
                    {shopdetail.addressline}
                </H5>
                <H5>
                    {shopdetail.areaname} {shopdetail.cityname}
                </H5>
                <H5>
                    {shopdetail.area} {shopdetail.city}
                </H5>
                <H5>
                    {shopdetail.phone}
                </H5>
                <H5>
                    <Box width="100%">
                        <Box>{!isLoading && <a href="#" onClick={handleExternalPage(url)}>{`${url || ""}`}</a>}</Box>
                        <Box width="100%" display="flex">
                            <Box width="50%" >
                                {!isLoading && (<div>
                                    <ButtonComponent
                                        sx={{
                                            mt: 2,
                                            mb: 2,
                                        }}
                                        type="primary"
                                        variant="contained"
                                        name="Visit Online Shop"
                                        size="small"
                                        onClick={handleExternalPage(url)}
                                    />
                                </div>)
                                }
                            </Box>
                            <Box width="50%" >
                                {((!isLoading && shopdetail.is_domain_locked) && (checkUserAllowed(userDetails.userlevel, "unlockurl"))) &&
                                    getButtonDataSendUnlockURL(onFinishUnlocked).map((element) => (
                                        <ButtonComponent
                                            sx={{
                                                mt: 2,
                                                mb: 2,
                                            }}
                                            type={element.type}
                                            variant={element.variant}
                                            onClick={element.onClick}
                                            disabled={element.disabled}
                                            name={element.name}
                                            size="small"
                                        />
                                    ))
                                }
                            </Box>
                        </Box>
                    </Box>
                </H5>

            </Box>
            <Box mt={2} mb={2}>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Contact Name:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.user && shopdetail.user.name }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Phone/Email:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.user && (shopdetail.user.mobile + " " + shopdetail.user.email) }
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Added on:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {getLocalDate(shopdetail.created_at, DATE_TIME_FORMAT_SHORT)}
                        </H5>
                    </Box>
                </Box>
            </Box>
        </>
    );

    const renderShopDetail = () => (
        <Box display={window.innerWidth < 400 ? "" : "flex"}>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Verified:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_verified ? "Yes" : "No"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Verified by:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_verified ? shopdetail.sales_user && shopdetail.sales_user.name : "NA"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Domain:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_domain_locked ? "Ready" : "Not Ready"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Delivery:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.shippingoption}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Payment:
                        </H5>
                    </Box>
                    <Box width="70%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_payment_setup ? "Ready" : "Not Ready"}
                        </H5>
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.paymentoption} {shopdetail.paymentupi}
                        </H5>
                    </Box>
                </Box>
                <Box mb={2}>
                    {((!isLoading && !shopdetail.is_verified) && (checkUserAllowed(userDetails.userlevel, "verifyshop"))) &&
                        getTextfieldDataPin(pin).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        ))
                    }

                    {!isLoading && !shopdetail.is_verified && (checkUserAllowed(userDetails.userlevel, "verifyshop")) &&
                    (<SelectFieldComponent
                    label="Sales Lead for verification"
                    options={salesLeadOptions}
                    onChange={(event) => onSelectingSalesLead(event)}
                />)}

                    {((!isLoading && !shopdetail.is_verified) && (checkUserAllowed(userDetails.userlevel, "verifyshop"))) &&
                        getButtonDataSendVerified(onFinishVerified).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                </Box>
            </Box>
        </Box>
    );

    const renderShopPhotos = () => (
        <Box mb={4}>
            <Box>
                <CustomSectionCreator isSubtle={true} title="Shop Profile"  />
                <ImageAlbumAdmin showLink={checkUserAllowed(userDetails.userlevel, "approveshop")} images={shopdetail.logo} />
            </Box>
            <Box>
                <CustomSectionCreator isSubtle={true} title="Shop Photos"  />
                <ImageAlbumAdmin showLink={checkUserAllowed(userDetails.userlevel, "approveshop")} images={shopdetail.images} />
            </Box>
        </Box>
    );

    const renderSalesLeadPhotos = () => (
        <Box mb={4}>
            <CustomSectionCreator isSubtle={true} title="Sales Lead Photos"  />
            <ImageAlbumAdmin showLink={checkUserAllowed(userDetails.userlevel, "approveshop")} images={saleslead && saleslead.images} />
        </Box>
    );

    const renderShopQRPhoto = () => (
        <Box mb={4}>
            <CustomSectionCreator isSubtle={true} title="Payment QR Photo"  />
            <Avatar
            variant="square"
            sx={{ height: '200px', width: '200px', marginTop: '10px' }}
            src={shopdetail.paymentqrcode && shopdetail.paymentqrcode.length > 0 && shopdetail.paymentqrcode[0].cdn.secure_url} />
        </Box>
    );

    const renderSalesLeadInformation = () => (
        <Box>
            <Box mb={2} width="100%">
                <CustomSectionCreator isSubtle={true} title="Sales Lead"  />
                {
                    saleslead &&
                    (
                        <Box>
                            <Box width="100%" display="flex">
                                <Box width="30%" >
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        Shop Name:
                                    </H5>
                                </Box>
                                <Box width="70%">
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        {saleslead.shopname}
                                    </H5>
                                </Box>
                            </Box>
                            <Box width="100%" display="flex">
                                <Box width="30%" >
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        Owner/Phone:
                                    </H5>
                                </Box>
                                <Box width="70%">
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        {saleslead.name} / {saleslead.phone}
                                    </H5>
                                </Box>
                            </Box>
                            <Box width="100%" display="flex">
                                <Box width="30%" >
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        Added by:
                                    </H5>
                                </Box>
                                <Box width="70%">
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        {saleslead.owner && saleslead.owner.name}
                                    </H5>
                                </Box>
                            </Box>
                            <Box width="100%" display="flex">
                                <Box width="30%" >
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        Lead added on:
                                    </H5>
                                </Box>
                                <Box width="70%">
                                    <H5 fontSize={14} textAlign="left" ellipsis>
                                        {getLocalDate(saleslead.created_at, DATE_TIME_FORMAT_SHORT)}
                                    </H5>
                                </Box>
                            </Box>
                        </Box>
                    )
                }
            </Box>
            <Box mb={2} width="100%">
                <CustomSectionCreator isSubtle={true} title="Signup/Review Information:"  />
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Active:
                        </H5>
                    </Box>
                    <Box width="70%">
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_active ? "Yes" : "No"}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Status:
                        </H5>
                    </Box>
                    <Box width="70%">
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.status}
                        </H5>
                    </Box>
                </Box>
                <Box width="100%" display="flex">
                    <Box width="30%" >
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            Deleted:
                        </H5>
                    </Box>
                    <Box width="70%">
                        <H5 fontSize={14} textAlign="left" ellipsis>
                            {shopdetail.is_deleted ? "Yes" : "No"}
                        </H5>
                    </Box>
                </Box>
                <Box mt={2} width="100%" display="flex">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Review Log:
                    </H5>
                </Box>
                {internalmessages && internalmessages.map((messageItem, index) => (
                    <Box width="100%" display="flex">
                        <Box width="30%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {getLocalDate(messageItem.added_at, DATE_FORMAT_SHORT)} ({messageItem.added_by && messageItem.added_by.name})
                            </H5>
                        </Box>
                        <Box width="70%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {messageItem.message}
                            </H5>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );

    const renderShopSocialMediaSearch = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Social Media:"  />
            <Box width="100%" alignItems="center" display="flex">

                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Instagram:</H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.instahandle && <IconButton
                            size="large"
                            onClick={() => openInstagram(shopdetail.instahandle)}
                        >
                            <InstagramIcon sx={{width: 40, height: 40}} color="primary"/>
                        </IconButton>}

                        {<IconButton
                            size="large"
                            onClick={() => searchGoogleForInstagram(shopdetail.title + " " + shopdetail.city)}
                        >
                            <ManageSearchIcon sx={{ width: 40, height: 40 }} color="primary"/>
                        </IconButton>}

                        {shopdetail.user && shopdetail.user.mobile && <IconButton
                            size="small"
                            onClick={() => searchGoogleForInstagram(shopdetail.user.mobile.slice(shopdetail.user.countrycode ? shopdetail.user.countrycode.length : 2))}
                        >
                            <PersonSearchIcon sx={{ width: 40, height: 40 }} color="primary"/>
                        </IconButton>}

                    </H5>
                    {shopdetail.instahandle}
                </Box>
            </Box>
        </Box>
    );

    const renderShopSocialMediaUpdate = () => (
        <Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Facebook:</H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.facebookhandle && <IconButton
                            size="small"
                            onClick={() => openFacebook(shopdetail.facebookhandle)}
                        >
                            <ArrowForwardIcon color="primary"/>
                        </IconButton>}
                    </H5>
                    {shopdetail.facebookhandle}
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Update Facebook:
                    </H5>
                </Box>
                <Box width="70%">
                    {
                        getTextfieldUpdateSocial(instahandle, facebookhandle, whatsapphandle).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        ))
                    },
                    {getButtonUpdateSocial(onFinishUpdateSocial).map((element) => (
                        <ButtonComponent
                            type={element.type}
                            variant={element.variant}
                            onClick={element.onClick}
                            disabled={element.disabled}
                            name={element.name}
                            size="small"
                        />
                    ))
                    }
                </Box>
            </Box>
        </Box>
    );

    const renderShopDomainUpdate = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Update domain:"  />
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Update domain:
                    </H5>
                </Box>
                <Box width="70%">
                    {
                        getTextfieldUpdateDomain(subdomain).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        ))
                    },
                    {getButtonUpdateDomain(onFinishUpdateDomain).map((element) => (
                        <ButtonComponent
                            type={element.type}
                            variant={element.variant}
                            onClick={element.onClick}
                            disabled={element.disabled}
                            name={element.name}
                            size="small"
                        />
                    ))
                    }
                </Box>
            </Box>
        </Box>
    );

    const renderShopInternalLog = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Internal Log:"  />
            <Box mb={2}>
                {
                    getTextfieldDataInternalMessage(internalmessage).map((element) => (
                        <Box className="textfield" m={2}>
                            <TextFieldComponent
                                required
                                label={element.label}
                                value={element.value}
                                error={!!getError(element.onChangeField)}
                                helperText={getError(element.onChangeField)}
                                onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))
                },
                {getButtonDataSendInternalMsg(onFinishSendInternalMsg).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
                }
            </Box>
        </Box>
    );
    const renderShopSendMessages = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Message to Shop owner:"  />
            <Box mb={2}>
                {
                    getTextfieldDataMessage(message).map((element) => (
                        <Box className="textfield" m={2}>
                            <TextFieldComponent
                                required
                                label={element.label}
                                value={element.value}
                                error={!!getError(element.onChangeField)}
                                helperText={getError(element.onChangeField)}
                                onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))
                },
                {getButtonDataSendMsg(onFinishSendMsg).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
                }
            </Box>
        </Box>
            );

    const renderShopSentMessages = () => (
        <Box>
            <Box mb={2} width="100%">
                {messages && messages.map((messageItem, index) => (
                    <Box width="100%" display="flex">
                        <Box width="30%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {getLocalDate(messageItem.added_at, DATE_FORMAT_SHORT)} ({messageItem.added_by && messageItem.added_by.name})
                            </H5>
                        </Box>
                        <Box width="70%">
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {messageItem.message}
                            </H5>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );

    const renderSalesLeadDetail = () => (
        <Box>
            <CustomSectionCreator isSubtle={true} title="Admin Information:"  />
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Modify:
                    </H5>
                </Box>
                <Box width="70%">
                    <SelectFieldComponent
                    label="Sales Lead"
                    sx={{fontSize:'10px'}}
                    options={salesLeadOptions}
                    onChange={(event) => onFinishSalesLead(event)} />
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Signup:</H5>
                </Box>
                <Box width="70%">
                    {
                        shopdetail.shoplocation &&
                        (
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {shopdetail.shoplocation && <IconButton
                                    size="small"
                                    onClick={() => openGoogleMapLocation(shopdetail.shoplocation)}
                                >
                                    <ArrowForwardIcon color="primary"/>
                                </IconButton>}
                            </H5>)
                    }
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Sales Lead:</H5>
                </Box>
                <Box width="70%">
                    {
                        saleslead &&
                        (
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {saleslead.location && <IconButton
                                    size="small"
                                    onClick={() => openGoogleMapLocation(saleslead.location)}
                                >
                                    <ArrowForwardIcon color="primary"/>
                                </IconButton>}
                            </H5>)
                    }
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Verified:</H5>
                </Box>
                <Box width="70%">
                    {
                        shopdetail &&
                        (
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {shopdetail.location && <IconButton
                                    size="small"
                                    onClick={() => openGoogleMapLocation(shopdetail.location)}
                                >
                                    <ArrowForwardIcon color="primary"/>
                                </IconButton>}
                            </H5>)
                    }
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Place ID:</H5>
                </Box>
                <Box width="70%">
                    {
                        saleslead &&
                        (
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {saleslead.place_id && <IconButton
                                    size="small"
                                    onClick={() => openGoogleMapPlaceId(saleslead.place_id, saleslead.location)}
                                >
                                    <ArrowForwardIcon color="primary"/>
                                </IconButton>}
                            </H5>)
                    }
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Shop ID:</H5>
                </Box>
                <Box width="70%">
                    {shopdetail.id}
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Shop signup IP:</H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.created_ip}
                    </H5>
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Shop user create IP:</H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.user && shopdetail.user.created_ip }
                    </H5>
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Shop user last IP:</H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.user && shopdetail.user.last_login_ip }
                    </H5>
                </Box>
            </Box>
            <Box width="100%" alignItems="center" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>Saleslead  IP:</H5>
                </Box>
                <Box width="70%">
                    {
                        saleslead &&
                        (
                            <H5 fontSize={14} textAlign="left" ellipsis>
                                {saleslead.created_ip }
                            </H5>)
                    }
                </Box>
            </Box>
        </Box>
    );


    const renderShopDisplayActions = () => (
        <>
            <CustomSectionCreator isSubtle={true} title="Request Display/Standee:"  />
            <Box width="100%" display="flex">
                <Box width="30%" >
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Display Status:
                    </H5>
                </Box>
                <Box width="70%">
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        {shopdetail.display_status}
                    </H5>
                </Box>
            </Box>

            {((shopdetail.display_status === "delivered" || shopdetail.display_status === "deliveryconfirmed") && (checkUserAllowed(userDetails.userlevel, "displayreq"))) &&
                getButtonDataSendDisplayReq(onFinishDisplayReq).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
            }

            {((shopdetail.display_status === "inprint") && (checkUserAllowed(userDetails.userlevel, "displayprinted"))) &&
                getButtonDataSendDisplayPrinted(onFinishDisplayPrinted).map((element) => (
                    <ButtonComponent
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                        size="small"
                    />
                ))
            }
        </>
    );

    const renderShopDisplayInformation = () => (
        <>
            <CustomSectionCreator isSubtle={true} title="Display/Standee"  />
            <Avatar
                variant="square"
                sx={{ height: '200px', width: '200px', marginTop: '10px' }}
                src={shopdetail.urlqrcode && shopdetail.urlqrcode.length > 0 && shopdetail.urlqrcode[0].cdn.secure_url} />
        </>
    );

    return (
        <Container>
            <Container maxWidth="md">
                <Box mb={2}>
                    <ButtonComponent
                        type="primary"
                        variant="outlined"
                        name="< Back"
                        size="small"
                        onClick={onCancel}
                    />
                </Box>
                {isLoading && <Box m={10}><MockGeneralHeader  /></Box>}
                {!isLoading && renderShopInformation()}
                {!isLoading && renderShopDetail()}
                <Box mb={2}>
                    <Box width="100%" display="flex">
                        <Box width="40%">
                    {((!isLoading && (shopdetail.status === "new" || shopdetail.status === "rejected")) && (checkUserAllowed(userDetails.userlevel, "approveshop"))) &&
                        getButtonDataSendApproved(onFinishApproved).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }

                        </Box>
                        <Box width="30%">
                    {((!isLoading && (shopdetail.status === "new" || shopdetail.status === "approved")) && (checkUserAllowed(userDetails.userlevel, "rejectshop"))) &&
                        getButtonDataSendRejected(onFinishRejected).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                        </Box>
                        <Box width="30%">
                            {((!isLoading && (shopdetail.status === "rejected")) && (checkUserAllowed(userDetails.userlevel, "deleteshop"))) &&
                                getButtonDataSendDeleted(onFinishDeleted).map((element) => (
                                    <ButtonComponent
                                        type={element.type}
                                        variant={element.variant}
                                        onClick={element.onClick}
                                        disabled={element.disabled}
                                        name={element.name}
                                        size="small"
                                    />
                                ))
                            }
                        </Box>
                    </Box>
                </Box>
                <Box mb={2}>
                    {((!isLoading && (shopdetail.status === "approved" && shopdetail.is_active)) && (checkUserAllowed(userDetails.userlevel, "disableshop"))) &&
                        getButtonDataSendDisable(onFinishDisable).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                    {((!isLoading && (shopdetail.status === "approved" && !shopdetail.is_active)) && (checkUserAllowed(userDetails.userlevel, "enableshop"))) &&
                        getButtonDataSendEnable(onFinishEnable).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                                size="small"
                            />
                        ))
                    }
                </Box>
                <Box mb={2}>
                    {!isLoading && shopdetail.is_verified && renderSalesLeadInformation()}
                    <AdminSellerShopsList selectedShop={selectedShop} />
                    {(checkUserAllowed(userDetails.userlevel, "updatedomain")) && renderShopDomainUpdate()}
                    {(checkUserAllowed(userDetails.userlevel, "searchsocial")) && renderShopSocialMediaSearch()}
                    {(checkUserAllowed(userDetails.userlevel, "updatesocial")) && renderShopSocialMediaUpdate()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderSalesLeadDetail()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopInternalLog()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSendMessages()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopSentMessages()}
                    {(checkUserAllowed(userDetails.userlevel, "approveshop")) && renderShopPhotos()}
                    {renderSalesLeadPhotos()}
                    {renderShopQRPhoto()}
                    {!isLoading && shopdetail.is_verified && renderShopDisplayActions()}
                    {!isLoading && shopdetail.is_verified && renderShopDisplayInformation()}

                </Box>
            </Container>
        </Container>
    );
};

export default AdminSellerShopEditor;
