//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import {
  Notification,
    Pace
} from "components/Atoms";

import * as Actions       from "actions/seller/seller.store";

import "./styles.scss";

import ShopImageEditor from "components/widgets/seller/ShopImageEditor";
import { Container } from "@mui/material";
import CustomSectionCreator from "components/CustomSectionCreator";
import ImageIcon from '@mui/icons-material/Image';
import {useState} from "react";
// Component Imports


const StoreProfileImages = (props) =>  {

  const [state, setState] = useState({ pictureMode: "PREVIEW" });

  const {
    isLoading, reqStatus, serviceError,
    getStoreImages, history,
    storeImages,
    addStoreImage,
    removeStoreImage,
  } = props;


  React.useEffect(() => {
    getStoreImages();
  }, []);



  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  React.useEffect(() => {
    switch (reqStatus) {
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          // sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);

  const handleImagesEdit = (mode) => (e) => {
    setState({ pictureMode: mode });
  };

  const handleImagesEditCancel = (mode) => (e) => {
    setState({ pictureMode: mode });
    history.push({
      pathname: '/shop'
    });
  }

    const { pictureMode } = state;

    return (
        <Container>
          <CustomSectionCreator isSubtle={true} icon={<ImageIcon />} title="Shop Photos">
            {pictureMode === "PREVIEW" ? (
                <ShopImageEditor
                    storeImages={storeImages}
                    addStoreImage={addStoreImage}
                    removeStoreImage={removeStoreImage}
                    cancelEdit={handleImagesEditCancel("EDIT")}
                />
            ) : (
                <ShopImageEditor
                    storeImages={storeImages}
                    cancelEdit={handleImagesEditCancel("PREVIEW")}
                />
            )}
          </CustomSectionCreator>
        </Container>
    );
}
// Mapping Props and State.
const _StoreProfileImages = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeImages: store.storeImages,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileImages);

export { _StoreProfileImages as default };
