// @flow
import * as React from "react";


import "./styles.scss";
import Topbar from "components/topbar/Topbar";

const ErrorBoundary = (props) => {
  const { errorInfo } = props;
  return (
      <section className="p-0">
          <Topbar bgColor="#eee" titleBanner={`${process.env.REACT_APP_BANNER}`} isFixed={true} />
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      <div className="error-section">
                          <h2>Not able to process</h2>
                          <a href="/" className="btn btn-solid">Go Back</a>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      <div className="error-section">
                        <a href="/" className="btn btn-solid">{process.env.REACT_APP_NAME} Home</a>
                      </div>
                  </div>
              </div>
          </div>

      </section>
  );
};

export { ErrorBoundary as default };
