//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions       from "actions/seller/seller.store";

import {Box, Card, Container} from "@mui/material";
import { H4 } from 'components/CustomTypography';
import {
  Notification,
  Pace,
} from "components/Atoms";

import "./styles.scss";

import PaymentQRCodeEditor   from "components/widgets/seller/PaymentQRCodeEditor";
import CustomSectionCreator from "components/CustomSectionCreator";
import ImageIcon from '@mui/icons-material/Image';
import {useState} from "react";

// Component Imports


const StorePaymentQRCode = (props) => {

  const [state, setState] = useState({ pictureMode: "PREVIEW" });

  const {
    isLoading, reqStatus, serviceError,
    getStorePayment, history,
    storePayment,
    updateStorePaymentQRCode,
  } = props;

  const {paymentqrcode} = storePayment;

  React.useEffect(() => {
    getStorePayment();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  React.useEffect(() => {
    switch (reqStatus) {
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          // sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);


  const handleImagesEdit = (mode) => (e) => {
    setState({ pictureMode: mode });
  };

  const handleImagesEditCancel = (mode) => (e) => {
    setState({ pictureMode: mode });
    history.push({
      pathname: '/shop/payment'
    });
  }


    const { pictureMode } = state;

    return (
        <Container>
          {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
          <CustomSectionCreator isSubtle={true} icon={<ImageIcon />} title="Payment QRCode">
            {pictureMode === "PREVIEW" ? (
                <PaymentQRCodeEditor
                    image={paymentqrcode}
                    updateStorePaymentQRCode={updateStorePaymentQRCode}
                    cancelEdit={handleImagesEditCancel("EDIT")}
                />
            ) : (
                <PaymentQRCodeEditor
                    image={paymentqrcode}
                    updateStorePaymentQRCode={updateStorePaymentQRCode}
                    cancelEdit={handleImagesEditCancel("PREVIEW")}
                />
            )}
          </CustomSectionCreator>
        </Container>
    );
}
// Mapping Props and State.
const storePaymentQRCode = connect(
  ({ store }) => ({
    store,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storePayment: store.storePayment,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StorePaymentQRCode);

export { storePaymentQRCode as default };
