import * as React from "react";

export const SellerSideBarConfig = [
  {
    id: "home",
    name: "Home",
    header: `${process.env.REACT_APP_NAME}`,
    icon: "icon-home",
    route: "/",
  },
  {
    id: "shop",
    name: "Shop",
    header: "Shop",
    icon: "icon-website",
    route: "/shop",
    subMenu: [
      {
        id: "shop_profile",
        name: "Shop Profile",
        header: "Shop Profile",
        route: "/shop",
      },
      {
        id: "store_claim",
        name: "Shop claim",
        header: "Shop Claim",
        route: "/register-store",
      },
    ],
  },
  {
    id: "orders",
    name: "Orders",
    header: "Orders",
    icon: "icon-order",
    route: "/orders/all",
    subMenu: [
      {
        id: "new_orders",
        name: "New Orders",
        header: "New Orders",
        route: "/orders/new",
      },
      {
        id: "open_orders",
        name: "Open Orders",
        header: "Open Orders",
        route: "/orders/open",
      },
      {
        id: "readypickup_orders",
        name: "Orders Ready",
        header: "Orders Ready",
        route: "/orders/readypickup",
      },
      {
        id: "all_orders",
        name: "All Orders",
        header: "Orders",
        route: "/orders/all",
      },
      {
        id: "search_orders",
        name: "Search Orders",
        header: "Search Orders",
        route: "/orders/search",
      },
    ],
  },
  {
    id: "products",
    name: `${process.env.REACT_APP_PRODUCT_TITLE}`,
    header: `${process.env.REACT_APP_PRODUCT_TITLE}`,
    icon: "icon-product",
    route: "/products/private",
    subMenu: [
      {
        id: "private_product",
        name: "Products",
        header: "Products",
        route: "/products/private",
      },
      {
        id: "my_categories",
        name: "Categories",
        header: "Categories",
        route: "/products/categories",
      },
      {
        id: "my_products",
        name: "All Products in shop",
        header: "Products in shop",
        route: "/products/all",
      },
      {
        id: "add_product",
        name: "Scan to add common product",
        header: "Branded Products",
        route: "/products/scan",
      },
      {
        id: "search_product",
        name: "Search Common Products",
        header: "Branded Products",
        route: "/products/search",
      },
    ],
  },
  {
    id: "account",
    name: "Account",
    header: "Account",
    icon: "icon-home",
    route: "/me",
  },
];
