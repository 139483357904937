import * as React from "react";
import {
  Box, Container, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import PropTypes from "prop-types";
import {
  schema,
  getButtonData,
  getTextfieldData
} from "./helper";
import {checkUserAllowed, getLocalDate} from "helpers/utils";
import {H2, H4, H5} from "components/CustomTypography";
import TextFieldComponent from "components/TextField";
import {DATE_FORMAT} from "config/constants";

const AdminOfferAdd = ({
  setState, adminAddOfferClicked, shop_id, cancelEdit, isLoading, userDetails, history, selectedOffer,
}) => {


  const {
    name, description
  } = selectedOffer;


  const [blur, setblur] = React.useState({
    name: false,
    description: false,
  });


  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOffer);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.description );

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOffer);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };


  const onFinishAdd = () => {
    adminAddOfferClicked({
      shop_id, name, description,
    });
  };

  return (
      <Container>
        <Box mb={2}>
          {getTextfieldData(name, description).map((element) => (
              <Box className="textfield" m={2}>
                <TextFieldComponent
                    required
                    label={element.label}
                    value={element.value}
                    error={!!getError(element.onChangeField)}
                    helperText={getError(element.onChangeField)}
                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                />
              </Box>
          ))}

          <Typography align="center">
            {getButtonData(onFinishAdd, cancelEdit, hasError, isTouched).map((element) => (
                <ButtonComponent
                    sx={{
                      m: 2,
                    }}
                    type={element.type}
                    variant={element.variant}
                    onClick={element.onClick}
                    disabled={element.disabled}
                    name={element.name}
                />
            ))}
          </Typography>

        </Box>
      </Container>
  );
};

AdminOfferAdd.propTypes = {
  setState: PropTypes.func.isRequired,
  offer: PropTypes.object.isRequired,
  adminAddOfferClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AdminOfferAdd;
