//  @flow
import * as React from "react";
import {
  Page,
} from "components/Atoms";

import PrivateCategoryMain    from "components/pages/seller/products/PrivateCategoryMain";

import "./styles.scss";

const PrivateCategories = (props) => {

  return (
        <Page className="products-home">
          <PrivateCategoryMain></PrivateCategoryMain>
        </Page>
    );
}

export default (PrivateCategories)
