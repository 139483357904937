// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  AutoComplete,
  Button,
  Notification,
} from "components/Atoms";
import { H6 } from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import "./style.scss";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import AdminSalesLead from "components/elements/admin/sales/AdminSalesLead";
import AdminSalesLeadEditor from "components/elements/admin/sales/AdminSalesLeadEditor";
import AdminSalesLeadSettingEditor from "components/elements/admin/sales/AdminSalesLeadSettingEditor";
import AdminSalesImageMain from "components/pages/admin/sales/AdminSalesImageMain";
import AdminSalesLeadSelector from "components/elements/admin/sales/AdminSalesLeadSelector";

import MockProductBoxList from "components/elements/main/MockProductBoxList";
import { FlexBox } from "components/flex-box";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, Grid, IconButton, MenuItem} from "@mui/material";
import {useHistory} from "react-router";
import DatePicker from "react-datepicker";
import ButtonComponent from "components/Button";
import {
  checkUserAllowed,
  getDistance,
  getGeoLocation,
  getSalesLeadFilterList,
  getSalesLeadStatusList
} from "helpers/utils";
import AdminSalesQuickAddMain from "components/pages/admin/sales/AdminSalesQuickAddMain";
import SelectFieldComponent from "components/SelectField";
import GoogleMap from "components/GoogleMap";
import Marker from "components/Marker";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const { Search } = Input;
const { Option } = AutoComplete;

const AdminSalesMain = (props) => {
  const [state, setState] = useState({ categoryMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: props.categoryInput });
  const [statusState, setStatusState] = useState("");
  const [filterState, setFilterState] = useState("");
  const [startdateState, setStartdateState] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [executiveState, setExecutiveState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [salesSearchTypeState, setSalesSearchTypeState] = useState("");
  const [offsetState, setOffsetState] = useState(0);

  const [mapref, setMapRef] = useState(null);

  const {
    serviceError, reqStatus, isLoading, searchleads, bootupInfo, userDetails,
    adminSearchSalesLead, adminAddSalesLead, adminUpdateSalesLead, adminUpdateCommonCategorySetting,
    adminUpdateSalesLeadPlaceId, adminSearchSalesFuture, searchfutures,
    adminGetSalesExecutives, execList,
    leadtype,
  } = props;

  useEffect(() => {
    setSalesSearchTypeState(leadtype);
    adminSearchSalesLead(leadtype, statusState, startdateState, locationState, filterState, executiveState, "", offsetState);
    adminGetSalesExecutives();

  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_SUCCESS:
        setState({ categoryMode: "PREVIEW" });
      case ActionTypes.ADMIN_ADD_SALES_LEAD_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_SALES_LEAD_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_SUCCESS:
      case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_SUCCESS:
      case ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_SUCCESS:
        adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, offsetState);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateCategoryToAccountCall = (values) => {
    if (!values.id) {
      adminAddSalesLead(values);
    } else {
      adminUpdateSalesLead(values);
    }
    setState({ categoryMode: "PREVIEW" });
  };

  const updateCategorySettingCall = (values, id) => {
    values.id = id;
    adminUpdateCommonCategorySetting(values);
    setState({ categoryMode: "PREVIEW" });
  };

  const handleSalesLeadPlaceIdClicked = (lead_id, place_id) => {
    adminUpdateSalesLeadPlaceId({ lead_id: lead_id, place_id: place_id });
    setState({ categoryMode: "PREVIEW" });
  };

  const handleSalesLeadEdit = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          shopname: "", name: "", phone: "", leadstatus: "", description: "", id: "", images: [], keyword: "",
          location: "", cardtext: ""
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          shopname: item.shopname, name: item.name, phone: item.phone, leadstatus: item.leadstatus,
          description: item.description, id: item.id, images: item.images, keyword: item.shopname,
          location: item.location, cardtext: item.cardtext,
        },
      });
    }
  };

  const CustomDatePicker = React.forwardRef(({ value, onClick }, ref) => (
      <ButtonComponent
          type="primary"
          variant="contained"
          name={value}
          size="small"
          onClick={onClick} ref={ref}>
        {value}
      </ButtonComponent>
  ));

  const handleLeadDate = (update) => {
    setStartdateState(update.getTime());
    adminSearchSalesLead(salesSearchTypeState, statusState, update.getTime(), locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, 0);
  };

  const handleSalesLeadStatus = (event) => {
    setStatusState(event.target.value);
    adminSearchSalesLead(salesSearchTypeState, event.target.value, startdateState, locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, 0);
  };


  const handleSalesLeadFilter = (event) => {
    setFilterState(event.target.value);
    adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, event.target.value, executiveState, "" /* shopname */, "" /* shopphone */, 0);
  };

  const handleSalesLeadExecutive = (event) => {
    setExecutiveState(event.target.value);
    adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, filterState, event.target.value, "" /* shopname */, "" /* shopphone */, 0);
  };

  const handlePrevOffset = () => {
    const offset = offsetState > 30 ? offsetState - 30 : 0;
    setOffsetState(offset);
    adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 30;
    setOffsetState(offset);
    adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, offset);
  };

  const handleSalesLeadSetting = (mode, item) => (e) => {
    setState({ categoryMode: mode });

    setSalesSearchTypeState("exact");
    adminSearchSalesFuture("exact", "", item.location, "");

    setCategoryState({
      storeCategory: {
        name: item.name,
        shopname: item.shopname,
        phone: item.phone,
        id: item.id,
        images: item.images,
        is_popular: item.is_popular,
        is_active: item.is_active,
        location: item.location,
        distance: item.distance,
        keyword: item.shopname,
        place_id: item.place_id,
        salesfuture: item.salesfuture,
        cardtext: item.cardtext
      },
    });
  };

  const handleSalesFutureKeywords = (item, keyword, phone) => {
    setSalesSearchTypeState("search");
    adminSearchSalesFuture("search", "", item.location, keyword, phone);
  };

  const handleSalesLeadImage = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          shopname: "", name: "",  phone: "", description: "", id: "", images: [], location: "", distance: "",
          place_id: "", salesfuture: "", cardtext: ""
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          shopname: item.shopname, name: item.name, phone: item.phone,
          description: item.description, id: item.id, images: item.images,
          location: item.location, distance: item.distance,
          place_id: item.place_id, salesfuture: item.salesfuture,
          cardtext: item.cardtext
        },
      });
    }
  };

  const setSalesleadStatus = (status) => {
    setStatusState(status);
    adminSearchSalesLead(salesSearchTypeState, statusState, startdateState, locationState, filterState, executiveState, "" /* shopname */, "" /* shopphone */, 0);
  };

  const updateLocationSearch = () => {

    const lat = mapref.getCenter().lat();
    const lng = mapref.getCenter().lng();
    const location = { coordinates: [ lng, lat ] };

    setLocationState(location);
    setSalesSearchTypeState("map");
    adminSearchSalesLead("map", statusState, startdateState, location, filterState, executiveState, "" /* shopname */, "" /* shopphone */, 0);

  };

  const openMapView = () => {
    setState({ categoryMode: "EDIT_MAP" });
  };

  const closeMapView = () => {
    setState({ categoryMode: "PREVIEW" });
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { salesleads } = searchleads;
  const { categoryMode } = state;
  const { storeCategory = {} } = categoryState;

  const leadstatus = getSalesLeadStatusList();

  const inputLeadStatusOptions = leadstatus.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });

  const leadfilter = getSalesLeadFilterList();

  const inputLeadFilterOptions = leadfilter.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });


  const { executives } = execList;

  const inputExecutiveFilterOptions = executives.map((item) => {
    const { name, _id } = item;
    return (
        <MenuItem value={_id}>{name}</MenuItem>
    );
  });

  const { salesfutures, searched } = searchfutures;
  var nomatch = [{ place_id: "none", shopname: "No Match", name: "", distance: "" }];

  const salesLeadPlaceIdOptions = nomatch.concat(salesfutures).concat(searched).map((item) => {
    const { place_id, shopname, name, distance } = item;
    return (
        <MenuItem value={place_id}>{shopname} {name} {getDistance(distance)}</MenuItem>
    );
  });

  const renderNoResults = () => (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <H6>No lead found
                  </H6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );

  const renderResults = () => (
    <div>
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
          {salesleads.map((saleslead, index) => (
              <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
              <AdminSalesLead
                className="product-item"
                key={saleslead.id}
                lead={saleslead}
                userDetails={bootupInfo.userDetails}
                editCategoryImage={handleSalesLeadImage("EDIT_IMG", saleslead, false)}
                editCategoryInfo={handleSalesLeadEdit("EDIT", saleslead, false)}
                editCategorySettings={handleSalesLeadSetting("EDIT_SET", saleslead)}
              />
              </Grid>
          ))}
        </Grid>
      <br/><br/><br/><br/>
    </div>
  );

  const renderCategoryList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
          <Button
              sx={{marginRight: '2px'}}
              size="large"
              color="primary"
              variant="contained"
              onClick={handleSalesLeadEdit("EDIT_QUICK", null, true)}
          >
            Quick Add
          </Button>
          <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleSalesLeadEdit("EDIT", null, true)}
          >
            Add New Lead
          </Button>
        </CustomFlexBox>

        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>
          <Box width="60%">
            Before:
          <DatePicker
              placeholderText="Select Date"
              selected={startdateState}
              maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
              onChange={handleLeadDate}
              dateFormat="MMMM d, yy"
              customInput={<CustomDatePicker />}
          />

          </Box>
          <Box width="40%" align="right">
            <ButtonComponent
                type="primary"
                variant="contained"
                name="Map View"
                size="small"
                onClick={openMapView}>
            </ButtonComponent>
          </Box>
        </CustomFlexBox>

        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>

          {(checkUserAllowed(userDetails.userlevel, "approveshop")) &&
              (<SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputLeadStatusOptions}
                  label="Status"
                  value={statusState}
                  onChange={(event) => handleSalesLeadStatus(event)}
              />)
          }



          {(checkUserAllowed(userDetails.userlevel, "approveshop")) &&
              (<SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputLeadFilterOptions}
                  label="Filter"
                  value={filterState}
                  onChange={(event) => handleSalesLeadFilter(event)}
              />)
          }

          {(checkUserAllowed(userDetails.userlevel, "approveshop")) &&
              (<SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputExecutiveFilterOptions}
                  label="Executive"
                  value={executiveState}
                  onChange={(event) => handleSalesLeadExecutive(event)}
              />)
          }
        </CustomFlexBox>

        {!isLoading && salesleads.length > 0 && <CustomFlexBox m={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box width="60%" align="center">
          </Box>
          <Box width="20%" align="right">
            {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
              <ArrowBackIosIcon fontSize="medium" color="primary"/>
            </IconButton>
            }
          </Box>
          <Box width="20%" align="right">
            <IconButton onClick={handleNextOffset}>
              <ArrowForwardIosIcon fontSize="medium" color="primary"/>
            </IconButton>

          </Box>
        </CustomFlexBox>
        }



      {isLoading ? <MockProductBoxList />
        : salesleads.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );


  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(
          place.location.coordinates[1],
          place.location.coordinates[0],
      ));
    });
    return bounds;
  };

// Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

// Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {

    setMapRef(map);

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const address = getGeoLocation();
  const center = address ? { lat: parseFloat(address.lat), lng: parseFloat(address.lng) } : salesleads && salesleads.length > 0 ? { lat: salesleads[0].location.coordinates[1], lng : salesleads[0].location.coordinates[0] } : { lat: 0, lng: 0 };


  const renderCategoryEdit = () => {
    switch (categoryMode) {
      case "EDIT":
        return (
          <AdminSalesLeadEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
              userDetails={userDetails}
            category={storeCategory}
              statusOptions={inputLeadStatusOptions}
            addUpdateCategoryToShopClicked={addUpdateCategoryToAccountCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_IMG":
        return (
          <AdminSalesImageMain
            lead={storeCategory}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );

      case "EDIT_QUICK":
        return (
            <AdminSalesQuickAddMain
                saleslead={storeCategory}
                cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
                autoClose={() => setState({ categoryMode: "PREVIEW" })}
            />
        );

      case "EDIT_MAP":
        return (
            <Box>
              <CustomFlexBox m={2} sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}>
                <Box width="50%" align="left">
                  <ButtonComponent
                      type="primary"
                      variant="outlined"
                      name="Back"
                      size="small"
                      onClick={closeMapView}>
                  </ButtonComponent>
                </Box>
                <Box width="50%" align="right">
                  <ButtonComponent
                      m={2}
                      type="primary"
                      variant="contained"
                      name="Search more"
                      size="small"
                      onClick={updateLocationSearch}>
                  </ButtonComponent>
                </Box>
              </CustomFlexBox>
              <div style={{ height: '500px', width: '100%' }}>
                <GoogleMap
                    style={{height: '500px', width: '100%'}}
                    resetBoundsOnResize={true}
                    defaultCenter={[center.lat, center.lng]}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={
                      ({ map, maps }) => apiIsLoaded(map, maps, salesleads)
                    }
                >
                  {salesleads.map((place) => (
                      <Marker
                          sx={{background: "#f0f"}}
                          key={place.place_id}
                          text={place.shopname}
                          lat={place.location.coordinates[1]}
                          lng={place.location.coordinates[0]}
                      />
                  ))}
                </GoogleMap>
              </div>
            </Box>
        );

      case "EDIT_SET":
      default:
        return (
          <AdminSalesLeadSettingEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
              lead={storeCategory}
              searchfutures={searchfutures}
              updateSalesLeadPlaceIdClicked={handleSalesLeadPlaceIdClicked}
              searchFuturePlaceIdKeywordClicked={handleSalesFutureKeywords}
              salesLeadPlaceIdOptions={salesLeadPlaceIdOptions}
              cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
              userDetails={userDetails}
          />
        );
    }
  };

  return (
    <Container>
      {categoryMode === "PREVIEW" ? (
        renderCategoryList()
      ) : (
        renderCategoryEdit(categoryMode)
      )}
    </Container>
  );
};

AdminSalesMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  searchleads: [],
};

AdminSalesMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  leadtype: PropTypes.string,
  searchleads: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  adminSearchSalesLead: PropTypes.func.isRequired,
  adminAddSalesLead: PropTypes.func.isRequired,
  adminUpdateCommonCategory: PropTypes.func.isRequired,
  adminUpdateCommonCategorySetting: PropTypes.func.isRequired,
};

const adminSalesMain = connect(
  ({ adminSales, bootupInfo }) => ({
    bootupInfo,
    userDetails: bootupInfo.userDetails,
    reqStatus: adminSales.reqStatus,
    isLoading: adminSales.isLoading,
    serviceError: adminSales.serviceError,
    searchleads: adminSales.searchleads,
    searchfutures: adminSales.searchfutures,
    execList: adminSales.execList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSalesMain);

export default adminSalesMain;
