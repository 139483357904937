/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  Card,
  IconButton,
  Container,
  Divider,
  MenuItem,
  Snackbar,
} from "@mui/material";

import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import { DATE_FORMAT } from "config/constants";
import {getImage, getLocalDate, getPriceCurrency, getTimeSlot, isMarketPlace} from "helpers/utils";

import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";
import { getCartTotal } from "services";
import {Notification, Pace} from "components/Atoms";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import {H2, H4} from "components/CustomTypography";
import CustomSectionCreator from "components/CustomSectionCreator";

const SubscriptionPaymentCheckout = (props) => {

  const { location, serviceError, reqStatus, isLoading, currency,
    getSubscriptionsCart, subscriptioncart,
    shopinfo, generateSubscriptionPlanOrder, orderplaced, history } = props;

  const {
    address, items, total_price, total_service, total_order, total_tax, order_type
  } = location.state || {};

  const [state, setState] = useState({
    address,
    paymentoption: "cash",
  });

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    getSubscriptionsCart();
  }, []);

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.GENERATE_SUBSCRIPTION_ORDER_SUCCESS:
        if (props.serviceCode === 200) {
            history.push({
              pathname: "/subscriptions/order-success",
              state: {
                // payment: { paymentID: props.order.order.order_id },
                order_id: orderplaced.order.order_id,
                _id: orderplaced.order._id,
                status: orderplaced.order.status,
                paymentstatus: orderplaced.order.paymentstatus,
                plans: orderplaced.order.plans,
                created_at: orderplaced.order.created_at,
                order_type: orderplaced.order.order_type,
                total_price: orderplaced.order.total_price,
                total_service: orderplaced.order.total_service,
                total_order: orderplaced.order.total_order,
                total_tax: orderplaced.order.total_tax
              },
            });
        }
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const handlePaymentOption = (event) => {
    setState({
      paymentoption: event.target.value,
    });
  };

  const handlePlaceOrder = () => {
    generateSubscriptionPlanOrder(state);
  };

  const handlePage = (page) => () => {
    props.history.push({
      pathname: page,
    });
  };

  const {
    order, cartItems, symbol, total, shippingAddress,
  } = props;

  // Paypal Integration
  const onSuccess = (payment) => {
    if (props.bootupInfo.appState.isWebsite) {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    } else {
      props.history.push({
        pathname: "/order-success",
        state: {
          payment,
          items: order.products,
          shipping: order.shipping,
          delivery_date: order.delivery_date,
          delivery_time: order.delivery_time,
          orderTotal: total,
          symbol,
        },
      });
    }
  };

  const client = {
    sandbox: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
    production: "AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_",
  };

  const getPrice = (symbol, item) => {
    const price = item.seller_price
        - (item.product && item.product.discount
          ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {symbol}
        {price}
        {item.product.discount && (
          <del>
            <span className="money">
              {symbol}
              {item.seller_price}
            </span>
          </del>
        )}
      </Typography>
    );
  };

  const renderOrderItems = () => (
      <Box>
        {subscriptioncart.map((item, index) => (
            <Box className="styled-card" width="100%" display="flex" p={1}>
              <Box width="80%" order={1} ml={2}>
                <H2 mb={2} fontSize={16}  ellipsis>
                  {item.plan.title}
                </H2>
                <H4 fontSize={12}>
                  {item.plan.description}
                </H4>
              </Box>
              <Box width="20%" order={2} ml={2}>
                <H2 mb={2} fontSize={16}  ellipsis>
                  {getPriceCurrency(item.price, currency)}
                </H2>
              </Box>
            </Box>
        ))}
      </Box>
  );

  const renderOrderTotal = () => (
    <Box>
      <Box className="styled-card" width="100%" display="flex" p={1}>
        <Box width="80%" order={1} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>Subtotal: </H2>
        </Box>
        <Box width="20%" order={2} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_price, currency)}</H2>
        </Box>
      </Box>

      <Box className="styled-card" width="100%" display="flex" p={1}>
        <Box width="80%" order={1} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>Service Charge: </H2>
        </Box>
        <Box width="20%" order={2} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_service, currency)}</H2>
        </Box>
      </Box>

      <Box className="styled-card" width="100%" display="flex" p={1}>
        <Box width="80%" order={1} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>Taxes: </H2>
        </Box>
        <Box width="20%" order={2} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_tax, currency)}</H2>
        </Box>
      </Box>

      <hr className="solid" />

      <Box className="styled-card" width="100%" display="flex" p={1}>
        <Box width="80%" order={1} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>Total: </H2>
        </Box>
        <Box width="20%" order={2} ml={2}>
          <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_order, currency)}</H2>
        </Box>
      </Box>
    </Box>
  );

  let paymenttag = shopinfo && shopinfo.paymentoption === "qrcodeimage" ? "Pay using QRCode image" : "Pay using UPI ID: " + shopinfo.paymentupi;

  const options2 = [
    { label: paymenttag, value: "cash" },
  ];

  const inputPaymentOptions = options2.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });

  return (
    <div>
      <ShopHeaderNav history={history} />
      {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
      <CustomSectionCreator isSubtle={true} title="Package Order - Summary" />
              <div>
                <form>
                  <Container>
                    <Box>

                    {renderOrderItems()}

                    <hr className="solid" />

                     {renderOrderTotal()}
                    </Box>
                    <Box>
                      {(total || !total)
                          ? (

                              <Typography className="m-2" align="center">
                                <ButtonComponent
                                    variant="contained"
                                    type="primary"
                                    name= {"Place Order (" + `${getPriceCurrency(total_order, currency)}` + ")"}
                                    fullWidth
                                    onClick={() => handlePlaceOrder()}
                                />
                              </Typography>
                          )
                          : ""}
                    </Box>

                  </Container>
                </form>
              </div>
    </div>
  );
};

// Mapping Props and State.
const subscriptionPaymentCheckout = connect(
  ({ subscriptions, bootupInfo, userproducts }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    shopinfo: bootupInfo.shopinfo,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    serviceCode: subscriptions.serviceCode,
    subscriptioncart: subscriptions.subscriptioncart,
    orderplaced: subscriptions.orderplaced
  }),
  (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(SubscriptionPaymentCheckout);

export { subscriptionPaymentCheckout as default };
