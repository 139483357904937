// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminResetSearchSalesLead = () => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_RESET
});

export const adminResetSearchKeywords = () => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_KEYWORD_RESET
});

export const adminResetLeadDetail = () => ({
  type: ActionTypes.ADMIN_SALES_LEAD_DETAIL_RESET
});

export const adminResetLeadImages = () => ({
  type: ActionTypes.ADMIN_SALES_LEAD_IMAGES_RESET
});

export const adminResetExecutiveSearch = () => ({
  type: ActionTypes.ADMIN_SALES_EXECUTIVE_SEARCH_RESET
});

export const adminSearchSalesLead = (type, leadstatus, startdate, location, filter, executive, shopname, shopphone, offset) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_START,
  name: type,
  leadstatus: leadstatus,
  start: startdate,
  location: location,
  filter: filter,
  executive: executive,
  shopname: shopname,
  shopphone, shopphone,
  offset, offset
});

export const adminSearchSalesLeadSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_SUCCESS,
    payload: data,
  };
};

export const adminSearchSalesLeadFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_FAILURE,
  payload: error,
});

export const adminSearchSalesLeadKeywords = (searchtype, name, execid, location) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_START,
  searchtype: searchtype,
  name: name,
  execid: execid,
  location: location
});

export const adminSearchSalesLeadKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const adminSearchSalesLeadKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_FAILURE,
  payload: error,
});

export const adminGetSalesLeadInfo = (reqData) => ({
  type: ActionTypes.ADMIN_GET_SALES_LEAD_INFO_START,
  payload: reqData,
});

export const adminGetSalesLeadInfoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SALES_LEAD_INFO_SUCCESS,
    payload: data,
  };
};
export const adminGetSalesLeadInfoFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SALES_LEAD_INFO_FAILURE,
  payload: error,
});

export const adminAddSalesLead = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_START,
  payload: reqData,
});

export const adminAddSalesLeadSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_SALES_LEAD_SUCCESS,
    payload: data,
  };
};
export const adminAddSalesLeadFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_FAILURE,
  payload: error,
});

export const adminUpdateSalesLead = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_START,
  payload: reqData,
});

export const adminUpdateSalesLeadSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_SUCCESS,
    payload: data,
  };
};
export const adminUpdateSalesLeadFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_FAILURE,
  payload: error,
});

export const adminUpdateSalesLeadCard = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_START,
  payload: reqData,
});

export const adminUpdateSalesLeadCardSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_SUCCESS,
    payload: data,
  };
};
export const adminUpdateSalesLeadCardFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_FAILURE,
  payload: error,
});

export const adminUpdateSalesLeadEvent = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_START,
  payload: reqData,
});

export const adminUpdateSalesLeadEventSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_SUCCESS,
    payload: data,
  };
};
export const adminUpdateSalesLeadEventFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_FAILURE,
  payload: error,
});

export const adminUpdateSalesLeadPlaceId = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_START,
  payload: reqData,
});

export const adminUpdateSalesLeadPlaceIdSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_SUCCESS,
    payload: data,
  };
};
export const adminUpdateSalesLeadPlaceIdFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_FAILURE,
  payload: error,
});

export const adminAddSalesLeadImage = (reqData, formData) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const adminAddSalesLeadImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminAddSalesLeadImageFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_FAILURE,
  payload: error,
});

export const adminRemoveSalesLeadImage = (reqData, image_id) => ({
  type: ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_START,
  payload: {lead_id: reqData, image_id: image_id},
});

export const adminRemoveSalesLeadImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_SUCCESS,
    payload: data,
  };
};
export const adminRemoveSalesLeadImageFailure = (error) => ({
  type: ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_FAILURE,
  payload: error,
});

export const adminAddSalesLeadImageQuick = (formData) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_START,
  formData: formData
});

export const adminAddSalesLeadImageQuickSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_SUCCESS,
    payload: data,
  };
};
export const adminAddSalesLeadImageQuickFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_FAILURE,
  payload: error,
});

export const adminGetSalesExecutives = (reqData) => ({
  type: ActionTypes.ADMIN_GET_SALES_EXECUTIVES_START,
  payload: reqData,
});

export const adminGetSalesExecutivesSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SALES_EXECUTIVES_SUCCESS,
    payload: data,
  };
};
export const adminGetSalesExecutivesFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SALES_EXECUTIVES_FAILURE,
  payload: error,
});

export const adminUpdateLeadExecutive = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_START,
  payload: req,
});

export const adminUpdateLeadExecutiveSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_SUCCESS,
    payload: data,
  };
};
export const adminUpdateLeadExecutiveFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_FAILURE,
  payload: error,
});

export const adminSearchSalesFuture = (type, leadstatus, location, keyword, phone, offset) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_FUTURE_START,
  name: type,
  leadstatus: leadstatus,
  location: location,
  keyword: keyword,
  phone: phone,
  offset: offset,
});

export const adminUpdateShopFutureLogoImage = (shop_place_id, formData) => ({
  type: ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_START,
  payload: shop_place_id,
  formData: formData
});

export const adminUpdateShopFutureLogoImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopFutureLogoImageFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_FAILURE,
  payload: error,
});

export const adminSearchSalesFutureSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SALES_FUTURE_SUCCESS,
    payload: data,
  };
};

export const adminSearchSalesFutureFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SALES_FUTURE_FAILURE,
  payload: error,
});

export const adminUpdateSalesFuture = (reqData) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_FUTURE_START,
  payload: reqData,
});

export const adminUpdateSalesFutureSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_SALES_FUTURE_SUCCESS,
    payload: data,
  };
};
export const adminUpdateSalesFutureFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_SALES_FUTURE_FAILURE,
  payload: error,
});
