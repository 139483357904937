/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";
import Grid from '@mui/material/Grid';
import {
  Pace,
} from "components/Atoms";

import LoginEmailForm from "components/elements/core/LoginEmailForm";

import LoginRegister from "components/elements/core/LoginRegister";
import LoginRegisterSuccess from "components/elements/core/LoginRegisterSuccess";
import PasswordResetForm from "components/elements/core/PasswordResetForm";
import PasswordResetSuccess from "components/elements/core/PasswordResetSuccess";
import LoginPreOTPForm from "components/elements/core/LoginPreOTPForm";
import LoginPostOTPForm from "components/elements/core/LoginPostOTPForm";

import "./styles.scss";
import {Box, Container, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";


function Copyright(props) {
  return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}{process.env.REACT_APP_DOMAIN_NAME}{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}


const Login = (props) => {
  const [state, setState] = useState({
    viewMode: "LOGIN",
    loginMode: "OTP",
    errorType: "",
    mobile: "",
  });
  const {
    login: loginApi,
    serviceCode,
    serviceError,
    reqStatus,
    isLoading,
  } = props;

  useEffect(() => {
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      case ActionTypes.LOGIN_POST_OTP_SUCCESS:
        break;
      default:
        break;
    }
  }, [reqStatus]);

  // const sendNotification = (type, { title, description = "" }) => {
  //   Notification[type]({
  //     message: title,
  //     description,
  //   });
  // };
  const setViewMode = (viewMode) => () => {
    setState({ ...state, viewMode });
  };

  const setLoginMode = (loginMode) => () => {
    setState({ ...state, loginMode });
  };

  const login = (loginDetails) => {
    loginApi(loginDetails);
  };

  const loginPreOTP = (code, number) => {
    props.loginPreOTP({code: code, mobile: number});
    setState({ ...state, mobile: number, code: code });
  };

  const loginPostOTP = (loginDetails) => {
    props.loginPostOTP(loginDetails);
  };

  const register = (registerDetails) => {
    props.register(registerDetails);
  };

  const resetPassword = (email) => {
    props.resetPassword(email);
  };

  const handleSignInClick = () => {
    window.open(`${process.env.REACT_APP_GOOGLE_LOGIN_ENDPOINT}`, "_self");
  };

  const { viewMode, loginMode, mobile, code } = state;

  if (isLoading) {
    return <Pace color="#27ae60" height="2px" progressbar="2px" />
  } else {
    return (
        <div>
          <Container component="main">
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
              <Grid item justify="center">
                <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                >
                  <Avatar sx={{ m: 1, width: 100, height: 150  }} variant="square" src={`${process.env.REACT_APP_LOGO}`} />
                </Box>
              </Grid>
              <Grid item justify="center">
                {viewMode === "LOGIN" ? (
                    loginMode === "OTP" ? (
                        (serviceCode === 200 && reqStatus === ActionTypes.LOGIN_PRE_OTP_SUCCESS)
                            ? (
                                <LoginPostOTPForm
                                    serviceError={serviceError}
                                    serviceCode={serviceCode}
                                    reqStatus={reqStatus}
                                    OTP_USER_MOBILE={{ mobile }}
                                    OTP_USER_COUNTRYCODE={{ code }}
                                    showResetPassword={setViewMode("RESET_PASSWORD")}
                                    showRegister={setViewMode("REGISTER")}
                                    showPasswordLogin={setLoginMode("PASSWORD_LOGIN")}
                                    loginPostOTP={loginPostOTP}
                                    loginPreOTP={loginPreOTP}
                                />
                            )
                            : (
                                <LoginPreOTPForm
                                    serviceError={serviceError}
                                    serviceCode={serviceCode}
                                    reqStatus={reqStatus}
                                    showResetPassword={setViewMode("RESET_PASSWORD")}
                                    showRegister={setViewMode("REGISTER")}
                                    showPasswordLogin={setLoginMode("PASSWORD_LOGIN")}
                                    loginPreOTP={loginPreOTP}
                                />
                            )
                    ) : (
                        <LoginEmailForm
                            serviceError={serviceError}
                            serviceCode={serviceCode}
                            reqStatus={reqStatus}
                            showResetPassword={setViewMode("RESET_PASSWORD")}
                            showRegister={setViewMode("REGISTER")}
                            showOTPLogin={setLoginMode("OTP")}
                            login={login}
                            googleSignIn={handleSignInClick}
                        />
                    )
                ) : viewMode === "REGISTER" ? (
                    (serviceCode === 200 && reqStatus === ActionTypes.REGISTER_SUCCESS)
                        ? (
                            <LoginRegisterSuccess
                                serviceError={serviceError}
                                serviceCode={serviceCode}
                                reqStatus={reqStatus}
                                showLogin={setViewMode("LOGIN")}
                                showResetPassword={setViewMode("RESET_PASSWORD")}
                                register={register}
                            />
                        )
                        : (
                            <LoginRegister
                                serviceError={serviceError}
                                serviceCode={serviceCode}
                                reqStatus={reqStatus}
                                showLogin={setViewMode("LOGIN")}
                                showResetPassword={setViewMode("RESET_PASSWORD")}
                                register={register}
                            />
                        )
                ) : (viewMode === "RESET_LOGIN") ? (
                    (
                        <LoginPreOTPForm
                            serviceError={serviceError}
                            serviceCode={serviceCode}
                            reqStatus={reqStatus}
                            showResetPassword={setViewMode("RESET_PASSWORD")}
                            showRegister={setViewMode("REGISTER")}
                            showPasswordLogin={setLoginMode("PASSWORD_LOGIN")}
                            loginPreOTP={loginPreOTP}
                        />
                    )
                ) : (
                    (serviceCode === 200 && reqStatus === ActionTypes.RESET_PASSWORD_SUCCESS)
                        ? (
                            <PasswordResetSuccess
                                serviceError={serviceError}
                                serviceCode={serviceCode}
                                reqStatus={reqStatus}
                                showLogin={setViewMode("LOGIN")}
                                showRegister={setViewMode("REGISTER")}
                                resetPassword={resetPassword}
                            />
                        )
                        : (
                            <PasswordResetForm
                                serviceError={serviceError}
                                serviceCode={serviceCode}
                                reqStatus={reqStatus}
                                showLogin={setViewMode("LOGIN")}
                                showRegister={setViewMode("REGISTER")}
                                resetPassword={resetPassword}
                            />
                        )
                )
                }
              </Grid>
              <Grid item>
                <Copyright sx={{
                  mt: 8, mb: 4
                }} />
              </Grid>
            </Grid>
          </Container>
        </div>
    );
  }
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loginPreOTP: PropTypes.func.isRequired,
  loginPostOTP: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  serviceCode: PropTypes.number.isRequired,
  serviceError: PropTypes.string.isRequired,
  reqStatus: PropTypes.string.isRequired,
};

// Mapping Props and State.
const LoginComponent = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    serviceCode: bootupInfo.serviceCode,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(Login);

export { LoginComponent as default };
