/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as Actions from "actions";
import MockOrderDetail from "components/elements/main/MockOrderDetail";
import { DATE_FORMAT, CURRENCY } from "config/constants";
import {getLocalDate, getTimeSlot, getImage, isMarketPlace} from "helpers/utils";

import {
  Typography, Container, Box, Card, Paper, Avatar, CircularProgress, Button
} from "@mui/material";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import * as ActionTypes from "constants/ActionTypes";
import {Notification} from "components/Atoms";
import CustomSectionCreator from "components/CustomSectionCreator";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ImagesDownload from "components/elements/main/ImagesDownload";
import ButtonComponent from "../../../../Button";

const ShopOrderDetail = (props) => {
  const [prices, setPrices] = useState({
    total: 0, totalDiscount: 0,
  });
  const {
      serviceError, reqStatus, isLoading,
      orderdetail, getShopOrderDetail, userUpdateOrderStatus,
      bootupInfo, shopinfo, symbol, match,
  } = props;

    const [copySuccess, setCopySuccess] = useState('Copy');

  const { orderId } = match.params;

    const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

    const {
    products, shipping, created_at: createdAt, order_id: order_id, total_price: totalPrice = 0,
      order_type, total_order, total_price, total_shipping, total_tax, delivery_date, delivery_time
  } = orderdetail;

  useEffect(() => {
    getShopOrderDetail(orderId, shopId, !isMarketPlace());
  }, []);

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.USER_UPDATE_ORDER_STATUS_SUCCESS:
                getShopOrderDetail(orderId, shopId, !isMarketPlace());;
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

  useEffect(() => {
    let total = 0;
    let totalDiscount = 0;
    if (products.length) {
      products.forEach((item) => {
        total += item.seller_price;
        totalDiscount += item.discount || 0;
      });
      setPrices({ total, totalDiscount });
    }
  }, [products]);

    const updateOrder = (orderdetail) => {
        userUpdateOrderStatus({ order_id: orderdetail._id, status: "paid", message: "" });
    };


    if (isLoading) {
    return <MockOrderDetail />;
  }


    const renderOrderInformation = () => (
        <Box>
            <CustomSectionCreator icon={<ShoppingCartRoundedIcon />} title={"Order Confirmation: " + order_id}>
            </CustomSectionCreator>
        </Box>
    );

    const renderPendingPaymentInformation = () => (
        <Box>
            <CustomSectionCreator icon={<CurrencyRupeeIcon />} title={"Next step: Make payment to confirm order"}>
                {shopinfo.paymentoption && shopinfo.paymentoption === "upibased" ?
                    <Box>
                        <Typography>Pay via UPI payment app: <b>{shopinfo.paymentupi}</b>
                            &nbsp;<ButtonComponent variant="contained"
                                             type="primary"
                                             name={copySuccess}
                                             size="small"
                                             onClick={() => copyToClipBoard(shopinfo.paymentupi)} />
                        </Typography>
                        <Typography>Payment due: <b>{symbol + total_order}</b></Typography>
                    </Box>
                    :
                    <ImagesDownload
                        images={shopinfo.paymentqrcode}
                        message={"Download QRCode and Pay via payment app " + symbol + total_order}
                        shopqrfilename={shopinfo.title + " order " + order_id}
                    />
                }
                <Box><Typography>Add order no: {order_id} in payment information</Typography></Box>
                <Box>
                    <ButtonComponent
                        variant="contained"
                        type="primary"
                        name="Confirm Paid"
                        fullWidth
                        onClick={() => updateOrder(orderdetail)}
                    />
                </Box>
            </CustomSectionCreator>
        </Box>
    );

    const renderPaymentInformation = () => (
        <Box>
            {orderdetail.status === "pending" ? renderPendingPaymentInformation()
                :
                <CustomSectionCreator icon={<CurrencyRupeeIcon />} title={"Payment"}>
                    <Box><Typography>Status: Paid</Typography></Box>
                </CustomSectionCreator>
            }
        </Box>
    );


  const renderOrderItems = () => (
    <Box>
      <Card className="p-3">
        {products.map((cartItem, index) => (
          <Card className="order-card" key={`${index + 1}`}>
            <Box minHeight="100%" width="100%" display="flex">
              <Box className="styled-card" width={window.innerWidth < 400 ? "17%" : "12%"}>
                <Avatar variant="square" src={getImage(cartItem.product.images)} alt="" />
              </Box>
              <Box flexGrow={1}>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {cartItem.product.name}
                </Typography>
                <Typography display="block" variant="caption">
                  {cartItem.quantity}
                  {" "}
                  x
                  {symbol}
                  {cartItem.seller_price}
                </Typography>
                  <Typography className="styled-link" gutterBottom variant="h6" component="p">
                      {cartItem.product_size}
                  </Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  Total:
                  {symbol}
                  {cartItem.quantity * cartItem.seller_price}
                </Typography>
              </Box>
            </Box>
          </Card>
        ))}
      </Card>
    </Box>
  );

  const renderShippingAddress = () => (
    <Card className="p-3">
      <Typography gutterBottom variant="h6">Delivery Address</Typography>
      <Typography display="block" variant="subtitle2">
        {shipping.name}
      </Typography>
      <Typography display="block" variant="subtitle2">
        {shipping.address}
        ,
        {shipping.city}
        ,
        {shipping.pincode}
      </Typography>
      <Typography display="block" variant="subtitle2">
        {shipping.phone}
      </Typography>
    </Card>
  );

    const renderShopPickup = () => (
        <Card className="p-3">
            <Typography variant="h6">Pickup</Typography>
            <Typography variant="subtitle2" display="block">
                {getLocalDate(delivery_date, DATE_FORMAT)}
                ,
                {getTimeSlot(delivery_time)}
            </Typography>
        </Card>
    );

  const renderOrderTotal = () => (
      <Card className="p-3">
          <Box display="flex">
              <Box width="50%">
                  <Typography variant="subtitle2" display="block">Subtotal: </Typography>
              </Box>
              <Box width="50%">
                  <Typography variant="subtitle2" display="block" align="right">
                      {symbol}
                      {total_price}
                  </Typography>
              </Box>
          </Box>
          <Box display="flex">
              <Box width="50%">
                  <Typography variant="subtitle2" display="block">Shipping: </Typography>
              </Box>
              <Box width="50%">
                  <Typography variant="subtitle2" display="block" align="right">
                      {symbol}
                      {total_shipping}
                  </Typography>
              </Box>
          </Box>
          <hr className="solid" />
          <Box display="flex">
              <Box width="50%">
                  <Typography variant="subtitle2" display="block">Total price: </Typography>
              </Box>
              <Box width="50%">
                  <Typography variant="subtitle2" display="block" align="right">
                      {symbol}
                      {total_order}
                  </Typography>
              </Box>
          </Box>
      </Card>
  );

    const renderOrderDetails = () => (
        <Box>
            <CustomSectionCreator icon={<ShoppingCartRoundedIcon />} title={"Order detail: " }>
                {renderOrderItems()}
                {renderOrderTotal()}
            </CustomSectionCreator>
        </Box>
    );

  return (
    <div>
        {isMarketPlace() &&
            <ShopHeaderMini {...props} />}
      <Container maxWidth="md">
        <Container className="header-color">
          {renderOrderInformation()}
        </Container>

          <Box display={window.innerWidth < 400 ? "" : "flex"}>
              <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                  {order_type === "delivery" ? renderShippingAddress() : renderShopPickup()}
              </Box>
          </Box>

          {renderPaymentInformation()}
          {renderOrderDetails()}
      </Container>
    </div>
  );
};

ShopOrderDetail.defaultProps = {
  isLoading: false,
  symbol: CURRENCY,
};

ShopOrderDetail.propTypes = {
  isLoading: PropTypes.bool,
  orderdetail: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  getShopOrderDetail: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
};

// Mapping Props and State.
const shopOrderDetail = connect(
  ({ userorders, bootupInfo }) => ({
    bootupInfo,
      symbol: bootupInfo.symbol,
      shopinfo: bootupInfo.shopinfo,
    reqStatus: userorders.reqStatus,
    isLoading: userorders.isLoading,
    serviceError: userorders.serviceError,
    orderdetail: userorders.orderdetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopOrderDetail);

export { shopOrderDetail as default };
