/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

// Marketplace specific
import UserHomeMain from "components/pages/user/home/UserHomeMain";

// Combined Product and Shops search
import UserCombinedSearchMain from "components/pages/user/home/UserCombinedSearchMain";

// Products
import UserProductsPageSearch from "components/pages/user/products/UserProductsPageSearch";

// Shops
import UserShopsPageSearch from "../components/pages/user/shop/UserShopsPageSearch";

// Products
import UserProductPageDetail from "../components/pages/user/products/UserProductPageDetail";

// Orders
import UserOrderList from "../components/pages/user/orders/UserOrderList";
import UserOrderDetail from "../components/pages/user/orders/UserOrderDetail";

// Offers
import UserOffersPageSearch from "../components/pages/user/offers/UserOffersPageSearch";
import UserOffers from "../components/pages/user/offers/UserOffers";
import UserOfferList from "../components/pages/user/offers/UserOfferList";
import UserOfferDetail from "../components/pages/user/offers/UserOfferDetail";


// Favourites
import UserWishList from "../components/pages/user/favourites/UserWishList";
import UserShopList from "../components/pages/user/favourites/UserShopList";


// Profile
import UserProfileHome from "components/pages/user/home/UserProfileHome";


// Shop specific
import ShopSiteHomeMain from "../components/pages/user/home/ShopSiteHomeMain";

// Products
import ShopProductPageSearch from "../components/pages/user/products/ShopProductPageSearch";
import ShopProductPageDetail from "../components/pages/user/products/ShopProductPageDetail";

// Orders -
import ShopOrdersList from "../components/pages/user/orders/ShopOrderList";
import ShopOrderDetail from "../components/pages/user/orders/ShopOrderDetail";

// Favourites -
import ShopWishList from "../components/pages/user/favourites/ShopWishList";
import UserFavs from "../components/pages/user/favourites/UserFavs";

// Checkout -
import shopCart from "../components/pages/user/checkout/shopCart";
import shopCheckOut from "../components/pages/user/checkout/shopCheckout";
import shopPaymentCheckout from "../components/pages/user/checkout/shopCheckout/payment-page";
import shopOrderSuccess from "../components/pages/user/checkout/shopCheckout/success-page";

// CHANGE THIS TO PAGE
import UserBarCodeScanner from "../components/elements/user/UserBarCodeScanner";

import CollectionLeftSidebarShop from "components/pages/user/collections/CollectionLeftSidebarShop";
import CollectionShopsSidebar from "components/pages/user/collections/CollectionShopsSidebar";

import ShopViewComponent from "../components/pages/user/shop/ShopView";
import ShopCategoryProduct from "../components/pages/user/products/ShopCategoryProduct";
import ProductList from "../components/pages/user/products/ProductList";


export const MarketplaceRoutes = [
  // Market main
  { path: "/", component: UserHomeMain, exact: true },
    // Marketplace - combined product and shop search

  // Marketplace - combined search
  { path: "/search", component: UserCombinedSearchMain, exact: true },
  { path: "/search/:query", component: UserCombinedSearchMain, exact: true },

  // Marketplace - product search
  { path: "/products", component: UserProductsPageSearch, exact: true },
  { path: "/products/sort/:sort", component: UserProductsPageSearch, exact: true },
  { path: "/products/location/:location", component: UserProductsPageSearch, exact: true },
  { path: "/products/market/:market", component: UserProductsPageSearch, exact: true },
  { path: "/products/category/:category", component: UserProductsPageSearch, exact: true },
  { path: "/products/search", component: UserProductsPageSearch, exact: true },
  { path: "/products/search/:query", component: UserProductsPageSearch, exact: true },
  { path: "/products/:id", component: UserProductPageDetail, exact: true },

    // Marketplace - shop search
  { path: "/shops", component: UserShopsPageSearch, exact: true },
  { path: "/shops/sort/:sort", component: UserShopsPageSearch, exact: true },
  { path: "/shops/location/:location", component: UserShopsPageSearch, exact: true },
  { path: "/shops/market/:market", component: UserShopsPageSearch, exact: true },
  { path: "/shops/search", component: UserShopsPageSearch, exact: true },
  { path: "/shops/search/:query", component: UserShopsPageSearch, exact: true },

  { path: "/offers", component: UserOffersPageSearch, exact: true },
  { path: "/offers/sort/:sort", component: UserOffersPageSearch, exact: true },
  { path: "/offers/location/:location", component: UserOffersPageSearch, exact: true },
  { path: "/offers/market/:market", component: UserOffersPageSearch, exact: true },
  //{ path: "/favs/offers", component: UserOfferList, exact: true },
  { path: "/offers/:offerId", component: UserOfferDetail, exact: true },

  { path: "/favs", component: UserFavs, exact: true },
  { path: "/favs/offers", component: UserOfferList, exact: true },
  { path: "/favs/offers/:offerId", component: UserOfferDetail, exact: true },

  { path: "/me/orders", component: UserOrderList, exact: true },
  { path: "/me/orders/:orderId", component: UserOrderDetail, exact: true },

];
