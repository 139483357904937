/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/prefer-default-export */
// Shop site
import ShopSiteHomeMain from "../components/pages/user/home/ShopSiteHomeMain";
import ShopSiteProductSearchMain from "../components/pages/user/home/ShopSiteProductSearchMain";
// Products
import ShopProductPageDetail from "../components/pages/user/products/ShopProductPageDetail";
// Orders
import ShopOrderList from "../components/pages/user/orders/ShopOrderList";
import ShopOrderDetail from "../components/pages/user/orders/ShopOrderDetail";
// Favourites
import ShopWishList from "../components/pages/user/favourites/ShopWishList";
// Checkout
import shopCart from "../components/pages/user/checkout/shopCart";
import shopCheckOut from "../components/pages/user/checkout/shopCheckout";
import shopPaymentCheckout from "../components/pages/user/checkout/shopCheckout/payment-page";
import shopOrderSuccess from "../components/pages/user/checkout/shopCheckout/success-page";

import ShopProduct from "../components/pages/user/products/shopProduct";

export const SellerShopSiteRoutes = [
    // Main
  { path: "/", component: ShopSiteHomeMain, exact: true },

  // Products - common component with marketplace shop
  { path: "/products", component: ShopSiteProductSearchMain, exact: true },
  { path: "/products/search", component: ShopSiteProductSearchMain, exact: true },
  { path: "/products/search/:query", component: ShopSiteProductSearchMain, exact: true },
  { path: "/products/sort/:sort", component: ShopSiteProductSearchMain, exact: true },
  { path: "/products/:prodId", component: ShopProductPageDetail, exact: true },

	// Checkout - common component with marketplace shop
  { path: "/cart", component: shopCart, exact: true },
  { path: "/cart/checkout", component: shopCheckOut },
  { path: "/cart/payment-page", component: shopPaymentCheckout },
  { path: "/cart/order-success", component: shopOrderSuccess },

  // Orders - common component with marketplace shop
  { path: "/me/orders", component: ShopOrderList, exact: true },
  { path: "/me/orders/:orderId", component: ShopOrderDetail, exact: true },

  // Favourites - products in shop - common component with marketplace shop
  { path: "/me/wishlist", component: ShopWishList, exact: true },

];
