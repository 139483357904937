/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions/admin/admin.products";
import * as ActionTypes from "constants/ActionTypes";
import {
    TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid, MenuItem,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AdminSellerProduct from "components/elements/admin/products/AdminSellerProduct";
import AdminSellerProductEditor from "components/elements/admin/products/AdminSellerProductEditor";
import AdminSellerProductEditorSetting from "components/elements/admin/products/AdminSellerProductEditorSetting";
import MockProductBoxList from "components/elements/main/MockProductBoxList";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { H6 } from "components/CustomTypography";

import AdminSellerProductStatusSelector from "components/elements/admin/products/AdminSellerProductStatusSelector";
import CustomFlexBox from "components/CustomFlexBox";
import DatePicker from "react-datepicker";
import {getAdminProductSortList, getAdminProductStatusList} from "helpers/utils";
import ButtonComponent from "components/Button";
import SelectFieldComponent from "components/SelectField";
import {adminUpdateProductInfo} from "actions/admin/admin.products";
import {Notification} from "../../../Atoms";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const AdminProductSearch = (props) => {
  const [state, setState] = useState({
    limit: 20,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search products",
    isShop: false,
  });

  const [productState, setProductState] = useState("");
  const [productModeState, setProductModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);
    const [optionValuesCategory, setOptionValuesCategory] = useState([]);

    const [categoryState, setCategoryState] = useState("");
    const [statusState, setStatusState] = useState("");
    const [sortState, setSortState] = useState("");
    const [startdateState, setStartdateState] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [cityState, setCityState] = useState("");
    const [offsetState, setOffsetState] = useState(0);

    const {
    serviceError, reqStatus, isLoadingProducts,
    adminResetSearchProducts, adminResetSearchKeywords,
    adminSearchProducts, adminSearchProductKeywords,
        adminGetProductAndShopsInfo, adminUpdateStatusPrivateProduct,
      adminSendMessage, adminSearchCommonCategory, adminUpdateProductCategory, adminUpdateProductInfo, adminUpdateProductVideo,
    products, productshopsdetail, searchkeywords, searchcategorykeywords,
        adminResetProductDetail, adminResetCategorySearch, getCities, cities,
        adminGetCommonProductCategories, commonCategoryList,
      currency, match, history, symbol = "$",
    isLoadingShop, shopId, isShop, limit,
        userDetails
  } = props;

  const { keywords } = searchkeywords;
  const { keywordscategory } = searchcategorykeywords;

  const { productdetail, productshops } = productshopsdetail;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;

  useEffect(() => {
    if (query) {
      adminResetSearchProducts();
      adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offsetState);
    } else {
      adminSearchProducts("", isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offsetState);
    }
    adminGetCommonProductCategories();
    getCities();
  }, []);

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: serviceError,
                description: serviceError,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS:
            case ActionTypes.ADMIN_UPDATE_PRODUCT_VIDEO_SUCCESS:
            case ActionTypes.ADMIN_UPDATE_PRODUCT_INFO_SUCCESS:
                if (query) {
                    adminResetSearchProducts();
                    adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offsetState);
                } else {
                    adminSearchProducts("", isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offsetState);
                }
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

  const runSearch = (keyword) => {
    adminResetSearchKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });

    if (isShop) {
      history.push(`/products/shop/${shopId}/psearch/${keyword}`);
    } else {
      history.push(`/products/psearch/${keyword}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      adminSearchProductKeywords(value, shopId, isShop);
    }
  };

  const selectKeywordSearch = (value) => {
    if (isShop) {
        history.push(`/products/shop/${shopId}/psearch/${value}`);
    } else {
        history.push(`/products/psearch/${value}`);
    }
  };

  const handleDelete = () => {
      if (isShop) {
          history.push(`/products/shop/${shopId}`);
      } else {
      history.push("/products");
        }
  };

  const updateProductStatus = (product) => {
      adminUpdateStatusPrivateProduct(product);
    setProductModeState("PREVIEW");
  };

    const messageShop = (product, message) => {
        adminSendMessage({ pid: product.id, type: "product", msg: message });
        setProductModeState("PREVIEW");
    };

    const updateProductCategory = (prodId, categoryId) => {
        adminUpdateProductCategory({ pid: prodId, cid: categoryId });
        adminResetProductDetail();
        adminResetCategorySearch();
        setProductModeState("PREVIEW");
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, 0);
    };

    const updateProductInfoAdmin = (prodinfo) => {
        adminUpdateProductInfo(prodinfo);
        setProductModeState("PREVIEW");
    };

    const adminUpdateProductVideoClicked = (id, formData) => {
        adminUpdateProductVideo(id, formData);
        setProductModeState("PREVIEW");
    };

    const runCategorySearch = (value) => {
        if ((value.length > 1)) {
            adminSearchCommonCategory(value);
        }
    };


  const handleProductEdit = (mode, product, reset) => () => {
    setProductModeState(mode);
    if (reset) {
      setProductState({
        selectedProduct: {
           _id: "", status: "", name: "", description: "", images: [], autotags: "", autocategory: "", metadata: "",
            video: [],
        },
      });
    } else {
        adminGetProductAndShopsInfo(product.id);
      setProductState({
          selectedProduct: {
          _id: product.id, status: product.status, name: product.name, description: product.description,
              images: product.images, autotags: product.autotags, autocategory: product.autocategory,
              metadata: product.metadata, video: product.video,
          },
      });
    }
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);

    useEffect(() => {
        setOptionValuesCategory(keywordscategory);
    }, [keywordscategory]);

    const setProductStatus = (status) => {
        setStatusState(status);
        adminSearchProducts(query, isShop, shopId, status, startdateState, sortState, categoryState, cityState, 0);
    };

    const CustomDatePicker = React.forwardRef(({ value, onClick }, ref) => (
        <ButtonComponent
            type="primary"
            variant="contained"
            name={value}
            size="small"
            onClick={onClick} ref={ref}>
            {value}
        </ButtonComponent>
    ));

    const handleProductAddDate = (update) => {
        setStartdateState(update.getTime());
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, statusState, update.getTime(), sortState, categoryState, cityState, 0);
    };

    const handleSortProducts = (event) => {
        setSortState(event.target.value);
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, event.target.value, categoryState, cityState, 0);
    };

    const handleCategoryProducts = (event) => {
        setCategoryState(event.target.value);
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, event.target.value, cityState, 0);
    };

    const handleFilterProducts = (event) => {
        setStatusState(event.target.value);
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, event.target.value, startdateState, sortState, categoryState, cityState, 0);
    };

    const handleCityProducts = (event) => {
        setCityState(event.target.value);
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, event.target.value, 0);
    };

    const handleRestAll = (event) => {
        setStatusState("");
        setStartdateState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
        setSortState("newest");
        setCategoryState("");
        setCityState("");
        setOffsetState(0);
        adminSearchProducts(query, isShop, shopId, "", new Date(new Date().getTime() + 24 * 60 * 60 * 1000), "newest", "", 0);
    };

    const handleReload = (event) => {
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offsetState);
     };

    const handlePrevOffset = () => {
        const offset = offsetState > 1 ? offsetState - 1 : 0;
        setOffsetState(offset);
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offset);
    }

    const handleNextOffset = () => {
        const offset = offsetState + 1;
        setOffsetState(offset);
        adminSearchProducts(query, isShop, shopId, statusState, startdateState, sortState, categoryState, cityState, offset);
    }

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/products/psearch" || match.path !== "/products/shop/:shopId/psearch")) {
      history.push(isShop ? `/products/shop/${shopId}/psearch` : "/products/psearch");
    }
  };

  let isModalSearch = false;

    const productsort = getAdminProductSortList();

    const sortOptions = productsort.map((item) => {
        const { value, label } = item;
        return (
            <MenuItem value={value}>{label}</MenuItem>
        );
    });

    const { categories } = commonCategoryList;

    const categoryFilterOptions = categories.map((item) => {
        const { id, name } = item;
        return (
            <MenuItem value={id}>{name}</MenuItem>
        );
    });


    const statusList = getAdminProductStatusList();

    const inputProductFilterOptions = statusList.map((item) => {
        const { value, label } = item;
        return (
            <MenuItem value={value}>{label}</MenuItem>
        );
    });

    const citiesFilterOptions = cities.map((item) => {
        const { id, name } = item;
        return (
            <MenuItem value={item}>{name}</MenuItem>
        );
    });

    const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No Product found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  const renderResults = () => (
      <div>
          <Grid item container md={9} xs={12} spacing={2}>
        { products.map((product, index) => (
            <Grid item xs={6} sm={4} key={index}>
              <AdminSellerProduct
                  product={product}
                  userDetails={userDetails}
                  currency={currency}
                  key={`${index + 1}`}
                  editProductStatus={handleProductEdit("EDIT", product, false)}
                  editProductSetting={handleProductEdit("EDIT_SET", product, false)}
              />
            </Grid>
        ))}
          </Grid>
      </div>
  );

  const renderSearchHelp = () => (
      <Container>
          <Box>
              {
                  window.innerWidth <= 500
                  && (match.path === "/products/psearch" || match.path === "/products/shop/:shopId/psearch")
                  && optionValues.map((element) => (
                      <Container>
                          <Typography onClick={() => selectKeywordSearch(element.label)}>
                              <IconButton disabled size="large">
                                  <SearchIcon />
                              </IconButton>
                              {element.label}
                          </Typography>
                      </Container>
                  ))
              }
          </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box>
        {
            window.innerWidth <= 500
            && (match.path === "/products/psearch" || match.path === "/products/shop/:shopId/psearch") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={handleKeyPress}
          onSelect={(input) => runKeywordSearch(input.target.value)}
          options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
          renderInput={(params) => (
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large" onClick={() => props.history.push("/")}>
                      <ArrowBackIosIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...params}
              type="search"
              size="large"
              autoFocus={isModalSearch}
              label={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
              placeholder={`Search ${process.env.REACT_APP_PRODUCT_TITLE}`}
              margin="normal"
            />
          )}
        />
        </Box>
      </Container>

        {
            renderSearchHelp()
        }


      <Container>
        {query && (
        <Chip
          label={query}
          onDelete={handleDelete}
        />
        )}
      </Container>

      { match.path !== "/"
      && (
          match.path === "/products/psearch/:query" ||
          match.path === "/products/shop/:shopId" ||
          match.path === "/products/shop/:shopId/psearch/:query" ||
          match.path === "/shops/shop/:shopId" ||
          match.path === "/shops" ||
          match.path === "/products"
      ) && (
      <>

          <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
          }}>
              <Box width="50%">Before:
                  <DatePicker
                      sx={{margin: "4px"}}
                      placeholderText="Select Date"
                      selected={startdateState}
                      maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                      onChange={handleProductAddDate}
                      dateFormat="MMMM d, yy"
                      customInput={<CustomDatePicker />}
                  />

              </Box>
              <Box width="50%" align="right">
                  <ButtonComponent
                      sx={{margin: "4px"}}
                      type="primary"
                      variant="contained"
                      name="Reset"
                      size="small"
                      onClick={handleRestAll}>
                  </ButtonComponent>
                  <ButtonComponent
                      sx={{margin: "4px"}}
                      type="primary"
                      variant="contained"
                      name="Reload"
                      size="small"
                      onClick={handleReload}>
                  </ButtonComponent>
              </Box>
          </CustomFlexBox>
          <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
          }}>
              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={sortOptions}
                  label="Sort"
                  value={sortState}
                  onChange={(event) => handleSortProducts(event)}
              />

              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={categoryFilterOptions}
                  label="By Category"
                  value={categoryState}
                  onChange={(event) => handleCategoryProducts(event)}
              />

              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputProductFilterOptions}
                  label="Status Filter"
                  value={statusState}
                  onChange={(event) => handleFilterProducts(event)}
              />
              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={citiesFilterOptions}
                  label="By City"
                  value={cityState}
                  onChange={(event) => handleCityProducts(event)}
              />
          </CustomFlexBox>


          {!(isLoadingProducts) && products.length > 0 && <CustomFlexBox m={2} sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
          }}>
              <Box width="60%" align="center">
              </Box>
              <Box width="20%" align="right">
                  {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
                      <ArrowBackIosIcon fontSize="medium" color="primary"/>
                  </IconButton>
                  }
              </Box>
              <Box width="20%" align="right">
                  <IconButton onClick={handleNextOffset}>
                      <ArrowForwardIosIcon fontSize="medium" color="primary"/>
                  </IconButton>

              </Box>
          </CustomFlexBox>
          }

          {isLoadingProducts ? <MockProductBoxList />
            : products.length > 0
              ? renderResults()
              : renderNoResults()}
      </>
      )}
    </>
  );

  const renderProductEdit = (productMode) => {
    switch (productMode) {
      case "EDIT":
        return (
            <AdminSellerProductEditor
                setState={(value, field) => setProductState({
                  ...state,
                  selectedProduct: {
                    ...selectedProduct,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                userDetails={userDetails}
                isLoading={isLoadingProducts}
                productdetail={productdetail}
                productshops={productshops}
                selectedProduct={selectedProduct}
                updateProductStatusClicked={updateProductStatus}
                adminUpdateProductVideoClicked={adminUpdateProductVideoClicked}
                messageShopClicked={messageShop}
                onCancel={() => setProductModeState("PREVIEW")}
            />
        );
      case "EDIT_SET":
      default:
        return (
            <AdminSellerProductEditorSetting
                setState={(value, field) => setProductState({
                  ...state,
                  selectedProduct: {
                    ...selectedProduct,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                userDetails={userDetails}
                isLoading={isLoadingProducts}
                currency={currency}
                productdetail={productdetail}
                productshops={productshops}
                selectedProduct={selectedProduct}
                history={history}
                updateProductCategory={updateProductCategory}
                updateProductClicked={updateProductInfoAdmin}
                runCategorySearch={runCategorySearch}
                optionValuesCategory={optionValuesCategory}
                onCancel={() => setProductModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedProduct = {} } = productState;
  const { records } = products;

  return (
      <Container>
        {productModeState === "PREVIEW" ? (
            renderSearchModule()
        ) : (
            renderProductEdit(productModeState)
        )}
      </Container>
  );


};

AdminProductSearch.defaultProps = {
  currency: "",
};

AdminProductSearch.propTypes = {
  currency: PropTypes.string,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  products: PropTypes.arrayOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
    commonCategoryList: PropTypes.objectOf(PropTypes.any).isRequired,
  adminSearchProducts: PropTypes.func.isRequired,
  adminSearchProductKeywords: PropTypes.func.isRequired,
  adminResetSearchKeywords: PropTypes.func.isRequired,
  adminResetSearchProducts: PropTypes.func.isRequired,
    adminGetProductAndShopsInfo: PropTypes.func.isRequired,
    adminUpdateStatusPrivateProduct: PropTypes.func.isRequired,
    adminUpdateProductCategory: PropTypes.func.isRequired,
    adminSendMessage: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminProductSearchComponent = connect(
  ({ adminProducts, bootupInfo, adminShops }) => ({
    bootupInfo,
      cities: bootupInfo.cities,
      userDetails: bootupInfo.userDetails,
    currency: bootupInfo.symbol,
    reqStatus: adminProducts.reqStatus,
    isLoadingProducts: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
    products: adminProducts.searchproducts,
    searchkeywords: adminProducts.searchkeywords,
      searchcategorykeywords: adminProducts.searchcategorykeywords,
      commonCategoryList: adminProducts.categoryList,
      productshopsdetail: adminProducts.productshopsdetail,
      shopDetail: adminShops.shopDetail,
    isLoadingShop: adminShops.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminProductSearch);

export { AdminProductSearchComponent as default };
