/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import ButtonComponent from "components/Button";


const HeaderBack = (props) => {

    const {
        onCancel, history
    } = props;

  const [state] = useState({
    layoutColumns: 3,
  });

    const handleBack = () => {
        history.goBack();
    };

    const handleHome = () => {
        history.push(`/`);
    };

    return (
        <Box mb={2} width="100%" display="flex">
            <Box width="100%" alignItems="left">
                <ButtonComponent
                    type="primary"
                    variant="contained"
                    name="< Back"
                    size="small"
                    onClick={onCancel ? onCancel : handleBack}
                />
            </Box>
            {/*
            <Box width="50%" display="flex" justifyContent="flex-end">
                <ButtonComponent
                    type="primary"
                    variant="contained"
                    name="Home"
                    size="small"
                    onClick={handleHome}
                />
            </Box>
            */}
        </Box>
    );
};

export default HeaderBack;
