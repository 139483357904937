import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {removeFromCart, removeProductFromCart} from '../actions';
import {getCartTotal} from '../services';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";

const LocationContainer = () => (
    <div>
        <Link  to='/city'>
            <IconButton color="primary" size="small">
                <LocationOnIcon />
            </IconButton>
        </Link>
    </div>
)


function mapStateToProps(state) {
    return {
        cartList: state.cartList.cart,
        total: getCartTotal(state.cartList.cart),
        symbol: state.bootupInfo.symbol,
    }
}

export default connect(mapStateToProps, {removeFromCart, removeProductFromCart})(LocationContainer);
