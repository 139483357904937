/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import AdminOrderSearch from "components/widgets/admin/AdminOrderSearch";
import "components/elements/main/index.scss";
import { checkUserAllowed } from "helpers/utils.js";

const AdminMainOrderSearch = (props) => {
  const [state] = useState({
    layoutColumns: 3,
  });

  useEffect(() => {
    props.adminResetSearchProducts();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

    const renderOrderSearch = () => {
        if (checkUserAllowed(props.bootupInfo.userDetails.userlevel, "orders")) {
            return <AdminOrderSearch {...props} colSize={state.layoutColumns} />;
        } else {
            return  <div>Not available </div>;
        }
    }

  return (
      <div>
      {
          renderOrderSearch()
      }
      </div>
  );
};

AdminMainOrderSearch.propTypes = {
  adminResetSearchProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminMainOrderSearchComponent = connect(
  ({ adminProducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminProducts.reqStatus,
    isLoading: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminMainOrderSearch);

export { AdminMainOrderSearchComponent as default };
