import React from 'react';
import ContentLoader from "react-content-loader";

const MockSalesFutureList = (props) => (
    <ContentLoader
        speed={2}
        width={400}
        height={600}
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    >
        <rect x="10" y="10" width="100%" height="100" />
        <rect x="10" y="120" width="100%" height="100" />
        <rect x="10" y="230" width="100%" height="100" />
        <rect x="10" y="340" width="100%" height="100" />
    </ContentLoader>
)

export default MockSalesFutureList;
