
import React, { ReactNode } from 'react'
import { Box, Container } from '@mui/material'
import CustomSectionHeader from './CustomSectionHeader'

const CustomSectionCreator = ({ icon, seeMoreLink, seeLink, seeMoreLinkTitle, title, isSubtle, children }) => (
    <Box mb={7.5}>
        <Container sx={{ pb: '0.4rem' }}>
            {title && (
                <CustomSectionHeader
                    title={title}
                    isSubtle={isSubtle}
                    seeMoreLink={seeMoreLink}
                    seeLink={seeLink}
                    seeMoreLinkTitle={seeMoreLinkTitle}
                    icon={icon}
                />
            )}

            {children}
        </Container>
    </Box>
)

export default CustomSectionCreator
