import React, {useState} from 'react'
import {Box, Grid, IconButton,} from "@mui/material";
import {FlexBox, FlexRowCenter} from "components/flex-box";
import LazyImage from "components/LazyImage";
import {getImage, getImageFromURL, getLocalDate, openGoogleImageSearch, openGoogleMapLocation} from "helpers/utils";
import BazaarAvatar from "components/BazaarAvatar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";

const ImageAlbumAdmin = ({images, showLink}) => {

    const [selectedImage, setSelectedImage] = useState(0);

    const handleImageClick = (ind) => () => {
        setSelectedImage(ind);
    };

    return (
        <Grid item md={6} xs={12} alignItems="center">
            <FlexBox overflow="auto">
                {images && images.map((url, ind) => (
                    <FlexRowCenter
                        key={ind}
                        width={64}
                        height={64}
                        minWidth={64}
                        bgcolor="white"
                        border="1px solid"
                        borderRadius="10px"
                        ml={ind === 0 ? "auto" : 0}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={handleImageClick(ind)}
                        mr={ind === images.length - 1 ? "auto" : "10px"}
                        borderColor={
                            selectedImage === ind ? "primary.main" : "grey.400"
                        }
                    >
                        <BazaarAvatar src={getImageFromURL(url)} variant="square" height={40} />
                    </FlexRowCenter>
                ))}
            </FlexBox>
            <Box justifyContent="center">
                <Box>
                <LazyImage
                    width={400}
                    height={400}
                    loading="eager"
                    objectFit="contain"
                    src={getImage(images, selectedImage)}
                />
                </Box>
                <FlexBox overflow="auto">
                    <Box width="70%">
                        {showLink && images && images.length > 0 && <>
                            {getLocalDate(images[selectedImage].cdn.created_at, DATE_TIME_FORMAT_SHORT) }
                            {images[selectedImage].created_ip }
                            {images[selectedImage].location &&  <IconButton
                                size="small"
                                onClick={() => openGoogleMapLocation(images[selectedImage].location)}
                            >
                                <ArrowForwardIcon color="primary"/>
                            </IconButton>}
                        </>}
                    </Box>
                    <Box width="30%">
                        {showLink && <IconButton
                            size="small"
                            onClick={() => openGoogleImageSearch(getImage(images, selectedImage))}
                        >
                           Search <ArrowForwardIcon color="primary"/>
                        </IconButton>
                        }
                    </Box>
                </FlexBox>
            </Box>
        </Grid>
    )
}


export default ImageAlbumAdmin;
