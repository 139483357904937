/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { withTranslate } from "react-redux-multilingual";

import { BottomNavigation, BottomNavigationAction } from "components/Atoms";

import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import HomeIcon from "@mui/icons-material/Home";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import StorageIcon from "@mui/icons-material/Storage";
import {isShopSiteAndSubdomain, getURI} from "helpers/utils";

const BottomNavUser = (props) => {

  const {
    isShopPage, shopId, pathArray
  } = props;

  const [tab, setTab] = React.useState("home");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const selectedTab = () => {
    let link = isShopSiteAndSubdomain() ? pathArray[1] : pathArray[3];
    switch (link) {
      case "me":
        setTab("account");
        break;
      case "products":
        setTab("products");
        break;
      case "cart":
        setTab("cart");
        break;
      case "home":
      default:
        setTab("home");
        break;
    }
  }

  React.useEffect(() => {
    selectedTab();
  });

  return (
    <BottomNavigation
      showLabels
      style={{
        zIndex: 10, position: "fixed", bottom: 0, left: 0, right: 0, backgroundColor: "#fff", boxShadow: "0 -1px 1px 0 rgba(0,0,0,.1)",
      }}
      value={tab}
      onChange={handleChange}
    >
      <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, isShopPage ? "shop_home" : "home", shopId)} />
      <BottomNavigationAction label={process.env.REACT_APP_PRODUCT_TITLE} value="products" icon={<StorageIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, isShopPage ? "shop_products" : "products", shopId)} />
      <BottomNavigationAction label="Cart" value="cart" icon={<ShoppingCartRoundedIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, isShopPage ? "shop_cart" : "cart", shopId)} />
      <BottomNavigationAction label="Account" value="account" icon={<PersonRoundedIcon />} component={Link} to={getURI(process.env.REACT_APP_TYPE, isShopPage ? "shop_account" : "account", shopId)} />
    </BottomNavigation>
  );
};

BottomNavUser.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withTranslate(BottomNavUser);
