/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Container,
} from "@mui/material";

import {
  Button,
  Notification,
    Divider
} from "components/Atoms";
import { H6 } from 'components/CustomTypography';

import * as _ from "lodash";

import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import SellerOrder from "components/elements/seller/SellerOrder";
import ProfileCityEditor from "components/elements/core/ProfileCityEditor";
import MockProductList from "components/elements/main/MockProductList";

import { CURRENCY } from "config/constants";
import "./styles.scss";
import {getCity} from "../../../../helpers/utils";

const ProfileCity = (props) => {

  const [state, setState] = useState({ orderMode: "PREVIEW" });
  const [orderState, setOrderState] = useState("");

  const {
    serviceError, reqStatus, isLoading, cities, symbol = "$", bootupInfo = {},
    getCities, includestatus, getOrderDetail, orderdetail, updateOrderStatus, getCityLocations,
      history
  } = props;

  useEffect(() => {
    getCities();
  }, []);


  const editCity = () => {
    history.push('/');
    getCityLocations(getCity().id);
  };

  const handleOrderEdit = (mode, order, reset) => () => {
    setState({ orderMode: mode });
    if (reset) {
      setOrderState({
        selectedOrder: {
          order_id: "", id: "", status: "", order_type: "", user: "",
        },
      });
    } else {
      setOrderState({
        selectedOrder: {
          order_id: order.order_id, id: order._id, status: order.status, order_type: order.order_type, user: order.user,
        },
      });
    }
  };

  const handlePage = (page) => (e) => {
    props.history.push({
      pathname: page,
    });
  };

  const { orderMode } = state;
  const { selectedOrder = {} } = orderState;

  const renderNoResults = () => (
    <section className="cart-section section-b-space">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div>
              <div className="col-sm-12 empty-cart-cls text-center">
                <H6>No order found
                </H6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const renderResults = () => (
    cities.map((order) => (
      <SellerOrder
        className="product-item"
        key={order.id}
        order={order}
        editOrderStatus={handleOrderEdit("EDIT", order, false)}
      />
    ))
  );

  const renderOrderList = () => (
      <Container>
      {isLoading ? <MockProductList />
        : cities.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );

  const renderOrderEdit = (orderMode) => {
    switch (orderMode) {
      case "EDIT":
      default:
        return (
          <ProfileCityEditor
              setState={(value, field) => setOrderState({
                ...state,
                selectedOrder: {
                  ...selectedOrder,
                  [field]: value,
                },
              })}
              symbol={symbol}
              cities={cities}
              isLoading={isLoading}
              orderdetail={orderdetail}
              selectedOrder={selectedOrder}
            updateCityClicked={editCity}
            onCancel={() => setState({ orderMode: "PREVIEW" })}
          />
        );
    }
  };

  return (
    <Container>
      {renderOrderEdit()}
    </Container>
  );
};

ProfileCity.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  cities: [],
  includestatus: true,
};

ProfileCity.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  cities: PropTypes.arrayOf(PropTypes.object),
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getCities: PropTypes.func.isRequired,
};

const profileCity = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    cities: bootupInfo.cities,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileCity);

export default profileCity;
