import * as yup from "yup";

export const schema = yup.object().shape({
  title: yup.string().required("Name is required field"),
});

export const getTextfieldData = (title) => [
  {
    label: "Shop Name",
    value: title,
    onChangeField: "title",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
    /*
  {
    name: "Cancel",
    onClick: cancelEdit,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
     */
];
