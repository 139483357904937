/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */

import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Notification,
} from "components/Atoms";

import * as Actions from "actions/core/core.actions";
import * as ActionTypes from "constants/ActionTypes";

import StoreProfileWidget from "components/pages/seller/store/StoreProfileWidget";
import OrdersMain from "components/pages/seller/orders/OrdersMain";

import "./styles.scss";
import { Container } from "@mui/material";
import CustomSectionCreator from "components/CustomSectionCreator";

import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import StoreIcon from "@mui/icons-material/Store";
import { useEffect } from "react";
import PropTypes from "prop-types";

const SellerHome = (props) => {

  const {
    isLoading,
    userDetails,
    serviceError,
    reqStatus,
  } = props;

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  return (
      <Container>
        <CustomSectionCreator isSubtle={true} icon={<StoreIcon />} title="Shop" seeMoreLink="/shop" seeMoreLinkTitle="Update">
          <StoreProfileWidget {...props} />
        </CustomSectionCreator>
        <CustomSectionCreator isSubtle={true} icon={<ShoppingCartRoundedIcon />} title="New Orders" seeMoreLink="/orders/all" seeMoreLinkTitle="View Orders">
          <OrdersMain {...props} orderstatus="new" includestatus="false" title="New Orders"></OrdersMain>
        </CustomSectionCreator>
      </Container>
  );
}

SellerHome.defaultProps = {
  isLoading: false,
};

SellerHome.propTypes = {
  isLoading: PropTypes.bool,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const _Home = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SellerHome);

export { _Home as default };
