//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Notification,
  Pace,
} from "components/Atoms";

import * as Actions from "actions/seller/seller.store";
import * as ActionTypes from "constants/ActionTypes";

import TaxPreview from "components/elements/seller/TaxPreview";
import TaxEditor from "components/elements/seller/TaxEditor";

import "./styles.scss";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import CustomSectionCreator from "components/CustomSectionCreator";
import ViewListIcon from "@mui/icons-material/ViewList";

const StoreProfileTax = (props) => {
  const [state, setState] = useState({ serviceMode: "PREVIEW" });

  const {
    serviceError, reqStatus, isLoading, shopTax,
    getStoreTax, updateStoreTax,
  } = props;

  useEffect(() => {
    getStoreTax();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_STORE_TAX_SUCCESS:
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const { serviceMode, storeTax = {} } = state;

  const handleServiceEdit = (service) => {
    setState({ serviceMode: "EDIT" });
    setState({
      storeTax: service,
    });
  };

  const handleServiceEditCancel = (mode) => (e) => {
    setState({ serviceMode: mode });
    props.history.push({
      pathname: "/shop",
    });
  };

  const updateServiceCall = (service) => {
    setState({ serviceMode: "PREVIEW" });
    updateStoreTax(service);
  };

  return (
      <Container>
        {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
        <CustomSectionCreator isSubtle={true} icon={<ViewListIcon />} title="Tax">
          {serviceMode === "PREVIEW" ? (
              <TaxPreview
                  isLoading={isLoading}
                  service={shopTax}
                  editService={() => handleServiceEdit(shopTax)}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          ) : (
              <TaxEditor
                  setState={(value, field) => setState({
                    ...state,
                    storeTax: {
                      ...storeTax,
                      [field]: value,
                    },
                  })}
                  service={storeTax}
                  updateServiceCall={updateServiceCall}
                  cancelEdit={handleServiceEditCancel("PREVIEW")}
              />
          )}
        </CustomSectionCreator>
      </Container>
  );
};

StoreProfileTax.propTypes = {
  history: PropTypes.object.isRequired,
  shopTax: PropTypes.object.isRequired,
  updateStoreTax: PropTypes.func.isRequired,
  getStoreTax: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  serviceError: PropTypes.object.isRequired,
  reqStatus: PropTypes.object.isRequired,
};

// Mapping Props and State.
const storeProfileTax = connect(
  ({ store, appBase }) => ({
    appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    shopTax: store.storeTax,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(StoreProfileTax);

export { storeProfileTax as default };
