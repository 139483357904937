/* eslint-disable import/no-named-as-default-member */
/* eslint-disable linebreak-style */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import PageNotFound from "components/pages/core/404";
import PageServerError from "components/pages/core/500";
import Policy from "components/pages/core/Policy";
import Login from "components/pages/core/Login";
import LoginSocial from "components/pages/core/LoginSocial";
import ProfileHome from "components/pages/core/ProfileHome";
import ProfileCity from "components/pages/core/ProfileCity";
import ProfileName from "components/pages/core/ProfileName";
import ProfileImage from "components/pages/core/ProfileImage";
import ProfileAddresses from "components/pages/core/ProfileAddresses";

export const AppBaseRoutes = [
  { path: "/error", component: PageServerError, exact: true },
  { path: "/404", component: PageNotFound, exact: true },
  { path: "/policy", component: Policy, exact: true },
  { path: "/login/success", component: LoginSocial, exact: true },
  { path: "/me", component: ProfileHome, exact: true },
  { path: "/city", component: ProfileCity, exact: true },
  { path: "/me/profile", component: ProfileName, exact: true },
  { path: "/me/image", component: ProfileImage, exact: true },
  { path: "/me/address", component: ProfileAddresses, exact: true },
];

export const ShopMarketAppHomeRoutes = [
  { path: "/login", component: Login, exact: true },
];

export const AdminAppHomeRoutes = [
  { path: "/login", component: Login, exact: true },
];

export const SellerAppHomeRoutes = [
  { path: "/login", component: Login, exact: true },
];


