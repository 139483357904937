import * as React from "react";
import ButtonComponent from "components/Button";
import { Box, Typography } from "@mui/material";
import { H6 } from "components/CustomTypography";

const TaxPreview = ({
  isLoading, service, editService, cancelEdit,
}) => {
  const { is_tax_active, taxidnumber, taxpercentage } = service;

  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
        {!is_tax_active ? (
          <Typography>Tax (e.g. GSTN) is disabled</Typography>
        ) : (
          <Typography>
            <H6> Tax Identification (e.g. GSTN):</H6>
            {`${taxidnumber || ""}`}
            <br/>
            <H6>Tax percentage:</H6>
            {`${taxpercentage || ""}`}
          </Typography>
        )}
      </Box>
      <Box>
        {!isLoading
              && (
              <ButtonComponent
                sx={{
                  m: 2,
                }}
                variant="contained"
                type="primary"
                name="Edit"
                size="small"
                onClick={editService}
              />
              )}
      </Box>
    </Box>
  );
};

export default TaxPreview;
