/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Notification,
} from "components/Atoms";
import {
  Container, Avatar, Typography, Box, Card, IconButton,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ViewListIcon from "@mui/icons-material/ViewList";
import * as Actions from "actions/core/core.actions";

import "./styles.scss";
import {isMarketPlace} from "helpers/utils";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";

const UserProfileHome = (props) => {

  const {
    history, match
  } = props;


  const { shopId } = match.params;

  const handlePage = (page) => () => {
    history.push({
      pathname: page,
    });
  };

  return (
    <Container>
      <ShopHeaderNav history={history} />
      {isMarketPlace() &&
          <ShopHeaderMini {...props} />}
            <Box m={2}>
              <Card>
                <Box display="flex">
                  <Box width="80%">
                    <Typography className="cursor-pointer" onClick={handlePage( `/shop/${shopId}/me/orders`)}>
                      <IconButton size="large">
                        <ViewListIcon />
                      </IconButton>
                      Shop Orders
                    </Typography>
                  </Box>
                  <Box width="20%">
                    <Typography align="right">
                      <IconButton size="large">
                        <KeyboardArrowRightRoundedIcon />
                      </IconButton>
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          <Box m={2}>
            <Card>
              <Box display="flex">
                <Box width="80%">
                  <Typography className="cursor-pointer" onClick={handlePage(`/shop/${shopId}/me/wishlist`)}>
                    <IconButton size="large">
                      <FavoriteIcon />
                    </IconButton>
                    Favourites Products
                  </Typography>
                </Box>
                <Box width="20%">
                  <Typography align="right">
                    <IconButton size="large">
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>

    </Container>
  );
};

UserProfileHome.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userProfileHome = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProfileHome);

export { userProfileHome as default };
