/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Box, Card, IconButton, Button,
} from "@mui/material";
import {getDistance, getImage, getLogo} from "helpers/utils";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import "./UserSellerShop.scss";
import * as Actions from "actions";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {FlexBetween} from "components/flex-box";
import {H6, H2} from "components/CustomTypography";
import ArrowRight from '@mui/icons-material/ArrowRight'

const UserSellerShop = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const history = useHistory();

  const {
    shop = {}, viewShop, viewShopWithStory, isDirect, link
  } = props;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

    const handleShopButtonClick = () => {
        if (link) {
            history.push(`${link}`);
        } else {
            history.push(`/shop/${shop.id}`);
        }
    };

  return (
      <Box pb={3}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={isDirect ? handleShopButtonClick : viewShopWithStory}>
          <LazyImage
              src={getImage(shop.logo)}
              width="100%"
              alt={shop.title}
              mx="auto"
          />
        </HoverBox>
          <FlexBetween mt={0} onClick={isDirect ? handleShopButtonClick : viewShop}>
              <Box width="10%">
                  <ArrowRight fontSize="small" color="primary" />
              </Box>
              <Box width="90%">
                    <H2 textTransform="capitalize" fontSize={14} textAlign="left" title={shop.title} ellipsis>
                     {shop.title}
                    </H2>
                    <H6 fontSize={10} textAlign="left">
                        {shop.area}
                    </H6>
              </Box>
          </FlexBetween>
      </Box>
  );
};

export default UserSellerShop;
