import * as React from "react";
import ButtonComponent from "components/Button";
import Box from "@mui/material/Box";

import "./style.scss";

const UserProductStatusSelector = (props) => {

    const {
        onStatusSelect, productstatus
    } = props;

    return (
          <Box>
            <Box>
              <span style={{ paddingRight: "5px" }}>
              {!productstatus || productstatus === "popular" ?
                  <ButtonComponent type="primary" size="medium" name="Popular" variant="contained" onClick={() => onStatusSelect("popular")}></ButtonComponent>
                  :
                  <ButtonComponent type="primary" size="medium" name="Popular" variant="outlined" onClick={() => onStatusSelect("popular")}></ButtonComponent>
              }
              </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "new" ?
                    <ButtonComponent type="primary" size="medium" name="Latest" variant="contained" onClick={() => onStatusSelect("new")} ></ButtonComponent>
                    :
                    <ButtonComponent type="primary" size="medium" name="Latest" variant="outlined" onClick={() => onStatusSelect("new")} ></ButtonComponent>
                }
                </span>
            </Box>
          </Box>
    );
}

export default UserProductStatusSelector;
