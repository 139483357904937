import * as React from "react";
import {
    Box, Container } from "@mui/material";
import ButtonComponent from "components/Button";
import {
    schema,
} from "./helper";
import { getPrice, isMarketPlace} from "helpers/utils";


import {useState} from "react";
import MockProductBox from "components/elements/main/MockProductBox";
import {H2, H5, H6} from "components/CustomTypography";
import ImageAlbum from "components/elements/main/ImageAlbum";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopHeaderShopOverlay from "components/elements/user/shops/ShopHeaderShopOverlay";
import ShopHeaderOurCollection from "components/elements/user/shops/ShopHeaderOurCollection";
import MockProductImage from "components/elements/main/MockProductImage";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";

const UserProductDetail = ({
   selectedProduct, currency, onCancel, productdetail, isLoading, history, onAddToFavlistClicked,
                               userMainGetShopInfoById
}) => {

    const [share, setShare] = useState(false);

    const [state, setState] = useState({});

    let isModalSearch = false;
    const {product, shops} = productdetail;

    const {
     created_at: createdAt, _id: _id, status, message
  } = selectedProduct || {};

  const [blur, setblur] = React.useState({
    status: false,
  });


    const onAddToFav = () => {
        onAddToFavlistClicked(product.id);
    };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedProduct);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedProduct);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

    const renderProductInformation = () => (

        <Box>
            <H2 fontSize={18} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left" title={product.name} ellipsis>
                {product.name}
            </H2>
            <H5 fontSize={14} textAlign="left" ellipsis>
                {product.description}
            </H5>
        </Box>
    );

    const renderProductPhotos = () => (
        <Box>
            <ImageAlbum images={product.images} mainOnly={true} />
        </Box>
    );

    const renderProductBuyFromShop = () => (
        <Box>
            {shops.map((shop, index) => (
                <Box>
                    <ShopHeaderShopOverlay userMainGetShopInfoById={userMainGetShopInfoById} history={history} shopId={shop.id}/>
                </Box>
            ))}
        </Box>
    );

    const renderProductShopMoreInfo = () => (
        <Box>
            {shops.map((shop, index) => (
                <Box>
                    <Box onClick={() => history.push(`/shop/${shop.id}/products/${product.id}`)} alignContent="center" width="100%" display="flex" pt={3} pb={3}>
                        <Box width="70%" order={3}>
                            <H2 fontSize={20}>
                                {getPrice(shop, currency)}
                            </H2>
                        </Box>
                        <Box width="30%" order={4}>
                            <ButtonComponent
                                sx={{ fontSize: '18px' }}
                                type="primary"
                                variant="contained"
                                name="Buy"
                                size="small"
                                onClick={() => history.push(`/shop/${shop.id}/products/${product.id}`)}
                            />
                        </Box>
                    </Box>
                    <ShopHeaderOurCollection />
                    <ShopProductPopularSlider shopId={shop.id} />
                    <ShopProductNewSlider shopId={shop.id} />
                    <ShopOffersSlider shopId={shop.id} />
                </Box>
                ))}
        </Box>
    );


    return (
        <Container>
            <Container maxWidth="md">
                <ShopHeaderNav onCancel={onCancel} history={history} />

                {isLoading && <MockGeneralHeader /> }

                {/*!isLoading && renderProductInformation()*/}

                {!isLoading && renderProductBuyFromShop()}

                {isLoading && <MockProductImage />}
                {!isLoading && renderProductPhotos()}

                {!isLoading && renderProductShopMoreInfo()}
            </Container>
        </Container>
    );
};

export default UserProductDetail;
