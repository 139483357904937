import * as React from "react";


import "./SellerCategory.scss";
import {Box, Card, IconButton} from "@mui/material";

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H4, H5, H6 } from "components/CustomTypography";
import { getImage, getLocalDate, getDistance, checkUserAllowed } from "helpers/utils";
import { DATE_TIME_FORMAT_SHORT } from "config/constants";


const AdminSalesLead = (props) => {
  const { lead = {}, userDetails, editCategoryImage, editCategoryInfo, editCategorySettings, className = "" } = props;
  const { id, name, images = [], is_popular, is_active, store_entry } = lead;

  return (

      <Box pb={2}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={editCategoryImage}>
          <LazyImage
              src={getImage(lead.images)}
              width={150}
              height={150}
              alt={lead.name}
              mx="auto"
          />
        </HoverBox>
        <H5 textTransform="uppercase" fontSize={12} textAlign="left" title={lead.name} ellipsis>
            {lead.shopname}
        </H5>
          <H5 fontSize={10} textAlign="left" ellipsis>
              {lead.owner.name}
          </H5>
          <H5 fontSize={10} textAlign="left" ellipsis>
              {lead.leadstatus} {getLocalDate(lead.created_at, DATE_TIME_FORMAT_SHORT)}
          </H5>
        <H4 fontSize={12} textAlign="left">
          <IconButton color="primary" size="small" onClick={editCategoryInfo}>
            <MoreHorizIcon />
          </IconButton>
          {checkUserAllowed(userDetails.userlevel, "salesops") &&
            <IconButton color="primary" size="small" onClick={editCategorySettings}>
              <EditIcon/>
            </IconButton>
          }
        </H4>
          {lead.distance &&
              <H6 fontSize={10} textAlign="left" ellipsis>
              {getDistance(lead.distance)}
          </H6>
          }
      </Box>
  );
};

export default AdminSalesLead;
