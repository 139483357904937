/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { H5 } from "components/CustomTypography";
import { schema, getTextfieldData, getButtonData } from "./helper";

const SocialEditor = ({
  setState, storeSocial, updateSocialCall, cancelEdit,
}) => {
  const {
    _id, instahandle, facebookhandle, whatsapphandle
  } = storeSocial;

  const [blur, setblur] = React.useState({
    instahandle: false,
    facebookhandle: false,
    whatsapphandle: false,
  });

  const onFinish = () => {
    updateSocialCall({
      _id, instahandle, facebookhandle, whatsapphandle
    });
  };

  const handleChange = (event, field) => {
    setState(event, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(storeSocial);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.instahandle || blur.facebookhandle || blur.whatsapphandle );

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, storeSocial);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Box m={2}>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(instahandle, facebookhandle, whatsapphandle).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Box>
  );
};

SocialEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  storeSocial: PropTypes.object.isRequired,
  updateSocialCall: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default SocialEditor;
