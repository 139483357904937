/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserShopSearch from "components/widgets/user/UserShopSearch";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Actions from "actions";
import {Chip, Container, IconButton, Typography} from "@mui/material";
import MarketHeaderMini from "components/elements/user/shops/MarketHeaderMini";
import {isLocalMarket} from "helpers/utils";


const MarketUserShopPageSearch = (props) => {

    const {
        match, history,
        userMainResetSearchShops, bootupInfo
    } = props;

    const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : match.params.marketId);


    const [state] = useState({
        layoutColumns: 3,
    });

    useEffect(() => {
        userMainResetSearchShops();
        document.getElementById("color").setAttribute("href", "#");
    }, []);



    return (
        <div>
            {isLocalMarket() && <MarketHeaderMini />}
            <UserShopSearch {...props} marketId={marketId} />
        </div>
    );
};

MarketUserShopPageSearch.defaultProps = {
  isLoading: false,
};

MarketUserShopPageSearch.propTypes = {
    userResetSearchProducts: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

const marketUserShopPageSearch = connect(
  ({  bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(MarketUserShopPageSearch);

export default marketUserShopPageSearch;
