import * as React from "react";
import {
  Avatar,
  Box, Card, Container, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import TextFieldComponent from "components/TextField";
import {
   getButtonDataSendMsg, getButtonDataCancel, getTextfieldDataRejectMessage,
    schema,
} from "./helper";
import { DATE_FORMAT } from "config/constants";
import { getLocalDate, getTimeSlot } from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import { getImage } from "helpers/utils";


const AdminSellerOrderEditor = ({
  setState, selectedOrder, symbol, updateOrderClicked, onCancel, orderdetail, isLoading
}) => {

  const {
     created_at: createdAt, order_id: orderIdValue,
    order_type, order_id, _id, message
  } = selectedOrder || {};

    const {
        products, shipping,
        total_order, total_price, total_shipping, total_tax,
        delivery_date, delivery_time
    } = orderdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinishSendMsg = () => {
    updateOrderClicked({ order_id, _id, message: message });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOrder);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOrder);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

    const renderOrderInformation = () => (
        <Box p={2} width="100%" display="flex">
            <Box width="50%">
                <Typography className="styled-link" variant="subtitle2">
                    {`Order Id: ${orderIdValue}`}
                </Typography>
            </Box>
            <Box width="50%">
                <Typography className="styled-link" align="right" display="block" variant="subtitle2">
                    Date:
                    {" "}
                    {getLocalDate(createdAt)}
                </Typography>
            </Box>
        </Box>
    );

    const renderOrderItems = () => (
        <Box>
            <Card className="p-3">
                {products.map((cartItem, index) => (
                    <Card className="order-card" key={`${index + 1}`}>
                        <Box minHeight="100%" width="100%" display="flex">
                            <Box className="styled-card" width={window.innerWidth < 400 ? "17%" : "12%"}>
                                <Avatar variant="square" src={getImage(cartItem.product.images)} alt="" />
                            </Box>
                            <Box flexGrow={1}>
                                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                                    {cartItem.product.name}
                                </Typography>
                                <Typography display="block" variant="caption">
                                    {cartItem.quantity}
                                    {" "}
                                    x
                                    {symbol}
                                    {cartItem.seller_price}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant="caption">
                                    {symbol}
                                    {cartItem.quantity * cartItem.seller_price}
                                </Typography>
                            </Box>
                        </Box>
                    </Card>
                ))}
            </Card>
        </Box>
    );

    const renderShippingAddress = () => (
        <Card className="p-3">
            <Typography gutterBottom variant="h6">Delivery Address</Typography>
            <Typography display="block" variant="subtitle2">
                {shipping.name}
            </Typography>
            <Typography display="block" variant="subtitle2">
                {shipping.address}
                ,
                {shipping.city}
                ,
                {shipping.pincode}
            </Typography>
            <Typography display="block" variant="subtitle2">
                {shipping.phone}
            </Typography>
        </Card>
    );

    const renderShopPickup = () => (
        <Card className="p-3">
            <Typography variant="h6">Pickup</Typography>
            <Typography variant="subtitle2" display="block">
                {getLocalDate(delivery_date, DATE_FORMAT)}
                ,
                {getTimeSlot(delivery_time)}
            </Typography>
        </Card>
    );

    const renderOrderTotal = () => (
        <Card className="p-3">
            <Box display="flex">
                <Box width="50%">
                    <Typography variant="subtitle2" display="block">Subtotal: </Typography>
                </Box>
                <Box width="50%">
                    <Typography variant="subtitle2" display="block" align="right">
                        {symbol}
                        {total_price}
                    </Typography>
                </Box>
            </Box>
            <Box display="flex">
                <Box width="50%">
                    <Typography variant="subtitle2" display="block">Shipping: </Typography>
                </Box>
                <Box width="50%">
                    <Typography variant="subtitle2" display="block" align="right">
                        {symbol}
                        {total_shipping}
                    </Typography>
                </Box>
            </Box>
            <Box display="flex">
                <Box width="50%">
                    <Typography variant="subtitle2" display="block">Taxes: </Typography>
                </Box>
                <Box width="50%">
                    <Typography variant="subtitle2" display="block" align="right">
                        {symbol}
                        {total_tax}
                    </Typography>
                </Box>
            </Box>
            <hr className="solid" />
            <Box display="flex">
                <Box width="50%">
                    <Typography variant="subtitle2" display="block">Total price: </Typography>
                </Box>
                <Box width="50%">
                    <Typography variant="subtitle2" display="block" align="right">
                        {symbol}
                        {total_order}
                    </Typography>
                </Box>
            </Box>
        </Card>
    );

    const renderOrderDetail = () => (
        <Container>
            <Box display={window.innerWidth < 400 ? "" : "flex"}>
                <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                    {(order_type === "delivery" && shipping) ? renderShippingAddress() : renderShopPickup()}
                </Box>
            </Box>
            <Box>
                {renderOrderItems()}
            </Box>
            <Box>
                {renderOrderTotal()}
            </Box>
        </Container>
    );

    return (
        <Container>
            <Container maxWidth="md">
                <Container className="header-color">
                    {renderOrderInformation()}
                </Container>
                <Box mb={2}>
                    {
                        getButtonDataSendMsg(onFinishSendMsg).map((element) => (
                            <ButtonComponent
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        ))
                    },
                    {
                        getButtonDataCancel(onCancel).map((element) => (
                            <ButtonComponent
                                sx={{
                                    m: 2,
                                }}
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        ))
                    },
                    {
                        getTextfieldDataRejectMessage(message).map((element) => (
                            <Box className="textfield" m={2}>
                                <TextFieldComponent
                                    required
                                    label={element.label}
                                    value={element.value}
                                    error={!!getError(element.onChangeField)}
                                    helperText={getError(element.onChangeField)}
                                    onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                                />
                            </Box>
                        ))
                    }
                </Box>
                {isLoading ? <MockProductList /> : renderOrderDetail() }
            </Container>
        </Container>
    );
};

export default AdminSellerOrderEditor;
