/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";

import {Container, Box} from "@mui/material";
import ButtonComponent from "components/Button";
import {getDistance} from "helpers/utils";
import {H2} from "components/CustomTypography";
import AdminSalesFutureLogoEditor from "components/pages/admin/sales/AdminSalesFutureLogoEditor";
import CustomSectionCreator from "components/CustomSectionCreator";
import HeaderBack from "components/elements/main/HeaderBack";

const AdminSalesFutureDetail = ({ category, cancelEdit, adminAddQuickOfferClicked, adminUpdateShopLogoClicked }) => {

  const {
    shop_id, shopname, leadstatus, distance, logo
  } = category;


  return (
      <Container>
        <Box>
          <HeaderBack onCancel={() => cancelEdit()}/>
          {<CustomSectionCreator title="Update Shop Photo" /> }
          <Box>
            <H2 fontSize={18} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left" title={shopname} ellipsis>
              {shopname}
            </H2>
          </Box>

          <AdminSalesFutureLogoEditor
              key={2}
              logo={logo}
              shop_id={shop_id}
              cancelEdit={cancelEdit}
              adminUpdateShopLogoClicked={adminUpdateShopLogoClicked}
          />


        </Box>
      </Container>
  );
};


export default AdminSalesFutureDetail;
