import * as React from "react";
import ButtonComponent from "components/Button";
import { H6 } from "components/CustomTypography";
import { Box, Typography } from "@mui/material";

const AddressPreview = ({
  isLoading, address, editAddress, cancelEdit,
}) => {
  const {
    cityname, areaname, city, area, addressline, pincode,
  } = address;

  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
        <Typography>
          {`${addressline || ""}`}
        </Typography>
        <Typography>
          {`${area ? area : areaname || ""}`}
        </Typography>
        <Typography>
          {`${city ? city : cityname || ""} ${pincode || ""}`}
        </Typography>
      </Box>
      <Box>
        {!isLoading
          && (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            variant="contained"
            type="primary"
            size="small"
            name="Edit"
            onClick={editAddress}
          />
          )}
      </Box>
    </Box>
  );
};

export default AddressPreview;
