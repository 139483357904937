/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import {Container, Box, Typography, MenuItem} from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { schema, getTextfieldData, getButtonData, getSelectFieldData } from "./helper";
import SelectFieldComponent from "components/SelectField";

const AdminCommonCategoryEditor = ({
  setState, category, addUpdateCategoryToShopClicked, cancelEdit, mainCategoryOptions
}) => {

  const {
    id, name, description, parent_id
  } = category;

  const [blur, setblur] = React.useState({
    name: false,
    description: false,
    parent_id: false
  });

  const onFinish = () => {
    addUpdateCategoryToShopClicked({
      id, name, description, parent: parent_id
    });
  };

  const handleChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleSelectFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });

  };

  const hasError = () => {
    try {
      return !schema.validateSync(category);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.name || blur.description || blur.parent_id);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, category);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(name, description).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}

        {getSelectFieldData(parent_id).map((element) => (
            <Box className="textfield" m={2}>
              <SelectFieldComponent
                  label={element.label}
                  value={element.value}
                  options={mainCategoryOptions}
                  onChange={(event) => handleSelectFieldChange(event, element.onChangeField)}
              />
            </Box>
        ))}

      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            variant={element.variant}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
    </Container>
  );
};

AdminCommonCategoryEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  addUpdateCategoryToShopClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AdminCommonCategoryEditor;
