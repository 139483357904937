import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    latestoffers: [],
    offerdetail: {
    },
};

export default function offerReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_GET_LATEST_OFFERS_START:
        case ActionTypes.USER_GET_OFFER_DETAIL_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_GET_LATEST_OFFERS_FAILURE:
        case ActionTypes.USER_GET_OFFER_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.USER_GET_LATEST_OFFERS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                latestoffers: action.payload.offers };

        case ActionTypes.USER_GET_OFFER_DETAIL_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                offerdetail: action.payload
            };

        default:
    }
    return state;
}
