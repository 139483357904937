/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import { getImage, getPrice, getLocalDate } from "helpers/utils.js";
import {Box, Container} from "@mui/material";
import MockProductBox from "components/elements/main/MockProductBox";
import LazyImage from "components/LazyImage";

import {H2, H5} from "components/CustomTypography";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";
import ButtonComponent from "components/Button";

const AdminShopProductDetail = (props) => {

    const {
        match, productshopsdetail,
        adminGetProductAndShopsInfo, isLoading, history, currency,
    } = props;

    const { prodId } = match.params;

    const [state] = useState({
        layoutColumns: 3,
    });

    useEffect(() => {
        adminGetProductAndShopsInfo(prodId);
    }, []);

    const {productdetail, productshops} = productshopsdetail;


    const renderProductDetail = () => (
        <Box display={window.innerWidth < 400 ? "" : "flex"}>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>


                <H5 fontSize={14} textAlign="left" ellipsis>
                    Added on: {getLocalDate(productdetail.created_at, DATE_TIME_FORMAT_SHORT)}
                </H5>
                <H5 fontSize={14} textAlign="left" ellipsis>
                    Approved (Marketplace): {productdetail.is_verified ? "Yes" : "No"}
                </H5>
            </Box>
        </Box>
    );

    const renderProductShops = () => (
        <Box mb={2}>
            {productshops.map((shop, index) => (
                <Box key={`${index + 1}`}>
                    <H2 sx={{ textTransform: "capitalize" }} fontSize={16} textAlign="left" title={shop.title} ellipsis>
                        {shop.title}
                    </H2>
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Price: {getPrice(shop, currency)} {shop.is_price_visible ? "(Visible)" : "(Hidden)"}
                    </H5>
                    <H5 fontSize={14} textAlign="left" ellipsis>
                        Status: {shop.is_listing_ready ? "Ready for Sale" : "Not Ready"}
                    </H5>
                    <H5 fontSize={14} textAlign="left">
                        Enabled (Owner): {productdetail.is_active ? "Yes" : "No"}
                    </H5>
                    <H5 fontSize={14} textAlign="left">
                        Popular (Owner): {shop.is_popular ? "Yes" : "No"}
                    </H5>
                </Box>
            ))}
        </Box>
    );

    const renderProductInformation = () => (
        <Box mb={2}>
            <H2 sx={{ textTransform: "capitalize" }} textAlign="left" title={productdetail.name} ellipsis>
                {productdetail.name}
            </H2>
        </Box>
    );

    const renderProductPhotos = () => (
        <Box>
            <Box width="100%" display="flex">
                <LazyImage
                    src={getImage(productdetail.images)}
                    width={150}
                    height={150}
                    alt={productdetail.name}
                    mx="auto"
                />
            </Box>
        </Box>
    );

    return (
        <Container>
            <Container maxWidth="md">
                <Box mb={2}>
                    <ButtonComponent
                        type="primary"
                        variant="outlined"
                        name="< Back"
                        size="small"
                        onClick={() => history.goBack()}
                    />
                </Box>
                {isLoading && <MockProductBox />}
                {!isLoading && renderProductInformation()}
                {!isLoading && renderProductDetail()}
                {!isLoading && renderProductShops()}
                {!isLoading && renderProductPhotos()}
            </Container>
        </Container>
    );
};

AdminShopProductDetail.defaultProps = {
  isLoading: false,
    currency: "",
};

AdminShopProductDetail.propTypes = {
    currency: PropTypes.string,
    adminGetProductAndShopsInfo: PropTypes.func.isRequired,
    productshopsdetail: PropTypes.arrayOf(PropTypes.object).isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

const adminShopProductDetail = connect(
  ({  bootupInfo, adminProducts }) => ({
    bootupInfo,
      currency: bootupInfo.symbol,
    reqStatus: adminProducts.reqStatus,
    isLoading: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
      productshopsdetail: adminProducts.productshopsdetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopProductDetail);

export default adminShopProductDetail;
