import * as React from "react";

import { H6, SpanInline } from 'components/CustomTypography';
import CustomImageBox from 'components/CustomImageBox';

import "./SellerCategory.scss";
import {Box, Card, IconButton} from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HoverBox from "../../../HoverBox";
import LazyImage from "../../../LazyImage";
import {getImage} from "../../../../helpers/utils";
import {FlexBetween, FlexBox} from "../../../flex-box";
import {H4} from "../../../Typography";
import BazaarButton from "../../../BazaarButton";
import {Edit} from "@mui/icons-material";


const SellerCategory = (props) => {
  const { category = {}, editCategoryImage, editCategoryInfo, editCategorySettings, className = "" } = props;
  const { id, name, image = [], is_popular, is_active, store_entry } = category;

  const galleryInput = image.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });



  return (
      <Box pb={2} >
        <HoverBox onClick={editCategoryImage} mb={0} mx="auto" borderRadius="2px">
          <LazyImage
              src={getImage(category.image)}
              width={150}
              height={150}
              alt={category.name}
              mx="auto"
          />
        </HoverBox>
        <FlexBetween mt={2}>
          <Box width="80%" noWrap sx={{ overflow: 'hidden' }}>
            <H4  width="100%" textAlign="left" fontWeight="600" fontSize="14px" mb={0.5} title={category.name} >
              {category.name}
            </H4>
              <H4  width="100%" textAlign="left" fontWeight="600" fontSize="14px" mb={0.5} >

              </H4>
          </Box>

          <Box width="20%">
            <BazaarButton
                disableRipple
                disableElevation
                onClick={editCategoryInfo}
                sx={{
                  height: "0",
                  alignItems: "flex-start",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
            >
              <Edit color="primary" fontSize="small"/>
            </BazaarButton>
            <BazaarButton
                disableRipple
                disableElevation
                onClick={editCategorySettings}
                sx={{
                  height: "0",
                  alignItems: "flex-start",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
            >
              <Edit color="primary" fontSize="small" />
            </BazaarButton>
          </Box>
        </FlexBetween>
      </Box>
  );
};

export default SellerCategory;
