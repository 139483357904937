import { styled } from "@mui/material";
import clsx from "clsx";
import { Link } from 'react-router-dom';
//import { useRouter } from "next/router"; - sandeep
import React from "react"; // component props interface

// styled component
const StyledLink = styled("a")(({ theme, active_route }) => ({
  position: "relative",
  transition: "color 150ms ease-in-out",
  color: active_route === "active" ? theme.palette.primary.main : "inherit",
  "&:hover": {
    color: `${theme.palette.primary.main} !important`,
  },
}));

const NavLink = ({ href, children, style, className, ...props }) => {
 // const { pathname } = useRouter();
  const { pathname } = window.location.pathname;

  const checkRouteMatch = () => {
    if (href === "/") return pathname === href;

    return pathname === href;
  //  return pathname.includes(href);
  }; // active route

  const currentRoute = checkRouteMatch();
  return (
      <StyledLink
        href={href}
        style={style}
        className={clsx(className)}
        active_route={currentRoute ? "active" : ""}
        {...props}
      >
        {children}
      </StyledLink>
  );
};

export default NavLink;
