/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Link, useHistory} from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {getImage, getPrice} from "helpers/utils";


import "./UserProductItem.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {FlexBetween} from "components/flex-box";
import { H2, H6 } from "components/CustomTypography";
import ArrowRight from "@mui/icons-material/ArrowRight";


const UserProductItem = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });

    const history = useHistory();

  const {
    product, viewProduct, viewProductWithStory, onAddToWishlistClicked, isDirect, currency, link
  } = props;

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };

  const vegIconUrl = `${process.env.PUBLIC_URL}/assets/images/GreenIcon.png`;
  const nonVegIconUrl = `${process.env.PUBLIC_URL}/assets/images/RedIcon.png`;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

    const handleProductButtonClick = () => {

        if (link) {
            history.push(`${link}`);
        } else {
            history.push(`/products/${product.id}`);
        }
    };

  return (
      <Box pb={3}>
          <HoverBox mb={0} mx="auto" borderRadius="2px" onClick={isDirect ? handleProductButtonClick : viewProductWithStory}>
              <LazyImage
                  src={getImage(product.images)}
                  width="100%"
                  alt={product.name}
                  mx="auto"
              />
          </HoverBox>
          <FlexBetween mt={0} onClick={isDirect ? handleProductButtonClick : viewProduct}>
              <Box width="10%">
                  <ArrowRight fontSize="small" color="primary" />
              </Box>
              <Box width="90%">
                  <H2 fontSize={14} textAlign="left" ellipsis>
                      {getPrice(product, currency)}
                  </H2>
                  <H6 textTransform="uppercase" fontSize={10} textAlign="left" ellipsis>
                      {product.title}
                  </H6>
                  <H6 textTransform="capitalize" fontSize={10} textAlign="left" title={product.area} ellipsis>
                      {product.area}
                  </H6>
              </Box>
          </FlexBetween>
      </Box>
  );
};

UserProductItem.defaultProps = {

};

UserProductItem.propTypes = {
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
  onAddToWishlistClicked: PropTypes.func.isRequired,
  symbol: PropTypes.string,
};

const userProductItem = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    productprices: userproducts.productprices,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductItem);

export default userProductItem;
