// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";

const querystring = require('querystring');


function* userMainGetWishlistProducts(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      market: action.marketId ? action.marketId : ""
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;
    const response = yield API.get(
        `/users/products/wishlist/get-wishlist` + query,
        { withCredentials: true }
    );
    yield put(Actions.userMainGetWishlistProductsSuccess(response));
  } catch (error) {
    yield put(Actions.userMainGetWishlistProductsFailure(error));
  }
}

function* userMainAddProductToWishlist(action: Object) {
  try {
    const response = yield API.post(
        `users/products/wishlist/add-product`,
        { "product_id" : action.prodId},
        { withCredentials: true }
    );
    yield put(Actions.userMainAddProductToWishlistSuccess(response, action.prodId));
  } catch (error) {
    yield put(Actions.userMainAddProductToWishlistFailure(error));
  }
}

function* userMainRemoveProductFromWishlist(action: Object) {
  try {
    const response = yield API.post(
        `users/products/wishlist/remove-product`,
        { "product_id" : action.payload.product.id},
        { withCredentials: true }
    );
    yield put(Actions.userMainRemoveProductFromWishlistSuccess(response, action.payload.product.id));
  } catch (error) {
    yield put(Actions.userMainRemoveProductFromWishlistFailure(error));
  }
}

export default function* WishListSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_START,     userMainGetWishlistProducts);
  yield takeLatest(ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_START,        userMainAddProductToWishlist);
  yield takeLatest(ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_START,   userMainRemoveProductFromWishlist);

}
