/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const ButtonComponent = ({
  variant, type, size, onClick, name, disabled, ...rest
}) => (
  <Button
    variant={variant}
    color={type}
    size={size}
    onClick={onClick}
    disabled={disabled}
    {...rest}
  >
    {name}
  </Button>
);

ButtonComponent.defaultProps = {
  variant: "contained",
  type: "primary",
  disabled: false,
  size: "large",
};

ButtonComponent.propTypes = {
  variant: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default ButtonComponent;
