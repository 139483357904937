import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    Notification, Pace,
} from "components/Atoms";

import * as Actions         from "actions";

import Images               from "components/elements/main/Images";
import CameraButton         from "components/elements/main/CameraButton";
import FileButton from "components/elements/main/FileButton";

import {Box, Container, Typography} from "@mui/material";
import { H4, H5 } from "components/CustomTypography";

import {dataURIToBlob, resizeFile} from "services/utils";
import {useEffect, useState} from "react";
import PhotoUpload from "components/PhotoUpload";

const AdminCommonCategoryImageMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, category, cancelEdit,
        adminRemoveCommonCategoryImage, adminAddCommonCategoryImage,
        categoryImageList
    } = props;

    const {id, name, image} = category;

    //const [categoryState, setCategoryState] = useState(id);
    //const [imageState, setImageState] = useState(image);

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        category.image = categoryImageList.image;
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        adminAddCommonCategoryImage(category.id, formData);
    }

    const filter = (id) => {
    return image.filter(image => image.cdn.public_id !== id)
  }

    const removeImageCallback = (id) => {
        category.image = category.image.filter(image => image.cdn.public_id !== id);
        adminRemoveCommonCategoryImage(category.id, id);
    }

  const onError = (id) => {
      //setImageState(filter(id));
  }


    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={true}
                             images={image}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );

};

// Mapping Props and State.
const adminCommonCategoryImageMain = connect(
    ({ adminProducts, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: adminProducts.reqStatus,
      isLoading: adminProducts.isLoading,
      serviceError: adminProducts.serviceError,
      categoryImageList: adminProducts.categoryImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(AdminCommonCategoryImageMain);

export { adminCommonCategoryImageMain as default };
