import * as React from "react";
import ButtonComponent from "components/Button";
import { H6 } from "components/CustomTypography";
import { Box, Typography } from "@mui/material";

const SocialPreview = ({
  isLoading, social, editSocial, openSocialInsta, openSocialFB, openSocialWA, cancelEdit,
}) => {
  const {
    instahandle, facebookhandle, whatsapphandle
  } = social;

  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
        <Typography>
            { instahandle && <ButtonComponent
                sx={{
                    m: 2,
                }}
                variant="contained"
                type="primary"
                size="small"
                name="Open Instagram"
                onClick={openSocialInsta}
            />}
            {`${instahandle || "No Instagram"}`}
        </Typography>
        <Typography>
            { facebookhandle && <ButtonComponent
                sx={{
                    m: 2,
                }}
                variant="contained"
                type="primary"
                size="small"
                name="Open Facebook"
                onClick={openSocialFB}
            />}
            {`${facebookhandle || "No Facebook"}`}
        </Typography>
          <Typography>
              { whatsapphandle && <ButtonComponent
                  sx={{
                      m: 2,
                  }}
                  variant="contained"
                  type="primary"
                  size="small"
                  name="Open WhatsApp"
                  onClick={openSocialWA}
              />}
              {`${whatsapphandle || "User Mobile #"}`}
          </Typography>
      </Box>
      <Box>
        {!isLoading
          && (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            variant="contained"
            type="primary"
            size="small"
            name="Edit"
            onClick={editSocial}
          />
          )}
      </Box>
    </Box>
  );
};

export default SocialPreview;
