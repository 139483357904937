//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { handleExternalPage } from "services/utils";

import {
  Notification,
} from "components/Atoms";
import {Avatar, Box} from "@mui/material";

import { H2, H5, H4 } from 'components/CustomTypography';
import ButtonComponent from "components/Button";

import * as Actions       from "actions/seller/seller.store";

import "./styles.scss";
import {useEffect, useState} from "react";


const StoreProfileWidget = (props) => {

  const {
    isLoading, reqStatus, serviceError, storeProfile, storeDomain,
    getStoreProfile, getDomain
  } = props;

  const [copySuccess, setCopySuccess] = useState('Copy to share!');

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess(err);
    }
  };

  /*
  function copy(text) {
    return new Promise((resolve, reject) => {
      if (typeof navigator !== "undefined" && typeof navigator.clipboard !== "undefined" && navigator.permissions !== "undefined") {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.permissions.query({name: "clipboard-write"}).then((permission) => {
          if (permission.state === "granted" || permission.state === "prompt") {
            navigator.clipboard.write(data).then(resolve, reject).catch(reject);
          }
          else {
            reject(new Error("Permission not granted!"));
          }
        });
      }
      else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        textarea.style.width = '2em';
        textarea.style.height = '2em';
        textarea.style.padding = 0;
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';
        textarea.style.background = 'transparent';
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          document.body.removeChild(textarea);
          resolve();
        }
        catch (e) {
          document.body.removeChild(textarea);
          reject(e);
        }
      }
      else {
        reject(new Error("None of copying methods are supported by this browser!"));
      }
    });

  }
  */

  useEffect(() => {
    getStoreProfile();
    getDomain();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);


  const handlePage = (page) => (e) => {
    this.props.history.push({
      pathname: page
    });
  }

  const url = (storeDomain.value.domain && storeDomain.value.subdomain) ? "https://" + storeDomain.value.subdomain + "." + storeDomain.value.domain : "";

  return (
      <Box>
        <H2 fontSize={18}>{storeProfile.title}</H2>
        {!isLoading && <a href="#" onClick={handleExternalPage(url)}>{`${url || ""}`}</a>}
        {!isLoading && (<div>
          <ButtonComponent
              sx={{
                mt: 2,
                mb: 2,
              }}
              type="primary"
              variant="contained"
              name="Visit Your Online Shop"
              size="small"
              onClick={handleExternalPage(url)}
          />&nbsp; &nbsp;
          <ButtonComponent variant="contained"
                                   type="primary"
                                   name={copySuccess}
                                   size="small"
                                   onClick={() => copyToClipBoard(url)}
          />&nbsp; &nbsp;
          <ButtonComponent variant="contained"
                           type="primary"
                           name="Contact Us!"
                           size="small"
                           onClick={handleExternalPage("https://wa.me/" + storeProfile.support_contact + "?text=Hi")} />
          <br /><H5 fontSize={16}>PIN: {storeProfile.account_pin}</H5></div>)
        }

        { (storeProfile.urlqrcode && storeProfile.urlqrcode.length > 0) &&
          <Avatar
              variant="square"
              sx={{height: '150px', width: '150px', marginTop: '10px'}}
              src={storeProfile.urlqrcode[0].cdn.secure_url}/>
        }

      </Box>
  );
}
// Mapping Props and State.
const storeProfileWidget = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
    storeDomain: store.storeDomain,
    storeAddress: store.storeAddress,
    storeImages: store.storeImages,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileWidget);

export { storeProfileWidget as default };
