/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Link, useHistory} from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {getDistance, getImage, getImageOrLogo, getURI} from "helpers/utils";


import "./UserOfferItem.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import { H2, H6 } from "components/CustomTypography";


const UserOfferListItem = (props) => {
  const [state, setState] = useState("");

    const history = useHistory();

  const {
      offer
  } = props;


    const handleOfferButtonClick = () => {
        history.push(getURI(process.env.REACT_APP_TYPE, "offer", "", "", offer.offer_id));
    };

    return (
        <Box className="styled-card" width="100%" display="flex" p={1}
             onClick={handleOfferButtonClick}>
            <Box width="20%" order={1}>
                <Typography align="center">
                    <Link
                        className="styled-link"
                        to={getURI(process.env.REACT_APP_TYPE, "offer", "", "", offer.offer_id)}
                    >
                        <img
                            alt=""
                            className="img-product"
                            src={getImageOrLogo(offer.images, offer.logo)}
                        />
                    </Link>
                </Typography>
            </Box>
            <Box width="80%" order={1} ml={2}>
                <Typography align="left">
                    <H2 mb={2} fontSize={16}  ellipsis>
                        {offer.name}
                    </H2>
                    <H6 fontSize={14}>
                        {offer.title} {getDistance(offer.distance)}
                    </H6>
                </Typography>
                <Typography align="left">
                    <H6 mt={1} fontSize={12}>
                        {offer.description}
                    </H6>
                </Typography>
            </Box>
        </Box>
    );
};

UserOfferListItem.defaultProps = {

};

UserOfferListItem.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
};

const userOfferListItem = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOfferListItem);

export default userOfferListItem;
