import { SvgIcon } from "@mui/material";
import React from "react";

const BabyBoy = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path d="M256,416a24,24,0,1,0,24,24A24.027,24.027,0,0,0,256,416Zm0,32a8,8,0,1,1,8-8A8.009,8.009,0,0,1,256,448Z" />
      <path d="M432,288a64,64,0,1,0-11.642-126.934A176.884,176.884,0,0,0,311.914,57.108,7.937,7.937,0,0,0,312,56V24a8,8,0,0,0-16,0V52.588q-7.87-1.836-16-2.945V24a8,8,0,0,0-16,0V48.189c-2.653-.119-5.318-.189-8-.189s-5.347.07-8,.189V24a8,8,0,0,0-16,0V49.643q-8.121,1.113-16,2.945V24a8,8,0,0,0-16,0V56a7.937,7.937,0,0,0,.086,1.108A176.884,176.884,0,0,0,91.642,161.066a64,64,0,1,0,0,125.868,176.4,176.4,0,0,0,37.121,58.545A135.783,135.783,0,0,0,72,456v32a8,8,0,0,0,8,8H432a8,8,0,0,0,8-8V456a135.776,135.776,0,0,0-56.763-110.521,176.4,176.4,0,0,0,37.121-58.545A64.266,64.266,0,0,0,432,288Zm0-112a48,48,0,1,1-6.532,95.55,174.671,174.671,0,0,0,5.577-29.192l3.3,3.3a8,8,0,1,0,11.314-11.314L435.313,224H456a8,8,0,0,0,0-16H431.265a174.782,174.782,0,0,0-5.8-31.55A48.379,48.379,0,0,1,432,176ZM216,69.062V96a8,8,0,0,0,16,0V65.8a160.113,160.113,0,0,1,16-1.6V96a8,8,0,0,0,16,0V64.2a160.113,160.113,0,0,1,16,1.6V96a8,8,0,0,0,16,0V69.062C364.931,86.869,416,149.582,416,224v0c0,88.223-71.777,160-160,160S96,312.225,96,224v0C96,149.583,147.069,86.869,216,69.062ZM343.99,376.379a31.987,31.987,0,0,1-56.049,20.708A174.763,174.763,0,0,0,343.99,376.379ZM224.059,397.087a31.987,31.987,0,0,1-56.049-20.708A174.763,174.763,0,0,0,224.059,397.087ZM80,272a48,48,0,1,1,6.532-95.55,174.782,174.782,0,0,0-5.8,31.55H56a8,8,0,0,0,0,16H76.687L66.343,234.343a8,8,0,0,0,11.314,11.314l3.3-3.3a174.671,174.671,0,0,0,5.577,29.192A48.379,48.379,0,0,1,80,272ZM424,456v24H88V456a119.183,119.183,0,0,1,52.562-99.256q5.955,5.185,12.37,9.828a48,48,0,0,0,88.961,32.862q6.982.556,14.107.566t14.107-.566a48,48,0,0,0,88.961-32.862q6.405-4.643,12.37-9.828A119.183,119.183,0,0,1,424,456Z" />
      <path d="M184,256a24,24,0,1,0-24-24A24.027,24.027,0,0,0,184,256Zm0-32a8,8,0,1,1-8,8A8.009,8.009,0,0,1,184,224Z" />
      <path d="M328,256a24,24,0,1,0-24-24A24.027,24.027,0,0,0,328,256Zm0-32a8,8,0,1,1-8,8A8.009,8.009,0,0,1,328,224Z" />
      <path d="M256,296a32.036,32.036,0,0,0,32-32,8,8,0,0,0-16,0,16,16,0,0,1-32,0,8,8,0,0,0-16,0A32.036,32.036,0,0,0,256,296Z" />{" "}
    </SvgIcon>
  );
};

export default BabyBoy;
