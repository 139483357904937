// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/admin/admin.shops";

const querystring = require('querystring');

function* adminGetLocationShopList(action: Object) {
  try {

    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }

    const shops = yield API.get(`/admin/shop/get-shops`, { params: query });
    yield put(Actions.adminGetLocationShopListSuccess(shops));
  } catch (error) {
    yield put(Actions.adminGetShopLocationListFailure(error));
  }
}

function* adminSearchShops(action: Object) {

  try {

    let locationquery = {};

    if (action.searchtype === "nearby") {
      if (navigator.geolocation) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        locationquery = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    if (action.location) {
      locationquery = {
        lat: action.location.coordinates[1],
        lng: action.location.coordinates[0],
      };
    }

    const searchQuery = querystring.stringify({
      searchtype: action.searchtype,
      name: action.name,
      startdate: action.startdate ? action.startdate : "",
      filter: action.filter ? action.filter : "",
      sort: action.sort ? action.sort : "",
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
      exec: action.executive ? action.executive : "",
      city: action.city ? action.city : "",
      offset: action.offset ? action.offset : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/shop/get-shops` + query
    );
    yield put(Actions.adminSearchShopsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchShopsFailure(error));
  }
}

function* adminSearchShopKeywords(action: Object) {
  try {

    let locationquery = {};

    if (action.searchtype === "nearby" || action.searchtype === "mynearby") {
      if (navigator.geolocation) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        locationquery = {
          lat: latitude,
          lng: longitude,
        };
      }
    }

    if (action.searchtype === "exact" && action.location) {
      locationquery = {
        lat: action.location.coordinates[1],
        lng: action.location.coordinates[0],
      };
    }

    const searchQuery = querystring.stringify({
      searchtype: action.searchtype,
      name: action.name,
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/shop/get-keywords` + query
    );
    yield put(Actions.adminSearchShopKeywordsSuccess(response));
  } catch (error) {
    yield put(Actions.adminSearchShopKeywordsFailure(error));
  }
}

function* adminGetShopInfoById(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId });
    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/admin/shop/get-shopinfo`+ query
    );
    yield put(Actions.adminGetShopInfoByIdSuccess(response));
  } catch (error) {
    yield put(Actions.adminGetShopInfoByIdFailure(error));
  }
}

function* adminUpdateShopStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-status`,
        action.payload
    );
    yield put(Actions.adminUpdateShopStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopStatusFailure(error));
  }
}

function* adminUpdateShopProfile(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-profile`,
        action.payload
    );
    yield put(Actions.adminUpdateShopProfileSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopProfileFailure(error));
  }
}

function* adminUpdateShopLogoImage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-logo-pic/` +  action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminUpdateShopLogoImageSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopLogoImageFailure(error));
  }
}

function* adminUpdateShopVideo(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-video/` +  action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminUpdateShopVideoSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopVideoFailure(error));
  }
}

function* adminAddShopCollectionVideo(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/add-collection-video/` +  action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminAddShopCollectionVideoSuccess(response));
  } catch (error) {
    yield put(Actions.adminAddShopCollectionVideoFailure(error));
  }
}



function* adminUpdateShopURLLock(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-urllock`,
        action.payload
    );
    yield put(Actions.adminUpdateShopURLLockSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopURLLockFailure(error));
  }
}

function* adminUpdateShopSalesLead(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-saleslead`,
        action.payload
    );
    yield put(Actions.adminUpdateShopSalesLeadSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopSalesLeadFailure(error));
  }
}

function* adminEnableShopStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/enable-status`,
        action.payload
    );
    yield put(Actions.adminEnableShopStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminEnableShopStatusFailure(error));
  }
}

function* adminDisableShopStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/disable-status`,
        action.payload
    );
    yield put(Actions.adminDisableShopStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminDisableShopStatusFailure(error));
  }
}

function* adminUpdateShopDomain(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-domain`,
        action.payload
    );
    yield put(Actions.adminUpdateShopDomainSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopDomainFailure(error));
  }
}

function* adminUpdateShopSocial(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/update-info-social`,
        action.payload
    );
    yield put(Actions.adminUpdateShopSocialSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopSocialFailure(error));
  }
}

function* adminDisplayDeliveredShopStatus(action: Object) {
  try {

    let locationquery = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      locationquery = {
        lat: latitude,
        lng: longitude,
      };
    }

    const searchQuery = querystring.stringify({
      lat: locationquery.lat ? locationquery.lat : "",
      lng: locationquery.lng ? locationquery.lng : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;
    const response = yield API.post(
        `/admin/shop/display-delivered/`+ action.payload +  query,
        action.formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );

    yield put(Actions.adminDisplayDeliveredShopStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminDisplayDeliveredShopStatusFailure(error));
  }
}


function* adminDisplayRequestShopStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/display-request`,
        action.payload
    );
    yield put(Actions.adminDisplayRequestShopStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminDisplayRequestShopStatusFailure(error));
  }
}

function* adminDisplayPrintedStatus(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/display-printed`,
        action.payload
    );
    yield put(Actions.adminDisplayPrintedStatusSuccess(response));
  } catch (error) {
    yield put(Actions.adminDisplayPrintedStatusFailure(error));
  }
}

function* adminUpdateShopVerified(action: Object) {

  try {
    let query = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error)
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      query = {
        lat: latitude,
        lng: longitude,
      };
    }
    const response = yield API.post(
        `/admin/shop/update-verified`,
        {... action.payload, ...query}
    );

    yield put(Actions.adminUpdateShopVerifiedSuccess(response));
  } catch (error) {
    yield put(Actions.adminUpdateShopVerifiedFailure(error));
  }
}

function* adminSendShopMessage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/add-message`,
        action.payload
    );
    yield put(Actions.adminSendShopMessageSuccess(response));
  } catch (error) {
    yield put(Actions.adminSendShopMessageFailure(error));
  }
}

function* adminSendShopInternalMessage(action: Object) {
  try {
    const response = yield API.post(
        `/admin/shop/add-internal-message`,
        action.payload
    );
    yield put(Actions.adminSendShopInternalMessageSuccess(response));
  } catch (error) {
    yield put(Actions.adminSendShopInternalMessageFailure(error));
  }
}


export default function* RegisterStoreSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START,        adminGetLocationShopList);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SHOPS_START,          adminSearchShops);
  yield takeLatest(ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START,  adminSearchShopKeywords);
  yield takeLatest(ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START,    adminGetShopInfoById);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_STATUS_START,   adminUpdateShopStatus);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_PROFILE_START,   adminUpdateShopProfile);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_LOGO_START,   adminUpdateShopLogoImage);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_VIDEO_START,   adminUpdateShopVideo);
  yield takeLatest(ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_START,   adminAddShopCollectionVideo);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_START,  adminUpdateShopURLLock);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_START,  adminUpdateShopSalesLead);
  yield takeLatest(ActionTypes.ADMIN_ENABLE_STORE_STATUS_START,    adminEnableShopStatus);
  yield takeLatest(ActionTypes.ADMIN_DISABLE_STORE_STATUS_START,    adminDisableShopStatus);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_START,     adminUpdateShopDomain);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_START,     adminUpdateShopSocial);
  yield takeLatest(ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_START,  adminDisplayDeliveredShopStatus);
  yield takeLatest(ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_START,    adminDisplayRequestShopStatus);
  yield takeLatest(ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_START,    adminDisplayPrintedStatus);
  yield takeLatest(ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_START,   adminUpdateShopVerified);
  yield takeLatest(ActionTypes.ADMIN_SEND_MESSAGE_START,            adminSendShopMessage);
  yield takeLatest(ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_START,   adminSendShopInternalMessage);
}
