// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,

  searchleads: {
    salesleads:[],
  },
  leaddetail: {
    images: [],
  },
  execList: {
    count: 0,
    executives: [],
  },
  searchkeywords: {
    keywords: [],
  },
  leadImageList: {
    images: [],
  },
  searchfutures: {
    salesfutures: [],
    searched: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_START:
    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_START:
    case ActionTypes.ADMIN_GET_SALES_LEAD_INFO_START:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_START:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_START:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_START:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_START:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_START:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_START:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_START:
    case ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_START:
    case ActionTypes.ADMIN_GET_SALES_EXECUTIVES_START:
    case ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_START:
    case ActionTypes.ADMIN_SEARCH_SALES_FUTURE_START:
    case ActionTypes.ADMIN_UPDATE_SALES_FUTURE_START:
    case ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_START:{
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_FAILURE:
    case ActionTypes.ADMIN_GET_SALES_LEAD_INFO_FAILURE:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_FAILURE:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_FAILURE:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_FAILURE:
    case ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_FAILURE:
    case ActionTypes.ADMIN_GET_SALES_EXECUTIVES_FAILURE:
    case ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_FAILURE:
    case ActionTypes.ADMIN_SEARCH_SALES_FUTURE_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SALES_FUTURE_FAILURE:
    case ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { salesleads } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchleads: {
            salesleads : salesleads,
          },

        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchleads: []
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_KEYWORDS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { keywords } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchkeywords: {
            keywords : keywords,
          },
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchkeywords: {
            keywords: [],
          },
        };
      }
    }

    case ActionTypes.ADMIN_GET_SALES_LEAD_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          leaddetail: action.payload.response
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          leaddetail: {
            images: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_ADD_SALES_LEAD_SUCCESS:
    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_QUICK_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_CARD_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_EVENT_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SALES_LEAD_PLACEID_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SALES_FUTURE_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_ADD_SALES_LEAD_IMAGE_SUCCESS:
    case ActionTypes.ADMIN_REMOVE_SALES_LEAD_IMAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          leadImageList: {
            images: action.payload.response.images,
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          leadImageList: {
            images: [],
          },
        };
      }
    }


    case ActionTypes.ADMIN_GET_SALES_EXECUTIVES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { users } = action.payload.response;
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          execList: {
            count: 0,
            executives: users,
          }
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          execList: {
            count: 0,
            executives: []
          },
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_LEAD_EXECUTIVE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SALES_FUTURE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { salesfutures, searched } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchfutures: {
            salesfutures : salesfutures,
            searched : searched,
          },

        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          salesfutures: [],
          searched: [],
        };
      }
    }

    case ActionTypes.ADMIN_SEARCH_SALES_LEAD_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchleads: {
          salesleads:[],
        },
      };

    case ActionTypes.ADMIN_SEARCH_SALES_KEYWORD_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        searchkeywords: {
          keywords : [],
        },
      };


    case ActionTypes.ADMIN_SALES_LEAD_DETAIL_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        leaddetail: {
          images: [],
        },
      };

    case ActionTypes.ADMIN_SALES_LEAD_IMAGES_RESET:

      return { ...state,
        reqStatus: action.type,
        serviceError: "",
        leadImageList: {
          images: [],
        },
      };

    default:
      return state;
  }
}
