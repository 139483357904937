/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import "components/elements/main/index.scss";
import {Box, Button, IconButton} from "@mui/material";
import {FlexBetween, FlexBox} from "components/flex-box";
import MockShopHeader from "components/elements/main/MockShopHeader";

import { Avatar, Card, Typography } from "@mui/material";
import { H2, H5, H6 } from "components/CustomTypography";
import {getLogo, openGoogleMapLocation, openInstagram, openWhatsApp} from "helpers/utils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import * as ActionTypes from "constants/ActionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import PlaceIcon from "@mui/icons-material/Place";
import ButtonComponent from "../../../../Button";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ShopHeaderMini = (props) => {

    const {
        serviceError, reqStatus, isLoading, isLoadingBootInfo, bootupInfo,
        history, shopinfo, userMainAddStoreToFavlist, getShopProfile
    } = props;

  const [state] = useState({
    layoutColumns: 3,
  });

    const handleBackHome = () => {
        history.push(`/shops`);
    };

    useEffect(() => {
        switch (reqStatus) {
            case ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS:
                getShopProfile(bootupInfo.appState.subdomain_shopId, false);
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);


    const addShopFavourite = () => {
        userMainAddStoreToFavlist(shopinfo.id);
    };

    const openGoogleMap = () => {
        openGoogleMapLocation(shopinfo.location);
    };

    return (
        <Box mb={2}
        >

            <Box
                sx={{
                    display: "flex",
                    p: "4px 4px",
                    height: "100%",
                    alignItems: "center",
                    bgcolor: "#f4f4f4"
                }}
            >
                {isLoadingBootInfo && <MockShopHeader height={100} />}
                {!isLoadingBootInfo &&
                    <>
                        <Box width="25%" >
                        <Avatar
                            src={getLogo(shopinfo.logo)}
                            variant="square"
                            sx={{
                                height: 80,
                                width: 80,
                            }}
                        />
                        </Box>
                        <Box width="75%" >
                                <Box width="100%" alignItems="left">
                                    <H2 textTransform="capitalize" fontSize="22px" fontWeight="bold" color="primary.black" ellipsis>
                                        {shopinfo.title}
                                    </H2>
                                    <H6 fontSize="12px" color="grey.900">
                                        {shopinfo.area && (shopinfo.area)}
                                    </H6>
                                </Box>
                                <FlexBox width="100%" alignItems="left">
                                    <FlexBox width="40%">
                                        <IconButton onClick={() => openGoogleMap()}>
                                            <PlaceIcon fontSize={"medium"} color="primary" />
                                        </IconButton>
                                        {shopinfo.instahandle && <IconButton onClick={() => openInstagram(shopinfo.instahandle)}>
                                            <InstagramIcon fontSize={"medium"} color="primary"/>
                                        </IconButton>
                                        }
                                        {shopinfo.whatsapphandle &&
                                            <IconButton onClick={() => openWhatsApp(shopinfo.whatsapphandle)}>
                                                <WhatsAppIcon fontSize={"medium"} color="primary"/>
                                            </IconButton>
                                        }
                                    </FlexBox>
                                    <Box width="60%" alignItems="left">
                                            {parseInt(shopinfo.fav) > 0 ?
                                                <ButtonComponent
                                                    sx={{
                                                        mt: 2,
                                                        mb: 2,
                                                    }}
                                                    disabled
                                                    variant="contained"
                                                    name="Following"
                                                    size="small"
                                                />
                                                :
                                                isLoading ? <CircularProgress size="1rem"/> :
                                                    <>
                                                    <ButtonComponent
                                                        sx={{
                                                            mt: 2,
                                                            mb: 2,
                                                        }}
                                                        type="primary"
                                                        variant="contained"
                                                        name="Follow us!"
                                                        size="small"
                                                        onClick={() => addShopFavourite()}
                                                    />
                                                </>
                                            }
                                    </Box>
                                </FlexBox>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    );
};

ShopHeaderMini.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const shopHeaderMini = connect(
  ({ bootupInfo, userMainShops }) => ({
    bootupInfo,
    reqStatus: userMainShops.reqStatus,
    isLoading: userMainShops.isLoading,
    serviceError: userMainShops.serviceError,
      isLoadingBootInfo: bootupInfo.isLoading,
      shopinfo: bootupInfo.shopinfo,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopHeaderMini);

export { shopHeaderMini as default };
