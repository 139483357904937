import React from 'react';
import { styled } from '@mui/material/styles';
import UploadFile from '@mui/icons-material/UploadFile';
import ButtonComponent from "components/Button";

const Input = styled('input')({
    display: 'none',
});

const FileButton = ({onFileSelect, idx}) => {

    return (
        <label htmlFor={`contained-button-file-${idx}`}>
            <Input accept="image/*" id={`contained-button-file-${idx}`} type="file" onChange={onFileSelect} />
            <ButtonComponent
                sx={{
                    m: 2,
                    color: 'text.lightprimary',
                }}
                endIcon={<UploadFile />}
                type="primary"
                variant="contained"
                size="small"
                name="Photos"
                component="span"
            />
        </label>
    )
}


export default FileButton;
