//  @flow
import * as React from "react";
import {
  Page,
} from "components/Atoms";
import * as Actions from "actions";

import AdminCommonCategoryMain    from "components/pages/admin/products/AdminCommonCategoryMain";
import { checkUserAllowed } from "helpers/utils.js";

import "./styles.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";


const AdminCommonCategories = (props) => {

  const {
    userDetails
  } = props;

  const renderCategories = () => {
    if (checkUserAllowed(userDetails.userlevel, "categories")) {
      return (
          <Page className="products-home">
            <AdminCommonCategoryMain></AdminCommonCategoryMain>
          </Page>
      )
    } else {
      return (
          <div>Not available </div>
      )
    }
  }

  return (
      <div>
        {
          renderCategories()
        }
      </div>
  );
}

const _adminCommonCategories = connect(
    ({ bootupInfo }) => ({
      bootupInfo,
      userDetails: bootupInfo.userDetails,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminCommonCategories);

export { _adminCommonCategories as default };

