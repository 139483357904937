import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { bindActionCreators } from "redux";

import * as Actions     from "actions";
import MockProductList  from "components/elements/main/MockProductList";

import {getDistance, getImage, getURI, isLocalMarket} from "helpers/utils";
import {Box, Card, Container, Typography} from "@mui/material";
import { H2, H5, H6 } from "components/CustomTypography";
import {CURRENCY} from "config/constants";
import PropTypes from "prop-types";
import UserOfferListItem from "components/elements/user/offers/UserOfferListItem";

const UserOfferList = (props) => {

    const {
        isLoading, offerlist, symbol, match, history, bootupInfo, userMainGetNearbyOffers,
    } = props;

    const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : match.params.marketId);

    useEffect(() => {
        userMainGetNearbyOffers(marketId);
    }, []);

    if (isLoading) {
        return <MockProductList />;
    }



        return (
            <div>
                {offerlist.length > 0
                    ? (
                        <Container maxWidth="md">
                            {offerlist.map((offer, index) => (
                                <Box key={`${index + 1}`} p={1}>
                                    <UserOfferListItem
                                        offer={offer}
                                        history={history}
                                    />
                                </Box>
                            ))}
                        </Container>
                    ):(
                        <Box align="center">
                            <H6 fontSize={16}>No offer is available</H6>
                        </Box>
                    )}
            </div>
        );
}

UserOfferList.defaultProps = {
    isLoading: false,
    symbol: CURRENCY,
};

UserOfferList.propTypes = {
    isLoading: PropTypes.bool,
    offerlist: PropTypes.arrayOf(PropTypes.object).isRequired,
    symbol: PropTypes.string,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainGetNearbyOffers: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userOfferList = connect(
    ({ userMainOffers, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: userMainOffers.reqStatus,
        isLoading: userMainOffers.isLoading,
        serviceError: userMainOffers.serviceError,
        offerlist: userMainOffers.nearbyofferlist,
        symbol: bootupInfo.symbol
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(UserOfferList);

export { userOfferList as default };
