//  @flow
import * as React from "react";
import { useState } from "react";
import {
  Page,
} from "components/Atoms";

import {
  Box, Typography,
} from "@mui/material";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import PrivateProductsMain from "components/pages/seller/products/PrivateProductsMain";
import PrivateCategoryMain from "components/pages/seller/products/PrivateCategoryMain";

import "./styles.scss";

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PrivateProducts = (props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="">
          <Tab label={process.env.REACT_APP_PRODUCT_TAG} {...a11yProps(0)} />
          <Tab label="Categories" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} >
        <PrivateProductsMain />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PrivateCategoryMain />
      </TabPanel>
    </Box>
  );
};

export default (PrivateProducts);
