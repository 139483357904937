//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
    Pace, Button, Divider
} from "components/Atoms";


import * as Actions from "actions";

import ShopClaimList  from "components/widgets/seller/ShopClaimList";
import CustomSectionCreator from "components/CustomSectionCreator";
import ButtonComponent from "components/Button";

import "./styles.scss";
import PropTypes from "prop-types";
import {Container, Typography} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
// Component Imports


const Home = (props) => {

  const {
     serviceError, reqStatus, logout, isLoading
  } = props;

  React.useEffect(() => {
    switch (reqStatus) {
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          //sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);

  const handleLogout = () => {
    localStorage.clear();
    logout();
  }

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <CustomSectionCreator icon={<StoreIcon />} title="Register Shop">
                <ShopClaimList></ShopClaimList>
                <br/>
                <br/>
                <Typography align="center">
                <ButtonComponent
                    sx={{
                        m: 2,
                    }}
                    variant="outlined"
                    type="primary"
                    onClick={handleLogout}
                    name="Logout"
                />
                </Typography>
            </CustomSectionCreator>
        </Container>
    );
}

Home.propTypes = {
    isLoading: false,
  reqStatus: PropTypes.string.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  logout: PropTypes.func.isRequired,
};

// Mapping Props and State.
const HomeComponent = connect(
  ({ registerStore, bootupInfo }) => ({
    bootupInfo,
    reqStatus: registerStore.reqStatus,
    isLoading: registerStore.isLoading,
    serviceError: registerStore.serviceError,
    usersAddress: registerStore.usersAddress,
    placeSuggestions: registerStore.placeSuggestions,
    storeList: registerStore.storeList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(Home);

export { HomeComponent as default };
