//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions       from "actions/seller/seller.store";

import {Box, Card, Container} from "@mui/material";

import {Notification, Pace} from "components/Atoms";

import "./styles.scss";

import ProfileLogoEditor   from "components/widgets/seller/ProfileLogoEditor";
import CustomSectionCreator from "components/CustomSectionCreator";
import ImageIcon from '@mui/icons-material/Image';

import {useState} from "react";


const StoreProfileLogo = (props) => {

  const [state, setState] = useState({ pictureMode: "PREVIEW" });

  const {
    isLoading, reqStatus, serviceError,
    storeProfile, updateStoreLogo, getStoreProfile, history,
  } = props;

  const {logo} = storeProfile;

  React.useEffect(() => {
    getStoreProfile();
  }, []);


  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  React.useEffect(() => {
    switch (reqStatus) {
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          // sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);


  const handleImagesEdit = (mode) => (e) => {
    setState({ pictureMode: mode });
  };

  const handleImagesEditCancel = (mode) => (e) => {
    setState({ pictureMode: mode });
    history.push({
      pathname: '/shop'
    });
  }


    const { pictureMode } = state;

    return (
        <Container>
          {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
          <CustomSectionCreator isSubtle={true} icon={<ImageIcon />} title="Shop Logo">
            {pictureMode === "PREVIEW" ? (
                <ProfileLogoEditor
                    image={logo}
                    updateStoreLogo={updateStoreLogo}
                    cancelEdit={handleImagesEditCancel("EDIT")}
                />
            ) : (
                <ProfileLogoEditor
                    image={logo}
                    cancelEdit={handleImagesEditCancel("PREVIEW")}
                />
            )}
          </CustomSectionCreator>
        </Container>
    );
}
// Mapping Props and State.
const _StoreProfileLogo = connect(
  ({ store }) => ({
    store,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    storeProfile: store.storeProfile,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(StoreProfileLogo);

export { _StoreProfileLogo as default };
