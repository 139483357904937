import React, { useEffect } from "react";
import {
  Avatar,
  Box, Card, Container, Typography, IconButton,
} from "@mui/material";
import ButtonComponent from "components/Button";
import {
    schema,
} from "./helper";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import {H2, H5} from "components/CustomTypography";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import ShopHeaderMiniOverlay from "components/elements/user/shops/ShopHeaderMiniOverlay";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopProductsList from "components/elements/user/products/ShopProductList";

const UserSellerShopView = ({
  setState, selectedShop, symbol, onAddToFavlistClicked,  onCancel, shopdetail, history,
                                userMainGetShopInfoById
}) => {

  const {
     shop_id: shopId,
  } = selectedShop || {};

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const {
        products
    } = shopdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onAddToFav = () => {
      onAddToFavlistClicked(shopId);
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedShop);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedShop);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

    const handleShopButtonClick = () => {
        history.push(`/shop/${shopId}`);
    };

    const renderShopInformation = () => (
        <Box mt={2}>
        </Box>
    );

    const renderShopAddress = () => (
        <Card className="p-3">
            <Typography gutterBottom variant="h6">Address</Typography>
            <Typography display="block" variant="subtitle2">
                {shopdetail.addressline}
                ,
                {shopdetail.cityname}
                ,
                {shopdetail.areaname}
            </Typography>
            <Typography display="block" variant="subtitle2">
                {shopdetail.phone}
            </Typography>
        </Card>
    );

    const renderShopDetail = () => (
        <Container>
            <Box display={window.innerWidth < 400 ? "" : "flex"}>
                <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                    {renderShopAddress()}
                </Box>
            </Box>
        </Container>
    );

    return (
        <Container>
            <Container maxWidth="md">
                <ShopHeaderNav onCancel={onCancel} history={history} />
                <ShopHeaderMiniOverlay userMainGetShopInfoById={userMainGetShopInfoById} history={history} shopId={shopId}/>
                <ShopProductPopularSlider shopId={shopId} />
                <ShopOffersSlider shopId={shopId} />
                <ShopProductNewSlider shopId={shopId} />
                <ShopProductsList shopId={shopId} />
            </Container>
        </Container>
    );
};

export default UserSellerShopView;
