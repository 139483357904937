/* eslint-disable max-len */
const { cordova } = window;

export const scanBarCode = (resolve, reject) => {
  if (cordova) {
    const settings = cordova.plugins.scanner.getDefaultSettings();
    cordova.plugins.scanner.startScanning(
      (result) => {
        resolve(result);
      },
      (error) => {
        reject(error);
      },
      settings,
    );
  } else {
    console.log("Run cordova project");
  }
};

export const stopScanning = () => {
  if (cordova) {
    cordova.plugins.scanner.stopBarcodeScan();
  } else {
    console.log("Run cordova project");
  }
};

export const startCamera = () => {
  if (cordova) {
    const cp = cordova.plugins.CameraPreview;
    cp.startCamera({
      x: 50, y: 50, width: 300, height: 300, toBack: false, previewDrag: true, tapPhoto: true,
    });
  } else {
    console.log("Run cordova project");
  }
};

export const stopCamera = () => {
  if (cordova) {
    const cp = cordova.plugins.CameraPreview;
    cp.stopCamera();
  } else {
    console.log("Run cordova project");
  }
};

export const cameraTakePicture = (resolve, reject) => {
   navigator.camera.getPicture(
       (result) => {
           resolve(result);
       },
       (error) => {
           reject(error);
       },
       {
            quality: 50,
           correctOrientation: 1,
           sourceType: 1, // 0 - file system, 1 - camera
            destinationType: 0, // 0/DATA_URL - blob, 1/FILE_URI - File
       }
   );
}

// const app = {
//   startCameraAbove() {
//     cordova.plugins.CameraPreview.startCamera({
//       x: 50, y: 50, width: 300, height: 300, toBack: false, previewDrag: true, tapPhoto: true,
//     });
//   },
/*
    init: function() {
        document.getElementById('startCameraAboveButton').addEventListener('click', this.startCameraAbove, false);
    }

 */
// };
/*
document.addEventListener('deviceready', function(){
    app.init();
} , false);
*/
