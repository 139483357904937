// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/seller/seller.offers";


const querystring = require('querystring');

function* sellerSearchOffers(action: Object) {
  try {

    const searchQuery = querystring.stringify({
      name: action.name ? action.name : "",
    });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/sellers/offers/search`
    );
    yield put(Actions.sellerSearchOffersSuccess(response));
  } catch (error) {
    yield put(Actions.sellerSearchOffersFailure(error));
  }
}


function* sellerGetOfferInfo(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/offers/get-info/` + action.payload
    );
    yield put(Actions.sellerGetOfferInfoSuccess(response));
  } catch (error) {
    yield put(Actions.sellerGetOfferInfoFailure(error));
  }
}


function* sellerAddOffer(action: Object) {
  try {

    const response = yield API.post(
        `/sellers/offers/add-offer`,
        {... action.payload}
    );
    yield put(Actions.sellerAddOfferSuccess(response));
  } catch (error) {
    yield put(Actions.sellerAddOfferFailure(error));
  }
}

function* sellerUpdateOffer(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/offers/update-offer/` + action.payload.id,
        action.payload
    );
    yield put(Actions.sellerUpdateOfferSuccess(response));
  } catch (error) {
    yield put(Actions.sellerUpdateOfferFailure(error));
  }
}


function* sellerAddOfferImage(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/offers/add-offer-images/` + action.payload,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.sellerAddOfferImageSuccess(response));
  } catch (error) {
    yield put(Actions.sellerAddOfferImageFailure(error));
  }
}

function* sellerRemoveOfferImage(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/offers/remove-offer-image`,
        action.payload
    );
    yield put(Actions.sellerRemoveOfferImageSuccess(response));
  } catch (error) {
    yield put(Actions.sellerRemoveOfferImageFailure(error));
  }
}


export default function* OffersSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.SELLER_SEARCH_OFFERS_START, sellerSearchOffers);
  yield takeLatest(ActionTypes.SELLER_GET_OFFER_INFO_START, sellerGetOfferInfo);
  yield takeLatest(ActionTypes.SELLER_ADD_OFFER_START, sellerAddOffer);
  yield takeLatest(ActionTypes.SELLER_UPDATE_OFFER_START, sellerUpdateOffer);
  yield takeLatest(ActionTypes.SELLER_ADD_OFFER_IMAGE_START, sellerAddOfferImage);
  yield takeLatest(ActionTypes.SELLER_REMOVE_OFFER_IMAGE_START, sellerRemoveOfferImage);
}
