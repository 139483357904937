// @flow
import * as ActionTypes from '../../constants/ActionTypes';


export const sellerSearchOffers = (name) => ({
  type: ActionTypes.SELLER_SEARCH_OFFERS_START,
  name: name,
});

export const sellerSearchOffersSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_SEARCH_OFFERS_SUCCESS,
    payload: data,
  };
};

export const sellerSearchOffersFailure = (error) => ({
  type: ActionTypes.SELLER_SEARCH_OFFERS_FAILURE,
  payload: error,
});

export const sellerGetOfferInfo = (reqData) => ({
  type: ActionTypes.SELLER_GET_OFFER_INFO_START,
  payload: reqData,
});

export const sellerGetOfferInfoSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_GET_OFFER_INFO_SUCCESS,
    payload: data,
  };
};
export const sellerGetOfferInfoFailure = (error) => ({
  type: ActionTypes.SELLER_GET_OFFER_INFO_FAILURE,
  payload: error,
});

export const sellerAddOffer = (reqData) => ({
  type: ActionTypes.SELLER_ADD_OFFER_START,
  payload: reqData,
});

export const sellerAddOfferSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_ADD_OFFER_SUCCESS,
    payload: data,
  };
};
export const sellerAddOfferFailure = (error) => ({
  type: ActionTypes.SELLER_ADD_OFFER_FAILURE,
  payload: error,
});

export const sellerUpdateOffer = (reqData) => ({
  type: ActionTypes.SELLER_UPDATE_OFFER_START,
  payload: reqData,
});

export const sellerUpdateOfferSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_UPDATE_OFFER_SUCCESS,
    payload: data,
  };
};
export const sellerUpdateOfferFailure = (error) => ({
  type: ActionTypes.SELLER_UPDATE_OFFER_FAILURE,
  payload: error,
});

export const sellerAddOfferImage = (reqData, formData) => ({
  type: ActionTypes.SELLER_ADD_OFFER_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const sellerAddOfferImageSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_ADD_OFFER_IMAGE_SUCCESS,
    payload: data,
  };
};
export const sellerAddOfferImageFailure = (error) => ({
  type: ActionTypes.SELLER_ADD_OFFER_IMAGE_FAILURE,
  payload: error,
});

export const sellerRemoveOfferImage = (reqData, image_id) => ({
  type: ActionTypes.SELLER_REMOVE_OFFER_IMAGE_START,
  payload: {offer_id: reqData, image_id: image_id},
});

export const sellerRemoveOfferImageSuccess = (data) => {
  return {
    type: ActionTypes.SELLER_REMOVE_OFFER_IMAGE_SUCCESS,
    payload: data,
  };
};
export const sellerRemoveOfferImageFailure = (error) => ({
  type: ActionTypes.SELLER_REMOVE_OFFER_IMAGE_FAILURE,
  payload: error,
});

