import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().required("Name is required field").min(3, 'Minimum 3 characters'),
  description: yup.string().required("Description is required field").min(3, 'Minimum 3 characters'),
});

export const getTextfieldData = (name, description) => [
  {
    label: "Name",
    value: name,
    onChangeField: "name",
  },
  {
    label: "Description",
    value: description,
    onChangeField: "description",
  },
];

export const getCheckboxData = (is_food, is_nonveg) => [
  {
    label: "Food Item",
    value: is_food,
    onChangeField: "is_food",
  },
  {
    label: "Non-Veg",
    value: is_nonveg,
    onChangeField: "is_nonveg",
  },
];

export const getCheckboxDataWithoutFood = (is_nonveg) => [
  {
    label: "Non-Veg",
    value: is_nonveg,
    onChangeField: "is_nonveg",
  },
];

export const getSelectFieldData = (category) => [
  {
    label: "Category",
    value: category,
    onChangeField: "category",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    variant: "contained",
    onClick: onFinish,
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    variant: "outlined",
    onClick: cancelEdit,
    type: "primary",
    disabled: false,
  },
];
