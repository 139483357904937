import React from 'react'
import { Image } from "components/Atoms";
import {IconButton, Typography} from "@mui/material";
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {getLocalDate, openGoogleMapLocation} from "helpers/utils";
import {DATE_FORMAT_SHORT, DATE_TIME_FORMAT_SHORT} from "config/constants";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default props =>
    props.images.map((image, i) =>
        <div key={i}>
            {props.isRemoveAllowed && props.removeImage &&
                <IconButton size="large" color="primary" onClick={() => props.removeImage(image.cdn.public_id)}>
                    <ClearSharpIcon/>
                </IconButton>
            }
            { props.isScanAllowed && props.scanImage && <IconButton size="large" color="primary" onClick={() => props.scanImage(image._id)}>
                <ManageSearchIcon />
            </IconButton>
            }
            <img
                width={300}
                src={image.cdn.secure_url}
                alt=''
                onError={() => props.onError(image.cdn.public_id)}
            />
        </div>
    )
