/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import { schema, getTextfieldData1, getTextfieldData2, getSelectFieldData, getButtonData } from "./helper";
import SelectFieldComponent from "components/SelectField";
import AdminSellerShopsList from "components/elements/admin/shops/AdminSellerShopsList";
import AdminSalesLeadList from "components/elements/admin/sales/AdminSalesLeadList";
import {checkUserAllowed} from "helpers/utils";

const AdminSalesLeadEditor = ({
  setState, category, addUpdateCategoryToShopClicked, cancelEdit, statusOptions, userDetails
}) => {
  const {
    id, name, shopname, phone, description, leadstatus, location, cardtext
  } = category;
  const [blur, setblur] = React.useState({
    name: false,
    shopname: false,
    description: false,
    phone: false,
    leadstatus: false,
  });

  const onFinish = () => {
    addUpdateCategoryToShopClicked({
      id, name, shopname, phone, description, leadstatus
    });
  };

  const handleChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const handleSelectFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });

  };
  const hasError = () => {
    try {
      return !schema.validateSync(category);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.shopname || blur.name || blur.phone || blur.description || blur.leadstatus);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, category);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

  return (
    <Container>
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData1(shopname).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleChange(event, element.onChangeField)}
            />
          </Box>
        ))}

        {getSelectFieldData(leadstatus).map((element) => (
            <Box className="textfield" m={2}>
              <SelectFieldComponent
                  label={element.label}
                  value={element.value}
                  options={statusOptions}
                  onChange={(event) => handleSelectFieldChange(event, element.onChangeField)}
              />
            </Box>
        ))}

        {getTextfieldData2( name, phone, description).map((element) => (
            <Box className="textfield" m={2}>
              <TextFieldComponent
                  required
                  label={element.label}
                  value={element.value}
                  error={!!getError(element.onChangeField)}
                  helperText={getError(element.onChangeField)}
                  onChange={(event) => handleChange(event, element.onChangeField)}
              />
            </Box>
        ))}

      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, cancelEdit, hasError, isTouched).map((element) => (
          <ButtonComponent
            sx={{
              m: 2,
            }}
            variant={element.variant}
            type={element.type}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>
      {checkUserAllowed(userDetails.userlevel, "scancard") && cardtext}
      { id && <AdminSellerShopsList selectedShop={{ title: shopname, id: id, name: name, location: location }} />}
      { id && <AdminSalesLeadList selectedShop={{ title: shopname, id: id, name: name, location: location }} />}
    </Container>
  );
};

AdminSalesLeadEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired,
  addUpdateCategoryToShopClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};

export default AdminSalesLeadEditor;
