/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from 'lodash.debounce';

import * as Actions from "actions/admin/admin.shops";
import * as ActionTypes from "constants/ActionTypes";

import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box, Grid, MenuItem,
} from "@mui/material";

import {
  Notification,
} from "components/Atoms";

import Autocomplete from "@mui/material/Autocomplete";
import AdminSellerShop from "components/elements/admin/shops/AdminSellerShop";
import AdminSellerShopEditor from "components/elements/admin/shops/AdminSellerShopEditor";
import AdminSellerShopProfileEditor from "components/elements/admin/shops/AdminSellerShopProfileEditor";
import MockProductBoxList from "components/elements/main/MockProductBoxList";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { H6 } from "components/CustomTypography";
import {checkUserAllowed, getAdminShopFilterList, getAdminShopSortList, getDistance, getGeoLocation, getLocalDate} from "helpers/utils";
import ButtonComponent from "components/Button";
import CustomFlexBox from "components/CustomFlexBox";
import DatePicker from "react-datepicker";
import SelectFieldComponent from "components/SelectField";
import {DATE_FORMAT_SHORT} from "config/constants";
import AdminDisplayDeliveredMain from "components/pages/admin/shops/AdminDisplayDeliveredMain";
import AdminAddOfferQuickMain from "components/pages/admin/offers-coupons/AdminAddOfferQuickMain";

import GoogleMap from "components/GoogleMap";
import Marker from "components/Marker";


const AdminShopSearch = (props) => {

  const [state, setState] = useState({
    limit: 5,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
  });
  const [shopState, setShopState] = useState("");
  const [shopModeState, setShopModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);
  const [filterState, setFilterState] = useState("");
  const [sortState, setSortState] = useState("");
  const [startdateState, setStartdateState] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
  const [salesLeadId, setSalesLeadId] = useState("");
  const [cityState, setCityState] = useState("");
  const [executiveState, setExecutiveState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [shopSearchTypeState, setShopSearchTypeState] = useState("");
  const [offsetState, setOffsetState] = useState(0);

  const [mapref, setMapRef] = useState(null);

  const {
    serviceError, reqStatus, isLoading, isLoadingOffers,
    shops, shopdetail, currency, adminSearchShops, adminGetShopInfoById,
    adminUpdateShopStatus, adminEnableShopStatus, adminDisableShopStatus, adminUpdateShopVerified, adminUpdateShopURLLock,
    adminDisplayDeliveredShopStatus, adminDisplayRequestShopStatus, adminDisplayPrintedStatus,
    adminSearchShopKeywords, adminSearchSalesLead, adminUpdateShopSalesLead, adminShopOffers,
    shopsearchtype, searchleads, shopoffers,
    adminResetSearchShops, adminResetSearchShopKeywords, adminGetSalesExecutives, execList, citiesList,
    adminSendShopInternalMessage, adminSendShopMessage, adminUpdateShopDomain, adminUpdateShopSocial,
    adminUpdateShopProfile, adminUpdateShopLogoImage, adminUpdateShopVideo, adminAddShopCollectionVideo,
    adminAddPrivateProductQuick, adminAddOfferInfo, adminAddOfferQuickInfo,
    searchkeywords, match, history, symbol = "$", userDetails,
  } = props;

  const { keywords } = searchkeywords;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;

  useEffect(() => {
    adminGetSalesExecutives();
    setShopSearchTypeState(shopsearchtype);
    if (query) {
      adminResetSearchShops();
      adminSearchShops(query, shopsearchtype, startdateState, filterState, sortState, executiveState, "", cityState, offsetState);
    } else {
      adminSearchShops("", shopsearchtype, startdateState, filterState, sortState, executiveState, "", cityState, offsetState);
    }
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: serviceError,
        description: serviceError,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_SUCCESS:
      case ActionTypes.ADMIN_ENABLE_STORE_STATUS_SUCCESS:
      case ActionTypes.ADMIN_DISABLE_STORE_STATUS_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_STATUS_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_PROFILE_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_LOGO_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_STORE_VIDEO_SUCCESS:
      case ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_SUCCESS:
      case ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_SUCCESS:
        adminSearchShops(query, shopSearchTypeState, startdateState, filterState, sortState, executiveState, locationState, cityState, offsetState);
        break;
      case ActionTypes.ADMIN_ADD_OFFER_SUCCESS:
      case ActionTypes.ADMIN_ADD_OFFER_QUICK_SUCCESS:
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const runSearch = (keyword) => {
    adminResetSearchShopKeywords();
    setState({ resetvalue: "" });

    if (props.bootupInfo.appState.isWebsite) {
      history.push(`/shops/ssearch/${keyword}`);
    } else {
      history.push(`/shops/ssearch/${keyword}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const selectItem = (e, value) => {
    if (e.key === "Enter") {
      runSearch(value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      history.push(`/shops/shop/${option.key}`)
      //runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      adminSearchShopKeywords(value,
        props.bootupInfo.appState.subdomain_shopId, true, props.bootupInfo.appState.isWebsite);
    }
  };

  const debouncedChangeHandler = useCallback(
      debounce(runKeywordSearch, 300)
      , []);

  const handleDelete = () => {
    history.push("/shops");
  };

  const handleShopEdit = (mode, shop, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedShop: {
          shop_id: "", title: "", status: "", user: "",
          is_active: "", is_verified: "",
          saleslead: "", images: [], subdomain: "",
          instahandle: "", facebookhandle: "", whatsapphandle: "",
          internalmessages: [], messages: [], location: "", logo: []
        },
      });
    } else {
      setSalesLeadId("");
      adminGetShopInfoById(shop.id);

      //verified as location is present - L3/L4 admin user - remote
      if (shop.location) {
        adminSearchSalesLead("exact", "", "", shop.location, "", "", shop.title, "" /* user phone na */ );
      } else {
        //not verified as location is absent
        // - L1/L2 admin user - at shop location
        if (checkUserAllowed(userDetails.userlevel, "verifyshop")) {
          adminSearchSalesLead("mynearby", "", "", "", "", "", shop.title, "" /* user phone na */ );
        } else {
          // - L3/L4 admin user - by shop signup location
          if (shop.shoplocation) {
            adminSearchSalesLead("exact", "", "", shop.shoplocation, "", "", shop.title, "" /* user phone na */ );
          } else {
            // - L3/L4 admin user - shop name only
            adminSearchSalesLead("exact", "", "", "", "", "", shop.title, shop.user && shop.user.mobile /* user phone na */ );
          }
        }
      }
      setShopState({
        selectedShop: {
          shop_id: shop.id, title: shop.title, status: shop.status, user: shop.user,
          is_active: shop.is_active, is_verified: shop.is_verified,
          saleslead: shop.saleslead, images: shop.images, subdomain: shop.subdomain,
          instahandle: shop.instahandle, facebookhandle: shop.facebookhandle, whatsapphandle: shop.whatsapphandle,
          internalmessages: shop.internalmessages,
          messages: shop.messages, location: shop.location, logo: shop.logo
        },
      });
    }
  };

  const handleShopEditProfile = (mode, shop, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedShop: {
          shop_id: "", title: "", addressline: "", subdomain: "",
          insthandle: "", facebookhandle: "", whatsapphandle: "",
          status: "", user: "",
          is_active: "", is_verified: "", saleslead: "", images: [],
          internalmessages: [], messages: [], location: "", logo: [], video: [], collections: []
        },
      });
    } else {
      setSalesLeadId("");
      setShopState({
        selectedShop: {
          shop_id: shop.id, title: shop.title, addressline: shop.addressline,
          status: shop.status, user: shop.user, subdomain: shop.subdomain,
          instahandle: shop.instahandle, facebookhandle: shop.facebookhandle,
          whatsapphandle: shop.whatsapphandle,
          is_active: shop.is_active, is_verified: shop.is_verified,
          saleslead: shop.saleslead, images: shop.images,
          internalmessages: shop.internalmessages,
          messages: shop.messages, location: shop.location,
          logo: shop.logo, video: shop.video, collections: shop.collections
        },
      });
    }
  };

  const handleShopAddOffer = (mode, shop, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedShop: {
          shop_id: "", title: "", addressline: "", subdomain: "",
          status: "", user: "",
          is_active: "", is_verified: "", saleslead: "", images: [],
          internalmessages: [], messages: [], location: "", logo: [], deliveryimage: [],
        },
      });
    } else {
      adminShopOffers(shop.id);
      setShopState({
        selectedShop: {
          shop_id: shop.id, title: shop.title, addressline: shop.addressline,
          status: shop.status, user: shop.user, subdomain: shop.subdomain,
          is_active: shop.is_active, is_verified: shop.is_verified,
          saleslead: shop.saleslead, images: shop.images,
          internalmessages: shop.internalmessages,
          messages: shop.messages, location: shop.location,
          logo: shop.logo, deliveryimage: shop.deliveryimage ? shop.deliveryimage : []
        },
      });
    }
  };

  const handleStandeeDelivery = (mode, shop, reset) => () => {
    setShopModeState(mode);
    if (reset) {
      setShopState({
        selectedShop: {
          shop_id: "", title: "", addressline: "", subdomain: "",
          status: "", user: "",
          is_active: "", is_verified: "", saleslead: "", images: [],
          internalmessages: [], messages: [], location: "", logo: [], deliveryimage: [],
        },
      });
    } else {
      setShopState({
        selectedShop: {
          shop_id: shop.id, title: shop.title, addressline: shop.addressline,
          status: shop.status, user: shop.user, subdomain: shop.subdomain,
          is_active: shop.is_active, is_verified: shop.is_verified,
          saleslead: shop.saleslead, images: shop.images,
          internalmessages: shop.internalmessages,
          messages: shop.messages, location: shop.location,
          logo: shop.logo, deliveryimage: shop.deliveryimage ? shop.deliveryimage : []
        },
      });
    }
  };

  const CustomDatePicker = React.forwardRef(({ value, onClick }, ref) => (
      <ButtonComponent
          type="primary"
          variant="contained"
          name={value}
          size="small"
          onClick={onClick} ref={ref}>
        {value}
      </ButtonComponent>
  ));

  const handleShopDate = (update) => {
    setStartdateState(update.getTime());
    setOffsetState(0);
    adminSearchShops("", shopsearchtype, update.getTime(), filterState, sortState, executiveState, locationState, cityState, 0);
  };

  const handleShopFilter = (event) => {
    setFilterState(event.target.value);
    setOffsetState(0);
    adminSearchShops("", shopSearchTypeState, startdateState, event.target.value, sortState, executiveState, locationState, cityState, 0);
  };

  const handleShopSort = (event) => {
    setSortState(event.target.value);
    setOffsetState(0);
    adminSearchShops("", shopSearchTypeState, startdateState, filterState, event.target.value, executiveState, locationState, cityState, 0);
  };

  const handleShopCities = (event) => {
    setCityState(event.target.value);

    // Reset location if city is changed
    setShopSearchTypeState("");
    setLocationState("");
    setOffsetState(0);

    adminSearchShops("", "", startdateState, filterState, sortState, executiveState, "", event.target.value, 0);
  };

  const handleSalesLeadExecutive = (event) => {
    setExecutiveState(event.target.value);
    setOffsetState(0);
    adminSearchShops(query, shopSearchTypeState, startdateState, filterState, sortState, event.target.value, locationState, cityState, 0);
  };

  const approveRejectShop = (shop) => {
    adminUpdateShopStatus(shop);
    setShopModeState("PREVIEW");
  };

  const unlockURL = (shop) => {
    adminUpdateShopURLLock(shop);
    setShopModeState("PREVIEW");
  };

  const disableShop = (shop) => {
    adminDisableShopStatus(shop);
    setShopModeState("PREVIEW");
  };

  const enableShop = (shop) => {
    adminEnableShopStatus(shop);
    setShopModeState("PREVIEW");
  };

  const updateShopDomain = (shop) => {
    adminUpdateShopDomain(shop);
    setShopModeState("PREVIEW");
  };

  const updateShopSocial = (shop) => {
    adminUpdateShopSocial(shop);
    setShopModeState("PREVIEW");
  };

  const verifiedShop = (shopId, pin) => {
    if (salesLeadId) {
      adminUpdateShopVerified({ shop_id: shopId, pin: pin, saleslead_id: salesLeadId });
      setShopModeState("PREVIEW");
    } else {
      const feedback = {
        title: "Sales Lead",
        description: "Select sales lead for verification",
      };
      sendNotification("error", feedback);
    }
  };

  const displayDeliveredShop = (shop_id, formData) => {
    adminDisplayDeliveredShopStatus(shop_id, formData);
    setShopModeState("PREVIEW");
  };

  const addOfferShop = (shop_id, formData) => {
    adminAddOfferQuickInfo(shop_id, formData);
    setShopModeState("PREVIEW");
  };

  const displayReqShop = (shop) => {
    adminDisplayRequestShopStatus(shop);
    setShopModeState("PREVIEW");
  };

  const displayPrintedShop = (shop) => {
    adminDisplayPrintedStatus(shop);
    setShopModeState("PREVIEW");
  };

  const updateShopSalesLeadAdmin = (shopId, salesleadId) => {
    adminUpdateShopSalesLead({ shop_id: shopId, saleslead_id: salesleadId });
    setShopModeState("PREVIEW");
  };

  const updateShopMessageAdmin = (shopId, message) => {
    adminSendShopMessage({ shop_id: shopId, message: message });
    setShopModeState("PREVIEW");
  };

  const updateShopInternalMessageAdmin = (shopId, message) => {
    adminSendShopInternalMessage({ shop_id: shopId, message: message });
    setShopModeState("PREVIEW");
  };

  const updateShopSalesLeadExec = (salesleadId) => {
    setSalesLeadId(salesleadId);
  };

  const updateShopProfileAdmin = (shopinfo) => {
    adminUpdateShopProfile(shopinfo);
    setShopModeState("PREVIEW");
  };

  const addShopOfferAdmin = (offerinfo) => {
    adminAddOfferInfo(offerinfo);
    setShopModeState("PREVIEW");
  };

  const adminUpdateShopLogoClicked = (id, formData) => {
    adminUpdateShopLogoImage(id, formData);
    setShopModeState("PREVIEW");
  };

  const adminUpdateShopVideoClicked = (id, formData) => {
    adminUpdateShopVideo(id, formData);
    setShopModeState("PREVIEW");
  };

  const adminAddShopCollectionVideoClicked = (id, formData) => {
    adminAddShopCollectionVideo(id, formData);
    setShopModeState("PREVIEW");
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);

  const MobileViewOnClick = () => {
    if ((match.path !== "/shops/ssearch" || match.path !== "/shops/ssearch")) {
      props.history.push(process.env.REACT_APP_TYPE === "MARKETPLACE" ? "/shops/ssearch" : "/shops/ssearch");
    }
  };

  const updateLocationSearch = () => {

    const lat = mapref.getCenter().lat();
    const lng = mapref.getCenter().lng();
    const location = { coordinates: [ lng, lat ] };

    setLocationState(location);
    setShopSearchTypeState("map");
    setCityState("");
    setOffsetState(0);

    // reset city, offset as search is based on map location
    adminSearchShops(query, "map", startdateState, filterState, sortState, executiveState, location, "", 0);

  };

  const openMapView = () => {
    setShopModeState("EDIT_MAP");
  };

  const closeMapView = () => {
    setShopModeState("PREVIEW");
  };

  const handleRestAll = (event) => {
    setStartdateState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    setFilterState("");
    setSortState("");
    setExecutiveState("");
    setCityState("");
    setOffsetState(0);
    adminSearchShops("", "", new Date(new Date().getTime() + 24 * 60 * 60 * 1000), "", "", "", "", "", 0);
  };

  const handleReload = (event) => {
    adminSearchShops(query, shopSearchTypeState, startdateState, filterState, sortState, executiveState, locationState, cityState, offsetState);
  };

  const handlePrevOffset = () => {
    const offset = offsetState > 30 ? offsetState - 30 : 0;
    setOffsetState(offset);
    adminSearchShops(query, shopSearchTypeState, startdateState, filterState, sortState, executiveState, locationState, cityState, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 30;
    setOffsetState(offset);
    adminSearchShops("", shopSearchTypeState, startdateState, filterState, sortState, executiveState, locationState, cityState, offset);
  };

  let isModalSearch = false;

  const shopfilter = getAdminShopFilterList();

  const inputShopFilterOptions = shopfilter.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });

  const shopSort = getAdminShopSortList();

  const inputShopSortOptions = shopSort.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });


  const inputCitiesFilterOptions = citiesList.map((item) => {
    const { name, id } = item;
    return (
        <MenuItem value={id}>{name}</MenuItem>
    );
  });

  const { executives } = execList;

  const inputExecutiveFilterOptions = executives.map((item) => {
    const { name, _id } = item;
    return (
        <MenuItem value={_id}>{name}</MenuItem>
    );
  });

  const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No shop found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  const renderResults = () => (
      <div>
        <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
        { shops.map((shop, index) => (
            <Grid item xs={6} sm={3} md={3} lg={3} key={index}>
              <AdminSellerShop
                  userDetails={userDetails}
                  shop={shop}
                  symbol={currency}
                  editShopStatus={handleShopEdit("EDIT", shop, false)}
                  editShopProfile={handleShopEditProfile("EDIT_PROFILE", shop, false)}
                  addShopOffer={handleShopAddOffer("EDIT_OFFER", shop, false)}
                  editStandeeDelivery={handleStandeeDelivery("EDIT_STANDEE", shop, false)}
                  key={`${index + 1}`}
              />
            </Grid>
        ))}
        </Grid>
      </div>
  );

  const renderSearchHelpMobile = () => (
      <Container>
        <Box>
          {
            window.innerWidth <= 500
            && (match.path === "/shops/ssearch" || match.path === "/shops/ssearch")
            && optionValues.map((element) => (
                  <H6 onClick={() => history.push(`/shops/shop/${element.value}`)}>
                    {element.label}
                    <IconButton disabled align="right" size="large">
                      <ArrowForwardIcon />
                    </IconButton>
                  </H6>
            ))
          }
        </Box>
      </Container>
  );

  const renderSearchModule = () => (
    <>

      <Container onClick={MobileViewOnClick}>
          <Box>
        {
            window.innerWidth <= 500
            && (match.path === "/shops/ssearch" || match.path === "/shops/ssearch") ? isModalSearch = true : isModalSearch = false
          }
        <Autocomplete
          id="autocomplete1"
          onClose={setKeyword}
          disableClearable
          freeSolo
          inputValue={resetvalue}
          onOpen={() => setState({ selected: false })}
          value={resetvalue}
          onKeyPress={
            handleKeyPress
          }
          onChange={
            (event, newValue) => selectItem(event, newValue)

          }
          onSelect={
            (input) => debouncedChangeHandler(input.target.value)
          }
          options={
            window.innerWidth <= 500 ? [] : optionValues
          }
          renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
          )}
          renderInput={(params) => (
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="large">
                      <ArrowBackIosIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...params}
              type="search"
              size="large"
              autoFocus={isModalSearch}
              label={`Search by Shop Name or Mobile number or Subdomain`}
              placeholder={`Search by Shop Name or Mobile number or Subdomain`}
              margin="normal"
            />
          )}
        />
        </Box>
      </Container>

      {
        renderSearchHelpMobile()
      }

      <Container>
        {query && (
        <Chip
          label={query}
          onDelete={handleDelete}
        />
        )}
      </Container>
      { match.path !== "/"
      && (
          match.path === "/shops/ssearch/:query" ||
          match.path === "/shops/ssearch/:query" ||
          match.path === "/shops"
          ) && (
          <>
            <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
              <Box width="50%">Before:
                <DatePicker
                    sx={{margin: "4px"}}
                    placeholderText="Select Date"
                    selected={startdateState}
                    maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
                    onChange={handleShopDate}
                    dateFormat="MMMM d, yy"
                    customInput={<CustomDatePicker />}
                />

              </Box>
              <Box width="50%" align="right">
                <ButtonComponent
                    sx={{margin: "4px"}}
                    type="primary"
                    variant="contained"
                    name="Map View"
                    size="small"
                    onClick={openMapView}>
                </ButtonComponent>

                <ButtonComponent
                    sx={{margin: "4px"}}
                    type="primary"
                    variant="contained"
                    name="View all"
                    size="small"
                    onClick={handleRestAll}>
                </ButtonComponent>

                <ButtonComponent
                    sx={{margin: "4px"}}
                    type="primary"
                    variant="contained"
                    name="Reload"
                    size="small"
                    onClick={handleReload}>
                </ButtonComponent>

              </Box>
            </CustomFlexBox>

            <CustomFlexBox sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputShopFilterOptions}
                  label="Filter"
                  value={filterState}
                  onChange={(event) => handleShopFilter(event)}
              />

              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputShopSortOptions}
                  label="Sort"
                  value={sortState}
                  onChange={(event) => handleShopSort(event)}
              />

              <SelectFieldComponent
                  sx={{margin: "4px"}}
                  required
                  options={inputCitiesFilterOptions}
                  label="Cities"
                  value={cityState}
                  onChange={(event) => handleShopCities(event)}
              />

              {(checkUserAllowed(userDetails.userlevel, "approveshop")) &&
                  (<SelectFieldComponent
                      sx={{margin: "4px"}}
                      required
                      options={inputExecutiveFilterOptions}
                      label="Executive"
                      value={executiveState}
                      onChange={(event) => handleSalesLeadExecutive(event)}
                  />)
              }


            </CustomFlexBox>

            {!(isLoading && isLoadingOffers) && shops.length > 0 && <CustomFlexBox m={2} sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}>
              <Box width="60%" align="center">
              </Box>
              <Box width="20%" align="right">
                {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
                  <ArrowBackIosIcon fontSize="medium" color="primary"/>
                </IconButton>
                }
              </Box>
              <Box width="20%" align="right">
                <IconButton onClick={handleNextOffset}>
                  <ArrowForwardIosIcon fontSize="medium" color="primary"/>
                </IconButton>

              </Box>
            </CustomFlexBox>
            }

          {(isLoading || isLoadingOffers) ? <MockProductBoxList />
            : shops.length > 0
              ? renderResults()
              : renderNoResults()
          }
        </>
      )}
    </>
  );

  const { salesleads } = searchleads;
  const { offers, otheroffers } = shopoffers;


  const salesLeadOptions = salesleads && salesleads.map((item) => {
    const { id, shopname, name, phone, distance, owner, created_at, leadstatus } = item;
    return (
        <MenuItem sx={{display: 'block'}} value={id}>
          <b>{shopname}</b> {getDistance(distance)} {phone}
          <Typography sx={{fontSize: '11px'}}>
            Added by: {owner && owner.name} {getLocalDate(created_at, DATE_FORMAT_SHORT)} {leadstatus}
          </Typography>
        </MenuItem>
    );
  })

  const shopMatchingList = keywords.map((item) => {
    const { value, label, distance } = item;
    return (
        <Box>
          <b>{label}</b> {getDistance(distance)}
        </Box>
    );
  })

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      place.location &&
      bounds.extend(new maps.LatLng(
          place.location.coordinates[1],
          place.location.coordinates[0],
      ));
    });
    return bounds;
  };

// Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

// Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {

    setMapRef(map);

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const address = getGeoLocation();
  const center = address ? { lat: parseFloat(address.lat), lng: parseFloat(address.lng) } : shops && shops.length > 0 && shops[0].location ? { lat: shops[0].location.coordinates[1], lng : shops[0].location.coordinates[0] } : { lat: 0, lng: 0 };



  const renderShopEdit = (shopMode) => {
    switch (shopMode) {
      case "EDIT":
        return (
            <AdminSellerShopEditor
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                userDetails={userDetails}
                isLoading={isLoading}
                shopdetail={shopdetail}
                selectedShop={selectedShop}
                salesLeadOptions={salesLeadOptions}
                shopMatchingList={shopMatchingList}
                handleSalesLeadClicked={updateShopSalesLeadAdmin}
                handleSalesLeadSelected={updateShopSalesLeadExec}
                handleShopMessageClicked={updateShopMessageAdmin}
                handleShopInternalMessageClicked={updateShopInternalMessageAdmin}
                approvedrejectShopClicked={approveRejectShop}
                unlockURLShopClicked={unlockURL}
                enableShopClicked={enableShop}
                disableShopClicked={disableShop}
                updateDomainClicked={updateShopDomain}
                updateSocialClicked={updateShopSocial}
                verifiedShopClicked={verifiedShop}
                displayReqClicked={displayReqShop}
                displayDeliveredClicked={displayDeliveredShop}
                displayPrintedClicked={displayPrintedShop}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );
      case "EDIT_PROFILE":
        return (
            <AdminSellerShopProfileEditor
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                {...props}
                symbol={symbol}
                userDetails={userDetails}
                isLoading={isLoading}
                shopdetail={shopdetail}
                selectedShop={selectedShop}
                updateShopProfileClicked={updateShopProfileAdmin}
                adminAddOfferClicked={addShopOfferAdmin}
                adminUpdateShopLogoClicked={adminUpdateShopLogoClicked}
                adminUpdateShopVideoClicked={adminUpdateShopVideoClicked}
                adminAddShopCollectionVideoClicked={adminAddShopCollectionVideoClicked}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );

      case "EDIT_OFFER":
        return (
            <AdminAddOfferQuickMain
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                selectedShop={selectedShop}
                shopOffers={offers}
                images={[]}
                cancelEdit={() => setShopModeState("PREVIEW")}
                adminAddQuickOfferClicked={addOfferShop}
            />
        );

      case "EDIT_STANDEE":
        return (
            <AdminDisplayDeliveredMain
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                selectedShop={selectedShop}
                images={shopdetail.deliveryimage ? shopdetail.deliveryimage : []}
                cancelEdit={() => setShopModeState("PREVIEW")}
                adminDisplayDeliveredClicked={displayDeliveredShop}
            />
        );

      case "EDIT_MAP":
        return (
            <Box>
              <CustomFlexBox m={2} sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}>
                <Box width="50%" align="left">
                  <ButtonComponent
                      type="primary"
                      variant="outlined"
                      name="Back"
                      size="small"
                      onClick={closeMapView}>
                  </ButtonComponent>
                </Box>
                <Box width="50%" align="right">
                  <ButtonComponent
                      m={2}
                      type="primary"
                      variant="contained"
                      name="Search more"
                      size="small"
                      onClick={updateLocationSearch}>
                  </ButtonComponent>
                </Box>
              </CustomFlexBox>
              <div style={{ height: '500px', width: '100%' }}>
                <GoogleMap
                    style={{height: '500px', width: '100%'}}
                    resetBoundsOnResize={true}
                    defaultCenter={[center.lat, center.lng]}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={
                      ({ map, maps }) => apiIsLoaded(map, maps, shops)
                    }
                >
                  {shops.map((shop) => (shop.location &&
                      <Marker
                          sx={{background: "#f0f"}}
                          key={shop.id}
                          text={shop.title}
                          lat={shop.location.coordinates[1]}
                          lng={shop.location.coordinates[0]}
                      />
                  ))}
                </GoogleMap>
              </div>
            </Box>
        );

      case "EDIT_SET":
      default:
        return (
            <AdminSellerShopEditor
                setState={(value, field) => setShopState({
                  ...state,
                  selectedShop: {
                    ...selectedShop,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                userDetails={userDetails}
                isLoading={isLoading}
                shopdetail={shopdetail}
                selectedShop={selectedShop}
                approvedShopClicked={approveRejectShop}
                enableShopClicked={enableShop}
                disableShopClicked={disableShop}
                verifiedShopClicked={verifiedShop}
                displayReqClicked={displayReqShop}
                displayDeliveredClicked={displayDeliveredShop}
                onCancel={() => setShopModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedShop = {} } = shopState;

  return (
      <Container>
        {shopModeState === "PREVIEW" ? (
            renderSearchModule()
        ) : (
            renderShopEdit(shopModeState)
        )}
      </Container>
  );
};

AdminShopSearch.defaultProps = {
  currency: "",
};

AdminShopSearch.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
    adminSearchShops: PropTypes.func.isRequired,
    adminSearchShopKeywords: PropTypes.func.isRequired,
    adminResetSearchShopKeywords: PropTypes.func.isRequired,
    adminResetSearchShops: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminShopSearchComponent = connect(
  ({ adminShops, adminSales, adminOffers, bootupInfo }) => ({
    bootupInfo,
    userDetails: bootupInfo.userDetails,
    currency: bootupInfo.symbol,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
    shops: adminShops.searchshops,
    searchkeywords: adminShops.searchkeywords,
    shopdetail: adminShops.shopDetail,
    searchleads: adminSales.searchleads,
    execList: adminSales.execList,
    citiesList: bootupInfo.cities,
    isLoadingOffers: adminOffers.isLoading,
    shopoffers: adminOffers.shopoffers,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopSearch);

export { AdminShopSearchComponent as default };
