// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  AutoComplete,
  Button,
  Notification,
} from "components/Atoms";
import { H6 } from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import "./style.scss";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import AdminCommonCategory from "components/elements/admin/products/AdminCommonCategory";
import AdminCommonCategoryEditor from "components/elements/admin/products/AdminCommonCategoryEditor";
import AdminCommonCategorySettingEditor from "components/elements/admin/products/AdminCommonCategorySettingEditor";
import AdminCommonCategoryImageMain from "components/pages/admin/products/AdminCommonCategoryImageMain";

import MockProductList from "components/elements/main/MockProductList";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, MenuItem} from "@mui/material";
import {useHistory} from "react-router";

const { Search } = Input;
const { Option } = AutoComplete;

const AdminCommonCategoryMain = (props) => {
  const [state, setState] = useState({ categoryMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: props.categoryInput });

  const {
    serviceError, reqStatus, isLoading, categoryList, mainCategoryList,
    adminGetCommonProductCategories, adminGetCommonProductMainCategories,
    adminAddCommonCategory, adminUpdateCommonCategory, adminUpdateCommonCategorySetting
  } = props;

  useEffect(() => {
    adminGetCommonProductCategories();
    adminGetCommonProductMainCategories();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_COMMON_CATEGORY_SETTING_SUCCESS:
      case ActionTypes.ADMIN_ADD_COMMON_CATEGORY_IMAGE_SUCCESS:
      case ActionTypes.ADMIN_REMOVE_COMMON_CATEGORY_IMAGE_SUCCESS:
        adminGetCommonProductCategories();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const addUpdateCategoryToAccountCall = (values) => {
    if (!values.id) {
      adminAddCommonCategory(values);
    } else {
      adminUpdateCommonCategory(values);
    }
    setState({ categoryMode: "PREVIEW" });
  };

  const updateCategorySettingCall = (values, id) => {
    values.id = id;
    adminUpdateCommonCategorySetting(values);
    setState({ categoryMode: "PREVIEW" });
  };

  const handleCategoryEdit = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          name: "", description: "", id: "", image: [], parent_id: ""
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          name: item.name, description: item.description, id: item.id, image: item.image, parent_id: item.parent_id,
        },
      });
    }
  };

  const handleCategorySetting = (mode, item) => (e) => {
    setState({ categoryMode: mode });
    setCategoryState({
      storeCategory: {
        name: item.name,
        id: item.id,
        is_popular: item.is_popular,
        is_active: item.is_active,
        parent_id: item.parent_id,
      },
    });
  };

  const handleCategoryImage = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          name: "", description: "", id: "", image: [],
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          name: item.name, description: item.description, id: item.id, image: item.image,
        },
      });
    }
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { categories } = categoryList;
  const { maincategories } = mainCategoryList;

  const { categoryMode } = state;
  const { storeCategory = {} } = categoryState;


  const mainCategoryOptions = maincategories.map((item) => {
    const { id, name } = item;
    return (
        <MenuItem value={id}>{name}</MenuItem>
    );
  });

  const renderNoResults = () => (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <H6>No category found
                  </H6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );

  const renderResults = () => (
    <div>
      <Container>
        <Box display="flex" flexWrap="wrap" mb={4}>
          {categories.map((category, index) => (
            <div className="product-card-container" key={`${index + 1}`}>
              <AdminCommonCategory
                className="product-item"
                key={category.id}
                category={category}
                editCategoryImage={handleCategoryImage("EDIT_IMG", category, false)}
                editCategoryInfo={handleCategoryEdit("EDIT", category, false)}
                editCategorySettings={handleCategorySetting("EDIT_SET", category)}
              />
            </div>
          ))}
        </Box>
      </Container>
    </div>
  );

  const renderCategoryList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          width: '100%',
          bottom: 70,
          justifyContent: 'center',
          zIndex: 'modal' }}>
          <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleCategoryEdit("EDIT", null, true)}
          >
            Add New Category
          </Button>
        </CustomFlexBox>

      {isLoading ? <MockProductList />
        : categories.length > 0
          ? renderResults()
          : renderNoResults()}
      </Container>
  );

  const renderCategoryEdit = () => {
    switch (categoryMode) {
      case "EDIT":
        return (
          <AdminCommonCategoryEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
            category={storeCategory}
              mainCategoryOptions={mainCategoryOptions}
            addUpdateCategoryToShopClicked={addUpdateCategoryToAccountCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_IMG":
        return (
          <AdminCommonCategoryImageMain
            category={storeCategory}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
      case "EDIT_SET":
      default:
        return (
          <AdminCommonCategorySettingEditor
              setState={(value, field) => setCategoryState({
                ...state,
                storeCategory: {
                  ...storeCategory,
                  [field]: value,
                },
              })}
            category={storeCategory}
            updateCategorySettingClicked={updateCategorySettingCall}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
          />
        );
    }
  };

  return (
    <Container>
      {categoryMode === "PREVIEW" ? (
        renderCategoryList()
      ) : (
        renderCategoryEdit(categoryMode)
      )}
    </Container>
  );
};

AdminCommonCategoryMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  categoryList: [],
};

AdminCommonCategoryMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  categoryList: PropTypes.arrayOf(PropTypes.object),
  mainCategoryList: PropTypes.arrayOf(PropTypes.object),
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  adminGetCommonProductCategories: PropTypes.func.isRequired,
  adminAddCommonCategory: PropTypes.func.isRequired,
  adminUpdateCommonCategory: PropTypes.func.isRequired,
  adminUpdateCommonCategorySetting: PropTypes.func.isRequired,
};

const adminCommonCategoryMain = connect(
  ({ adminProducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminProducts.reqStatus,
    isLoading: adminProducts.isLoading,
    serviceError: adminProducts.serviceError,
    categoryList: adminProducts.categoryList,
    mainCategoryList: adminProducts.mainCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminCommonCategoryMain);

export default adminCommonCategoryMain;
