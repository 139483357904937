//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
} from "components/Atoms";

import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.products";

import Product              from "components/elements/seller/Product";
import ProductEntryForm     from "components/elements/seller/ProductEntryForm";
import CategoryBrowser      from "components/elements/seller/CategoryBrowser";

import "./styles.scss";
import {useEffect, useState} from "react";
import * as ActionTypes from "constants/ActionTypes";

const ProductByCategory = (props) => {


  const {
    serviceError, reqStatus, isLoading, match,
    getStoreProducts, storeProducts, categoryList
  } = props;

  const { storeId, categoryId } = match.params;

  const [state, setState] = useState({
    bShowProductEntryDialog: false,
    bShowCategoryBrowser: false,
    selectedProduct: null,
  });

  useEffect(() => {
    this.filterCategories = _.debounce(filterProducts, 1000);
    getStoreProducts();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const filterProducts = (value) => {
    setState({ filterText: value.toLowerCase() });
  };

  const addAllProductsInCategory = () => {
    const storeId = storeId;
    const categoryId = categoryId;
  };

  const removeAllProductsInCategory = () => {
    // TODO: remove all products from store by categoryId and Store ID
    const storeId = storeId;
    const categoryId = categoryId;
  };

  const addProductToStore = (product) => () => {};
  const removeProductFromStore = (product) => () => {};


    const {
      bShowProductEntryDialog,
      bShowCategoryBrowser,
      selectedProduct,
    } = state;

    let products = [
      ...storeProducts.records,
      ...storeProducts.records,
      ...storeProducts.records,
    ];

    return (
      <Page className="products-catalog">
        <h2 className="page-title">Products in Category: {this.categoryId}</h2>
        <div className="content">
          <div className="header">
            <Input.Search
              placeholder="Enter Product Name"
              onChange={(e) => this.filterProducts(e.target.value)}
              onSearch={this.filterProducts}
            />
            <div className="actions">
              {this.storeId ? (
                <Button
                  type="primary"
                  onClick={this.removeAllProductsInCategory}
                >
                  Remove All Products
                </Button>
              ) : (
                <Button type="primary" onClick={this.addAllProductsInCategory}>
                  Add All Products
                </Button>
              )}
            </div>
          </div>
          <div className="product-list">
            <Row gutter={[24, 24]}>
              {products.map((product) => {
                return (
                  <Col span={6} key={`pr-item-${product.id}`}>
                    <Product
                      className="product-item"
                      key={product.id}
                      data={product}
                      actions={
                        this.storeId ? (
                          <>
                            <Button
                              icon={<DeleteOutlined />}
                              size={32}
                              style={{ marginRight: "10px" }}
                              onClick={this.removeProductFromStore(product)}
                            />
                            <Button type="primary">Edit</Button>
                          </>
                        ) : (
                          <Button
                            type="primary"
                            onClick={this.addProductToStore(product)}
                          >
                            Add to Store
                          </Button>
                        )
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <ProductEntryForm
          visible={bShowProductEntryDialog}
          data={selectedProduct}
          onOk={this.saveProduct}
          onCancel={this.hideProductEntryDialog}
        />
        <CategoryBrowser
          visible={bShowCategoryBrowser}
          categoryList={categoryList}
          onOk={this.hideCategoryBrowser}
          onCancel={this.hideCategoryBrowser}
        />
      </Page>
    );

}
// Mapping Props and State.
const _ProductByCategory = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProductByCategory);

export { _ProductByCategory as default };
