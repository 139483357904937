import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import * as Actions     from "actions/seller/seller.store";

import {
    Notification, Pace,
} from "components/Atoms";

import {Box, Container} from "@mui/material";

import {useEffect} from "react";
import {H5} from "components/CustomTypography";
import PhotoUpload from "components/PhotoUpload";


const ShopImageEditor = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, cancelEdit,
        addStoreImage, removeStoreImage,
        storeImages
    } = props;

    const {images} = storeImages;


    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        storeImages.images = storeImages.images;
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        addStoreImage(formData);
    }

    const filter = (id) => {
        return images.filter(image => image.cdn.public_id !== id)
    }

    const removeImageCallback = (id) => {
        storeImages.images = storeImages.images.filter(image => image.cdn.public_id !== id);
        removeStoreImage(id);
    }

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <PhotoUpload isRemoveAllowed={true}
                             images={images}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );
};

// Mapping Props and State.
const _ShopImageEditor = connect(
    ({ store, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: store.reqStatus,
      isLoading: store.isLoading,
      serviceError: store.serviceError,
      storeImages: store.storeImages,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ShopImageEditor);

export { _ShopImageEditor as default };
