/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Select, FormControl, InputLabel } from "@mui/material";
import PropTypes from "prop-types";

const CustomSelectField = ({
   label, value, options, error, onChange, ...rest
}) => (
    <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
  <Select
    label={label}
    variant="standard"
    value={value}
    error={error}
    onChange={onChange}
    children={options}
    {...rest}
  />
    </FormControl>
);

CustomSelectField.defaultProps = {
  type: "text",
};

CustomSelectField.propTypes = {
  type: PropTypes.string,
};

export default CustomSelectField;
