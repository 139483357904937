import * as React from "react";
import ButtonComponent from "components/Button";
import { Box, Typography } from "@mui/material";
import { H6 } from "components/CustomTypography";
import {schema} from "../ServiceEditor/helper";

const ServicePreview = ({
  isLoading, service, editService, cancelEdit,
}) => {
  const { shippingoption, orderminimum, shipping } = service;


    const renderDelivery = (option) => {
        switch(option) {
            case "freeshipping":
                return (
                    <Typography>
                        Delivery by shop (Free with all orders)
                    </Typography>
                );
            case "shippingcost":
                return (
                    <Typography>
                        <H6>Delivery by shop (Free with minimum order):</H6>
                        {`${orderminimum || ""}`}
                        <br/>
                        <H6>Delivery cost:</H6>
                        {`${shipping || ""}`}
                    </Typography>
                );
            case "actualcost":
                return (
                    <Typography>
                        Delivery by {process.env.REACT_APP_NAME}
                    </Typography>
                );
            case "noshipping":
            default:
                return (
                    <Typography>
                        No Delivery (Disabled)
                    </Typography>
                );
        }
    }


  return (
    <Box display="flex">
      <Box overflow="hidden" width="80%" className="p-3">
          {renderDelivery(shippingoption)}
      </Box>
      <Box>
        {!isLoading
              && (
              <ButtonComponent
                sx={{
                  m: 2,
                }}
                variant="contained"
                type="primary"
                name="Edit"
                size="small"
                onClick={editService}
              />
              )}
      </Box>
    </Box>
  );
};

export default ServicePreview;
