// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Input,
  AutoComplete,
  Button,
  Notification,
} from "components/Atoms";
import { H6 } from 'components/CustomTypography';
import CustomFlexBox from 'components/CustomFlexBox';

import "./style.scss";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import AdminOffer from "components/elements/admin/offers/AdminOffer";
import AdminOfferSettingEditor from "components/elements/admin/offers/AdminOfferSettingEditor";

import MockProductList from "components/elements/main/MockProductList";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, Grid, MenuItem} from "@mui/material";
import {useHistory} from "react-router";
import {getAdminOfferFilterList} from "helpers/utils";
import SelectFieldComponent from "../../../../SelectField";
import ButtonComponent from "../../../../Button";
import DatePicker from "react-datepicker";

const { Search } = Input;
const { Option } = AutoComplete;

const AdminOffersMain = (props) => {

  const [state, setState] = useState({
    limit: 5,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
  });

  const [offerModeState, setOfferModeState] = useState("PREVIEW");
  const [offerState, setOfferState] = useState("");
  const [filterState, setFilterState] = useState("");
  const [startdateState, setStartdateState] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000));

  const {
    serviceError, reqStatus, isLoading, searchoffers, bootupInfo,
    adminSearchOffers, adminUpdateOfferStatus, adminUpdateOfferInfo
  } = props;

  useEffect(() => {
    adminSearchOffers("", filterState);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_UPDATE_OFFER_STATUS_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_OFFER_INFO_SUCCESS:
        adminSearchOffers("", filterState);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const updateOfferStatusToAccountCall = (data) => {
    adminUpdateOfferStatus(data);
    setOfferModeState("PREVIEW");
  };

  const updateOfferInfoToAccountCall = (data) => {
    adminUpdateOfferInfo(data);
    setOfferModeState("PREVIEW");
  };

  const handleCategoryEdit = (mode, item, reset) => (e) => {
    setOfferModeState(mode);
    if (reset) {
      setOfferState({
        selectedOffer: {
          name: "", description: "", _id: "", images: [], offer_id : "", title : "",
          is_offer_active: "", is_shop_active: "", shop_created_at: "", offer_created_at: ""
        },
      });
    } else {
      setOfferState({
        selectedOffer: {
          name: item.name, description: item.description, _id: item._id, images: item.images,
          offer_id : item.offer_id, title : item.title, is_offer_active: item.is_offer_active,
          is_shop_active: item.is_shop_active, shop_created_at: item.shop_created_at,
          offer_created_at: item.offer_created_at,
        },
      });
    }
  };

  const handleCategorySetting = (mode, item) => (e) => {
    setOfferModeState(mode);
    setOfferState({
      selectedOffer: {
        name: item.name,
        description: item.description,
        _id: item._id,
        title: item.title,
        offer_id: item.offer_id,
        is_active: item.is_active,
        is_offer_active: item.is_offer_active,
        is_shop_active: item.is_shop_active,
        shop_created_at: item.shop_created_at,
        offer_created_at: item.offer_created_at,
      },
    });
  };

  const handleCategoryImage = (mode, item, reset) => (e) => {
    setOfferModeState(mode);
    if (reset) {
      setOfferState({
        selectedOffer: {
          name: "", description: "", _id: "", images: [], offer_id: "", title: "",
          is_offer_active: "", is_shop_active: "", shop_created_at: "", offer_created_at: ""
        },
      });
    } else {
      setOfferState({
        selectedOffer: {
          name: item.name, description: item.description, _id: item._id, images: item.images,
          offer_id: item.offer_id, title: item.title, is_offer_active: item.is_offer_active,
          is_shop_active: item.is_shop_active, shop_created_at: item.shop_created_at,
          offer_created_at: item.offer_created_at,
        },
      });
    }
  };

  const CustomDatePicker = React.forwardRef(({ value, onClick }, ref) => (
      <ButtonComponent
          type="primary"
          variant="contained"
          name={value}
          size="small"
          onClick={onClick} ref={ref}>
        {value}
      </ButtonComponent>
  ));

  const handleOfferAddDate = (update) => {
    setStartdateState(update.getTime());
    adminSearchOffers("", filterState, update.getTime());
  };

  const handleOfferFilter = (event) => {
    setFilterState(event.target.value);
    adminSearchOffers("", event.target.value);
  };

  const offerfilter = getAdminOfferFilterList();

  const inputOfferFilterOptions = offerfilter.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });
  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const { offers, otheroffers } = searchoffers;

  //const { categoryMode } = state;
  //const { selectedOffer = {} } = categoryState;

  const { selectedOffer = {} } = offerState;

  const renderNoResults = () => (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <H6>No offer found
                  </H6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );

  const renderOfferResults = () => (
    <div>
        <Grid item container md={9} xs={12} spacing={4}>
          {offers.map((offer, index) => (
              <Grid item xs={6} sm={4} key={index}>
              <AdminOffer
                className="product-item"
                key={offer.id}
                offer={offer}
                userDetails={bootupInfo.userDetails}
                isLoading={isLoading}
                editCategorySettings={handleCategorySetting("EDIT_SET", offer)}
              />
              </Grid>
          ))}
        </Grid>
    </div>
  );

  const renderOtherOfferResults = () => (
      <div>
        <Grid item container md={9} xs={12} spacing={4}>
          {otheroffers.map((offer, index) => (
              <Grid item xs={6} sm={4} key={index}>
                <AdminOffer
                    className="product-item"
                    key={offer.id}
                    offer={offer}
                    userDetails={bootupInfo.userDetails}
                    isLoading={isLoading}
                    editCategorySettings={handleCategorySetting("EDIT_SET", offer)}
                />
              </Grid>
          ))}
        </Grid>
      </div>
  );

  const renderOfferList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>
          Shops Before:
          <DatePicker
              placeholderText="Select Date"
              selected={startdateState}
              maxDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
              onChange={handleOfferAddDate}
              dateFormat="MMMM d, yyyy"
              customInput={<CustomDatePicker />}
          />
        <SelectFieldComponent
            required
            options={inputOfferFilterOptions}
            label="Filter"
            value={filterState}
            onChange={(event) => handleOfferFilter(event)}
        />
        </CustomFlexBox>
      {isLoading ? <MockProductList /> : offers.length > 0 ? renderOfferResults() : renderNoResults()},
        {isLoading ? <MockProductList /> : otheroffers.length > 0 ? renderOtherOfferResults() : renderNoResults()}
      </Container>
  );

  const renderCategoryEdit = () => {
    switch (offerModeState) {
      case "EDIT_SET":
      default:
        return (
          <AdminOfferSettingEditor
              setState={(value, field) => setOfferState({
                ...state,
                selectedOffer: {
                  ...selectedOffer,
                  [field]: value,
                },
              })}
            offer={selectedOffer}
              selectedOffer={selectedOffer}
              history={history}
              userDetails={bootupInfo.userDetails}
            updateOfferSettingClicked={updateOfferStatusToAccountCall}
              updateOfferInfoClicked={updateOfferInfoToAccountCall}
              isLoading={isLoading}
            cancelEdit={() => setOfferModeState("PREVIEW")}
          />
        );
    }
  };

  return (
    <Container>
      {offerModeState === "PREVIEW" ? (
        renderOfferList()
      ) : (
        renderCategoryEdit(offerModeState)
      )}
    </Container>
  );
};

AdminOffersMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  searchoffers: [],
};

AdminOffersMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  searchoffers: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  adminSearchOffers: PropTypes.func.isRequired,
  adminUpdateOffer: PropTypes.func.isRequired,
};

const adminOffersMain = connect(
  ({ adminOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: adminOffers.reqStatus,
    isLoading: adminOffers.isLoading,
    serviceError: adminOffers.serviceError,
    searchoffers: adminOffers.searchoffers,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminOffersMain);

export default adminOffersMain;
