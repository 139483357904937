import * as React from "react";
import { Button } from "components/Atoms";

import {getPriceCurrency} from "helpers/utils";

import "./style.scss";
import {Box, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {H2, H4} from "components/CustomTypography";
import ButtonComponent from "../../../Button";


const AvailablePlan = (props) => {
  const { currency, data, addToCart, removeFromCart, inCart } = props;

  const { title, description, duration, price, images } =
    props.data || {};

  return (
      <Box className="styled-card" width="100%" display="flex" mt={4} mb={4}>
            <Box width="60%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>
                        {title}
                    </H2>
                    <H4 fontSize={12}>
                        {description}
                    </H4>
            </Box>
              <Box width="20%" order={2} ml={2}>
                  <H2 mb={2} fontSize={16}  ellipsis>
                      {getPriceCurrency(price, currency)}
                  </H2>
              </Box>
            <Box width="20%" order={3} ml={2}>
                {inCart ? (
                        <ButtonComponent
                            type="primary"
                            variant="outlined"
                            name="Remove"
                            size="medium"
                            onClick={removeFromCart}>
                        </ButtonComponent>
                ) : (
                    <ButtonComponent
                        type="primary"
                        variant="contained"
                        name="Add"
                        size="medium"
                        onClick={addToCart}>
                    </ButtonComponent>
                )
                }
            </Box>
        </Box>
  );
};

export default AvailablePlan;
