import React, {Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as Actions from "actions";
import {getLocalDate, getImage, getTimeSlot} from "helpers/utils";
import MockOrderDetail from "components/elements/main/MockOrderDetail";
import {Avatar, Box, Card, Container, Typography} from "@mui/material";
import {DATE_FORMAT} from "config/constants";


const OrderDetail = (props) => {
  const [prices, setPrices] = useState({
    total: 0, totalDiscount: 0,
  });
  const {
    isLoading, orderdetail, symbol, match, userMainGetOrderDetail, bootupInfo,
  } = props;

  const { orderId } = match.params;

  const {
    products, shipping, created_at: createdAt, order_id: orderIdValue, total_price: totalPrice = 0, shop,
    order_type, total_order, total_price, total_shipping, total_tax, delivery_date, delivery_time
  } = orderdetail;

  useEffect(() => {
    userMainGetOrderDetail(orderId);
  }, []);

  useEffect(() => {
    let total = 0;
    let totalDiscount = 0;
    if (products.length) {
      products.forEach((item) => {
        total += item.seller_price;
        totalDiscount += item.discount || 0;
      });
      setPrices({ total, totalDiscount });
    }
  }, [products]);

  if (isLoading) {
    return <MockOrderDetail />;
  }

  const renderOrderInformation = () => (
      <Box p={2} width="100%" display="flex">
        <Box width="50%">
          <Typography className="styled-link" variant="subtitle2">
            {`Order Id: ${orderIdValue}`}
          </Typography>
        </Box>
        <Box width="50%">
          <Typography className="styled-link" align="right" display="block" variant="subtitle2">
            Date:
            {" "}
            {getLocalDate(createdAt)}
          </Typography>
        </Box>
      </Box>
  );

  const renderOrderItems = () => (
      <Box>
        <Card className="p-3">
          {products.map((cartItem, index) => (
              <Card className="order-card" key={`${index + 1}`}>
                <Box minHeight="100%" width="100%" display="flex">
                  <Box className="styled-card" width={window.innerWidth < 400 ? "17%" : "12%"}>
                    <Avatar variant="square" src={getImage(cartItem.product.images)} alt="" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography className="styled-link" gutterBottom variant="h6" component="p">
                      {cartItem.product.name}
                    </Typography>
                    <Typography display="block" variant="caption">
                      {cartItem.quantity}
                      {" "}
                      x
                      {symbol}
                      {cartItem.seller_price}
                    </Typography>
                      <Typography className="styled-link" gutterBottom variant="h6" component="p">
                          {cartItem.product_size}
                      </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">
                      Total:
                      {symbol}
                      {cartItem.quantity * cartItem.seller_price}
                    </Typography>
                  </Box>
                </Box>
              </Card>
          ))}
        </Card>
      </Box>
  );

  const renderShippingAddress = () => (
      <Card className="p-3">
        <Typography gutterBottom variant="h6">Delivery Address</Typography>
        <Typography display="block" variant="subtitle2">
          {shipping.name}
        </Typography>
        <Typography display="block" variant="subtitle2">
          {shipping.address}
          ,
          {shipping.city}
          ,
          {shipping.pincode}
        </Typography>
        <Typography display="block" variant="subtitle2">
          {shipping.phone}
        </Typography>
      </Card>
  );

  const renderShopInformation = () => (
      <Card className="p-3">
        <Typography gutterBottom variant="h6">Shop</Typography>
        <Typography display="block" variant="subtitle2">
          {shop[0].title}
        </Typography>
        <Typography display="block" variant="subtitle2">
          {shop[0].addressline}
          ,
          {shop[0].areaname}
          ,
          {shop[0].cityname}
        </Typography>
        <Typography display="block" variant="subtitle2">
          {shop[0].phone}
        </Typography>
      </Card>
  );

  const renderShopPickup = () => (
      <Card className="p-3">
        <Typography variant="h6">Pickup</Typography>
        <Typography variant="subtitle2" display="block">
          {getLocalDate(delivery_date, DATE_FORMAT)}
          ,
          {getTimeSlot(delivery_time)}
        </Typography>
      </Card>
  );

  const renderOrderTotal = () => (
      <Card className="p-3">
        <Box display="flex">
          <Box width="50%">
            <Typography variant="subtitle2" display="block">Subtotal: </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="subtitle2" display="block" align="right">
              {symbol}
              {total_price}
            </Typography>
          </Box>
        </Box>
        <Box display="flex">
          <Box width="50%">
            <Typography variant="subtitle2" display="block">Shipping: </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="subtitle2" display="block" align="right">
              {symbol}
              {total_shipping}
            </Typography>
          </Box>
        </Box>
        <hr className="solid" />
        <Box display="flex">
          <Box width="50%">
            <Typography variant="subtitle2" display="block">Total price: </Typography>
          </Box>
          <Box width="50%">
            <Typography variant="subtitle2" display="block" align="right">
              {symbol}
              {total_order}
            </Typography>
          </Box>
        </Box>
      </Card>
  );

  return (
      <div>
        <Container maxWidth="md">
          <Container className="header-color">
            {renderOrderInformation()}
          </Container>

          <Box display={window.innerWidth < 400 ? "" : "flex"}>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
              {order_type === "delivery" ? renderShippingAddress() : renderShopPickup()}
            </Box>
            <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
              {shop && shop.length > 0 && renderShopInformation()}
            </Box>
          </Box>

          <Container>
            <Box>
              {renderOrderItems()}
            </Box>
            <Box>
              {renderOrderTotal()}
            </Box>
          </Container>
        </Container>
      </div>
  );

}

// Mapping Props and State.
const _OrderDetail = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
    orderdetail: userMainOffers.orderdetail,
    symbol: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(OrderDetail);

export { _OrderDetail as default };
