import { SvgIcon } from "@mui/material";
import React from "react";

const Laptop = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M511.976,416.063c-0.005-0.075-0.004-0.149-0.011-0.224c-0.027-0.297-0.07-0.588-0.131-0.873
			c-0.006-0.028-0.015-0.056-0.022-0.084c-0.059-0.262-0.132-0.518-0.218-0.768c-0.023-0.067-0.048-0.132-0.073-0.198
			c-0.084-0.227-0.179-0.448-0.284-0.663c-0.018-0.038-0.03-0.077-0.049-0.115l-40.112-79.118V73.72
			c0-8.006-6.513-14.519-14.519-14.519H55.441c-8.006,0-14.519,6.513-14.519,14.519v260.298L0.811,413.137
			c-0.019,0.038-0.031,0.077-0.049,0.115c-0.105,0.215-0.199,0.436-0.284,0.662c-0.025,0.066-0.05,0.132-0.073,0.199
			c-0.085,0.251-0.159,0.508-0.218,0.77c-0.006,0.027-0.015,0.054-0.021,0.081c-0.061,0.286-0.104,0.577-0.131,0.874
			c-0.007,0.074-0.007,0.149-0.011,0.223C0.014,416.217,0,416.371,0,416.529v19.026c0,9.509,7.735,17.244,17.244,17.244h477.512
			c9.509,0,17.244-7.735,17.244-17.244v-19.026C512,416.371,511.986,416.217,511.976,416.063z M55.923,74.203h400.154v254.109
			H55.923V74.203z M53.029,343.311H458.97l33.318,65.717h-164.78l-8.271-29.989c-1.684-6.105-7.282-10.369-13.615-10.369h-99.246
			c-6.333,0-11.932,4.264-13.615,10.368l-8.271,29.99H19.711L53.029,343.311z M311.948,409.029H200.052l6.993-25.358h97.91
			L311.948,409.029z M497,435.554c0,1.237-1.007,2.244-2.244,2.244H17.244c-1.237,0-2.244-1.007-2.244-2.244v-11.526h482V435.554z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M432.577,213.756c-4.143,0-7.5,3.357-7.5,7.5v76.055H86.923v-76.055c0-4.143-3.357-7.5-7.5-7.5
			c-4.143,0-7.5,3.357-7.5,7.5v79.294c0,6.485,5.275,11.761,11.761,11.761h344.633c6.485,0,11.761-5.275,11.761-11.761v-79.294
			C440.077,217.115,436.72,213.756,432.577,213.756z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M428.316,90.203H83.684c-6.485,0-11.761,5.275-11.761,11.761v79.294c0,4.143,3.357,7.5,7.5,7.5c4.143,0,7.5-3.357,7.5-7.5
			v-76.055h338.154v76.055c0,4.143,3.357,7.5,7.5,7.5s7.5-3.357,7.5-7.5v-79.294C440.077,95.477,434.802,90.203,428.316,90.203z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default Laptop;
