import React from 'react';
import ContentLoader from "react-content-loader";

const MockGeneralHeader = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={80}
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    ><rect x="0" y="0" width="100%" height="80" />
    </ContentLoader>
)

export default MockGeneralHeader;
