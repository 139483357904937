/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    Button, Container, Typography, IconButton, Box, Card, MenuItem,
} from "@mui/material";
import { FlexBox} from "components/flex-box";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import * as Actions from "actions";
import {checkProductInCart, getImage, getPrice, isMarketPlace} from "helpers/utils";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import MockProductBox from "components/elements/main/MockProductBox";
import ImageAlbum from "components/elements/main/ImageAlbum";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {H2, H5, H6} from "components/CustomTypography";
import ButtonComponent from "components/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CircularProgress from "@mui/material/CircularProgress";
import * as ActionTypes from "constants/ActionTypes";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SelectFieldComponent from "components/SelectField";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import {Helmet} from "react-helmet";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopHeaderSimilarItems from "components/elements/user/shops/ShopHeaderSimilarItems";
import MockProductImage from "components/elements/main/MockProductImage";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";

const ShopProductPageDetail = (props) => {
  const {
    match, bootupInfo, history, currency,
    addProductToCart, addProductToWishlist, userGetProductInfo,
    product, cartItems, cartAddingPid,
      isProdLoading, isCartLoading,
      reqCartStatus, serviceCartError
  } = props;

    const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

  const [share, setShare] = useState(false);
  const [sizeState, setSizeState] = useState("");
  const { prodId } = match.params;

  useEffect(() => {
    userGetProductInfo(prodId, shopId);
  }, []);

    useEffect(() => {
        userGetProductInfo(prodId, shopId);
    }, [prodId, shopId]);

    useEffect(() => {
        switch (reqCartStatus) {
            case ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS:
                userGetProductInfo(prodId, shopId);
                break;
            default:
                break;
        }
    }, [serviceCartError, reqCartStatus]);



    const inputSizeOptions = product.size && product.size.map((item) => {
        return (
            <MenuItem value={item}>{item}</MenuItem>
        );
    });

    const handleSizeClick = (event) => {
        setSizeState(event.target.value);
    };

    const renderProductSize = () => (
        <SelectFieldComponent
            size="small"
            required
            options={inputSizeOptions}
            label="Size"
            value={sizeState}
            onChange={(event) => handleSizeClick(event)}
        />
    );

  const renderProductInformation = () => (
      <Box width="100%" >
          <Box mb={2} width="100%" display="flex" alignItems="center">
              <Box width="40%" >
                  <H2 fontSize={18} textAlign="left">
                      {getPrice(product, currency)}
                  </H2>
              </Box>
              <Box width="60%" display="flex" justifyContent="flex-end">
                      {
                          product.is_price_visible &&
                          (checkProductInCart(cartItems, product.prod_id) ?
                                  <ShoppingCartIcon color="primary" />
                                  :
                                  (isCartLoading && cartAddingPid === product.prod_id ?
                                          <CircularProgress size="1rem"/>
                                          :
                                          <>
                                          <Box width="40%" display="flex" justifyContent="flex-end">
                                              {(product.size && product.size.length > 0) &&
                                                  renderProductSize()
                                              }
                                          </Box>
                                              <Box width="60%" display="flex" justifyContent="flex-end">
                                              <ButtonComponent
                                                  sx={{
                                                      mt: 2,
                                                      mb: 2,
                                                  }}
                                                  type="primary"
                                                  variant="contained"
                                                  name="Add to cart"
                                                  size="small"
                                                  onClick={() => addProductToCart(prodId, shopId, !isMarketPlace(), sizeState)}
                                              />
                                              </Box>
                                          </>
                                  )
                          )
                      }
              </Box>
          </Box>
          <Box width="100%" >
              <FlexBox width="100%" alignItems="left">
                  <Box width="50%">
                      <H5 fontSize={14} textAlign="left" >
                          {parseInt(product.fav) > 0 ?
                              <ButtonComponent
                                  sx={{
                                      mt: 2,
                                      mb: 2,
                                  }}
                                  disabled
                                  variant="contained"
                                  name="Liked!"
                                  size="small"
                              />
                              :
                              isCartLoading ? <CircularProgress size="1rem"/> :
                                  <>
                                      <ButtonComponent
                                          sx={{
                                              mt: 2,
                                              mb: 2,
                                          }}
                                          type="primary"
                                          variant="contained"
                                          name="Like!"
                                          size="small"
                                          onClick={() => addProductToWishlist(prodId)}
                                      />
                                  </>
                          }
                      </H5>

                  </Box>
                  {/*
              <Box width="50%" display="flex" justifyContent="flex-end">
                  <Typography align="left">
                      <IconButton onClick={() => setShare(!share)} size="small">
                          <ShareIcon color="primary" />&nbsp; <H5>Share Online!</H5>
                      </IconButton>
                  </Typography>

                  {
                      share && (
                          <>
                              <EmailShareButton url={window.location.href}>
                                  <IconButton size="small">
                                      <MailIcon />
                                  </IconButton>
                              </EmailShareButton>
                              <FacebookShareButton url={window.location.href}>
                                  <IconButton size="small">
                                      <FacebookIcon />
                                  </IconButton>
                              </FacebookShareButton>
                              <TwitterShareButton url={window.location.href}>
                                  <IconButton size="small">
                                      <TwitterIcon />
                                  </IconButton>
                              </TwitterShareButton>
                              <WhatsappShareButton url={"www.shopana.in"}
                                                   title={"Check with us!"}>
                                  <IconButton size="small">
                                      <WhatsAppIcon />
                                  </IconButton>
                              </WhatsappShareButton>
                          </>
                      )
                  }
              </Box>
              */}
              </FlexBox>
          </Box>
      </Box>
  );

    const renderProductPhotos = () => (
        <Box pb={2}>
            <ImageAlbum images={product.images} />
        </Box>
    );

  return (
      <div>
          {/* SEO Support */}
          <Helmet>
              <title>{bootupInfo.shopinfo.title}</title>
              <meta name="description" content={bootupInfo.shopinfo.title} />
          </Helmet>
          {/* SEO Support End */}
          <ShopHeaderNav history={history} />
          {isMarketPlace() &&
              <ShopHeaderMini {...props} />
          }
      <Container>
        <Container maxWidth="md">

            {isProdLoading && <MockGeneralHeader />}

            {isProdLoading && <MockProductImage />}
            {!isProdLoading && renderProductPhotos()}

            {!isProdLoading && renderProductInformation()}

            {!isProdLoading && <ShopHeaderSimilarItems />}

            <ShopProductPopularSlider shopId={shopId} />
            <ShopProductNewSlider shopId={shopId} />
        </Container>
      </Container>
      </div>
  );
};

ShopProductPageDetail.propTypes = {
    currency: PropTypes.string,
    addProductToCart: PropTypes.func.isRequired,
    product: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  userGetProductInfo: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const shopProductPageDetail = connect(
  ({ userproducts, cartList, bootupInfo }) => ({
    bootupInfo,
      currency: bootupInfo.symbol,
      isProdLoading: userproducts.isLoading,
      reqProdStatus: userproducts.reqStatus,
    serviceProdError: userproducts.serviceError,
      product: userproducts.productdetail,
    cartItems: cartList.cart,
    isCartLoading: cartList.isLoading,
      reqCartStatus: cartList.reqStatus,
      serviceCartError: cartList.serviceError,
    cartAddingPid: cartList.cartAddingPid,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductPageDetail);

export { shopProductPageDetail as default };
