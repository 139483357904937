import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const userMainResetSearchShops = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_RESET
});

export const userMainResetSearchShopKeywords = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORDS_RESET
});

export const userMainGetLocationShopList = (req) => ({
    type: ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_START,
    payload: req,
});

export const userMainResetStores = () => ({
    type: ActionTypes.USER_MAIN_SHOPS_RESET_ALL
});

export const userMainGetLocationStoreList = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_START,
    payload: reqData,
});

export const userMainGetLocationStoreListSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_SUCCESS,
        payload: data,
    };
};
export const userMainGetLocationStoreListFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_FAILURE,
    payload: error,
});

export const userMainGetExactLocationStoreList = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_START,
    payload: reqData,
});

export const userMainGetExactLocationStoreListSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_SUCCESS,
        payload: data,
    };
};
export const userMainGetExactLocationStoreListFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_FAILURE,
    payload: error,
});

export const userMainGetPopularStoreList = (marketId) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_START,
    market: marketId
});

export const userMainGetPopularStoreListSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_SUCCESS,
        payload: data,
    };
};
export const userMainGetPopularStoreListFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_FAILURE,
    payload: error,
});

export const userMainGetLatestStoreList = (marketId) => ({
    type: ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_START,
    market: marketId
});

export const userMainGetLatestStoreListSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_SUCCESS,
        payload: data,
    };
};
export const userMainGetLatestStoreListFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_FAILURE,
    payload: error,
});

export const userMainSearchShops = (reqData, sort, location, marketId, offset) => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_START,
    payload: reqData,
    sort: sort,
    location: location,
    market: marketId,
    offset: offset
});

export const userMainSearchShopsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_SHOPS_SUCCESS,
        payload: data,
    };
};

export const userMainSearchShopsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_FAILURE,
    payload: error,
});

export const userMainSearchShopKeywords = (req) => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORD_START,
    payload: req,
});

export const userMainSearchShopKeywordsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORD_SUCCESS,
        payload: data,
    };
};
export const userMainSearchShopKeywordsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORD_FAILURE,
    payload: error,
});

export const userMainGetShopInfoById = (reqData, isShopDomain) => ({
    type: ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_START,
    payload: reqData,
    isShopDomain: isShopDomain
});

export const userMainGetShopInfoByIdSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_SUCCESS,
        payload: data,
    };
};
export const userMainGetShopInfoByIdFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_FAILURE,
    payload: error,
});

export const userMainGetStoreFavlist = (marketId) => ({
    type: ActionTypes.USER_MAIN_GET_STORE_FAVLIST_START,
    marketId: marketId,
});

export const userMainGetStoreFavlistSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_STORE_FAVLIST_SUCCESS,
        payload: data,
    };
};
export const userMainGetStoreFavlistFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_STORE_FAVLIST_FAILURE,
    payload: error,
});

export const userMainAddStoreToFavlist = (shopId) => ({
    type: ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_START,
    shopId: shopId,
});

export const userMainAddStoreToFavlistSuccess = (data, storeId) => {
    return {
        type: ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS,
        payload: data,
        storeId: storeId
    };
};
export const userMainAddStoreToFavlistFailure = (error) => ({
    type: ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_FAILURE,
    payload: error,
});

export const userMainRemoveStoreFromFavlist = (reqData) => ({
    type: ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_START,
    payload: reqData,
});

export const userMainRemoveStoreFromFavlistSuccess = (data, storeId) => {
    return {
        type: ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_SUCCESS,
        payload: data,
        storeId: storeId
    };
};
export const userMainRemoveStoreFromFavlistFailure = (error) => ({
    type: ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_FAILURE,
    payload: error,
});




