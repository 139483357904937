//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";


import * as Actions       from "actions";
import * as ActionTypes   from 'constants/ActionTypes';

import ActiveSubscription from "components/elements/seller/ActiveSubscription";

import "./styles.scss";
import {useEffect} from "react";
// Component Imports


const Home = (props) => {

  const {
    serviceError, reqStatus, isLoading,
    getSellerActiveSubscriptions, getAvailableSubscriptions, activePlans, availablePlans
  } = props;

  useEffect(() => {
    getSellerActiveSubscriptions();
    getAvailableSubscriptions();
  }, []);


  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);

    return (
      <Page className="subscriptions-home">
        <h4 className="page-title">Active Subscriptions</h4>
        <Row gutter={[24, 24]}>
          {activePlans.map((activePlan, index) => {
            return (
              <Col xs={24} md={12} lg={8} key={activePlan._id}>
                <ActiveSubscription data={activePlan} />
              </Col>
            );
          })}
        </Row>
      </Page>
    );
}
// Mapping Props and State.
const _Home = connect(
  ({ subscriptions, appBase }) => ({
    appBase: appBase,
    reqStatus: subscriptions.reqStatus,
    isLoading: subscriptions.isLoading,
    serviceError: subscriptions.serviceError,
    activePlans: subscriptions.activePlans,
    availablePlans: subscriptions.availablePlans,
    subscriptioncart: subscriptions.subscriptioncart,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(Home);

export { _Home as default };
