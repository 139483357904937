/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import * as Actions from "actions";
import {Container} from "@mui/material";

import ShopProductSearch from "components/widgets/user/ShopProductSearch";

import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import ShopHeaderInfo from "components/elements/user/shops/ShopHeaderInfo";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import ShopProductsList from "components/elements/user/products/ShopProductList";


import {getLogo, isFeatureSupported, isMarketPlace} from "helpers/utils";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import CustomFlexBox from "components/CustomFlexBox";
import {Button} from "components/Atoms";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import StoriesPlayer from "components/StoriesPlayer/StoriesPlayer";


const ShopSiteHomeMain = (props) => {

  const [displayModeState, setDisplayModeState] = useState("MAIN");

  const [state] = useState({
    layoutColumns: 3,
    storeid: "NA",
  });

  const {
    match, bootupInfo, history, shopinfo
  } = props;

  const {video, collections, logo} = shopinfo;

  const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

  useEffect(() => {

    props.userResetSearchProducts();
    //props.usergetProductCategories(props.bootupInfo.appState.subdomain_shopId,
    //  true, props.bootupInfo.appState.isWebsite);

  }, []);

    const refetch = () => {
    //    props.usergetProductCategories(props.bootupInfo.appState.subdomain_shopId,
    //        true, props.bootupInfo.appState.isWebsite);
    };


    let shopStoryData = [];
    let stories = [];

    if (collections && collections.length > 0) {
        collections.map((item) => {
            stories.push({
                video: item.cdn.secure_url,
            });
        });
    };

    shopStoryData.push(
        {
            user: {
                avatar: logo && logo[0] && logo[0].cdn.secure_url,
                name: "Latest Collection",
                userLink: ""
            },
            stories: stories,
        }
    );

  const handleResetResultsStory = () => {
    setDisplayModeState("MAIN");
  };

  const renderResultsAsStories = () => (
      <div>
        <StoriesPlayer
            storiesData={shopStoryData}
            onCancel={() => handleResetResultsStory()}
        />
      </div>
  );

    const renderDisplayMode = (displayMode) => {

      switch (displayMode) {
        case "STORIES":
          return renderResultsAsStories();

        case "MAIN" :
              default:
        return (
          <div>
            {/* SEO Support */}
            <Helmet>
              <title>{shopinfo.title}</title>
              <meta name="description" content={shopinfo.title}/>
            </Helmet>
            {/* SEO Support End */}

            {isMarketPlace() && <ShopHeaderNav history={history}/>}

            {isMarketPlace() ?
                <ShopHeaderMini {...props} /> : <ShopHeaderInfo {...props} />}

            <ShopProductSearch {...props} shopId={shopId} colSize={state.layoutColumns}/>

            {/*
      <Container>
        <CategoryTitleSlider {...props} refetch={refetch} />
      </Container>
      */}

            <ShopProductPopularSlider shopId={shopId}/>

            <ShopOffersSlider shopId={shopId}/>

            {/*
      <CategoryMenuSlider {...props} />
      */}

            <ShopProductNewSlider shopId={shopId}/>

            <ShopProductsList shopId={shopId}/>

            {isFeatureSupported("shopreels") && collections && collections.length > 0 &&
                <CustomFlexBox sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  width: '100%',
                  bottom: 70,
                  justifyContent: 'center',
                  zIndex: 'modal'
                }}>

                  <Button
                      sx={{marginRight: '2px', marginLeft: '4px'}}
                      size="large"
                      color="primary"
                      variant="contained"
                      onClick={() => setDisplayModeState("STORIES")}
                  >
                    <SlideshowIcon/> &nbsp; Latest Collection
                  </Button>
                </CustomFlexBox>
            }

          </div>
        );

      }


    }

  return (
      <Container>
        {renderDisplayMode(displayModeState)}
      </Container>
  );

};

ShopSiteHomeMain.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
  usergetProductCategories: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const _ShopSiteHomeMain = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
      shopinfo: bootupInfo.shopinfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    categoryList: userproducts.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopSiteHomeMain);

export { _ShopSiteHomeMain as default };
