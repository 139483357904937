/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container, Card, Box, Typography, IconButton, Button,
} from "@mui/material";
import {getImage, getLogo, getURI, isLocalMarket} from "helpers/utils";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MockProductList from "components/elements/main/MockProductList";

import * as Actions from "actions";
import {H2, H3, H4, H6} from "components/CustomTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {Link} from "react-router-dom";

const UserShopList = (props) => {
  const {
    isLoading, match, storeswishlist, userMainGetStoreFavlist, userMainRemoveStoreFromFavlist, history,
    bootupInfo
  } = props;

  const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : match.params.marketId);

  useEffect(() => {
    userMainGetStoreFavlist(marketId);
  }, []);

  if (isLoading) {
    return <MockProductList />;
  }

  return (
    <div>
      {storeswishlist.length > 0
        ? (
          <Container maxWidth="md">
            {storeswishlist.map((shopitem, index) => shopitem.shop && (
                <Box key={`${index + 1}`} p={1}>
                  <Box className="styled-card" width="100%" display="flex" p={1}
                       onClick={() => history.push(getURI(process.env.REACT_APP_TYPE, "shop", shopitem.shop._id, "", ""))}>
                    <Box width="20%" order={1}>
                      <Typography align="center">
                        <Link
                            className="styled-link"
                            to={getURI(process.env.REACT_APP_TYPE, "shop", shopitem.shop._id, "", "")}
                        >
                          <img
                              alt=""
                              className="img-product"
                              src={getLogo(shopitem.shop && shopitem.shop.logo)}
                          />
                        </Link>
                      </Typography>
                    </Box>
                    <Box width="80%" order={1} ml={2}>
                      <Typography align="left">
                        <H2 fontSize={16}>
                          {shopitem.shop && shopitem.shop.title}
                          <IconButton
                              size="small"
                              onClick={() => userMainRemoveStoreFromFavlist(shopitem)}
                          >
                            <HighlightOffIcon color="secondary" />
                          </IconButton>
                        </H2>
                        <H6 fontSize={16}>
                          {shopitem.shop && shopitem.shop.areaname}
                        </H6>
                      </Typography>
                      <Typography align="left">
                        <H6 fontSize={12}>
                          {shopitem.shop.cityname}
                        </H6>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
            ))}
          </Container>
        )
        : (
              <Box align="center">
                <H6 fontSize={16}>No {process.env.REACT_APP_SHOP_TAG} added to favourite list</H6>
              </Box>
        )}
    </div>
  );
};

UserShopList.defaultProps = {
  isLoading: false,
};

UserShopList.propTypes = {
  isLoading: PropTypes.bool,
  storeswishlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  userMainGetStoreFavlist: PropTypes.func.isRequired,
  userMainAddStoreToFavlist: PropTypes.func.isRequired,
  userMainRemoveStoreFromFavlist: PropTypes.func.isRequired,
};

const userShopList = connect(
  ({ userMainShops, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainShops.reqStatus,
    isLoading: userMainShops.isLoading,
    serviceError: userMainShops.serviceError,
    storeswishlist: userMainShops.storeswishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserShopList);

export default userShopList;
