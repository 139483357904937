import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


import "./style.scss";

import {Slider6} from "services/script";
import Slider from "react-slick/lib";

class AdminSellerProductStatusSelector extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const {
        productstatus,
        onStatusSelect
    } = this.props;

    return (
          <Box>
            <Box pb={2}>
            <Slider {...Slider6} className="product-4 product-m arrow">
              <span style={{ paddingRight: "5px" }}>
              {productstatus === "all" ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("")}>All</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("")}>All</Button>
              }
              </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "nocategory" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("nocategory")} >No Category</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("nocategory")} >No Category</Button>
                }
                </span>

                <span style={{ paddingRight: "5px" }}>
                {productstatus === "notapproved" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("notapproved")} >Not Approved</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("notapproved")} >Not Approved</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "approved" ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("approved")} >Approved</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("approved")} >Approved</Button>
              }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "ready" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("ready")} >Ready</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("ready")} >Ready</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {productstatus === "notready" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("notready")} >Not Ready</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("notready")} >Not Ready</Button>
                }
                </span>

            </Slider>
            </Box>
          </Box>
    );
  }
}

export default AdminSellerProductStatusSelector;
