/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions/admin/admin.orders";
import * as ActionTypes from "constants/ActionTypes";

import {
  TextField, InputAdornment, IconButton, Container, Typography, Chip, Box,
} from "@mui/material";

import {
  Notification,
} from "components/Atoms";

import Autocomplete from "@mui/material/Autocomplete";
import AdminSellerOrder from "components/elements/admin/orders/AdminSellerOrder";
import AdminSellerOrderEditor from "components/elements/admin/orders/AdminSellerOrderEditor";
import MockProductList from "components/elements/main/MockProductList";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { H6 } from "components/CustomTypography";

const AdminOrderSearch = (props) => {

  const [state, setState] = useState({
    limit: 20,
    hasMoreItems: true,
    breakPoint: "lg",
    bShowSearchModal: false,
    searchvalue: "Search Orders",
  });
  const [orderState, setOrderState] = useState("");
  const [orderModeState, setOrderModeState] = useState("PREVIEW");
  const [optionValues, setOptionValues] = useState([]);

  const {
    serviceError, reqStatus, isLoadingOrder,
    adminGetOrderDetail, adminSearchOrders, adminUpdateOrder,
    adminSearchOrderKeywords,
    adminResetSearchShopKeywords, adminResetSearchShops,
    orders, orderdetail, searchkeywords,
    match, history, symbol = "$",
    isLoadingShop, shopId, isShop, limit
  } = props;

  const { keywords } = searchkeywords;

  const {
    resetvalue,
  } = state;

  const { query } = match.params;

  useEffect(() => {
    if (query) {
      adminResetSearchShops();
      adminSearchOrders(query, shopId, isShop, limit);
    } else {
      adminSearchOrders("", shopId, isShop, limit);
    }
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_UPDATE_ORDER_SUCCESS:
        adminSearchOrders(query);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const runSearch = (keyword) => {
    adminResetSearchShopKeywords();
    setState({ searchquery: keyword });
    setState({ resetvalue: "" });

    if (isShop) {
      history.push(`/orders/shop/${shopId}/osearch/${keyword}`);
    } else {
      history.push(`/orders/osearch/${keyword}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      runSearch(e.target.value);
    }
  };

  const setKeyword = (option, type) => {
    if (type === "selectOption") {
      runSearch(option.target.innerText);
    }
  };

  const runKeywordSearch = (value) => {
    setState({ resetvalue: value });
    if ((value.length > 1)) {
      adminSearchOrderKeywords(value, shopId, isShop);
    }
  };

  const selectKeywordSearch = (value) => {
    if (isShop)
      history.push(`/orders/shop/${shopId}/osearch/${value}`)
    else
      history.push(`/orders/osearch/${value}`)
  };

  const handleDelete = () => {
    if (isShop)
      history.push(`/orders/shop/${shopId}`);
      else
    history.push("/orders");
  };

  const editOrder = (order) => {
    adminUpdateOrder(order);
    setOrderModeState("PREVIEW");
  };

  const handleOrderEdit = (mode, order, reset) => () => {
    setOrderModeState(mode);
    if (reset) {
      setOrderState({
        selectedOrder: {
          order_id: "", _id: "", status: "", order_type: "", user: "",
        },
      });
    } else {
      adminGetOrderDetail(order._id);
      setOrderState({
        selectedOrder: {
          order_id: order.order_id, _id: order._id, status: order.status, order_type: order.order_type, user: order.user,
        },
      });
    }
  };

  useEffect(() => {
    setOptionValues(keywords);
  }, [keywords]);

  const MobileViewOnClick = () => {
    if (window.innerWidth <= 500 && (match.path !== "/orders/osearch" || match.path !== "/orders/shop/:shopId/osearch")) {
      history.push(isShop ? `/orders/shop/${shopId}/osearch` : "/orders/osearch");
    }
  };

  let isModalSearch = false;


  const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No order found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  const renderResults = () => (
      records.map((order, index) => (
          <AdminSellerOrder
              className="product-item"
              key={`${index + 1}`}
              order={order}
              editOrderStatus={handleOrderEdit("EDIT", order, false)}
          />
      ))
  );

  const renderSearchHelp = () => (
      <Container>
        <Box>
          {
            window.innerWidth <= 500
            && (match.path === "/orders/osearch" || match.path === "/orders/shop/:shopId/osearch")
            && optionValues.map((element) => (
                <Container>
                  <Typography onClick={() => selectKeywordSearch(element.label)}>
                    <IconButton disabled size="large">
                      <SearchIcon />
                    </IconButton>
                    {element.label}
                  </Typography>
                </Container>
            ))
          }
        </Box>
      </Container>
  );

  const renderSearchModule = () => (
      <>

        <Container onClick={MobileViewOnClick}>
          <Box>
            {
              window.innerWidth <= 500
              && (match.path === "/orders/osearch" || match.path === "/orders/shop/:shopId/osearch") ? isModalSearch = true : isModalSearch = false
            }
            <Autocomplete
                id="autocomplete2"
                onClose={setKeyword}
                disableClearable
                freeSolo
                inputValue={resetvalue}
                onOpen={() => setState({ ...state, selectedState: false })}
                value={resetvalue}
                onKeyPress={handleKeyPress}
                onSelect={(input) => runKeywordSearch(input.target.value)}
                options={window.innerWidth <= 500 ? [] : optionValues.map((element) => element.label)}
                renderInput={(params) => (
                    <TextField
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                <IconButton size="large" onClick={() => props.history.push("/")}>
                                  <ArrowBackIosIcon />
                                </IconButton>
                              </InputAdornment>
                          ),
                        }}
                        {...params}
                        type="search"
                        size="large"
                        autoFocus={isModalSearch}
                        label={`Search Orders`}
                        placeholder={`Search Orders`}
                        margin="normal"
                    />
                )}
            />
          </Box>
        </Container>

        {
          renderSearchHelp()
        }

        <Container>
          {query && (
              <Chip
                  label={query}
                  onDelete={handleDelete}
              />
          )}
        </Container>
        { match.path !== "/" &&
        (match.path === "/orders/osearch/:query" ||
            match.path === "/orders/shop/:shopId" ||
            match.path === "/orders/shop/:shopId/osearch/:query" ||
            match.path === "/shops/shop/:shopId" ||
            match.path === "/orders") && (
            <div className="product-wrapper-grid">
              <div className="container-fluid">
                {isLoadingOrder ? <MockProductList />
                    : records.length > 0
                        ? renderResults()
                        : renderNoResults()}
              </div>
            </div>
        )}
      </>
  );

  const renderOrderEdit = (orderMode) => {
    switch (orderMode) {
      case "EDIT":
        return (
            <AdminSellerOrderEditor
                setState={(value, field) => setOrderState({
                  ...state,
                  selectedOrder: {
                    ...selectedOrder,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                isLoading={isLoadingOrder}
                orderdetail={orderdetail}
                selectedOrder={selectedOrder}
                updateOrderClicked={editOrder}
                onCancel={() => setOrderModeState("PREVIEW")}
            />
        );
      case "EDIT_SET":
      default:
        return (
            <AdminSellerOrderEditor
                setState={(value, field) => setOrderState({
                  ...state,
                  selectedOrder: {
                    ...selectedOrder,
                    [field]: value,
                  },
                })}
                symbol={symbol}
                isLoading={isLoadingOrder}
                orderdetail={orderdetail}
                selectedOrder={selectedOrder}
                updateOrderClicked={editOrder}
                onCancel={() => setOrderModeState("PREVIEW")}
            />
        );
    }
  };

  const { selectedOrder = {} } = orderState;
  const { records } = orders;

  return (
      <Container>
        {orderModeState === "PREVIEW" ? (
            renderSearchModule()
        ) : (
            renderOrderEdit(orderModeState)
        )}
      </Container>
  );


};

AdminOrderSearch.defaultProps = {
  currency: "",
};

AdminOrderSearch.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
  adminGetOrderDetail: PropTypes.func.isRequired,
  adminSearchOrders: PropTypes.func.isRequired,
  adminSearchOrderKeywords: PropTypes.func.isRequired,
  adminResetSearchShopKeywords: PropTypes.func.isRequired,
  adminResetSearchShops: PropTypes.func.isRequired,
  adminUpdateOrder: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminOrderSearchComponent = connect(
  ({ adminOrders, bootupInfo, adminShops }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: adminOrders.reqStatus,
    isLoadingOrder: adminOrders.isLoading,
    serviceError: adminOrders.serviceError,
    orders: adminOrders.searchorderList,
    searchkeywords: adminOrders.searchkeywords,
    orderdetail: adminOrders.orderdetail,
    shopDetail: adminShops.shopDetail,
    isLoadingShop: adminShops.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminOrderSearch);

export { AdminOrderSearchComponent as default };
