/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Box, Card, IconButton, Button,
} from "@mui/material";
import {getDistance, getImage, getImageOrLogo, getLogo} from "helpers/utils";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";
import "./UserSellerOffer.scss";
import * as Actions from "actions";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {H6, H2} from "components/CustomTypography";

const UserSellerOffer = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const history = useHistory();

  const {
    offer = {}, viewOffer, isDirect
  } = props;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

    const handleShopButtonClick = () => {
        history.push(`/offers/${offer.id}`);
    };

  return (
      <Box pb={3} onClick={isDirect ? handleShopButtonClick : viewOffer}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px">
          <LazyImage
              src={getImageOrLogo(offer.images, offer.logo)}
              width="100%"
              alt={offer.title}
              mx="auto"
          />
        </HoverBox>
        <H2 textTransform="capitalize" fontSize={14} textAlign="left" title={offer.name} ellipsis>
          {offer.name}
        </H2>
          <H6 fontSize={10} textAlign="left">
              {offer.title}
          </H6>
        <H6 fontSize={10} textAlign="left">
            {offer.valid_date}
        </H6>
      </Box>
  );
};

export default UserSellerOffer;
