import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { DATE_FORMAT } from "config/constants";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import {
    getLocalDate,
    getTimeSlot,
    getImage,
    isShopSiteAndSubdomain,
    isMarketPlace,
    getURI,
    getPriceCurrency
} from "helpers/utils";
import {
    Avatar, Box, Button, Card, Container, IconButton, Typography,
} from "@mui/material";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CustomSectionCreator from "components/CustomSectionCreator";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "actions";
import * as ActionsBase from "actions/core/core.actions";
import ImagesDownload from "components/elements/main/ImagesDownload";
import * as ActionTypes from "constants/ActionTypes";
import {Notification} from "components/Atoms";
import ButtonComponent from "components/Button";
import {H2, H4} from "components/CustomTypography";

const SubscriptionOrderSuccess = (props) => {

  const { location } = props;

  const {
    order_id, _id, plans, total_price, total_service, total_order, total_tax,
      created_at, order_type, status, paymentstatus
  } = location.state || {};

    const {
        serviceError, reqStatus, isLoading, currency,
        shopinfo, userUpdateOrderStatus, history
    } = props;

    const [copySuccess, setCopySuccess] = useState('Copy');

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    };

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };


    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.USER_UPDATE_ORDER_STATUS_SUCCESS:
                history.push(getURI(process.env.REACT_APP_TYPE, "shop_orders", shopinfo.id, "", ""));
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

    const updateOrder = () => {
        userUpdateOrderStatus({ order_id: _id, status: "paid", message: "" });
    };

    const renderOrderInformation = () => (
    <Box ml={2}>
        <H2 mb={2} fontSize={16}>Order # {order_id}</H2>
    </Box>
  );


    const renderPaymentInformation = () => (
        <Box ml={2}>
            <H2 mb={2} fontSize={16}> Payment Status: {paymentstatus} </H2>
        </Box>
    );

  const renderOrderItems = () => (
    <Box>
        {plans.map((item, index) => (
            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>
                        {item.plan.title}
                    </H2>
                    <H4 fontSize={12}>
                        {item.plan.description}
                    </H4>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>
                        {getPriceCurrency(item.price, currency)}
                    </H2>
                </Box>
            </Box>
        ))}
    </Box>
  );


    const renderOrderTotal = () => (
        <Box>
            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Subtotal: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_price, currency)}</H2>
                </Box>
            </Box>

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Service Charge: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_service, currency)}</H2>
                </Box>
            </Box>

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Taxes: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_tax, currency)}</H2>
                </Box>
            </Box>

            <hr className="solid" />

            <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="80%" order={1} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>Total: </H2>
                </Box>
                <Box width="20%" order={2} ml={2}>
                    <H2 mb={2} fontSize={16}  ellipsis>{getPriceCurrency(total_order, currency)}</H2>
                </Box>
            </Box>
        </Box>
    );


  return (
    (order_id)
      ? (
        <div>
            <CustomSectionCreator isSubtle={true} title="Order Information" />
          <Container>
              <Box>
                  {renderOrderInformation()}
                  <hr className="solid" />
                  {renderPaymentInformation()}
                  <hr className="solid" />
                  {renderOrderItems()}
                  <hr className="solid" />
                  {renderOrderTotal()}
              </Box>
          </Container>
        </div>
      )
      : (
        <section className="p-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="error-section">
                  <h1>404</h1>
                  <h2>page not found</h2>
                  <a href="/" className="btn btn-solid">back to home</a>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
  );
};

SubscriptionOrderSuccess.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};


// Mapping Props and State.
const subscriptionOrderSuccess = connect(
    ({ bootupInfo, userorders }) => ({
        bootupInfo,
        currency: bootupInfo.symbol,
        shopinfo: bootupInfo.shopinfo,
        reqStatus: userorders.reqStatus,
        isLoading: userorders.isLoading,
        serviceError: userorders.serviceError,
    }),
    (dispatch) => bindActionCreators({ ...Actions, ...ActionsBase }, dispatch),
)(SubscriptionOrderSuccess);

export { subscriptionOrderSuccess as default };

