/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Snackbar, Divider, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import CustomSnackBar from "components/CustomSnackBar";
import * as ActionTypes from "constants/ActionTypes";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ResendOTP } from "otp-input-react";
import {H4} from "components/CustomTypography";
import Link from "@mui/material/Link";

const LoginPostOTPForm = ({
  loginPostOTP, loginPreOTP, OTP_USER_MOBILE, OTP_USER_COUNTRYCODE, serviceError, reqStatus, showPasswordLogin, showOTPLogin
}) => {
  const [otp, setOtp] = useState("");
  const [blur, setBlur] = useState(false);
  const { mobile } = OTP_USER_MOBILE;
  const { code } = OTP_USER_COUNTRYCODE;

    const onRetry = () => {
        loginPreOTP(code, mobile);
    };


    const onFinish = (otp) => {
      setOtp(otp);
      if (otp.length > 5)
        loginPostOTP({ otp, code, mobile });
  };

  const onBlur = () => {
    setBlur(true);
  };

  return (
      <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
      >
          <Grid item mt={2}>
              <H4 fontSize="16px">
                  Enter OTP sent to: {mobile}
              </H4>
          </Grid>
          <Grid item mt={2}>
              <TextFieldComponent
                  fullWidth
                  type="number"
                  margin="normal"
                  label="Enter OTP:"
                  required
                  //helperText={blur && !otp && "This field is required"}
                  onChange={(event) => { onFinish(event.target.value); setBlur(true); }}
                  //onBlur={onBlur}
                  //error={blur && !otp}
              />
              <ResendOTP onResendClick={onRetry} />
          </Grid>
          <Grid container>
              <Grid item>
                  {reqStatus === ActionTypes.LOGIN_POST_OTP_FAILURE && (
                      <CustomSnackBar
                          status
                          errorMessage={serviceError}
                      />
                  )}
              </Grid>
              <Grid container item sx={{height: "100px"}} direction="column" justifyContent="flex-end" alignItems="flex-end">
                  {/*<Grid item>
                      <Link onClick={showOTPLogin} variant="button">
                          {"Wrong number? Retry"}
                      </Link>
                  </Grid>
                  */}
                  <Grid item>
                      <Link onClick={showPasswordLogin} variant="button">
                          {"Password Login"}
                      </Link>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
  );
};

LoginPostOTPForm.propTypes = {
  loginPostOTP: PropTypes.func.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  OTP_USER: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LoginPostOTPForm;
