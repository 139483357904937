// @flow
import * as ActionTypes from 'constants/ActionTypes';
import { CURRENCY } from "config/constants";

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  bootupCompleted: false,
  userDetails: {
    photo: "https://randomuser.me/api/portraits/men/11.jpg",
    name: "",
    phone: "",
    email: "",
    token: "",
    image: [],
    userlevel: "",
  },
  cities: [],
  citylocations: {
    areas: [],
  },
  addresssuggestions: [],
  shop: [],
  shopinfo: {
    logo: [],
    images: [],
    video: [],
    collections: [],
    paymentqrcode: [],
  },
  marketinfo: {
    logo: [],
    images: [],
  },
  shippingAddress: [],
  billingAddress: {
    name: "",
    area: "",
    address: "",
    city: "",
    phone: "",
  },
  paymentorder: {},
  paymentverify: {},
  storeContext: {},
  symbol: CURRENCY,
  appState: {
    isWebsite: false,
    isSeller: false,
    isMarketMall: false,
    subdomain_shopId: "",
    subdomain_areaId: "",
    shopId: "",
    subdomain: "",
  }
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.LOGIN_START:
    case ActionTypes.LOGIN_PRE_OTP_START:
    case ActionTypes.LOGIN_POST_OTP_START:
    case ActionTypes.SOCIAL_LOGIN_START:
    case ActionTypes.DELETE_ACCOUNT_START:
    case ActionTypes.REGISTER_START:
    case ActionTypes.RESET_PASSWORD_START:
    case ActionTypes.GET_USER_PROFILE_START:
    case ActionTypes.UPDATE_USER_PROFILE_UUID_START:
    case ActionTypes.UPDATE_USER_PROFILE_LOCATION_START:
    case ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_START:
    case ActionTypes.GET_SHOP_USER_PROFILE_START:
    case ActionTypes.GET_SHOP_AND_USER_PROFILE_START:
    case ActionTypes.GET_SHOP_PROFILE_START:
    case ActionTypes.GET_MARKET_AND_USER_PROFILE_START:
    case ActionTypes.GET_MARKET_PROFILE_START:
    case ActionTypes.UPDATE_USER_PROFILE_START:
    case ActionTypes.GET_BILLING_ADDRESS_START:
    case ActionTypes.UPDATE_BILLING_ADDRESS_START:
    case ActionTypes.ADD_SHIPPING_ADDRESS_START:
    case ActionTypes.GET_SHIPPING_ADDRESS_START:
    case ActionTypes.UPDATE_SHIPPING_ADDRESS_START:
    case ActionTypes.DELETE_SHIPPING_ADDRESS_START:
    case ActionTypes.UPDATE_USER_PIC_START:
    case ActionTypes.GET_CITIES_START:
    case ActionTypes.GET_CITY_LOCATIONS_START:
    case ActionTypes.GET_ADDRESS_SUGGESTIONS_START:
    case ActionTypes.COMMON_CREATE_PAYMENT_ORDER_START:
    case ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_START:
    case ActionTypes.ADD_APP_ERROR_LOG_START:{
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
        serviceCode: ""
      };
    }

    /* Common Failures */
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.LOGIN_PRE_OTP_FAILURE:
    case ActionTypes.LOGIN_POST_OTP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload.data.statusMessage,
        serviceCode: action.payload.data.statusCode,
        bootupCompleted: false,
      };
    }
    case ActionTypes.SOCIAL_LOGIN_FAILURE:
    case ActionTypes.DELETE_ACCOUNT_FAILURE:
    case ActionTypes.REGISTER_FAILURE:
    case ActionTypes.RESET_PASSWORD_FAILURE:
    case ActionTypes.GET_USER_PROFILE_FAILURE:
    case ActionTypes.UPDATE_USER_PROFILE_UUID_FAILURE:
    case ActionTypes.UPDATE_USER_PROFILE_LOCATION_FAILURE:
    case ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_FAILURE:
    case ActionTypes.GET_SHOP_USER_PROFILE_FAILURE:
    case ActionTypes.GET_SHOP_AND_USER_PROFILE_FAILURE:
    case ActionTypes.GET_SHOP_PROFILE_FAILURE:
    case ActionTypes.GET_MARKET_AND_USER_PROFILE_FAILURE:
    case ActionTypes.GET_MARKET_PROFILE_FAILURE:
    case ActionTypes.UPDATE_USER_PROFILE_FAILURE:
    case ActionTypes.GET_BILLING_ADDRESS_FAILURE:
    case ActionTypes.UPDATE_BILLING_ADDRESS_FAILURE:
    case ActionTypes.ADD_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.GET_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.UPDATE_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.DELETE_SHIPPING_ADDRESS_FAILURE:
    case ActionTypes.UPDATE_USER_PIC_FAILURE:
    case ActionTypes.GET_CITIES_FAILURE:
    case ActionTypes.GET_CITY_LOCATIONS_FAILURE:
    case ActionTypes.GET_ADDRESS_SUGGESTIONS_FAILURE:
    case ActionTypes.COMMON_CREATE_PAYMENT_ORDER_FAILURE:
    case ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_FAILURE:
    case ActionTypes.ADD_APP_ERROR_LOG_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        bootupCompleted: false,
        serviceError: action.payload,
      };
    }

    /* Success Handlers */
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.LOGIN_POST_OTP_SUCCESS:
    case ActionTypes.SOCIAL_LOGIN_SUCCESS: {
      localStorage.setItem("token", action.payload.user.token);
      localStorage.setItem("isAuthorized", true);
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload.message,
        serviceCode: action.payload.statusCode,
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload.user },
        shop: action.payload.shop,
      };
    }

    case ActionTypes.LOGIN_PRE_OTP_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload.statusMessage,
        serviceCode: action.payload.statusCode,
        bootupCompleted: false,
      };
    }

    case ActionTypes.DELETE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: false,
      };
    }

    case ActionTypes.LOGOUT_SUCCESS: {
      localStorage.setItem("isAuthorized", false);
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: false,
        userDetails: { ...initialState.userDetails },
      };
    }

    case ActionTypes.REGISTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload.statusMessage,
        serviceCode: action.payload.statusCode,
        bootupCompleted: true,
        userDetails: action.payload,
      };
    }

    case ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload.statusMessage,
        serviceCode: action.payload.statusCode,
        bootupCompleted: true,
        userDetails: action.payload,
      };
    }

    case ActionTypes.GET_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload.user },
        shop: action.payload.shop,
      };
    }

    case ActionTypes.UPDATE_USER_PROFILE_UUID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    case ActionTypes.UPDATE_USER_PROFILE_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    case ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    case ActionTypes.GET_SHOP_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload.user },
        shop: action.payload.shop,
      };
    }

    case ActionTypes.GET_SHOP_AND_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload.user },
        shopinfo: action.payload.shop,
      };
    }

    case ActionTypes.GET_SHOP_PROFILE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          shopinfo: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          bootupCompleted: false,
          shopinfo: [],
        };
      }
    }

    case ActionTypes.GET_MARKET_AND_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
        bootupCompleted: true,
        userDetails: { ...state.userDetails, ...action.payload.user },
        marketinfo: action.payload.market,
      };
    }

    case ActionTypes.GET_MARKET_PROFILE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          marketinfo: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          bootupCompleted: false,
          marketinfo: [],
        };
      }
    }

    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          userDetails: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          userDetails: {
            photo: "",
            name: "",
            phone: "",
            email: "",
            token: "",
            image: []
          },
        };
      }
    }

    case ActionTypes.GET_BILLING_ADDRESS_SUCCESS:
    case ActionTypes.UPDATE_BILLING_ADDRESS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          billingAddress: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          bootupCompleted: true,
          billingAddress: {
            name: "",
            area: "",
            address: "",
            city: "",
            phone: "",
          },
        };
      }
    }

    case ActionTypes.GET_SHIPPING_ADDRESS_SUCCESS: {
      const { shipping } = action.payload.response;
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shippingAddress: shipping,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shippingAddress: [],
        };
      }
    }
    case ActionTypes.UPDATE_SHIPPING_ADDRESS_SUCCESS:
    case ActionTypes.DELETE_SHIPPING_ADDRESS_SUCCESS:
    case ActionTypes.ADD_SHIPPING_ADDRESS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }
    case ActionTypes.UPDATE_USER_PIC_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          userDetails: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          userDetails: {
            name: "",
            phone: "",
            email: "",
            image: []
          },
        };
      };
    }


    case ActionTypes.GET_CITIES_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          cities: action.payload.response.cities,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          cities: [],
        };
      }
    }

    case ActionTypes.GET_CITY_LOCATIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          citylocations: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          citylocations: {
            areas: []
          },
        };
      }
    }

    case ActionTypes.GET_ADDRESS_SUGGESTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          addresssuggestions: action.payload.response.suggestions,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          addresssuggestions: [],
        };
      }
    }

    case ActionTypes.COMMON_CREATE_PAYMENT_ORDER_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          paymentorder: action.payload.response,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          paymentorder: {},
        };
      }
    }

    case ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          paymentverify: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          paymentverify: {},
        };
      }
    }

    case ActionTypes.ADD_APP_ERROR_LOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    default:
      return state;
  }
}
