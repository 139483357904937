// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/seller/seller.orders";

function* getCommonOrders(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/orders/search` + (action.category ? `?category=` + action.category.id : ""),
      action.payload
    );
    yield put(Actions.getCommonOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.getCommonOrdersFailure(error));
  }
}

function* getStoreOrderCategories(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/categories/get-active-categories`,
      action.payload
    );
    yield put(Actions.getStoreOrderCategoriesSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreOrderCategoriesFailure(error));
  }
}

function* getStoreOrders(action: Object) {
  try {

    const searchQuery1 = (action.orderstatus ? `status=` + action.orderstatus : "");
    const searchQuery2 =  (action.ordertype ? `ordertype=` + action.ordertype  : "" );
    const query = (searchQuery1) ? (searchQuery2 ? "?" + searchQuery1 + "&" + searchQuery2 : "?" + searchQuery1) : (searchQuery2 ? "?" + searchQuery2 : "") ;

    const response = yield API.get(
      `/sellers/orders/search` + query,
      action.payload
    );
    yield put(Actions.getStoreOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreOrdersFailure(error));
  }
}

function* getSellerOrderDetail(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/orders/get-order/` + action.payload,
    );
    yield put(Actions.getSellerOrderDetailSuccess(response));
  } catch (error) {
    yield put(Actions.getSellerOrderDetailFailure(error));
  }
}

function* addOrder(action: Object) {
  try {
    const response = yield API.post(
      `sellers/orders/add-product-common/` + action.payload.id,
      action.payload
    );
    yield put(Actions.addOrderSuccess(response));
  } catch (error) {
    yield put(Actions.addOrderFailure(error));
  }
}

function* updateOrderStatus(action: Object) {
  try {
    const response = yield API.post(
        `sellers/orders/update`,
        action.payload
    );
    yield put(Actions.updateOrderStatusSuccess(response));
  } catch (error) {
    yield put(Actions.updateOrderStatusFailure(error));
  }
}

function* removeOrder(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/orders/remove-product-common/` + action.payload,
      action.payload
    );
    yield put(Actions.removeOrderSuccess(response));
  } catch (error) {
    yield put(Actions.removeOrderFailure(error));
  }
}
function* addOrderUPC(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/orders/add-product-using-upc/` + action.payload,
      action.payload
    );
    yield put(Actions.addOrderUPCSuccess(response));
  } catch (error) {
    yield put(Actions.addOrderUPCFailure(error));
  }
}
function* searchOrders(action: Object) {
  try {
    const searchQuery = action.searchType + "=" + action.payload ;
    const response = yield API.get(
      `/sellers/orders/search?` + searchQuery,
      action.payload
    );
    yield put(Actions.searchOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.searchOrdersFailure(error));
  }
}
function* searchKeywords(action: Object) {
  try {
    //const searchQuery = ((action.isUPCBased) ? (`upc=` + action.upc) : (`name=` + action.payload +  (action.category ? `&category=` + action.category.id : "")));
    const response = yield API.get(
        `/sellers/orders/seller-orders-keywords?name=` + action.payload,
        action.payload
    );
    yield put(Actions.searchKeywordsSuccess(response.response));
  } catch (error) {
    yield put(Actions.searchKeywordsFailure(error));
  }
}
function* checkOrders(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/orders/check-product-common/:id`,
      action.payload
    );
    yield put(Actions.checkOrdersSuccess(response));
  } catch (error) {
    yield put(Actions.checkOrdersFailure(error));
  }
}

export default function* OrdersSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.GET_COMMON_ORDERS_START, getCommonOrders);
  yield takeLatest(ActionTypes.GET_STORE_ORDER_CATEGORIES_START, getStoreOrderCategories);
  yield takeLatest(ActionTypes.GET_STORE_ORDERS_START, getStoreOrders);
  yield takeLatest(ActionTypes.GET_ORDER_DETAIL_START, getSellerOrderDetail);
  yield takeLatest(ActionTypes.ADD_ORDER_START, addOrder);
  yield takeLatest(ActionTypes.UPDATE_ORDER_STATUS_START, updateOrderStatus);
  yield takeLatest(ActionTypes.REMOVE_ORDER_START, removeOrder);
  yield takeLatest(ActionTypes.ADD_ORDER_UPC_START, addOrderUPC);
  yield takeLatest(ActionTypes.SEARCH_ORDERS_START, searchOrders);
  yield takeLatest(ActionTypes.SEARCH_ORDERS_KEYWORDS_START, searchKeywords);
  yield takeLatest(ActionTypes.CHECK_ORDER_START, checkOrders);
}
