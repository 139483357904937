/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Link, useHistory} from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {getImage, getURI, getPrice, isMarketPlace} from "helpers/utils";
import CircularProgress from '@mui/material/CircularProgress';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';



import "./ShopProductItem.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {FlexBetween, FlexBox} from "components/flex-box";
import { H2, H6 } from "components/CustomTypography";
import BazaarButton from "components/BazaarButton";
import {AddBox, Favorite} from "@mui/icons-material";
import ArrowRight from "@mui/icons-material/ArrowRight";


const ShopProductItem = (props) => {
  const [state, setState] = useState({
    open: false,
    stock: "InStock",
    quantity: 1,
    image: "",
  });

    const history = useHistory();

  const {
    product, viewProduct, viewProductWithStory, onAddToCartClicked, onAddToWishlistClicked,
      isDirect, currency, shopId, inCart, isCartLoading,
      cartAddingPid, link,
  } = props;

  // const onClickHandle = (img) => {
  //   setState({ image: img });
  // };

  const vegIconUrl = `${process.env.PUBLIC_URL}/assets/images/GreenIcon.png`;
  const nonVegIconUrl = `${process.env.PUBLIC_URL}/assets/images/RedIcon.png`;

  const onOpenModal = () => {
    setState({ open: true });
  };

  const onCloseModal = () => {
    setState({ open: false });
  };

    const handleProductButtonClick = () => {

        if (link) {
            history.push(`${link}`);
        } else {
            history.push(getURI(process.env.REACT_APP_TYPE, "shop_product", shopId, product.id, ""));
        }
    };

  return (
      <Box pb={3} pl={1} pr={1}>
        <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={isDirect ? handleProductButtonClick : viewProductWithStory}>
          <LazyImage
              src={getImage(product.images)}
              width="100%"
              alt={product.name}
              mx="auto"
          />
        </HoverBox>
        <FlexBetween mt={0}>
            <Box width="10%" noWrap sx={{ overflow: 'hidden' }} onClick={isDirect ? handleProductButtonClick : viewProduct}>
                <ArrowRight fontSize="small" color="primary" />
            </Box>
          <Box width="70%" noWrap sx={{ overflow: 'hidden' }} onClick={isDirect ? handleProductButtonClick : viewProduct}>
            <H2 fontSize={14} width="100%" textAlign="left" title={product.name} >
                {getPrice(product, currency)}
            </H2>
            <FlexBox alignItems="center">
              <H6 fontSize={10} textTransform="capitalize" textAlign="left" ellipsis>
                  {product.name}
              </H6>
            </FlexBox>
          </Box>

          <Box width="20%">
              {product.is_price_visible &&
                  (inCart ?
                          <ShoppingCartIcon color="primary" />
                          :
                      (isCartLoading && cartAddingPid === product.id ?
                              <CircularProgress size="1rem"/>
                              :
                          <BazaarButton
                              color="primary"
                              disableRipple
                              disableElevation
                              onClick={onAddToCartClicked}
                              sx={{
                                  height: "0",
                                  alignItems: "flex-start",
                                  "&:hover": {
                                      backgroundColor: "transparent",
                                  },
                              }}
                          >
                              <AddBox color="primary" fontSize="medium"/>
                          </BazaarButton>
                      )
                  )
              }
          </Box>
        </FlexBetween>
      </Box>
  );
};

ShopProductItem.defaultProps = {
    currency: "",
};

ShopProductItem.propTypes = {
    currency: PropTypes.string,
    product: PropTypes.objectOf(PropTypes.any).isRequired,
  onAddToCartClicked: PropTypes.func.isRequired,
  onAddToWishlistClicked: PropTypes.func.isRequired,
};

const ShopProductItemComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
      currency: bootupInfo.symbol,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    productprices: userproducts.productprices,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopProductItem);

export default ShopProductItemComponent;
