/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import "components/elements/main/index.scss";
import {Box, Button, IconButton} from "@mui/material";
import {FlexBetween, FlexBox} from "components/flex-box";
import MockShopHeader from "components/elements/main/MockShopHeader";

import { Avatar, Card, Typography } from "@mui/material";
import { H2, H5, H6 } from "components/CustomTypography";
import {getLogo, openGoogleMapLocation } from "helpers/utils";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import * as ActionTypes from "constants/ActionTypes";
import CircularProgress from "@mui/material/CircularProgress";
import PlaceIcon from "@mui/icons-material/Place";

const MarketHeaderMini = (props) => {

    const {
        serviceError, reqStatus, isLoading, isLoadingBootInfo, bootupInfo,
        history, marketinfo, userMainAddStoreToFavlist, getMarketProfile
    } = props;

  const [state] = useState({
    layoutColumns: 3,
  });

    const handleBackHome = () => {
        history.push(`/shops`);
    };

    useEffect(() => {
        switch (reqStatus) {
            case ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS:
                getMarketProfile(bootupInfo.appState.subdomain_marketId, false);
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);


    return (
        <Box mb={2}
        >

            <Box
                sx={{
                    display: "flex",
                    p: "4px 4px",
                    height: "100%",
                    alignItems: "center",
                    bgcolor: "primary.main"
                }}
            >
                {isLoadingBootInfo && <MockShopHeader height={60} />}
                {!isLoadingBootInfo &&
                    <>
                        <Box m={2} flex="1 1 0">
                            <FlexBetween flexWrap="wrap">
                                <div>
                                    <H2 fontSize="24px" fontWeight="bold" color="#ffffff">{marketinfo.name}</H2>
                                </div>
                            </FlexBetween>
                        </Box>
                    </>
                }
            </Box>
        </Box>
    );
};

MarketHeaderMini.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const marketHeaderMini = connect(
  ({ bootupInfo, userMainShops }) => ({
    bootupInfo,
    reqStatus: userMainShops.reqStatus,
    isLoading: userMainShops.isLoading,
    serviceError: userMainShops.serviceError,
      isLoadingBootInfo: bootupInfo.isLoading,
      marketinfo: bootupInfo.marketinfo,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(MarketHeaderMini);

export { marketHeaderMini as default };
