import * as yup from "yup";

export const schema = yup.object().shape({
  status: yup.string().required("Status is required field"),
});



export const getButtonDataCancel = (onCancel) => [
  {
    name: "Back",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false
  },
];
