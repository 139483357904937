// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminResetSearchShops = () => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_RESET
});

export const adminResetSearchShopKeywords = () => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORDS_RESET
});

export const adminGetLocationShopList = (req) => ({
  type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_START,
  payload: req,
});

export const adminGetLocationShopListSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_SUCCESS,
    payload: data,
  };
};
export const adminGetShopLocationListFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_LOCATION_STORE_LIST_FAILURE,
  payload: error,
});

export const adminSearchShops = (name, searchtype, startdate, filter, sort, executive, location, city, offset) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_START,
  name: name,
  searchtype: searchtype,
  startdate: startdate,
  filter: filter,
  sort: sort,
  executive: executive,
  location: location,
  city: city,
  offset: offset,
});

export const adminSearchShopsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SHOPS_SUCCESS,
    payload: data,
  };
};
export const adminSearchShopsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_FAILURE,
  payload: error,
});

export const adminSearchShopKeywords = (searchtype, name, location) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_START,
  searchtype: searchtype,
  name: name,
  location: location,
});

export const adminSearchShopKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_SUCCESS,
    payload: data,
  };
};
export const adminSearchShopKeywordsFailure = (error) => ({
  type: ActionTypes.ADMIN_SEARCH_SHOPS_KEYWORD_FAILURE,
  payload: error,
});


export const adminGetShopInfoById = (shopId) => ({
  type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_START,
  shopId: shopId,
});

export const adminGetShopInfoByIdSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_SUCCESS,
    payload: data,
  };
};

export const adminGetShopInfoByIdFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SHOP_INFO_BYID_FAILURE,
  payload: error,
});

export const adminUpdateShopStatus = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_START,
  payload: req,
});

export const adminUpdateShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminUpdateShopProfile = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_PROFILE_START,
  payload: req,
});

export const adminUpdateShopProfileSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopProfileFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_PROFILE_FAILURE,
  payload: error,
});

export const adminUpdateShopLogoImage = (shop_id, formData) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_LOGO_START,
  payload: shop_id,
  formData: formData
});

export const adminUpdateShopLogoImageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_LOGO_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopLogoImageFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_LOGO_FAILURE,
  payload: error,
});

export const adminUpdateShopVideo = (shop_id, formData) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_VIDEO_START,
  payload: shop_id,
  formData: formData
});

export const adminUpdateShopVideoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_VIDEO_SUCCESS,
    payload: data,
  };
};

export const adminUpdateShopVideoFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_VIDEO_FAILURE,
  payload: error,
});

export const adminAddShopCollectionVideo = (shop_id, formData) => ({
  type: ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_START,
  payload: shop_id,
  formData: formData
});

export const adminAddShopCollectionVideoSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_SUCCESS,
    payload: data,
  };
};

export const adminAddShopCollectionVideoFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_STORE_COLLECTION_VIDEO_FAILURE,
  payload: error,
});

export const adminUpdateShopURLLock = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_START,
  payload: req,
});

export const adminUpdateShopURLLockSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopURLLockFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_URLLOCK_FAILURE,
  payload: error,
});

export const adminUpdateShopSalesLead = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_START,
  payload: req,
});

export const adminUpdateShopSalesLeadSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopSalesLeadFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_SALESLEAD_FAILURE,
  payload: error,
});

export const adminEnableShopStatus = (req) => ({
  type: ActionTypes.ADMIN_ENABLE_STORE_STATUS_START,
  payload: req,
});

export const adminEnableShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ENABLE_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminEnableShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_ENABLE_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminDisableShopStatus = (req) => ({
  type: ActionTypes.ADMIN_DISABLE_STORE_STATUS_START,
  payload: req,
});

export const adminDisableShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_DISABLE_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminDisableShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_DISABLE_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminUpdateShopDomain = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_START,
  payload: req,
});

export const adminUpdateShopDomainSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopDomainFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_DOMAIN_FAILURE,
  payload: error,
});

export const adminUpdateShopSocial = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_START,
  payload: req,
});

export const adminUpdateShopSocialSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopSocialFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_SOCIAL_FAILURE,
  payload: error,
});

export const adminDisplayDeliveredShopStatus = (reqData, formData) => ({
  type: ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_START,
  payload: reqData,
  formData: formData
});

export const adminDisplayDeliveredShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminDisplayDeliveredShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_DISPLAY_DELIVERED_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminDisplayDoneShopStatus = (req) => ({
  type: ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_START,
  payload: req,
});

export const adminDisplayDoneShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminDisplayDoneShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_DISPLAY_DONE_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminDisplayRequestShopStatus = (req) => ({
  type: ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_START,
  payload: req,
});

export const adminDisplayRequestShopStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminDisplayRequestShopStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_DISPLAY_REQUEST_STORE_STATUS_FAILURE,
  payload: error,
});

export const adminDisplayPrintedStatus = (req) => ({
  type: ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_START,
  payload: req,
});

export const adminDisplayPrintedStatusSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_SUCCESS,
    payload: data,
  };
};
export const adminDisplayPrintedStatusFailure = (error) => ({
  type: ActionTypes.ADMIN_DISPLAY_PRINTED_STATUS_FAILURE,
  payload: error,
});


export const adminUpdateShopVerified = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_START,
  payload: req,
});

export const adminUpdateShopVerifiedSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_SUCCESS,
    payload: data,
  };
};
export const adminUpdateShopVerifiedFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STORE_VERIFIED_FAILURE,
  payload: error,
});

export const adminSendShopMessage = (req) => ({
  type: ActionTypes.ADMIN_SEND_MESSAGE_START,
  payload: req,
});

export const adminSendShopMessageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEND_MESSAGE_SUCCESS,
    payload: data,
  };
};
export const adminSendShopMessageFailure = (error) => ({
  type: ActionTypes.ADMIN_SEND_MESSAGE_FAILURE,
  payload: error,
});

export const adminSendShopInternalMessage = (req) => ({
  type: ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_START,
  payload: req,
});

export const adminSendShopInternalMessageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_SUCCESS,
    payload: data,
  };
};
export const adminSendShopInternalMessageFailure = (error) => ({
  type: ActionTypes.ADMIN_SEND_INTERNAL_MESSAGE_FAILURE,
  payload: error,
});
