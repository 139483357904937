import React from 'react';
import ContentLoader from "react-content-loader";

const MockProductBox = (props) => (
    <ContentLoader
        speed={2}
        width={350}
        height={190}
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    >
        <rect x="0" y="25" rx="0" ry="0" width="340" height="24" />
        <rect x="0" y="66" rx="0" ry="0" width="160" height="160" />
        <rect x="180" y="70" rx="0" ry="0" width="160" height="160" />
    </ContentLoader>
)

export default MockProductBox;
