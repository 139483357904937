// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "../../constants/ActionTypes";
import * as Actions from "../../actions";
import {USER_UPDATE_ORDER_STATUS_START} from "../../constants/ActionTypes";


function* getShopOrderlistProducts(action: Object) {
  try {

    const searchQuery = action.payload
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        '/users/orders/get-orders?shop=' + searchQuery,
        { withCredentials: true }
        );

    yield put(Actions.getShopOrderlistProductsSuccess(response.response));
  } catch (error) {
    yield put(Actions.getShopOrderlistProductsFailure(error));
  }
}

function* getShopOrderDetail(action: Object) {
  try {
    const searchQuery =  action.payload
        + `?shop=` + action.shopId
        + ((action.isShopDomain) ? "&isDomain=1" : "");

    const response = yield API.get(
        `/users/orders/get-order/` + searchQuery,
        { withCredentials: true }
    );
    yield put(Actions.getShopOrderDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.getShopOrderDetailFailure(error));
  }
}

function* userUpdateOrderStatus(action: Object) {
  try {
    const response = yield API.post(
        `users/orders/update`,
        action.payload
    );
    yield put(Actions.userUpdateOrderStatusSuccess(response));
  } catch (error) {
    yield put(Actions.userUpdateOrderStatusFailure(error));
  }
}

export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_GET_SHOP_ORDERLIST_PRODUCTS_START,    getShopOrderlistProducts);
  yield takeLatest(ActionTypes.USER_GET_SHOP_ORDER_DETAIL_START,          getShopOrderDetail);
  yield takeLatest(ActionTypes.USER_UPDATE_ORDER_STATUS_START,            userUpdateOrderStatus);
}
