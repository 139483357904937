// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  availablePlans: [],
  subscriptionorders:[],
  subscriptionorder: {
    plans: [],
    messages: [],
    total_order: "",
    total_tax: "",
    total_service: "",
    total_price: "",
    status: "",
    paymentstatus: "",
    paymentmethod: "",
    payment_id: ""
  },
};

export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_START:
    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_START:
    case ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START:
    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_START:
    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_START:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_FAILURE:
    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_FAILURE:
    case ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_FAILURE:
    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_FAILURE:
    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          subscriptionorders: action.payload.response.subscriptionorders,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          subscriptionorders: [],
        };
      }
    }

    case ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          subscriptionorder: action.payload.response.order,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          subscriptionorder: [],
        };
      }
    }

    case ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          availablePlans: action.payload.response.plans,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          availablePlans: [],
        };
      }
    }

    case ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    default:
      return state;
  }
}
