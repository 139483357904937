/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import "components/elements/main/index.scss";

import UserCombinedSearch from "components/widgets/user/UserCombinedSearch";
import UserProductPopularSlider from "components/elements/user/products/UserProductPopularSlider";
import UserProductNewSlider from "components/elements/user/products/UserProductNewSlider";
import UserOffersFavNearbySlider from "components/elements/user/offers/UserOffersFavNearbySlider";
import UserOffersNearbySlider from "components/elements/user/offers/UserOffersNearbySlider";
import UserOffersLatestSlider from "components/elements/user/offers/UserOffersLatestSlider";
import UserShopsNearSlider from "components/elements/user/shops/UserShopsNearSlider";
import UserShopsPopularSlider from "components/elements/user/shops/UserShopsPopularSlider";
import UserShopsLatestSlider from "components/elements/user/shops/UserShopsLatestSlider";
import UserProductCategorySlider from "components/elements/user/products/UserProductCategorySlider";
import UserShopLocationSlider from "components/elements/user/UserShopLocationSlider";
import {isLocalMarket} from "helpers/utils";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "actions";
import MarketHeaderMini from "components/elements/user/shops/MarketHeaderMini";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";

const MarketUserHomeMain = (props) => {
  const [state, setState] = useState({
    layoutColumns: 3,
  });

    const {
        match, bootupInfo, userResetSearchProducts, history
    } = props;

    const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : "");


  useEffect(() => {
      userResetSearchProducts();
  }, []);

  return (
      <div>
          <ShopHeaderNav history={history} />

          {isLocalMarket() && <MarketHeaderMini />}

          <UserCombinedSearch marketId={marketId} {...props} colSize={state.layoutColumns} />

          <UserProductCategorySlider isDirect={true} {...props} />

          <UserProductPopularSlider marketId={marketId} />

          <UserShopsPopularSlider marketId={marketId} />

          <UserProductNewSlider marketId={marketId} />

          <UserShopsLatestSlider marketId={marketId} />

          <UserOffersLatestSlider marketId={marketId} />

      </div>
  );
};


MarketUserHomeMain.propTypes = {
    userResetSearchProducts: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const marketUserHomeMain = connect(
    ({ userproducts, bootupInfo }) => ({
        bootupInfo,
        reqStatus: userproducts.reqStatus,
        isLoading: userproducts.isLoading,
        serviceError: userproducts.serviceError,
        categoryList: userproducts.categoryList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(MarketUserHomeMain);

export { marketUserHomeMain as default };


