/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import "components/elements/main/index.scss";
import {Box, Container, Button} from "@mui/material";
import {H2, H3, H4, H5, H6} from "components/CustomTypography";
import { handleExternalPage } from "services/utils";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import ButtonComponent from "components/Button";



const AdminShopHeader = (props) => {

  const [state] = useState({
    layoutColumns: 3,
  });

    const {
        match, adminGetShopInfoById, shopDetail, isLoading
    } = props;

    const { shopId } = match.params;


    useEffect(() => {
        adminGetShopInfoById(shopId)
    }, []);



    const url = (shopDetail.domain && shopDetail.subdomain) ? "https://" + shopDetail.subdomain + "." + shopDetail.domain : "";

    const renderShopInfo = () => (
        <Container>
            <Box>
                <H2 textAlign="left" title={shopDetail.title} ellipsis>
                    {shopDetail.title}
                </H2>
                <H5>
                    {shopDetail.addressline} {shopDetail.cityname}
                </H5>
                <H5>
                {shopDetail.phone}
                </H5>
                <H5>
                    {!isLoading && <a href="#" onClick={handleExternalPage(url)}>{`${url || ""}`}</a>}
                    {!isLoading && (<div>
                        <ButtonComponent
                            sx={{
                                mt: 2,
                                mb: 2,
                            }}
                            type="primary"
                            variant="contained"
                            name="Visit Online Shop"
                            size="small"
                            onClick={handleExternalPage(url)}
                        />
                        </div>)
                    }
                </H5>
            </Box>
        </Container>
    );


    return (
        <div>
            <ButtonComponent
                type="primary"
                variant="outlined"
                name="< Back"
                size="small"
                onClick={() => props.history.goBack()}
            />
            {isLoading && <Box m={10}><MockGeneralHeader  /></Box>}
            {!isLoading && renderShopInfo()}
        </div>
    );
};

AdminShopHeader.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const AdminShopHeaderComponent = connect(
  ({ adminShops, bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: adminShops.reqStatus,
    isLoading: adminShops.isLoading,
    serviceError: adminShops.serviceError,
      shopDetail: adminShops.shopDetail,

  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopHeader);

export { AdminShopHeaderComponent as default };
