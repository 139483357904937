/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import { Container, Box } from "@mui/material";

// Import Swiper styles
import 'swiper/swiper.min.css';
import './UserShopsNearSlider.scss';

// import Custom Components
import UserSellerShop from "components/elements/user/shops/UserSellerShop";
import MockProductBox from "components/elements/main/MockProductBox";
import CustomSectionCreator from "components/CustomSectionCreator";

import {isFeatureSupported} from "helpers/utils";

const UserShopsNearSlider = (props) => {

  const {
      nearbyshops, userMainAddStoreToFavlist, userMainGetLocationStoreList, isLoading, marketId
  } = props;
  const { shops } = nearbyshops;

    useEffect(() => {
        userMainGetLocationStoreList("", "", "", marketId);
    }, []);

    const breakpoints = {
        300: {
            slidesPerView: 2.0,
            spaceBetween: 20,
        },
        340: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        380: {
            slidesPerView: 2.4,
            spaceBetween: 20,
        },
        420: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 2.8,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    const renderResults = () => (
        <Container>
            {<CustomSectionCreator title={'Nearby ' + process.env.REACT_APP_SHOP_TITLE} seeLink="/shops/location/nearby" seeMoreLinkTitle={isFeatureSupported("shopreels") ? "View " + process.env.REACT_APP_REELS_TAG : "View more"} seeMoreLink={isFeatureSupported("shopreels") ? "/shops/location/nearby?ls=1" : "/shops/location/nearby"} /> }
            <swiper-container slides-per-view="1.8" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
                { shops.map((shop, index) => (
                    <swiper-slide key={index}>
                        <Box sx={{ width: 150 }}>
                        <UserSellerShop
                            shop={shop}
                            isDirect={true}
                            link={isFeatureSupported("shopreels") ? "/shops/location/nearby?ls=1&li=" + index : ""}
                            onAddToFavlistClicked={() => userMainAddStoreToFavlist(shop)}
                            key={`${index + 1}`}
                        />
                        </Box>
                    </swiper-slide>
                ))}
            </swiper-container>
        </Container>
    );

  return (
<>
      {isLoading ? <MockProductBox />
              : shops.length > 0
                  ? renderResults()
                  : ""}

</>

  );
};

UserShopsNearSlider.propTypes = {
    nearbyshops: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainGetLocationStoreList: PropTypes.func.isRequired,
    userMainAddStoreToFavlist: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userShopsNearSlider = connect(
  ({ userMainShops, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainShops.reqStatus,
    isLoading: userMainShops.isLoading,
    serviceError: userMainShops.serviceError,
      nearbyshops: userMainShops.nearbyshops,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserShopsNearSlider);

export { userShopsNearSlider as default };
