//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Notification,
} from "components/Atoms";

import * as Actions from "actions/core/core.actions";
import * as ActionTypes from 'constants/ActionTypes';

import "./styles.scss";
import {useEffect} from "react";

const LoginSocial = (props) => {

  const {
    isLoading,
    Sociallogin,
    userDetails,
    serviceError,
    reqStatus,
  } = props;


  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    Sociallogin();
  }, []);

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
        break;
      default:
        if (serviceError) {
          let feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          this.sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);

  return (
      <section className="page-home">
        <div className="note line">
          <span className="title bold">{userDetails.name}</span>
        </div>
        <div style={{ marginBottom: "10px" }} />

      </section>
  );
}
// Mapping Props and State.
const _LoginSocial = connect(
  ({ stores, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(LoginSocial);

export { _LoginSocial as default };
