import React, {Component, useEffect, useState} from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

import * as Actions from "actions";
import MockOrderDetail from "components/elements/main/MockOrderDetail";
import {Box, Container, Typography} from "@mui/material";
import {H2, H4, H5} from "components/CustomTypography";
import ButtonComponent from "components/Button";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import {getLocalDate} from "helpers/utils";
import {DATE_FORMAT} from "config/constants";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopHeaderMiniOverlay from "components/elements/user/shops/ShopHeaderMiniOverlay";
import ShopHeaderOurCollection from "components/elements/user/shops/ShopHeaderOurCollection";


const UserOfferDetail = (props) => {
  const [prices, setPrices] = useState({
    total: 0, totalDiscount: 0,
  });
  const {
    isLoading, offerdetail, symbol, match, userMainGetOfferDetail, bootupInfo, history,
  } = props;

  const { offerId } = match.params;

  const {
    shop,
  } = offerdetail;

  useEffect(() => {
      userMainGetOfferDetail(offerId);
  }, []);

  if (isLoading) {
    return <MockOrderDetail />;
  }

  const renderOfferInformation = () => (
      <Box mt={2}>
          <H2 fontSize="24px" textAlign="left" title={offerdetail.name} ellipsis>
              {offerdetail.name}
          </H2>
          <H4 fontSize="14px" textAlign="left" title={offerdetail.detail} ellipsis>
              {offerdetail.description}
          </H4>
          <H4 fontSize="14px" textAlign="left" title={offerdetail.valid_date} ellipsis>
              {offerdetail.valid_date && getLocalDate(offerdetail.valid_date, DATE_FORMAT)}
          </H4>
      </Box>
  );

    const handleShopButtonClick = () => {
        history.push(`/shop/${shop._id}`);
    };

  const renderShopInformation = () => (
      <Box>
          <H2 textAlign="left" title={shop.title} ellipsis>
              {shop.title}
          </H2>
          <H5>
              {!isLoading && (<div>
                  <ButtonComponent
                      sx={{
                          mt: 2,
                          mb: 2,
                      }}
                      type="primary"
                      variant="contained"
                      name="Visit Shop"
                      size="small"
                      onClick={handleShopButtonClick}
                  />
              </div>)
              }
          </H5>
      </Box>
  );

  return (
        <Container>
            <ShopHeaderNav history={history} />
            {shop &&
                <>
                    <ShopHeaderMiniOverlay history={history} shopId={shop._id}/>
                    {renderOfferInformation()}
                    <ShopHeaderOurCollection />
                    <ShopProductPopularSlider shopId={shop._id} />
                    <ShopProductNewSlider shopId={shop._id} />
                </>
            }

        </Container>
  );

}

// Mapping Props and State.
const userOfferDetail = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
      offerdetail: userMainOffers.offerdetail,
    symbol: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOfferDetail);

export { userOfferDetail as default };
