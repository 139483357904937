import React from 'react';
import { useCallback, useState } from "react";
import Slider from '@mui/material/Slider';
import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import CustomFlexBox from "./CustomFlexBox";
import {Box} from "@mui/material";
import ButtonComponent from "components/Button";
import Images from "components/elements/main/Images";
import FileButton from "components/elements/main/FileButton";
import CameraButton from "components/elements/main/CameraButton";
import {dataURIToBlob, resizeFile} from "services/utils";

const PhotoUpload = ({ images, addImageCallback, removeImageCallback, scanImageCallback, cancelEdit, isRemoveAllowed, isScanAllowed, key, idx }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const [image, setImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      addImageCallback(croppedImage);
      setCroppedImage(null);
      setImage(null);

    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  const onClose = useCallback(() => {
    setCroppedImage(null);
  }, []);


  const cancelUpload = () => {
    setCroppedImage(null);
    setImage(null);
  }


  const addCameraImage = (file) => {

    (async () => {
      const cameraimage = await resizeFile(dataURIToBlob("data:image/jpeg;base64,"+file));
      setImage(cameraimage);
    })();
  }

  const addFileImage = (e) => {

    const files = Array.from(e.target.files)
    const errs = []
    const types = ['image/png', 'image/jpeg', 'image/gif', 'image/webp']
    files.forEach(async (file) => {

      if (types.every(type => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`)
      }

      if (file.size > 15000000) {
        errs.push(`'${file.name}' is too large, please pick a smaller file`)
      }

      if (errs.length) {
        return errs.forEach(err => this.sendNotification("error", {title: err, description: err}))
      }

      const fileimage = await resizeFile(file);
      setImage(fileimage);
    });
  }

  const filter = id => {
    return images.filter(image => image.cdn.public_id !== id)
  }

  const handleError = id => {
    setImage(filter(id))
  }

  return (
    <Box key={key}>
      <Box width="100%" alignItems="left" display="flex">
        <Box width="35%" >
          <FileButton idx={idx} onFileSelect={addFileImage} />
        </Box>
        <Box width="35%" >
          <CameraButton key={key} onChange={addCameraImage} />
        </Box>
        <Box width="30%" >
          <ButtonComponent
              sx={{
                m: 2,
                color: 'text.lightprimary',
              }}
              type="primary"
              variant="contained"
              onClick={cancelEdit}
              name="Done"
          />
        </Box>
      </Box>
      <Box
        className="container"
        style={{
          display: image === null || croppedImage !== null ? "none" : "block",
        }}
      >
        <CustomFlexBox sx={{
          position: 'fixed',
          width: '100%',
          top: 60,
          zIndex: 'modal' }}>
          <Box width="100%" alignItems="center" display="flex">
            <Box width="35%" >
              <label style={{color: "white", width: "80%"}} >
                Rotate
                <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="rotate"
                    onChange={(e, rotation) => setRotation(rotation)}
                    className="range"
                />
              </label>
            </Box>
            <Box width="35%" >
              <label style={{color: "white", width: "80%" }}>
                Zoom
                <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="zoom"
                    onChange={(e, zoom) => setZoom(zoom)}
                    className="range"
                />
              </label>
            </Box>
            <Box width="30%" >
            <ButtonComponent
                key={key}
                style={{
                  display: image === null || croppedImage !== null ? "none" : "block",
                }}
                sx={{marginRight: '2px'}}
                size="small"
                color="primary"
                variant="contained"
                onClick={showCroppedImage}
                name="Upload"
            />
            </Box>
          </Box>
        </CustomFlexBox>
        <Box className="crop-container">
          <Cropper
              key={key}
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={4 / 4}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            style={{
              cropAreaStyle: {
                opacity: 0.8,
                color: 'black',
              }
            }}
          />
        </Box>
      </Box>
      <Box className="cropped-image-container">
        {croppedImage && (
          <img className="cropped-image" src={croppedImage} alt="cropped" />
        )}
        {croppedImage && <button onClick={onClose}>close</button>}
      </Box>
      <Box>
        <Images
            isRemoveAllowed={isRemoveAllowed}
            images={images}
            removeImage={removeImageCallback}
            scanImage={scanImageCallback}
            isScanAllowed={isScanAllowed}
            onError={handleError}
        />
      </Box>
      <CustomFlexBox sx={{
        position: 'fixed',
        width: '100%',
        bottom: 80,
        justifyContent: 'center',
        zIndex: 'modal' }}>
            <ButtonComponent
                style={{
                  display: image === null || croppedImage !== null ? "none" : "block",
                }}
                sx={{marginRight: '2px'}}
                size="small"
                color="primary"
                variant="contained"
                onClick={cancelUpload}
                name="Cancel"
            />
      </CustomFlexBox>
    </Box>
  );
};

export default PhotoUpload;
