import React, { useEffect } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
  Notification,
    Pace
} from "components/Atoms";

import {Box, Container} from "@mui/material";
import PhotoUpload from "components/PhotoUpload";
import * as Actions         from "actions";

const AdminSalesQuickAddMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, saleslead, cancelEdit,
        adminAddSalesLeadImageQuick
    } = props;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };


    useEffect(() => {
        if (serviceError && reqStatus) {
            let feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        saleslead.images = [];
    }, [serviceError, reqStatus]);

    const addImageCallback = (file) => {

        const formData = new FormData()
        formData.append("image", file);
        adminAddSalesLeadImageQuick(formData);
    }


    const {images} = saleslead;

      return (
          <Container>
              {isLoading && <Pace color="#27ae60" height="2px" />}
              <Box >
                  <PhotoUpload
                            isRemoveAllowed={false}
                            images={images}
                            addImageCallback={addImageCallback}
                            cancelEdit={cancelEdit}
                  />
              </Box>
          </Container>
      );
};

// Mapping Props and State.
const adminSalesQuickAddMain = connect(
    ({ adminSales, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: adminSales.reqStatus,
        isLoading: adminSales.isLoading,
        serviceError: adminSales.serviceError,
        leadImageList: adminSales.leadImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(AdminSalesQuickAddMain);

export { adminSalesQuickAddMain as default };
