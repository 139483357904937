// @flow
import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,
  usersAddress: {},
  placeSuggestions: [],
  storeList: [],
};

export default function (state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */

    case ActionTypes.GET_USER_LOCATION_START:
    case ActionTypes.GET_PLACE_SUGGESTIONS_START:
    case ActionTypes.GET_STORES_FOR_LOCATION_START:
    case ActionTypes.ADD_SHOP_TO_ACCOUNT_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.GET_USER_LOCATION_FAILURE:
    case ActionTypes.GET_PLACE_SUGGESTIONS_FAILURE:
    case ActionTypes.GET_STORES_FOR_LOCATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    case ActionTypes.ADD_SHOP_TO_ACCOUNT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: action.payload && action.payload.message ? action.payload.message : action.payload.statusMessage,
      };
    }

    /* Success Handlers */
    case ActionTypes.GET_USER_LOCATION_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          userAddress: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          userAddress: {

          },
        };
      }
    }

    case ActionTypes.GET_PLACE_SUGGESTIONS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          placeSuggestions: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          placeSuggestions: [],
        };
      }
    }

    case ActionTypes.GET_STORES_FOR_LOCATION_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
          placeSuggestions: action.payload,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
          placeSuggestions: [],
        };
      }
    }

    case ActionTypes.ADD_SHOP_TO_ACCOUNT_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    default:
      return state;
  }
}
