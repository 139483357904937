/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card, Typography, IconButton, Container, Box, Button,
} from "@mui/material";
import {getImage, getLogo, getURI, isLocalMarket} from "helpers/utils";

import * as Actions from "actions";
import MockProductList from "components/elements/main/MockProductList";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {H2, H6} from "components/CustomTypography";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {Link} from "react-router-dom";

const WishList = (props) => {
  const {
    isLoading, match, wishlistItems, userMainGetWishlistProducts, userMainRemoveProductFromWishlist, history,
    bootupInfo
  } = props;

  const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : match.params.marketId);

  useEffect(() => {
    userMainGetWishlistProducts(marketId);
  }, []);

  if (isLoading) {
    return <MockProductList />;
  }

  return (
    <div>
      {wishlistItems.length > 0
        ? (
          <Container maxWidth="md">
            {wishlistItems.map((item, index) => (
                <Box key={`${index + 1}`} p={1}>
                  <Box className="styled-card" width="100%" display="flex" p={1}
                       onClick={() => history.push(getURI(process.env.REACT_APP_TYPE, "product", "", item.product.id, ""))}>
                    <Box width="20%" order={1}>
                      <Typography align="center">
                        <Link
                            className="styled-link"
                            to={getURI(process.env.REACT_APP_TYPE, "product", "", item.product.id, "")}
                        >
                          <img
                              alt=""
                              className="img-product"
                              src={getImage(item.product && item.product.images)}
                          />
                        </Link>
                      </Typography>
                    </Box>
                    <Box width="80%" order={1} ml={2}>
                      <Typography align="left">
                        <H2 fontSize={16}>
                          {item.product && item.product.name}
                          <IconButton
                              size="small"
                              onClick={() => userMainRemoveProductFromWishlist(item)}
                          >
                            <HighlightOffIcon color="secondary" />
                          </IconButton>
                        </H2>
                        <H6 fontSize={16}>
                          {item.product && item.product.description}
                        </H6>
                      </Typography>
                      <Typography align="left">
                        <H6 fontSize={12}>

                        </H6>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
            ))}
          </Container>
        )
        : (
              <Box align="center">
                <H6 fontSize={16}>No {process.env.REACT_APP_PRODUCT_TAG} added to favourite list</H6>
              </Box>
        )}
    </div>
  );
};

WishList.defaultProps = {
  isLoading: false,
};

WishList.propTypes = {
  isLoading: PropTypes.bool,
  wishlistItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  symbol: PropTypes.string,
  userMainGetWishlistProducts: PropTypes.func.isRequired,
  userMainRemoveProductFromWishlist: PropTypes.func.isRequired,
};

// Mapping Props and State.
const wishList = connect(
  ({ userMainWish, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainWish.reqStatus,
    isLoading: userMainWish.isLoading,
    serviceError: userMainWish.serviceError,
    wishlistItems: userMainWish.wishlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(WishList);

export default wishList;
