import * as React from "react";
import { Button } from "components/Atoms";
import { Link } from "react-router-dom";
import moment from "moment";
import { currencyFormatter } from "helpers/utils";

import "./style.scss";

const ActiveSubscription = (props) => {
  const { title, validity, expiry_date, plan } = props.data || {};
  const { description, duration, pricing } = plan;
  const expiryFromNow = moment(expiry_date).fromNow(); //format("MMM/DD/YYYY :hh:mm:A");
  return (
    <div className="active-subscription">
      <h4>{title}</h4>
      <div className="body">
        <div className="validity">
          <div className="value text large bold">{duration}</div>
          <p className="label"> Days</p>
        </div>
        <p className="description">{description}</p>
      </div>
      <h5 className="price">{currencyFormatter(pricing)}</h5>
      <div className="footer">
        <span className="expiry">
          <span className="label text light small">Expires </span>
          {expiryFromNow}
        </span>
        <Button type="secondary" size="large">
          View Details
        </Button>
      </div>
    </div>
  );
};

export default ActiveSubscription;
