/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */


// Profile
import UserProfileHome from "components/pages/user/home/UserProfileHome";


// Shop specific
import ShopSiteHomeMain from "components/pages/user/home/ShopSiteHomeMain";

// Products
import ShopProductPageSearch from "components/pages/user/products/ShopProductPageSearch";
import ShopProductPageDetail from "components/pages/user/products/ShopProductPageDetail";

// Orders -
import ShopOrdersList from "../components/pages/user/orders/ShopOrderList";
import ShopOrderDetail from "../components/pages/user/orders/ShopOrderDetail";

// Favourites -
import ShopWishList from "../components/pages/user/favourites/ShopWishList";
import UserFavs from "../components/pages/user/favourites/UserFavs";

// Checkout -
import shopCart from "../components/pages/user/checkout/shopCart";
import shopCheckOut from "../components/pages/user/checkout/shopCheckout";
import shopPaymentCheckout from "../components/pages/user/checkout/shopCheckout/payment-page";
import shopOrderSuccess from "../components/pages/user/checkout/shopCheckout/success-page";


export const MarketLocalCommonRoutes = [

  // shop pages on marketplace or local market
  // shop main page
  { path: "/shop/:shopId", component: ShopSiteHomeMain, exact: true },

  // shop products page
  { path: "/shop/:shopId/products", component: ShopProductPageSearch, exact: true },
  { path: "/shop/:shopId/products/sort/:sort", component: ShopProductPageSearch, exact: true },
  { path: "/shop/:shopId/products/search", component: ShopProductPageSearch, exact: true },
  { path: "/shop/:shopId/products/search/:query", component: ShopProductPageSearch, exact: true },
  { path: "/shop/:shopId/products/:prodId", component: ShopProductPageDetail, exact: true },

	// Checkout - common component with seller shop
  { path: "/shop/:shopId/cart", component: shopCart, exact: true },
  { path: "/shop/:shopId/cart/checkout", component: shopCheckOut },
  { path: "/shop/:shopId/cart/payment-page", component: shopPaymentCheckout },
  { path: "/shop/:shopId/cart/order-success", component: shopOrderSuccess },

  // profile - common component with seller shop
  { path: "/shop/:shopId/me", component: UserProfileHome, exact: true },

  // Orders - common component with seller shop
  { path: "/shop/:shopId/me/orders", component: ShopOrdersList, exact: true },
  { path: "/shop/:shopId/me/orders/:orderId", component: ShopOrderDetail, exact: true },

  // Favourites - products in shop - common component with seller shop
  { path: "/shop/:shopId/me/wishlist", component: ShopWishList, exact: true },


];
