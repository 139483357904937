import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {Box, Container, Typography} from "@mui/material";

import * as Actions from "actions/core/core.actions";

import PhotoUpload from "components/PhotoUpload";

const ProfileImageEditor = (props) => {
  const {
      serviceError, reqStatus, isLoading, userDetails, cancelEdit,
      updateUserPic,
  } = props;

    const { image } = userDetails;

  React.useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
  }, [serviceError, reqStatus]);

  const sendNotification = (type, { title, description = "" }) => {
    console.log({
      message: title,
      description,
    });
  };

    const addImageCallback = (file) => {
        const formData = new FormData()
        formData.append("image", file);
        updateUserPic(formData);
    }
    
    return (
        <Container>
            <Box >
                <PhotoUpload isRemoveAllowed={false}
                             images={image}
                             addImageCallback={addImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );
};

// Mapping Props and State.
const profileImageEditor = connect(
  ({ store, bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileImageEditor);

export { profileImageEditor as default };
