/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Slider from "react-multi-carousel";
import * as Actions from "actions";
import { Container, Grid } from "@mui/material";

import MockProductBox from "components/elements/main/MockProductBox";
import CustomSectionCreator from "components/CustomSectionCreator";
import AdminSellerShopItem from "components/elements/admin/shops/AdminSellerShopItem";

const AdminSellerShopsList = (props) => {

  const { searchkeywords, selectedShop, userDetails, adminSearchShopKeywords, isLoading } = props;

    useEffect(() => {
        adminSearchShopKeywords("exact", selectedShop.title, selectedShop.location);
    }, []);

    const renderResults = () => (
        <Container>
            <CustomSectionCreator isSubtle={true} title="Similar shops"  />
            <Grid item container md={9} xs={12} spacing={2}>
            { searchkeywords.keywords.map((shop, index) => (
                <Grid item xs={6} sm={4} key={index}>
                    <AdminSellerShopItem
                        shop={shop}
                        isSame={shop.value === selectedShop.shop_id}
                        isDirect={true}
                        key={`${index + 1}`}
                    />
                </Grid>
            ))}
            </Grid>
        </Container>
    );

  return (
<>
      {isLoading ? <MockProductBox />
              : AdminSellerShopsList.length > 0
                  ? renderResults()
                  : ""}

</>

  );
};

AdminSellerShopsList.propTypes = {
    searchkeywords: PropTypes.objectOf(PropTypes.any).isRequired,
    adminSearchShopKeywords: PropTypes.func.isRequired,
};

// Mapping Props and State.
const adminSellerShopsList = connect(
  ({ adminShops, bootupInfo }) => ({
      userDetails: bootupInfo.userDetails,
      reqStatus: adminShops.reqStatus,
      isLoading: adminShops.isLoading,
      serviceError: adminShops.serviceError,
      searchkeywords: adminShops.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSellerShopsList);

export { adminSellerShopsList as default };
