//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";
import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.registerstore";

import "./styles.scss";
import {useEffect} from "react";
// Component Imports

const ClaimStore = (props) => {

  const {
    isLoading,
    serviceError,
    reqStatus,
  } = props;

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      default:
        break;
    }
  }, [serviceError, reqStatus]);


    return (
      <Page className="claim-store">
        <h2 className="page-title">Claim Store</h2>
      </Page>
    );

}
// Mapping Props and State.
const _ClaimStore = connect(
  ({ store, appBase }) => ({
    appBase: appBase,
    reqStatus: store.reqStatus,
    isLoading: store.isLoading,
    serviceError: store.serviceError,
    overview: store.overview,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ClaimStore);

export { _ClaimStore as default };
