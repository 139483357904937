import React from 'react'
import { Image } from "components/Atoms";
import {IconButton, Typography, Box} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';

const downloadImage = (image, imagename) => {
    saveAs(image, imagename + '.jpg') // Put your image url here.
}

export default props =>
    props.images.map((image, i) =>
        <div onClick={() => downloadImage(image.cdn.secure_url, props.shopqrfilename)} style={{ backgroundColor: "#f1f1f1", marginBottom: 4 } } key={i}>
            <Image
                preview={{ visible: false }}
                width={200}
                src={image.cdn.secure_url}
                alt=''
                onError={() => props.onError(image.cdn.public_id)}
            />
            <Box><Typography> <IconButton><DownloadIcon /></IconButton> {props.message}</Typography></Box>

        </div>
    )
