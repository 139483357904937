// @flow
import React, {useCallback, useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { AppBar, Toolbar, Menu } from "components/Atoms";
import { withTranslate } from "react-redux-multilingual";

import { BreakPoints } from "config/constants";
import { SellerSideBarConfig } from "config/SellerNavigation";
import { UserSideBarConfig } from "config/UserNavigation";
import { SellerWebSideBarConfig } from "config/SellerWebNavigation";
import { SellerSiteSideBarConfig } from "config/SellerSiteNavigation";
import { AdminSideBarConfig } from "config/AdminNavigation";

import CartContainer from "containers/CartContainer";
import LocationContainer from "containers/LocationContainer";

import TopBarUser from "components/elements/main/headers/common/topbar-user";

import SidebarUser from "components/elements/main/sidebars/sidebar-user";
import SidebarSeller from "components/elements/main/sidebars/sidebar-seller";
import SidebarMain from "components/elements/main/sidebars/sidebar-main";
import SidebarAdmin from "components/elements/main/sidebars/sidebar-admin";
import {isMarketPlace, getCity, isCitySaved, isCurrentLocationSaved} from "helpers/utils";

import Topbar from "components/topbar/Topbar";
import Header from "components/header/Header";


import {
  ShoppingCartOutlined,
} from "@ant-design/icons";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import "./style.scss";

import makeStyles from "@mui/styles/makeStyles";

const { SubMenu } = Menu;

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  page: {
    background: "#f9f9f9",
    width: "100%",
    padding: theme.spacing(3),
  },
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  appbartitle: {
    background: "#f4f4f4",
    color: theme.palette.primary[900],
  },
  title: {
    padding: theme.spacing(2),
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  date: {
    flexGrow: 1,
  },
  toolbar: theme.mixins.toolbar,
  avatar: {
    marginLeft: theme.spacing(2),
  },
}));

const PageHeader = (props) => {

  const classes = useStyles();

  const [state, setState] = useState({
    collapsedWidth: 0,
    breakPoint: "lg",
    isHeaderVisible: false,
  });

  const [visible, setVisible] = useState(false);


    const manageLayoutChange = () => {
    const docElement = document.documentElement;
    const currentWindowWidth = window.innerWidth || (docElement && docElement.offsetWidth);

    if (currentWindowWidth < BreakPoints.md) {
      setState({ isHeaderVisible: false });
      return setState({ collapsedWidth: 0 });
    }
    setState({ isHeaderVisible: false });
    return setState({ collapsedWidth: 80 });
  };

  useEffect(() => {
    window.addEventListener("resize", manageLayoutChange);
  }, []);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const constructHeaderMenu = (navList) => {
    const { enabledFeatures, collapseSider } = props;

    return navList.map((navItem) => {
      const {
        id, name, icon, route, routestart, routeend, subMenu,
      } = navItem;

      let routelink;

      if (navItem.subMenu) {
        if (isMarketPlace() && props.routeType === "SHOPPAGE") {
          routelink = subMenu[0].routestart + this.props.subdomain_shopId + subMenu[0].routeend;
        } else if (process.env.REACT_APP_TYPE === "USERSITE") {
          routelink = subMenu[0].routestart + subMenu[0].routeend;
        } else {
          routelink = subMenu[0].route;
        }

        return (
          <SubMenu
            key={routelink}
            icon={subMenu[0] && subMenu[0].icon}
            className="sub-menu"
            title={name}
          >
            {constructHeaderMenu(subMenu)}
          </SubMenu>
        );
      }
      if (isMarketPlace() && props.routeType === "SHOPPAGE") {
        routelink = routestart + props.subdomain_shopId + routeend;
      } else if (process.env.REACT_APP_TYPE === "USERSITE") {
        routelink = routestart + routeend;
      } else {
        routelink = route;
      }

      return (
        <Menu.Item onClick={onClose} className="menu-item">
          <Link className="item-link" to={routelink}>
            <span className="title">{name}</span>
          </Link>
        </Menu.Item>
      );
    });
  };


  const constructTopbarMenuItems = (navList) => navList.map((navItem) => {
    const {
      id, name, icon, route, routestart, routeend, subMenu,
    } = navItem;

    let routelink;

    if (isMarketPlace() && props.routeType === "SHOPPAGE") {
      routelink = routestart + props.subdomain_shopId + routeend;
    } else if (process.env.REACT_APP_TYPE === "USERSITE") {
      routelink = routestart + routeend;
    } else {
      routelink = route;
    }

    return ({ routelink, name, icon });
  });


  const {
    titleContent = {},
    routeType,
    subdomain_shopId,
    shopinfo,
    pageRoute,
  } = props;

  let SideBarConfig, sidebar;
  let cart;
  let headerCart;
  let cartlink;
  let checkoutlink;
  let topBar;
  let titleTag;
  let titleBanner;
  let locationTag;
  let isTitleTag = false;

    switch (process.env.REACT_APP_TYPE) {
        case "USERSITE":
            SideBarConfig = SellerSiteSideBarConfig;
            titleTag = shopinfo.title;
            isTitleTag = true;
            cartlink = "/cart";
            checkoutlink = "/checkout";
            headerCart = (<CartContainer cartlink={cartlink} checkoutlink={checkoutlink}/>);
            topBar = <Topbar bgColor="primary" />;
            sidebar = (<SidebarUser open={visible} onOpen={showDrawer} onClose={onClose}/>)
            cart = (<span className="item cart"><Link to={cartlink}><ShoppingCartOutlined className="icon" /></Link></span>);
            break;

        case "SELLERAPP":
            titleBanner = `${process.env.REACT_APP_BANNER}`;
            cart = "";
            cartlink = "";
            checkoutlink = "";
            SideBarConfig = SellerSideBarConfig;
            sidebar = (<SidebarSeller open={visible} onOpen={showDrawer} onClose={onClose}/>);
            topBar = <Topbar bgColor="#eee" />;
            break;

        case "ADMINAPP":
            titleBanner = `${process.env.REACT_APP_BANNER}`;
            cart = "";
            cartlink = "";
            checkoutlink = "";
            SideBarConfig = AdminSideBarConfig;
            sidebar = (<SidebarAdmin open={visible} onOpen={showDrawer} onClose={onClose}/>);
            topBar = <Topbar />;
            break;

        case "LOCALMARKET":
            titleBanner = `${process.env.REACT_APP_BANNER}`;
            cart = "";
            cartlink = "";
            checkoutlink = "";
            SideBarConfig = UserSideBarConfig;
            sidebar = (<SidebarUser open={visible} onOpen={showDrawer} onClose={onClose} />)
            topBar = <Topbar bgColor="#eee" />;
            break;

        case "MARKETPLACE":
        default:
            titleBanner = `${process.env.REACT_APP_BANNER}`;
            if (routeType === "MAINPAGE") {
                SideBarConfig = UserSideBarConfig;
                cartlink = "/city";
                checkoutlink = "/checkout";
                headerCart = (<LocationContainer city="Vadodara"  />);
                topBar = <Topbar bgColor="#eee" />;
                sidebar = (<SidebarUser open={visible} onOpen={showDrawer} onClose={onClose} />)
                cart = (<span className="item cart"><Link to={cartlink}><LocationOnIcon /></Link></span>);
                locationTag = isCitySaved() ? getCity().name : "Current Location";

            } else {
                SideBarConfig = SellerWebSideBarConfig;
                cartlink = `/shop/${subdomain_shopId}/cart`;
                checkoutlink = `/shop/${subdomain_shopId}/checkout`;
                headerCart = (<CartContainer cartlink={cartlink} checkoutlink={checkoutlink} />);
                topBar = <Topbar bgColor="#eee"  />;
                sidebar = (<SidebarMain open={visible} onOpen={showDrawer} onClose={onClose} />)
                cart = (<span className="item cart"><Link to={cartlink}><ShoppingCartOutlined className="icon" /></Link></span>);
            }

            break;
    }

  const drawerMenuItems = constructTopbarMenuItems(SideBarConfig);

  const { translate } = props;
    const [isFixed, setIsFixed] = useState(false);


    return (
    <div>
      <AppBar
        position="fixed"
        elevation={0}
      >
          <Topbar bgColor="#eee" isTitleTag={isTitleTag} titleBanner={titleBanner} titleTag={titleTag} headerCart={headerCart} locationTag={locationTag} isFixed={true} />
          {/*<Header headerCart={headerCart} cart={cart} menuItems={drawerMenuItems} isFixed={isFixed} />*/}
      </AppBar>
      {sidebar}
    </div>
  );
};

export default withTranslate(PageHeader);
