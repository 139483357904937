import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    cartAddingPid: "",
    cart: [],
    wishlist: [],
    shopwishlist: [],
    order:[]
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_GET_CART_PRODUCTS_START:
        case ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_START:
        case ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_START:
        case ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_START:
        case ActionTypes.USER_UPDATE_PRODUCT_SIZE_IN_CART_START:
        case ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_START:
        case ActionTypes.USER_GENERATE_PRODUCT_ORDER_START:
        case ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_START:
        case ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_START:
        case ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        case ActionTypes.USER_ADD_PRODUCT_TO_CART_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
                cartAddingPid: action.pid, // only for inflight cart add to display progress
            };
        }

        /* Common Failures */
        case ActionTypes.USER_GET_CART_PRODUCTS_FAILURE:
        case ActionTypes.USER_ADD_PRODUCT_TO_CART_FAILURE:
        case ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_FAILURE:
        case ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_FAILURE:
        case ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_FAILURE:
        case ActionTypes.USER_UPDATE_PRODUCT_SIZE_IN_CART_FAILURE:
        case ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_FAILURE:
        case ActionTypes.USER_GENERATE_PRODUCT_ORDER_FAILURE:
        case ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_FAILURE:
        case ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_FAILURE:
        case ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.USER_GET_CART_PRODUCTS_SUCCESS:

            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    cart: action.payload.response.cartitems,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    cart: [],
                };
            }

        case ActionTypes.USER_ADD_PRODUCT_TO_CART_SUCCESS:
            if (action.payload.statusCode === 200) {
                if (state.cart.findIndex(cartItem => cartItem.product.id === action.productId) !== -1) {
                    const cart = state.cart.reduce((cartAcc, cartItem) => {
                        if (cartItem.product.id === action.productId) {
                            cartAcc.push({
                                ...cartItem,
                                product_quantity: cartItem.product_quantity + 1,
                                sum: (cartItem.price) * (cartItem.product_quantity + 1)
                            }) // Increment qty
                        } else {
                            cartAcc.push(cartItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        cart
                    }
                }

                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    cart: [...state.cart, {
                        ...action.payload.response,
                        qty: action.payload.response.product_quantity,
                        sum: (action.payload.response.product.price) * action.payload.response.product_quantity
                    }]
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                };
            }

        case ActionTypes.USER_INCREMENT_PRODUCT_IN_CART_SUCCESS:
            if (action.payload.statusCode === 200) {
                if (state.cart.findIndex(cartItem => cartItem.product.id === action.productId) !== -1) {
                    const cart = state.cart.reduce((cartAcc, cartItem) => {
                        if (cartItem.product.id === action.productId && cartItem.product_quantity > 0) {
                            //console.log('price: '+product.price+'Qty: '+product.qty)
                            cartAcc.push({
                                ...cartItem,
                                product_quantity: cartItem.product_quantity + 1,
                                sum: (cartItem.product.price) * (cartItem.product_quantity - 1)
                            }) // Decrement qty
                        } else {
                            cartAcc.push(cartItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        cart
                    }
                }

                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    cart: [...state.cart, {
                        ...action.cartItem,
                        qty: action.qty,
                        sum: action.response.cartItem.price * action.qty
                    }]
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                };
            }

        case ActionTypes.USER_DECREMENT_PRODUCT_IN_CART_SUCCESS:
            if (action.payload.statusCode === 200) {
                if (state.cart.findIndex(cartItem => cartItem.product.id === action.productId) !== -1) {
                    const cart = state.cart.reduce((cartAcc, cartItem) => {
                        if (cartItem.product.id === action.productId && cartItem.product_quantity > 1) {
                            cartAcc.push({
                                ...cartItem,
                                product_quantity: cartItem.product_quantity - 1,
                                sum: (cartItem.product.price) * (cartItem.product_quantity - 1)
                            }) // Decrement qty
                        } else {
                            cartAcc.push(cartItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        cart
                    }
                }

                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    cart: [...state.cart, {...action.response.cartItem, qty: action.qty, sum: action.product.price * action.qty}]
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                };
            }

        case ActionTypes.USER_REMOVE_PRODUCT_FROM_CART_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    cart: state.cart.filter(cartItem => cartItem.product.id !== action.productId)
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                };
            }

        case ActionTypes.USER_UPDATE_PRODUCT_SIZE_IN_CART_SUCCESS:

            if (action.payload.statusCode === 200) {
                return { ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                };
            }

        case ActionTypes.USER_CHECKOUT_PRODUCT_ORDER_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    order: action.payload.response,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    order: []
                };
            }

        case ActionTypes.USER_GENERATE_PRODUCT_ORDER_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    cart: [],
                    order: action.payload.response,

                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    cart: [],
                    order:[],
                };
            }

        case ActionTypes.USER_GET_SHOP_WISHLIST_PRODUCTS_SUCCESS:
            if (action.payload.statusCode === 200) {
                const {wishlistitems} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: wishlistitems,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }

        case ActionTypes.USER_ADD_PRODUCT_TO_WISHLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                /*
                if (state.wishlist.findIndex(wishItem => wishItem.product.id === action.productId) !== -1) {
                    const wishlist = state.wishlist.reduce((cartAcc, wishItem) => {
                        if (wishItem.product.id === action.productId) {
                            cartAcc.push({...wishItem}) // Increment qty
                        } else {
                            cartAcc.push(wishItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        wishlist
                    }
                }
*/
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: [],
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }

        case ActionTypes.USER_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: state.wishlist.filter(wishItem => wishItem.product.id !== action.productId)
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }


        case ActionTypes.ADD_TO_CART:
            const productId = action.product.id
            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === productId) {
                        cartAcc.push({ ...product, qty: product.qty+1, sum: (product.price*product.discount/100)*(product.qty+1) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: (action.product.price) * action.qty }] }


        case ActionTypes.INCREMENT_QTY:

            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({ ...product, qty: product.qty + 1, sum: (product.price)*(product.qty - 1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: action.product.price * action.qty }] }

        case ActionTypes.DECREMENT_QTY:
            
            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({ ...product, qty: product.qty - 1, sum: (product.price) * (product.qty - 1) }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return { ...state, cart }
            }

            return { ...state, cart: [...state.cart, { ...action.product, qty: action.qty, sum: action.product.price * action.qty }] }

        case ActionTypes.REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }

        case ActionTypes.USER_CART_WISHLIST_RESET:

            return { ...state,
                isLoading: false,
                cart: [],
                wishlist: [],
                shopwishlist: [],
                order:[]
            };

        default:
    }
    return state;
}
