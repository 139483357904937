// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions/seller/seller.store";


function* getDomain(action: Object) {
  try {
    const response = yield API.get(`/sellers/store/get-domain`, action.payload);
    yield put(Actions.getDomainSuccess(response));
  } catch (error) {
    yield put(Actions.getDomainFailure(error));
  }
}
function* checkDomainAvailability(action: Object) {
  try {
    const response = yield API.post(
      `/sellers/store/is-available`,
      action.payload
    );
    yield put(Actions.checkDomainAvailabilitySuccess(response));
  } catch (error) {
    yield put(Actions.checkDomainAvailabilityFailure(error));
  }
}
function* updateDomain(action: Object) {
  try {
    const response = yield API.put(
      `/sellers/store/update-domain`,
      action.payload
    );
    yield put(Actions.updateDomainSuccess(response));
  } catch (error) {
    yield put(Actions.updateDomainFailure(error));
  }
}

function* getStoreProfile(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-profile`,
        action.payload
    );
    yield put(Actions.getStoreProfileSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreProfileFailure(error));
  }
}

function* updateStoreProfile(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-profile`,
        action.payload
    );
    yield put(Actions.updateStoreProfileSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreProfileFailure(error));
  }
}

function* getStoreService(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-service`,
        action.payload
    );
    yield put(Actions.getStoreServiceSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreServiceFailure(error));
  }
}

function* updateStoreService(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-service`,
        action.payload
    );
    yield put(Actions.updateStoreServiceSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreServiceFailure(error));
  }
}

function* getStoreReturns(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-returns`,
        action.payload
    );
    yield put(Actions.getStoreReturnsSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreReturnsFailure(error));
  }
}

function* updateStoreReturns(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-returns`,
        action.payload
    );
    yield put(Actions.updateStoreReturnsSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreReturnsFailure(error));
  }
}

function* getStorePayment(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-payment`,
        action.payload
    );
    yield put(Actions.getStorePaymentSuccess(response));
  } catch (error) {
    yield put(Actions.getStorePaymentFailure(error));
  }
}

function* updateStorePayment(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-payment`,
        action.payload
    );
    yield put(Actions.updateStorePaymentSuccess(response));
  } catch (error) {
    yield put(Actions.updateStorePaymentFailure(error));
  }
}

function* updateStorePaymentQRCode(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/store/update-payment-qrcode`,
        action.payload
    );

    yield put(Actions.updateStorePaymentQRCodeSuccess(response));
  } catch (error) {
    yield put(Actions.updateStorePaymentQRCodeFailure(error));
  }
}

function* getStoreTax(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-tax`,
        action.payload
    );
    yield put(Actions.getStoreTaxSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreTaxFailure(error));
  }
}

function* updateStoreTax(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-tax`,
        action.payload
    );
    yield put(Actions.updateStoreTaxSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreTaxFailure(error));
  }
}

function* getStoreAddress(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/store/get-info-address`,
      action.payload
    );
    yield put(Actions.getStoreAddressSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreAddressFailure(error));
  }
}
function* updateStoreAddress(action: Object) {
  try {
    const response = yield API.put(
      `/sellers/store/update-info-address`,
      action.payload
    );
    yield put(Actions.updateStoreAddressSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreAddressFailure(error));
  }
}

function* updateStoreLocation(action: Object) {
  try {

    let locationquery = {};

    if (navigator.geolocation) {
      const usergetUserGeoLocation = () =>
          new Promise((resolve, reject) => {
            var options = {
              timeout: 5000,
              maximumAge: 0,
              enableHighAccuracy: true
            };
            navigator.geolocation.getCurrentPosition(
                (location) => resolve(location),
                (error) => reject(error),
                options
            );
          });

      const response = yield call(usergetUserGeoLocation);
      const {latitude, longitude} = response.coords;

      locationquery = {
        lat: latitude,
        lng: longitude,
      };
    }

    const response = yield API.put(
        `/sellers/store/update-location`,
        {... action.payload, ...locationquery}
    );
    yield put(Actions.updateStoreLocationSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreLocationFailure(error));
  }
}

function* getStoreSocial(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-info-social`,
        action.payload
    );
    yield put(Actions.getStoreSocialSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreSocialFailure(error));
  }
}
function* updateStoreSocial(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/store/update-info-social`,
        action.payload
    );
    yield put(Actions.updateStoreSocialSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreSocialFailure(error));
  }
}


function* getStoreImages(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/store/get-images`,
        action.payload
    );
    yield put(Actions.getStoreImagesSuccess(response));
  } catch (error) {
    yield put(Actions.getStoreImagesFailure(error));
  }
}

function* addStoreImage(action: Object) {
  try {
    const response = yield API.post(
        `sellers/store/add-store-image`,
        action.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
    );
    yield put(Actions.addStoreImageSuccess(response));
  } catch (error) {
    yield put(Actions.addStoreImageFailure(error));
  }
}

function* removeStoreImage(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/store/remove-store-image`,
        action.payload
    );
    yield put(Actions.removeStoreImageSuccess(response));
  } catch (error) {
    yield put(Actions.removeStoreImageFailure(error));
  }
}

function* updateStoreLogo(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/store/update-logo-pic`,
        action.payload
    );

    yield put(Actions.updateStoreLogoSuccess(response));
  } catch (error) {
    yield put(Actions.updateStoreLogoFailure(error));
  }
}

export default function* StoreSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.GET_DOMAIN_START, getDomain);
  yield takeLatest(
    ActionTypes.CHECK_DOMAIN_AVAILABILITY_START,
    checkDomainAvailability
  );
  yield takeLatest(ActionTypes.UPDATE_DOMAIN_START, updateDomain);
  yield takeLatest(ActionTypes.GET_STORE_PROFILE_START, getStoreProfile);
  yield takeLatest(ActionTypes.UPDATE_STORE_PROFILE_START, updateStoreProfile);
  yield takeLatest(ActionTypes.GET_STORE_SERVICE_START, getStoreService);
  yield takeLatest(ActionTypes.UPDATE_STORE_SERVICE_START, updateStoreService);
  yield takeLatest(ActionTypes.GET_STORE_RETURNS_START, getStoreReturns);
  yield takeLatest(ActionTypes.UPDATE_STORE_RETURNS_START, updateStoreReturns);
  yield takeLatest(ActionTypes.GET_STORE_PAYMENT_START, getStorePayment);
  yield takeLatest(ActionTypes.UPDATE_STORE_PAYMENT_START, updateStorePayment);
  yield takeLatest(ActionTypes.UPDATE_STORE_PAYMENT_QRCODE_START, updateStorePaymentQRCode);
  yield takeLatest(ActionTypes.GET_STORE_TAX_START, getStoreTax);
  yield takeLatest(ActionTypes.UPDATE_STORE_TAX_START, updateStoreTax);
  yield takeLatest(ActionTypes.GET_STORE_ADDRESS_START, getStoreAddress);
  yield takeLatest(ActionTypes.UPDATE_STORE_ADDRESS_START, updateStoreAddress);
  yield takeLatest(ActionTypes.UPDATE_STORE_LOCATION_START, updateStoreLocation);
  yield takeLatest(ActionTypes.GET_STORE_SOCIAL_START, getStoreSocial);
  yield takeLatest(ActionTypes.UPDATE_STORE_SOCIAL_START, updateStoreSocial);
  yield takeLatest(ActionTypes.GET_STORE_IMAGES_START, getStoreImages);
  yield takeLatest(ActionTypes.ADD_STORE_IMAGE_START, addStoreImage);
  yield takeLatest(ActionTypes.REMOVE_STORE_IMAGE_START, removeStoreImage);
  yield takeLatest(ActionTypes.UPDATE_STORE_LOGO_START, updateStoreLogo);
}
