import * as yup from "yup";

export const schema = yup.object().shape({
});

export const getTextfieldData = (instahandle, facebookhandle, whatsapphandle) => [
  {
    label: "Instagram Page",
    value: instahandle,
    onChangeField: "instahandle",
  },
  {
    label: "Facebook Page",
    value: facebookhandle,
    onChangeField: "facebookhandle",
  },
  {
    label: "WhatsApp #",
    value: whatsapphandle,
    onChangeField: "whatsapphandle",
  }
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    type: "primary",
    variant: "contained",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    type: "primary",
    variant: "outlined",
    disabled: false,
  },
];
