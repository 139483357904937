import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    popularshops: {
        count: 0,
        shops: [],
    },
    nearbyshops: {
        count: 0,
        shops: [],
    },
    exactlocationshops: {
        count: 0,
        shops: [],
    },
    latestshops: {
        count: 0,
        shops: [],
    },
    shopinfo: [],
    storeswishlist: [],
    searchshops: [],
    shopDetail: {
        title: "",
        addressline: "",
        cityname: "",
        phone:"",
        subdomain:"",
        domain:"",
    },
    searchkeywords: {
        keywords: [],
    },
};

export default function storeReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_START:
        case ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_START:
        case ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_START:
        case ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_START:
        case ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_START:
        case ActionTypes.USER_MAIN_SEARCH_SHOPS_START:
        case ActionTypes.USER_MAIN_GET_STORE_FAVLIST_START:
        case ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_START:
        case ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_FAILURE:
        case ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_FAILURE:
        case ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_FAILURE:
        case ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_FAILURE:
        case ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_FAILURE:
        case ActionTypes.USER_MAIN_SEARCH_SHOPS_FAILURE:
        case ActionTypes.USER_MAIN_GET_STORE_FAVLIST_FAILURE:
        case ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_FAILURE:
        case ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: action.payload,
            };
        }

        case ActionTypes.USER_MAIN_GET_LOCATION_STORE_LIST_SUCCESS: {
            if (action.payload.statusCode === 200) {
                const {shop_count, shops} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    nearbyshops: {
                        count: shop_count,
                        shops: shops,
                    },
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    nearbyshops: {
                        count: 0,
                        shops: [],
                    },
                };
            }
        }

        case ActionTypes.USER_MAIN_GET_EXACT_LOCATION_STORE_LIST_SUCCESS: {
            if (action.payload.statusCode === 200) {
                const {shop_count, shops} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    exactlocationshops: {
                        count: shop_count,
                        shops: shops,
                    },
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    exactlocationshops: {
                        count: 0,
                        shops: [],
                    },
                };
            }
        }

        case ActionTypes.USER_MAIN_GET_POPULAR_STORE_LIST_SUCCESS: {
            if (action.payload.statusCode === 200) {
                const {shop_count, shops} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    popularshops: {
                        count: shop_count,
                        shops: shops,
                    },
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    popularshops: {
                        count: 0,
                        shops: [],
                    },
                };
            }
        }

        case ActionTypes.USER_MAIN_GET_LATEST_STORE_LIST_SUCCESS: {
            if (action.payload.statusCode === 200) {
                const {shop_count, shops} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    latestshops: {
                        count: shop_count,
                        shops: shops,
                    },
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    latestshops: {
                        count: 0,
                        shops: [],
                    },
                };
            }
        }

        case ActionTypes.USER_MAIN_GET_SHOP_INFO_BYID_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    shopDetail: action.payload.response,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    shopDetail: {
                        ratings: [],
                        images: [],
                    },
                };
            }

        case ActionTypes.USER_MAIN_SEARCH_SHOPS_SUCCESS: {
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    searchshops: action.payload.response.shops
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    searchshops: []
                };
            }
    }

        case ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORD_SUCCESS:
            if (action.payload.statusCode === 200) {
                const { keywords } = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    searchkeywords: {
                        keywords: keywords,
                    },
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    searchkeywords: {
                        keywords: [],
                    },
                };
            }

        case ActionTypes.USER_MAIN_GET_STORE_FAVLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                const { shoplist } = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    storeswishlist: shoplist,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    storeswishlist: [],
                };
            }

        case ActionTypes.USER_MAIN_ADD_STORE_TO_FAVLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                /*
                if (state.storeswishlist.findIndex(wishItem => wishItem.shop._id === action.storeId) !== -1) {
                    const wishlist = state.storeswishlist.reduce((cartAcc, wishItem) => {
                        if (wishItem.store._id === action.storeId) {
                            cartAcc.push({...wishItem}) // Increment qty
                        } else {
                            cartAcc.push(wishItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        wishlist
                    };
                }

                 */

                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    storeswishlist: [],
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    storeswishlist: [],
                };
            }

        case ActionTypes.USER_MAIN_REMOVE_STORE_FROM_FAVLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    storeswishlist: state.storeswishlist.filter(wishItem => wishItem.shop._id !== action.storeId)
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    storeswishlist: [],
                };
            }

        case ActionTypes.USER_MAIN_SHOPS_RESET_ALL:

            return { ...state,
                isLoading: false,
                popularshops: {
                    count: 0,
                    shops: [],
                },
                nearbyshops: {
                    count: 0,
                    shops: [],
                },
                shopinfo: [],
                storeswishlist: [],
                searchshops: [],
                searchkeywords: {
                    keywords: [],
                },
            };

        case ActionTypes.USER_MAIN_SEARCH_SHOPS_RESET:

            return { ...state,
                reqStatus: action.type,
                serviceError: "",
                searchshops: [],
            };

        case ActionTypes.USER_MAIN_SEARCH_SHOPS_KEYWORDS_RESET:

            return { ...state,
                reqStatus: action.type,
                serviceError: "",
                searchkeywords: {
                    keywords : [],
                },
            };

        default:
    }
    return state;
}
