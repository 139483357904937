/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Box, Container, IconButton,} from "@mui/material";
import * as Actions from "actions";
import {getPrice} from "helpers/utils";

import MockProductImage from "components/elements/main/MockProductImage";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import {H2, H4} from "components/CustomTypography";
import ButtonComponent from "components/Button";
import ImageAlbum from "components/elements/main/ImageAlbum";
import ShopHeaderBack from "components/elements/user/shops/ShopHeaderBack";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopHeaderShopOverlay from "components/elements/user/shops/ShopHeaderShopOverlay";
import ShopHeaderOurCollection from "components/elements/user/shops/ShopHeaderOurCollection";

const UserProductPageDetail = (props) => {

  const {
    match, bootupInfo, productdetail, userMainGetProductInfo, userMainAddProductToWishlist, history, isLoading, currency,
      userMainGetShopInfoById
  } = props;

  const [share, setShare] = useState(false);
  const { id } = match.params;

  useEffect(() => {
      userMainGetProductInfo(id);
  }, []);

    useEffect(() => {
        userMainGetProductInfo(id);
    }, [id]);

    const {product, shops} = productdetail;

    const renderProductInformation = () => (
        <Box>
            {/*
            <H2 fontSize={18} sx={{ mb: "2", mt: "4", textTransform: "capitalize" }} textAlign="left" title={product.name} ellipsis>
                {product.name}
            </H2>
            <H5 fontSize={14} textAlign="left" ellipsis>
                {product.description}
            </H5>
            */}

            <Box width="100%" display="flex">
                <IconButton size="small" onClick={() => userMainAddProductToWishlist(id)}>
                    <FavoriteBorderIcon color="secondary" />
                </IconButton>

                {/*
                <Typography align="right">
                    <IconButton onClick={() => setShare(!share)} size="large">
                        <ShareIcon />
                    </IconButton>
                </Typography>

                    share && (
                        <>
                            <EmailShareButton url={window.location.href}>
                                <IconButton size="small">
                                    <MailIcon />
                                </IconButton>
                            </EmailShareButton>
                            <FacebookShareButton url={window.location.href}>
                                <IconButton size="small">
                                    <FacebookIcon />
                                </IconButton>
                            </FacebookShareButton>
                            <TwitterShareButton url={window.location.href}>
                                <IconButton size="small">
                                    <TwitterIcon />
                                </IconButton>
                            </TwitterShareButton>
                            <WhatsappShareButton url={window.location.href}>
                                <IconButton size="small">
                                    <WhatsAppIcon />
                                </IconButton>
                            </WhatsappShareButton>
                        </>
                    )
                */}
            </Box>
        </Box>
    );

    const renderProductPhotos = () => (
        <Box>
            <ImageAlbum images={product.images} mainOnly={true} />
        </Box>
    );

    const renderProductBuyFromShop = () => (
        <Box>
            {shops.map((shop, index) => (
                <Box>
                    <ShopHeaderShopOverlay userMainGetShopInfoById={userMainGetShopInfoById} history={history} shopId={shop.id}/>
                </Box>
            ))}
        </Box>
    );

    const renderProductShopMoreInfo = () => (
        <Box>
            {shops.map((shop, index) => (
                <Box>
                    <Box onClick={() => history.push(`/shop/${shop.id}/products/${product.id}`)} alignContent="center" width="100%" display="flex" pt={3} pb={3}>
                        <Box width="70%" order={3}>
                            <H2 fontSize={20}>
                                {getPrice(shop, currency)}
                            </H2>
                        </Box>
                        <Box width="30%" order={4}>
                            <ButtonComponent
                                sx={{ fontSize: '18px' }}
                                type="primary"
                                variant="contained"
                                name="Buy"
                                size="small"
                                onClick={() => history.push(`/shop/${shop.id}/products/${product.id}`)}
                            />
                        </Box>
                    </Box>
                    <ShopHeaderOurCollection />
                    <ShopProductPopularSlider shopId={shop.id} />
                    <ShopProductNewSlider shopId={shop.id} />
                    <ShopOffersSlider shopId={shop.id} />
                </Box>
            ))}
        </Box>
    );


    return (
      <Container>
        <Container maxWidth="md">
            <ShopHeaderBack history={history} />

            {isLoading && <MockGeneralHeader />}

            {/*!isLoading && renderProductInformation()*/}

            {!isLoading && renderProductBuyFromShop()}

            {isLoading && <MockProductImage />}
            {!isLoading && renderProductPhotos()}

            {!isLoading && renderProductShopMoreInfo()}

        </Container>
      </Container>
  );

};

UserProductPageDetail.defaultProps = {
    currency: "",
};

UserProductPageDetail.propTypes = {
    currency: PropTypes.string,
    productdetail: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainAddProductToWishlist: PropTypes.func.isRequired,
    userMainGetProductInfo: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userProductPageDetail = connect(
  ({ userMainProducts, bootupInfo }) => ({
    bootupInfo,
      currency: bootupInfo.symbol,
    reqStatus: userMainProducts.reqStatus,
    isLoading: userMainProducts.isLoading,
    serviceError: userMainProducts.serviceError,
      productdetail: userMainProducts.productdetail,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserProductPageDetail);

export { userProductPageDetail as default };
