import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    orderlist: [],
    shoporderlist: [],
    orderdetail: {
        shop: [],
        products: []
    },
};

export default function orderReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_GET_SHOP_ORDERLIST_PRODUCTS_START:
        case ActionTypes.USER_GET_SHOP_ORDER_DETAIL_START:
        case ActionTypes.USER_UPDATE_ORDER_STATUS_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_GET_SHOP_ORDERLIST_PRODUCTS_FAILURE:
        case ActionTypes.USER_GET_SHOP_ORDER_DETAIL_FAILURE:
        case ActionTypes.USER_UPDATE_ORDER_STATUS_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.USER_GET_SHOP_ORDER_DETAIL_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                orderdetail: action.payload.order
            };

        case ActionTypes.USER_GET_SHOP_ORDERLIST_PRODUCTS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                shoporderlist: action.payload.orders };

        case ActionTypes.USER_UPDATE_ORDER_STATUS_SUCCESS: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        case ActionTypes.USER_ORDERS_RESET:

            return { ...state,
                isLoading: false,
                orderlist: [],
                shoporderlist: [],
                orderdetail: {
                    shop: [],
                    products: []
                },
            };

        default:
    }
    return state;
}
