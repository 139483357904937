/* eslint-disable no-shadow */
import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import {
  Box, Typography, Card, IconButton, Container, Divider, MenuItem
} from "@mui/material";
import {getImage, isMarketPlace} from "helpers/utils";

import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import AddBoxSharpIcon from "@mui/icons-material/AddBoxSharp";
import IndeterminateCheckBoxSharpIcon from "@mui/icons-material/IndeterminateCheckBoxSharp";
import ShopHeader from "components/elements/user/shops/ShopHeader";
import ButtonComponent from "components/Button";
import MockProductList from "components/elements/main/MockProductList";
import { H6 } from "components/CustomTypography";

import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import { getCartTotal } from "services";
import { CURRENCY } from "config/constants";
import Sticky from "components/sticky/Sticky";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import SelectFieldComponent from "components/SelectField";
import {Notification} from "components/Atoms";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopHeaderInfo from "../../../../elements/user/shops/ShopHeaderInfo";

const ShopCart = (props) => {
  const {
    serviceError, reqStatus, isLoading,
    cartItems, symbol, total, getCartProducts, bootupInfo, removeProductFromCart,
    incrementProductInCart, decrementProductInCart, updateProductSizeInCart
  } = props;

  useEffect(() => {
    getCartProducts(bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: serviceError,
        description: serviceError,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.USER_UPDATE_PRODUCT_SIZE_IN_CART_SUCCESS:
        getCartProducts(bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  let checkOutLink;

  const getPrice = (symbol, item) => {
    const price = item.seller_price
    - (item.product && item.product.discount
      ? (item.seller_price * (parseInt(item.product.discount, 10) / 100)) : 0);
    return (
      <Typography variant="caption" gutterBottom>
        {symbol}
        {price}
        {item.product.discount && (
        <del>
          <span className="money">
            {symbol}
            {item.seller_price}
          </span>
        </del>
        )}
      </Typography>
    );
  };

  const handleSizeClick = (event, item) => {
    updateProductSizeInCart(item, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite, event.target.value);
  };

  const validateSizeInCart = () => {

    let valid = true;

    cartItems.map(function (item) {
      if (item.size && item.size.length > 0) {
        if (!(item.size.includes(item.product_size))) {
          valid = false;
        }
      }
    });

    return valid;
  };

  const getSize = (item) => {

    const availablesize = item.size;

    if (!(availablesize.length > 0)) {
      return (<></>);
    }

    const inputSizeOptions = availablesize.map((item) => {
      return (
          <MenuItem value={item}>{item}</MenuItem>
      );
    });

    return (
        <Typography variant="caption" gutterBottom>
          {item.size && (
              <SelectFieldComponent
              options={inputSizeOptions}
              label="Size"
              value={item.product_size}
              onChange={(event) => handleSizeClick(event, item)}
          />
          )}
        </Typography>
    );
  };

  const getquantity = (cartItem) => (
    <Box display="flex">
      <IconButton color="primary" size="small" onClick={cartItem.product_quantity > 1 && !isLoading ? () => decrementProductInCart(cartItem, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite) : ""}>
        <IndeterminateCheckBoxSharpIcon />
      </IconButton>
      <Typography className="p-1">{cartItem.product_quantity}</Typography>
      <IconButton color="primary" size="small" onClick={!isLoading ? () => incrementProductInCart(cartItem, props.bootupInfo.appState.subdomain_shopId, props.bootupInfo.appState.isWebsite) : ""}>
        <AddBoxSharpIcon />
      </IconButton>
    </Box>
  );

  const history = useHistory();

  const handleCheckoutPage = (page) => (e) => {
    if (validateSizeInCart()) {
      history.push(page);
    } else {
      sendNotification("error", {title: "Size", description: "Select a proper size"});
    }
  };

  if (bootupInfo.appState.isWebsite) {
    checkOutLink = "/cart/checkout";
  } else {
    checkOutLink = `/shop/${props.bootupInfo.appState.subdomain_shopId}/cart/checkout`;
  }

  const renderOrderItems = () => (
    <Box>
      {cartItems.map((item, index) => (
        <Card key={`${index + 1}`} className="m-3">
          <Box className="styled-card" width="100%" display="flex" p={1}>
            <Box width="15%" order={1}>
              <img
                alt=""
                className="img-product"
                src={getImage(item.product.images)}
              />
            </Box>
            <Box width="85%" className="ml-1" order={2} display="flex">
              <Box flexGrow={1}>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {item.product.name}
                </Typography>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                {getPrice(symbol, item)}
                </Typography>
                <Typography className="styled-link" gutterBottom variant="h6" component="p">
                  {item.size && getSize(item)}
                </Typography>
              </Box>
              <Box>
                <Typography align="right">
                  <IconButton border="1px solid" size="small" color="primary" onClick={() => removeProductFromCart(item, bootupInfo.appState.subdomain_shopId, bootupInfo.appState.isWebsite)}>
                    <ClearSharpIcon />
                  </IconButton>
                </Typography>
                <br />
                {getquantity(item)}
              </Box>
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );

  const renderEmptyCart = () => (
    <Container>
      <Box>
        <Typography className="m-2" align="center">
          <img src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`} className="img-fluid mb-4" alt="" />
          <H6>
            Cart is Empty
          </H6>
        </Typography>
      </Box>
    </Container>
  );

    return (
    <div>
      {/* SEO Support */}
      <Helmet>
        <title>{bootupInfo.shopinfo.title}</title>
        <meta name="description" content={bootupInfo.shopinfo.title} />
      </Helmet>
      {/* SEO Support End */}

      {isMarketPlace() && <ShopHeaderNav history={history} />}

      {isMarketPlace() ? <ShopHeaderMini {...props} /> : <ShopHeaderInfo {...props} /> }

      {isLoading ? <MockProductList />
        : (cartItems.length > 0
          ? (
            <Container>
              {renderOrderItems()}
              <Container>
                <Typography display="block" align="right" component="caption" variant="h6">{`Total: ${symbol} ${total}`}</Typography>
              </Container>
                <Typography className="m-2" align="center">
                    <ButtonComponent
                        variant="contained"
                        type="primary"
                        name="Checkout"
                        fullWidth
                        onClick={handleCheckoutPage(checkOutLink)}
                    />
                </Typography>
            </Container>
          )
          : renderEmptyCart())}
    </div>
  );
};

ShopCart.defaultProps = {
    symbol: CURRENCY,
};

ShopCart.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeProductFromCart: PropTypes.func.isRequired,
  incrementProductInCart: PropTypes.func.isRequired,
  decrementProductInCart: PropTypes.func.isRequired,
  getCartProducts: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
};

const shopCart = connect(
  ({ cartList, bootupInfo }) => ({
    bootupInfo,
      symbol: bootupInfo.symbol,
    reqStatus: cartList.reqStatus,
    isLoading: cartList.isLoading,
    serviceError: cartList.serviceError,
    cartItems: cartList.cart,
    total: getCartTotal(cartList.cart),
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopCart);

export { shopCart as default };
