//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
  Input,
  Notification,
  Roller,
  AntdModal,
    Pace
} from "components/Atoms";

import * as _ from "lodash";

import * as Actions         from "actions/seller/seller.products";
import * as ActionTypes     from 'constants/ActionTypes';

import SellerProduct          from "components/elements/seller/SellerProduct";
import SellerProductEntryForm from "components/elements/seller/SellerProductEntryForm";
import SellerCategorySelector       from "components/elements/seller/SellerCategorySelector";

import "./styles.scss";
import {useEffect, useState} from "react";
// Component Imports


const ProductsMain = (props) =>  {

  const { serviceError, reqStatus, isLoading,
    storeProducts, categoryList,
    getStoreProductCategories, getStoreProducts } = props;

  const { bShowProductEntryDialog, bShowCategoryBrowser, selectedProduct, selectedCategory } = this.state;

  const { count, records } = storeProducts;

  const [state, setState] = useState({
    bShowProductEntryDialog: false,
    bShowCategoryBrowser: false,
    selectedProduct: null,
    selectedCategory: null,
  });

  useEffect(() => {
    this.filterCategories = _.debounce(this.filterProducts, 1000);
    getStoreProductCategories();
    getStoreProducts(null, false);
  }, []);


  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        // optimize later - updated product only
        getStoreProducts();
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);

  const filterProducts = (value) => {
    setState({ filterText: value.toLowerCase() });
  };

  const addProduct = () => {
    props.history.push("/products/new");
  };

  const editProduct = (product, prodId) => {
    product.id = prodId;
    this.props.updateProduct(product);
    this.hideProductEntryDialog();
  };


  /* Header actions*/
  const showProductEntryDialog = (product) => () => {
    this.setState({ selectedProduct: product, bShowProductEntryDialog: true });
  };
  const hideProductEntryDialog = () => {
    this.setState({ selectedProduct: null, bShowProductEntryDialog: false });
  };

  const showCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: true });
  };
  const hideCategoryBrowser = () => {
    this.setState({ bShowCategoryBrowser: false });
  };

  const resetCategory = () => {
    this.state.selectedCategory = null;
    this.props.getStoreProducts(this.state.selectedCategory);
  };

  const setCategory = (category) => {
    this.state.selectedCategory = category;
    this.hideCategoryBrowser();
    this.props.getStoreProducts(this.state.selectedCategory);
  };

  const handlePage = (page) => (e) => {
    this.props.history.push({
      pathname: page
    });
  }

    return (
      <Page className="products-home">
        <div className="content">
          {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px"/>}
          <div className="product-list">
            <SellerCategorySelector
                title="All Product Categories"
                visible={bShowCategoryBrowser}
                categoryList={categoryList}
                handlePage={this.handlePage}
                resetCategory={this.resetCategory}
                onCategorySelect={this.setCategory}
            />
            <Row gutter={[16, 16]}>
              {records.map((product) => {
                return (
                  <Col span={6} key={`pr-item-${product.id}`}>
                    <SellerProduct
                      className="product-item"
                      key={product.id}
                      data={product}
                      actions={
                        <>
                          <Button
                              type="primary"
                              onClick={this.showProductEntryDialog(product)}
                          >
                            Edit
                          </Button>
                        </>
                      }
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <SellerProductEntryForm
            visible={bShowProductEntryDialog}
            data={selectedProduct}
            onOk={this.editProduct}
            onCancel={this.hideProductEntryDialog}
        />
      </Page>
    );
}
// Mapping Props and State.
const _ProductsMain = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    storeProducts: products.storeProducts,
    categoryList: products.storeProductCategoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(ProductsMain);

export { _ProductsMain as default };
