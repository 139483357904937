/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

// Marketplace specific
import MarketUserHomeMain from "components/pages/user/home/MarketUserHomeMain";

// Combined Product and Shops search
import UserCombinedSearchMain from "components/pages/user/home/UserCombinedSearchMain";

// Products
import MarketUserProductPageSearch from "components/pages/user/products/MarketUserProductPageSearch";

// Shops
import MarketUserShopPageSearch from "../components/pages/user/shop/MarketUserShopPageSearch";

// Products
import UserProductPageDetail from "../components/pages/user/products/UserProductPageDetail";

// Orders
import UserOrderList from "../components/pages/user/orders/UserOrderList";
import UserOrderDetail from "../components/pages/user/orders/UserOrderDetail";

import UserOfferList from "../components/pages/user/offers/UserOfferList";
import UserOfferDetail from "../components/pages/user/offers/UserOfferDetail";

import UserFavs from "../components/pages/user/favourites/UserFavs";


export const LocalMarketRoutes = [
  // Market main
  { path: "/", component: MarketUserHomeMain, exact: true },
    // Marketplace - combined product and shop search

  // Marketplace - combined search
  { path: "/search", component: UserCombinedSearchMain, exact: true },
  { path: "/search/:query", component: UserCombinedSearchMain, exact: true },

  // Marketplace - product search
  { path: "/products", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/sort/:sort", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/location/:location", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/market/:market", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/category/:category", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/search", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/search/:query", component: MarketUserProductPageSearch, exact: true },
  { path: "/products/:id", component: UserProductPageDetail, exact: true },

    // Marketplace - shop search
  { path: "/shops", component: MarketUserShopPageSearch, exact: true },
  { path: "/shops/sort/:sort", component: MarketUserShopPageSearch, exact: true },
  { path: "/shops/location/:location", component: MarketUserShopPageSearch, exact: true },
  { path: "/shops/market/:market", component: MarketUserShopPageSearch, exact: true },
  { path: "/shops/search", component: MarketUserShopPageSearch, exact: true },
  { path: "/shops/search/:query", component: MarketUserShopPageSearch, exact: true },

  { path: "/favs", component: UserFavs, exact: true },
  { path: "/favs/offers", component: UserOfferList, exact: true },
  { path: "/favs/offers/:offerId", component: UserOfferDetail, exact: true },

  { path: "/me/orders", component: UserOrderList, exact: true },
  { path: "/me/orders/:orderId", component: UserOrderDetail, exact: true },

];
