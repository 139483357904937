import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    searchoffers: [],
    otheroffers: [],
    nearbyofferlist: [],
    favnearbyofferlist: [],
    latestofferlist: [],
    offerdetail: {
    },
    searchkeywords: {
        keywords: [],
    },
};

export default function offerReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_MAIN_SEARCH_OFFERS_START:
        case ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_START:
        case ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_START:
        case ActionTypes.USER_MAIN_GET_LATEST_OFFERS_START:
        case ActionTypes.USER_MAIN_GET_OFFER_DETAIL_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_MAIN_SEARCH_OFFERS_FAILURE:
        case ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_FAILURE:
        case ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_FAILURE:
        case ActionTypes.USER_MAIN_GET_LATEST_OFFERS_FAILURE:
        case ActionTypes.USER_MAIN_GET_OFFER_DETAIL_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.USER_MAIN_SEARCH_OFFERS_SUCCESS: {
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    serviceCode: action.payload.statusCode,
                    searchoffers: action.payload.response.offers,
                    otheroffers: action.payload.response.otheroffers
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    searchoffers: [],
                    otheroffers: [],
                };
            }
        }

        case ActionTypes.USER_MAIN_GET_NEARBY_OFFERS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                nearbyofferlist: action.payload.offers };

        case ActionTypes.USER_MAIN_GET_FAV_NEARBY_OFFERS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                favnearbyofferlist: action.payload.offers };

        case ActionTypes.USER_MAIN_GET_LATEST_OFFERS_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                latestofferlist: action.payload.offers };

        case ActionTypes.USER_MAIN_GET_OFFER_DETAIL_SUCCESS:
            return { ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: "",
                offerdetail: action.payload
            };

        case ActionTypes.USER_MAIN_OFFERS_RESET_ALL:

            return { ...state,
                isLoading: false,
                searchoffers: [],
                nearbyofferlist: [],
                favnearbyofferlist: [],
                latestofferlist: [],
                searchkeywords: {
                    keywords: [],
                },
            };

        case ActionTypes.USER_MAIN_SEARCH_OFFERS_RESET:

            return { ...state,
                reqStatus: action.type,
                serviceError: "",
                searchoffers: [],
            };

        case ActionTypes.USER_MAIN_SEARCH_OFFERS_KEYWORDS_RESET:

            return { ...state,
                reqStatus: action.type,
                serviceError: "",
                searchkeywords: {
                    keywords : [],
                },
            };

        default:
    }
    return state;
}
