import * as React from "react";
import {
  Avatar,
  Box, Card, Container, Typography, IconButton,
} from "@mui/material";
import ButtonComponent from "components/Button";
import {
    schema,
} from "./helper";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

import {H2, H4, H5} from "components/CustomTypography";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import {getLocalDate, isMarketPlace} from "helpers/utils";
import ShopHeaderMiniOverlay from "components/elements/user/shops/ShopHeaderMiniOverlay";
import {DATE_FORMAT} from "config/constants";
import ShopHeaderNav from "../../shops/ShopHeaderNav";
import ShopHeaderOurCollection from "components/elements/user/shops/ShopHeaderOurCollection";
import ImageAlbum from "components/elements/main/ImageAlbum";

const UserSellerOfferView = ({
  setState, selectedOffer, symbol, onAddToFavlistClicked,  onCancel, shopdetail, offerdetail, isLoading, history,
}) => {

  const {
     shop_id: shopId,
  } = selectedOffer || {};

    const {
        shop
    } = offerdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onAddToFav = () => {
      onAddToFavlistClicked(shopId);
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOffer);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOffer);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

    const handleShopButtonClick = () => {
        history.push(`/offer/${shopId}`);
    };

    const renderOfferPhotos = () => (
        <Box>
            <ImageAlbum images={offerdetail.images} mainOnly={true} />
        </Box>
    );

    const renderOfferInformation = () => (
        <Box mt={2}>
            <H2 fontSize="24px" textAlign="left" title={offerdetail.name} ellipsis>
                {offerdetail.name}
            </H2>
            <H4 fontSize="14px" textAlign="left" title={offerdetail.detail} ellipsis>
                {offerdetail.description}
            </H4>
            <H4 fontSize="14px" textAlign="left" title={offerdetail.valid_date} ellipsis>
                {offerdetail.valid_date && getLocalDate(offerdetail.valid_date, DATE_FORMAT)}
            </H4>
        </Box>
    );

    return (
        <Container>
            <ShopHeaderNav onCancel={onCancel} history={history} shopId={shopId}/>
            <ShopHeaderMiniOverlay history={history} shopId={shopId}/>
            {!isLoading && renderOfferInformation()}
            {!isLoading && renderOfferPhotos()}
            <ShopHeaderOurCollection />
            <ShopProductPopularSlider shopId={shopId} />
            <ShopOffersSlider shopId={shopId} />
            <ShopProductNewSlider shopId={shopId} />
        </Container>
    );
};

export default UserSellerOfferView;
