/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, {useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import ShopProductSearch from "components/widgets/user/ShopProductSearch";
import "components/elements/main/index.scss";
import {isMarketPlace} from "helpers/utils";
import Sticky from "components/sticky/Sticky";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";
import ShopHeaderInfo from "../../../../elements/user/shops/ShopHeaderInfo";

const ShopSiteProductSearch = (props) => {
  const [state] = useState({
    layoutColumns: 3,
  });

    const {
        match, bootupInfo, history
    } = props;

    const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

    useEffect(() => {
    props.userResetSearchProducts();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

    const [isFixed, setIsFixed] = useState(false);
    const toggleIsFixed = useCallback((fixed) => setIsFixed(fixed), []);

    return (
      <div>
          {isMarketPlace() && <ShopHeaderNav history={history} />}

          {isMarketPlace() ? <ShopHeaderMini {...props} /> : <ShopHeaderInfo {...props} /> }

      <ShopProductSearch {...props} shopId={shopId} isShop={true} colSize={state.layoutColumns} />
      </div>
  );
};

ShopSiteProductSearch.propTypes = {
  userResetSearchProducts: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ShopSiteProductSearchComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopSiteProductSearch);

export { ShopSiteProductSearchComponent as default };
