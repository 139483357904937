/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdminProductSearch from "components/widgets/admin/AdminProductSearch";
import AdminShopHeader from "components/pages/admin/shops/AdminShopHeader";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";
import { checkUserAllowed } from "helpers/utils.js";
import CustomSectionCreator from "components/CustomSectionCreator";
import ViewListIcon from '@mui/icons-material/ViewList';
import AdminPrivateProductQuickAddMain from "../AdminPrivateProductQuickAddMain";
import {
    Notification,
} from "components/Atoms";

const AdminShopProductList = (props) => {

    const {
        serviceError, reqStatus,
        match, userDetails, adminSearchProducts,
        adminResetSearchProducts, isLoading, adminAddPrivateProductQuick
    } = props;

    const { shopId } = match.params;

    const [state] = useState({
        layoutColumns: 3,
    });

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        adminResetSearchProducts();
        document.getElementById("color").setAttribute("href", "#");
    }, []);

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: serviceError,
                description: serviceError,
            };
            sendNotification("error", feedback);
        }
        switch (reqStatus) {
            case ActionTypes.ADMIN_ADD_PRIVATE_PRODUCT_QUICK_SUCCESS:
                adminSearchProducts("", true, shopId, "", "", "", "", "");
                break;
            default:
                break;
        }
    }, [serviceError, reqStatus]);

    const adminAddPrivateProductClicked = (id, formData) => {
        adminAddPrivateProductQuick(id, formData);
    };

    const renderProductSearch = () => {
        if (checkUserAllowed(userDetails.userlevel, "products")) {
            return <AdminProductSearch {...props} shopId={shopId} isShop={true} colSize={state.layoutColumns} />;
        } else {
            return  <div>Not available </div>;
        }
    }

    return (
        <div>
            <AdminShopHeader {...props} />
            <AdminPrivateProductQuickAddMain
                shop_id={shopId}
                adminAddPrivateProductClicked={adminAddPrivateProductClicked}
            />
            <CustomSectionCreator icon={<ViewListIcon />} title="Products">
            {
                renderProductSearch()
            }
            </CustomSectionCreator>
        </div>
    );
};

AdminShopProductList.defaultProps = {
  isLoading: false,
};

AdminShopProductList.propTypes = {
    adminResetSearchProducts: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

const adminShopProductList = connect(
  ({  bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminShopProductList);

export default adminShopProductList;
