import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    serviceError: "",
    serviceCode: 200,
    reqStatus: "",
    isLoading: false,
    wishlist: [],
};

export default function wishReducer(state = initialState, action) {
    switch (action.type) {

        /* Common Triggers */
        case ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_START:
        case ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_START:
        case ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_START: {
            return {
                ...state,
                isLoading: true,
                reqStatus: action.type,
                serviceError: "",
            };
        }

        /* Common Failures */
        case ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_FAILURE:
        case ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_FAILURE:
        case ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                reqStatus: action.type,
                serviceError: { ...action.payload },
            };
        }

        case ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_SUCCESS:
            if (action.payload.statusCode === 200) {
                const {wishlistitems} = action.payload.response;
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: wishlistitems,
                };
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }

        case ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                /*
                if (state.wishlist.findIndex(wishItem => wishItem.product.id === action.productId) !== -1) {
                    const wishlist = state.wishlist.reduce((cartAcc, wishItem) => {
                        if (wishItem.product.id === action.productId) {
                            cartAcc.push({...wishItem}) // Increment qty
                        } else {
                            cartAcc.push(wishItem)
                        }

                        return cartAcc
                    }, [])

                    return {
                        ...state,
                        isLoading: false,
                        reqStatus: action.type,
                        serviceError: "",
                        wishlist
                    }
                }

                 */

                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: [],
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }

        case ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS:
            if (action.payload.statusCode === 200) {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: "",
                    wishlist: state.wishlist.filter(wishItem => wishItem.product.id !== action.productId)
                }
            } else {
                return {
                    ...state,
                    isLoading: false,
                    reqStatus: action.type,
                    serviceError: action.payload.statusMessage,
                    serviceCode: action.payload.statusCode,
                    wishlist: [],
                };
            }


        case ActionTypes.USER_MAIN_WISHLIST_RESET:

            return { ...state,
                isLoading: false,
                wishlist: [],
            };

        default:
    }
    return state;
}
