/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Link } from "react-router-dom";

import { Notification } from "components/Atoms";
import {
  Box,
  CircularProgress, Container, Typography,
} from "@mui/material";
import * as Actions from "actions";
import ProductItem from "components/elements/user/ProductItem";
import CategoryTitleSlider from "components/elements/user/CategoryTitleSlider";
import ShopProductSearch from "components/widgets/user/ShopProductSearch";
import MockProductList from "components/elements/main/MockProductList";
import { H6 } from "components/CustomTypography";
import { BreakPoints, CURRENCY } from "config/constants";

const ProductList = (props) => {
  const [state, setState] = useState({
    hasMoreItems: true,
    breakPoint: "lg",
    isMobile: false,
    bShowSearchModal: false,
    searchvalue: "Search products",
    layoutColumns: 3,
  });
  const [queryValue, setQueryValue] = useState("");

  const {
    serviceError,
    reqStatus,
    match,
    bootupInfo,
    usergetProductCategories,
  } = props;

  const { category } = match.params;

  const manageLayoutChange = () => {
    const docElement = document.documentElement;
    const currentWindowWidth = window.innerWidth || (docElement && docElement.offsetWidth);
    if (currentWindowWidth < BreakPoints.md) {
      setState({ ...state, isMobile: true });
    } else {
      setState({ ...state, isMobile: false });
    }
  };

  useEffect(() => {
    manageLayoutChange();
    props.userResetCategoryProducts();
    props.usergetCommonProducts(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite, category);
    usergetProductCategories(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite);
  }, []);

  const refetch = () => {
    usergetProductCategories(bootupInfo.appState.subdomain_shopId,
      true, bootupInfo.appState.isWebsite);
  };

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    if (category !== queryValue) {
      setQueryValue(category);
      props.userResetCategoryProducts();
      props.usergetCommonProducts(bootupInfo.appState.subdomain_shopId,
        true, bootupInfo.appState.isWebsite, category);
    }
  }, [serviceError, reqStatus]);

  const {
    isLoading, products, symbol, addProductToCart, addProductToWishlist,
    addToCompare,
  } = props;

  const renderNoResults = () => (
      <Container>
        <Box>
          <Typography className="m-2" align="center">
            <H6>
              No product found
            </H6>
          </Typography>
        </Box>
      </Container>
  );

  const renderResults = () => (
      <div className="row">
        { products.map((product, index) => (
            <div className="product-card-container" key={`${index + 1}`}>
              <ProductItem
                  product={product}
                  symbol={symbol}
                  onAddToCompareClicked={() => addToCompare(product)}
                  onAddToWishlistClicked={() => addProductToWishlist(product)}
                  onAddToCartClicked={() => addProductToCart(product,
                      props.bootupInfo.appState.subdomain_shopId,
                      props.bootupInfo.appState.isWebsite)}
                  key={`${index + 1}`}
              />
            </div>
        ))}
      </div>
  );

  return (
      <Container>
        <ShopProductSearch {...props} colSize={state.layoutColumns} />
        <Container>
          <CategoryTitleSlider {...props} refetch={refetch} />
        </Container>
        {isLoading ? <MockProductList />
            : products.length > 0
                ? renderResults()
                : renderNoResults()}
      </Container>
  );

};

ProductList.defaultProps = {
  currency: "",
};

ProductList.propTypes = {
  currency: PropTypes.string,
  addProductToCart: PropTypes.func.isRequired,
  usergetProductCategories: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  categoryList: PropTypes.arrayOf(PropTypes.object).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  addProductToWishlist: PropTypes.func.isRequired,
  addToCompare: PropTypes.func.isRequired,
  usergetCommonProducts: PropTypes.func.isRequired,
  userResetCategoryProducts: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  //reqStatus: PropTypes.objectOf(PropTypes.any).isRequired,
  //serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const ProductListComponent = connect(
  ({ userproducts, bootupInfo }) => ({
    bootupInfo,
    currency: bootupInfo.symbol,
    reqStatus: userproducts.reqStatus,
    isLoading: userproducts.isLoading,
    serviceError: userproducts.serviceError,
    products: userproducts.categoryproducts,
    categoryList: userproducts.categoryList,
    searchkeywords: userproducts.searchkeywords,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProductList);

export default ProductListComponent;
