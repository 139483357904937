/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import "components/elements/main/index.scss";
import {Avatar, Box, Button, Card, Container, IconButton, Rating} from "@mui/material";
import { H4, H6 } from "components/CustomTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {FlexBetween, FlexBox} from "../../../../flex-box";
import {H3, Small, Span} from "../../../../Typography";
import {Call, Place} from "@mui/icons-material";
import {getImage} from "../../../../../helpers/utils";



const ShopHeader = (props) => {

    const {
        history, shopinfo
    } = props;

  const [state] = useState({
    layoutColumns: 3,
  });

    const handleBackHome = () => {
        history.push(`/shops`);
    };

    const renderShopInfo = () => (
        <Container >
            <Container>
                <IconButton onClick={handleBackHome} size="large">
                    <ArrowBackIcon />
                </IconButton>
            </Container>
            <Box width="100%" bgcolor="#eee">
                <H4>
                    {shopinfo.title}
                </H4>
                <H6>
                    {shopinfo.addressline} {shopinfo.cityname} {shopinfo.phone}
                </H6>
                <H6>
                    {shopinfo.subdomain} {shopinfo.domain}
                </H6>
            </Box>
        </Container>
    );


    return (
        <Card
            sx={{
                mb: 4,
                pb: 2.5,
            }}
        >
            <Box
                height="200px"
                sx={{
                    background: `url(${getImage(shopinfo.images)}) center/cover`,
                }}
            />

            <FlexBox mt={-30} px={3.75} flexWrap="wrap">
                <Avatar
                    src={getImage(shopinfo.logo)}
                    sx={{
                        mr: "37px",
                        width: "120px",
                        height: "120px",
                        border: "4px solid",
                        borderColor: "grey.100",
                    }}
                />

                <Box
                    sx={{
                        flex: "1 1 0",
                        minWidth: "250px",
                        "@media only screen and (max-width: 500px)": {
                            marginLeft: 0,
                        },
                    }}
                >
                    <FlexBetween flexWrap="wrap" mt={0.375} mb={3}>
                        <Box
                            my={1}
                            p="4px 16px"
                            borderRadius="4px"
                            display="inline-block"
                            bgcolor="primary.main"
                        >
                            <H3 fontWeight="600" color="grey.100">
                                {shopinfo.title}
                            </H3>
                        </Box>

                    </FlexBetween>

                    <FlexBetween flexWrap="wrap">
                        <Box>
                            <FlexBox alignItems="center" gap={1} mb={2}>
                                <Rating color="warn" size="small" value={5} readOnly />
                                <Small color="grey.600" display="block">
                                    (45)
                                </Small>
                            </FlexBox>

                            <FlexBox color="grey.600" gap={1} mb={1} maxWidth={270}>
                                <Place
                                    fontSize="small"
                                    sx={{
                                        fontSize: 18,
                                        mt: "3px",
                                    }}
                                />
                                <Span color="grey.600">
                                    {shopinfo.addressline} {shopinfo.cityname}
                                </Span>
                            </FlexBox>

                            <FlexBox color="grey.600" gap={1} mb={1}>
                                <Call
                                    fontSize="small"
                                    sx={{
                                        fontSize: 18,
                                        mt: "2px",
                                    }}
                                />
                                <Span color="grey.600">{shopinfo.phone}</Span>
                            </FlexBox>
                        </Box>

                            <Button
                                variant="outlined"
                                color="primary"
                                sx={{
                                    my: 1.5,
                                }}
                            >
                                Visit
                            </Button>
                    </FlexBetween>
                </Box>
            </FlexBox>
        </Card>
    );
};

ShopHeader.propTypes = {
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const shopHeader = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
      shopinfo: bootupInfo.shopinfo,

  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopHeader);

export { shopHeader as default };
