import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    Notification, Pace,
} from "components/Atoms";

import * as Actions         from "actions/seller/seller.products";

import {Box, Container, Typography} from "@mui/material";
import { H4, H5 } from "components/CustomTypography";

import {useEffect} from "react";
import PhotoUpload from "components/PhotoUpload";

const PrivateCategoryImageMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, category, cancelEdit,
        addPrivateCategoryImage, categoryImageList
    } = props;

    const {id, name, image} = category;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            let feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        category.image = categoryImageList.image;
    }, [serviceError, reqStatus]);


    const addImageCallback = (file) => {
        const formData = new FormData()
        formData.append("image", file);
        addPrivateCategoryImage(category.id, formData);
    }


    const filter = (id) => {
        return image.filter(image => image.cdn.public_id !== id)
    }

    const removeImageCallback = (id) => {
        category.image = category.image.filter(image => image.cdn.public_id !== id);
        //removePrivateCategoryImage(category.id, id);
    }


    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H5>{name}</H5>
                <PhotoUpload isRemoveAllowed={true}
                             images={image}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );

};

// Mapping Props and State.
const _PrivateCategoryImageMain = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      categoryImageList: products.categoryImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(PrivateCategoryImageMain);

export { _PrivateCategoryImageMain as default };
