import * as React from "react";
import {
  Avatar,
  Box, Card, Container, FormControlLabel, Radio, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import TextFieldComponent from "components/TextField";
import { Link } from "react-router-dom";
import {
  getButtonData, getRadioButtonData, getButtonDataAccept, getButtonDataReject, getTextfieldDataRejectMessage,
    schema,
} from "./helper";
import { DATE_FORMAT } from "config/constants";
import { getCityListStatic, setCity, getCity } from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import CustomSectionCreator from "components/CustomSectionCreator";

import LocationOnIcon from '@mui/icons-material/LocationOn';

const ProfileCityEditor = ({
  setState, selectedOrder, symbol, updateCityClicked, onCancel, orderdetail, isLoading,
    cities
}) => {

  const {
     created_at: createdAt, order_id: orderIdValue,
    order_type, order_id, id, status, user, message
  } = selectedOrder || {};

    const {
        products, shipping,
        total_order, total_price, total_shipping, total_tax,
        delivery_date, delivery_time
    } = orderdetail || {};


  const [blur, setblur] = React.useState({
    id: false,
  });

  const onFinish = () => {
    setCity(id, cities);
    updateCityClicked({ status, order_id, id });
  };


  const hasError = () => {
    try {
      return !schema.validateSync(selectedOrder);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.id);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOrder);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleRadioButtonChange = (event, field) => {
        setState(event.target.value, field);
    };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

  let options = (cities) ? cities : getCityListStatic();

  const inputOptions = options.map((item) => {
    const { id, name } = item;
    return (
      <FormControlLabel value={id} control={<Radio />} label={name} />
    );
  });


    const renderLocationDetail = () => (
        <Container>
            <Box mb={2}>
                {getRadioButtonData(id).map((element) => (
                        <Box className="textfield" m={2}>
                            <RadioButtonFieldComponent
                                required
                                options={inputOptions}
                                label={element.label}
                                value={element.value}
                                onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
                            />
                        </Box>
                    ))}
                    </Box>

          <Box>
            {getButtonData(onFinish, onCancel, hasError, isTouched).map((element) => (
                            <ButtonComponent
                                sx={{
                                    m: 2,
                                }}
                                type={element.type}
                                variant={element.variant}
                                onClick={element.onClick}
                                disabled={element.disabled}
                                name={element.name}
                            />
                        ))
                }
            </Box>
        </Container>
    );

    return (
        <Container>
            <Container maxWidth="md">
              <CustomSectionCreator title="Change City">
                {isLoading ? <MockProductList /> : renderLocationDetail() }
              </CustomSectionCreator>
            </Container>
        </Container>
    );
};

export default ProfileCityEditor;
