import { Typography } from "@mui/material";
import { FlexRowCenter } from "components/flex-box";
import LazyImage from "components/LazyImage";
import React from "react";
import {getImage} from "helpers/utils";
import { H4 } from 'components/CustomTypography'

// ==============================================================
const MobileCategoryImageBox = ({ name, image }) => {
  return (
    <FlexRowCenter flexDirection="column">
        <LazyImage
          src={getImage(image)}
          borderRadius="0px"
          width={80}
          height={80}
          objectFit="cover"
        />
        <H4 fontSize={10} fontWeight="bold" lineHeight="1">{name}</H4>
    </FlexRowCenter>
  );
};

export default MobileCategoryImageBox;
