import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import { Input, Button, Modal } from "components/Atoms";

import "./style.scss";

//import * as Actions   from "modules/products/actions";
import * as Actions     from "actions/seller/seller.products";
import {useState} from "react";


const SearchModal = (props) => {

  const [state, setState] = useState({
    searchText: "",
  });

  const {
    serviceError, reqStatus, isLoading, match,
    searchKeywords,
    title,
    searchkeywords,
    storeId,
    visible,
    onOk,
    onCancel,
    onCategorySelect
  } = props;


  const { keywords } = searchkeywords;
  const { searchText } = state;

  const filterCategories = (value) => {
    setState({ searchText: value.toLowerCase() });
  };

  const fetchSearchResults = ( updatedPageNo = '', query ) => {
    state.searchquery = query;
    console.log("search now : " + query);
    searchKeywords(query);
  };

  const handleOnInputChange = ( event ) => {
    const query = event.target.value;
    console.log("Query : " + query);
    if ( ! query ) {
      setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      setState( { query, loading: true, message: '' }, () => {
        fetchSearchResults( 1, query );
      } );
    }
  };


    return (
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        type="full-page"
        className="dialog-category-browser"
      >
        <Input.Search
          placeholder="Search"
          onChange={this.handleOnInputChange}
        />
        <div className="category-list">
          {keywords.map((keyword, index) => {
            const { name, id } = keyword;
            if (searchText) {
              if (name.toLowerCase().indexOf(searchText) === -1) return null;
            }
            return (
              <span className="category-item" key={id}>
                <Button
                    onClick={() => this.props.onKeywordSelect(name)}
                >
                  {name}
                </Button>
              </span>
            );
          })}
        </div>
      </Modal>
    );
}

//export default SearchModal;

const _SearchModal = connect(
    ({ products, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: products.reqStatus,
      isLoading: products.isLoading,
      serviceError: products.serviceError,
      searchkeywords: products.searchkeywords,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchModal);

export { _SearchModal as default };
