import * as React from "react";
import {
    Box, Card, Chip, Container, IconButton, InputAdornment, MenuItem, TextField, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import {getPrice, isMarketPlace, checkProductInCart} from "helpers/utils";


import {useState} from "react";

import {H2, H5} from "components/CustomTypography";
import ImageAlbum from "components/elements/main/ImageAlbum";
import ShopHeaderOurCollection from "components/elements/user/shops/ShopHeaderOurCollection";
import ShopProductPopularSlider from "components/elements/user/products/ShopProductPopularSlider";
import ShopProductNewSlider from "components/elements/user/products/ShopProductNewSlider";
import ShopOffersSlider from "components/elements/user/offers/ShopOffersSlider";
import ShopHeaderBack from "components/elements/user/shops/ShopHeaderBack";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import MockProductImage from "components/elements/main/MockProductImage";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CircularProgress from "@mui/material/CircularProgress";
import {FlexBox} from "components/flex-box";
import SelectFieldComponent from "components/SelectField";


const ShopProductDetail = ({
   selectedProduct, currency, onAddToWishlistClicked, onAddToCartClicked, messageShopClicked, onCancel, productdetail,
                               isLoading, history, shopId, bootupInfo, cartItems, isCartLoading, cartAddingPid
}) => {

    const [state, setState] = useState({});
    const [share, setShare] = useState(false);
    const [sizeState, setSizeState] = useState("");

    const product = productdetail;

    const {
     created_at: createdAt, _id: _id, status, message
  } = selectedProduct || {};


    const handleShare = () => {
        setShare(!share);
    };

    const inputSizeOptions = product.size && product.size.map((item) => {
        return (
            <MenuItem value={item}>{item}</MenuItem>
        );
    });

    const handleSizeClick = (event) => {
        setSizeState(event.target.value);
    };

    const renderProductSize = () => (
        <SelectFieldComponent
            size="small"
            required
            options={inputSizeOptions}
            label="Size"
            value={sizeState}
            onChange={(event) => handleSizeClick(event)}
        />
    );


    const renderProductInformation = () => (
        <Box width="100%" >
            <Box mb={2} width="100%" display="flex" alignItems="center">
                <Box width="40%" >
                    <H2 fontSize={18} textAlign="left">
                        {getPrice(product, currency)}
                    </H2>
                </Box>
                <Box width="60%" display="flex" justifyContent="flex-end">
                    {
                        product.is_price_visible &&
                        (checkProductInCart(cartItems, product.prod_id) ?
                                <ShoppingCartIcon color="primary" />
                                :
                                (isCartLoading && cartAddingPid === product.prod_id ?
                                        <CircularProgress size="1rem"/>
                                        :
                                        <>
                                            <Box width="40%" display="flex" justifyContent="flex-end">
                                                {(product.size && product.size.length > 0) &&
                                                    renderProductSize()
                                                }
                                            </Box>
                                            <Box width="60%" display="flex" justifyContent="flex-end">
                                                <ButtonComponent
                                                    sx={{
                                                        mt: 2,
                                                        mb: 2,
                                                    }}
                                                    type="primary"
                                                    variant="contained"
                                                    name="Add to cart"
                                                    size="small"
                                                    onClick={() => onAddToCartClicked(product.prod_id, shopId, !isMarketPlace(), sizeState)}
                                                />
                                            </Box>
                                        </>
                                )
                        )
                    }
                </Box>
            </Box>
            <Box width="100%" >
                <FlexBox width="100%" alignItems="left">
                    <Box width="50%">
                        <H5 fontSize={14} textAlign="left" >
                            {parseInt(product.fav) > 0 ?
                                <ButtonComponent
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                    }}
                                    disabled
                                    variant="contained"
                                    name="Liked!"
                                    size="small"
                                />
                                :
                                isCartLoading ? <CircularProgress size="1rem"/> :
                                    <>
                                        <ButtonComponent
                                            sx={{
                                                mt: 2,
                                                mb: 2,
                                            }}
                                            type="primary"
                                            variant="contained"
                                            name="Like!"
                                            size="small"
                                            onClick={() => onAddToWishlistClicked(product.prod_id)}
                                        />
                                    </>
                            }
                        </H5>

                    </Box>
                </FlexBox>
            </Box>
        </Box>
    );
    const renderProductPhotos = () => (
        <Box>
            <ImageAlbum images={product.images} mainOnly={true} />
        </Box>
    );


    const renderProductShopMoreInfo = () => (
        <Box>
            <ShopHeaderOurCollection />
            <ShopProductPopularSlider shopId={isMarketPlace() ? shopId : bootupInfo.appState.subdomain_shopId} />
            <ShopProductNewSlider shopId={shopId} />
            <ShopOffersSlider shopId={shopId} />
        </Box>
    );


    return (
        <Container>
            <Container maxWidth="md">
                <ShopHeaderBack onCancel={onCancel} />

                {isLoading && <MockGeneralHeader /> }

                {isLoading && <MockProductImage />}
                {!isLoading && renderProductPhotos()}
                {!isLoading && renderProductInformation()}
                {/* !isLoading && renderProductShopMoreInfo() */}
            </Container>
        </Container>
    );
};

export default ShopProductDetail;
