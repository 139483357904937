//  @flow
import * as React from "react";
import axios from 'axios';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  GroupOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Page,
  Row,
  Col,
  Button,
    Input,
  Notification,
  DatePicker,
  Roller,
} from "components/Atoms";


import * as Actions         from "actions/seller/seller.products";
import * as ActionTypes     from 'constants/ActionTypes';

import Product              from "components/elements/seller/Product";
import ProductEntryForm     from "components/elements/seller/ProductEntryForm";
import CategoryBrowser      from "components/elements/seller/CategoryBrowser";
import SearchModal          from "components/elements/seller/SearchModal";

import "./styles.scss";
import {scanBarCode, stopScanning} from "utils/cordova";
import {useEffect, useState} from "react";
import * as _ from "lodash";
// Component Imports


const SearchProductList = (props) => {

  const [state, setState] = useState({
    bShowProductEntryDialog: false,
    bShowCategoryBrowser: false,
    bShowSearchModal: false,
    selectedProduct: null,
    selectedCategory: null,
    searchquery: "",
    isUPCbased: false
  });

const { serviceError, reqStatus, isLoading,
    query, searchproductList, categoryList,
    getActiveCategories, searchProducts
  } = props;

  const { count, products } = searchproductList;


  useEffect(() => {

    const cordova = window.cordova;
    if (cordova) {
      startScan();
      state.isUPCbased = true;
    } else {
      state.isUPCbased = false;
    }
    getActiveCategories();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADD_PRODUCT_SUCCESS:
      case ActionTypes.UPDATE_PRODUCT_SUCCESS:
        // optimize later - updated product only
        searchProducts(state.searchquery, state.selectedCategory, state.upc, state.isUPCbased);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);


  const saveProduct = (product, prodId, store_entry) => {

    if (!store_entry) {
      product.id = prodId;
      props.addProduct(product);
    } else {
      product.id = store_entry._id;
      props.updateProduct(product);
    }
    hideProductEntryDialog();

  };

  const filterProducts = (value) => {
    setState({ filterText: value.toLowerCase() });
  };

  /* Header actions*/
  const showProductEntryDialog = (product) => () => {
    setState({ selectedProduct: product, bShowProductEntryDialog: true });
  };

  const hideProductEntryDialog = () => {
    setState({ selectedProduct: null, bShowProductEntryDialog: false });
  };

  const showCategoryBrowser = () => {
    setState({ bShowCategoryBrowser: true });
  };

  const hideCategoryBrowser = () => {
    setState({ bShowCategoryBrowser: false });
  };

  const showSearchModal = () => {
    setState({ bShowSearchModal: true });
  };

  const hideSearchModal = () => {
    setState({ bShowSearchModal: false });
  };

  const setKeyword = (keyword) => {
    state.searchquery = keyword;
    hideSearchModal();
    searchProducts(keyword, state.selectedCategory, state.upc, state.isUPCbased);
  };

  const resetCategory = () => {
    state.selectedCategory = null;
    searchProducts(state.searchquery, state.selectedCategory, state.upc, state.isUPCbased);
  };

  const setCategory = (category) => {
    state.selectedCategory = category;
    hideCategoryBrowser();
    searchProducts(state.searchquery, state.selectedCategory, state.upc, state.isUPCbased);
  };

  const fetchSearchResults = ( updatedPageNo = '', query ) => {
    state.searchquery = query;
    searchProducts(query, state.selectedCategory, state.upc, state.isUPCbased);
  };

  const handleOnInputChange = ( value ) => {
    const query = value;
    if ( ! query ) {
      setState( { query, results: {}, message: '', totalPages: 0, totalResults: 0 } );
    } else {
      setState( { query, loading: true, message: '' }, () => {
        fetchSearchResults( 1, query );
      } );
    }
  };

  const resolve = (value) => {
    state.upc = value;
    searchProducts("", this.state.selectedCategory, this.state.upc, this.state.isUPCbased);
  };

  const reject = (value) => {};

  const startScan = () => {
    setState(
        {
          isScanning: true,
          scanError: false,
        },
        () => {
          scanBarCode(this.resolve, this.reject);
        }
    );
  };

  const stopScan = () => {
    setState(
        {
          isScanning: false,
          scanError: false,
        },
        () => {
          stopScanning();
        }
    );
  };

    return (
        <Page className="products-catalog">
        <h4 className="page-title">Search Products</h4>
        <div className="content">
          <div className="header">
            <Input.Search
                type="text"
                name="query"
                value={ query }
                placeholder="Enter Product Name"
                onSearch={this.handleOnInputChange}
            />
            <div className="actions">
              <Button
                  icon={<GroupOutlined />}
                  onClick={this.showCategoryBrowser}
              />
            </div>
          </div>
          {state.selectedCategory ? "Search in category: " + state.selectedCategory.name : ""}
          <Button
            icon={<DeleteOutlined/>}
            onClick={this.resetCategory}
          />
          <div className="product-list">
            <Row gutter={[24, 24]}>
              {products.map((product) => {
                return (
                    <Col span={6} key={`pr-item-${product.id}`}>
                      <Product
                          className="product-item"
                          key={product.id}
                          data={product}
                          editactions={
                            <>
                              <Button
                                  type="primary"
                                  onClick={this.showProductEntryDialog(product)}
                              >
                                Edit
                              </Button>
                            </>
                          }
                          addactions={
                            <>
                              <Button
                                  type="primary"
                                  onClick={this.showProductEntryDialog(product)}
                              >
                                Add to Store
                              </Button>
                            </>
                          }
                      />
                    </Col>
                );
              })}
            </Row>
          </div>
        </div>
        <ProductEntryForm
            visible={state.bShowProductEntryDialog}
            data={state.selectedProduct}
            onOk={saveProduct}
            onCancel={hideProductEntryDialog}
        />
          <CategoryBrowser
              title="All Product Categories"
              visible={state.bShowCategoryBrowser}
              categoryList={categoryList}
              onCategorySelect={setCategory}
              onOk={hideCategoryBrowser}
              onCancel={hideCategoryBrowser}
          />
          <SearchModal
              title="Search"
              visible={state.bShowSearchModal}
              onKeywordSelect={setKeyword}
              onOk={hideSearchModal}
              onCancel={hideSearchModal}
          />
      </Page>
    );
}
// Mapping Props and State.
const _SearchProductList = connect(
  ({ products, bootupInfo }) => ({
    bootupInfo: bootupInfo,
    reqStatus: products.reqStatus,
    isLoading: products.isLoading,
    serviceError: products.serviceError,
    searchproductList: products.searchproductList,
    categoryList: products.categoryList,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SearchProductList);

export { _SearchProductList as default };
