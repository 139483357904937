//  @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Page,
  Row,
  Col,
  Button,
  Notification,
  DatePicker,
} from "components/Atoms";

import * as Actions       from "actions";
import * as ActionTypes   from 'constants/ActionTypes';


import AvailablePlan      from "components/elements/seller/AvailablePlan";

import "./styles.scss";
import {useEffect, useState} from "react";
import ButtonComponent from "components/Button";
import MockGeneralHeader from "components/elements/main/MockGeneralHeader";
import {Box, Card, Container, IconButton, Typography} from "@mui/material";
import {checkPackageInCart, getImage, getPriceCurrency, getLocalDate} from "helpers/utils";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import {H2, H4, H3} from "components/CustomTypography";
import CustomSectionCreator from "../../../../CustomSectionCreator";
import {DATE_FORMAT_SHORT} from "config/constants";
import SubscriptionOrder from "components/elements/seller/SubscriptionOrder";
import {getAvailableSubscriptionPlans, getSubscriptionsOrders} from "actions";
// Component Imports


const SubscriptionStore = (props) => {

    const [subscriptionModeState, setSubscriptionModeState] = useState("PREVIEW");
    const [selectedOrder, setSelectedOrder] = useState("");

  const {
    serviceError, reqStatus, isLoading, symbol, currency,
    getAvailableSubscriptionPlans, getSellerActiveSubscriptionPlans, getSubscriptionsCart, getSubscriptionsActiveOrders,
    addSubscriptionToCart, removeSubscriptionFromCart, checkoutSubscriptionPlanOrder, getSubscriptionsOrders, getSubscriptionsOrderDetail,
      serviceErrorBootupInfo, reqStatusBootupInfo, serviceCodeBootupInfo, isLoadingBootupInfo,
      paymentorder, paymentverify, createPaymentOrder, checkPaymentVerifyOrder,
    availablePlans, subscriptioncart, activePlans, subscriptionactiveorders, subscriptionorders, subscriptionorder, history
  } = props;

  const itemsInCart = availablePlans.filter((plan) => plan.addedToCart).length;

  const checkOutReady = itemsInCart > 0;

  useEffect(() => {
      getSubscriptionsCart();
    getAvailableSubscriptionPlans();
    //getSellerActiveSubscriptionPlans();
    getSubscriptionsActiveOrders();
      getSubscriptionsOrders();
  }, []);


  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      return sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.CHECKOUT_SUBSCRIPTION_ORDER_SUCCESS:
        history.push(`/subscriptions/checkout`);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);


    const handleOrderDetail = (order) => {
        setSelectedOrder(order);
        setSubscriptionModeState("ORDERDETAIL");
        getSubscriptionsOrderDetail(order._id);
    }

    const handlePayment = () => {
        createPaymentOrder({ _id:  selectedOrder._id });
    }

    const handlePaymentVerification = (response) => {

        checkPaymentVerifyOrder({
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
        });
    }

    // handlePaymentVerify Function
    const handlePaymentProcessing = (data) => {

        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: data.amount,
            currency: data.currency,
            name: "Shopana",
            image: "https://shopana.in/images/shopanaicon.png",
            description: "Package Information",
            order_id: data.id,
            handler: function(response) {
                handlePaymentVerification(response);
            },
            theme: {
                color: "#FFFFFF",
            }
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    useEffect(() => {

        if (serviceErrorBootupInfo) {
            const feedback = {
                title: serviceErrorBootupInfo,
                description: "Error: " + serviceCodeBootupInfo,
            };
            sendNotification("error", feedback);
        }

        switch (reqStatusBootupInfo) {
            case ActionTypes.COMMON_CREATE_PAYMENT_ORDER_SUCCESS:
                handlePaymentProcessing(paymentorder.data);
                break;
            case ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_SUCCESS:
                if (paymentverify.statusMessage) {
                    sendNotification("success", paymentverify.statusMessage);
                }
                // following calls will reset statusMessage
                // no need to reset it explicity
                getSubscriptionsActiveOrders();
                getSubscriptionsOrders();
                setSubscriptionModeState("PREVIEW");
                break;
            default:
                break;
        }
    }, [serviceErrorBootupInfo, reqStatusBootupInfo]);


  const addToCart = (plan) => () => {
    addSubscriptionToCart(plan._id);
  };

  const removeFromCart = (plan) => () => {
    removeSubscriptionFromCart(plan._id);
  };

  const handleCheckoutPage = () => (e) => {
      history.push("/subscriptions/checkout");
  };


  const renderCartItems = () => (
      <Box>
        {subscriptioncart.map((item, index) => (
            <Card key={`${index + 1}`} className="m-3">
              <Box className="styled-card" width="100%" display="flex" p={1}>
                <Box width="15%" order={1}>
                  <img
                      alt=""
                      className="img-product"
                      src={getImage(item.plan.images)}
                  />
                </Box>
                <Box width="85%" className="ml-1" order={2} display="flex">
                  <Box flexGrow={1}>
                    <Typography className="styled-link" gutterBottom variant="h6" component="p">
                      {item.plan.title}
                    </Typography>
                    <Typography className="styled-link" gutterBottom variant="h6" component="p">
                      {item.plan.description}
                    </Typography>
                    <Typography className="styled-link" gutterBottom variant="h6" component="p">
                      {getPriceCurrency(item.price, currency)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography align="right">
                      <IconButton border="1px solid" size="small" color="primary" onClick={() => removeSubscriptionFromCart(item.plan._id)}>
                        <ClearSharpIcon />
                      </IconButton>
                    </Typography>
                    <br />
                  </Box>
                </Box>
              </Box>
            </Card>
        ))}
      </Box>
  );

    const renderActiveOrders = () => (
        <Box>
            {subscriptionactiveorders.map((item, index) => (
                <Box className="styled-card" width="100%" display="flex" mt={4} mb={4} onClick={() => handleOrderDetail(item)}>
                    <Box width="60%" order={1} ml={2}>
                        <H2 mb={2} fontSize={16} sx={{textDecoration: 'underline'}} ellipsis>
                            Order# {item.order_id}
                        </H2>
                        <H4 fontSize={12}>
                            {getLocalDate(item.created_at, DATE_FORMAT_SHORT)}
                        </H4>
                    </Box>
                    <Box width="20%" order={2} ml={2}>
                        <H2 mb={2} fontSize={16}  ellipsis>
                            {getPriceCurrency(item.total_order, currency)}
                        </H2>
                    </Box>
                    <Box width="20%" order={3} ml={2}>
                        {item.paymentstatus === "due" && (
                            <ButtonComponent
                                type="primary"
                                variant="contained"
                                name="Pay"
                                size="medium"
                            >
                            </ButtonComponent>
                        )
                        }
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const renderFewPreviousOrders = () => (
        <Box>
            {subscriptionorders.slice(0, 2).map((item, index) => (
                <Box className="styled-card" width="100%" display="flex" mt={4} mb={4} onClick={() => handleOrderDetail(item)} >
                    <Box width="60%" order={1} ml={2}>
                        <H2 mb={2} fontSize={16} sx={{textDecoration: 'underline'}}  ellipsis>
                            Order# {item.order_id}
                        </H2>
                        <H4 fontSize={12}>
                            {getLocalDate(item.created_at, DATE_FORMAT_SHORT)}
                        </H4>
                    </Box>
                    <Box width="20%" order={2} ml={2}>
                        <H2 mb={2} fontSize={16}  ellipsis>
                            {getPriceCurrency(item.total_order, currency)}
                        </H2>
                    </Box>
                    <Box width="20%" order={3} ml={2}>
                        {item.paymentstatus === "due" ?
                            <ButtonComponent
                                type="primary"
                                variant="contained"
                                name="Pay"
                                size="medium"
                                onClick={() => handleOrderDetail(item)}>
                            </ButtonComponent>
                        : <ButtonComponent
                                type="primary"
                                variant="outlined"
                                name="View"
                                size="medium"
                                onClick={() => handleOrderDetail(item)}>
                            </ButtonComponent>
                        }
                    </Box>
                </Box>
            ))}
            <ButtonComponent
                type="primary"
                variant="outlined"
                name="View All..."
                size="medium"
                onClick={() => setSubscriptionModeState("ORDERS")}
            >
            </ButtonComponent>
        </Box>
    );

    const renderAllPreviousOrders = () => (
        <>
        <CustomSectionCreator isSubtle={true} title="Previous Orders" />
        <Box>
            {subscriptionorders.map((item, index) => (
                <Box className="styled-card" width="100%" display="flex" mt={4} mb={4} onClick={() => handleOrderDetail(item)} >
                    <Box width="60%" order={1} ml={2}>
                        <H2 mb={2} fontSize={16} sx={{textDecoration: 'underline'}}  ellipsis>
                            Order# {item.order_id}
                        </H2>
                        <H4 fontSize={12}>
                            {getLocalDate(item.created_at, DATE_FORMAT_SHORT)}
                        </H4>
                    </Box>
                    <Box width="20%" order={2} ml={2}>
                        <H2 mb={2} fontSize={16}  ellipsis>
                            {getPriceCurrency(item.total_order, currency)}
                        </H2>
                    </Box>
                    <Box width="20%" order={3} ml={2}>
                        {item.paymentstatus === "due" ?
                            <ButtonComponent
                                type="primary"
                                variant="contained"
                                name="Pay"
                                size="medium"
                                onClick={() => handleOrderDetail(item)}>
                            </ButtonComponent>
                            : <ButtonComponent
                                type="primary"
                                variant="outlined"
                                name="View"
                                size="medium"
                                onClick={() => handleOrderDetail(item)}>
                            </ButtonComponent>
                        }
                    </Box>
                </Box>
            ))}
            <ButtonComponent
                type="primary"
                variant="outlined"
                name="< Back"
                size="medium"
                onClick={() => setSubscriptionModeState("PREVIEW")}
            >
            </ButtonComponent>
        </Box>
        </>
    );


    const renderEmptyPayments = () => (
      <Container>
        <Box>
            <H4>
              No Pending Payment
            </H4>
        </Box>
      </Container>
  );

    const renderEmptyOrders = () => (
        <Container>
            <Box>
                <H4>
                    No Previous Orders
                </H4>
            </Box>
        </Container>
    );

    const renderOrderDetailPage = () => (
        <Container>
            <SubscriptionOrder
                isLoadingBootInfo={isLoadingBootupInfo}
                currency={currency}
                subscriptionorder={subscriptionorder}
                handlePayment={handlePayment}
                history={history}
                onCancel={() => setSubscriptionModeState("PREVIEW")}
            />
        </Container>
    );

    const renderMainPage = () => (
        <Container>
            <Box>
                <CustomSectionCreator isSubtle={true} title="Buy Promotion Packages" />
                {availablePlans.map((availablePlan, index) => {
                    return (
                        <AvailablePlan
                            currency={currency}
                            inCart={checkPackageInCart(subscriptioncart, availablePlan._id)}
                            data={availablePlan}
                            addToCart={addToCart(availablePlan)}
                            removeFromCart={removeFromCart(availablePlan)}
                        />
                    );
                })}
            </Box>
            <Box pb={4} />
            <Box>
                {isLoading ? <MockGeneralHeader />
                    : (subscriptioncart.length > 0
                        ? (
                            <Box>
                                <Typography className="m-2" align="center">
                                    <ButtonComponent
                                        variant="contained"
                                        type="primary"
                                        name="Buy Selected Package"
                                        fullWidth
                                        onClick={handleCheckoutPage()}
                                    />
                                </Typography>
                            </Box>
                        )
                        : "")}
            </Box>
            <Box pb={4} />
            <Box>
                <CustomSectionCreator isSubtle={true} title="Payment Due" />
                {isLoading ? <MockGeneralHeader /> : (subscriptionactiveorders.length > 0 ? renderActiveOrders() : renderEmptyPayments())}
            </Box>
            <Box pb={4} />
            <Box>
                <CustomSectionCreator isSubtle={true} title="Previous Orders" />
                {isLoading ? <MockGeneralHeader /> : (subscriptionorders.length > 0 ? renderFewPreviousOrders() : renderEmptyOrders())}
            </Box>
        </Container>
    );


    const renderSubscriptionMode = (subscriptionMode) => {
        switch (subscriptionMode) {
            case "ORDERDETAIL":
                return renderOrderDetailPage();
            case "ORDERS":
                return renderAllPreviousOrders();
            case "PREVIEW":
            default:
                return renderMainPage();
        }
    };

    return (
        <Container>
            { renderSubscriptionMode(subscriptionModeState)}
        </Container>
    );
};

// Mapping Props and State.
const subscriptionStore = connect(
  ({ subscriptions, bootupInfo }) => ({
      bootupInfo,
      currency: bootupInfo.symbol,
      reqStatus: subscriptions.reqStatus,
      isLoading: subscriptions.isLoading,
      serviceError: subscriptions.serviceError,
      availablePlans: subscriptions.availablePlans,
      activePlans: subscriptions.activePlans,
      subscriptioncart: subscriptions.subscriptioncart,
      subscriptionorder: subscriptions.subscriptionorder,
      subscriptionorders: subscriptions.subscriptionorders,
      subscriptionactiveorders: subscriptions.subscriptionactiveorders,
      reqStatusBootupInfo: bootupInfo.reqStatus,
      isLoadingBootupInfo: bootupInfo.isLoading,
      serviceErrorBootupInfo: bootupInfo.serviceError,
      serviceCodeBootupInfo: bootupInfo.serviceCode,
      paymentorder: bootupInfo.paymentorder,
      paymentverify: bootupInfo.paymentverify,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SubscriptionStore);

export { subscriptionStore as default };
