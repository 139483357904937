// @flow
import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Notification, Pace,
} from "components/Atoms";
import {H2, H4, H6} from 'components/CustomTypography';

import "./style.scss";
import * as Actions from "actions";
import * as ActionTypes from "constants/ActionTypes";

import AdminSalesFuture from "components/elements/admin/sales/AdminSalesFuture";
import AdminSalesFutureDetail from "components/elements/admin/sales/AdminSalesFutureDetail";

import MockSalesFutureList from "components/elements/main/MockSalesFutureList";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {Box, Container, Grid, IconButton, MenuItem, Typography} from "@mui/material";
import {useHistory} from "react-router";
import {
  calculateDistance, checkUserAllowed,
  getAdminSalesFutureFilterList,
  getDistance,
  getDistanceKms,
  getDistanceLocations,
  getGeoLocation,
  getSalesLeadStatusList, openGoogleMapLocation,
} from "helpers/utils";
import ButtonComponent from "components/Button";
import CustomFlexBox from 'components/CustomFlexBox';

import Marker from 'components/Marker';
import GoogleMap from 'components/GoogleMap';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SelectFieldComponent from "components/SelectField";
import AdminAddOfferQuickMain from "components/pages/admin/offers-coupons/AdminAddOfferQuickMain";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const AdminSalesFutureMain = (props) => {

  const [state, setState] = useState({ categoryMode: "PREVIEW" });
  const [categoryState, setCategoryState] = useState({ selectedCategory: props.categoryInput });
  const [statusState, setStatusState] = useState("");
  const [locationState, setLocationState] = useState("");
  const [salesFutureSearchTypeState, setSalesFutureSearchTypeState] = useState("");
  const [offsetState, setOffsetState] = useState(0);

  const [mapref, setMapRef] = useState(null);

  const {
    serviceError, reqStatus, isLoading, isLoadingOffers, searchfutures, shopoffers,
    bootupInfo, userDetails,
    adminSearchSalesFuture, updateAdminLocation, adminAddOfferQuickInfo, adminShopOffers,
    adminUpdateShopFutureLogoImage,
    leadtype,
  } = props;

  useEffect(() => {
    setSalesFutureSearchTypeState(leadtype);
    adminSearchSalesFuture(leadtype, statusState, "", "" /* shop name */, "" /* shop phone */, offsetState);
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
    switch (reqStatus) {
      case ActionTypes.ADMIN_UPDATE_SALES_FUTURE_SUCCESS:
      case ActionTypes.ADMIN_UPDATE_SHOP_FUTURE_LOGO_SUCCESS:
        adminSearchSalesFuture(salesFutureSearchTypeState, statusState, locationState, "" /* shop name */, "" /* shop phone */, offsetState);
        break;
      default:
        break;
    }
  }, [serviceError, reqStatus]);


  const handleCategoryEdit = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          shopname: "", name: "", phone: "", leadstatus: "", description: "", shop_id: "", images: [], logo: []
        },
      });
    } else {
      setCategoryState({
        storeCategory: {
          shopname: item.shopname, name: item.name, phone: item.phone, leadstatus: item.leadstatus,
          description: item.description, shop_id: item.place_id, images: item.images, logo: item.logo,
        },
      });
    }
  };

  const handleAddOffer = (mode, item, reset) => (e) => {
    setState({ categoryMode: mode });
    if (reset) {
      setCategoryState({
        storeCategory: {
          title: "", name: "", phone: "", leadstatus: "", description: "", shop_id: "", images: [], logo: []
        },
      });
    } else {
      adminShopOffers(item.place_id);
      setCategoryState({
        storeCategory: {
          title: item.shopname, name: item.name, phone: item.phone, leadstatus: item.leadstatus,
          description: item.description, shop_id: item.place_id, images: item.images, logo: item.logo,
        },
      });
    }
  };


  const setSalesFutureStatus = (status) => {
    setStatusState(status);
    adminSearchSalesFuture(salesFutureSearchTypeState, status, locationState, "" /* shop name */, "" /* shop phone */, offsetState);
  };

  const handleSalesFutureFilter = (event) => {
    setStatusState(event.target.value);
    adminSearchSalesFuture(salesFutureSearchTypeState, event.target.value, locationState, "" /* shop name */, "" /* shop phone */, 0);
  };

  const updateLocationSearch = () => {

    const lat = mapref.getCenter().lat();
    const lng = mapref.getCenter().lng();
    const location = { coordinates: [ lng, lat ] };

    setLocationState(location);
    setSalesFutureSearchTypeState("map");
    setOffsetState(0);
    adminSearchSalesFuture("map", statusState, location, "", 0);
  };


  const handlePrevOffset = () => {
    const offset = offsetState > 30 ? offsetState - 30 : 0;
    setOffsetState(offset);
    adminSearchSalesFuture(salesFutureSearchTypeState, statusState, locationState, "" /* shop name */, "" /* shop phone */, offset);
  };

  const handleNextOffset = () => {
    const offset = offsetState + 30;
    setOffsetState(offset);
    adminSearchSalesFuture(salesFutureSearchTypeState, statusState, locationState, "" /* shop name */, "" /* shop phone */, offset);
  };

  const openMapView = () => {
    setState({ categoryMode: "EDIT_MAP" });
  };

  const closeMapView = () => {
    setState({ categoryMode: "PREVIEW" });
  };

  const history = useHistory();

  const handlePage = (page) => (e) => {
    history.push(page);
  };

  const addOfferShop = (shop_id, formData) => {
    adminAddOfferQuickInfo(shop_id, formData);
    setState({ categoryMode: "PREVIEW" });
  };

  const adminUpdateShopLogoClicked = (id, formData) => {
    adminUpdateShopFutureLogoImage(id, formData);
    setState({ categoryMode: "PREVIEW" });
  };

  const { salesfutures } = searchfutures;
  const { categoryMode } = state;
  const { storeCategory = {} } = categoryState;

  const { offers, otheroffers } = shopoffers;


  const futurestatus = getAdminSalesFutureFilterList();

  const inputSalesFutureOptions = futurestatus.map((item) => {
    const { value, label } = item;
    return (
        <MenuItem value={value}>{label}</MenuItem>
    );
  });

  const renderNoResults = () => (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <H6>No lead found
                  </H6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );

  const renderResults = () => (
      <Box>
        {salesfutures.map((salesfuture, index) => (
            <Box mb={2} sx={{backgroundColor: "#eee", border: "1px solid #CCC",}} key={`${index + 1}`}>
              <Box width="100%">
                <AdminSalesFuture
                    className="product-item"
                    key={salesfuture.id}
                    lead={salesfuture}
                    isShopAdded={!!salesfuture.shop}
                    userDetails={bootupInfo.userDetails}
                    editCategoryInfo={handleCategoryEdit("EDIT", salesfuture, false)}
                    addOfferInfo={handleAddOffer("EDIT_OFFER", salesfuture, false)}
                />
              </Box>
              {(checkUserAllowed(userDetails.userlevel, "salesfuturesdetail")) &&
                  (
              <Box>
                <Box sx={{backgroundColor: "#ffea99"}}>
                  {salesfuture.salesleads && salesfuture.salesleads.map((saleslead, index) => (
                      <Box width="100%" display="flex">
                        <Box width="50%">
                          <H2 fontSize={14} ellipsis>
                          {saleslead.shopname}
                          </H2>
                        </Box>
                        <Box width="25%">
                          <H4 fontSize={14} ellipsis>
                          {saleslead.phone}
                          </H4>
                        </Box>
                        <Box width="25%">
                          <H4 fontSize={12}>
                          {salesfuture.location && saleslead.location && getDistanceLocations(salesfuture.location, saleslead.location)}
                          <IconButton
                              size="small"
                              onClick={() => openGoogleMapLocation(saleslead.location)}
                          >
                            <ArrowForwardIcon color="primary" />
                          </IconButton>
                          </H4>
                        </Box>
                      </Box>
                  ))}
                </Box>
                <Box sx={{backgroundColor: "#e8ff8a"}}>
                  <Box width="100%" display="flex">
                    {salesfuture.shop && (
                        <>
                          <Box width="50%">
                            <H2 fontSize={14} ellipsis>
                              {salesfuture.shop.title}
                            </H2>
                            </Box>
                          <Box width="25%">
                            <H4 fontSize={14} ellipsis>{salesfuture.shop.user_id && salesfuture.shop.user_id.mobile}
                            </H4>
                          </Box>
                          <Box width="25%">
                            <H4 fontSize={12}>
                              {salesfuture.location && salesfuture.shop.location && getDistanceLocations(salesfuture.location, salesfuture.shop.location)}
                              <IconButton
                                size="small"
                                onClick={() => openGoogleMapLocation(salesfuture.shop.location)}
                            >
                              <ArrowForwardIcon color="primary" />
                            </IconButton>
                            </H4>
                          </Box>
                        </>)}
                  </Box>
                </Box>
              </Box>)}
            </Box>
        ))}
      </Box>
  );

  const renderCategoryList = () => (
      <Container>
        <CustomFlexBox sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          margin: "4px"
        }}>
          <Box width="50%" align="left">
            <SelectFieldComponent
                required
                options={inputSalesFutureOptions}
                label="Filter"
                value={statusState}
                onChange={(event) => handleSalesFutureFilter(event)}
            />
          </Box>
          <Box width="50%" align="right">
            <ButtonComponent
                type="primary"
                variant="contained"
                name="Map View"
                size="small"
                onClick={openMapView}>
            </ButtonComponent>
          </Box>
        </CustomFlexBox>

        {!(isLoading && isLoadingOffers) && salesfutures.length > 0 && <CustomFlexBox m={2} sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}>
          <Box width="60%" align="center">
          </Box>
          <Box width="20%" align="right">
            {offsetState > 0 && <IconButton onClick={handlePrevOffset}>
              <ArrowBackIosIcon fontSize="medium" color="primary"/>
            </IconButton>
            }
          </Box>
          <Box width="20%" align="right">
            <IconButton onClick={handleNextOffset}>
              <ArrowForwardIosIcon fontSize="medium" color="primary"/>
            </IconButton>

          </Box>
        </CustomFlexBox>
        }

        <Box>
        {(isLoading || isLoadingOffers) ? <MockSalesFutureList />
            : salesfutures.length > 0
                ? renderResults()
                : renderNoResults()}
        </Box>
      </Container>
  );

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach((place) => {
      bounds.extend(new maps.LatLng(
          place.location.coordinates[1],
          place.location.coordinates[0],
      ));
    });
    return bounds;
  };

// Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };

// Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {

    setMapRef(map);

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  const address = getGeoLocation();
  const center = address ? { lat: parseFloat(address.lat), lng: parseFloat(address.lng) } : salesfutures && salesfutures.length > 0 ? { lat: salesfutures[0].location.coordinates[1], lng : salesfutures[0].location.coordinates[0] } : { lat: 0, lng: 0 };

  const renderCategoryEdit = () => {
    switch (categoryMode) {
      case "EDIT_MAP":
        return (
            <Box>
              {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
              <CustomFlexBox p={2} sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%'
              }}>
                <Box width="50%" align="left">
                  <ButtonComponent
                      type="primary"
                      variant="outlined"
                      name="Back"
                      size="small"
                      onClick={closeMapView}>
                  </ButtonComponent>
                </Box>
                <Box width="50%" align="right">
                  <ButtonComponent
                      m={2}
                      type="primary"
                      variant="contained"
                      name="Search more"
                      size="small"
                      onClick={updateLocationSearch}>
                  </ButtonComponent>
                </Box>
              </CustomFlexBox>
              <div style={{ height: '500px', width: '100%' }}>
                <GoogleMap
                    style={{height: '500px', width: '100%'}}
                    resetBoundsOnResize={true}
                    defaultCenter={[center.lat, center.lng]}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={
                      ({ map, maps }) => apiIsLoaded(map, maps, salesfutures)
                    }
                >
                  {salesfutures.map((place) => (
                      <Marker
                          sx={{background: "#f0f"}}
                          key={place.place_id}
                          text={place.shopname}
                          status={place.saleslead_id ? 'done' : 'notdone'}
                          updated={place.is_updated ? 'updated' : 'notupdated'}
                          lat={place.location.coordinates[1]}
                          lng={place.location.coordinates[0]}
                      />
                  ))}
                </GoogleMap>
              </div>
            </Box>
        );
      case "EDIT_OFFER":
        return (
        <AdminAddOfferQuickMain
            key={1}
            selectedShop={storeCategory}
            shopOffers={otheroffers}
            images={[]}
            cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
            adminAddQuickOfferClicked={addOfferShop}
        />
        );
      case "EDIT":
      default:
        return (
            <AdminSalesFutureDetail
                adminUpdateShopLogoClicked={adminUpdateShopLogoClicked}
                category={storeCategory}
                cancelEdit={() => setState({ categoryMode: "PREVIEW" })}
            />
        );
    }
  };

  return (
    <Container>
      {categoryMode === "PREVIEW" ? (
        renderCategoryList()
      ) : (
        renderCategoryEdit(categoryMode)
      )}
    </Container>
  );
};

AdminSalesFutureMain.defaultProps = {
  isLoading: false,
  serviceError: "",
  reqStatus: "",
  searchfutures: [],
};

AdminSalesFutureMain.propTypes = {
  isLoading: PropTypes.bool,
  serviceError: PropTypes.string,
  reqStatus: PropTypes.string,
  leadtype: PropTypes.string,
  searchfutures: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  adminSearchSalesFuture: PropTypes.func.isRequired,
  adminUpdateSalesFuture: PropTypes.func.isRequired,
};

const adminSalesFutureMain = connect(
  ({ adminSales, adminOffers, bootupInfo }) => ({
    bootupInfo,
    userDetails: bootupInfo.userDetails,
    reqStatus: adminSales.reqStatus,
    isLoading: adminSales.isLoading,
    serviceError: adminSales.serviceError,
    searchfutures: adminSales.searchfutures,
    isLoadingOffers: adminOffers.isLoading,
    shopoffers: adminOffers.shopoffers,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSalesFutureMain);

export default adminSalesFutureMain;
