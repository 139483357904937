/* eslint-disable import/no-unresolved */
/* eslint-disable no-nested-ternary */
import React, {useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  Button,
  Notification,
} from "components/Atoms";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Container, Avatar, Typography, Box, Card, IconButton,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ViewListIcon from "@mui/icons-material/ViewList";
import * as Actions from "actions/core/core.actions";

import "./styles.scss";
import CustomFlexBox from "components/CustomFlexBox";
import * as ActionTypes from "constants/ActionTypes";

const ProfileHome = (props) => {
  const [open, setOpen] = useState(false);

  const {
    getUserProfile, serviceError, reqStatus, userDetails, history, logout,
    deleteAccount
  } = props;
  React.useEffect(() => {
    getUserProfile();
  }, []);

  const sendNotification = (type, { title, description = "" }) => {
    Notification[type]({
      message: title,
      description,
    });
  };

  React.useEffect(() => {
    switch (reqStatus) {
      case ActionTypes.DELETE_ACCOUNT_SUCCESS:
        localStorage.clear();
        logout();
        break;
      default:
        if (serviceError) {
          const feedback = {
            title: "Something went wrong!",
            description: serviceError.statusText,
          };
          sendNotification("error", feedback);
        }
        break;
    }
  }, [serviceError, reqStatus]);

  const handleLogout = () => {
    localStorage.clear();
    logout();
  };

  const handlePage = (page) => () => {
    history.push({
      pathname: page,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAccount = () => {
    setOpen(false);
    deleteAccount();
  };

  const { image } = userDetails;
  return (
    <Container>
      <Box m={2} display="flex" className="position-relative">
        <Box>
          <Avatar onClick={handlePage("/me/image")} src={image && image.length > 0 && image[0].cdn.secure_url} className="profile-img" />
          <div className="camera-icon">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handlePage("/me/image")}
              size="large">
              <PhotoCamera />
            </IconButton>
          </div>
        </Box>
        <Box className="detail-text">
          <Typography variant="h6">{userDetails.name}</Typography>
          <Typography variant="h6">{userDetails.email}</Typography>
          <Typography variant="h6">{userDetails.mobile}</Typography>
        </Box>
      </Box>
      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handlePage("/me/profile")}>
                <IconButton size="large">
                  <PersonRoundedIcon />
                </IconButton>
                Profile
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>


      {(process.env.REACT_APP_TYPE === "MARKETPLACE" ||
      process.env.REACT_APP_TYPE === "LOCALMARKET" ||
      process.env.REACT_APP_TYPE === "USERSITE") ?
          (
          <Box m={2}>
            <Card>
              <Box display="flex">
                <Box width="80%">
                  <Typography className="cursor-pointer" onClick={handlePage("/me/address")}>
                    <IconButton size="large">
                      <LocationOnRoundedIcon/>
                    </IconButton>
                    Delivery Address
                  </Typography>
                </Box>
                <Box width="20%">
                  <Typography align="right">
                    <IconButton size="large">
                      <KeyboardArrowRightRoundedIcon/>
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          ) : ""
      }

      {process.env.REACT_APP_TYPE === "MARKETPLACE"
        ? (
          <>
            <Box m={2}>
              <Card>
                <Box display="flex">
                  <Box width="80%">
                    <Typography className="cursor-pointer" onClick={handlePage( "/me/orders")}>
                      <IconButton size="large">
                        <ViewListIcon />
                      </IconButton>
                      Orders
                    </Typography>
                  </Box>
                  <Box width="20%">
                    <Typography align="right">
                      <IconButton size="large">
                        <KeyboardArrowRightRoundedIcon />
                      </IconButton>
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </Box>
          </>
        )
        : ""
      }

      {process.env.REACT_APP_TYPE === "USERSITE" ? (
          <>
          <Box m={2}>
            <Card>
              <Box display="flex">
                <Box width="80%">
                  <Typography className="cursor-pointer" onClick={handlePage( "/me/orders")}>
                    <IconButton size="large">
                      <ViewListIcon />
                    </IconButton>
                    Orders
                  </Typography>
                </Box>
                <Box width="20%">
                  <Typography align="right">
                    <IconButton size="large">
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          <Box m={2}>
            <Card>
              <Box display="flex">
                <Box width="80%">
                  <Typography className="cursor-pointer" onClick={handlePage("/me/wishlist")}>
                    <IconButton size="large">
                      <FavoriteIcon />
                    </IconButton>
                    Favourites Products
                  </Typography>
                </Box>
                <Box width="20%">
                  <Typography align="right">
                    <IconButton size="large">
                      <KeyboardArrowRightRoundedIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
          </>
        ) : ""
      }

      <Box m={2}>
        <Card>
          <Box display="flex">
            <Box width="80%">
              <Typography className="cursor-pointer" onClick={handleLogout}>
                <IconButton size="large">
                  <ExitToAppRoundedIcon />
                </IconButton>
                Logout
              </Typography>
            </Box>
            <Box width="20%">
              <Typography align="right">
                <IconButton size="large">
                  <KeyboardArrowRightRoundedIcon />
                </IconButton>
              </Typography>
            </Box>
          </Box>
        </Card>
      </Box>
      <br />
      <br />
      <br />
      <CustomFlexBox sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        width: '100%',
        bottom: 70,
        justifyContent: 'center',
        zIndex: 'modal' }}>
        <Button
            size="large"
            color="primary"
            variant="outlined"
            onClick={handleClickOpen}
        >
          Delete Account
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Account?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>Cancel</Button>
            <Button onClick={handleDeleteAccount}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </CustomFlexBox>
    </Container>
  );
};

ProfileHome.propTypes = {
  reqStatus: PropTypes.string.isRequired,
  serviceError: PropTypes.objectOf(PropTypes.any).isRequired,
  userDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  getUserProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

// Mapping Props and State.
const ProfileHomeComponent = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
    userDetails: bootupInfo.userDetails,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ProfileHome);

export { ProfileHomeComponent as default };
