//  @flow
import * as React from "react";
import {
  Page,
} from "components/Atoms";

import PrivateProductsMain    from "components/pages/seller/products/PrivateProductsMain";

import "./styles.scss";

const PrivateProductsByCategory  = (props) =>  {

  const { categoryId } = props.match.params;

    return (
        <Page className="products-home">
          <PrivateProductsMain categoryInput={categoryId}></PrivateProductsMain>
        </Page>
    );
}

export default (PrivateProductsByCategory)
