/* eslint-disable import/no-unresolved */
//  @flow
import * as React from "react";
import { Box, Container } from "@mui/material";
import { H4 } from "components/CustomTypography";
import ShippingAddressList from "components/widgets/core/ShippingAddressList";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

import "./styles.scss";
import CustomSectionCreator from "components/CustomSectionCreator";

const ProfileAddresses = () => (
  <Container>
      <Box>
          <CustomSectionCreator icon={<LocationOnRoundedIcon />} title="Delivery Address">
              <ShippingAddressList />
          </CustomSectionCreator>
      </Box>
  </Container>
);

export default (ProfileAddresses);
