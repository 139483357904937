// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const adminGetAllSubscriptionsOrders = (req) => ({
  type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_START,
  payload: req,
});

export const adminGetAllSubscriptionsOrdersSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_SUCCESS,
    payload: data,
  };
};
export const adminGetAllSubscriptionsOrdersFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDERS_FAILURE,
  payload: error,
});


export const adminGetSubscriptionsOrderById = (req) => ({
  type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_START,
  payload: req,
});

export const adminGetSubscriptionsOrderByIdSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_SUCCESS,
    payload: data,
  };
};
export const adminGetSubscriptionsOrderByIdFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_SUBSCRIPTION_ORDER_ID_FAILURE,
  payload: error,
});


export const adminAddSubscriptionOrderMessage = (reqData) => ({
  type: ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_START,
  payload: reqData,
});

export const adminAddSubscriptionOrderMessageSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_SUCCESS,
    payload: data,
  };
};

export const adminAddSubscriptionOrderMessageFailure = (error) => ({
  type: ActionTypes.ADMIN_SEND_SUBSCRIPTION_ORDER_MESSAGE_FAILURE,
  payload: error,
});



export const adminGetAllSubscriptions = (req) => ({
  type: ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_START,
  payload: req,
});

export const adminGetAllSubscriptionsSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const adminGetAllSubscriptionsFailure = (error) => ({
  type: ActionTypes.ADMIN_GET_ALL_SUBSCRIPTIONS_FAILURE,
  payload: error,
});

export const adminAddSubscriptionPlan = (reqData) => ({
  type: ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_START,
  payload: reqData,
});

export const adminAddSubscriptionPlanSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_SUCCESS,
    payload: data,
  };
};

export const adminAddSubscriptionPlanFailure = (error) => ({
  type: ActionTypes.ADMIN_ADD_SUBSCRIPTION_PLAN_FAILURE,
  payload: error,
});

export const adminUpdateStatusSubscription = (req) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_START,
  payload: req,
});

export const adminUpdateStatusSubscriptionSuccess = (data) => {
  return {
    type: ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_SUCCESS,
    payload: data,
  };
};
export const adminUpdateStatusSubscriptionFailure = (error) => ({
  type: ActionTypes.ADMIN_UPDATE_STATUS_SUBSCRIPTION_PLAN_FAILURE,
  payload: error,
});
