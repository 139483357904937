import { AppBaseRoutes, ShopMarketAppHomeRoutes, SellerAppHomeRoutes, AdminAppHomeRoutes } from "../routes-common/core-routes";
import { MarketLocalCommonRoutes } from "../routes-common/market-local-common-routes";
import { MarketplaceRoutes } from "../routes-common/marketplace-routes";
import { LocalMarketRoutes } from "../routes-common/localmarket-routes";
import { SellerAppRoutes } from "../routes-common/seller-app-routes";
import { SellerShopSiteRoutes } from "../routes-common/seller-shop-routes";
import { AdminAppRoutes } from "../routes-common/admin-routes";

// ifdef REACT_APP_TYPE = SELLERAPP
export const SellerMgmtRoutes = [
  ...AppBaseRoutes,
  ...SellerAppRoutes,
  ...SellerAppHomeRoutes
];
// endif

// ifdef REACT_APP_TYPE = ADMINAPP
export const AdminMgmtRoutes = [
  ...AppBaseRoutes,
  ...AdminAppRoutes,
  ...AdminAppHomeRoutes
];
// endif

// ifdef REACT_APP_TYPE = USERSITE
export const ShopWebsiteRoutes = [
  ...AppBaseRoutes,
  ...SellerShopSiteRoutes,
  ...ShopMarketAppHomeRoutes,
];
// endif

// ifdef REACT_APP_TYPE = MARKETPLACE
export const MarketplaceAppRoutes = [
  ...AppBaseRoutes,
  ...MarketplaceRoutes,
  ...MarketLocalCommonRoutes,
  ...ShopMarketAppHomeRoutes,
];
// endif

// ifdef REACT_APP_TYPE = LOCALMARKET
export const LocalMarketWebsiteRoutes = [
  ...AppBaseRoutes,
  ...LocalMarketRoutes,
  ...MarketLocalCommonRoutes,
  ...ShopMarketAppHomeRoutes,
];
// endif
