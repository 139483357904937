/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserProductSearch from "components/widgets/user/UserProductSearch";
import * as Actions from "actions";
import {isLocalMarket} from "helpers/utils";
import MarketHeaderMini from "components/elements/user/shops/MarketHeaderMini";
import ShopHeaderNav from "components/elements/user/shops/ShopHeaderNav";


const MarketUserProductPageSearch = (props) => {

    const {
        match, history,
        userResetSearchProducts, bootupInfo
    } = props;

    const marketId = (isLocalMarket() ? bootupInfo.appState.subdomain_marketId : match.params.marketId);


    const [state] = useState({
        layoutColumns: 3,
    });

    useEffect(() => {
        userResetSearchProducts();
        document.getElementById("color").setAttribute("href", "#");
    }, []);



    return (
        <div>
            <ShopHeaderNav history={history} />
            {isLocalMarket() && <MarketHeaderMini />}
            <UserProductSearch {...props} marketId={marketId} />
        </div>
    );
};

MarketUserProductPageSearch.defaultProps = {
  isLoading: false,
};

MarketUserProductPageSearch.propTypes = {
    userResetSearchProducts: PropTypes.func.isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.object).isRequired,
};

const marketUserProductPageSearch = connect(
  ({  bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: bootupInfo.reqStatus,
    isLoading: bootupInfo.isLoading,
    serviceError: bootupInfo.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(MarketUserProductPageSearch);

export default marketUserProductPageSearch;
