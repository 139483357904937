import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions/seller/seller.store";

import {Box, Container} from "@mui/material";
import {useEffect} from "react";

import VideoUpload from "components/VideoUpload";
import VideoPlayer from "components/VideoPlayer";


const AdminShopVideoEditor = (props) => {

  const { serviceError, reqStatus, isLoading,
      adminUpdateShopVideoClicked, video, shop_id,
      cancelEdit } = props;

    //const { logo } = selectedShop;

    useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
  }, [serviceError, reqStatus]);

  const sendNotification = (type, { title, description = "" }) => {
    console.log({
      message: title,
      description,
    });
  };

    const addVideoCallback = (file) => {

        const formData = new FormData();
        formData.append('inputFile', file);
//        const newFile = dataURIToBlob("data:video/mp4;base64,"+file);
//        formData.append("video", newFile);
        adminUpdateShopVideoClicked(shop_id, formData);
    }


    return (
        <Container>
            <Box >
                <VideoUpload isRemoveAllowed={false}
                             images={video}
                             addVideoCallback={addVideoCallback}
                             cancelEdit={cancelEdit}
                             idx="2"
                />

                <VideoPlayer
                    id="demo-player"
                    publicId={video && video[0] && video[0].cdn.secure_url}
                    width="360"
                    height="480"
                />

            </Box>
        </Container>
    );
};

export default AdminShopVideoEditor;
