// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const sellerResetStore = () => ({
  type: ActionTypes.SELLER_STORE_RESET
});

export const getDomain = (req) => ({
  type: ActionTypes.GET_DOMAIN_START,
  payload: req,
});

export const getDomainSuccess = (data) => {
  return {
    type: ActionTypes.GET_DOMAIN_SUCCESS,
    payload: data,
  };
};
export const getDomainFailure = (error) => ({
  type: ActionTypes.GET_DOMAIN_FAILURE,
  payload: error,
});

export const checkDomainAvailability = (req) => ({
  type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_START,
  payload: req,
});

export const checkDomainAvailabilitySuccess = (data) => {
  return {
    type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_SUCCESS,
    payload: data,
  };
};
export const checkDomainAvailabilityFailure = (error) => ({
  type: ActionTypes.CHECK_DOMAIN_AVAILABILITY_FAILURE,
  payload: error,
});

export const updateDomain = (req) => ({
  type: ActionTypes.UPDATE_DOMAIN_START,
  payload: req,
});

export const updateDomainSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_DOMAIN_SUCCESS,
    payload: data,
  };
};
export const updateDomainFailure = (error) => ({
  type: ActionTypes.UPDATE_DOMAIN_FAILURE,
  payload: error,
});

export const getStoreProfile = (req) => ({
  type: ActionTypes.GET_STORE_PROFILE_START,
  payload: req,
});

export const getStoreProfileSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const getStoreProfileFailure = (error) => ({
  type: ActionTypes.GET_STORE_PROFILE_FAILURE,
  payload: error,
});

export const updateStoreProfile = (req) => ({
  type: ActionTypes.UPDATE_STORE_PROFILE_START,
  payload: req,
});

export const updateStoreProfileSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};
export const updateStoreProfileFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_PROFILE_FAILURE,
  payload: error,
});

export const getStoreService = (req) => ({
  type: ActionTypes.GET_STORE_SERVICE_START,
  payload: req,
});

export const getStoreServiceSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_SERVICE_SUCCESS,
    payload: data,
  };
};
export const getStoreServiceFailure = (error) => ({
  type: ActionTypes.GET_STORE_SERVICE_FAILURE,
  payload: error,
});

export const updateStoreService = (req) => ({
  type: ActionTypes.UPDATE_STORE_SERVICE_START,
  payload: req,
});

export const updateStoreServiceSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_SERVICE_SUCCESS,
    payload: data,
  };
};
export const updateStoreServiceFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_SERVICE_FAILURE,
  payload: error,
});

export const getStoreReturns = (req) => ({
  type: ActionTypes.GET_STORE_RETURNS_START,
  payload: req,
});

export const getStoreReturnsSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_RETURNS_SUCCESS,
    payload: data,
  };
};
export const getStoreReturnsFailure = (error) => ({
  type: ActionTypes.GET_STORE_RETURNS_FAILURE,
  payload: error,
});

export const updateStoreReturns = (req) => ({
  type: ActionTypes.UPDATE_STORE_RETURNS_START,
  payload: req,
});

export const updateStoreReturnsSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_RETURNS_SUCCESS,
    payload: data,
  };
};
export const updateStoreReturnsFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_RETURNS_FAILURE,
  payload: error,
});

export const getStorePayment = (req) => ({
  type: ActionTypes.GET_STORE_PAYMENT_START,
  payload: req,
});

export const getStorePaymentSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_PAYMENT_SUCCESS,
    payload: data,
  };
};
export const getStorePaymentFailure = (error) => ({
  type: ActionTypes.GET_STORE_PAYMENT_FAILURE,
  payload: error,
});

export const updateStorePayment = (req) => ({
  type: ActionTypes.UPDATE_STORE_PAYMENT_START,
  payload: req,
});

export const updateStorePaymentSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_PAYMENT_SUCCESS,
    payload: data,
  };
};
export const updateStorePaymentFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_PAYMENT_FAILURE,
  payload: error,
});

export const updateStorePaymentQRCode = (req) => ({
  type: ActionTypes.UPDATE_STORE_PAYMENT_QRCODE_START,
  payload: req,
});

export const updateStorePaymentQRCodeSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_PAYMENT_QRCODE_SUCCESS,
    payload: data,
  };
};
export const updateStorePaymentQRCodeFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_PAYMENT_QRCODE_FAILURE,
  payload: error,
});

export const getStoreTax = (req) => ({
  type: ActionTypes.GET_STORE_TAX_START,
  payload: req,
});

export const getStoreTaxSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_TAX_SUCCESS,
    payload: data,
  };
};
export const getStoreTaxFailure = (error) => ({
  type: ActionTypes.GET_STORE_TAX_FAILURE,
  payload: error,
});

export const updateStoreTax = (req) => ({
  type: ActionTypes.UPDATE_STORE_TAX_START,
  payload: req,
});

export const updateStoreTaxSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_TAX_SUCCESS,
    payload: data,
  };
};
export const updateStoreTaxFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_TAX_FAILURE,
  payload: error,
});

export const getStoreAddress = (req) => ({
  type: ActionTypes.GET_STORE_ADDRESS_START,
  payload: req,
});

export const getStoreAddressSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const getStoreAddressFailure = (error) => ({
  type: ActionTypes.GET_STORE_ADDRESS_FAILURE,
  payload: error,
});

export const updateStoreAddress = (req) => ({
  type: ActionTypes.UPDATE_STORE_ADDRESS_START,
  payload: req,
});

export const updateStoreAddressSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_ADDRESS_SUCCESS,
    payload: data,
  };
};
export const updateStoreAddressFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_ADDRESS_FAILURE,
  payload: error,
});

export const updateStoreLocation = (req) => ({
  type: ActionTypes.UPDATE_STORE_LOCATION_START,
  payload: req,
});

export const updateStoreLocationSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_LOCATION_SUCCESS,
    payload: data,
  };
};
export const updateStoreLocationFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_LOCATION_FAILURE,
  payload: error,
});

export const getStoreSocial = (req) => ({
  type: ActionTypes.GET_STORE_SOCIAL_START,
  payload: req,
});

export const getStoreSocialSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_SOCIAL_SUCCESS,
    payload: data,
  };
};
export const getStoreSocialFailure = (error) => ({
  type: ActionTypes.GET_STORE_SOCIAL_FAILURE,
  payload: error,
});

export const updateStoreSocial = (req) => ({
  type: ActionTypes.UPDATE_STORE_SOCIAL_START,
  payload: req,
});

export const updateStoreSocialSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_SOCIAL_SUCCESS,
    payload: data,
  };
};
export const updateStoreSocialFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_SOCIAL_FAILURE,
  payload: error,
});

export const getStoreImages = (req) => ({
  type: ActionTypes.GET_STORE_IMAGES_START,
  payload: req,
});

export const getStoreImagesSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_IMAGES_SUCCESS,
    payload: data,
  };
};
export const getStoreImagesFailure = (error) => ({
  type: ActionTypes.GET_STORE_IMAGES_FAILURE,
  payload: error,
});

export const addStoreImage = (reqData) => ({
  type: ActionTypes.ADD_STORE_IMAGE_START,
  formData: reqData
});

export const addStoreImageSuccess = (data) => {
  return {
    type: ActionTypes.ADD_STORE_IMAGE_SUCCESS,
    payload: data,
  };
};
export const addStoreImageFailure = (error) => ({
  type: ActionTypes.ADD_STORE_IMAGE_FAILURE,
  payload: error,
});

export const removeStoreImage = (reqData) => ({
  type: ActionTypes.REMOVE_STORE_IMAGE_START,
  payload: {image_id: reqData},
});

export const removeStoreImageSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_STORE_IMAGE_SUCCESS,
    payload: data,
  };
};
export const removeStoreImageFailure = (error) => ({
  type: ActionTypes.REMOVE_STORE_IMAGE_FAILURE,
  payload: error,
});

export const updateStoreLogo = (req) => ({
  type: ActionTypes.UPDATE_STORE_LOGO_START,
  payload: req,
});

export const updateStoreLogoSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_STORE_LOGO_SUCCESS,
    payload: data,
  };
};
export const updateStoreLogoFailure = (error) => ({
  type: ActionTypes.UPDATE_STORE_LOGO_FAILURE,
  payload: error,
});
