import React from 'react';
import ContentLoader from "react-content-loader";

const MockProductImage = (props) => (
    <ContentLoader
        speed={2}
        width="100%"
        height={360}
        backgroundColor="#f1f1f1"
        foregroundColor="#e1e1e1"
        {...props}
    >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="360" />
    </ContentLoader>
)

export default MockProductImage;
