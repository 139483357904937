// @flow
import * as React from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import API from "api";

import * as ActionTypes     from 'constants/ActionTypes';
import {
  getUserProfile, updateUserUUID, updateUserLocation,
  updateAdminLocation, getShopAndUserProfile, getShopUserProfile,
  getMarketAndUserProfile, getMarketProfile,
  getShopProfile, getCities, getCityLocations,
  getCartProducts, userMainGetShopInfoById, userMainGetProductCategories } from "actions";
import { AppContext } from "app-context";


import {Grid}           from "components/Atoms";
import {
  PageHeader,
  PageContent,
} from "components/elements/main";

import BottomNavSeller from "components/elements/main/BottomNavSeller";
import BottomNavUser from "components/elements/main/BottomNavUser";
import BottomNavMain from "components/elements/main/BottomNavMain";
import BottomNavAdmin from "components/elements/main/BottomNavAdmin";
import FooterOne from "components/elements/main/footers/footer-one";


//import {changeCurrency, getCartProducts, userMainGetShopInfoById, userMainGetProductCategories} from "../../actions";
import {getCity, updateCityList} from "../../helpers/utils";

const UNAUTHORIZED_ROUTES = [
  "/login",
  "/password/reset",
  "/password/create",
  "/unsubscribe",
];

const SHOP_ROUTES = [
  "/test"
];
class Bootstrap extends React.PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      bootUpCompleted: true,
      collapsed: false,
      isLoading: true,
      subdomainshopId: "",
      subdomainmarketId: "",
      routeType: "",
      pageHeader: {
        title: "",
        breadCrumb: "",
      },
    };
    this.attachInterceptor();

    const cordova = window.cordova;

    switch (process.env.REACT_APP_TYPE) {
      case "USERSITE":

        // Seller web site : e.g. xyzseller.shopana.com
        let subdomainKeyShop = window.location.host.split('.').length === 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
        if (window.location.host.split('.')[0] === "www") {
          subdomainKeyShop = window.location.host.split('.')[1];
        }
        this.props.bootupInfo.appState.subdomain_shopId = subdomainKeyShop;
        //this.state.routeType = "SELLERSITE";
        this.state.subdomainshopId = this.props.bootupInfo.appState.subdomain_shopId;

        if (localStorage.getItem("isAuthorized") === "true") {
          // user information and shop information
          props.getShopAndUserProfile(this.props.bootupInfo.appState.subdomain_shopId, true);
          if (cordova) {
            props.updateUserUUID();
          }
          props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, true);
        } else {
          props.getShopProfile(this.props.bootupInfo.appState.subdomain_shopId, true);
        }

        break;

      case "SELLERAPP":
        // Seller mgmt site : e.g. my.shopana.com
        if (localStorage.getItem("isAuthorized") === "true") {
          // user and associated shop information
          props.getShopUserProfile();
          if (cordova) {
            props.updateUserUUID();
          }
        } else {
          this.props.history.push("/login");
        }
        break;

      case "ADMINAPP":
        // Admin mgmt site : e.g. admin.shopana.com
        if (localStorage.getItem("isAuthorized") === "true") {
          // user information
          props.getUserProfile();
          props.updateAdminLocation();
          if (cordova) {
            props.updateUserUUID();
          }
          props.getCities();
        } else {
          this.props.history.push("/login");
        }
        break;

      case "LOCALMARKET":
        // Seller web site : e.g. xyzseller.shopana.com
        let subdomainKeyMarket = window.location.host.split('.').length === 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
        if (window.location.host.split('.')[0] === "www") {
          subdomainKeyMarket = window.location.host.split('.')[1];
        }
        this.props.bootupInfo.appState.subdomain_marketId = subdomainKeyMarket;
        this.state.subdomainmarketId = this.props.bootupInfo.appState.subdomain_marketId;

        if (localStorage.getItem("isAuthorized") === "true") {
          // user information and shop information
          props.getMarketAndUserProfile(this.props.bootupInfo.appState.subdomain_marketId, true);
          if (cordova) {
            props.updateUserUUID();
          }
        } else {
          props.getMarketProfile(this.props.bootupInfo.appState.subdomain_marketId, true);
        }


      case "MARKETPLACE":

      default:
        if (localStorage.getItem("isAuthorized") === "true") {
          // user information
          props.getUserProfile();

          // If marketplace, update user location for alerts
          props.updateUserLocation();
          if (cordova) {
            props.updateUserUUID();
          }
        }
        props.getCities();
        props.userMainGetProductCategories();

        // get area for default/saved city
        if (getCity()) {
          props.getCityLocations(getCity().id);
        }
        break;
    }
  }

  attachInterceptor() {

    API.interceptors.request.use((request) => {
      const { bootupInfo } = this.props;
      const isLoginRequest = (request.url.indexOf("/login") !== -1) ||
                              request.url.indexOf("/postotp_login") !== -1 ||
                              (request.url.indexOf("/login/success") !== -1);

      if (localStorage.getItem("isAuthorized") === "true") {
        bootupInfo.bootupCompleted = true;
      }

      if (request.withCredentials) {
        if (localStorage.getItem("isAuthorized") === "true") {
          request.headers.Authorization = localStorage.getItem("token");
        } else {
          this.props.history.push("/login");
        }
      } else if (!isLoginRequest && bootupInfo.bootupCompleted) {
        request.headers.Authorization = localStorage.getItem("token");
      }

      return request;
    });

    API.interceptors.response.use(
      (response) => {
        if (
          response.config.url.indexOf("/logout") >= 0 &&
          response.status === 200
        ) {
          this.props.history.push("/login");
        }
        // if website and no shop info then redirect to unknown website 404
        if (process.env.REACT_APP_TYPE === "USERSITE") {
          if (response.data.statusCode === 404) {
            // Fix it. Redirect to marketplace site, e.g. www.shopana.com
            window.location.href = process.env.REACT_APP_MARKETPLACE;
//            this.props.history.push("/404");
          }
        }

        if (process.env.REACT_APP_TYPE === "SELLERAPP") {
          if (response.data.statusCode === 403) {
            // if not current page then redirect to register-store
            // otherwise causing loop of reloading same page
            const pageRoute = this.props.location.pathname;
            if (pageRoute.indexOf("/register-store") === -1) {
              this.props.history.push("/register-store");
            }
          }
        }

        if (process.env.REACT_APP_TYPE === "ADMINAPP") {
          if (response.data.statusCode === 403) {
            this.props.history.push("/login");
          }
        }

        return response.data;
      },
      (error) => {
        const errorStatusCode = error.response && error.response.status;
        switch (errorStatusCode) {
          case 404: {
            this.props.history.push("/404");
            return;
          }
        }
        return Promise.reject(error.response);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { bootupInfo, history, match } = this.props;
    const { serviceError, reqStatus } = bootupInfo;
    const {
      serviceError: prevServiceError,
      reqStatus: prevReqStatus,
    } = prevProps.bootupInfo;

    const pageRoute = this.props.location.pathname;

    const pathArray = pageRoute.split("/");
    let subdomainKey;

    switch (process.env.REACT_APP_TYPE) {
      case "USERSITE":

        subdomainKey = window.location.host.split('.').length === 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
        if (window.location.host.split('.')[0] === "www") {
          subdomainKey = window.location.host.split('.')[1];
        }

        this.props.bootupInfo.appState.subdomain_shopId = subdomainKey;

        if (prevState.subdomainshopId !== this.props.bootupInfo.appState.subdomain_shopId) {
          this.props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, true);
        }
        break;

      case "SELLERAPP":
        this.props.bootupInfo.appState.isSeller = true;
        break;

      case "ADMINAPP":
        this.props.bootupInfo.appState.isSeller = true;
        break;

      case "LOCALMARKET":
        subdomainKey = window.location.host.split('.').length === 1 ? process.env.DEFAULT_BRAND : window.location.host.split('.')[0];
        if (window.location.host.split('.')[0] === "www") {
          subdomainKey = window.location.host.split('.')[1];
        }

        this.props.bootupInfo.appState.isMarketMall = true;
        this.props.bootupInfo.appState.subdomain_marketId = subdomainKey;

        // handle change in marketarea
        if (prevState.subdomainmarketId !== this.props.bootupInfo.appState.subdomain_marketId) {
          //this.props.getMarketProfile(this.props.bootupInfo.appState.subdomain_marketId, false);
        }

        // CONTINUE to common logic between market place and local market
      case "MARKETPLACE":
      default:
        if (pathArray && pathArray[1] === ("shop")) {
          this.props.bootupInfo.appState.subdomain_shopId = pathArray[2];
          this.state.routeType = "SHOPPAGE";

          if (prevState.subdomainshopId !== this.props.bootupInfo.appState.subdomain_shopId) {
            this.props.getShopProfile(this.props.bootupInfo.appState.subdomain_shopId, false);
            this.props.getCartProducts(this.props.bootupInfo.appState.subdomain_shopId, false);
            this.state.subdomainshopId = this.props.bootupInfo.appState.subdomain_shopId;
          }
        } else {
          this.state.routeType = "MAINPAGE";
          this.state.subdomainshopId = "";
          this.state.subdomainmarketId = "";
        }

        break;
    }


    if (reqStatus === prevReqStatus) return;

    const isLoginRoute = (pageRoute.indexOf("/login") !== -1) || (pageRoute.indexOf("/login/success") !== -1);
    switch (reqStatus) {
      case ActionTypes.LOGIN_SUCCESS:
      case ActionTypes.LOGIN_POST_OTP_SUCCESS:
        // Update UUID only on new login
        const cordova = window.cordova;
        if (cordova) {
          this.props.updateUserUUID();
        }

        // If marketplace, update user location for alerts
        if (process.env.REACT_APP_TYPE === "MARKETPLACE") {
          this.props.updateUserLocation();
        } else if (process.env.REACT_APP_TYPE === "ADMINAPP") {
          this.props.updateAdminLocation();
        }

      case ActionTypes.GET_USER_PROFILE_SUCCESS:
      case ActionTypes.SOCIAL_LOGIN_SUCCESS:
        if (isLoginRoute) history.push("/");


        if (process.env.REACT_APP_TYPE === "SELLERAPP") {
          if (bootupInfo.shop.length < 1) {
            // if current page is not same then redirect
            if (pageRoute.indexOf("/register-store") === -1) {
              history.push("/register-store");
            }
          }
        }
        break;
      case ActionTypes.GET_USER_PROFILE_FAILURE:
      case ActionTypes.LOGOUT_SUCCESS:
      case ActionTypes.SOCIAL_LOGIN_FAILURE:
        history.push("/login");
        break;

      case ActionTypes.GET_SHOP_USER_PROFILE_SUCCESS:
        // redirected in intercept before render happens
        //history.push("/404");
        break;

      case ActionTypes.GET_CITIES_SUCCESS:
        updateCityList(this.props.bootupInfo.cities);
        break;

      default:
        break;
    }
  }

  setPageHeader = (headerContent) => {
    const state = { pageHeader: { ...headerContent } };
    this.setState(state);
  };

  onSiderCollapse = (collapsed) => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {

    const { logout, bootupInfo, history, isLoading, match } = this.props;
    const { userDetails = {}, shopinfo, shop, bootupCompleted } = bootupInfo;
    const { pageHeader, collapsed, subdomainshopId, subdomainmarketId, routeType }  = this.state;

    const { shopId } = match.params;

    const pageRoute = this.props.location.pathname;

    const pathArray = pageRoute.split("/");

    let bottomNav;

    switch (process.env.REACT_APP_TYPE) {
      case "USERSITE":
        this.props.bootupInfo.appState.isWebsite = true;
        bottomNav = <BottomNavUser isShopPage={false} pathArray={pathArray} {...this.props} />
        break;

      case "SELLERAPP":
        bottomNav = <BottomNavSeller pathArray={pathArray} {...this.props} />
        this.props.bootupInfo.appState.isSeller = true;
        break;

      case "ADMINAPP":
        bottomNav = <BottomNavAdmin userDetails={userDetails} pathArray={pathArray} {...this.props} />
        break;

      case "LOCALMARKET":
      case "MARKETPLACE":
      default:
        if (pathArray && pathArray[1] === "shop") {
          this.state.routeType = "SHOPPAGE";
          bottomNav = <BottomNavUser isShopPage={true} pathArray={pathArray} shopId={this.props.bootupInfo.appState.subdomain_shopId} {...this.props} />
        } else {
          this.state.routeType = "MAINPAGE";
          bottomNav = <BottomNavMain pathArray={pathArray} {...this.props} />
        }
        break;
    }

    if (UNAUTHORIZED_ROUTES.indexOf(pageRoute) !== -1) {
      return (
          <Grid container>
            <Grid item lg={2} md={2} >
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
            </Grid>
            <Grid item lg={3} md={2} >
            </Grid>
          </Grid>
      );
    }

    // redirect all seller routes to claim url if store is not created yet
    if (SHOP_ROUTES.indexOf(pageRoute) !== -1) {
      return (
          <div>
            <Grid container>
              <Grid item lg={2} md={2} >
              </Grid>
              <Grid item lg={7} md={8} sm={12} xs={12}>
                <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
              </Grid>
              <Grid item lg={3} md={2} >
              </Grid>
            </Grid>
          </div>
      );
    }


    return (
        <AppContext.Provider
            value={{
              pageHeader: pageHeader,
              setPageHeader: this.setPageHeader,
            }}
        >
          <AppContext.Consumer>
            {({ pageHeader, setPageHeader }) => (
                <PageHeader
                    routeType={this.state.routeType}
                    subdomain_shopId={this.props.bootupInfo.appState.subdomain_shopId}
                    shopinfo={shopinfo}
                    logout={logout}
                    pageRoute={pageRoute}
                    titleContent={pageHeader}
                    userName={this.props.bootupInfo.userDetails.name || "Login"}
                    collapseSider={this.onSiderCollapse}
                    userNotifications={[]}
                />
            )}
          </AppContext.Consumer>

          <Grid container>
            <Grid item lg={2} md={2} >
            </Grid>
            <Grid item lg={7} md={8} sm={12} xs={12}>
              <PageContent style={{ zIndex: -1}}>{this.props.children}</PageContent>
            </Grid>
            <Grid item lg={3} md={2} >
            </Grid>
          </Grid>
          <FooterOne height={50} />

            {bottomNav}

        </AppContext.Provider>
    );
  }
}


Bootstrap.contextTypes = {
  router: PropTypes.object,
};

export default withRouter(
  connect(
    (state) => ({ bootupInfo: state.bootupInfo,}),
    (dispatch) => bindActionCreators({ getUserProfile, updateUserUUID, updateUserLocation, updateAdminLocation, getShopUserProfile, getShopAndUserProfile, getShopProfile, getMarketAndUserProfile, getMarketProfile, getCartProducts, userMainGetShopInfoById, getCities, getCityLocations, userMainGetProductCategories }, dispatch)
  )(Bootstrap)
);
