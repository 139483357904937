/* eslint-disable import/no-unresolved */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Container, Box,
} from "@mui/material";

// Import Swiper styles
import 'swiper/swiper-bundle.css';

import { connect } from "react-redux";
import * as Actions from "actions";
import { bindActionCreators } from "redux";
import CategoryItem from "./CategoryItems";
import CustomSectionCreator from "components/CustomSectionCreator";
import StorageIcon from "@mui/icons-material/Storage";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {H2, H4} from "components/CustomTypography";
import CustomFlexBox from "components/CustomFlexBox";


const UserShopLocationSlider = (props) => {

  const { citylocations, isDirect, history, isProduct, setLocation, handleSelectCategorySet, selectedCategory, openCategoryList, headerTitle } = props;

  const handleSelectLink = (id) => {
      if (isProduct) {
          history.push("/products/location/" + id);
      } else {
          history.push("/shops/location/" + id);
      }
  };

  if (!citylocations) {
    return null;
  }

  const { areas } = citylocations;

  if (!areas.length) {
    return <></>;
  }

  let popularlist = [ { "id" : "exact", "name" : "Near me"} ];
  let categorylist = null;

    areas && areas.length > 0 && areas.forEach((area) => {
        //if (area.is_popular === true) {
            popularlist.push(area);
        //}
  });

    const breakpoints = {
        480: {
            slidesPerView: 5,
            spaceBetween: 0,
        },
        640: {
            slidesPerView: 6,
            spaceBetween: 0,
        },
        768: {
            slidesPerView: 7,
            spaceBetween: 0,
        },
        1024: {
            slidesPerView: 8,
            spaceBetween: 0,
        },
    };

  return (
      <Container>
          <Box mb={2} sx={{borderBottom: 1, borderTop: 1, borderColor: 'primary.100'}}>
          <swiper-container slides-per-view="3.5" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)} grid-fill="row" grid-rows={2}>
              { popularlist.map((category, index) => (
                  <swiper-slide key={index+1}>
                      <CategoryItem key={`${index + 1}`} isSelected={setLocation === category.id} isDirect={isDirect} category={category} handleSelectCategoryLink={handleSelectLink} handleSelectCategorySet={handleSelectCategorySet} />
                  </swiper-slide>
              )) }
          </swiper-container>
          </Box>
      </Container>
  );
};

UserShopLocationSlider.propTypes = {
  usergetProductCategories: PropTypes.func.isRequired,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

const _userShopLocationSlider = connect(
  ({ bootupInfo }) => ({
    bootupInfo,
    citylocations: bootupInfo.citylocations,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserShopLocationSlider);

export default _userShopLocationSlider;
