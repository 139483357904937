// @flow
import * as ActionTypes from '../../constants/ActionTypes';

export const sellerResetProducts = () => ({
  type: ActionTypes.SELLER_PRODUCTS_RESET
});

export const getActiveCategories = (reqData) => ({
  type: ActionTypes.GET_ACTIVE_CATEGORIES_START,
  payload: reqData,
});

export const getActiveCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.GET_ACTIVE_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const getActiveCategoriesFailure = (error) => ({
  type: ActionTypes.GET_ACTIVE_CATEGORIES_FAILURE,
  payload: error,
});

export const getStoreProductCategories = (reqData) => ({
  type: ActionTypes.GET_STORE_PRODUCT_CATEGORIES_START,
  payload: reqData,
});

export const getStoreProductCategoriesSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_PRODUCT_CATEGORIES_SUCCESS,
    payload: data,
  };
};
export const getStoreProductCategoriesFailure = (error) => ({
  type: ActionTypes.GET_STORE_PRODUCT_CATEGORIES_FAILURE,
  payload: error,
});

export const getCommonProducts = (reqData, category) => ({
  type: ActionTypes.GET_COMMON_PRODUCTS_START,
  payload: reqData,
  category: category
});

export const getCommonProductsSuccess = (data) => {
  return {
    type: ActionTypes.GET_COMMON_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const getCommonProductsFailure = (error) => ({
  type: ActionTypes.GET_COMMON_PRODUCTS_FAILURE,
  payload: error,
});

export const getStoreProducts = (reqData, isPrivate, sort, offset) => ({
  type: ActionTypes.GET_STORE_PRODUCTS_START,
  category: reqData,
  private: isPrivate,
  sort: sort,
  offset: offset
});

export const getStoreProductsSuccess = (data) => {
  return {
    type: ActionTypes.GET_STORE_PRODUCTS_SUCCESS,
    payload: data,
  };
};
export const getStoreProductsFailure = (error) => ({
  type: ActionTypes.GET_STORE_PRODUCTS_FAILURE,
  payload: error,
});

export const addProduct = (reqData) => ({
  type: ActionTypes.ADD_PRODUCT_START,
  payload: reqData,
});

export const addProductSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const addProductFailure = (error) => ({
  type: ActionTypes.ADD_PRODUCT_FAILURE,
  payload: error,
});

export const updateProduct = (reqData) => ({
  type: ActionTypes.UPDATE_PRODUCT_START,
  payload: reqData,
});

export const updateProductSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const updateProductFailure = (error) => ({
  type: ActionTypes.UPDATE_PRODUCT_FAILURE,
  payload: error,
});

export const removeProduct = (reqData) => ({
  type: ActionTypes.REMOVE_PRODUCT_START,
  payload: reqData,
});

export const removeProductSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const removeProductFailure = (error) => ({
  type: ActionTypes.REMOVE_PRODUCT_FAILURE,
  payload: error,
});

export const addProductUPC = (reqData) => ({
  type: ActionTypes.ADD_PRODUCT_UPC_START,
  payload: reqData,
});

export const addProductUPCSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRODUCT_UPC_SUCCESS,
    payload: data,
  };
};

export const addProductUPCFailure = (error) => ({
  type: ActionTypes.ADD_PRODUCT_UPC_FAILURE,
  payload: error,
});

export const searchProducts = (reqData, category, upc, isUPCBased) => ({
  type: ActionTypes.SEARCH_PRODUCTS_START,
  payload: reqData,
  category: category,
  upc: upc,
  isUPCBased: isUPCBased
});

export const searchProductsSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const searchProductsFailure = (error) => ({
  type: ActionTypes.SEARCH_PRODUCTS_FAILURE,
  payload: error,
});

export const searchKeywords = (reqData) => ({
  type: ActionTypes.SEARCH_KEYWORDS_START,
  payload: reqData,
});

export const searchKeywordsSuccess = (data) => {
  return {
    type: ActionTypes.SEARCH_KEYWORDS_SUCCESS,
    payload: data,
  };
};

export const searchKeywordsFailure = (error) => ({
  type: ActionTypes.SEARCH_KEYWORDS_FAILURE,
  payload: error,
});

export const checkProducts = (reqData) => ({
  type: ActionTypes.CHECK_PRODUCT_START,
  payload: reqData,
});

export const checkProductsSuccess = (data) => {
  return {
    type: ActionTypes.CHECK_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const checkProductsFailure = (error) => ({
  type: ActionTypes.CHECK_PRODUCT_FAILURE,
  payload: error,
});

export const addPrivateProduct = (reqData) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_START,
  payload: reqData,
});

export const addPrivateProductSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const addPrivateProductFailure = (error) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_FAILURE,
  payload: error,
});

export const updatePrivateProduct = (reqData) => ({
  type: ActionTypes.UPDATE_PRIVATE_PRODUCT_START,
  payload: reqData,
});

export const updatePrivateProductSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_PRIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const updatePrivateProductFailure = (error) => ({
  type: ActionTypes.UPDATE_PRIVATE_PRODUCT_FAILURE,
  payload: error,
});

export const removePrivateProduct = (reqData) => ({
  type: ActionTypes.REMOVE_PRIVATE_PRODUCT_START,
  payload: reqData,
});

export const removePrivateProductSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_PRIVATE_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const removePrivateProductFailure = (error) => ({
  type: ActionTypes.REMOVE_PRIVATE_PRODUCT_FAILURE,
  payload: error,
});

export const addPrivateProductImage = (reqData, formData) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const addPrivateProductImageSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const addPrivateProductImageFailure = (error) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const removePrivateProductImage = (reqData, image_id) => ({
  type: ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_START,
  payload: {prod_id: reqData, image_id: image_id},
});

export const removePrivateProductImageSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_SUCCESS,
    payload: data,
  };
};
export const removePrivateProductImageFailure = (error) => ({
  type: ActionTypes.REMOVE_PRIVATE_PRODUCT_IMAGE_FAILURE,
  payload: error,
});

export const addPrivateProductImageQuick = (formData) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_QUICK_START,
  formData: formData
});

export const addPrivateProductImageQuickSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_QUICK_SUCCESS,
    payload: data,
  };
};
export const addPrivateProductImageQuickFailure = (error) => ({
  type: ActionTypes.ADD_PRIVATE_PRODUCT_IMAGE_QUICK_FAILURE,
  payload: error,
});

export const addPrivateCategory = (reqData) => ({
  type: ActionTypes.ADD_PRIVATE_CATEGORY_START,
  payload: reqData,
});

export const addPrivateCategorySuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRIVATE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const addPrivateCategoryFailure = (error) => ({
  type: ActionTypes.ADD_PRIVATE_CATEGORY_FAILURE,
  payload: error,
});

export const updatePrivateCategory = (reqData) => ({
  type: ActionTypes.UPDATE_PRIVATE_CATEGORY_START,
  payload: reqData,
});

export const updatePrivateCategorySuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_PRIVATE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const updatePrivateCategoryFailure = (error) => ({
  type: ActionTypes.UPDATE_PRIVATE_CATEGORY_FAILURE,
  payload: error,
});

export const updatePrivateCategorySetting = (reqData) => ({
  type: ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_START,
  payload: reqData,
});

export const updatePrivateCategorySettingSuccess = (data) => {
  return {
    type: ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_SUCCESS,
    payload: data,
  };
};
export const updatePrivateCategorySettingFailure = (error) => ({
  type: ActionTypes.UPDATE_PRIVATE_CATEGORY_SETTING_FAILURE,
  payload: error,
});

export const removePrivateCategory = (reqData) => ({
  type: ActionTypes.REMOVE_PRIVATE_CATEGORY_START,
  payload: reqData,
});

export const removePrivateCategorySuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_PRIVATE_CATEGORY_SUCCESS,
    payload: data,
  };
};
export const removePrivateCategoryFailure = (error) => ({
  type: ActionTypes.REMOVE_PRIVATE_CATEGORY_FAILURE,
  payload: error,
});

export const addPrivateCategoryImage = (reqData, formData) => ({
  type: ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_START,
  payload: reqData,
  formData: formData
});

export const addPrivateCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const addPrivateCategoryImageFailure = (error) => ({
  type: ActionTypes.ADD_PRIVATE_CATEGORY_IMAGE_FAILURE,
  payload: error,
});

export const removePrivateCategoryImage = (reqData, image_id) => ({
  type: ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_START,
  payload: {categ_id: reqData, image_id: image_id},
});

export const removePrivateCategoryImageSuccess = (data) => {
  return {
    type: ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_SUCCESS,
    payload: data,
  };
};
export const removePrivateCategoryImageFailure = (error) => ({
  type: ActionTypes.REMOVE_PRIVATE_CATEGORY_IMAGE_FAILURE,
  payload: error,
});
