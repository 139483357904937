// @flow
import * as ActionTypes from 'constants/ActionTypes';
import {SELLER_SEARCH_OFFERS_START} from "constants/ActionTypes";

const initialState = {
  serviceError: "",
  serviceCode: 200,
  reqStatus: "",
  isLoading: false,

  searchoffers: {
    offers:[],
    otheroffers:[],
  },
  shopoffers: {
    offers:[],
    otheroffers:[],
  },
  offerdetail: {
    images: [],
  },
  offerImageList: {
    images: [],
  },
};
export default function(state: Object = initialState, action: Object) {
  switch (action.type) {
    /* Common Triggers */
    case ActionTypes.ADMIN_SEARCH_OFFERS_START:
    case ActionTypes.ADMIN_SHOP_OFFERS_START:
    case ActionTypes.ADMIN_GET_OFFER_INFO_START:
    case ActionTypes.ADMIN_UPDATE_OFFER_STATUS_START:
    case ActionTypes.ADMIN_ADD_OFFER_START:
    case ActionTypes.ADMIN_UPDATE_OFFER_INFO_START:
    case ActionTypes.ADMIN_ADD_OFFER_QUICK_START: {
      return {
        ...state,
        isLoading: true,
        reqStatus: action.type,
        serviceError: "",
      };
    }

    /* Common Failures */
    case ActionTypes.ADMIN_SEARCH_OFFERS_FAILURE:
    case ActionTypes.ADMIN_SHOP_OFFERS_FAILURE:
    case ActionTypes.ADMIN_GET_OFFER_INFO_FAILURE:
    case ActionTypes.ADMIN_UPDATE_OFFER_STATUS_FAILURE:
    case ActionTypes.ADMIN_ADD_OFFER_FAILURE:
    case ActionTypes.ADMIN_UPDATE_OFFER_INFO_FAILURE:
    case ActionTypes.ADMIN_ADD_OFFER_QUICK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        reqStatus: action.type,
        serviceError: { ...action.payload },
      };
    }

    /* Success Handlers */

    case ActionTypes.ADMIN_SEARCH_OFFERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { offers, otheroffers } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchoffers: {
            offers : offers,
            otheroffers: otheroffers,
          },

        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          searchoffers: {
            offers: [],
            otheroffers: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_SHOP_OFFERS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        const { offers, otheroffers } = action.payload.response;
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shopoffers: {
            offers : offers,
            otheroffers : otheroffers,
          },

        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          shopoffers: {
            offers: [],
            otheroffers: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_GET_OFFER_INFO_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          offerdetail: action.payload.response
        };
      } else {
        return { ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          offerdetail: {
            images: [],
          }
        };
      }
    }

    case ActionTypes.ADMIN_UPDATE_OFFER_STATUS_SUCCESS: {
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    case ActionTypes.ADMIN_ADD_OFFER_SUCCESS:
    case ActionTypes.ADMIN_UPDATE_OFFER_INFO_SUCCESS:
    case ActionTypes.ADMIN_ADD_OFFER_QUICK_SUCCESS:{
      if (action.payload.statusCode === 200) {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: "",
          serviceCode: action.payload.statusCode,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          reqStatus: action.type,
          serviceError: action.payload.statusMessage,
          serviceCode: action.payload.statusCode,
        };
      }
    }

    default:
      return state;
  }
}
