import * as ActionTypes from '../../constants/ActionTypes'
import 'react-toastify/dist/ReactToastify.min.css';


export const userMainResetOrders = () => ({
    type: ActionTypes.USER_MAIN_ORDERS_RESET
});

export const userMainGetOrderlistProducts = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_ORDERLIST_PRODUCTS_START,
    payload: reqData,
});

export const userMainGetOrderlistProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_ORDERLIST_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const userMainGetOrderlistProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_ORDERLIST_PRODUCTS_FAILURE,
    payload: error,
});

export const userMainGetOrderDetail = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_ORDER_DETAIL_START,
    payload: reqData,
});

export const userMainGetOrderDetailSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_ORDER_DETAIL_SUCCESS,
        payload: data,
    };
};

export const userMainGetOrderDetailFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_ORDER_DETAIL_FAILURE,
    payload: error,
});
