import * as ActionTypes from '../../constants/ActionTypes'


export const usergetLatestOffers = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_GET_LATEST_OFFERS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
});

export const usergetLatestOffersSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_LATEST_OFFERS_SUCCESS,
        payload: data,
    };
};
export const usergetLatestOffersFailure = (error) => ({
    type: ActionTypes.USER_GET_LATEST_OFFERS_FAILURE,
    payload: error,
});

export const usergetOfferDetail = (reqData, shopId, isShopDomain) => ({
    type: ActionTypes.USER_GET_OFFER_DETAIL_START,
    payload: reqData,
    shopId: shopId,
    isShopDomain: isShopDomain
});

export const usergetOfferDetailSuccess = (data) => {
    return {
        type: ActionTypes.USER_GET_OFFER_DETAIL_SUCCESS,
        payload: data,
    };
};

export const usergetOfferDetailFailure = (error) => ({
    type: ActionTypes.USER_GET_OFFER_DETAIL_FAILURE,
    payload: error,
});
