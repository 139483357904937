import * as React from "react";
import {
    Box, Card, Container, FormControlLabel, IconButton, Radio, Typography,
} from "@mui/material";
import ButtonComponent from "components/Button";
import RadioButtonFieldComponent from "components/RadioButtonField";
import TextFieldComponent from "components/TextField";
import { Link } from "react-router-dom";
import {
  getButtonData, getRadioButtonData, getButtonDataAccept, getButtonDataReject, getTextfieldDataRejectMessage,
    schema,
} from "./helper";
import { DATE_FORMAT } from "config/constants";
import { getLocalDate, getTimeSlot} from "helpers/utils";
import MockProductList from "components/elements/main/MockProductList";
import { getImage } from "helpers/utils";

import {useState} from "react";
import PropTypes from "prop-types";
import HoverBox from "components/HoverBox";
import {H2} from "components/CustomTypography";
import {H6} from "components/Typography";


const SellerOrderEditor = ({
  setState, selectedOrder, symbol, updateOrderClicked, onCancel, orderdetail, isLoading
}) => {

  const {
     created_at: createdAt, order_id: orderIdValue,
    order_type, order_id, _id, status, user, message
  } = selectedOrder || {};

    const {
        products, shipping,
        total_order, total_price, total_shipping, total_tax,
        delivery_date, delivery_time
    } = orderdetail || {};


  const [blur, setblur] = React.useState({
    status: false,
  });

  const onFinish = () => {
    updateOrderClicked({ status, order_id, _id });
  };

  const onFinishAccept = () => {
    updateOrderClicked({ status: "accepted", order_id, _id, message: message });
  };

  const onFinishReject = () => {
    updateOrderClicked({ status: "rejected", order_id, _id, message: message });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedOrder);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.status);

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedOrder);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const handleRadioButtonChange = (event, field) => {
        setState(event.target.value, field);
    };

    const handleTextFieldChange = (event, field) => {
        setState(event.target.value, field);
    };

  let options;
  { order_type === "pickup"
    ? options = [
      { label: "Accepted", value: "accepted" },
      { label: "In progress", value: "inprogress" },
      { label: "Ready for Pickup", value: "pickupready" },
      { label: "Picked", value: "picked" },
    ]
    : options = [
      { label: "Accepted", value: "accepted" },
      { label: "In progress", value: "inprogress" },
      { label: "Ready for Delivery", value: "readydelivery" },
      { label: "Delivered", value: "delivered" },
    ];
  }

  const inputOptions = options.map((item) => {
    const { label, value } = item;
    return (
      <FormControlLabel value={value} control={<Radio />} label={label} />
    );
  });


    const renderOrderInformation = () => (
        <Box p={2} width="100%" display="flex">
            <Box width="50%">
                <Typography className="styled-link" variant="subtitle2">
                    <H2 fontSize={14}  ellipsis>
                    {`Order Id: ${orderIdValue}`}
                    </H2>
                </Typography>
            </Box>
            <Box width="50%">
                <Typography className="styled-link" align="right" display="block" variant="subtitle2">
                    <H2 fontSize={14}  ellipsis>
                    Date:
                    {" "}
                    {getLocalDate(createdAt)}
                    </H2>
                </Typography>
            </Box>
        </Box>
    );

    const renderOrderItems = () => (
        <Box>
            {products.map((cartItem, index) => (
                <Box className="styled-card" width="100%" display="flex" p={1}>
                    <Box width="30%" order={1}>
                        <Typography align="center">
                            <HoverBox  mb={0} mx="auto" borderRadius="2px">
                                <img
                                    alt=""
                                    className="img-product"
                                    src={getImage(cartItem.product.images)}
                                />
                            </HoverBox>
                        </Typography>
                    </Box>
                    <Box width="50%" order={1} ml={2}>
                        <Typography align="left">
                            <H6 mb={1} fontSize={14}  ellipsis>
                                {cartItem.product.name}
                            </H6>
                            <H6 fontSize={12}>
                                {cartItem.quantity}
                                {" "}
                                x
                                {symbol}
                                {cartItem.seller_price}
                            </H6>
                            <H6 mb={1} fontSize={14}  ellipsis>
                                {cartItem.product_size}
                            </H6>
                        </Typography>
                    </Box>
                    <Box width="20%" order={1} ml={3}>
                        <H6 fontSize={14}>
                            {symbol}
                            {cartItem.quantity * cartItem.seller_price}
                        </H6>
                    </Box>
                </Box>
            ))}
        </Box>
    );

    const renderShippingAddress = () => (

        <Box className="styled-card" width="100%" display="flex">
            <Box width="30%" order={1}>
                <Typography align="left">
                    <H6 fontSize={14} >
                        Delivery Address:
                    </H6>
                </Typography>
            </Box>
            <Box width="70%" order={1}>
                <Typography align="left">
                    <H6 fontSize={14} ellipsis>
                        {shipping.name}
                    </H6>
                    <H6 fontSize={14}>
                        {shipping.address}
                    </H6>
                    <H6 fontSize={14}>

                        {shipping.city}
                        ,
                        {shipping.pincode}
                    </H6>
                    <H6 fontSize={14}>
                    {shipping.phone}
                    </H6>
                </Typography>
            </Box>
        </Box>
    );

    const renderShopPickup = () => (

        <Box className="styled-card" width="100%" display="flex">
            <Box width="30%" order={1}>
                <Typography align="left">
                    <H6 fontSize={14} >
                        Pickup:
                    </H6>
                </Typography>
            </Box>
            <Box width="70%" order={1}>
                <Typography align="left">
                    <H6 fontSize={14}>
                        {getLocalDate(delivery_date, DATE_FORMAT)}
                    </H6>
                    <H6 fontSize={14}>
                        {getTimeSlot(delivery_time)}
                    </H6>
                </Typography>
            </Box>
        </Box>
    );

    const renderOrderTotal = () => (
        <Box>
            <Box display="flex">
                <Box width="80%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            Subtotal:
                        </H6>
                    </Typography>
                </Box>
                <Box width="20%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            {symbol}
                            {total_price}
                        </H6>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex">
                <Box width="80%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            Delivery:
                        </H6>
                    </Typography>
                </Box>
                <Box width="20%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            {symbol}
                            {total_shipping}
                        </H6>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex">
                <Box width="80%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            Taxes:
                        </H6>
                    </Typography>
                </Box>
                <Box width="20%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            {symbol}
                            {total_tax}
                        </H6>
                    </Typography>
                </Box>
            </Box>
            <hr className="solid" />
            <Box display="flex">
                <Box width="80%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            Total price:
                        </H6>
                    </Typography>
                </Box>
                <Box width="20%">
                    <Typography align="left">
                        <H6 fontSize={14} >
                            {symbol}
                            {total_order}
                        </H6>
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    const renderOrderDetail = () => (
        <Container>
            <Box display={window.innerWidth < 400 ? "" : "flex"}>
                <Box width={window.innerWidth < 400 ? "100%" : "50%"} className={window.innerWidth < 400 ? "mb-2" : "mr-2"}>
                    {(order_type === "delivery" && shipping) ? renderShippingAddress() : renderShopPickup()}
                </Box>
            </Box>
            <Box>
                {renderOrderItems()}
            </Box>
            <Box>
                {renderOrderTotal()}
            </Box>
        </Container>
    );


    const renderOrderStatus = () => (
        (status !== "new" && status !== "rejected")
            ? [getRadioButtonData(status).map((element) => (
                <Box className="textfield" m={2}>
                    <RadioButtonFieldComponent
                        required
                        options={inputOptions}
                        label={element.label}
                        value={element.value}
                        onChange={(event) => handleRadioButtonChange(event, element.onChangeField)}
                    />
                </Box>
            )),
                getButtonData(onFinish, onCancel, hasError, isTouched).map((element) => (
                    <ButtonComponent
                        sx={{
                            m: 2,
                        }}
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                    />
                ))]
            : [getButtonDataAccept(onFinishAccept).map((element) => (
                <ButtonComponent
                    type={element.type}
                    variant={element.variant}
                    onClick={element.onClick}
                    disabled={element.disabled}
                    name={element.name}
                />
            )),
                getButtonDataReject(onFinishReject).map((element) => (
                    <ButtonComponent
                        sx={{
                            m: 2,
                        }}
                        type={element.type}
                        variant={element.variant}
                        onClick={element.onClick}
                        disabled={element.disabled}
                        name={element.name}
                    />
                )),
                getTextfieldDataRejectMessage(message).map((element) => (
                    <Box className="textfield" m={2}>
                        <TextFieldComponent
                            required
                            label={element.label}
                            value={element.value}
                            error={!!getError(element.onChangeField)}
                            helperText={getError(element.onChangeField)}
                            onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
                        />
                    </Box>
                ))
            ]
    );

    return (
        <Container>
            <Container maxWidth="md">
                <Container className="header-color">
                    {renderOrderInformation()}
                </Container>
                <Box mb={2} mt={2}>
                    <ButtonComponent
                        type="primary"
                        variant="outlined"
                        name="< Back"
                        size="small"
                        onClick={onCancel}
                    />
                </Box>
                <Box mb={2}>
                    {(() => {
                        switch (status) {
                            case "pending":
                                return <H2 fontSize={16}>Status: Waiting for payment from customer</H2>;
                            case "rejected":
                                return <H2 fontSize={16}>Status: Rejected</H2>;
                            default:
                                return renderOrderStatus();
                        }
                    })()}
                </Box>
                {isLoading ? <MockProductList /> : renderOrderDetail() }
            </Container>
        </Container>
    );
};

export default SellerOrderEditor;
