import * as yup from "yup";

export const schema = yup.object().shape({
  is_tax_active: yup.bool().nullable().required(),
  taxidnumber: yup.string().nullable().when("is_tax_active", { is: true, then: yup.string().required().matches(/^[A-Za-z0-9]+$/, "Must be char or number") }),
  taxpercentage: yup.string().nullable().when("is_tax_active", { is: true, then: yup.string().required().matches(/^[0-9.]+$/, "Must be number") }),
});



export const getTextfieldData = (taxidnumber, taxpercentage) => [
  {
    label: "Tax ID Number:",
    value: taxidnumber,
    onChangeField: "taxidnumber",
  },
  {
    label: "Tax (%))",
    value: taxpercentage,
    onChangeField: "taxpercentage",
  },
];

export const getRadioButtonData = (is_tax_active) => [
  {
    label: "Tax (e.g. GSTN)",
    value: is_tax_active,
    onChangeField: "is_tax_active",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    vairant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    vairant: "outlined",
    type: "primary",
    disabled: false,
  },
];
