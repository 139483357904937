import * as yup from "yup";

export const schema = yup.object().shape({
  place_id: yup.string().required("Required"),
});

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Update",
    onClick: onFinish,
    type: "primary",
    variant: "contained",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    type: "primary",
    variant: "outlined",
    disabled: false,
  },
];

export const getRadioButtonData = (place_id) => [
  {
    label: "Verify",
    value: place_id,
    onChangeField: "place_id",
  },
];
