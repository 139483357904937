/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// import materialComponents
import {
  Card, Typography, Box, Container,
} from "@mui/material";
import MockProductList from "components/elements/main/MockProductList";
import * as Actions from "actions";
import {getLocalDate, isMarketPlace, getURI} from "helpers/utils";
import {CURRENCY, DATE_FORMAT} from "config/constants";
import ShopHeaderMini from "components/elements/user/shops/ShopHeaderMini";
import CustomSectionCreator from "components/CustomSectionCreator";
import ViewListIcon from "@mui/icons-material/ViewList";


const ShopOrderList = (props) => {
  const {
    isLoading, shoporderlist, symbol, history, bootupInfo, getShopOrderlistProducts, match
  } = props;

  const shopId = (isMarketPlace() ? match.params.shopId : bootupInfo.appState.subdomain_shopId);

  const getPrice = (currencyFormat, order) => {
    const price = order.total_price
    - (order.discount ? (order.total_price * (parseInt(order.discount, 10) / 100)) : 0);
    return (
      <Typography>
        {currencyFormat}
        {price}
        {order.discount && (
        <del>
          <span className="money">
            {currencyFormat}
            {order.total_price}
          </span>
        </del>
        )}
      </Typography>
    );
  };

  useEffect(() => {
    getShopOrderlistProducts(shopId, !isMarketPlace());
  }, []);

  if (isLoading) {
    return <MockProductList />;
  }

  if (!shoporderlist.length) {
    return (
      <section className="cart-section section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="col-sm-12 empty-cart-cls text-center">
                  <h3>
                    <strong>No order found</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  return (
    <div>
      <Container>
        {isMarketPlace() &&
            <ShopHeaderMini {...props} />}
        <CustomSectionCreator icon={<ViewListIcon />} title="Orders">
        </CustomSectionCreator>
        <Box alignContent="center" width="100%" display="flex" p={2}>
          <Box width="25%" order={1}>
            <Typography variant="h6" align="center">Order</Typography>
          </Box>
          <Box width="40%" order={3}>
            <Typography variant="h6" align="center">Date</Typography>
          </Box>
          <Box width="35%" order={4}>
            <Typography variant="h6" align="center">Status</Typography>
          </Box>
        </Box>
        {shoporderlist.map((order, index) => (
          <Card key={`${index + 1}`} className="m-2">

            <Box onClick={() => history.push(getURI(process.env.REACT_APP_TYPE, "shop_order", shopId, "", order._id))} alignContent="center" width="100%" display="flex" p={2}>
              <Box width="25%" order={1}>
                <Typography align="center">
                  <Link
                    className="styled-link"
                    to={getURI(process.env.REACT_APP_TYPE, "shop_order", shopId, "", order._id)}
                  >
                    {order.order_id}
                  </Link>
                </Typography>
              </Box>
              <Box width="40%" order={3}>
                <Typography align="center">
                  {getLocalDate(order.created_at, DATE_FORMAT)}
                </Typography>
              </Box>
              <Box width="35%" order={4}>
                <Typography align="center">
                  {order.status}
                </Typography>
              </Box>
            </Box>
          </Card>
        ))}
      </Container>
    </div>
  );
};

ShopOrderList.defaultProps = {
  isLoading: false,
  symbol: CURRENCY,
};

ShopOrderList.propTypes = {
  isLoading: PropTypes.bool,
  shoporderlist: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbol: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
  getShopOrderlistProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const shopOrderList = connect(
  ({ userorders, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userorders.reqStatus,
    isLoading: userorders.isLoading,
    serviceError: userorders.serviceError,
    shoporderlist: userorders.shoporderlist,
    symbol: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopOrderList);

export { shopOrderList as default };
