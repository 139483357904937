import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  //background-color: #000;
  background-color: ${(props) => (props.status === "done" ? "green" : props.updated === "updated" ? "blue" : "red")};
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-70%, -70%);
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  &:hover {
    z-index: 1;
  }
`;

const Marker = ({ text, onClick, status, updated }) => (
    <div>
  <Wrapper
    alt={text}
    onClick={onClick}
      status={status}
    updated={updated}
  /><div style={{ fontSize: 16, fontWeight: "bold" }}>{text}</div>
    </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default Marker;
