import * as React from "react";
import {
    Box, IconButton
} from "@mui/material";

import  { Edit, MoreHoriz } from '@mui/icons-material';
import {getImage, getPrice} from "helpers/utils";
import "./SellerProduct.scss";
import BazaarButton from "components/BazaarButton";
import { FlexBetween, FlexBox } from "components/flex-box";
import {H2, H4, H6} from "components/CustomTypography";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";


const SellerProduct = (props) => {
  const { product = {}, editShopImages, editProductInfo, editProductSettings, symbol, currency, className = "" } = props;
  const { id, name, categories, images, price, is_popular, is_price_visible, is_active, store_entry } = product;
  const galleryInput = images.map((image) => {
    const { id, cdn } = image;
    return {
      id,
      original: cdn.secure_url,
      thumbnail: cdn.secure_url,
    };
  });

  return (
      <Box pb={2} >
          <HoverBox onClick={editShopImages} m={0} mx="auto" borderRadius="2px">
              <LazyImage
                  src={getImage(product.images)}
                  width={150}
                  height={150}
                  alt={product.name}
                  mx="auto"
              />
          </HoverBox>
          <FlexBetween onClick={editProductSettings} m={0} p={0}>
              <Box width="80%" noWrap sx={{ overflow: 'hidden' }}>
                  <H2 fontSize={14} textAlign="left" ellipsis>
                      {getPrice(product, currency)}
                  </H2>
              </Box>
              <Box width="20%">
                  <MoreHoriz color="primary" fontSize="small"/>
              </Box>
          </FlexBetween>
          <FlexBetween onClick={editProductInfo} m={0} p={0}>
              <Box width="80%" noWrap sx={{ overflow: 'hidden' }}>
                  <H4 textTransform="uppercase" fontSize={12} textAlign="left" ellipsis>
                      {product.name}
                  </H4>
              </Box>
              <Box width="20%">
                  <Edit color="primary" fontSize="small" />
              </Box>
          </FlexBetween>
      </Box>
  );
};

export default SellerProduct;
