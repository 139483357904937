// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "../../api";

import * as ActionTypes from "constants/ActionTypes";
import * as Actions from "../../actions";
import querystring from "querystring";

function* usergetLatestOffers(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "" });

    const query = (searchQuery) ? "?"+searchQuery : "" ;

    const response = yield API.get(
        `/users/offers/get-offers`  + query
    );
    yield put(Actions.usergetLatestOffersSuccess(response.response));
  } catch (error) {
    yield put(Actions.usergetLatestOffersFailure(error));
  }
}

function* usergetOfferDetail(action: Object) {
  try {

    const searchQuery = querystring.stringify({ shop: action.shopId, isDomain: action.isShopDomain ? "1" : "" });

    const query = (searchQuery) ? "?"+searchQuery : "" ;


    const response = yield API.get(
        `/users/offers/get-offer/` + action.payload + query
    );
    yield put(Actions.usergetOfferDetailSuccess(response.response));
  } catch (error) {
    yield put(Actions.usergetOfferDetailFailure(error));
  }
}


export default function* ProductsSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.USER_GET_LATEST_OFFERS_START,  usergetLatestOffers);
  yield takeLatest(ActionTypes.USER_GET_OFFER_DETAIL_START,  usergetOfferDetail);
}
