import React, { useState } from "react";
import { Link } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from "components/Atoms";

import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import HomeIcon from "@mui/icons-material/Home";
import StoreIcon from "@mui/icons-material/Store";
import StorageIcon from "@mui/icons-material/Storage";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";


const useStyles = makeStyles((theme) => ({
  fullList: {
    width: 300,
  },
}));

const drawerWidth = 300;

const SidebarSeller = (props) => {
  const classes = useStyles();

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = (event) => {
    if (
      event
        && event.type === "keydown"
        && (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(true);
  };

  const constructSidebarMenu = () => {
    const { classes, onClose } = props;
    return (
      <div
        className="p-4"
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <List>
            <ListItem className="p-2 mr-2" button component={Link} to="/" key="home">
              <ListItemIcon><HomeIcon /></ListItemIcon><ListItemText primary="Home" />
            </ListItem>
          <ListItem className="p-2 mr-2" button component={Link} to="/shops" key="shops">
            <ListItemIcon><StoreIcon /></ListItemIcon><ListItemText primary="Shops" />
          </ListItem>
          <ListItem className="p-2 mr-2" button component={Link} to="/products" key="products">
            <ListItemIcon><StorageIcon /></ListItemIcon><ListItemText primary="Products" />
          </ListItem>
          <ListItem className="p-2 mr-2" button component={Link} to="/orders/all" key="orders">
            <ListItemIcon><ShoppingCartRoundedIcon /></ListItemIcon><ListItemText primary="Orders" />
          </ListItem>
          <ListItem className="p-2 mr-2" button component={Link} to="/me" key="account">
            <ListItemIcon><PersonRoundedIcon /></ListItemIcon><ListItemText primary="Account" />
          </ListItem>
        </List>
      </div>
    );
  };

  const {
    open, onOpen, onClose,
  } = props;

  return (
    <SwipeableDrawer
      width={drawerWidth}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      {constructSidebarMenu()}
    </SwipeableDrawer>
  );
};

export default SidebarSeller;
