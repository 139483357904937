/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "actions";
import UserOfferSearch from "components/widgets/user/UserOfferSearch";
import "components/elements/main/index.scss";
import { checkUserAllowed } from "helpers/utils.js";


const UserOffersPageSearch = (props) => {

  const [state] = useState({
    layoutColumns: 3,
  });


    useEffect(() => {
    props.userMainResetSearchShops();
    document.getElementById("color").setAttribute("href", "#");
  }, []);

    return (
        <div>
            <UserOfferSearch {...props} colSize={state.layoutColumns} />
        </div>
    );

};

UserOffersPageSearch.propTypes = {
    userMainResetSearchShops: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
};

// Mapping Props and State.
const userOffersPageSearch = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
      userDetails: bootupInfo.userDetails,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOffersPageSearch);

export { userOffersPageSearch as default };
