import * as React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {
    Notification, Pace,
} from "components/Atoms";

import * as Actions         from "actions";

import {Box, Container} from "@mui/material";
import { H2 } from "components/CustomTypography";

import {useEffect} from "react";
import PhotoUpload from "components/PhotoUpload";

const SellerOffersImageMain = (props) => {

    const {
        serviceError, reqStatus,
        isLoading, offer, cancelEdit,
        sellerAddOfferImage, sellerRemoveOfferImage,
        offerImageList
    } = props;

    const {id, name, images} = offer;

    const sendNotification = (type, { title, description = "" }) => {
        Notification[type]({
            message: title,
            description,
        });
    };

    useEffect(() => {
        if (serviceError && reqStatus) {
            const feedback = {
                title: "Something went wrong!",
                description: serviceError.statusText,
            };
            sendNotification("error", feedback);
        }
        offer.images = offerImageList.images;
    }, [serviceError, reqStatus]);


    const addImageCallback = (file) => {
        const formData = new FormData();
        formData.append("image", file);
        sellerAddOfferImage(offer.id, formData);
    }

    const filter = (id) => {
        return images.filter(image => image.cdn.public_id !== id)
    }

    const removeImageCallback = (id) => {
        offer.images = offer.images.filter(image => image.cdn.public_id !== id);
        sellerRemoveOfferImage(offer.id, id);
    }

    return (
        <Container>
            {isLoading && <Pace color="#27ae60" height="2px" progressbar="2px" />}
            <Box >
                <H2 fontSize={16} ellipsis>{name}</H2>
                <PhotoUpload isRemoveAllowed={true}
                             images={images}
                             addImageCallback={addImageCallback}
                             removeImageCallback={removeImageCallback}
                             cancelEdit={cancelEdit}
                />
            </Box>
        </Container>
    );

};

// Mapping Props and State.
const sellerOffersImageMain = connect(
    ({ offers, bootupInfo }) => ({
      bootupInfo: bootupInfo,
      reqStatus: offers.reqStatus,
      isLoading: offers.isLoading,
      serviceError: offers.serviceError,
        offerImageList: offers.offerImageList,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(SellerOffersImageMain);

export { sellerOffersImageMain as default };
