/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as Actions from "actions";
import {
  Card, IconButton, Box, Typography, Button,
} from "@mui/material";
import {checkUserAllowed, getImage, getLocalDate} from "helpers/utils";

import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import "./AdminSellerProduct.scss";
import HoverBox from "components/HoverBox";
import LazyImage from "components/LazyImage";
import {H2, H4, H6} from "components/CustomTypography";
import {DATE_TIME_FORMAT_SHORT} from "config/constants";


const AdminSellerProduct = (props) => {

    const history = useHistory();

    const handlePage = (page) => (e) => {
        history.push(page);
    };

    const {
    product, editProductStatus, editProductSetting, userDetails,
  } = props;

    const handleProductsButtonClick = (product) => {
        history.push(`/products/${product.id}`);
    };

  return (
      <Box mb={2}>
          <HoverBox  mb={0} mx="auto" borderRadius="2px" onClick={() => handleProductsButtonClick(product)}>
              <LazyImage
                  src={getImage(product.images)}
                  width={150}
                  height={150}
                  alt={product.name}
                  mx="auto"
              />
          </HoverBox>
          <H2 sx={{ textTransform: "capitalize" }} fontSize={14} textAlign="left" title={product.name} ellipsis>
                  {product.name}
          </H2>
          <H6 textTransform="uppercase" fontSize={10} textAlign="left">
              {product.title}
          </H6>
          <H4 textAlign="left">
              {checkUserAllowed(userDetails.userlevel, "sendproductmsg") &&
                  <IconButton color="primary" size="small" onClick={editProductStatus}>
                      <MoreHorizIcon/>
                  </IconButton>
              }
              {checkUserAllowed(userDetails.userlevel, "approveproduct") &&
                  <IconButton color="primary" size="small" onClick={editProductSetting}>
                      <EditIcon/>
                  </IconButton>
              }
          </H4>
          <H6 fontSize={10} textAlign="left">
              (Likes: {product.likes})&nbsp;
              {!product.is_active && "Inactive"} &nbsp;
              {!product.is_listing_ready && "NotReady"}
          </H6>
          <H6 fontSize={10} textAlign="left" ellipsis>
              {getLocalDate(product.created_at, DATE_TIME_FORMAT_SHORT)}
          </H6>
      </Box>

  );
};


export default AdminSellerProduct;
