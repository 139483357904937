/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import SellerHome from "components/pages/seller/home/SellerHome";

import SearchOrderList from "components/pages/seller/orders/SearchOrderList";
import OrdersMain from "components/pages/seller/orders/OrdersMain";

import PrivateProducts from "components/pages/seller/products/PrivateProducts";
import PrivateCategories from "components/pages/seller/products/PrivateCategories";
import PrivateProductsByCategory from "components/pages/seller/products/PrivateProductsByCategory";
import BrandedProductsMain from "components/pages/seller/products/BrandedProductsMain";
import BrandedProductCatalog from "components/pages/seller/products/BrandedProductCatalog";
import BrandedProductByCategory from "components/pages/seller/products/BrandedProductByCategory";
import BrandedBarCodeScannerList from "components/pages/seller/products/BrandedBarCodeScannerList";
import SearchProductList from "components/pages/seller/products/SearchProductList";

import StoreHome from "components/pages/seller/register-store/Home";
import ClaimStore from "components/pages/seller/register-store/ClaimStore";

import StoreProfileHome from "components/pages/seller/store/StoreProfileHome";
import StoreProfileName from "components/pages/seller/store/StoreProfileName";
import StoreProfileLogo from "components/pages/seller/store/StoreProfileLogo";
import StoreProfileAddress from "components/pages/seller/store/StoreProfileAddress";
import StoreProfileSocial from "components/pages/seller/store/StoreProfileSocial";
import StoreProfileDomain from "components/pages/seller/store/StoreProfileDomain";
import StoreProfileImages from "components/pages/seller/store/StoreProfileImages";
import StoreProfileService from "components/pages/seller/store/StoreProfileService";
import StoreProfilePayment from "components/pages/seller/store/StoreProfilePayment";
import StorePaymentQRCode from "components/pages/seller/store/StorePaymentQRCode";
import StoreProfileTax from "components/pages/seller/store/StoreProfileTax";

import SellerOffersMain from "components/pages/seller/offers-coupons/SellerOffersMain";
import StoreProfileReturns from "components/pages/seller/store/StoreProfileReturns";

import SubscriptionHome from "components/pages/seller/subscriptions/Home";
import SubscriptionStore from "components/pages/seller/subscriptions/SubscriptionStore";
import SubscriptionCheckout from "components/pages/seller/subscriptions/SubscriptionCheckout";
import SubscriptionPaymentCheckout from "components/pages/seller/subscriptions/SubscriptionCheckout/payment-page";
import SubscriptionOrderSuccess from "components/pages/seller/subscriptions/SubscriptionCheckout/success-page";

export const SellerAppRoutes = [
  { path: "/", component: SellerHome, exact: true },
  // Orders
  { path: "/orders", component: OrdersMain, exact: true },
  { path: "/orders/search", component: SearchOrderList, exact: true },
  // Products
  { path: "/products/all", component: BrandedProductsMain, exact: true },
  { path: "/products/private", component: PrivateProducts, exact: true },
  { path: "/products/categories", component: PrivateCategories, exact: true },
  { path: "/products/category/:categoryId", component: PrivateProductsByCategory, exact: true },
  { path: "/products/scan", component: BrandedBarCodeScannerList, exact: true },
  { path: "/products/search", component: SearchProductList, exact: true },
  { path: "/brandedproducts/catalog", component: BrandedProductCatalog, exact: true },
  { path: "/brandedproducts/category/:categoryId", component: BrandedProductByCategory, exact: true },
  { path: "/brandedproducts/:storeId/category/:categoryId", component: BrandedProductByCategory, exact: true },
  // Register
  { path: "/register-store", component: StoreHome, exact: true },
  { path: "/register-store/claim/:storeId", component: ClaimStore, exact: true },
  // Shop profile
  { path: "/shop", component: StoreProfileHome, exact: true },
  { path: "/shop/logo", component: StoreProfileLogo, exact: true },
  { path: "/shop/profile", component: StoreProfileName, exact: true },
  { path: "/shop/address", component: StoreProfileAddress, exact: true },
  { path: "/shop/social", component: StoreProfileSocial, exact: true },
  { path: "/shop/domain", component: StoreProfileDomain, exact: true },
  { path: "/shop/photos", component: StoreProfileImages, exact: true },
  { path: "/shop/service", component: StoreProfileService, exact: true },
  { path: "/shop/payment", component: StoreProfilePayment, exact: true },
  { path: "/shop/qrcode", component: StorePaymentQRCode, exact: true },
  { path: "/shop/tax", component: StoreProfileTax, exact: true },
  { path: "/shop/offers", component: SellerOffersMain, exact: true },
  { path: "/shop/returns", component: StoreProfileReturns, exact: true },

  // Subscription
  { path: "/subscriptions", component: SubscriptionHome, exact: true },
  { path: "/subscriptions/store", component: SubscriptionStore, exact: true },
  { path: "/subscriptions/checkout", component: SubscriptionCheckout, exact: true },
  { path: "/subscriptions/payment-page", component: SubscriptionPaymentCheckout, exact: true },
  { path: "/subscriptions/order-success", component: SubscriptionOrderSuccess, exact: true },
];
