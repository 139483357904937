import * as yup from "yup";

export const schema = yup.object().shape({
  returnsoption: yup.string().nullable().required(),
  alloweddays: yup.string().nullable().when("returnsoption", { is: "exchange" || "fullrefund", then: yup.string().required().matches(/^[0-9]+$/, "Must be number") }),
});

export const getTextfieldData = (alloweddays) => [
  {
    label: "Days",
    value: alloweddays,
    onChangeField: "alloweddays",
  },
];

export const getRadioButtonData = (returnsoption) => [
  {
    label: "Returns",
    value: returnsoption,
    onChangeField: "returnsoption",
  },
];

export const getButtonData = (onFinish, cancelEdit, hasError, isTouched) => [
  {
    name: "Save",
    onClick: onFinish,
    vairant: "contained",
    type: "primary",
    disabled: hasError() || !isTouched(),
  },
  {
    name: "Cancel",
    onClick: cancelEdit,
    vairant: "outlined",
    type: "primary",
    disabled: false,
  },
];
