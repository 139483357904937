import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";


import "./style.scss";

import {Slider6} from "services/script";
import Slider from "react-slick/lib";

class AdminSalesLeadSelector extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const {
        salesleadstatus,
        onStatusSelect
    } = this.props;

    return (
          <Box>
            <Box pb={2}>
            <Slider {...Slider6} className="product-4 product-m arrow">
              <span style={{ paddingRight: "5px" }}>
              {!salesleadstatus ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("")}>All</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("")}>All</Button>
              }
              </span>
                <span style={{ paddingRight: "5px" }}>
                {salesleadstatus === "tryagain" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("tryagain")} >No owner, Visit Again</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("tryagain")} >No owner, Visit Again</Button>
                }
                </span>

                <span style={{ paddingRight: "5px" }}>
                {salesleadstatus === "visit" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("visit")} >Interested, Visit Again</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("visit")} >Interested, Visit Again</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {salesleadstatus === "later" ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("later")} >Maybe Later</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("later")} >Maybe Later</Button>
              }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {salesleadstatus === "signed" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("signed")}>Signed</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("signed")}>Signed</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {salesleadstatus === "no" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("no")}>Not Interested</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("no")}>Not Interested</Button>
                }
                </span>
            </Slider>
            </Box>
          </Box>
    );
  }
}

export default AdminSalesLeadSelector;
