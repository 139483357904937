/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import * as Actions from "actions";

// Import Swiper styles
import 'swiper/swiper.min.css';
import './UserOffersLatestSlider.scss';

// import Custom Components
import UserOfferBoxItem from "components/elements/user/offers/UserOfferBoxItem";
import MockProductBox from "components/elements/main/MockProductBox";
import CustomSectionCreator from "components/CustomSectionCreator";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const UserOffersLatestSlider = (props) => {
  const {
      offers, userMainGetLatestOffers, isLoading, history, marketId,
  } = props;

  useEffect(() => {
      userMainGetLatestOffers(marketId);
  }, []);

    const breakpoints = {
        300: {
            slidesPerView: 2.0,
            spaceBetween: 20,
        },
        340: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        380: {
            slidesPerView: 2.4,
            spaceBetween: 20,
        },
        420: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 2.8,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    const renderResults = () => (

    <Container>
        {<CustomSectionCreator title="Latest Offers" seeMoreLink="/offers/sort/new" seeMoreLinkTitle="View more" />}
        <swiper-container slides-per-view="1.8" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
                { offers.map((offer, index) => (
                    <swiper-slide key={index}>
                        <Box sx={{ width: 150 }}>
                        <UserOfferBoxItem
                            offer={offer}
                            history={history}
                        />
                        </Box>
                    </swiper-slide>
                ))}
            </swiper-container>
    </Container>
    );

  return (

      <>
          {isLoading ? <MockProductBox />
              : offers.length > 0
                  ? renderResults()
                  : ""}

      </>


  );
};

UserOffersLatestSlider.defaultProps = {
  symbol: "$",
};

UserOffersLatestSlider.propTypes = {
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
  symbol: PropTypes.string,
    userMainGetLatestOffers: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userOffersLatestSlider = connect(
  ({ userMainOffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: userMainOffers.reqStatus,
    isLoading: userMainOffers.isLoading,
    serviceError: userMainOffers.serviceError,
    offers: userMainOffers.latestofferlist,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(UserOffersLatestSlider);

export default userOffersLatestSlider;
