import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const userMainResetWishlist = () => ({
    type: ActionTypes.USER_MAIN_WISHLIST_RESET
});

export const userMainGetWishlistProducts = (marketId) => ({
    type: ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_START,
    marketId: marketId,
});

export const userMainGetWishlistProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const userMainGetWishlistProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_WISHLIST_PRODUCTS_FAILURE,
    payload: error,
});


export const userMainAddProductToWishlist = (prodId) => ({
    type: ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_START,
    prodId: prodId,
});

export const userMainAddProductToWishlistSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const userMainAddProductToWishlistFailure = (error) => ({
    type: ActionTypes.USER_MAIN_ADD_PRODUCT_TO_WISHLIST_FAILURE,
    payload: error,
});

export const userMainRemoveProductFromWishlist = (reqData) => ({
    type: ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_START,
    payload: reqData,
});

export const userMainRemoveProductFromWishlistSuccess = (data, productId) => {
    return {
        type: ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_SUCCESS,
        payload: data,
        productId: productId
    };
};
export const userMainRemoveProductFromWishlistFailure = (error) => ({
    type: ActionTypes.USER_MAIN_REMOVE_PRODUCT_FROM_WISHLIST_FAILURE,
    payload: error,
});


