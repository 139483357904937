// @flow
import { call, put, takeLatest, select } from "redux-saga/effects";
import * as _ from "lodash";
import API from "api";

import * as ActionTypes from 'constants/ActionTypes';
import * as Actions     from "actions";
import {getGeoLocation, getGeoLocationDue} from "helpers/utils";


function* login(action: Object) {
  try {
    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/login`;
        break;
      case "ADMINAPP":
        url = `/admin/login`;
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/login`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.loginSuccess(response.response));
  } catch (error) {
    yield put(Actions.loginFailure(error));
  }
}

function* loginPreOTP(action: Object) {
  try {
    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/preotp_login`;
        break;
      case "ADMINAPP":
        url = `/admin/preotp_login`;
        break;
        case "USERAPP":
        case "MARKETPLACE":
        case "LOCALMARKET":
      default:
        url = `/users/preotp_login`;
        break;
    }

    const response = yield API.post(
        url,
        action.payload
    );
    yield put(Actions.loginPreOTPSuccess(response));
  } catch (error) {
    yield put(Actions.loginPreOTPFailure(error));
  }
}

function* loginPostOTP(action: Object) {
  try {
    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/postotp_login`;
        break;
      case "ADMINAPP":
        url = `/admin/postotp_login`;
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/postotp_login`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.loginPostOTPSuccess(response.response));
  } catch (error) {
    yield put(Actions.loginPostOTPFailure(error));
  }
}

function* Sociallogin(action: Object) {
  try {

    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/login/success`;
        break;
      case "ADMINAPP":
        url = `/admin/login/success`;
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/login/success`;
        break;
    }

    // Needs cookie from Google to request JWT
    const response = yield API.get(url, {
      withCredentials: true
    },  action.payload );
    yield put(Actions.SocialloginSuccess(response.response));
  } catch (error) {
    yield put(Actions.SocialloginFailure(error));
  }
}

function* deleteAccount(action: Object) {
  try {

    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        //url = `/sellers/delete`;
        break;
      case "ADMINAPP":
        //url = `/admin/delete`;
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/delete`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.deleteAccountSuccess(response));
  } catch (error) {
    yield put(Actions.deleteAccountFailure(error));
  }
}

function* logout(action: Object) {
  try {

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        yield put(Actions.sellerResetStore());
        yield put(Actions.sellerResetProducts());
        yield put(Actions.sellerResetOrders());
        break;
      case "ADMINAPP":

        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        yield put(Actions.userMainResetStores());
        yield put(Actions.userResetOrders());
        yield put(Actions.userResetProducts());
        //yield put(Actions.userResetCartWishlist());
        break;
    }

    const response = yield API.post(`/auth/logout`, action.payload);
    yield put(Actions.logoutSuccess(response));
  } catch (error) {
    yield put(Actions.logoutFailure(error));
  }
}

function* resetPassword(action: Object) {
  try {

    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/forgot-password`;
        break;
      case "ADMINAPP":
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/forgot-password`;
        break;
    }

    const response = yield API.post(url, action.payload);

    yield put(Actions.resetPasswordSuccess(response));
  } catch (error) {
    yield put(Actions.resetPasswordFailure(error));
  }
}

function* register(action: Object) {
  try {

    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        url = `/sellers/register`;
        break;
      case "ADMINAPP":
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/register`;
        break;
    }

    const response = yield API.post(url, action.payload);
    yield put(Actions.registerSuccess(response));
  } catch (error) {
    yield put(Actions.registerFailure(error));
  }
}

function* getUserProfile(action: Object) {
  try {

    let url;

    switch (process.env.REACT_APP_TYPE) {
      case "SELLERAPP":
        // Seller always gets use and shop profile.
          // This is called under profile for all users so switch to shop profile
        url = `/sellers/account/get-shop-profile`;
        break;
      case "ADMINAPP":
        url = `/admin/account/get-profile`;
        break;
      case "USERAPP":
      case "MARKETPLACE":
      case "LOCALMARKET":
      default:
        url = `/users/account/get-profile`;

        break;
    }

    const response = yield API.get(url, action.payload);

    yield put(Actions.getUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getUserProfileFailure(error));
  }
}

function* updateUserUUID(action: Object) {
  try {
    let query = {};

    const Firebase = window.FirebasePlugin;

//    if (Firebase) {
      const usergetUserToken = () =>
          new Promise((resolve, reject) => {
            window.FirebasePlugin.getToken(function(token) {
              resolve(token);
            }, function(error) {
              console.log('Failed to get FCM token', error);
            });

          });

      const token = yield call(usergetUserToken);

 //   }

    const update = yield API.post(`/users/update-uuid`, { token: token });

    yield put(Actions.updateUserUUIDSuccess(update));
  } catch (error) {
    yield put(Actions.updateUserUUIDFailure(error));
  }
}

function* updateUserLocation(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        updateUserLocation(response.coords);
        coordinates = {lat: latitude, lng: longitude};
      } else {
        coordinates = getGeoLocation();
      }
    }
  } catch (error) {
    // try if there is an earlier location
    coordinates = getGeoLocation();
  }

  try {


    const update = yield API.post(`/users/update-location`, coordinates);

    yield put(Actions.updateUserLocationSuccess(update));
  } catch (error) {
    yield put(Actions.updateUserLocationFailure(error));
  }
}

function* updateAdminLocation(action: Object) {

  let coordinates;

  try {
    if (navigator.geolocation) {
      if (getGeoLocationDue()) {
        const usergetUserGeoLocation = () =>
            new Promise((resolve, reject) => {
              var options = {
                timeout: 5000,
                maximumAge: 0,
                enableHighAccuracy: true
              };
              navigator.geolocation.getCurrentPosition(
                  (location) => resolve(location),
                  (error) => reject(error),
                  options
              );
            });

        const response = yield call(usergetUserGeoLocation);
        const {latitude, longitude} = response.coords;

        updateUserLocation(response.coords);
        coordinates = {lat: latitude, lng: longitude};
      } else {
        coordinates = getGeoLocation();
      }
    }
  } catch (error) {
    // try if there is an earlier location
    coordinates = getGeoLocation();
  }

  try {


    const update = yield API.post(`/admin/update-location`, coordinates);

    yield put(Actions.updateAdminLocationSuccess(update));
  } catch (error) {
    yield put(Actions.updateAdminLocationFailure(error));
  }
}

function* getShopUserProfile(action: Object) {
  try {
    const response = yield API.get(
        `/sellers/account/get-shop-profile`,
        action.payload
    );

    yield put(Actions.getUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getUserProfileFailure(error));
  }
}

function* getShopAndUserProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shop-user-profile?shop=`+ searchQuery
    );

    yield put(Actions.getShopAndUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getShopAndUserProfileFailure(error));
  }
}

function* getShopProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-shopinfo?shop=`+ searchQuery
    );

    yield put(Actions.getShopProfileSuccess(response));
  } catch (error) {
    yield put(Actions.getShopProfileFailure(error));
  }
}

function* getMarketAndUserProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-market-user-profile?market=`+ searchQuery
    );

    yield put(Actions.getMarketAndUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.getMarketAndUserProfileFailure(error));
  }
}

function* getMarketProfile(action: Object) {
  try {
    const searchQuery = action.payload + ((action.isShopDomain) ? "&isDomain=1" : "");
    const response = yield API.get(
        `/users/shops/get-marketinfo?market=`+ searchQuery
    );

    yield put(Actions.getMarketProfileSuccess(response));
  } catch (error) {
    yield put(Actions.getMarketProfileFailure(error));
  }
}


function* updateUserProfile(action: Object) {
  try {
    const response = yield API.post(
      `/users/account/update-profile`,
      action.payload
    );

    yield put(Actions.updateUserProfileSuccess(response.response));
  } catch (error) {
    yield put(Actions.updateUserProfileFailure(error));
  }
}

function* getBillingAddress(action: Object) {
  try {
    const response = yield API.get(
      `/sellers/account/get-billing-address`,
      action.payload
    );

    yield put(Actions.getBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.getBillingAddressFailure(error));
  }
}
function* updateBillingAddress(action: Object) {
  try {
    const response = yield API.put(
        `/sellers/account/update-billing-address`,
        action.payload
    );

    yield put(Actions.updateBillingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.updateBillingAddressFailure(error));
  }
}

function* addShippingAddress(action: Object) {
  try {
    const response = yield API.post(
        `/users/account/add-shipping-address`,
        action.payload
    );

    yield put(Actions.addShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.addShippingAddressFailure(error));
  }
}

function* getShippingAddress(action: Object) {
  try {
    const response = yield API.get(
        `/users/account/get-shipping-address`,
        action.payload
    );

    yield put(Actions.getShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.getShippingAddressFailure(error));
  }
}

function* updateShippingAddress(action: Object) {
  try {
    const response = yield API.put(
        `/users/account/update-shipping-address`,
        action.payload
    );

    yield put(Actions.updateShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.updateShippingAddressFailure(error));
  }
}

function* deleteShippingAddress(action: Object) {
  try {
    const response = yield API.post(
        `/users/account/remove-shipping-address`,
        action.payload
    );

    yield put(Actions.deleteShippingAddressSuccess(response));
  } catch (error) {
    yield put(Actions.deleteShippingAddressFailure(error));
  }
}

function* updateUserPic(action: Object) {
  try {
    const response = yield API.post(
        `/sellers/account/update-profile-pic`,
        action.payload
    );

    yield put(Actions.updateUserPicSuccess(response));
  } catch (error) {
    yield put(Actions.updateUserPicFailure(error));
  }
}

function* getCities(action: Object) {
  try {
    const response = yield API.get(
        `/users/cities`,
        action.payload
    );

    yield put(Actions.getCitiesSuccess(response));
  } catch (error) {
    yield put(Actions.getCitiesFailure(error));
  }
}

function* getCityLocations(action: Object) {
  try {
    const response = yield API.get(
        `/users/cities/area/` + action.cityid
    );

    yield put(Actions.getCityLocationsSuccess(response));
  } catch (error) {
    yield put(Actions.getCityLocationsFailure(error));
  }
}

function* getAddressSuggestions(action: Object) {
  try {

    const response = yield API.post(
        `/users/account/get-address-suggestion`,
        action.payload
    );

    yield put(Actions.getAddressSuggestionsSuccess(response));
  } catch (error) {
    yield put(Actions.getAddressSuggestionsFailure(error));
  }
}

function* createPaymentOrder(action: Object) {
  try {

    const response = yield API.post(
        `/payments/order`,
        action.payload
    );

    yield put(Actions.createPaymentOrderSuccess(response));
  } catch (error) {
    yield put(Actions.createPaymentOrderFailure(error));
  }
}

function* checkPaymentVerifyOrder(action: Object) {
  try {

    const response = yield API.post(
        `/payments/verify`,
        action.payload
    );

    yield put(Actions.checkPaymentVerifyOrderSuccess(response));
  } catch (error) {
    yield put(Actions.checkPaymentVerifyOrderFailure(error));
  }
}

function* addAppErrorLog(action: Object) {
  try {
    const response = yield API.post(
        `/users/error`,
        action.payload
    );

    yield put(Actions.addAppErrorLogSuccess(response));
  } catch (error) {
    yield put(Actions.addAppErrorLogFailure(error));
  }
}

export default function* AppBaseSaga(): Generator<*, *, *> {
  yield takeLatest(ActionTypes.LOGIN_START, login);
  yield takeLatest(ActionTypes.LOGIN_PRE_OTP_START, loginPreOTP);
  yield takeLatest(ActionTypes.LOGIN_POST_OTP_START, loginPostOTP);
  yield takeLatest(ActionTypes.SOCIAL_LOGIN_START, Sociallogin);
  yield takeLatest(ActionTypes.LOGOUT_START, logout);
  yield takeLatest(ActionTypes.REGISTER_START, register);
  yield takeLatest(ActionTypes.DELETE_ACCOUNT_START, deleteAccount);
  yield takeLatest(ActionTypes.RESET_PASSWORD_START, resetPassword);
  yield takeLatest(ActionTypes.GET_USER_PROFILE_START, getUserProfile);
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_UUID_START, updateUserUUID);
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_LOCATION_START, updateUserLocation);
  yield takeLatest(ActionTypes.UPDATE_ADMIN_PROFILE_LOCATION_START, updateAdminLocation);
  yield takeLatest(ActionTypes.GET_SHOP_USER_PROFILE_START, getShopUserProfile);
  yield takeLatest(ActionTypes.GET_SHOP_AND_USER_PROFILE_START, getShopAndUserProfile);
  yield takeLatest(ActionTypes.GET_SHOP_PROFILE_START, getShopProfile);
  yield takeLatest(ActionTypes.GET_MARKET_AND_USER_PROFILE_START, getMarketAndUserProfile);
  yield takeLatest(ActionTypes.GET_MARKET_PROFILE_START, getMarketProfile);
  yield takeLatest(ActionTypes.UPDATE_USER_PROFILE_START, updateUserProfile);
  yield takeLatest(ActionTypes.GET_BILLING_ADDRESS_START, getBillingAddress);
  yield takeLatest(ActionTypes.UPDATE_BILLING_ADDRESS_START, updateBillingAddress);
  yield takeLatest(ActionTypes.ADD_SHIPPING_ADDRESS_START, addShippingAddress);
  yield takeLatest(ActionTypes.GET_SHIPPING_ADDRESS_START, getShippingAddress);
  yield takeLatest(ActionTypes.UPDATE_SHIPPING_ADDRESS_START, updateShippingAddress);
  yield takeLatest(ActionTypes.DELETE_SHIPPING_ADDRESS_START, deleteShippingAddress);
  yield takeLatest(ActionTypes.UPDATE_USER_PIC_START, updateUserPic);
  yield takeLatest(ActionTypes.GET_CITIES_START, getCities);
  yield takeLatest(ActionTypes.GET_CITY_LOCATIONS_START, getCityLocations);
  yield takeLatest(ActionTypes.GET_ADDRESS_SUGGESTIONS_START, getAddressSuggestions);
  yield takeLatest(ActionTypes.COMMON_CREATE_PAYMENT_ORDER_START, createPaymentOrder);
  yield takeLatest(ActionTypes.COMMON_CHECK_PAYMENT_VERIFY_START, checkPaymentVerifyOrder);
  yield takeLatest(ActionTypes.ADD_APP_ERROR_LOG_START, addAppErrorLog);
}
