import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Span } from 'components/CustomTypography';


import "./style.scss";

import {Slider6} from "services/script";
import Slider from "react-slick/lib";

class SellerOrderSelector extends React.PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const {
      handlePage,
        orderstatus,
        ordertype,
        onTypeSelect,
        onStatusSelect
    } = this.props;

    return (
          <Box>
            <Box pb={2}>
            <Slider {...Slider6} className="product-4 product-m arrow">
              <span style={{ paddingRight: "5px" }}>
              {!orderstatus ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("")}>All</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("")}>All</Button>
              }
              </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "new" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("new")} /* onClick={handlePage("/orders/new")} */ >New</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("new")} /* onClick={handlePage("/orders/new")} */ >New</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "accepted" ?
                  <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("accepted")} /* onClick={handlePage("/orders/accepted")} */>Accepted</Button>
                  :
                  <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("accepted")} /* onClick={handlePage("/orders/accepted")} */>Accepted</Button>
              }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "inprogress" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("inprogress")}>In progress</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("inprogress")}>In progress</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "delivered" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("delivered")}>Delivered</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("delivered")}>Delivered</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "picked" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("picked")}>Picked</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("picked")}>Picked</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "pickupready" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("readypickup")}>Ready (Pickup)</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("readypickup")}>Ready (Pickup)</Button>
                }
                </span>
                <span style={{ paddingRight: "5px" }}>
                  {orderstatus === "readydelivery" ?
                      <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("readydelivery")}>Ready (Delivery)</Button>
                      :
                      <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("readydelivery")}>Ready (Delivery)</Button>
                  }
                </span>
                <span style={{ paddingRight: "5px" }}>
                {orderstatus === "rejected" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onStatusSelect("rejected")}>Rejected</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onStatusSelect("rejected")}>Rejected</Button>
                }
                </span>
            </Slider>
            </Box>
            <Box> <Span>Order Type: </Span>
                <span style={{ paddingRight: "5px" }}>
          {ordertype === "delivery" ?
              <Button color="primary" size="medium" variant="contained" onClick={() => onTypeSelect("delivery")}>Delivery</Button>
              :
              <Button color="secondary" size="medium" variant="outlined" onClick={() => onTypeSelect("delivery")}>Delivery</Button>
          }
                </span>
                {ordertype === "pickup" ?
                    <Button color="primary" size="medium" variant="contained" onClick={() => onTypeSelect("pickup")}>Pickup</Button>
                    :
                    <Button color="secondary" size="medium" variant="outlined" onClick={() => onTypeSelect("pickup")}>Pickup</Button>
                }

            </Box>
          </Box>
    );
  }
}

export default SellerOrderSelector;
