/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Container, Box } from "@mui/material";
import * as Actions from "actions";

// Import Swiper styles
import 'swiper/swiper.min.css';
//import 'swiper/components/navigation/navigation.min.css';

import UserOfferBoxItem from "components/elements/user/offers/UserOfferBoxItem";

import { CURRENCY } from "config/constants";
import MockProductBox from "components/elements/main/MockProductBox";
import {isMarketPlace} from "helpers/utils";
import CustomSectionCreator from "components/CustomSectionCreator";


const ShopOffersSlider = (props) => {
  const {
      offers, symbol,
      usergetLatestOffers, bootupInfo, isLoading, shopId
  } = props;

  useEffect(() => {
      usergetLatestOffers(shopId, true, !isMarketPlace());
  }, []);

    const breakpoints = {
        300: {
            slidesPerView: 2.0,
            spaceBetween: 20,
        },
        340: {
            slidesPerView: 2.2,
            spaceBetween: 20,
        },
        380: {
            slidesPerView: 2.4,
            spaceBetween: 20,
        },
        420: {
            slidesPerView: 2.6,
            spaceBetween: 20,
        },
        480: {
            slidesPerView: 2.8,
            spaceBetween: 20,
        },
        640: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
        1024: {
            slidesPerView: 5,
            spaceBetween: 20,
        },
    };

    const renderResults = () => (
        <Container>
            <CustomSectionCreator title="Latest Offers" />
            <swiper-container slides-per-view="1.8" space-between="4" free-mode="true" breakpoints={JSON.stringify(breakpoints)}>
                    { offers.map((offer, index) => (
                        <swiper-slide key={index}>
                            <Box sx={{ width: 150 }}>
                            <UserOfferBoxItem
                                offer={offer}
                                shopId={shopId}
                            />
                            </Box>
                        </swiper-slide>
                    ))}
                </swiper-container>
        </Container>
    );
  return (

      <>
          {isLoading ? <MockProductBox />
              : offers.length > 0
                  ? renderResults()
                  : ""}

      </>
  );
};

ShopOffersSlider.defaultProps = {
  symbol: CURRENCY,
};

ShopOffersSlider.propTypes = {
  symbol: PropTypes.string,
    usergetLatestOffers: PropTypes.func.isRequired,
  bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// Mapping Props and State.
const shopOffersSlider = connect(
  ({ useroffers, bootupInfo }) => ({
    bootupInfo,
    reqStatus: useroffers.reqStatus,
    isLoading: useroffers.isLoading,
    serviceError: useroffers.serviceError,
    offers: useroffers.latestoffers,
    symbol: bootupInfo.symbol,
  }),
  (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(ShopOffersSlider);

export { shopOffersSlider as default };
