import * as ActionTypes from '../../constants/ActionTypes'
import store from "../../store";
import { toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const userMainResetProducts = () => ({
    type: ActionTypes.USER_MAIN_PRODUCTS_RESET
});

export const userMainResetWishlist = () => ({
    type: ActionTypes.USER_MAIN_WISHLIST_RESET
});

export const userMainResetCategoryProducts = () => ({
    type: ActionTypes.USER_MAIN_CATEGORY_PRODUCTS_RESET
});

export const userMainResetSearchProducts = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_PRODUCTS_RESET
});

export const userMainResetsearchKeywords = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_RESET
});

export const userMainResetSearchCombined = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_COMBINED_RESET
});

export const userMainResetsearchKeywordsCombined = () => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_COMBINED_RESET
});

export const userMainResetProductDetail = () => ({
    type: ActionTypes.USER_MAIN_PRODUCT_DETAIL_RESET
});

export const userMainGetCommonProducts = (shopId, isShop, isShopDomain, category, location, marketId, offset) => ({
    type: ActionTypes.USER_MAIN_GET_COMMON_PRODUCTS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    category: category,
    location: location,
    market: marketId,
    offset: offset
});

export const userMainGetCommonProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_COMMON_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const userMainGetCommonProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_COMMON_PRODUCTS_FAILURE,
    payload: error,
});

export const userMainGetPopularProducts = (shopId, isShop, isShopDomain, marketId) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_PRODUCTS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    market: marketId
});

export const userMainGetPopularProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_POPULAR_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const userMainGetPopularProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_PRODUCTS_FAILURE,
    payload: error,
});

export const userMainGetNewProducts = (shopId, isShop, isShopDomain, marketId) => ({
    type: ActionTypes.USER_MAIN_GET_NEW_PRODUCTS_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    market: marketId
});

export const userMainGetNewProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_NEW_PRODUCTS_SUCCESS,
        payload: data,
    };
};
export const userMainGetNewProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_NEW_PRODUCTS_FAILURE,
    payload: error,
});

export const userMainGetProductCategories = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_MAIN_GET_ACTIVE_CATEGORIES_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const userMainGetProductCategoriesSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_ACTIVE_CATEGORIES_SUCCESS,
        payload: data,
    };
};
export const userMainGetProductCategoriesFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_ACTIVE_CATEGORIES_FAILURE,
    payload: error,
});

export const userMainGetProductPopularCategories = (shopId, isShop, isShopDomain) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_START,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain
});

export const userMainGetProductPopularCategoriesSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_SUCCESS,
        payload: data,
    };
};
export const userMainGetProductPopularCategoriesFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_POPULAR_ACTIVE_CATEGORIES_FAILURE,
    payload: error,
});

export const userMainSearchProducts = (keyword, shopId, isShop, isShopDomain, sort, category, location, marketId, offset) => ({
    type: ActionTypes.USER_MAIN_SEARCH_PRODUCTS_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    sort: sort,
    category: category,
    location: location,
    market: marketId,
    offset: offset
});

export const userMainSearchProductsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_PRODUCTS_SUCCESS,
        payload: data,
    };
};

export const userMainSearchProductsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_PRODUCTS_FAILURE,
    payload: error,
});

export const userMainSearchKeywords = (keyword, shopId, isShop, isShopDomain, limit) => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    limit: limit
});

export const userMainSearchKeywordsSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_SUCCESS,
        payload: data,
    };
};

export const userMainSearchKeywordsFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_FAILURE,
    payload: error,
});

export const userMainSearchCombined = (keyword, shopId, isShop, isShopDomain, marketId) => ({
    type: ActionTypes.USER_MAIN_SEARCH_COMBINED_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    market: marketId
});

export const userMainSearchCombinedSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_COMBINED_SUCCESS,
        payload: data,
    };
};

export const userMainSearchCombinedFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_COMBINED_FAILURE,
    payload: error,
});

export const userMainSearchKeywordsCombined = (keyword, shopId, isShop, isShopDomain, limit) => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_COMBINED_START,
    keyword: keyword,
    shopId: shopId,
    isShop: isShop,
    isShopDomain: isShopDomain,
    limit: limit
});

export const userMainSearchKeywordsCombinedSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_COMBINED_SUCCESS,
        payload: data,
    };
};

export const userMainSearchKeywordsCombinedFailure = (error) => ({
    type: ActionTypes.USER_MAIN_SEARCH_KEYWORDS_COMBINED_FAILURE,
    payload: error,
});

export const userMainGetProductInfo = (reqData) => ({
    type: ActionTypes.USER_MAIN_GET_PRODUCT_INFO_START,
    payload: reqData,
});

export const userMainGetProductInfoSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_PRODUCT_INFO_SUCCESS,
        payload: data,
    };
};

export const userMainGetProductInfoFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_PRODUCT_INFO_FAILURE,
    payload: error,
});

export const userMainGetProductPrices = (reqData, isProd) => ({
    type: ActionTypes.USER_MAIN_GET_PRODUCT_PRICES_UPC_START,
    isProd: isProd,
    payload: reqData,
});

export const userMainGetProductPricesSuccess = (data) => {
    return {
        type: ActionTypes.USER_MAIN_GET_PRODUCT_PRICES_UPC_SUCCESS,
        payload: data,
    };
};
export const userMainGetProductPricesFailure = (error) => ({
    type: ActionTypes.USER_MAIN_GET_PRODUCT_PRICES_UPC_FAILURE,
    payload: error,
});
