//  @flow
import * as React from "react";
import * as Actions from "actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AdminSalesMain    from "components/pages/admin/sales/AdminSalesMain";
import AdminSalesFutureMain    from "components/pages/admin/sales/AdminSalesFutureMain";
import { checkUserAllowed } from "helpers/utils.js";

import "./styles.scss";
import {Box, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useState} from "react";



function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const AdminSales = (props) => {

  const {
    userDetails
  } = props;

    const getActiveTabInitial = () => {

        if (checkUserAllowed(userDetails.userlevel, "salesnearby")) {
            return 0;
        } else if (checkUserAllowed(userDetails.userlevel, "salesmyleads")) {
            return 1;
        } else if (checkUserAllowed(userDetails.userlevel, "salesfutures")) {
            return 2;
        } else if (checkUserAllowed(userDetails.userlevel, "salesall")) {
            return 3;
        } else {
            return 0;
        }
    }

    const [value, setValue] = useState(getActiveTabInitial());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const renderCategories = () => {
        if (checkUserAllowed(userDetails.userlevel, "sales")) {
            return (
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value}
                              onChange={handleChange}
                              variant="fullWidth"
                              aria-label="">
                            {checkUserAllowed(userDetails.userlevel, "salesnearby") &&
                                <Tab label="Nearby Leads" {...a11yProps(0)} />
                            }
                            {checkUserAllowed(userDetails.userlevel, "salesmyleads") &&
                                <Tab label="My Leads" {...a11yProps(1)} />
                            }
                            {checkUserAllowed(userDetails.userlevel, "salesfutures") &&
                                <Tab label="Future Shops" {...a11yProps(2)} />
                            }
                            {checkUserAllowed(userDetails.userlevel, "salesall") &&
                                <Tab label="All Leads" {...a11yProps(3)} />
                            }
                        </Tabs>
                    </Box>

                    {checkUserAllowed(userDetails.userlevel, "salesnearby") &&
                        <TabPanel value={value} index={0}>
                            <br/>
                            <AdminSalesMain {...props} leadtype="nearby"></AdminSalesMain>
                        </TabPanel>
                    }

                    {checkUserAllowed(userDetails.userlevel, "salesmyleads") &&
                        <TabPanel value={value} index={1}>
                            <br/>
                            <AdminSalesMain {...props} leadtype="my"></AdminSalesMain>
                        </TabPanel>
                    }

                    {checkUserAllowed(userDetails.userlevel, "salesfutures") &&
                        <TabPanel value={value} index={2}>
                            <br/>
                            <AdminSalesFutureMain {...props} leadtype="nearby"></AdminSalesFutureMain>
                        </TabPanel>
                    }

                    { checkUserAllowed(userDetails.userlevel, "salesall") &&
                        <TabPanel value={value} index={3}>
                            <br/>
                            <AdminSalesMain {...props} leadtype="all" ></AdminSalesMain>
                        </TabPanel>
                    }
                </Box>
            )
        } else {
            return (
                <div>Not available </div>
            )
        }
    }

  return (
      <div>
        {
          renderCategories()
        }
      </div>
  );
}

const adminSales = connect(
    ({ bootupInfo }) => ({
      bootupInfo,
      userDetails: bootupInfo.userDetails,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSales);

export { adminSales as default };

