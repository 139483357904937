export const grey = {
  900: '#222222', // Main Text
  800: '#444444', // Paragraph
  700: '#666666',
  600: '#888888', // Low Priority form Title/Text
  500: '#AAAAAA',
  400: '#CCCCCC', // Border
  300: '#EEEEEE', // background
  200: '#F3F5F9', // Line Stroke
  100: '#FFFFFF',
}

export const white = {
  900: '#FFFFFF', // Main Text
  800: '#FFFFFF', // Paragraph
  700: '#FFFFFF',
  600: '#FFFFFF', // Low Priority form Title/Text
  500: '#FFFFFF',
  400: '#FFFFFF', // Border
  300: '#FFFFFF',
  200: '#FFFFFF', // Line Stroke
  100: '#FFFFFF',
}

export const black = {
  900: '#000000', // Main Text
  800: '#000000', // Paragraph
  700: '#000000',
  600: '#000000', // Low Priority form Title/Text
  500: '#000000',
  400: '#000000', // Border
  300: '#000000',
  200: '#000000', // Line Stroke
  100: '#000000',
}

export const primary = {
  100: '#E73A2F',
  200: '#E73A2F',
  300: '#E73A2F',
  400: '#E73A2F',
  500: '#E73A2F',
  600: '#E73A2F',
  700: '#E73A2F',
  800: '#E73A2F',
  900: '#E73A2F',
}

export const secondary = {
  100: '#e8e8ee',
  200: '#b9bacb',
  300: '#8a8ca8',
  400: '#5b5d85',
  500: '#141850',
  600: '#0F3460',
  700: '#101340',
  800: '#0e1138',
  900: '#0c0e30',
  main: '#0F3460',
  dark: '#0c0e30',
}

export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560',
}

export const success = {
  100: '#E7F9ED',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0b7724',
  main: 'rgb(51, 208, 103)',
}

export const warning = {
  main: '#FFCD4E',
  contrastText: '#FFFFFF',
}
