/* eslint-disable no-underscore-dangle */
import React from "react";
import {
    Box
} from "@mui/material";
import PropTypes from "prop-types";
import {H2} from "components/CustomTypography";

const CategoryItem = (props) => {
     const { isSelected, isDirect, isSmall, category, handleSelectCategoryLink, handleSelectCategorySet } = props;

    return (<Box sx={isSelected ? { backgroundColor: "primary.100"} : { backgroundColor: "grey.100"}} mr={1} p={1} onClick={isDirect ? () => handleSelectCategoryLink(category.id) : () => handleSelectCategorySet(category)}>
        <H2 textTransform="capitalize" color={ isSelected ? "grey.100" : "primary.main"} fontSize={isSmall ? 10 : 14} textAlign="center" title={category.name} ellipsis>
            {category.name}
        </H2>
    </Box>);
};

CategoryItem.propTypes = {
  category: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CategoryItem;
