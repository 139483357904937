import React, {Component, useEffect} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
import { bindActionCreators } from "redux";

import * as Actions     from "actions";
import MockProductList  from "components/elements/main/MockProductList";

import { getURI} from "helpers/utils";
import {Box, Card, Container, Typography} from "@mui/material";
import {CURRENCY} from "config/constants";
import PropTypes from "prop-types";

const UserOrderList = (props) => {

    const {
        isLoading, orderlistItems, symbol, history, bootupInfo, userMainGetOrderlistProducts, match
    } = props;

    useEffect(() => {
        userMainGetOrderlistProducts();
    }, []);

    if (isLoading) {
        return <MockProductList />;
    }



        return (
            <div>
                <Container>
                    <Box alignContent="center" width="100%" display="flex" p={2}>
                        <Box width="25%" order={1}>
                            <Typography variant="h6" align="center">Order</Typography>
                        </Box>
                        <Box width="40%" order={3}>
                            <Typography variant="h6" align="center">Date</Typography>
                        </Box>
                        <Box width="35%" order={4}>
                            <Typography variant="h6" align="center">Type</Typography>
                        </Box>
                    </Box>
                    {orderlistItems.map((order, index) => (
                        <Card key={`${index + 1}`} className="m-2">

                            <Box onClick={() => history.push(getURI(process.env.REACT_APP_TYPE, "order", "", "", order._id))} alignContent="center" width="100%" display="flex" p={2}>
                                <Box width="25%" order={1}>
                                    <Typography align="center">
                                        <Link
                                            className="styled-link"
                                            to={getURI(process.env.REACT_APP_TYPE, "order", "", "", order._id)}
                                        >
                                            {order.order_id}
                                        </Link>
                                    </Typography>
                                </Box>

                                <Box width="35%" order={4}>
                                    <Typography align="center">
                                        {order.order_type === "delivery" ? "Delivery" : "Pickup"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Card>
                    ))}
                </Container>
            </div>
        );
}

UserOrderList.defaultProps = {
    isLoading: false,
    symbol: CURRENCY,
};

UserOrderList.propTypes = {
    isLoading: PropTypes.bool,
    orderlistItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    symbol: PropTypes.string,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    bootupInfo: PropTypes.objectOf(PropTypes.any).isRequired,
    userMainGetOrderlistProducts: PropTypes.func.isRequired,
};

// Mapping Props and State.
const userOrderList = connect(
    ({ userMainOrders, bootupInfo }) => ({
        bootupInfo: bootupInfo,
        reqStatus: userMainOrders.reqStatus,
        isLoading: userMainOrders.isLoading,
        serviceError: userMainOrders.serviceError,
        orderlistItems: userMainOrders.orderlist,
        symbol: bootupInfo.symbol
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch)
)(UserOrderList);

export { userOrderList as default };
