import React, {useState} from 'react'
import {Grid, } from "@mui/material";
import {FlexBox, FlexRowCenter} from "components/flex-box";
import LazyImage from "components/LazyImage";
import {getImage, getImageFromURL} from "helpers/utils";
import BazaarAvatar from "components/BazaarAvatar";

const ImageAlbum = ({images, mainOnly}) => {

    const [selectedImage, setSelectedImage] = useState(0);

    const handleImageClick = (ind) => () => {
        setSelectedImage(ind);
    };

    const renderImageAlbum = () => (
        <Grid item md={6} xs={12} alignItems="center">
            {!mainOnly &&
                <FlexBox overflow="auto">
                    {images && images.length > 1 && images.map((url, ind) => (
                        <FlexRowCenter
                            key={ind}
                            width={64}
                            height={64}
                            minWidth={64}
                            bgcolor="white"
                            border="1px solid"
                            borderRadius="10px"
                            ml={ind === 0 ? "auto" : 0}
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={handleImageClick(ind)}
                            mr={ind === images.length - 1 ? "auto" : "10px"}
                            borderColor={
                                selectedImage === ind ? "primary.main" : "grey.400"
                            }
                        >
                            <BazaarAvatar src={getImageFromURL(url)} variant="square" height={40}/>
                        </FlexRowCenter>
                    ))}
                </FlexBox>
            }
            <FlexBox justifyContent="center">
                <LazyImage
                    width={400}
                    height={400}
                    loading="eager"
                    objectFit="contain"
                    src={getImage(images, selectedImage)}
                />
            </FlexBox>
        </Grid>
    );

    return (
        <>
            {images && images.length > 0 && renderImageAlbum()}
        </>
    );
}


export default ImageAlbum;
