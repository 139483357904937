import * as yup from "yup";

export const schema = yup.object().shape({
  id: yup.string().required("Select a city"),
});


export const getRadioButtonData = (id) => [
  {
    label: "City",
    value: id,
    onChangeField: "id",
  },
];

export const getButtonData = (onFinish, onCancel, hasError) => [
  {
    name: "Change",
    onClick: onFinish,
    variant: "contained",
    type: "primary",
    disabled: hasError(),
  },
  {
    name: "Cancel",
    onClick: onCancel,
    variant: "outlined",
    type: "primary",
    disabled: false,
  },
];

