/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-unresolved */
import React, {useState} from "react";
import PropTypes from "prop-types";
import { Container, Box, Typography } from "@mui/material";
import TextFieldComponent from "components/TextField";
import ButtonComponent from "components/Button";
import {
  schema, getTextfieldData, getButtonData, getCheckboxData, getCheckboxDataWithoutFood, getSelectFieldData
} from "./helper";
import AdminShopLogoEditor from "components/pages/admin/shops/AdminShopLogoEditor";
import AdminShopVideoEditor from "components/pages/admin/shops/AdminShopVideoEditor";
import AdminOfferAdd from "components/elements/admin/offers/AdminOfferAdd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Actions from "actions";
import CustomSectionCreator from "components/CustomSectionCreator";

const AdminSellerSubscriptionProfileEditor = (props) => {

  const {
    setState, updateShopProfileClicked, onCancel,  selectedShop,
  } = props;

    const [offerState, setOfferState] = useState({ selectedOffer: "" });

  const {
        shop_id, title, addressline, logo, video
  } = selectedShop;

  const [state] = useState({
    layoutColumns: 3,
  });


  const [blur, setblur] = React.useState({
    title: false,
    addressline: false,
  });

  const onFinish = () => {
    updateShopProfileClicked({
      shop_id, title, addressline,
    });
  };

  const handleTextFieldChange = (event, field) => {
    setState(event.target.value, field);
    setblur({ ...blur, [field]: true });
  };

  const hasError = () => {
    try {
      return !schema.validateSync(selectedShop);
    } catch (err) {
      return true;
    }
  };

  const handleBlur = (field) => {
    setblur({ ...blur, [field]: true });
  };

  const isTouched = () => (blur.title || blur.addressline );

  const getError = (field) => {
    if (blur[field] && hasError()) {
      try {
        schema.validateSyncAt(field, selectedShop);
      } catch (err) {
        return err.message;
      }
    }
    return null;
  };

    const { selectedOffer = {} } = offerState;

  return (
    <Container>
        {<CustomSectionCreator isSubtle={true} title="Update Shop Profile" /> }
      <Box display="flex" flexWrap="wrap">
        {getTextfieldData(title, addressline).map((element) => (
          <Box className="textfield" m={2}>
            <TextFieldComponent
              required
              label={element.label}
              value={element.value}
              error={!!getError(element.onChangeField)}
              helperText={getError(element.onChangeField)}
              onChange={(event) => handleTextFieldChange(event, element.onChangeField)}
            />
          </Box>
        ))}
      </Box>
      <br />
      <Typography align="center">
        {getButtonData(onFinish, onCancel, hasError, isTouched).map((element) => (
          <ButtonComponent
              sx={{
                m: 2,
              }}
            type={element.type}
            variant={element.variant}
            onClick={element.onClick}
            disabled={element.disabled}
            name={element.name}
          />
        ))}
      </Typography>

    </Container>
  );
};

AdminSellerSubscriptionProfileEditor.defaultProps = {
  isLoading: false,
};

AdminSellerSubscriptionProfileEditor.propTypes = {
  setState: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  addUpdateProductToShopClicked: PropTypes.func.isRequired,
  cancelEdit: PropTypes.func.isRequired,
};


const adminSellerSubscriptionProfileEditor = connect(
    ({  bootupInfo }) => ({
      bootupInfo,
      userDetails: bootupInfo.userDetails,
      reqStatus: bootupInfo.reqStatus,
      isLoading: bootupInfo.isLoading,
      serviceError: bootupInfo.serviceError,
    }),
    (dispatch) => bindActionCreators({ ...Actions }, dispatch),
)(AdminSellerSubscriptionProfileEditor);


export default adminSellerSubscriptionProfileEditor;
