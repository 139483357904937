import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Actions from "actions/seller/seller.store";

import {Box, Container} from "@mui/material";
import {useEffect} from "react";

import PhotoUpload from "components/PhotoUpload";
import {adminUpdateShopLogoImage} from "actions";
import AdminSellerShopEditor from "components/elements/admin/shops/AdminSellerShopEditor";

const AdminShopLogoEditor = (props) => {

  const { serviceError, reqStatus, isLoading,
      adminUpdateShopLogoClicked, logo, shop_id,
      cancelEdit } = props;

    //const { logo } = selectedShop;

    useEffect(() => {
    if (serviceError && reqStatus) {
      const feedback = {
        title: "Something went wrong!",
        description: serviceError.statusText,
      };
      sendNotification("error", feedback);
    }
  }, [serviceError, reqStatus]);

  const sendNotification = (type, { title, description = "" }) => {
    console.log({
      message: title,
      description,
    });
  };

    const addImageCallback = (file) => {
        const formData = new FormData()
        formData.append("image", file);
        adminUpdateShopLogoClicked(shop_id, formData);
    }


    return (
        <Container>
            <Box >
                <PhotoUpload isRemoveAllowed={false}
                             images={logo}
                             addImageCallback={addImageCallback}
                             cancelEdit={cancelEdit}
                             idx="1"
                />
            </Box>
        </Container>
    );
};

export default AdminShopLogoEditor;
